import React from 'react';
import BaseComponent, { FetchAPIPromise, PostAPIPromise } from '../BaseComponent.js'
import CustomStore from 'devextreme/data/custom_store';
import Form, { Item, ButtonItem, EmptyItem, RequiredRule, Label } from 'devextreme-react/form';
import * as $ from 'jquery'
import DataGrid, { Column, FilterRow, Pager, Paging, MasterDetail, CustomRule, Button as GridButton, Export, Editing, Form as GridForm } from 'devextreme-react/data-grid';
import { alert, confirm } from 'devextreme/ui/dialog';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { CheckBox } from 'devextreme-react/check-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import * as moment from 'moment';
import DataSource from 'devextreme/data/data_source';

var states = [];
var pickupAgencies = [];
var facilities = [];
var placementStatuses = [];
var inactiveReasons = [];

var addRequestParentDataGrid = null;
var changeRequestParentDataGrid = null;

export class PlacementChangeRequestContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.state = {
            importResult: null,
            importMessage: null,
            requestsToImport: [],
            returnPath: null,

            isHandlingChangeRequest: false,
            changeRequestHandleId: null,
            changeRequestProcessingResults: {},

            changeRequestFilter: {
                submitted: true,
                approved: false,
                notApproved: false
            },

            addRequestFilter: {
                submitted: true,
                processed: false,
                approved: false,
                notApproved: false
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Change History");
    }

    GetDropDownData = async () => {
        const facilityData = await this.FetchAPI('Facility/GetForSelection');

        var pickupAgenciesData = await this.FetchAPI('PickupLocation/GetPickupAgencies');

        const stateData = await this.FetchAPI('DictionaryState');

        const placementStatusData = await this.FetchAPI('DictionaryPlacementStatus');

        const inactiveReasonData = await this.FetchAPI('DictionaryInactiveReason');

        states = stateData;
        pickupAgencies = pickupAgenciesData;
        placementStatuses = placementStatusData;
        inactiveReasons = inactiveReasonData;
        facilities = facilityData;
    }

    placementList = new CustomStore({
        key: 'id',
        onLoading: (loadOptions) => {
            //console.log('Loading: ' + loadOptions);

            loadOptions.userData["submitted"] = this.state.changeRequestFilter.submitted;
            loadOptions.userData["approved"] = this.state.changeRequestFilter.approved;
            loadOptions.userData["notApproved"] = this.state.changeRequestFilter.notApproved;
        },
        load: (loadOptions) => {
            console.log('Load: ' + loadOptions);

            var queryString = ' ';
            var queryOptions = Object.keys(loadOptions.userData);

            console.log(queryOptions);

            for (var i = 0; i < queryOptions.length; i++) {

                if (queryString) {
                    queryString += '&';
                }

                queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
            }
            console.log(queryString);

            return FetchAPIPromise('PlacementChangeRequest', queryString);
        }
    });

    addRequestList = new CustomStore({
        key: 'id',
        onLoading: (loadOptions) => {
            //console.log('Loading: ' + loadOptions);

            loadOptions.userData["submitted"] = this.state.addRequestFilter.submitted;
            loadOptions.userData["processed"] = this.state.addRequestFilter.processed;
            loadOptions.userData["approved"] = this.state.addRequestFilter.approved;
            loadOptions.userData["notApproved"] = this.state.addRequestFilter.notApproved;
        },
        load: (loadOptions) => {
            console.log('Load: ' + loadOptions);

            var queryString = ' ';
            var queryOptions = Object.keys(loadOptions.userData);

            console.log(queryOptions);

            for (var i = 0; i < queryOptions.length; i++) {

                if (queryString) {
                    queryString += '&';
                }

                queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
            }
            console.log(queryString);

            return FetchAPIPromise('PlacementAddRequest', queryString);
        }
    });

    handleChangeRequestChange = (event) => {
        this.setState(
            (state) => ({
                changeRequestFilter: {
                    ...state.changeRequestFilter,
                    [event.element.id]: !state.changeRequestFilter[event.element.id]
                }
            }),
            () => {
                //console.log(this.changeRequestDataGrid);
                changeRequestParentDataGrid.instance().refresh();
            }
        );
    };

    handleAddRequestChange = (event) => {
        this.setState(
            (state) => ({
                addRequestFilter: {
                    ...state.addRequestFilter,
                    [event.element.id]: !state.addRequestFilter[event.element.id]
                }
            }),
            () => {
                //console.log(this.addRequestDataGrid);
                addRequestParentDataGrid.instance().refresh();
            }
        );
    };

    render() {
        return (
            <div className="container-fluid">
                <h1>Change History</h1>

                <br />
                <div className="dx-fieldset">
                    <div className="dx-fieldset-header">Placement Change Requests</div>
                    <div className="dx-field">

                        <div className="d-flex justify-content-end">
                            <div className="me-auto">
                                Status:{' '}&nbsp;
                                <CheckBox className="p-2" value={this.state.changeRequestFilter.submitted} id="submitted" text="Submitted" onValueChanged={this.handleChangeRequestChange} />&nbsp;
                                <CheckBox value={this.state.changeRequestFilter.approved} id="approved" text="Completed" onValueChanged={this.handleChangeRequestChange} />&nbsp;
                                <CheckBox value={this.state.changeRequestFilter.notApproved} id="notApproved" text="Rejected" onValueChanged={this.handleChangeRequestChange} />&nbsp;
                            </div>
                        </div>

                        <br />

                        <DataGrid dataSource={this.placementList}
                            keyExpr="id" ref={ref => changeRequestParentDataGrid = ref} allowColumnResizing={true}
                            showBorders={true} onExporting={this.onExporting}>
                            <Export enabled={true} fileName={"placementChangeRequests"} />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                                showInfo={true} />
                            <FilterRow visible={true} />

                            <Column caption="Received Date" dataField="receivedDate" dataType="datetime" format="MM/dd/yyyy HH:mm '(Local)'" />
                            <Column caption="Message" dataField="message" />
                            <Column caption="Status" dataField="status" />

                            <MasterDetail enabled={true} autoExpandAll={true} component={ChangeRequestDetail} />
                        </DataGrid>
                    </div>
                </div>
                <br />
                <div className="dx-fieldset">
                    <div className="dx-fieldset-header">Placement Add Requests</div>
                    <div className="dx-field">

                        <div className="d-flex justify-content-end">
                            <div className="me-auto">
                                Status:{' '}&nbsp;
                                <CheckBox className="p-2" value={this.state.addRequestFilter.submitted} id="submitted" text="Submitted" onValueChanged={this.handleAddRequestChange} />&nbsp;
                                <CheckBox value={this.state.addRequestFilter.processed} id="processed" text="Processed" onValueChanged={this.handleAddRequestChange} />&nbsp;
                                <CheckBox value={this.state.addRequestFilter.approved} id="approved" text="Completed" onValueChanged={this.handleAddRequestChange} />&nbsp;
                                <CheckBox value={this.state.addRequestFilter.notApproved} id="notApproved" text="Rejected" onValueChanged={this.handleAddRequestChange} />&nbsp;
                            </div>
                        </div>

                        <br />
                        <DataGrid dataSource={this.addRequestList}
                            keyExpr="id" ref={ref => addRequestParentDataGrid = ref} allowColumnResizing={true}
                            showBorders={true} onExporting={this.onExporting}>
                            <Export enabled={true} fileName={"placementChangeRequests"} />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                                showInfo={true} />
                            <FilterRow visible={true} />

                            <Column caption="Received Date" dataField="receivedDate" dataType="datetime" format="MM/dd/yyyy HH:mm '(Local)'" />
                            <Column caption="Message" dataField="message" />
                            <Column caption="Status" dataField="status" />

                            <MasterDetail enabled={true} autoExpandAll={true} component={AddRequestDetail} />
                        </DataGrid>
                    </div>
                </div>
                <br />

                <LoadPanel visible={this.state.loading} />
            </div>
        );
    }
}

class AddRequestDetail extends BaseComponent {
    constructor(props) {
        //console.log('Detail Props', props);
        //console.log(addRequestParentDataGrid);
        super(props);
        this.dataSource = props.data.row.data.requestList;

        //this.onRowUpdated = this.onRowUpdated.bind(this);
        this.onSaving = this.onSaving.bind(this);

        this.rowKey = -1;
    }

    canCorrectAndImport = (e) => {
        //console.log(e);

        return e.row.data.willImport == false && e.row.data.statusCode == 'SUBMITTED' == !this.IsReadOnly();
    }

    canRejected = (e) => {
        //console.log(e);

        return e.row.data.willImport == false && e.row.data.statusCode == 'SUBMITTED' == !this.IsReadOnly();
    }

    onAddRequestEditorPreparing = (e) => {
        //console.log(e);

        if (e.parentType == 'dataRow') {
            if (e.dataField == 'aNumber') {
                e.editorOptions.readOnly = e.row.data.isANumberValid;
            }

            if (e.dataField == 'admissionDate') {
                e.editorOptions.readOnly = e.row.data.isAdmissionDateValid;
            }

            if (e.dataField == 'approvalDate') {
                e.editorOptions.readOnly = e.row.data.isApprovalDateValid;
            }

            if (e.dataField == 'lastName') {
                e.editorOptions.readOnly = e.row.data.isLastNameValid;
            }

            if (e.dataField == 'middleName') {
                e.editorOptions.readOnly = true;
            }

            if (e.dataField == 'firstName') {
                e.editorOptions.readOnly = e.row.data.isFirstNameValid;
            }

            if (e.dataField == 'dob') {
                e.editorOptions.readOnly = e.row.data.isDOBValid;
            }

            if (e.dataField == 'gender') {
                e.editorOptions.readOnly = e.row.data.isGenderValid;
            }

            if (e.dataField == 'nationality') {
                e.editorOptions.readOnly = e.row.data.isNationalityValid;
            }

            if (e.dataField == 'familyGroupID') {
                e.editorOptions.readOnly = e.row.data.isFamilyGroupIDValid;
            }

            if (e.dataField == 'movementTypeCode') {
                e.editorOptions.readOnly = e.row.data.isMovementTypeCodeValid;
            }

            if (e.dataField == 'currentShelter') {
                e.editorOptions.readOnly = e.row.data.isCurrentShelterValid;
            }

            if (e.dataField == 'assignedShelter') {
                e.editorOptions.readOnly = e.row.data.isAssignedShelterValid;
            }

            if (e.dataField == 'sponsorName') {
                e.editorOptions.readOnly = e.row.data.isSponsorNameValid;
            }

            if (e.dataField == 'sponsorPhone') {
                e.editorOptions.readOnly = e.row.data.isSponsorPhoneValid;
            }

            if (e.dataField == 'sponsorAlternatePhone') {
                e.editorOptions.readOnly = e.row.data.isSponsorAlternatePhoneValid;
            }

            if (e.dataField == 'sponsorAddress') {
                e.editorOptions.readOnly = e.row.data.isSponsorAddressValid;
            }

            if (e.dataField == 'sponsorCity') {
                e.editorOptions.readOnly = e.row.data.isSponsorCityValid;
            }

            if (e.dataField == 'sponsorState') {
                e.editorOptions.readOnly = e.row.data.isSponsorStateValid;
            }

            if (e.dataField == 'sponsorZipCode') {
                e.editorOptions.readOnly = e.row.data.isSponsorZipCodeValid;
            }

            if (e.dataField == 'transportUnit') {
                e.editorOptions.readOnly = e.row.data.isTransportUnitValid;
            }

            if (e.dataField == 'familyUnitNumber') {
                e.editorOptions.readOnly = e.row.data.isFamilyUnitNumberValid;
            }

            if (e.dataField == 'subclassificationCode') {
                e.editorOptions.readOnly = e.row.data.isSubclassificationCodeValid;
            }

            if (e.dataField == 'subjectID') {
                e.editorOptions.readOnly = e.row.data.isSubjectIDValid;
            }
        }
    }

    //onEditorPreparing = (e) => {
    //    if (e.dataField === "systemFacilityId" && e.parentType === "dataRow") {
    //        e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode === "REUNIFICATION";
    //    }

    //    if (e.dataField === "sponsorName" && e.parentType === "dataRow") {
    //        e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
    //    }

    //    if (e.dataField === "sponsorPhone" && e.parentType === "dataRow") {
    //        e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
    //    }

    //    if (e.dataField === "sponsorAddress" && e.parentType === "dataRow") {
    //        e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
    //    }

    //    if (e.dataField === "sponsorCity" && e.parentType === "dataRow") {
    //        e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
    //    }

    //    if (e.dataField === "sponsorState" && e.parentType === "dataRow") {
    //        e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
    //    }

    //    if (e.dataField === "sponsorZipCode" && e.parentType === "dataRow") {
    //        e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
    //    }

    //    if (e.dataField === "sponsorNotified" && e.parentType === "dataRow") {
    //        e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
    //    }

    //    if (e.dataField === "sponsorNotifiedDate" && e.parentType === "dataRow") {
    //        e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
    //    }
    //}

    //validateShelterFields(e) {
    //    console.log('Validate shelter', e);
    //    return e.value || e.data.systemMovementTypeCode === 'REUNIFICATION';
    //}

    //validateApprovalField(e) {
    //    return e.value || e.data.systemMovementTypeCode === 'TRANSFER';
    //}

    //SubmitTransportRequest = async (e) => {

    //    if (this.placementDataSource._array.length == 0) {
    //        alert('Please enter at least one Customer to Transport');
    //    }
    //    else {

    //        for (var i = 0; i < this.placementDataSource._array.length; i++) {

    //            // Validate for single member relationship groups
    //            if (this.placementDataSource._array[i].familyGroupID != null && this.placementDataSource._array[i].familyGroupID != '') {
    //                if ((this.placementDataSource._array.filter(a => a.familyGroupID == this.placementDataSource._array[i].familyGroupID)).length < 2) {
    //                    alert('Relationship group ' + this.placementDataSource._array[i].familyGroupID + ' contains only one member.');
    //                    return;
    //                }
    //            }
    //            else {
    //                // TODO: possible better solution since "" should never be an imputted value, it should be null
    //                this.placementDataSource._array[i].familyGroupID = null;
    //            }

    //            // Validate for duplicated A numbers
    //            if ((this.placementDataSource._array.filter(a => a.aNumber == this.placementDataSource._array[i].aNumber && a.systemFacilityId == this.placementDataSource._array[i].systemFacilityId && a.systemPickupLocationId == this.placementDataSource._array[i].systemPickupLocationId)).length > 1) {
    //                alert('Error encountered during import. Multiple rows cannot have the same A number, current shelter, and assigned shelter. The first duplicated row found contains the A number: ' + this.placementDataSource._array[i].aNumber);
    //                return;
    //            }
    //        }

    //        var confirmAnswer = await confirm("Are you sure you want to submit this Transport Request?");

    //        if (confirmAnswer) {

    //            await this.setState({
    //                loading: true
    //            });

    //            console.log(this.state.placement);
    //            const result = await this.PostAPI('Placement/BulkEntry', this.placementDataSource._array);

    //            console.log(result);

    //            if (result.status == 1) {
    //                // TODO: Alert

    //                this.props.history.push('/');
    //                this.props.history.push('/initial-transport-request');
    //            }
    //            else {
    //                alert(result.message);
    //            }

    //            await this.setState({
    //                loading: false
    //            });
    //        }
    //    }
    //}

    markProcessed = async (e) => {
        //console.log('Saving...', e);

        await this.setState({
            loading: true
        });

        //console.log(e);

        var newRecord = e.row.data;

        newRecord.statusCode = 'PROCESSED';

        try {
            const result = await this.PostAPI('PlacementAddRequest', newRecord);

            //console.log(result);

            if (result.status != 1) {
                alert('Failed. Please try again later.');
            }
            else {
                addRequestParentDataGrid.instance().refresh();
            }
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    markRejected = async (e) => {
        //console.log('Saving...', e);

        await this.setState({
            loading: true
        });

        //console.log(e);

        var newRecord = e.row.data;

        newRecord.statusCode = 'NOTAPPROVED';

        try {
            const result = await this.PostAPI('PlacementAddRequest', newRecord);

            //console.log(result);

            if (result.status != 1) {
                alert('Failed. Please try again later.');
            }
            else {
                addRequestParentDataGrid.instance().refresh();
            }
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    validateSponsorFields(e) {
        //console.log(e);
        return e.value || e.data.systemMovementTypeCode !== 'REUNIFICATION';
    }

    onRowUpdating = async (options) => {
        //console.log('Row Updating', options);
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    onEditingStart = (e) => {
        this.rowKey = e.key;
    }

    onSaving(e) {
        let grid = this.addRequestDataGrid.instance();
        let rows = grid.getVisibleRows();
        let rowIndex = grid.getRowIndexByKey(this.rowKey);
        let row = rows[rowIndex];

        //console.log(row);
        //console.log(e);

        e.changes.map((change) => {
            Object.assign(row.data, change.data);
        });

        //console.log(row);

        e.promise = PostAPIPromise('PlacementAddRequest/CorrectAndImport', row.data).then((result) => {
            if (result.status != 1) {
                alert(result.message);
                e.cancel = true;
            }
        });
    }

    render() {
        return (
            <DataGrid dataSource={this.dataSource}
                keyExpr="id" ref={ref => this.addRequestDataGrid = ref} allowColumnResizing={true}
                showBorders={true} onExporting={this.onExporting} onSaving={this.onSaving}
                onEditorPreparing={this.onAddRequestEditorPreparing} onEditingStart={this.onEditingStart}>
                <Export enabled={true} fileName={"placementAddRequests"} />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 20]}
                    showInfo={true} />
                <FilterRow visible={true} />
                <Editing mode="popup" allowDeleting={false} allowAdding={false} allowUpdating={true}>
                    <GridForm>
                        <Item dataField="aNumber" />
                        <Item dataField="admissionDate" />
                        <Item dataField="approvalDate" />
                        <Item dataField="lastName" />
                        <Item dataField="firstName" />
                        <Item dataField="middleName" />

                        <Item dataField="dob" />
                        <Item dataField="gender" />
                        <Item dataField="nationality" />

                        <Item dataField="familyGroupID" />
                        <Item dataField="movementTypeCode" />
                        <Item dataField="currentShelter" editorType="dxSelectBox" editorOptions={{ showClearButton: true, searchEnabled: true, dataSource: pickupAgencies, displayExpr: 'name', valueExpr: 'name' }} />

                        <Item dataField="assignedShelter" editorType="dxSelectBox" editorOptions={{ showClearButton: true, searchEnabled: true, dataSource: facilities, displayExpr: 'name', valueExpr: 'name' }} />
                        <Item dataField="sponsorName" />
                        <Item dataField="sponsorPhone" />

                        <Item dataField="sponsorAlternatePhone" />
                        <Item dataField="sponsorAddress" />
                        <Item dataField="sponsorCity" />

                        <Item dataField="sponsorState" editorType="dxSelectBox" editorOptions={{ showClearButton: true, searchEnabled: true, dataSource: states, displayExpr: 'name', valueExpr: 'name' }} />
                        <Item dataField="sponsorZipCode" />
                        <Item dataField="transportUnit" />

                        <Item dataField="authority" />
                        <Item dataField="familyUnitNumber" />
                        <Item dataField="subclassificationCode" />

                        <Item dataField="subjectID" />
                    </GridForm>
                </Editing>

                <Column dataField="willImport" caption="Will Import?" cellRender={function (options) { return (<img width='20px' height='20px' src={options.row.data.willImport ? 'yesbutton.png' : 'nobutton.png'} />); }} />
                <Column dataField="aNumber" caption="A Number" cellRender={function (options) { return (<div title={options.row.data.aNumberMessage} style={{ backgroundColor: `${options.row.data.isANumberValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.aNumber}&nbsp;</div>); }} />
                <Column dataField="admissionDate" caption="Admission Date" visible={this.isAdmissionDateVisible} cellRender={function (options) { return (<div title={options.row.data.admissionDateMessage} style={{ backgroundColor: `${options.row.data.isAdmissionDateValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.admissionDate}&nbsp;</div>); }} />
                <Column dataField="approvalDate" caption="Approval Date" visible={this.isApprovalDateVisible} cellRender={function (options) { return (<div title={options.row.data.approvalDateMessage} style={{ backgroundColor: `${options.row.data.isApprovalDateValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.approvalDate}&nbsp;</div>); }} />
                <Column dataField="lastName" caption="Last Name" cellRender={function (options) { return (<div title={options.row.data.lastNameMessage} style={{ backgroundColor: `${options.row.data.isLastNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.lastName}&nbsp;</div>); }} />
                <Column dataField="firstName" caption="First Name" cellRender={function (options) { return (<div title={options.row.data.firstNameMessage} style={{ backgroundColor: `${options.row.data.isFirstNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.firstName}&nbsp;</div>); }} />
                <Column dataField="middleName" caption="Middle Name" />
                <Column dataField="dob" caption="DOB" cellRender={function (options) { return (<div title={options.row.data.dobMessage} style={{ backgroundColor: `${options.row.data.isDOBValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.dob}&nbsp;</div>); }} />
                <Column dataField="gender" caption="Gender" cellRender={function (options) { return (<div title={options.row.data.genderMessage} style={{ backgroundColor: `${options.row.data.isGenderValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.gender}&nbsp;</div>); }} />
                <Column dataField="nationality" caption="Nationality" cellRender={function (options) { return (<div title={options.row.data.nationalityMessage} style={{ backgroundColor: `${options.row.data.isNationalityValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.nationality}&nbsp;</div>); }} />
                <Column dataField="familyGroupID" caption="Family Group ID" cellRender={function (options) { return (<div title={options.row.data.familyGroupIDMessage} style={{ backgroundColor: `${options.row.data.isFamilyGroupIDValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.familyGroupID}&nbsp;</div>); }} />
                <Column dataField="movementTypeCode" caption="Type" cellRender={function (options) { return (<div title={options.row.data.movementTypeCodeMessage} style={{ backgroundColor: `${options.row.data.isMovementTypeCodeValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.movementTypeCode}&nbsp;</div>); }} />
                <Column dataField="currentShelter" caption="Current Shelter/Program" cellRender={function (options) { return (<div title={options.row.data.currentShelterMessage} style={{ backgroundColor: `${options.row.data.isCurrentShelterValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.currentShelter}&nbsp;</div>); }} />
                <Column dataField="assignedShelter" caption="Assigned Shelter/Program" cellRender={function (options) { return (<div title={options.row.data.assignedShelterMessage} style={{ backgroundColor: `${options.row.data.isAssignedShelterValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.assignedShelter}&nbsp;</div>); }} />
                <Column dataField="sponsorName" caption="Sponsor Name" visible={this.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorNameMessage} style={{ backgroundColor: `${options.row.data.isSponsorNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorName}&nbsp;</div>); }} />
                <Column dataField="sponsorPhone" caption="Phone No." visible={this.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorPhoneMessage} style={{ backgroundColor: `${options.row.data.isSponsorPhoneValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorPhone}&nbsp;</div>); }} />
                <Column dataField="sponsorAlternatePhone" caption="Alt. Phone No." visible={this.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorAlternatePhoneMessage} style={{ backgroundColor: `${options.row.data.isSponsorAlternatePhoneValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorAlternatePhone}&nbsp;</div>); }} />
                <Column dataField="sponsorAddress" caption="Street Address" visible={this.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorAddressMessage} style={{ backgroundColor: `${options.row.data.isSponsorAddressValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorAddress}&nbsp;</div>); }} />
                <Column dataField="sponsorCity" caption="City" visible={this.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorCityMessage} style={{ backgroundColor: `${options.row.data.isSponsorCityValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorCity}&nbsp;</div>); }} />
                <Column dataField="sponsorState" caption="State" visible={this.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorStateMessage} style={{ backgroundColor: `${options.row.data.isSponsorStateValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorState}&nbsp;</div>); }}>
                    <CustomRule validationCallback={this.validateSponsorFields} message="State is required" />
                </Column>
                <Column dataField="sponsorZipCode" caption="Zip Code" visible={this.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorZipCodeMessage} style={{ backgroundColor: `${options.row.data.isSponsorZipCodeValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorZipCode}&nbsp;</div>); }} />
                <Column dataField="transportUnit" caption="Transport Unit" cellRender={function (options) { return (<div title={options.row.data.transportUnitMessage} style={{ backgroundColor: `${options.row.data.isTransportUnitValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.transportUnit}&nbsp;</div>); }} />
                <Column dataField="authority" caption="Authority Code" cellRender={function (options) { return (<div title={options.row.data.authorityMessage} style={{ backgroundColor: `${options.row.data.isAuthorityValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.authority}&nbsp;</div>); }} />
                <Column dataField="familyUnitNumber" caption="Family Unit #" visible={this.isFamilyUnitNumberVisible} cellRender={function (options) { return (<div title={options.row.data.familyUnitNumberMessage} style={{ backgroundColor: `${options.row.data.isFamilyUnitNumberValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.familyUnitNumber}&nbsp;</div>); }} />
                <Column dataField="subclassificationCode" caption="Subclassification Code" visible={this.isSubclassificationCodeVisible} cellRender={function (options) { return (<div title={options.row.data.subclassificationCodeMessage} style={{ backgroundColor: `${options.row.data.isSubclassificationCodeValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.subclassificationCode}&nbsp;</div>); }} />
                <Column dataField="subjectID" caption="Subject ID" cellRender={function (options) { return (<div title={options.row.data.subjectIDMessage} style={{ backgroundColor: `${options.row.data.isSubjectIDValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.subjectID}&nbsp;</div>); }} />

                <Column type="buttons">
                    <GridButton name="edit" text="Correct and Import" visible={this.canCorrectAndImport} />
                    <GridButton text="Mark Processed" onClick={this.markProcessed} visible={!this.IsReadOnly()} />
                    <GridButton text="Mark Rejected" onClick={this.markRejected} visible={this.canRejected} />
                </Column>
            </DataGrid>
        );
    }
}

class ChangeRequestDetail extends BaseComponent {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.dataSource = props.data.row.data.requestList;

        this.state = {
            isHandlingChangeRequest: false,
            changeRequestHandleId: null,
            changeRequestProcessingResults: {},

            isMovingPlacement: false,
            placementToInactivate: {
                id: null,
                filteredPlacementStatuses: [],
                selectedPlacementStatusId: null,
                isPlacementStatusReadOnly: false,
                cancellationNotes: null,
                comments: null
            }
        }
    }

    hideMoveDialog = () => {
        this.setState({
            isMovingPlacement: false,
            placementToInactivate: {
                id: null,
                filteredPlacementStatuses: [],
                selectedPlacementStatusId: null,
                isPlacementStatusReadOnly: false,
                cancellationNotes: null,
                comments: null
            }
        });
    }

    makePlacementInactive = async (e) => {
        //console.log('Making placement inactive', e);

        var workOrderHistory = await this.FetchAPI('PlacementHistory/GetWorkOrdersByPlacementId/' + e.row.data.placementId);

        //console.log(workOrderHistory);

        var otherRequiredStatus = null;

        workOrderHistory.map((item) => {
            //console.log(this.props.workOrder.id);
            //console.log(item.workOrderID);
            //console.log(this.props.workOrder.id == item.workOrderID);

            if (item.workOrderID != e.row.data.workOrderID) {
                console.log('NOT the same work order');
                if (item.rosterStatus == 'Active' && item.workOrderType == 'Regular') {
                    // TODO: Test
                    otherRequiredStatus = 'Dispatched';
                }

                if (item.rosterStatus == 'Active' && item.workOrderType == 'Support') {
                    // TODO: Test
                    otherRequiredStatus = 'Added On Support';
                }
            }
        });

        //console.log(otherRequiredStatus);

        var filterPlacementStatuses = placementStatuses;
        var defaultPlacementStatus = null;

        if (!otherRequiredStatus) {
            // Filter to dispatched or canceled
            filterPlacementStatuses = filterPlacementStatuses.filter((item) => item.id == 0 || item.id == 3);
        }
        else if (otherRequiredStatus == 'Added On Support') {
            filterPlacementStatuses = filterPlacementStatuses.filter((item) => item.id == 2);
            defaultPlacementStatus = 2;
        }
        else if (otherRequiredStatus == 'Dispatched') {
            filterPlacementStatuses = filterPlacementStatuses.filter((item) => item.id == 1);
            defaultPlacementStatus = 1;
        }

        // Attempt to look up the change reasons
        var reasonCode = inactiveReasons.find((item) => item.name == this.state.changeRequestProcessingData.changeReasonName);

        /*console.log('Determined reason code', reasonCode);*/

        // TODO: ************ For single UCs versus FGs vs FAMUS, need to handle possible split scenarios

        // TODO: Disable/enable the select box
        // TODO: Hide/show/require the cancelation notes based on selection
        // Look across assignment history and look for -- 1) During cancelation, pick the resulting status
        // - If placement is in no other work order list, enabled, allow canceled or not dispatched
        // - If placement is in another work order list as dispatched, only allow dispatched
        // - If placement is in another work order list as added on support, only allow added on support?
        this.setState({
            isMovingPlacement: true,
            placementToInactivate: {
                id: e.row.data.placementId,
                workOrderId: e.row.data.workOrderID,
                filteredPlacementStatuses: filterPlacementStatuses,
                selectedPlacementStatusId: defaultPlacementStatus,
                isPlacementStatusReadOnly: defaultPlacementStatus != null,
                reasonCode: reasonCode.code,
                comments: this.state.changeRequestProcessingData.notes
            }
        });

        //console.log('RIGHT HERE', this.state.placementToInactivate);
    }

    saveInactivePlacement = async () => {
        //console.log('RIGHT HERE TWO', this.state.placementToInactivate);
        var result = this.editFormControl.instance().validate();

        if (result.isValid) {

            var placementToMoveDetails = this.state.placementToInactivate;

            // console.log(placementToMoveDetails);
            //console.log(this.state.changeRequestProcessingData);

            // changeRequestProcessingData has the customer id, need to do the transport unit checking here
            var underlyingCustomerId = this.state.changeRequestProcessingData.customerId;

            placementToMoveDetails.customerId = underlyingCustomerId;

            const result = await this.PostAPI('WorkOrderResourcesCustomers/InactivatePlacement', placementToMoveDetails);

            //console.log(result);

            if (result.status == 1) {

                this.setState({
                    isMovingPlacement: false,
                    placementToInactivate: {
                        id: null,
                        filteredPlacementStatuses: [],
                        selectedPlacementStatusId: null,
                        isPlacementStatusReadOnly: false,
                        cancellationNotes: null,
                        comments: null
                    }
                });

                var changeRequestHandleId = this.state.changeRequestHandleId;

                var requestHandlerData = await this.FetchAPI('PlacementChangeRequest/GetPlacementChangeData?changeRequestId=' + changeRequestHandleId);

                this.setState({
                    loading: false,
                    changeRequestProcessingResults: requestHandlerData,
                    changeRequestHandleId: changeRequestHandleId
                });
            }
            else {
                //console.log(result);
                alert('Failed. Please try again later.');
            }

            //this.setState({
            //    loading: true
            //});
        }
    }

    viewChangeRequestHandler = async (e) => {

        console.log(e.row.data);

        // Here, we need to look at the underlying data and determine what is allowable
        var changeRequestHandleId = e.row.data.id;

        this.setState({
            loading: true,
            isHandlingChangeRequest: true
        });

        var requestHandlerData = await this.FetchAPI('PlacementChangeRequest/GetPlacementChangeData?changeRequestId=' + changeRequestHandleId);

        console.log('Processing', requestHandlerData);

        if (requestHandlerData.placementList != null) {
            requestHandlerData.placementList.map((placement) => {
                if (placement.transportUnitCode != 'Family') {
                    // Find the customer we're choosing to handle here
                    var customerRow = placement.customerList.find((item) => item.id == e.row.data.customerId);

                    console.log('Customer', customerRow);

                    if (customerRow && !customerRow.relationCode) {
                        console.log('Customer not related to other members in the group, need to filter');
                        placement.customerList = [customerRow];
                    }
                }
            })
        }

        this.setState({
            loading: false,
            changeRequestProcessingResults: requestHandlerData,
            changeRequestProcessingData: e.row.data,
            changeRequestHandleId: changeRequestHandleId
        });
    }

    markRejected = async (e) => {
        //console.log('Saving...', e);

        await this.setState({
            loading: true
        });

        //console.log(e);

        var newRecord = this.state.changeRequestProcessingData;

        newRecord.statusCode = 'NOTAPPROVED';

        try {
            const result = await this.PostAPI('PlacementChangeRequest', newRecord);

            //console.log(result);

            if (result.status != 1) {
                alert('Failed. Please try again later.');
            }
            else {
                changeRequestParentDataGrid.instance().refresh();

                this.setState({
                    isHandlingChangeRequest: false,
                    changeRequestHandleId: null,
                    changeRequestProcessingData: {},
                    changeRequestProcessingResults: {}
                });
            }
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    markProcessed = async (e) => {
        //console.log(e);

        var newRecord = this.state.changeRequestProcessingData;

        newRecord.statusCode = 'PROCESSED';

        const result = await this.PostAPI('PlacementChangeRequest', newRecord);

        //console.log(result);

        if (result.status != 1) {
            alert('Failed. Please try again later.');
        }
        else {
            changeRequestParentDataGrid.instance().refresh();

            this.setState({
                isHandlingChangeRequest: false,
                changeRequestHandleId: null,
                changeRequestProcessingData: {},
                changeRequestProcessingResults: {}
            });
        }
    }

    hideChangeRequestHandler = (e) => {
        this.setState({
            isHandlingChangeRequest: false,
            changeRequestHandleId: null,
            changeRequestProcessingData: {},
            changeRequestProcessingResults: {}
        });
    }

    canInactivate = (e) => {
        //console.log(e);

        return e.row.data.rosterStatus == 'Active';
    }

    canCancel = (e) => {
        //console.log(e);

        var hasWorkOrderActions = this.state.changeRequestProcessingResults && this.state.changeRequestProcessingResults.workOrderList && this.state.changeRequestProcessingResults.workOrderList.some(item => item.rosterStatus == 'Active');

        //console.log('Has Work Order Actions', hasWorkOrderActions);

        return !hasWorkOrderActions && e.row.data.statusID != 3;
    }

    customizeItem = (item) => {
        if (item.dataField === 'cancellationNotes') {
            //console.log('Customize Item', item);
            //console.log(this.state.placementToInactivate);

            item.visible = this.state.placementToInactivate.selectedPlacementStatusId == 3;
            item.isRequired = this.state.placementToInactivate.selectedPlacementStatusId == 3;
        }
    }

    onFieldDataChanged = async (e) => {
        // console.log('Field data changed...', e);

        e.component.repaint();
    }

    pickupDateRender = function (options) {

        //console.log(options);

        if (options.data.pickupStatusCode == 'ACTIVE') {
            return (moment(options.value).format('MM/DD/yyyy HH:mm'));
        }
        else {
            return 'N/A';
        }
    }

    render() {

        return (
        <>
            <DataGrid dataSource={this.dataSource}
                keyExpr="id" ref={ref => this.changeRequestDataGrid = ref} allowColumnResizing={true}
                showBorders={true} onExporting={this.onExporting}>
                <Export enabled={true} fileName={"placementChangeRequests"} />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 20]}
                    showInfo={true} />
                <FilterRow visible={true} />

                <Column dataField="willImport" caption="Will Import?" cellRender={function (options) { return (<img width='20px' height='20px' src={options.row.data.willImport ? 'yesbutton.png' : 'nobutton.png'} />); }} />
                <Column dataField="aNumber" caption="A Number" cellRender={function (options) { return (<div title={options.row.data.aNumberMessage} style={{ backgroundColor: `${options.row.data.isANumberValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.aNumber}&nbsp;</div>); }} />

                <Column caption="Subject ID" dataField="subjectID" />
                <Column caption="Location Name" dataField="locationName" />
                <Column caption="Change Reason" dataField="changeReasonName" />
                <Column caption="Notes" dataField="notes" />
                <Column caption="Status" dataField="statusCode" />
                <Column caption="Updated By" dataField="lastUpdateUser" />
                <Column caption="Updated At" dataField="lastUpdateDate" dataType="datetime" format="MM/dd/yy, HH:mm" />

                    <Column type="buttons">
                        <GridButton text="Handle Request" onClick={this.viewChangeRequestHandler} visible={!this.IsReadOnly()} />
                </Column>
            </DataGrid>

            <Popup
                    visible={this.state.isHandlingChangeRequest}
                    onHiding={this.hideChangeRequestHandler}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    title="Handle Change Request">
                    <ScrollView width='100%' height='100%'>


                        <Form formData={this.state.changeRequestProcessingData}>
                            <Item itemType="group" colCount={2}>
                                <Item dataField="createDate" editorType="dxDateBox" editorOptions={{ readOnly: true, type: 'datetime', displayFormat: "MM/dd/yy, HH:mm" }}>
                                    <Label text="Request Date" />
                                </Item>
                                <Item dataField="locationName" editorOptions={{ readOnly: true }}>
                                    <Label text="Location Name" />
                                </Item>
                            </Item>
                        </Form>
                        <br />

                        {this.state.changeRequestProcessingResults &&
                            <DataGrid dataSource={this.state.changeRequestProcessingResults.workOrderList} showBorders={true} allowColumnResizing={true} visible={this.state.changeRequestProcessingResults.status != -2}>
                                <Column dataField="workOrderNumber" />
                                <Column dataField="workOrderName" />
                                <Column dataField="departureCityName" />
                                <Column dataField="destination" />
                                <Column dataField="planPickupDate" dataType="datetime" cellRender={this.pickupDateRender} />
                                <Column dataField="status" caption="Work Order Status" />
                                <Column dataField="rosterStatus" caption="Roster Status" />
                                <Column dataField="reason" caption="Reason" />
                            <Column dataField="comments" caption="Comments" />
                            <Column type="buttons">
                                <GridButton text="Inactivate Placement" onClick={this.makePlacementInactive} visible={this.canInactivate} />
                            </Column>
                            </DataGrid>
                        }
                        <br />
                        {this.state.changeRequestProcessingResults &&
                            <DataGrid dataSource={this.state.changeRequestProcessingResults.placementList} showBorders={true} allowColumnResizing={true} visible={this.state.changeRequestProcessingResults.status != -2}
                                ref={ref => this.placementListDataGrid = ref}>

                                <Column caption="Name" dataField="placementShortName" />
                                <Column caption="Placement Date" dataField="orrPlacementDate" dataType="datetime" format="MM/dd/yy, HH:mm '(Local)'" />
                                <Column caption="Movement Type" dataField="movementType" />
                                <Column caption="Transport Type" dataField="transportUnit" />
                                <Column caption="Departure City" dataField="departureCity" />
                                <Column caption="Pickup Location" dataField="pickupLocationName" />
                                <Column caption="Destination" dataField="destinationName" />
                                <Column caption="DHS Custody Date" dataField="dhsCustodyDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                                <Column caption="Subclassification Code" dataField="subclassificationName" />
                                <Column caption="Status" dataField="statusName" />
                                <Column type="buttons">
                                    <GridButton text="Cancel Placement" onClick={this.processCancelation} visible={this.canCancel} />
                                </Column>
                                <MasterDetail enabled={true} autoExpandAll={true} component={PlacementDetail} />
                            </DataGrid>
                        }

                        <p>{this.state.changeRequestProcessingResults.message}</p>

                        <br />
                        <div className="d-flex justify-content-end">
                            {/*<Button text="Save" onClick={this.SaveCustomer} />*/}
                            {/*&nbsp;*/}
                            {/*{this.state.changeRequestProcessingResults && this.state.changeRequestProcessingResults.status == -2 &&*/}
                            {/*    <Button text="Reject Change Request" onClick={this.markRejected} />*/}
                            {/*}*/}
                            {/*&nbsp;*/}
                            <Button text="Mark Processed" onClick={this.markProcessed} />
                            &nbsp;
                            <Button text="Mark Rejected" onClick={this.markRejected} />
                            &nbsp;
                            <Button text="Cancel" onClick={this.hideChangeRequestHandler} />
                        </div>
                        <br />
                    </ScrollView>
                    <br />
                </Popup>

                <Popup visible={this.state.isMovingPlacement} onHiding={this.hideMoveDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={475} height={350}>

                    <p>Are you sure you want to move this Placement to the inactive roster?</p>
                    <Form ref={ref => this.editFormControl = ref} onFieldDataChanged={this.onFieldDataChanged}
                        id="form" formData={this.state.placementToInactivate} colCount={1} customizeItem={this.customizeItem}>
                        <Item editorType="dxSelectBox" dataField="reasonCode" editorOptions={{
                            searchEnabled: true,
                            dataSource: inactiveReasons,
                            displayExpr: 'name',
                            valueExpr: 'code'
                        }}>
                            <RequiredRule />
                        </Item>
                        <Item editorType="dxTextArea" dataField="comments" editorOptions={{
                            maxLength: 1000
                        }}>
                            <RequiredRule />
                            <Label text="Reason" />
                        </Item>

                        <Item editorType="dxSelectBox" dataField="selectedPlacementStatusId" editorOptions={{
                            searchEnabled: true,
                            dataSource: this.state.placementToInactivate.filteredPlacementStatuses,
                            displayExpr: 'name',
                            valueExpr: 'id',
                            readOnly: this.state.placementToInactivate.isPlacementStatusReadOnly
                        }}>
                            <RequiredRule />
                            <Label text="New Placement Status" />
                        </Item>

                        <Item editorType="dxTextArea" dataField="cancellationNotes" editorOptions={{
                            maxLength: 1000
                        }}>
                            <Label text="Cancelation Notes" />
                        </Item>
                    </Form>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Yes" onClick={this.saveInactivePlacement} />
                        &nbsp;&nbsp;
                        <Button text="No" onClick={this.hideMoveDialog} />
                    </div>
                </Popup>
                </>
        );
    }
}

class PlacementDetail extends BaseComponent {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        
        this.dataSource = new DataSource({
            key: 'id',
            load: (loadOptions) => {
                return props.data.row.data.customerList;
            }
        })

        this.transportUnitCode = props.data.row.data.transportUnitCode;
        //this.placementId = props.data.row.data.id;
        //this.workOrderId = props.data.row.data.workOrderID;
        //this.workOrderStatusId = props.data.row.data.workOrderStatusId;
        //this.placementShortName = props.data.row.data.placementShortName;
        //this.active = !props.data.row.data.inactiveReason;
        //if (this.transportUnitCode == 'UC' && props.data.row.data.customerList.length > 1 && this.active)
        //    this.selectionMode = 'multiple';
        //else
        //    this.selectionMode = 'none';
    }
    
    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true}>
                <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                <Column caption="First Name" dataField="firstName" />
                <Column caption="Middle Name" dataField="middleName" />
                <Column caption="Last Name" dataField="lastName" />
                <Column caption="Age" dataField="age" />
                <Column caption="Gender" dataField="gender" />
                <Column caption="Nationality" dataField="nationality" />
                <Column caption="Relation" dataField="relationCode" visible={this.transportUnitCode == "UC"} />
            </DataGrid>
        );
    }
}
