
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, FormItem } from 'devextreme-react/data-grid';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import * as $ from 'jquery'

const itemOtherItems = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('InventoryItemOtherItems');
    },
    insert: (values) => {
        return PostAPIPromise('InventoryItemOtherItems', values);
    },
    remove: (key) => {
        return DeleteAPIPromise('InventoryItemOtherItems/' + key)
            .then((result) => {
                if (result.status == -2) {
                    alert('Item is referenced in the system and cannot be deleted. Consider inactivating.')
                }
            });
    },
    update: (key, values) => {
        return PostAPIPromise('InventoryItemOtherItems', values);
    }
});

export class InventoryItemOtherItemsContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    saleAmountFormat = { style: 'currency', currency: 'USD', useGrouping: true, minimumSignificantDigits: 3 };

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    render() {
        return (
            <div style={{ margin: "10px" }}>
                <DataGrid dataSource={itemOtherItems}
                    showBorders={true} allowColumnResizing={true}
                    keyExpr="id" onRowUpdating={this.onRowUpdating}>
                    <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()} />
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <Column caption="Item Number" dataField="itemNumber" >
                        <RequiredRule />
                        <StringLengthRule max={50} />
                    </Column>
                    <Column caption="Description" dataField="description" >
                        <RequiredRule />
                        <StringLengthRule max={255} />
                    </Column>
                    <Column caption="Cost" dataField="cost" format={this.saleAmountFormat} >
                        <FormItem editorType="dxNumberBox" editorOptions={{ format: '$ #,##0.##' }} />
                    </Column>
                </DataGrid>
            </div>
        );
    }
}