
import React from 'react';
import PropTypes from 'prop-types';
import * as $ from 'jquery'
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent.js';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Column, Editing, Lookup, FormItem, PatternRule, RequiredRule, StringLengthRule, Export } from 'devextreme-react/data-grid';

export class WorkOrderResourcesHotelStaysContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.workOrderName = props.workOrder.workOrderName;
        this.state = {
            cityList: [],
            reasonCodes: [],
            facilityList: []
        };
        this.roomPattern = /^[A-Z0-9]+((,|-)[A-Z0-9]+)*[A-Z0-9]+$/;
    }

    workOrderHotelStays = new CustomStore({
        key: 'documentNumber',
        load: (loadOptions) => {
            console.log('Load', loadOptions);
            console.log(this.props.workOrder.id);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderResourcesHotelStays/' + this.props.workOrder.id);
            }
        },
        insert: (values) => {
            console.log('Insert');
            values.workOrderId = this.props.workOrder.id;
            console.log(values);
            return PostAPIPromise('WorkOrderResourcesHotelStays', values);
        },
        remove: (key) => {
            console.log(key);

            return DeleteAPIPromise('WorkOrderResourcesHotelStays/' + key);
        },
        update: (key, values) => {
            return PostAPIPromise('WorkOrderResourcesHotelStays', values);
        }
    });

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    GetData = async () => {
        var reasonData = await this.FetchAPI('DictionaryHotelStayReason/GetAllActive');

        var cityData = await this.FetchAPI('DictionaryCity');

        //console.log('Cities', cityData);

        var facilityData = await this.FetchAPI('HotelFacility');

        //console.log('Hotels', facilityData);

        // TODO: Not 100% sure how these are going to work quite yet
        //if (this.props.workOrder.statusID < 4) {
        //    allowAdd = true;
        //    allowEdit = true;
        //    allowDel = true;
        //}

        await this.setState({
            cityList: cityData,
            reasonCodes: reasonData,
            facilityList: facilityData
        });
    }

    componentIsReadOnly = () => {
        return this.IsReadOnly() || this.props.workOrder.statusID > 1;
    }

    render() {
        //console.log("Beginning render");
        //console.log('Work Order ', this.props.workOrder);
        return (
            <div style={{ margin: "10px" }}>

                <DataGrid  onRowUpdating={this.onRowUpdating} dataSource={this.workOrderHotelStays} showBorders={true} allowColumnResizing={true}
                    ref={ref => this.dataGrid = ref} key="documentNumber" onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"customerHotelStays" + this.workOrderName} />
                    <Editing mode="popup" allowDeleting={!this.componentIsReadOnly()} allowAdding={!this.componentIsReadOnly()} allowUpdating={!this.componentIsReadOnly()} />
                    <FilterRow visible={true} />
                    <Column caption="Facility Name" dataField="hotelFacilityId" >
                        <Lookup allowClearing={true} dataSource={this.state.facilityList} valueExpr="id" displayExpr="name" />
                        <RequiredRule message="Please select Facility." />
                    </Column>
                    <Column caption="Facility City" dataField="facilityCity" >
                        <Lookup dataSource={this.state.cityList} valueExpr="cityCode" displayExpr="cityName" />
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Room Identifier" dataField="roomIdentifier" >
                        <RequiredRule message="Please input Room Identifier" />
                        <PatternRule message="Room Identifier must be a comma-delimited string of room numbers with no spaces."
                            pattern={this.roomPattern} />
                        <StringLengthRule max={10} message="May have no more than 10 characters" />
                    </Column>
                    <Column caption="Plan Check In" dataField="planCheckInDate" dataType="datetime" format="MM/dd/yyyy HH:mm" >
                        <RequiredRule message="Please input Plan Check In Date." />
                    </Column>
                    <Column caption="Plan Check Out" dataField="planCheckOutDate" dataType="datetime" format="MM/dd/yyyy HH:mm" >
                        <RequiredRule message="Please input Plan Check Out Date." />
                    </Column>
                    <Column caption="Reason" dataField="reasonCode">
                        <Lookup allowClearing={true} dataSource={this.state.reasonCodes} valueExpr="code" displayExpr="name" />
                        <RequiredRule />
                    </Column>
                </DataGrid>
                <br />
            </div>
        );
    }
}