
import {
    ArgumentAxis,
    Chart,
    CommonSeriesSettings,
    Legend,
    Series,
    TickInterval,
    ValueAxis
} from 'devextreme-react/chart';
import Form, { GroupItem, Item, Label } from 'devextreme-react/form';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';

import { FetchAPIPromise } from '../BaseComponent';

function HoursOfServiceLog(inputs) {

    const visualRange = {
        visualRange: {
            startValue: new Date(2024, 7, 24, 0, 0),
            endValue: new Date(2024, 7, 25, 0, 0)
        }
    };

    const [hoursOfServiceInformation, setHoursOfServiceInformation] = useState([]);

    const [totalDrivingTime, setTotalDrivingTime] = useState(0);
    const [totalOnDutyTime, setTotalOnDutyTime] = useState(0);
    const [totalOffDutyTime, setTotalOffDutyTime] = useState(0);

    useEffect(() => {
        async function fetchData() {

            //console.log('Incoming log id', inputs.logId);
            const hosInformation = await FetchAPIPromise('HoursOfService/GetHoursOfServiceInformation?id=' + inputs.logId);

            var localDrivingTime = 0;
            var localOnDutyTime = 0;
            var localOffDutyTime = 0;

            hosInformation.statuses.map((item) => {
                //console.log(moment.parseZone(item.beginTime));
                var beginTime = moment.parseZone(item.beginTime);
                var endTime = moment.parseZone(item.endTime);
                item.beginTime = new Date(beginTime.year(), beginTime.month(), beginTime.date(), beginTime.hour(), beginTime.minute());
                item.endTime = new Date(endTime.year(), endTime.month(), endTime.date(), endTime.hour(), endTime.minute());

                if (item.status == 'OnDutyNotDriving') {
                    localOnDutyTime += endTime.diff(beginTime, 'minute') / 60;
                }
                else if (item.status == 'OffDuty') {
                    localOffDutyTime += endTime.diff(beginTime, 'minute') / 60;
                }
                else if (item.status == 'Driving') {
                    localDrivingTime += endTime.diff(beginTime, 'minute') / 60;
                }
            });

            // console.log(existingHosLogsData);

            setHoursOfServiceInformation(hosInformation);

            setTotalDrivingTime(localDrivingTime);
            setTotalOffDutyTime(localOffDutyTime);
            setTotalOnDutyTime(localOnDutyTime);
        }

        fetchData();
    }, [inputs]);

    const homeTerminalRender = ((cell) => {
        console.log(cell);
        return <>{hoursOfServiceInformation.homeTerminalAddress}<br />{hoursOfServiceInformation.homeTerminalCity}, {hoursOfServiceInformation.homeTerminalState} {hoursOfServiceInformation.homeTerminalZip}</>;
    });

    return (

        <div className="container-fluid">
            <h1>Hours of Service Log</h1>
            <br />

            <Form
                id="form"
                width={750} colCount={3}
                formData={hoursOfServiceInformation}>
                <Item dataField="date" editorType="dxDateBox" editorOptions={{
                    readOnly: true, displayFormat: "MM/dd/yyyy", type: 'datetime'
                }}>
                    <Label location="top" text="MONTH DAY YEAR" showColon={false} />
                </Item>

                <Item colSpan={2} dataField="separatedVehicleNumbers">
                    <Label location="top" text="VEHICLE NUMBER(S)" showColon={false} />
                </Item>

                <Item colSpan={3} />

                <Item colSpan={3} render={homeTerminalRender}>
                    <Label location="top" text="HOME TERMINAL ADDRESS" showColon={false} />
                </Item>
            </Form>
            <br />

            <Chart rotated={true} height={300} width={750}
                id="chart"
                dataSource={hoursOfServiceInformation.statuses}
            >
                <CommonSeriesSettings
                    argumentField="status"
                    type="rangebar"
                    minBarSize={2}
                />
                <Series
                    rangeValue1Field="beginTime"
                    rangeValue2Field="endTime"
                    name="ANS West Coast"
                />

                <ArgumentAxis visualRange={visualRange} inverted={true}>
                </ArgumentAxis>
                <ValueAxis visualRange={visualRange}>
                    <TickInterval hours={1} />
                </ValueAxis>

                <Legend visible={false} />
            </Chart>
            <br />
            <p>Total Driving Time: {totalDrivingTime}</p>
            <p>Total On Duty Time: {totalOnDutyTime}</p>
            <p>Total Off Duty Time: {totalOffDutyTime}</p>
            <br />
        </div>
    );
}

export default HoursOfServiceLog;

/*
    1) Date
    2) Vehicle Numbers
    3) Carrier name
    4) Main office address
    5) Home terminal address (responsible office, technically could be more than one but I doubt it in a 24 hour period. Probably just take the first one)
    6) Total miles driving today (Actual driving miles)
    7) Total mileage today (All miles driven on the vehicle(s) for that day)
    8) Certification
    9) Co-driver(s) name(s)
*/