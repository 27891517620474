import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import PropTypes from 'prop-types';
import DataGrid, { FilterRow, Form, Label, Editing, Column, Pager, Paging, GroupPanel, Export } from 'devextreme-react/data-grid';
import BaseComponent, { FetchAPIPromise, DeleteAPIPromise, PostAPIPromise } from '../BaseComponent';
import { SimpleItem, Item, RequiredRule } from 'devextreme-react/form';
import DataSource from 'devextreme/data/data_source';
import * as $ from 'jquery';

const currencyFormat = {
    type: 'currency',
    precision: 2
};

export class WorkOrderResourcesMealsContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.workOrderName = props.workOrder.workOrderName;
        this.state = {
            customers: [],
            locations: null,
            dates: []
        };
    }

    mealsDataSource = new CustomStore({
        key: 'id',
        load: () => {
            console.log('Loading data for Meals');
            console.log(this.props.workOrder.id);
            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderResourcesMeals/' + this.props.workOrder.id);
            }
        },
        insert: (values) => {
            console.log('Values');
            console.log(values);
            this.itemDataGrid.instance().saveEditData();
            this.customerMeals = [];
            this.mealsData = {};
            return;
        },
        remove: (key) => {
            console.log('Remove from WO and Add to Inventory');
            console.log(key);
            return DeleteAPIPromise('WorkOrderResourcesMeals/DeleteCustomerMeal/' + key);
        }
    });

    mealItemDataSource = new CustomStore({
        load: () => {
            console.log('Loading data');
            return FetchAPIPromise('InventoryMealsManagement');
        },
        update: (key, values) => {
            console.log('Add items');
            values.customerIds = this.customerMeals;
            values.dates = this.mealsDates;
            values.workOrderId = this.props.workOrder.id;
            console.log(values);
            if (values.quantityToRemove > 0) {
                console.log('Remove');
                return PostAPIPromise('WorkOrderResourcesMeals', values);
            }
        }
    });

    componentDidUpdate(prevProps) {
        console.log('Props Updated');

        if (this.props.workOrder != prevProps.workOrder) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.fetchData();
        }
    }

    customerMeals = [];

    mealsDates = [];

    mealsData = {};

    locationChanged = async (args) => {
        this.mealsData.location = args.value;

        if (args.value == null) {
            this.itemDataGrid.instance().clearFilter();
        }
        else {
            this.itemDataGrid.instance().clearFilter();
            this.itemDataGrid.instance().filter(['locationId', '=', args.value]);
        }

        this.itemDataGrid.instance().repaint();
        this.itemDataGrid.instance().cancelEditData();
    }

    selectionChanged = (e) => {
        this.customerMeals = e.value;
    }

    dateChanged = (e) => {
        this.mealsDates = e.value;
    }

    GetDropDownData = async () => {
        const customersData = await this.FetchAPI('WorkOrderResourcesCustomers/GetFlattenedCustomerList/' + this.props.workOrder.id);
        const locationData = await this.FetchAPI('Location/GetWithOfficeName');
        const workOrderDates = await this.FetchAPI('WorkOrder/GetWorkOrderDates/' + this.props.workOrder.id);

        console.log(workOrderDates);

        const groupedLocations = new DataSource({
            store: locationData,
            key: 'id',
            group: 'officeName'
        });

        await this.setState({
            customers: customersData,
            locations: groupedLocations,
            dates: workOrderDates
        });
    }

    onContentReady = (e) => {
        if (this.itemDataGrid) {
            var filter = this.itemDataGrid.instance().getCombinedFilter();
            if (filter == undefined) {
                this.itemDataGrid.instance().filter(['locationId', '=', 0]);
            }
        }
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.visible = false;
    }

    onRowUpdated = (e) => {
        this.mealsDataSource.load();
        this.dataGrid.instance().refresh();
        this.dataGrid.instance().repaint();
    }

    componentIsReadOnly = () => {
        return this.IsReadOnly() || this.props.workOrder.statusID > 1;
    }

    render() {
        return (
            <div style={{ margin: "10px" }}>
                <DataGrid dataSource={this.mealsDataSource} showBorders={true} allowColumnResizing={true} ref={ref => this.dataGrid = ref} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"meals" + this.workOrderName} />
                    <GroupPanel visible={true} />
                    <Editing mode="popup" allowAdding={(this.props.workOrder.statusID < 2 ? true : false) && !this.componentIsReadOnly()} allowDeleting={(this.props.workOrder.statusID < 2 ? true : false) && !this.componentIsReadOnly()} texts={{
                        confirmDeleteMessage: 'Are you sure you want to delete this record and return items to inventory?'
                    }}>
                        <Form colCount={1}>
                            <SimpleItem ref={ref => this.tagBox = ref} editorType="dxTagBox" editorOptions={{
                                dataSource: this.state.customers, onValueChanged: this.selectionChanged, showSelectionControls: true, valueExpr: 'customerID', displayExpr: function (item) {
                                    return item ? item.firstName + ' ' + item.lastName + ' Age: ' + item.age + ' Gender: ' + item.gender : null;
                                }
                            }}>
                                <Label text="Customer(s)" />
                                <RequiredRule />
                            </SimpleItem>
                            <Item dataField="time" editorType="dxTagBox" editorOptions={{ dataSource: this.state.dates, showSelectionControls: true, valueExpr: 'date', displayExpr: 'dateString', onValueChanged: this.dateChanged }} >

                                <RequiredRule />
                            </Item>
                            <Item dataField="location_code" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, dataSource: this.state.locations, grouped: true, valueExpr: 'id', displayExpr: 'name', onValueChanged: this.locationChanged }} >
                                <RequiredRule />
                            </Item>
                            <Item>
                                <DataGrid dataSource={this.mealItemDataSource} sorting="none"
                                    ref={ref => this.itemDataGrid = ref} onRowUpdating={this.onRowUpdating}
                                    onRowUpdated={this.onRowUpdated} onToolbarPreparing={this.onToolbarPreparing}
                                    onContentReady={this.onContentReady} >
                                    <Editing mode="batch" allowUpdating={!this.componentIsReadOnly()} />
                                    <FilterRow visible={true} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 20]}
                                        showInfo={true} />
                                    <Column caption="Item No." dataField="itemNumber" allowEditing={false} />
                                    <Column caption="Name" dataField="itemDescription" allowEditing={false} />
                                    <Column caption="Price" dataField="price" format={currencyFormat} allowEditing={false} />
                                    <Column caption="Available Quantity" dataField="quantity" allowEditing={false} />
                                    <Column caption="Quantity to Remove" dataField="quantityToRemove" editorOptions={{ min: 0 }}>
                                    </Column>
                                </DataGrid>
                            </Item>
                        </Form>
                    </Editing>
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} visible={true} />
                    <Column caption="Customer Name" dataField='customerName' groupIndex={0} />
                    <Column caption="Date" dataField="time" dataType="date" format="MM/dd/yy" />
                    <Column caption="Item No." dataField='itemNumber' />
                    <Column caption="Item Name" dataField='itemName' />
                    <Column caption="Price" dataField='totalFee' format={currencyFormat} />
                    <Column caption="Quantity" dataField='count' />
                </DataGrid>
            </div>
        );
    }
}