export const msalConfig = {
    auth: {
        authority: process.env.REACT_APP_AUTHORITY,
        clientId: process.env.REACT_APP_CLIENTID,
        redirectUri: document.getElementById('root').baseURI
    },
    cache: {
        cacheLocation: "localStorage"
    }
};

