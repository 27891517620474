import React, { useEffect, useState, ReactNode } from 'react';
import { useParams } from 'react-router-dom';

// @ts-ignore
import { FetchAPIPromise, PostAPIPromise, withParams, isAccessReadOnly, formatComponentPageName } from '../BaseComponent.js';
//import TabPanel, { Item } from 'devextreme-react/tab-panel';
//import Button from 'devextreme-react/button';
//import List, { ItemDragging } from 'devextreme-react/list';
//import { Link } from 'react-router-dom';
//import DataGrid, { FilterRow, Column, GroupPanel, Selection, MasterDetail, Button as GridButton, Editing, Export } from 'devextreme-react/data-grid';
//import { Map as ReactMap } from 'devextreme-react/map';
//import CustomStore from 'devextreme/data/custom_store';
//import DropDownButton from 'devextreme-react/drop-down-button';
//import { Tooltip, ITooltipOptions } from 'devextreme-react/tooltip';
//import TextBox from 'devextreme-react/text-box';

//import DataGrid, { FilterRow, Button as GridButton, Pager, Paging, Column, MasterDetail } from 'devextreme-react/data-grid';
import Form, { EmptyItem, Item, Label, RequiredRule, StringLengthRule, CustomRule, FormTypes } from 'devextreme-react/form';
//import { LoadPanel } from 'devextreme-react/load-panel';
//import { Link } from 'react-router-dom';
//import Button from 'devextreme-react/button';
//import { alert, confirm } from 'devextreme/ui/dialog';
//import { Popup } from 'devextreme-react/popup';
//import CustomStore from 'devextreme/data/custom_store';
//import { flushSync } from 'react-dom'; // Note: react-dom, not react
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
//import ScrollView from 'devextreme-react/scroll-view';
//import { WorkOrderDetailsMSWOCoverageContainer } from './WorkOrderDetailsMSWOCoverageContainer.js';


//import { WorkOrderResourcesEquipmentContainer } from './WorkOrderResourcesEquipmentContainer.js';
//import { WorkOrderResourcesHotelStaysContainer } from './WorkOrderResourcesHotelStaysContainer.js';
//import { WorkOrderResourcesCustomersContainer } from './WorkOrderResourcesCustomersContainer.js';
//import { WorkOrderResourcesMealsContainer } from '../Inventory/WorkOrderResourcesMealsContainer';
//import { WorkOrderResourcesClothesContainer } from '../Inventory/WorkOrderResourcesClothesContainer.js';
//import { WorkOrderResourcesOtherContainer } from '../Inventory/WorkOrderResourcesOtherContainer.js';
//import { WorkOrderResourcesEmployeesContainer } from './WorkOrderResourcesEmployeesContainer.js';
//import { WorkOrderResourcesSupportStaffContainer } from './WorkOrderResourcesSupportStaffContainer.js';
//import WorkOrderResourcesGroundTransportationContainer from './WorkOrderResourcesGroundTransportationContainer.js';
//import { WorkOrderEmployeeHotelStaysContainer } from './WorkOrderEmployeeHotelStaysContainer.js';
//import { WorkOrderEmployeeFlightsContainer } from './WorkOrderEmployeeFlightsContainer.js';
//import { WorkOrderCustomerFlightsContainer } from './WorkOrderCustomerFlightsContainer.js';
//import { WorkOrderFlightsContainer } from './WorkOrderFlightsContainer.js';
//import WorkOrderTripStatusContainer from './WorkOrderTripStatusContainer.js';

//import WorkOrderPickupInfo from './WorkOrderPickupInfo.js';
//import WorkOrderDropoffInfo from './WorkOrderDropoffInfo.js';
import { UAC_WorkOrderDTO } from '../../types/uac_-work-order-dto.js';
import { IElementDescriptor } from 'devextreme-react/cjs/core/configuration/react/element.js';
import { UAC_WorkOrderPlanDatesDTO } from '../../types/uac_-work-order-plan-dates-dto.js';

function WorkOrderDetailsNew() {
    const params = useParams();

    const [workOrder, setWorkOrder] = useState<UAC_WorkOrderDTO>();
    //const [tripStages, setTripStages] = useState([]);

    //const [isViewingCustomersAndEmployees, setIsViewingCustomersAndEmployees] = useState(true);
    //const [isViewingMap, setIsViewingMap] = useState(false);

    //const [isViewingPickupLocation, setIsViewingPickupLocation] = useState(false);
    //const [currentPickupLocationId, setCurrentPickupLocationId] = useState(-1);

    //const [isViewingDropoffLocation, setIsViewingDropoffLocation] = useState(false);
    //const [currentDropoffLocationId, setCurrentDropoffLocationId] = useState(-1);

    //const [filters, setFilters] = useState({
    //    isShowingEmployeePlans: false,
    //    isShowingInactivePlans: false
    //});




    //constructor(props) {
    //    super(props);

    //    this.transferLocationChanged.bind(this);

    //    this.state = {
    //        workOrder: { workOrderName: null, planStartDate: null, planEndDate: null, statusID: null, departureCityName: null, trafficTypeCode: null, workOrderNumber: null, woNotes: null, transportUnit: null, workOrderType: null, destinationCityName: null, planPickupDate: null, planRemovalDate: null, transportMethodCode: null, statusID: null, supportWorkOrders: null, responsibleOfficeId: null },
    //        transportMethods: [],
    //        filteredTransportMethods: [],
    //        isActiveStatus: false,
    //        statuses: [],

    //        mvmTransferAutocompleteItems: [],

    //        offices: [],
    //        filteredOffices: [],

    //        trafficTypes: [],
    //        transportUnits: [],
    //        supportWorkOrderUACs: [],
    //        linkedRegularWorkOrders: [],
    //        departureCities: [],
    //        destinationCities: [],
    //        reviewStatuses: [],
    //        loading: false,
    //        isOutOfRatio: false,

    //        changeHistoryVisible: false,

    //        isViewingGroundTransitPlan: false,
    //        transitMetricsList: [],
    //        transitLegsList: [],

    //        QAQCIssuesVisible: false,
    //        QAQCIssuesList: [],
    //        QAQCAllowContinue: false
    //    };
    //    this.supportWorkOrderAOR = '';
    //}

    //componentDidMount = async () => {
    //    super.componentDidMount();
    //    //console.log('ComponentDidMount');

    //    const { type } = this.props.params;

    //    if (this.props.params.supportWorkOrderId) {
    //        var supportWorkOrderData = await this.FetchAPI('WorkOrder/GetSupportWorkOrderInfo/' + this.props.params.supportWorkOrderId);

    //        this.supportWorkOrderAOR = supportWorkOrderData.departureAOR;
    //    }

    //    await this.updateProperty('workOrder', 'workOrderType', type);

    //    await this.fetchData();
    //}

    //GetComponentPageName = () => {
    //    return (this.state.workOrder.workOrderNumber ? ("#" + this.state.workOrder.workOrderNumber + " WO Details") : 'Create New Work Order');
    //}





    useEffect(() => {
        async function fetchData() {
            //        var workOrderData = await FetchAPIPromise('WorkOrder/' + params.id);

            //        //console.log('Work Order', workOrderData);

            //        setWorkOrder(workOrderData);

            //        var employeeData = await FetchAPIPromise('WorkOrderResourcesEmployeeList/GetByWorkOrderIdSimple/' + workOrderData.id);

            //        //console.log(employeeData);

            //        var inactiveEmployeeData = await FetchAPIPromise('WorkOrderResourcesEmployeeList/GetInactiveEmployees/' + workOrderData.id);

            //        var customerData = await FetchAPIPromise('WorkOrderResourcesCustomers/GetFlattenedCustomerList/' + workOrderData.id);

            //        var inactiveCustomerData = await FetchAPIPromise('WorkOrderResourcesCustomers/GetFlattenedInactiveCustomerList/' + workOrderData.id);

            //        setEmployeeList(employeeData);
            //        setCustomerList(customerData);

            //        setInactiveCustomerList(inactiveCustomerData);
            //        setInactiveEmployeeList(inactiveEmployeeData);

            //        //var milestoneControlData = await this.FetchAPI('WorkOrderMilestone/GetActions');
            //        //milestoneControlData.controlOptionList = milestoneControlData.controlOptionList.filter(c => c.isActive == true);

            //        formatComponentPageName('#' + workOrderData.workOrderNumber + ' WO Resources');

            //        var itineraryData = await FetchAPIPromise('Itinerary/GetItineraryByWorkOrderId?workOrderId=' + params.id);

            //        //console.log('Itinerary Data', itineraryData);

            //        var workOrderMilestoneSteps = [];

            //        // First section, plan start date
            //        var beginDate = new Date(itineraryData.workOrderInfo.planStartDate);

            //        //console.log(beginDate);

            //        //// Add trip begin
            //        //this.addProperStepPosition(workOrderMilestoneSteps,
            //        //    {
            //        //        time: beginDate,
            //        //        code: "PREPARATION",
            //        //        name: "Prepare for Trip"
            //        //    });

            //        // Need to parse through the itinerary info for unique pickups
            //        const uniquePickupLocations = [...new Map(itineraryData.customerPlacementMappingInfo.map(item =>
            //            [item['pickupLocationId'], item])).values()];

            //        var pickupDate = beginDate;

            //        uniquePickupLocations.sort((a, b) => moment(a.planPickupDate).valueOf() - moment(b.planPickupDate).valueOf()).map((pickup) => {
            //            if (new Date(pickup.planPickupDate) > pickupDate) {
            //                pickupDate = new Date(pickup.planPickupDate);
            //            }

            //            addProperStepPosition(workOrderMilestoneSteps,
            //                {
            //                    time: new Date(pickup.planPickupDate),
            //                    code: "PICKUP",
            //                    name: "Pick Up UNCs",
            //                    itineraryStatus: pickup.itineraryStatus,
            //                    isCustomerItem: true,
            //                    transportUnit: workOrderData.transportUnit,
            //                    customerCount: customerData.filter((item) => itineraryData.customerPlacementMappingInfo.find((mapping) => mapping.pickupLocationId == pickup.pickupLocationId && mapping.placementId == item.orrPlacementId)).length,
            //                    itineraryItem: {
            //                        id: pickup.pickupLocationId,
            //                        placementId: pickup.placementId,
            //                        locationName: pickup.pickupLocationName
            //                    }
            //                });
            //        });

            //        pickupDate = new Date(pickupDate.getTime() + (1 * 60000));

            //        //this.addProperStepPosition(workOrderMilestoneSteps,
            //        //    {
            //        //        time: pickupDate,
            //        //        code: "GROUNDTRANSIT",
            //        //        name: "Ground Transit"
            //        //    });

            //        // Process hotel stays
            //        itineraryData.hotelStays.map((hotelStay) => {
            //            addProperStepPosition(workOrderMilestoneSteps,
            //                {
            //                    time: new Date(hotelStay.planCheckInDate),
            //                    code: "HOTELSTAY",
            //                    name: "Hotel Stay",
            //                    transportUnit: workOrderData.transportUnit,
            //                    // TODO
            //                    itineraryStatus: "ACTIVE",
            //                    // TODO
            //                    isCustomerItem: true,
            //                    itineraryItem: hotelStay
            //                });

            //            //this.addProperStepPosition(workOrderMilestoneSteps,
            //            //    {
            //            //        time: new Date(hotelStay.PlanCheckOutDate),
            //            //        code: "GROUNDTRANSIT",
            //            //        name: "Ground Transit"
            //            //    });
            //        });

            //        //console.log('itinerary', itineraryData);

            //        // Process Flights
            //        itineraryData.flights.map((flight) => {
            //            addProperStepPosition(workOrderMilestoneSteps,
            //                {
            //                    time: new Date(flight.departureLocalTime),
            //                    code: "FLIGHT",
            //                    name: "Flight",
            //                    transportUnit: workOrderData.transportUnit,
            //                    itineraryStatus: flight.itineraryStatus,
            //                    isCustomerItem: flight.isCustomerFlight,
            //                    itineraryItem: flight
            //                });

            //            //this.addProperStepPosition(workOrderMilestoneSteps,
            //            //    {
            //            //        time: new Date(flight.ArrivalLocalTime),
            //            //        code: "GROUNDTRANSIT",
            //            //        name: "Ground Transit"
            //            //    });
            //        });

            //        // Need to parse through the itinerary info for unique pickups
            //        const uniqueDropoffLocations = [...new Map(itineraryData.customerPlacementMappingInfo.map(item =>
            //            [item['facilityId'], item])).values()];

            //        uniqueDropoffLocations.sort((a, b) => moment(a.planDropoffDate).valueOf() - moment(b.planDropoffDate).valueOf()).map((pickup) => {

            //            addProperStepPosition(workOrderMilestoneSteps,
            //                {
            //                    time: new Date(pickup.planDropoffDate),
            //                    code: "DROPOFF",
            //                    name: "Drop Off UNCs",
            //                    transportUnit: workOrderData.transportUnit,
            //                    itineraryStatus: pickup.itineraryStatus,
            //                    customerCount: customerData.filter((item) => itineraryData.customerPlacementMappingInfo.find((mapping) => mapping.facilityId == pickup.facilityId && mapping.placementId == item.orrPlacementId)).length,
            //                    isCustomerItem: true,
            //                    itineraryItem: {
            //                        id: pickup.facilityId,
            //                        placementId: pickup.placementId,
            //                        locationName: pickup.facilityName
            //                    }
            //                });
            //        });

            //        //// Add trip end
            //        //this.addProperStepPosition(workOrderMilestoneSteps,
            //        //    {
            //        //        time: itineraryData.workOrderInfo.PlanEndDate,
            //        //        code: "RETURNTRIP",
            //        //        name: "Return to Office"
            //        //    });

            //        //// TODO: Order items within section
            //        //// TODO: Validate plan pickup date is within plan start and end date

            //        //// TODO: Initial Office/Start address?
            //        //// TODO: Pickup address?
            //        //// TODO: Return address?

            //        //var passFailData = [{ code: 'Pass', name: 'Pass' }, { code: 'Fail', name: 'Fail' }];
            //        //var yesNoData = [{ code: 'Yes', name: 'Yes' }, { code: 'No', name: 'No' }];

            //        //console.log('Final Itinerary Data', workOrderMilestoneSteps);

            //        setTripStages(workOrderMilestoneSteps);


            if (params.id == undefined) {
                console.log('New Work Order Creation');

                //var workOrderData = await this.FetchAPI('WorkOrder/GetNewWorkOrder/' + this.state.workOrder.workOrderType);
                //workOrderData.planStartDate = null;
                //workOrderData.planEndDate = null;
                //workOrderData.planRemovalDate = null;

                //const filterMethods = this.state.transportMethods.filter(t => t.trafficTypeCode === workOrderData.trafficTypeCode && (t.isActive || t.code === workOrderData.transportMethodCode));

                //// Filter on active and scheduling office
                //const filterOffices = this.state.offices.filter((item) => (item.isActive && item.isSchedulingOffice) || item.id == workOrderData.responsibleOfficeId);

                //flushSync(() => {
                //    this.setState({
                //        workOrder: workOrderData,
                //        filteredTransportMethods: filterMethods,
                //        filteredOffices: filterOffices
                //    });
                //});
            }
            else {
                console.log('Existing Work Order');
                var workOrderData = await FetchAPIPromise('WorkOrder/' + params.id);

                //var rules = await this.FetchAPI('WorkOrder/GetRules/' + this.props.params.id);
                ////console.log(rules);

                //var isOutOfRatio = !rules.statusMinTsFemaleCount || !rules.statusMinTsMaleCount || !rules.statusMinTsTotalCount;

                //const supportUACs = workOrderData.supportUAC;

                //const linkedRegularWorkOrders = workOrderData.linkedRegularWorkOrders;

                ////console.log('Transport methods from the server', this.state.transportMethods);

                //const filterMethods = this.state.transportMethods.filter(t => t.trafficTypeCode === workOrderData.trafficTypeCode && (t.isActive || t.code === workOrderData.transportMethodCode));

                //// Filter on active and scheduling office
                //// TODO: Test this
                //const filterOffices = this.state.offices.filter((item) => (item.isActive && item.isSchedulingOffice) || item.id == workOrderData.responsibleOfficeId);

                ////console.log('Work Order Data', workOrderData);
                //var isActiveStatus = workOrderData.statusID == 1;

                // ABG: Not sure why I have to do this, but devextreme will not handle offset dates properly
                workOrderData.planStartDate = moment.parseZone(workOrderData.planStartDate).format('yyyy-MM-DDTHH:mm:ss');
                workOrderData.planEndDate = moment.parseZone(workOrderData.planEndDate).format('yyyy-MM-DDTHH:mm:ss');

                //if (workOrderData.planPickupDates) {
                //    workOrderData.planPickupDates.map((date, index) => {
                //        date.planDate = moment.parseZone(date.planDate).format('yyyy-MM-DDTHH:mm:ss');
                //    });
                //}

                //// ABG: Not sure why I have to do this, but devextreme will not handle offset dates properly
                //if (workOrderData.planDropoffDates) {
                //    workOrderData.planDropoffDates.map((date, index) => {
                //        date.planDate = moment.parseZone(date.planDate).format('yyyy-MM-DDTHH:mm:ss');
                //    });
                //}

                setWorkOrder(workOrderData);

                //flushSync(() => {
                //    this.setState({
                //        supportWorkOrderUACs: supportUACs,
                //        filteredTransportMethods: filterMethods,
                //        filteredOffices: filterOffices,
                //        linkedRegularWorkOrders: linkedRegularWorkOrders,
                //        isActiveStatus: isActiveStatus,
                //        isOutOfRatio: isOutOfRatio
                //    });
                //});
            }
        }

        fetchData();
    }, [params]);

    //GetDropDownData = async () => {
    //    const trafficTypeData = await this.FetchAPI('DictionaryTrafficType');

    //    const transportMethodData = await this.FetchAPI('DictionaryTransportMethod');

    //    const cityData = await this.FetchAPI('DictionaryCity');

    //    const transportUnitData = await this.FetchAPI('DictionaryTransportUnit');

    //    const statusData = await this.FetchAPI('DictionaryWorkOrderStatus');

    //    const reviewStatusData = [{ code: 'UNREVIEWED', name: 'Unreviewed' }, { code: 'REVIEWED', name: 'Reviewed' }];

    //    const officeData = await this.FetchAPI('Office');

    //    const physicalTransferAddresses = await this.FetchAPI('Office/GetPhysicalAddressesForContract');

    //    physicalTransferAddresses.map((item) => {
    //        item.name = 'MVM Office: ' + item.name;
    //    });

    //    var destinationCityData = [...cityData];

    //    // Filter out inactive auth codes here
    //    var filterCity = [];
    //    if (this.state.workOrder.workOrderType == "Regular" && (this.state.workOrder.supportWorkOrders != null || this.props.params.supportWorkOrderId != undefined)) {
    //        filterCity = cityData.filter(t => t.aorCode == this.supportWorkOrderAOR);
    //    }
    //    else {
    //        filterCity = cityData;
    //    }

    //    flushSync(() => {
    //        this.setState({
    //            trafficTypes: trafficTypeData,
    //            transportMethods: transportMethodData,
    //            transportUnits: transportUnitData,
    //            statuses: statusData,
    //            departureCities: filterCity,
    //            destinationCities: destinationCityData,
    //            reviewStatuses: reviewStatusData,
    //            offices: officeData,

    //            mvmTransferAutocompleteItems: physicalTransferAddresses
    //        });
    //    });
    //}

    //deleteWorkOrder = async () => {
    //    if (window.confirm("Are you sure you want to delete this Work Order? Its Placement(s) will be moved into 'Not Dispatched' status. ")) {
    //        //console.log('Deleting work order...');
    //        await this.setState({
    //            loading: true
    //        });
    //        try {
    //            //console.log(this.props.params.id);
    //            //console.log(this.state.workOrder.id);
    //            const result = await this.DeleteAPI('WorkOrder/' + this.props.params.id);
    //            if (result.status == 1) {
    //                this.props.navigate('/workorders');
    //            }
    //            else {
    //                alert(result.message);
    //            }
    //        }
    //        finally {
    //            await this.setState({
    //                loading: false
    //            });
    //        }
    //    }
    //}

    //saveWorkOrder = async (e) => {
    //    //console.log('Saving...');
    //    e.preventDefault();

    //    await this.setState({
    //        loading: true
    //    });

    //    try {

    //        var workOrder = this.state.workOrder;

    //        //console.log(workOrder);

    //        if (this.props.params.supportWorkOrderId) {
    //            workOrder.supportWorkOrderId = this.props.params.supportWorkOrderId;
    //        }

    //        // Find responsibleoffice selected value and set the offset
    //        var responsibleOffice = this.state.offices.find((item) => item.id == workOrder.responsibleOfficeId);

    //        if (responsibleOffice) {
    //            var officeOffset = moment(workOrder.planStartDate).tz(responsibleOffice.timeZone).format('Z');

    //            workOrder.planStartDate = moment.parseZone(moment.parseZone(workOrder.planStartDate).format('MM/DD/YYYY HH:mm') + ' ' + officeOffset).format('YYYY-MM-DDTHH:mmZ');
    //            workOrder.planEndDate = moment.parseZone(moment.parseZone(workOrder.planEndDate).format('MM/DD/YYYY HH:mm') + ' ' + officeOffset).format('YYYY-MM-DDTHH:mmZ');

    //            if (workOrder.mvmToMVMTransferDate) {
    //                workOrder.mvmToMVMTransferDate = moment.parseZone(moment.parseZone(workOrder.mvmToMVMTransferDate).format('MM/DD/YYYY HH:mm') + ' ' + officeOffset).format('YYYY-MM-DDTHH:mmZ');
    //            }
    //        }

    //        //console.log(workOrder);

    //        // When we save the new date data, need to convert back to the expected offset
    //        if (workOrder.planPickupDates) {
    //            workOrder.planPickupDates.map((date, index) => {
    //                //console.log(moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ'));
    //                date.planDate = moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ');
    //            });
    //        }

    //        if (workOrder.planDropoffDates) {
    //            workOrder.planDropoffDates.map((date, index) => {
    //                console.log(date);
    //                //console.log(moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ'));
    //                date.planDate = moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ');
    //            });
    //        }

    //        // console.log('Data to save', workOrder);
    //        const result = await this.PostAPI('WorkOrder', { workOrder: workOrder, bypassQAQCWarnings: false });

    //        if (result.status == 1) {
    //            this.props.navigate('/workorder-detail/' + result.workOrderId);
    //            this.props.navigate(0);
    //        }
    //        else if (result.status == -1) {
    //            alert(result.message);
    //        }
    //        else {
    //            //alert('Display error log');

    //            // TODO: Display Error log
    //            // 1) Check results
    //            // 2) IF severity is critical, do not allow continue
    //            // 3) Else, display results and give user option to continue
    //            this.showQAQCIssues(result.workOrderRulesRunResults);
    //        }
    //    }
    //    finally {
    //        await this.setState({
    //            loading: false
    //        });
    //    }
    //}

    //saveFAMUs = async () => {
    //    var placements = await this.addFAMUdataGrid.instance().getSelectedRowKeys();

    //    if (placements.length == 0) {
    //        alert("Please select FAMU's.");
    //    }
    //    else {
    //        var array = {};
    //        array.placementIds = placements;
    //        array.workOrderID = this.props.params.id;
    //        array.remove = false;
    //        console.log(array);

    //        var validationResult = await this.PostAPI('Placement/CheckPlacementIsUsed', array);

    //        console.log(validationResult);

    //        if (validationResult.status == -1) {
    //            alert(validationResult.message);
    //            return;
    //        }
    //        else if (validationResult.status == 0) {
    //            alert("One or more Customers from these Placements are already on Work Order and cannot be added.");
    //            return;
    //        }
    //        else {
    //            for (var i = 0; i < validationResult.errorList.length; i++) {
    //                var item = validationResult.errorList[i];

    //                console.log(item);

    //                if (item.startsWith('Age Error')) {
    //                    alert(item + " is not the same Transport Unit. Assigned Placement Transport Units must be the same as the Work Order.");
    //                    return;
    //                }
    //                else if (item.startsWith('Inactive Roster Error ')) {
    //                    alert(item + " is already on this Work Order in the Inactive Roster and cannot be added. ");
    //                    return;
    //                }
    //                else {
    //                    alert(item + " has already been dispatched on another Work Order.");
    //                    return;

    //                }
    //            }
    //        }

    //        var uacs = {
    //            placementIds: placements,
    //            supportWorkOrderNumbers: this.state.workOrder.supportWorkOrders,
    //            workOrderId: this.props.params.id
    //        }

    //        var response = await this.PostAPI('WorkOrder/SaveUACFromSupport', uacs);
    //        if (response.status == 1) {
    //            window.location.href = '/workorder-detail/' + this.props.params.id;
    //        }
    //        else {
    //            alert('Failed. Please try again later.');
    //        }
    //    }
    //}

    //unlinkComponentIsReadOnly = () => {
    //    return this.IsReadOnly() || this.state.workOrder.statusID != 0;
    //}


    //unlinkRWO = async (e) => {
    //    var workOrderNumber = e.row.values[0];

    //    var array = {};
    //    array.supportWorkOrderID = this.props.params.id;
    //    array.remove = false;
    //    console.log(array);

    //    var uacs = {
    //        supportWorkOrderId: this.props.params.id,
    //        RegularWorkOrderNumber: workOrderNumber,
    //    }

    //    var response = await this.PostAPI('WorkOrder/CheckIfUnlinkAllowed', uacs);

    //    if (response.status == 1) {
    //        var response = await this.PostAPI('WorkOrder/UnlinkSWOFromRWO', uacs);
    //        if (response.status == 1) {
    //            window.location.href = '/workorder-detail/' + this.props.params.id;
    //        }
    //        else {
    //            alert('Failed to unlink WorkOrders. Please try again later.');
    //        }
    //    }
    //    else {
    //        alert('<center>Cannot unlink Regular Work Order from Support Work Order. <br /> Placements may only be Active on the Support OR Regular Work Order. <br/> Please inactivate all linked placements in the Support OR Regular Work Order and try again. <br/> To see the list of linked placements, use the MSWO -> RWO Coverage Table below and filter using Work Order Number: <u>' + workOrderNumber + '</u>. </center>');
    //    }

    //}

    //saveUACs = async () => {
    //    var placements = await this.addUACdataGrid.instance().getSelectedRowKeys();

    //    if (placements.length == 0) {
    //        alert("Please select UC's.");
    //    }
    //    else {
    //        var array = {};
    //        array.placementIds = placements;
    //        array.workOrderID = this.props.params.id;
    //        array.remove = false;
    //        console.log(array);

    //        var validationResult = await this.PostAPI('Placement/CheckPlacementIsUsed', array);

    //        console.log(validationResult);

    //        if (validationResult.status == -1) {
    //            alert(validationResult.message);
    //            return;
    //        }
    //        else if (validationResult.status == 0) {
    //            alert("One or more Customers from these Placements are already on Work Order and cannot be added.");
    //            return;
    //        }
    //        else {
    //            for (var i = 0; i < validationResult.errorList.length; i++) {
    //                var item = validationResult.errorList[i];

    //                console.log(item);

    //                if (item.startsWith('Age Error')) {
    //                    alert(item + " is not the same Transport Unit. Assigned Placement Transport Units must be the same as the Work Order.");
    //                    return;
    //                }
    //                else if (item.startsWith('Inactive Roster Error ')) {
    //                    alert(item + " is already on this Work Order in the Inactive Roster and cannot be added. ");
    //                    return;
    //                }
    //                else {
    //                    alert(item + " has already been dispatched on another Work Order.");
    //                    return;
    //                }

    //            }
    //        }

    //        var uacs = {
    //            placementIds: placements,
    //            supportWorkOrderNumbers: this.state.workOrder.supportWorkOrders,
    //            workOrderId: this.props.params.id
    //        }

    //        var response = await this.PostAPI('WorkOrder/SaveUACFromSupport', uacs);
    //        if (response.status == 1) {
    //            window.location.href = '/workorder-detail/' + this.props.params.id;
    //        }
    //        else {
    //            alert(response.message);
    //        }
    //    }
    //}

    //cancelClick = () => {
    //    //console.log('cancel click')
    //    this.props.navigate('/workorders');
    //}

    //linkSupportClick = () => {
    //    //console.log('link sup click');
    //    this.props.navigate('/link-to-support/' + this.props.params.id);
    //}

    //createNewRegularClick = () => {
    //    //console.log('create reg click');
    //    this.props.navigate('/create-workorder/Regular/' + this.props.params.id);
    //    this.props.navigate(0);
    //}

    const planPickupDateUpdate = async (e: React.MouseEvent<HTMLAnchorElement>, planDateId: string) => {
        e.preventDefault();

        //console.log(planDateId);

        // Pull the original
        var previous = workOrder?.planPickupDates;

        if (previous != undefined) {
            // find the item
            var updateItem = previous.find((item) => item.id == planDateId);

            if (updateItem != null) {
                updateItem.statusCode = (updateItem.statusCode == 'ACTIVE' ? 'CANCELLED' : 'ACTIVE');
            }

            // console.log(previous);
            setWorkOrder((prevState) => {
                if (prevState) {
                    return { ...prevState, planPickupDates: previous || [] };
                }
            });
        }
    }

    const planDropoffDateUpdate = async (e: React.MouseEvent<HTMLAnchorElement>, planDateId : string) => {
        e.preventDefault();

        //console.log(planDateId);

        // Pull the original
        var previous = workOrder?.planDropoffDates;

        if (previous != undefined) {
            // find the item
            var updateItem = previous.find((item) => item.id == planDateId);

            if (updateItem != null) {
                updateItem.statusCode = (updateItem.statusCode == 'ACTIVE' ? 'CANCELLED' : 'ACTIVE');
            }

            // console.log(previous);
            setWorkOrder((prevState) => {
                if (prevState) {
                    return { ...prevState, planDropoffDates: previous || [] };
                }
            });
        }
    }

    const fieldDataChanged = async (e: FormTypes.FieldDataChangedEvent) => {
        //console.log('Field data changed...', e);

        //switch (e.dataField) {
        //    case "departureCityName":
        //        var city = this.state.departureCities.find(c => c.cityCode === e.value);
        //        if (city) {

        //            await this.setState(prevState => ({
        //                workOrder: {
        //                    ...prevState.workOrder,
        //                    departureStateName: city.stateCode,
        //                    departureStateFullName: city.stateName,
        //                    departureAOR: city.aorCode
        //                }
        //            }));
        //        }
        //        break;
        //    case "destinationCityName":
        //        var city = this.state.destinationCities.find(c => c.cityCode === e.value);
        //        if (city) {
        //            await this.setState(prevState => ({
        //                workOrder: {
        //                    ...prevState.workOrder,
        //                    destinationStateName: city.stateCode,
        //                    destinationStateFullName: city.stateName,
        //                    destinationAOR: city.aorCode
        //                }
        //            }));
        //        }
        //        break;
        //    case "planEndDate":
        //        this.updateProperty('workOrder', 'planEndDate', e.value);
        //        break;
        //    case "planPickupDate":
        //        this.updateProperty('workOrder', 'planPickupDate', e.value);
        //        break;
        //    case "planRemovalDate":
        //        this.updateProperty('workOrder', 'planRemovalDate', e.value);
        //        break;
        //    case "planStartDate":
        //        this.updateProperty('workOrder', 'planStartDate', e.value);
        //        break;
        //    case "includesMVMToMVMTransfer":
        //        this.updateProperty('workOrder', 'includesMVMToMVMTransfer', e.value);
        //        break;
        //    case "statusID":
        //        this.updateProperty('workOrder', 'statusID', e.value);
        //        break;
        //    case "trafficTypeCode":
        //        const filterMethods = this.state.transportMethods.filter(t => t.trafficTypeCode === e.value && t.isActive);
        //        await this.setState({
        //            filteredTransportMethods: filterMethods
        //        });
        //        break;
        //}
    }

    //showChangeHistory = async (e) => {
    //    await this.setState({
    //        changeHistoryVisible: true
    //    });

    //    this.changeHistoryDataGrid.instance().refresh();
    //}

    //viewGroundTransitPlan = async (e) => {
    //    var workOrderGroundSegments = await this.FetchAPI('WorkOrder/GetWorkOrderTripSegments?workOrderId=' + this.state.workOrder.id);

    //    await this.setState({
    //        isViewingGroundTransitPlan: true,

    //        transitMetricsList: workOrderGroundSegments.transitMetricsList,
    //        transitLegsList: workOrderGroundSegments.transitLegsList
    //    });
    //}

    //hideChangeHistory = async () => {
    //    await this.setState({
    //        changeHistoryVisible: false
    //    });
    //}

    //hideGroundTransitPlan = async () => {
    //    await this.setState({
    //        isViewingGroundTransitPlan: false,

    //        transitMetricsList: [],
    //        transitLegsList: []
    //    });
    //}

    //changeLogDataSource = new CustomStore({
    //    key: 'id',
    //    load: (loadOptions) => {
    //        //console.log('Load', loadOptions);

    //        if (this.state.workOrder) {
    //            return FetchAPIPromise('WorkOrder/GetChangeHistory?workOrderId=' + this.state.workOrder.id);
    //        }
    //    }
    //});

    //showQAQCIssues = async (rulesResults) => {

    //    var allowContinue = true;

    //    if (rulesResults && rulesResults.find(element => element.ruleSeverity == 1)) {
    //        allowContinue = false;
    //    }

    //    await this.setState({
    //        QAQCIssuesVisible: true,
    //        QAQCIssuesList: rulesResults,
    //        QAQCAllowContinue: allowContinue
    //    });
    //}

    //hideQAQCIssues = async () => {
    //    await this.setState({
    //        QAQCIssuesVisible: false
    //    });
    //}

    //// TODO: Test that window does not even display if there are no issues at all
    //continueAndBypassQAQCIssues = async () => {
    //    //console.log('Saving...');

    //    this.hideQAQCIssues();

    //    await this.setState({
    //        loading: true
    //    });

    //    try {

    //        var workOrder = this.state.workOrder;
    //        if (this.props.params.supportWorkOrderId) {
    //            workOrder.supportWorkOrderId = this.props.params.supportWorkOrderId;
    //        }

    //        // Find responsibleoffice selected value and set the offset
    //        var responsibleOffice = this.state.offices.find((item) => item.id == workOrder.responsibleOfficeId);

    //        if (responsibleOffice) {
    //            var officeOffset = moment(workOrder.planStartDate).tz(responsibleOffice.timeZone).format('Z');

    //            workOrder.planStartDate = moment.parseZone(moment.parseZone(workOrder.planStartDate).format('MM/DD/YYYY HH:mm') + ' ' + officeOffset).format('YYYY-MM-DDTHH:mmZ');
    //            workOrder.planEndDate = moment.parseZone(moment.parseZone(workOrder.planEndDate).format('MM/DD/YYYY HH:mm') + ' ' + officeOffset).format('YYYY-MM-DDTHH:mmZ');
    //        }

    //        console.log(workOrder);

    //        // When we save the new date data, need to convert back to the expected offset
    //        if (workOrder.planPickupDates) {
    //            workOrder.planPickupDates.map((date, index) => {
    //                //console.log(moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ'));
    //                date.planDate = moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ');
    //            });
    //        }

    //        if (workOrder.planDropoffDates) {
    //            workOrder.planDropoffDates.map((date, index) => {
    //                console.log(date);
    //                //console.log(moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ'));
    //                date.planDate = moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ');
    //            });
    //        }

    //        // console.log('Data to save', workOrder);
    //        const result = await this.PostAPI('WorkOrder', { workOrder: workOrder, bypassQAQCWarnings: true });

    //        if (result.status == 1) {
    //            this.props.navigate('/workorder-detail/' + result.workOrderId);
    //            this.props.navigate(0);
    //        }
    //        else {
    //            alert(result.message);
    //        }
    //    }
    //    finally {
    //        await this.setState({
    //            loading: false
    //        });
    //    }
    //}

    //severityRender(cell) {
    //    //console.log('Severity Render', cell);

    //    var finalIcon;
    //    var finalAlt;

    //    if (cell.data.ruleSeverity == 1) {
    //        finalIcon = 'error_color.png';
    //        finalAlt = 'Critical';
    //    }
    //    else if (cell.data.ruleSeverity == 2) {
    //        finalIcon = 'warning_color.png';
    //        finalAlt = 'Warning';
    //    }
    //    else if (cell.data.ruleSeverity == 3) {
    //        finalIcon = 'information_color.png';
    //        finalAlt = 'Information';
    //    }
    //    else {
    //        finalIcon = 'warning_color.png';
    //        finalAlt = 'Warning';
    //    }

    //    return <img height="40" width="40" src={finalIcon} alt={finalAlt} />;
    //}

    //transferLocationChanged = (e) => {
    //    // console.log('Got here', e);

    //    if (e.selectedItem != null) {
    //        this.updateProperty('workOrder', 'mvmToMVMTransferLocation', e.selectedItem.name + ' ' + e.selectedItem.fullAddress);
    //    }
    //}

    interface ValidationCallbackOptions {
        value: any;
        rule: any;
        column?: any;
        data?: any;
    }

    const validateNumber = (e: ValidationCallbackOptions) => {

        // console.log(e);
        // console.log(workOrder);
        var pattern = '^[a-zA-Z0-9\\s\\:]{1,}?(\\d{1,}) [a-zA-Z0-9\\s]+(\\,)? [a-zA-Z]+(\\,)? [A-Z]{2} [0-9]{5,6}$';

        return workOrder?.statusID == 0 || (e.value && e.value.match(pattern));
    }
    
    var pickupItems: ReactNode[] = [];
    var dropoffItems: ReactNode[] = [];

    if (workOrder?.planPickupDates) {
        workOrder?.planPickupDates.map((date, index) => {
            var timezoneAbbr = date.timeZoneId ? moment.tz(date.planDate, date.timeZoneId).format('z') : null;
            //console.log('Pickup timezone', timezoneAbbr);

            pickupItems.push(
                <Item cssClass={date.statusCode == 'ACTIVE' ? `` : `lineThroughText`} colSpan={3} dataField={`planPickupDates[${index}].planDate`} editorType="dxDateBox" editorOptions={{ width: '190px', minWidth: '190px', displayFormat: `MM/dd/yyyy HH:mm '${timezoneAbbr}'`, type: 'datetime', readOnly: (workOrder.isWorkOrderDisabled || isAccessReadOnly), min: workOrder.planStartDate, max: workOrder.planEndDate }}>
                    <Label text={`${date.locationName} Pickup Time`} />
                    <RequiredRule />
                </Item>
            );

            if (workOrder?.isWorkOrderDisabled || isAccessReadOnly) {
                pickupItems.push(<EmptyItem />);
            }
            else {
                pickupItems.push(<Item><a href="#" style={{ verticalAlign: 'center' }} onClick={(e) => planPickupDateUpdate(e, date.id)}>{date.statusCode == 'ACTIVE' ? `Mark Canceled` : `Mark Active`}</a></Item >);
            }
        });
    }

    if (workOrder?.planDropoffDates) {
        workOrder?.planDropoffDates.map((date, index) => {
            var timezoneAbbr = date.timeZoneId ? moment.tz(date.planDate, date.timeZoneId).format('z') : null;
            //console.log('Dropoff timezone', timezoneAbbr);

            dropoffItems.push(
                <Item cssClass={date.statusCode == 'ACTIVE' ? `` : `lineThroughText`} colSpan={3} dataField={`planDropoffDates[${index}].planDate`} editorType="dxDateBox" editorOptions={{ width: '190px', minWidth: '190px', displayFormat: `MM/dd/yyyy HH:mm '${timezoneAbbr}'`, type: 'datetime', readOnly: (workOrder.isWorkOrderDisabled || isAccessReadOnly), min: workOrder.planStartDate, max: workOrder.planEndDate }}>
                    <Label text={`${date.locationName} Dropoff Time`} />
                    <RequiredRule />
                </Item>
            );

            if (workOrder?.isWorkOrderDisabled || isAccessReadOnly) {
                dropoffItems.push(<EmptyItem />);
            }
            else {
                dropoffItems.push(<Item><a href="#" onClick={(e) => planDropoffDateUpdate(e, date.id)}>{date.statusCode == 'ACTIVE' ? `Mark Canceled` : `Mark Active`}</a></Item>);
            }
        });
    }

    return (
            <div className="container specialForm">
            <h1>Work Order Details</h1>

            <Form formData={workOrder} onFieldDataChanged={fieldDataChanged} colCount={2}>
                        <Item itemType="group" colCount={3} alignItemLabels={false} colSpan={2}>
                            <Item dataField="workOrderName" editorOptions={{ readOnly: true }} >
                                <Label text="Work Order Name" />
                            </Item>
                            <Item dataField="workOrderNumber" editorOptions={{ readOnly: true }} >
                                <Label text="Work Order Number" />
                            </Item>
                            <Item dataField="workOrderType" editorOptions={{ readOnly: true }}>
                                <Label text="Work Order Type" />
                            </Item>
                            <Item itemType="empty" colSpan={3} />
                        </Item>

                <Item dataField="planStartDate" editorType="dxDateBox" editorOptions={{ displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime', readOnly: (workOrder?.isWorkOrderDisabled || isAccessReadOnly), max: workOrder?.planEndDate }}>
                            <Label text="Plan Start Date" />
                            <RequiredRule />
                </Item>
                <Item dataField="planEndDate" editorType="dxDateBox" editorOptions={{ displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime', readOnly: (workOrder?.isWorkOrderDisabled || isAccessReadOnly), min: workOrder?.planStartDate }}>
                            <Label text="Plan End Date" />
                            <RequiredRule />
                </Item>

                        {params.id != undefined && workOrder?.planPickupDates && workOrder?.planPickupDates.length > 0 &&
                                            <Item itemType="group" caption="Pickup Times" alignItemLabels={false} colCount={4}>
                                                {pickupItems}
                                            </Item>
                                        }

                                        {params.id != undefined && workOrder?.planDropoffDates && workOrder?.planDropoffDates.length > 0 &&
                                            <Item itemType="group" caption="Dropoff/Removal Times" alignItemLabels={false} colCount={4}>
                                                {dropoffItems}
                                            </Item>
                                        }

                <Item dataField="includesMVMToMVMTransfer" editorType="dxCheckBox" editorOptions={{ readOnly: (workOrder?.isWorkOrderDisabled || isAccessReadOnly) }}>
                                            <Label text="Includes MVM to MVM Transfer" />
                                        </Item>

                                        <EmptyItem />

                {/*{workOrder?.includesMVMToMVMTransfer &&*/}
                {/*    <Item dataField="mvmToMVMTransferDate" editorType="dxDateBox" editorOptions={{ displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime', readOnly: (workOrder?.isWorkOrderDisabled || isAccessReadOnly), min: workOrder?.planStartDate, max: workOrder?.planEndDate }}>*/}
                {/*                                <Label text="Transfer Date" />*/}
                {/*                                <RequiredRule />*/}
                {/*                            </Item>*/}
                {/*                        }*/}

                {/*{workOrder?.includesMVMToMVMTransfer &&*/}
                {/*    <Item dataField="mvmToMVMTransferLocation" editorType="dxAutocomplete" editorOptions={{ onSelectionChanged: transferLocationChanged, dataSource: mvmTransferAutocompleteItems, displayExpr: 'name', valueExpr: 'name', readOnly: (workOrder?.isWorkOrderDisabled || isAccessReadOnly), showClearButton: true, openOnFieldClick: true, minSearchLength: 0 }}>*/}
                {/*                                <Label text="Transfer Location" />*/}
                {/*                                <RequiredRule />*/}
                {/*                                <StringLengthRule max={255} />*/}
                {/*                                <CustomRule*/}
                {/*                                    validationCallback={validateNumber}*/}
                {/*                                    message="Transfer Location requires a full street address"*/}
                {/*                                />*/}
                {/*                            </Item>*/}
                {/*                        }*/}

                {/*<Item itemType="group" caption="Trip Logistics" colCount={2} colSpan={2}>*/}
                {/*    <Item dataField="transportUnit" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, dataSource: transportUnits, displayExpr: 'name', valueExpr: 'code', readOnly: workOrder?.areSupportFieldsDisabled || isAccessReadOnly }}>*/}
                {/*                                <Label text="Transport Unit" />*/}
                {/*                                <RequiredRule />*/}
                {/*                            </Item>*/}
                {/*                            {(workOrder?.transportUnit == "Family") ?*/}
                {/*                                (<Item dataField="familyUnitCount" editorType="dxNumberBox" editorOptions={{ readOnly: true }}>*/}
                {/*                                    <Label text="No. of Family Units" />*/}
                {/*                                </Item>) : (<Item itemType="empty" />)*/}
                {/*                            }*/}

                {/*    <Item dataField="trafficTypeCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, readOnly: (workOrder?.isWorkOrderDisabled || isAccessReadOnly), dataSource: trafficTypes, defaultValue: workOrder?.trafficTypeCode, displayExpr: 'name', valueExpr: 'code', id: "stuff" }}>*/}
                {/*                                <Label text="Transport Type" />*/}
                {/*                                <RequiredRule />*/}
                {/*    </Item>*/}
                {/*    <Item dataField="transportMethodCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, dataSource: filteredTransportMethods, displayExpr: 'name', valueExpr: 'code', readOnly: (workOrder?.isWorkOrderDisabled || isAccessReadOnly) }}>*/}
                {/*                                <Label text="Transport Method" />*/}
                {/*                                <RequiredRule />*/}
                {/*                            </Item>*/}

                {/*                            {(workOrder?.trafficTypeCode == 'Ground') &&*/}
                {/*                                <EmptyItem />*/}
                {/*                            }*/}

                {/*                            {(workOrder?.trafficTypeCode == 'Ground') &&*/}
                {/*                                <Item itemType="button" colSpan={1} buttonOptions={{ text: 'View Ground Transit Plan', stylingMode: 'contained', onClick: viewGroundTransitPlan }} />*/}
                {/*                            }*/}

                {/*    <Item dataField="departureCityName" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, readOnly: (workOrder?.isWorkOrderDisabled || isAccessReadOnly), dataSource: departureCities, valueExpr: 'cityCode', displayExpr: 'cityName' }} >*/}
                {/*                                <Label text="Departure City (Airport)" />*/}
                {/*                                <RequiredRule />*/}
                {/*                            </Item>*/}

                {/*    <Item dataField="destinationCityName" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, readOnly: (workOrder?.isWorkOrderDisabled || isAccessReadOnly), dataSource: destinationCities, valueExpr: 'cityCode', displayExpr: 'cityName' }} >*/}
                {/*                                <Label text="Destination City (Airport)" />*/}
                {/*                                <RequiredRule />*/}
                {/*                            </Item>*/}

                {/*                            {(workOrder?.workOrderType == "Regular") ?*/}
                {/*                                (<Item dataField="departureStateFullName" editorOptions={{ readOnly: true, value: workOrder?.departureStateFullName }}>*/}
                {/*                                    <Label text="Departure State" />*/}
                {/*                                </Item>) : (<Item itemType="empty" visible={false} />)*/}
                {/*                            }*/}

                {/*                            {(workOrder?.workOrderType == "Regular") ?*/}
                {/*                                (<Item dataField="destinationStateFullName" editorOptions={{ readOnly: true, value: workOrder?.destinationStateFullName }}>*/}
                {/*                                    <Label text="Destination State" />*/}
                {/*                                </Item>) : (<Item itemType="empty" visible={false} />)*/}
                {/*                            }*/}

                {/*                            <Item dataField="departureAOR" editorOptions={{ readOnly: true, value: workOrder?.departureAOR }}>*/}
                {/*                                <Label text="Departure AOR" />*/}
                {/*                            </Item>*/}
                {/*                            <Item dataField="destinationAOR" editorOptions={{ readOnly: true, value: workOrder?.destinationAOR }}>*/}
                {/*                                <Label text="Destination AOR" />*/}
                {/*                            </Item>*/}
                {/*                        </Item>*/}

                {/*<Item colSpan={2} dataField="woNotes" editorType="dxTextArea" editorOptions={{ readOnly: (workOrder?.isWorkOrderDisabled || isAccessReadOnly), autoResizeEnabled: true }} >*/}
                {/*                            <Label text="Notes" />*/}
                {/*                        </Item>*/}

                {/*<EmptyItem />*/}
                {/*<Item dataField="statusID" editorType="dxSelectBox" editorOptions={{ readOnly: isAccessReadOnly, searchEnabled: true, dataSource: statuses, defaultValue: workOrder?.statusID, displayExpr: 'name', valueExpr: 'id' }}>*/}
                {/*                            <Label text="Status" />*/}
                {/*                        </Item>*/}

                {/*<Item dataField="responsibleOfficeId" editorType="dxSelectBox" editorOptions={{ showClearButton: true, searchEnabled: true, dataSource: filteredOffices, displayExpr: 'name', valueExpr: 'id', readOnly: (workOrder?.isWorkOrderDisabled || isAccessReadOnly) }}>*/}
                {/*                            <Label text="Responsible Office" />*/}
                {/*                            <RequiredRule />*/}
                {/*                        </Item>*/}
                {/*                        <Item itemType="empty" />*/}

                    {/*
    //                    <Item dataField="reviewStatusCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, dataSource: this.state.reviewStatuses, displayExpr: 'name', valueExpr: 'code', readOnly: !this.state.isActiveStatus || this.IsReadOnly() }}
    //                        visible={this.props.params.id != undefined && this.state.workOrder.statusID > 0}>
    //                        <Label text="Review Status" />
    //                    </Item>
    //                    <Item visible={this.props.params.id != undefined && this.state.workOrder.statusID > 0}>
    //                        {this.state.workOrder.reviewStatusCode == 'REVIEWED' ? `Reviewed by ${this.state.workOrder.lastReviewedByDisplay} at ${moment(new Date(this.state.workOrder.reviewDate)).format('MM/DD/yyyy HH:mm')}` : ''}
    //                    </Item>

    //                    {(this.state.workOrder.workOrderType == "Regular" && this.state.workOrder.hasSupportWorkOrders) ?
    //                        (<Item dataField="supportWorkOrders" editorOptions={{ readOnly: true }} >
    //                            <Label text="Support Work Order" />
    //                        </Item>) : (<Item itemType="empty" visible={false} />)
    //                    }
    //                    {((this.state.isOutOfRatio || this.state.workOrder.outOfComplianceApprovalDate || this.state.workOrder.outOfComplianceApprovalNotes)) ?
    //                        (<Item itemType="group" caption="Unit Chief or Section Chief Approval">
    //                            <Item>
    //                                <p>This Work Order�s Employee ratio requires UC or SC approval. Please provide the UC or SC approval information below.</p>
    //                            </Item>

    //                            <Item dataField="outOfComplianceApprovalDate" editorType="dxDateBox" isRequired={this.state.workOrder.statusID == 1 && this.state.isOutOfRatio} editorOptions={{
    //                                displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime', readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly())
    //                            }}>
    //                                <Label text="Approval Date/Time" />
    //                            </Item>

    //                            <Item dataField="outOfComplianceApprovalNotes" editorType="dxTextArea" isRequired={this.state.workOrder.statusID == 1 && this.state.isOutOfRatio} editorOptions={{ readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), autoResizeEnabled: true }}>
    //                                <Label text="UC or SC Approval Notes" />
    //                            </Item>
    //                        </Item>)
    //                        : (<Item colSpan={2} />)
    //                    }

    //                    <Item itemType="group" colCount={5} colSpan={2}>
    //                    </Item>

    //                    <Item itemType="group" colCount={5} colSpan={2}>
    //                        <Item itemType="group" colSpan={1}>
    //                            {(this.state.workOrder.workOrderType == "Support" && this.props.params.id != undefined && !this.state.workOrder.isWorkOrderDisabled) ?
    //                                (<Item itemType="button" buttonOptions={{ text: 'Create New Regular Work Order', width: 'auto', type: 'danger', onClick: this.createNewRegularClick }} />)
    //                                : (<Item itemType="empty" visible={false} />)
    //                            }
    //                            {(this.state.workOrder.workOrderType == "Regular" && !this.state.supportWorkOrderId && this.props.params.id != undefined && !this.state.workOrder.isWorkOrderDisabled) ?
    //                                (<Item itemType="button" disabled={this.IsReadOnly()} buttonOptions={{ text: 'Link to Support Work Order', width: 'auto', type: 'danger', onClick: this.linkSupportClick }} />)
    //                                : (<Item itemType="empty" visible={false} />)
    //                            }
    //                        </Item>
    //                        <Item itemType="group" colSpan={4} colCount={6}>
    //                            <Item colSpan={1}>
    //                                {this.props.params.id != undefined &&
    //                                    <Link to={`/workorder-resources/${this.props.params.id}`}>
    //                                        <Button text="Resources" />
    //                                    </Link>
    //                                }
    //                            </Item>

    //                            <Item colSpan={1}>
    //                                {this.props.params.id != undefined &&
    //                                    <Link to={`/workorder-milestones/${this.props.params.id}`}>
    //                                        <Button text="Milestones" />
    //                                    </Link>
    //                                }
    //                            </Item>

    //                            <Item visible={this.props.params.id != undefined} itemType="button" buttonOptions={{ text: 'Change History', onClick: this.showChangeHistory }} />

    //                            {(this.props.params.id != undefined && this.state.workOrder.statusID == 0) ?
    //                                (<Item itemType="button" colSpan={1} disabled={this.IsReadOnly()} buttonOptions={{ text: 'Delete', onClick: this.deleteWorkOrder }} />)
    //                                : (<Item colSpan={1} />)
    //                            }

    //                            <Item itemType="button" colSpan={1} buttonOptions={{ text: 'Cancel', stylingMode: 'contained', onClick: this.cancelClick }} />

    //                            <Item disabled={this.IsReadOnly()} itemType="button" colSpan={1} buttonOptions={{ text: 'Save', type: 'success', width: 'auto', useSubmitBehavior: true }} />
    //                        </Item>
    //                    </Item>*/}
                    </Form>
            {/*//                <hr />
    //                <LoadPanel
    //                    visible={this.state.loading} />*/}
        </div>

        //    <form action="your-action" onSubmit={this.saveWorkOrder}>


    

    //            {
    //    this.state.workOrder.workOrderType == "Support" && this.props.params.id != undefined &&
    //    <React.Fragment>
    //        <h1>Linked Regular Work Orders</h1>
    //        <DataGrid dataSource={this.state.linkedRegularWorkOrders} showBorders={true}
    //            allowColumnResizing={true}
    //            ref={ref => this.linkedRegularWorkOrders = ref}>
    //            <FilterRow visible={true} />
    //            <Paging defaultPageSize={5} />
    //            <Pager showPageSizeSelector={true}
    //                allowedPageSizes={[5, 10, 20]}
    //                showInfo={true} visible={true} />
    //            <Column dataField="workOrderNumber" />
    //            <Column dataField="workOrderName" cellRender={function (options) { return (<Link to={`/workorder-detail/${options.row.data.id}`} onClick={() => window.location.href = '/workorder-detail/' + options.row.data.id}>{options.value}</Link>); }} />
    //            <Column caption="Unlink MSWO And RWO" type="buttons">
    //                <GridButton
    //                    text="Unlink"
    //                    onClick={this.unlinkRWO}
    //                    visible={!this.unlinkComponentIsReadOnly()}
    //                />
    //            </Column>
    //        </DataGrid>
    //    </React.Fragment>
    //}
    //<hr />
    //{
    //    this.state.workOrder.workOrderType == "Support" && this.props.params.id != undefined &&
    //    <React.Fragment>
    //        <h1>MSWO -> RWO Coverage</h1>
    //        <WorkOrderDetailsMSWOCoverageContainer workOrder={this.state.workOrder} />
    //    </React.Fragment>
    //}
    //{
    //    this.state.workOrder.supportWorkOrders != "" && this.state.workOrder.transportUnit == "UC" && this.props.params.id != undefined &&
    //    <React.Fragment>
    //        <legend style={{ color: 'darkgreen' }}>Add Placements from Support WorkOrder</legend>
    //        <DataGrid dataSource={this.state.supportWorkOrderUACs} allowColumnResizing={true}
    //            showBorders={true} onInitialized={this.onUCInitialized} keyExpr="id"
    //            selection={{ mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always', deferred: true }}
    //            ref={ref => this.addUACdataGrid = ref}>
    //            <FilterRow visible={true} />
    //            <Paging defaultPageSize={5} />
    //            <Pager showPageSizeSelector={true}
    //                allowedPageSizes={[5, 10, 20]}
    //                showInfo={true} visible={true} />

    //            <Column caption="Name" dataField="placementShortName" />
    //            <Column caption="Pickup Location" dataField="pickupLocationName" />
    //            <Column caption="Destination" dataField="destinationName" />

    //            <MasterDetail enabled={true} autoExpandAll={true} component={PlacementDetail} />
    //        </DataGrid>
    //        {!this.state.workOrder.isWorkOrderDisabled &&
    //            <div style={{ float: 'right' }}>
    //                <Button text="Save UC" onClick={this.saveUACs} />
    //                <br />
    //            </div>
    //        }
    //    </React.Fragment>
    //}
    //{
    //    this.state.workOrder.supportWorkOrders != "" && this.state.workOrder.transportUnit == 'Family' && this.props.params.id != undefined &&
    //    <React.Fragment>
    //        <legend style={{ color: 'darkgreen' }}>Add Placements from Support WorkOrder</legend>
    //        <DataGrid dataSource={this.state.supportWorkOrderUACs} allowColumnResizing={true}
    //            showBorders={true} onInitialized={this.onFAMUInitialized} keyExpr="id"
    //            selection={{ mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always', deferred: true }}
    //            ref={ref => this.addFAMUdataGrid = ref}>
    //            <FilterRow visible={true} />
    //            <Paging defaultPageSize={5} />
    //            <Pager showPageSizeSelector={true}
    //                allowedPageSizes={[5, 10, 20]}
    //                showInfo={true} visible={true} />

    //            <Column caption="Name" dataField="placementShortName" />
    //            <Column caption="Pickup Location" dataField="pickupLocationName" />
    //            <Column caption="Destination" dataField="destinationName" />

    //            <MasterDetail enabled={true} autoExpandAll={true} component={PlacementDetail} />
    //        </DataGrid>
    //        {!this.state.workOrder.isWorkOrderDisabled &&
    //            <div style={{ float: 'right' }}>
    //                <Button text="Save FAMU" onClick={this.saveFAMUs} />
    //                <br />
    //            </div>
    //        }
    //        <br />

    //        <br />
    //    </React.Fragment>
    //}
    //            <div style={{ marginBottom: 100 }} />

    //            <Popup visible={this.state.isViewingGroundTransitPlan} onHiding={this.hideGroundTransitPlan} dragEnabled={false}
    //                closeOnOutsideClick={true} showTitle={true} title="Ground Transit Metrics" width={900} height={500}>
    //                <ScrollView>
    //                    <p>Distance Metrics</p>
    //                    <DataGrid dataSource={this.state.transitMetricsList} showBorders={true} allowColumnResizing={true}>
                        
    //                        <Column dataField="origin" caption="Origin" />
    //                        <Column dataField="destination" caption="Destination" />
    //                        <Column dataField="straightLineDistance" caption="Distance" />
    //                    </DataGrid>
    //                    <br />
    //                    <p>Ground Transit Plan</p>
    //                    <DataGrid dataSource={this.state.transitLegsList} showBorders={true} allowColumnResizing={true}>
    //                        <Column dataField="origin" caption="Origin" />
    //                        <Column dataField="estimatedDepartureTime" caption="Estimated DepartureTime" dataType="datetime" format="MM/dd/yyyy HH:mm"  />
    //                        <Column dataField="destination" caption="Destination" />
    //                        <Column dataField="estimatedArrivalTime" caption="Estimated Arrival Time" dataType="datetime" format="MM/dd/yyyy HH:mm" />
    //                        <Column dataField="drivingDistance" caption="Driving Distance" />
    //                        <Column dataField="estimatedTransitTime" caption="Estimate Transit Time" />
    //                    </DataGrid>
    //                </ScrollView>
    //            </Popup>

    //            <Popup visible={this.state.changeHistoryVisible} onHiding={this.hideChangeHistory} dragEnabled={false}
    //                closeOnOutsideClick={true} showTitle={true} title="Change History" width={900} height={500}>

    //                <DataGrid dataSource={this.changeLogDataSource} showBorders={true} allowColumnResizing={true}
    //                    ref={ref => this.changeHistoryDataGrid = ref}>
    //                    <FilterRow visible={true} />
    //                    <Paging defaultPageSize={5} />
    //                    <Pager showPageSizeSelector={true}
    //                        allowedPageSizes={[5, 10, 20]}
    //                        showInfo={true} visible={true} />

    //                    <Column dataField="updateType" />
    //                    <Column dataField="updateField" caption="Field" />
    //                    <Column dataField="oldValue" />
    //                    <Column dataField="newValue" />
    //                    <Column dataField="display" caption="Change User" />
    //                    <Column dataField="createDate" caption="Change Date" dataType="datetime" format="MM/dd/yyyy HH:mm" />
    //                </DataGrid>
    //            </Popup>

    //            <Popup visible={this.state.QAQCIssuesVisible} onHiding={this.hideQAQCIssues} dragEnabled={false}
    //                closeOnOutsideClick={true} showTitle={true} title="Work Order QA/QC Items" width={900} height={500}>

    //                <DataGrid dataSource={this.state.QAQCIssuesList} showBorders={true} allowColumnResizing={true}
    //                    ref={ref => this.qaQCIssuesDataGrid = ref}>
    //                    <FilterRow visible={true} />
    //                    <Paging defaultPageSize={5} />
    //                    <Pager showPageSizeSelector={true}
    //                        allowedPageSizes={[5, 10, 20]}
    //                        showInfo={true} visible={true} />

    //                    <Column width={65} caption="Severity" cellRender={this.severityRender} />
    //                    <Column dataField="ruleDescription" caption="Item Description" />
    //                </DataGrid>

    //                <div className="d-flex justify-content-end">
    //                    <Button text="Continue" onClick={this.continueAndBypassQAQCIssues} visible={this.state.QAQCAllowContinue} />
    //                    &nbsp;
    //                    <Button text="Cancel" onClick={this.hideQAQCIssues} />
    //                </div>
    //            </Popup>
    //        </div >
    );
}

export default withParams(WorkOrderDetailsNew);