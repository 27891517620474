
import React from 'react';
import BaseComponent, { withParams } from '../BaseComponent.js'
import { LoadPanel } from 'devextreme-react/load-panel';
import Form, { Item, Label, ButtonItem } from 'devextreme-react/form';

class ComplianceAuditCreateContainer extends BaseComponent {
    static displayName = ComplianceAuditCreateContainer.name;

    constructor(props) {
        super(props);

        this.state = {
            complianceAudit: {
                id: '00000000-0000-0000-0000-000000000000', name: null, auditCategoryCode: null, referenceAuditId: null, referenceWorkOrderId: null, checklistIds: [], employeeId: null, planCompleteDate: null },
            auditors: [],
            referenceAudits: [],
            referenceWorkOrders: [],
            auditCategories: [],
            auditTypes: [],
            checklists: [],
            offices: [],
            loading: false
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Create Audit");
    }

    GetDropDownData = async () => {
        const auditCategoryData = await this.FetchAPI('ComplianceAuditCategory');
        //console.log('audit categories');
        //console.log(auditCategoryData);

        const auditorsData = await this.FetchAPI('Employee/GetActiveEmployees');
        //console.log(auditorsData);

        const checklistData = await this.FetchAPI('CheckList/GetActiveChecklists');
        //console.log(checklistData);

        var officeData = await this.FetchAPI('Office');

        // Filter on active and scheduling office
        officeData = officeData.filter((item) => item.isActive && item.isSchedulingOffice);

        const auditTypeData = await this.FetchAPI('ComplianceAuditTypes');

        await this.setState({
            auditCategories: auditCategoryData,
            auditors: auditorsData,
            checklists: checklistData,
            offices: officeData,
            auditTypes: auditTypeData
        });
    }

    SaveAudit = async (e) => {
        console.log('Saving...');
        e.preventDefault();

        await this.setState({
            loading: true
        });

        console.log(this.state.complianceAudit);

        try {
            const result = await this.PostAPI('ComplianceUserChecklist', this.state.complianceAudit);

            console.log(result);

            if (result.status == 1) {
                this.props.navigate('/compliance')
            }
            else {
                alert('Failed. Please try again later.');
            }
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    render() {
        console.log("Beginning render");

        return (
            <div className="container">
                <h1>Create Audit</h1>
                <React.Fragment>
                    <form action="your-action" onSubmit={this.SaveAudit}>
                        <Form
                            id="form"
                            colCount={1}
                            formData={this.state.complianceAudit}>
                            <Item itemType="group" caption="Basic Information">
                                <Item dataField="name" isRequired={true}>
                                    <Label text="Audit Name" />
                                </Item>
                                <Item dataField="auditCategoryCode" isRequired={true} editorType="dxSelectBox" editorOptions={{ dataSource: this.state.auditCategories, displayExpr: 'auditCategoryName', valueExpr: 'auditCategoryCode', searchEnabled: true }}>
                                    <Label text="Audit Category" />
                                </Item>
                                <Item dataField="AuditTypeCodes" isRequired={true} editorType="dxTagBox" editorOptions={{ applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.auditTypes, displayExpr: 'auditTypeName', valueExpr: 'auditTypeCode' }}>
                                    <Label text="Audit Types" />
                                </Item>
                                <Item dataField="employeeId" isRequired={true} editorType="dxSelectBox" editorOptions={{ dataSource: this.state.auditors, displayExpr: 'employeeName', valueExpr: 'employeeId', searchEnabled: true }}>
                                    <Label text="Auditor" />
                                </Item>
                                <Item dataField="officeId" isRequired={true} editorType="dxSelectBox" editorOptions={{ dataSource: this.state.offices, displayExpr: 'name', valueExpr: 'id', searchEnabled: true }}>
                                    <Label text="Office" />
                                </Item>
                                <Item dataField="checklistIds" isRequired={true} editorType="dxTagBox" editorOptions={{ dataSource: this.state.checklists, displayExpr: 'name', valueExpr: 'id', showSelectionControls: true, applyValueMode: 'useButtons' }}>
                                    <Label text="Checklist(s)" />
                                </Item>
                                <Item dataField="planCompleteDate" isRequired={true} editorType="dxDateBox">
                                    <Label text="Due Date" />
                                </Item>
                            </Item>

                            {/* TODO: This will not be known at create time
                             * <Item itemType="group" caption="Reference Information">
                                <Item name="Reference Audit">
                                    <Label text="Reference Audit" />
                                </Item>
                                <Item name="Reference Work Order">
                                    <Label text="Reference Work Order" />
                                </Item>
                            </Item>*/}

                            <ButtonItem buttonOptions={{ text: 'Save', useSubmitBehavior: true }} />
                        </Form>

                        <LoadPanel
                            visible={this.state.loading} />
                    </form>
                </React.Fragment>
            </div>
        );
    }
}

export default withParams(ComplianceAuditCreateContainer);