
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import Button from 'devextreme-react/button';
import { Link } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import DataGrid, { FilterRow, Column, Pager, Paging, Button as GridButton, Export } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { msalAuth } from '../../msal/MsalAuthProvider';
import { alert, confirm } from 'devextreme/ui/dialog';

export class EmployeeListContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            comments: [],
            isViewingLogs: false,
            currentEmployeeId: null,
            currentEmployeeNo: null,

            isViewingImportStatus: false,
            importStatus: {
                isSuccess: false,
                message: '',
                importedUsers: 0,
                updatedUsers: 0,
                importErrors: []
            },

            userIsSysAdmin: false
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Employee List");
    }

    customDataSource = new CustomStore({
        key: 'employeeId',
        load: (loadOptions) => {
            console.log('Load', loadOptions);

            return FetchAPIPromise('Employee');
        }
    });

    GetData = async () => {
        var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

        var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;
        //console.log('User ' + (isUserSystemAdmin ? 'IS' : 'IS NOT') + ' a Sys Admin');

        await this.setState({
            userIsSysAdmin: isUserSystemAdmin
        });
    }

    showScheduleComments = async (e) => {
        console.log(e);

        await this.setState({
            isViewingLogs: true,
            currentEmployeeId: e.row.data.employeeId,
            currentEmployeeNo: e.row.data.employeeNo
        });

        this.viewEmployeeScheduleLogsGrid.instance().refresh();
    }

    workdaySync = async (e) => {

        var dialogResult = await confirm('Are you sure you want to sync Employee data with Workday?');

        if (dialogResult) {
            try {
                this.setState({
                    loading: true
                });

                var result = await this.PostAPI('WorkdayIntegration');

                //console.log(result);

                if (result.status != 1) {
                    //alert(result.message);

                    this.setState({
                        isViewingImportStatus: true,
                        importStatus: {
                            isSuccess: false,
                            message: result.message
                        }
                    });
                }
                else {
                    this.setState({
                        isViewingImportStatus: true,
                        importStatus: {
                            isSuccess: true,
                            message: result.message,
                            importedUsers: result.importedUserCount,
                            updatedUsers: result.updatedUserCount,
                            importErrors: result.importErrors
                        }
                    });

                    //console.log(this.state.importStatus);
                }
            }
            finally {
                this.setState({
                    loading: false
                });
            }
        }
    }

    accessAudit = async (e) => {

        var dialogResult = await confirm('Are you sure you want to scan Employee data for access?');

        if (dialogResult) {
            try {
                this.setState({
                    loading: true
                });

                var result = await this.PostAPI('WorkdayIntegration/AuditAccess');

                //console.log(result);

                if (result.status != 1) {
                    alert(result.message);

                    //this.setState({
                    //    isViewingImportStatus: true,
                    //    importStatus: {
                    //        isSuccess: false,
                    //        message: result.message
                    //    }
                    //});
                }
                else {
                    //this.setState({
                    //    isViewingImportStatus: true,
                    //    importStatus: {
                    //        isSuccess: true,
                    //        message: result.message,
                    //        importedUsers: result.importedUserCount,
                    //        updatedUsers: result.updatedUserCount,
                    //        importErrors: result.importErrors
                    //    }
                    //});

                    //console.log(this.state.importStatus);
                }
            }
            finally {
                this.setState({
                    loading: false
                });
            }
        }
    }

    workspaceOneAudit = async (e) => {

        var dialogResult = await confirm('Are you sure you want to scan Employee data for access?');

        if (dialogResult) {
            try {
                this.setState({
                    loading: true
                });

                var result = await this.PostAPI('WorkdayIntegration/AuditAirwatchAccess');

                //console.log(result);

                if (result.status != 1) {
                    alert(result.message);

                    //this.setState({
                    //    isViewingImportStatus: true,
                    //    importStatus: {
                    //        isSuccess: false,
                    //        message: result.message
                    //    }
                    //});
                }
                else {
                    //this.setState({
                    //    isViewingImportStatus: true,
                    //    importStatus: {
                    //        isSuccess: true,
                    //        message: result.message,
                    //        importedUsers: result.importedUserCount,
                    //        updatedUsers: result.updatedUserCount,
                    //        importErrors: result.importErrors
                    //    }
                    //});

                    //console.log(this.state.importStatus);
                }
            }
            finally {
                this.setState({
                    loading: false
                });
            }
        }
    }

    hideImportStatus = async (e) => {
        this.setState({
            isViewingImportStatus: false,
            importStatus: {
                isSuccess: false,
                message: '',
                importedUsers: 0,
                updatedUsers: 0,
                importErrors: []
            }
        });
    }

    sendTestMessage = async (e) => {
        await this.PostAPI('OvertimeApproval/SendPushNotification/?employeeId=' + e.row.data.employeeId);
    }

    hidePopup = () => {
        this.setState({
            isViewingLogs: false,
            currentEmployeeId: null,
            currentEmployeeNo: null
        });
    }

    employeeMilestonesStore = new CustomStore({
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            return FetchAPIPromise('ScheduleComment/GetByEmployeeId?employeeId=' + this.state.currentEmployeeId);
        }
    })

    phoneCellRender = (e) => {
        //console.log(e);

        return this.formatPhoneNumber(e.value);
    }

    render() {


        return (
            <div className="container">
                <h1>Employee List</h1>

                {this.state.userIsSysAdmin &&
                    <div className="d-flex justify-content-end">
                            <Link to="/edit-employee">
                            <Button disabled={this.IsReadOnly()} icon="plus" text="Add Internal Employee" />
                        </Link>
                        &nbsp;
                        <Button disabled={this.IsReadOnly()} icon="refresh" text="Perform Access Audit" onClick={this.accessAudit} />
                    &nbsp;
                    {/*<Button icon="refresh" text="Perform Workspace One Audit" onClick={this.workspaceOneAudit} />*/}
                    {/*&nbsp;*/}
                        <Button disabled={this.IsReadOnly()} icon="refresh" text="Sync With Workday" onClick={this.workdaySync} />
                    </div>
                }

                <LoadPanel closeOnOutsideClick={true} message="Importing..." showIndicator={true} showPane={true}
                    visible={this.state.loading} />

                <br />
                <DataGrid dataSource={this.customDataSource}
                    showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"employeeList"} />
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column caption="Employee Number" dataField="employeeNo" dataType="string" cellRender={function (options) { return (<Link to={`/edit-employee/${options.row.data.employeeId}`}>{options.value}</Link>); }} />
                    <Column caption="First Name" dataField="firstName" />
                    <Column caption="Middle Name" dataField="middleName" />
                    <Column caption="Last Name" dataField="lastName" />
                    <Column caption="Email" dataField="email" />
                    <Column caption="Home Office" dataField="office" />
                    <Column caption="Scheduling Office" dataField="schedulingOffice" />
                    <Column caption="Phone" dataField="phone" cellRender={this.phoneCellRender} />
                    <Column caption="Active" dataField="active" defaultFilterValue={true} />
                    <Column caption="Azure Access" dataField="azureAccess" />

                    <Column type="buttons">
                        <GridButton text="Logs" onClick={this.showScheduleComments} />
                        <GridButton text="Test Message" onClick={this.sendTestMessage} visible={this.state.userIsSysAdmin && !this.IsReadOnly()} />
                    </Column>
                </DataGrid>

                <Popup visible={this.state.isViewingLogs} onHiding={this.hidePopup} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Schedule Comments">
                    <ScrollView width='100%' height='100%'>
                        <DataGrid dataSource={this.employeeMilestonesStore} showBorders={true} allowColumnResizing={true}
                            ref={ref => this.viewEmployeeScheduleLogsGrid = ref} onExporting={this.onExporting}>
                            <Export enabled={true} fileName={"scheduleComments" + this.state.currentEmployeeNo} />
                            <FilterRow visible={true} />

                            <Column caption="Submitted Date" dataField="createDateTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Work Order Number" dataField="workOrderNumber" />
                            <Column caption="Reason" dataField="reason" />
                            <Column caption="Comments" dataField="comment" />
                            <Column caption="Submitted By" dataField="submitter" />
                        </DataGrid>
                    </ScrollView>
                </Popup>

                <Popup visible={this.state.isViewingImportStatus} onHiding={this.hideImportStatus} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Workday Import Results" width={700} height={500}>
                    <ScrollView width='100%' height='100%'>
                        <div>
                        <p>{this.state.importStatus.message}</p>
                        {this.state.importStatus.isSuccess &&
                            <p>Imported {this.state.importStatus.importedUsers} Users</p>
                        }

                        {this.state.importStatus.isSuccess &&
                            <p>Updated {this.state.importStatus.updatedUsers} Users</p>
                        }

                        {this.state.importStatus.isSuccess &&
                            <>
                                <p><b>Import Errors</b></p>
                                <DataGrid dataSource={this.state.importStatus.importErrors} showBorders={true} allowColumnResizing={true}>
                                    <Column caption="Employee" dataField="display" />
                                    <Column caption="Supervisory Org" dataField="supervisoryOrg" />
                                    <Column caption="Home Office" dataField="homeOffice" />
                                    <Column caption="Error" dataField="message" />
                                </DataGrid>
                            </>
                            }
                        </div>
                    </ScrollView>
                </Popup>
                <br />
            </div>
        );
    }
}