import React from 'react';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import Form, { Item as FormItem, Label, EmptyItem, RequiredRule, ButtonItem } from 'devextreme-react/form';
import * as moment from 'moment';
import Button from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import { alert, confirm } from 'devextreme/ui/dialog';
import { flushSync } from 'react-dom'; // Note: react-dom, not react
import MultiView from 'devextreme-react/multi-view';
import DataGrid, { Column, Selection, FilterRow, Pager, Paging } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { Popover } from 'devextreme-react/popover';
import CustomStore from 'devextreme/data/custom_store';

import 'bootstrap';

// 1) Load with current date, current office selected
// 2) If no primary timesheet, create
// 3) Once created, re-load
// 4) Pull all Employee timesheets
//      If none, display none
// ?) Ability to generate timesheet detail per-employee

// TODO: Hide process timesheets if/when certain approvals are present?
// TODO: Warning that process will erase approvals?
// TODO: Maybe just have "process" skip any entries that 

// TODO: TEST: GM Hours calculation
// TODO: TEST: Daylight Savings?

export class TimesheetsContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            offices: [],
            employees: [],

            filters: {
                fromDate: '',
                toDate: '',
                officeId: null,
                employeeId: -1,
                totalScheduledHours: '0',
                totalWorkedHours: '0'
            },

            employeeTimesheetFilters: {
                approvalStatus: null,
                showOnlyItemsThatNeedReprocessing: false,
                employeeIds: []
            },

            primaryTimesheet: null,
            employeeTimesheets: [],

            filterEmployees: [],

            lastProcessedDisplay: '',

            isProcessDialogVisible: false,

            changeHistoryVisible: false,
            scheduleDetailId: ''
        };

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.moveFirstTimesheet = this.moveFirstTimesheet.bind(this);
        this.moveNextTimesheet = this.moveNextTimesheet.bind(this);
        this.movePreviousTimesheet = this.movePreviousTimesheet.bind(this);
        this.moveLastTimesheet = this.moveLastTimesheet.bind(this);
    }

    MultiViewItemRender = (data, index) => {
        var timesheet = data;

        this.approveTimesheet.bind(data);

        return (
            <div>
                <div className="container-fluid">
                    <table className="table table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                                <th colSpan={6}>Employee No/Name: {timesheet.display}</th>
                                <th colSpan={2}>Regular</th>
                                <th colSpan={2}>OverTime</th>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Desc</th>
                                <th>Contract</th>
                                <th>Work Order</th>
                                <th>Project Code</th>
                                <th>Hours</th>
                                <th>Rate</th>
                                <th>Hours</th>
                                <th>Rate</th>
                                <th>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {timesheet.lineItems.map((lineItem, lineItemIndex) => (
                                <tr>
                                    <td>{moment(lineItem.workDate).format('MM/DD/yyyy')}</td>
                                    <td><a id={`link${lineItem.scheduleDetailId}`}><span style={{ color: lineItem.needsReprocessing ? 'red' : 'black' }}>{lineItem.shiftName}</span></a></td>
                                    <td></td>
                                    <td>{lineItem.contractName}</td>
                                    <td>{lineItem.workOrderInfo}</td>
                                    <td>{lineItem.projectCode}</td>
                                    <td>{lineItem.regularHours == 0 ? null : lineItem.regularHours.toFixed(2)}</td>
                                    <td>{lineItem.regularHours == 0 ? null : '$' + lineItem.regularRate}</td>
                                    <td>{lineItem.overTimeHours == 0 ? null : lineItem.overTimeHours.toFixed(2)}</td>
                                    <td>{lineItem.overTimeHours == 0 ? null : '$' + lineItem.overTimeRate}</td>
                                    <td>{'$' + ((lineItem.regularHours == 0 ? 0 : lineItem.regularHours * lineItem.regularRate) + (lineItem.overTimeHours == 0 ? 0 : lineItem.overTimeHours * lineItem.overTimeRate)).toFixed(2)}</td>
                                    <td><a href="#" onClick={(event) => this.showChangeHistory(event, lineItem)} class="dx-link dx-link-delete">Change History</a></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={5}>Total</th>
                                <th style={{ textAlign: 'right' }}>Hours</th>
                                <th colSpan={2}>{timesheet.lineItems.reduce((a, b) => a + b.regularHours, 0).toFixed(2)}</th>
                                <th colSpan={2}>{timesheet.lineItems.reduce((a, b) => a + b.overTimeHours, 0).toFixed(2)}</th>
                                <th>{(timesheet.lineItems.reduce((a, b) => a + b.regularHours, 0) + timesheet.lineItems.reduce((a, b) => a + b.overTimeHours, 0)).toFixed(2)}</th>
                            </tr>
                            <tr>
                                <th colSpan={5}>{timesheet.display}</th>
                                <th style={{ textAlign: 'right' }}>Amount</th>
                                <th style={{ textAlign: 'right' }} colSpan={2}>{'$' + timesheet.lineItems.reduce((a, b) => a + b.regularHours * b.regularRate, 0).toFixed(2)}</th>
                                <th style={{ textAlign: 'right' }} colSpan={2}>{'$' + timesheet.lineItems.reduce((a, b) => a + b.overTimeHours * b.overTimeRate, 0).toFixed(2)}</th>
                                <th style={{ textAlign: 'right' }}>{'$' + (timesheet.lineItems.reduce((a, b) => a + b.regularHours * b.regularRate, 0) + timesheet.lineItems.reduce((a, b) => a + b.overTimeHours * b.overTimeRate, 0)).toFixed(2)}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <br />
                <div className="container">
                    <Form colCount={5} formData={timesheet}>
                        <FormItem colSpan={1} dataField='submitterDisplay' editorType='dxTextBox' editorOptions={{ readOnly: true, placeholder: 'Not yet submitted' }}>
                            <Label text="Submitted By" />
                        </FormItem>

                        <FormItem colSpan={1} dataField='employeeApprovalDate' editorType="dxDateBox" editorOptions={{ displayFormat: 'MM/dd/yyyy HH:mm', type: 'datetime', readOnly: true, placeholder: 'Not yet submitted' }}>
                            <Label text="On" />
                        </FormItem>

                        <FormItem colSpan={1} dataField='approverDisplay' editorType='dxTextBox' editorOptions={{ readOnly: true, placeholder: 'Not yet approved' }}>
                            <Label text="Approved By" />
                        </FormItem>

                        <FormItem colSpan={1} dataField='managerApprovalDate' editorType="dxDateBox" editorOptions={{ displayFormat: 'MM/dd/yyyy HH:mm', type: 'datetime', readOnly: true, placeholder: 'Not yet approved' }}>
                            <Label text="On" />
                        </FormItem>

                        <ButtonItem visible={timesheet.managerApprovalDate == null} buttonOptions={{ text: 'Approve Timesheet', type: 'success', onClick: (e) => this.approveTimesheet(e, timesheet) }} />
                    </Form>
                </div>

                {timesheet.lineItems.filter((lineItem) => lineItem.needsReprocessing).map((lineItem, lineItemIndex) => {
                    console.log(lineItem);

                    return (<Popover
                        target={`#link${lineItem.scheduleDetailId}`}
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        position="top"
                        width={350}
                    >
                        New Shift Hours values:<br />
                        <div style={{ paddingLeft: '4em' }}>Clock In: {moment(lineItem.scheduleDetailClockIn).format("MM/DD/YYYY HH:mm")}</div>
                        <div style={{ paddingLeft: '4em' }}>Clock Out: {moment(lineItem.scheduleDetailClockOut).format("MM/DD/YYYY HH:mm")}</div>

                        Timesheet Processed Date: {moment(lineItem.createDate).format("MM/DD/YYYY HH:mm")}<br />
                        Hours Update Date: {moment(lineItem.scheduleDetailUpdateDateTime).format("MM/DD/YYYY HH:mm")}
                    </Popover>);
                })}
                <br />
            </div>
        );
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Timesheets");
    }

    GetData = async () => {
        var today = moment();
        var from_date = today.clone().startOf('week');
        var to_date = today.clone().endOf('week');

        var defaultOfficeId = this.state.offices.length > 0 ? this.state.offices[0].id : -1;

        //console.log(this.state.offices);

        //console.log('Default office id', defaultOfficeId);

        var result = await this.FetchAPI('Timesheet/GetTimesheetByDate?beginDate=' + from_date.format("MM/DD/YYYY") + '&endDate=' + to_date.format("MM/DD/YYYY") + '&officeId=' + defaultOfficeId);

        // console.log('Primary Timesheet', result);

        var employeesForPeriod = await this.FetchAPI('Employee/GetTSEmployeesByOfficeAndDateRange?officeId=' + defaultOfficeId + '&beginDate=' + from_date.format("MM/DD/YYYY") + '&endDate=' + to_date.format("MM/DD/YYYY"));

        //console.log('Employees for period', employeesForPeriod);

        var display = ''
        if (result.timesheetLastUpdate != null) {
            display = 'Last Processed by: ' + result.timesheetLastUpdate.display + ' on ' + result.timesheetLastUpdate.dateDisplay;
        }

        await this.setState({
            filters: {
                fromDate: from_date.format("MM/DD/YYYY HH:mm"),
                toDate: to_date.format("MM/DD/YYYY HH:mm"),
                officeId: defaultOfficeId
            },

            primaryTimesheet: result.primaryTimesheet,
            employeeTimesheets: result.employeeTimesheets,

            filterEmployees: employeesForPeriod,

            lastProcessedDisplay: display
        });
    }

    GetDropDownData = async () => {
        const officeData = await this.FetchAPI('Office/GetEmployeeSupervisedOffices');
        console.log(officeData);

        flushSync(() => {
            this.setState({
                offices: officeData
            });
        });
    }

    isDisabledDate = (args) => {
        //console.log(args);

        if (args.view === "month") {
            return args.date.getDay() > 0;
        }

        return false;
    }

    loadTimesheetsFull = async (e) => {
        var initialValidate = this.filterForm.instance().validate();

        if (!initialValidate.isValid) {
            return false;
        }
        else {
            await this.setState({ loading: true });

            var filters = this.state.filters;

            var fromDate = moment(filters.fromDate);
            var toDate = moment(filters.toDate);

            var result = await this.FetchAPI('Timesheet/GetTimesheetByDate?beginDate=' + fromDate.format("MM/DD/YYYY") + '&endDate=' + toDate.format("MM/DD/YYYY") + '&officeId=' + filters.officeId);

            //console.log('Primary Timesheet', result);

            var employeesForPeriod = await this.FetchAPI('Employee/GetTSEmployeesByOfficeAndDateRange?officeId=' + filters.officeId + '&beginDate=' + fromDate.format("MM/DD/YYYY") + '&endDate=' + toDate.format("MM/DD/YYYY"));

            //console.log('Employees for period', employeesForPeriod);

            var display = ''
            if (result.timesheetLastUpdate != null) {
                display = 'Last Processed by: ' + result.timesheetLastUpdate.display + ' on ' + result.timesheetLastUpdate.dateDisplay;
            }

            await this.setState({
                loading: false,

                primaryTimesheet: result.primaryTimesheet,
                employeeTimesheets: result.employeeTimesheets,

                filterEmployees: employeesForPeriod,

                lastProcessedDisplay: display,

                employeeTimesheetFilters: {
                    approvalStatus: null,
                    showOnlyItemsThatNeedReprocessing: false,
                    employeeIds: []
                }
            });
        }
    }

    loadTimesheets = async (e) => {
        var initialValidate = this.filterForm.instance().validate();

        if (!initialValidate.isValid) {
            return false;
        }
        else {
            await this.setState({ loading: true });

            var filters = this.state.filters;

            var fromDate = moment(filters.fromDate);
            var toDate = moment(filters.toDate);

            var result = await this.FetchAPI('Timesheet/GetTimesheetByDate?beginDate=' + fromDate.format("MM/DD/YYYY") + '&endDate=' + toDate.format("MM/DD/YYYY") + '&officeId=' + filters.officeId);

            //console.log('Primary Timesheet', result);

            var display = ''
            if (result.timesheetLastUpdate != null) {
                display = 'Last Processed by: ' + result.timesheetLastUpdate.display + ' on ' + result.timesheetLastUpdate.dateDisplay;
            }

            await this.setState({
                loading: false,

                primaryTimesheet: result.primaryTimesheet,
                employeeTimesheets: result.employeeTimesheets,

                lastProcessedDisplay: display
            });
        }
    }

    createInitialTimesheet = async (e) => {

        var initialValidate = this.filterForm.instance().validate();

        if (!initialValidate.isValid) {
            return false;
        }
        else {
            await this.setState({ loading: true });

            var filters = this.state.filters;
            var fromDate = moment(filters.fromDate);
            var toDate = moment(filters.toDate);

            //console.log(this.state.placement);
            const result = await this.PostAPI('Timesheet/CreatePrimaryTimesheet?beginDate=' + fromDate.format("MM/DD/YYYY") + '&endDate=' + toDate.format("MM/DD/YYYY"));

            console.log(result);

            if (result.status == 1) {
                await this.setState({
                    loading: false
                });

                await this.loadTimesheetsFull();
            }
            else {
                //console.log(result);
                alert('Failed. Please try again later.');
            }
        }
    }

    processTimesheets = async (e) => {
        await this.setState({
            isProcessDialogVisible: true
        });
    }

    runReport = async (e) => {
        //console.log('got here');
        //console.log(this.props.workOrder);

        var filters = this.state.filters;
        var fromDate = moment(filters.fromDate);
        var toDate = moment(filters.toDate);

        //console.log(this.state.offices);
        //console.log(filters.officeId);

        var officeEntry = this.state.offices.find((item) => item.id == filters.officeId);

        var payrollReport = await this.RawPostAPI(`Reports/RunPayrollDetail?startDate=${fromDate.format("MM/DD/YYYY")}&endDate=${toDate.format("MM/DD/YYYY")}&officeLocation=${officeEntry.name}`);
        //console.log(cheatSheet);

        var blob = await payrollReport.blob();
        ////console.log(blob);

        //// Build a URL from the file
        //const fileURL = URL.createObjectURL(blob);

        //// Open the URL on new Window
        //const reportWindow = window.open();
        //reportWindow.location.href = fileURL;

        const file = new File([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // edit this line to have access to source blob
        const link = document.createElement('a');

        link.href = URL.createObjectURL(file);
        link.download = 'PayrollDetailByEmployeeHours_Paging.xlsx';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    }

    hideProcessDialog = async (e) => {
        await this.setState({
            isProcessDialogVisible: false
        });
    }

    approveTimesheet = async (e, data) => {
        var dialogResult = await confirm('Are you sure you want to approve this timesheet?');

        if (dialogResult) {
            //console.log('Approving', e);
            //console.log(data);

            // console.log(this.state.placement);
            const result = await this.PostAPI('Timesheet/ApproveTimesheet?timesheetId=' + data.id);

            //console.log(result);

            if (result.status == 1) {
                
                // Find underlying item?
                var timesheets = this.state.employeeTimesheets;
                var updatedItem = timesheets.find((item) => item.id == data.id);

                updatedItem.managerApprovalDate = result.approvalTime;
                updatedItem.approverDisplay = result.approver;

                await this.setState({
                    employeeTimesheets: timesheets,
                    loading: false
                });
            }
            else {
                //console.log(result);
                alert(result.message);
            }
        }
    }

    fieldDataChanged = async (e) => {
        //console.log('Field Data changed', e);
        //console.log(this.state.filters);

        if (e.dataField == 'fromDate') {
            //console.log(e);

            var filters = this.state.filters;

            filters.fromDate = e.value;

            var fromDate = moment(filters.fromDate);
            var toDate = fromDate.clone().endOf('week');

            filters.toDate = toDate.format("MM/DD/YYYY HH:mm");

            await this.setState({
                filters: filters
            });
        }

        if (this.filterForm != null) {
            this.filterForm.instance().repaint();
        }
    };

    employeeTimesheetsFilterFieldDataChanged = async (e) => {

        if (e.dataField == 'approvalStatus') {
            var filters = this.state.employeeTimesheetFilters;

            filters.approvalStatus = e.value;

            await this.setState({
                employeeTimesheetFilters: filters
            });
        }
        else if (e.dataField == 'employeeIds') {
            var filters = this.state.employeeTimesheetFilters;

            filters.employeeIds = e.value;

            await this.setState({
                employeeTimesheetFilters: filters
            });
        }
        else if (e.dataField == 'showOnlyItemsThatNeedReprocessing') {
            var filters = this.state.employeeTimesheetFilters;

            filters.showOnlyItemsThatNeedReprocessing = e.value;

            await this.setState({
                employeeTimesheetFilters: filters
            });
        }
    }

    onSelectionChanged(args) {
        //console.log(args);

        if (args.name === 'selectedIndex') {
            //console.log('Selection changed', args.value);
            this.setState({
                selectedEmployeeIndex: args.value,
            });
        }
    }

    moveFirstTimesheet(e) {
        // console.log(this.state.selectedEmployeeIndex + 1);
        this.setState({
            selectedEmployeeIndex: 0
        });
    }

    moveNextTimesheet(e) {
        //console.log(this.state.selectedEmployeeIndex + 1);
        this.setState({
            selectedEmployeeIndex: this.state.selectedEmployeeIndex + 1
        });
    }

    movePreviousTimesheet(e) {
        //console.log(this.state.selectedEmployeeIndex - 1);
        this.setState({
            selectedEmployeeIndex: this.state.selectedEmployeeIndex - 1
        });
    }

    moveLastTimesheet(e) {
        //console.log(this.state.selectedEmployeeIndex - 1);
        this.setState({
            selectedEmployeeIndex: this.state.employeeTimesheets.length - 1
        });
    }

    processTimesheetAll = async (e) => {
        var filters = this.state.filters;

        var parameters = {
            primaryTimesheetId: this.state.primaryTimesheet.id,
            officeId: filters.officeId
        };

        await this.centralTimesheetProcessing(parameters);
    }

    processTimesheetsFiltered = async (e) => {
        var filters = this.state.filters;

        // TODO: Alert if no employees filter
        var employeeIdValues = this.state.employeeTimesheetFilters.employeeIds

        if (!employeeIdValues || employeeIdValues.length == 0) {
            alert('No Employee(s) Selected');
            return;
        }

        var parameters = {
            primaryTimesheetId: this.state.primaryTimesheet.id,
            officeId: filters.officeId,
            employeeIds: employeeIdValues
        };

        await this.centralTimesheetProcessing(parameters);
    }

    processTimesheetsOutdated = async (e) => {
        var filters = this.state.filters;

        var parameters = {
            primaryTimesheetId: this.state.primaryTimesheet.id,
            officeId: filters.officeId,
            isOutdated: true
        };

        await this.centralTimesheetProcessing(parameters);
    }

    processTimesheetsUnapproved = async (e) => {
        var filters = this.state.filters;

        var parameters = {
            primaryTimesheetId: this.state.primaryTimesheet.id,
            officeId: filters.officeId,
            approvalStatusCode: 'Unapproved'
        };

        await this.centralTimesheetProcessing(parameters);
    }

    centralTimesheetProcessing = async (params) => {
        await this.setState({ loading: true });

        //console.log(this.state.placement);
        const result = await this.PostAPI('Timesheet/ProcessEmployeeTimesheets', params);

        console.log(result);

        if (result.status == 1) {
            await this.loadTimesheets();

            await this.hideProcessDialog();
        }
        else {
            //console.log(result);
            alert(result.message);
        }

        await this.setState({
            loading: false
        });
    }

    hideChangeHistory = async () => {
        await this.setState({
            changeHistoryVisible: false
        });
    }

    showChangeHistory = async (event, e) => {
        event.preventDefault();
        console.log(e);

        await this.setState({
            changeHistoryVisible: true,
            scheduleDetailId: e.scheduleDetailId
        });

        this.changeHistoryDataGrid.instance().refresh();
    }

    changeLogDataSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            if (this.state.scheduleDetailId) {
                return FetchAPIPromise('Schedule/GetScheduleDetailsChangeHistory?scheduleDetailsId=' + this.state.scheduleDetailId);
            }
        }
    });

    render() {
        console.log('Rendering');
        var timesheetsBindingArray = [this.state.primaryTimesheet];
        var approvalStatuses = [{ code: 'APPROVED', name: 'Approved' }, { code: 'UNAPPROVED', name: 'Unapproved' }];

        var filteredEmployeeTimesheets = this.state.employeeTimesheets;

        if (this.state.employeeTimesheetFilters.approvalStatus) {
            var approvalStatus = this.state.employeeTimesheetFilters.approvalStatus;

            if (approvalStatus == 'APPROVED') {
                filteredEmployeeTimesheets = filteredEmployeeTimesheets.filter((item) => item.managerApprovalDate != null);
            }
            else if (approvalStatus == 'UNAPPROVED') {
                filteredEmployeeTimesheets = filteredEmployeeTimesheets.filter((item) => item.managerApprovalDate == null);
            }
        }

        if (this.state.employeeTimesheetFilters.employeeIds.length > 0) {
            filteredEmployeeTimesheets = filteredEmployeeTimesheets.filter((item) => this.state.employeeTimesheetFilters.employeeIds.includes(item.employeeId));

            //console.log('Filter result', filteredEmployeeTimesheets);
        }

        if (this.state.employeeTimesheetFilters.showOnlyItemsThatNeedReprocessing) {
            filteredEmployeeTimesheets = filteredEmployeeTimesheets.filter((item) => item.lineItems.findIndex((subItem) => subItem.needsReprocessing) > -1);
        }

        //console.log('Filtered timesheets', filteredEmployeeTimesheets);

        var unapprovedCount = this.state.employeeTimesheets == null ? 0 : this.state.employeeTimesheets.filter((item) => item.managerApprovalDate == null).length;
        var reprocessCount = this.state.employeeTimesheets == null ? 0 : this.state.employeeTimesheets.filter((item) => item.lineItems.findIndex((subItem) => subItem.needsReprocessing) > -1).length;

        return (
            <>
                <div className="container">
                    <Form ref={ref => this.filterForm = ref} onFieldDataChanged={this.fieldDataChanged}
                        formData={this.state.filters} colCount={2}>
                        <FormItem editorType="dxDateBox" dataField="fromDate" editorOptions={{ disabledDates: this.isDisabledDate }}>
                            <Label text="Week Begin" />
                            <RequiredRule />
                        </FormItem>

                        <FormItem editorType="dxDateBox" dataField="toDate" editorOptions={{ readOnly: true }}>
                            <Label text="End" />
                        </FormItem>

                        <EmptyItem />

                        <FormItem dataField="officeId" editorType="dxSelectBox" editorOptions={{ dataSource: this.state.offices, displayExpr: 'name', valueExpr: 'id', searchEnabled: true, showClearButton: true }}>
                            <Label text="Office" />
                            <RequiredRule />
                        </FormItem>
                    </Form>
                    <br />
                    <div className="row">
                        <div className="ms-auto me-3">
                            <Button text="Load Timesheets" onClick={this.loadTimesheetsFull} />
                        </div>
                    </div>
                    <br />

                    <LoadPanel
                        visible={this.state.loading} />

                    {this.state.primaryTimesheet == null ?
                        <div className="row d-flex justify-content-between">
                            <h6>No Timesheets have been created for this time period. Would you like to create one now?</h6>
                            <br /><br />
                            <div className="ms-auto me-3">
                                <Button text="Create Primary Timesheet" onClick={this.createInitialTimesheet} />
                            </div>
                        </div>
                        :
                        <DataGrid dataSource={timesheetsBindingArray}>
                            <Selection mode="single" />
                            <Column caption="Week Begin" dataField="weekBegin" dataType="datetime" format="MM/dd/yyyy" />
                            <Column caption="Week End" dataField="weekEnd" dataType="datetime" format="MM/dd/yyyy" />
                        </DataGrid>
                    }
                    <br />

                    {this.state.primaryTimesheet != null &&
                        <div className="d-flex">
                            {this.state.employeeTimesheets.length > 0 &&
                                <div>
                                    {unapprovedCount > 0 && <img width='20px' height='20px' src='nobutton.png' />}
                                    {unapprovedCount == 0 && <img width='20px' height='20px' src='yesbutton.png' />}
                                    &nbsp;
                                    <span style={{ color: unapprovedCount > 0 ? 'red' : 'green' }}>{`${unapprovedCount} out of ${this.state.employeeTimesheets.length} Approvals remaining`}</span>
                                </div>
                            }
                            {this.state.employeeTimesheets.length > 0 &&
                                <div>
                                    &nbsp;&nbsp;
                                    {reprocessCount > 0 && <img width='20px' height='20px' src='nobutton.png' />}
                                    {reprocessCount == 0 && <img width='20px' height='20px' src='yesbutton.png' />}
                                    &nbsp;
                                    <span style={{ color: reprocessCount > 0 ? 'red' : 'green' }}>{`${reprocessCount} out of ${this.state.employeeTimesheets.length} Timesheets need Reprocessing`}</span>
                                </div>
                            }
                            <div className="ms-auto">
                                {this.state.primaryTimesheet != null &&
                                    <div className="ms-auto">
                                        <Button text="Run Payroll Detail Report" onClick={this.runReport} />
                                        &nbsp;
                                        <Button disabled={this.IsReadOnly()} text="Process Employee Timesheets" onClick={this.processTimesheets} />
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <br />

                    {this.state.primaryTimesheet != null &&
                        <Form
                            formData={this.state.employeeTimesheetFilters} colCount={3} onFieldDataChanged={this.employeeTimesheetsFilterFieldDataChanged}>
                            <FormItem editorType="dxSelectBox" dataField="approvalStatus" editorOptions={{ showClearButton: true, searchEnabled: true, dataSource: approvalStatuses, displayExpr: 'name', valueExpr: 'code' }}>
                                <Label text="Approval Status" />
                            </FormItem>

                            <FormItem editorType="dxCheckBox" dataField="showOnlyItemsThatNeedReprocessing">
                                <Label text="Only Show Items that Need Reprocessing" />
                            </FormItem>
                            <FormItem dataField="employeeIds" editorType="dxTagBox" editorOptions={{ applyValueMode: 'useButtons', showSelectionControls: true, searchEnabled: true, dataSource: this.state.filterEmployees, displayExpr: 'employeeName', valueExpr: 'employeeId' }}>
                                <Label text="Employee(s)" />
                            </FormItem>
                        </Form>
                    }

                    <br />

                    {this.state.primaryTimesheet != null &&
                        <div>
                            <span>{this.state.lastProcessedDisplay}</span>
                        </div>
                    }
                    <br />

                    {this.state.primaryTimesheet != null &&
                        <div>
                            {this.state.employeeTimesheets && this.state.employeeTimesheets.length > 0 &&
                                <div className="d-flex justify-content-center">
                                    {this.state.selectedEmployeeIndex > 0 &&
                                        <div>
                                            <Button icon="chevrondoubleleft" onClick={this.moveFirstTimesheet} />
                                        </div>
                                    }
                                    &nbsp;&nbsp;&nbsp;
                                    {this.state.selectedEmployeeIndex > 0 &&
                                        <div>
                                            <Button icon="chevronprev" onClick={this.movePreviousTimesheet} />
                                        </div>
                                    }
                                    &nbsp;&nbsp;&nbsp;
                                    <span style={{ textAlign: 'center' }}>
                                        <p>{`Viewing Employee ${filteredEmployeeTimesheets.length == 0 ? 0 : this.state.selectedEmployeeIndex + 1} of ${filteredEmployeeTimesheets.length}`}</p>
                                    </span>
                                    &nbsp;&nbsp;&nbsp;
                                    {filteredEmployeeTimesheets.length > 0 && this.state.selectedEmployeeIndex < filteredEmployeeTimesheets.length - 1 &&
                                        <div>
                                            <Button icon="chevronnext" onClick={this.moveNextTimesheet} />
                                        </div>
                                    }
                                    &nbsp;&nbsp;&nbsp;
                                    {filteredEmployeeTimesheets.length > 0 && this.state.selectedEmployeeIndex < filteredEmployeeTimesheets.length - 1 &&
                                        <div>
                                            <Button icon="chevrondoubleright" onClick={this.moveLastTimesheet} />
                                        </div>
                                    }
                                </div>
                            }
                            <br />

                            <MultiView style={{ minHeight: 600 }}
                                selectedIndex={this.state.selectedEmployeeIndex}
                                onOptionChanged={this.onSelectionChanged}
                                noDataText='No Employee Timesheets found'
                                dataSource={filteredEmployeeTimesheets}
                                itemRender={this.MultiViewItemRender} />
                        </div>
                    }

                    <Popup visible={this.state.isProcessDialogVisible} onHiding={this.hideProcessDialog} width={500} height={525}
                        title='Process Timesheets'>
                        <div>
                            <div>
                                <div className="text-center">
                                    <Button text="Clear Approvals and Process ALL Timesheets" onClick={this.processTimesheetAll} />
                                </div>
                                <div className="text-center">
                                    <br />
                                    <h4>- OR -</h4>
                                    <br />
                                </div>
                                <div className="text-center">
                                    <Button text="Process all UNAPPROVED Timesheets Only" onClick={this.processTimesheetsUnapproved} />
                                </div>
                                <div className="text-center">
                                    <br />
                                    <h4>- OR -</h4>
                                    <br />
                                </div>
                                <div className="text-center">
                                    <Button text="Process all OUTDATED Timesheets Only" onClick={this.processTimesheetsOutdated} />
                                    <br />
                                </div>
                                <div className="text-center">
                                    <br />
                                    <h4>- OR -</h4>
                                    <br />
                                </div>
                                <div className="text-center">
                                    <Button text="Process Timesheets for Selected Employee(s) Only" onClick={this.processTimesheetsFiltered} />
                                </div>
                                <div className="text-center">
                                    <br />
                                    <Button text="Cancel" onClick={this.hideProcessDialog} />
                                </div>
                            </div>
                        </div>
                    </Popup>

                    <Popup visible={this.state.changeHistoryVisible} onHiding={this.hideChangeHistory} dragEnabled={false}
                        closeOnOutsideClick={true} showTitle={true} title="Change History" width={900} height={500}>

                        <DataGrid dataSource={this.changeLogDataSource} showBorders={true} allowColumnResizing={true}
                            ref={ref => this.changeHistoryDataGrid = ref}>
                            <FilterRow visible={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                                showInfo={true} visible={true} />

                            <Column dataField="updateType" />
                            <Column dataField="updateField" caption="Field" />
                            <Column dataField="oldValue" />
                            <Column dataField="newValue" />
                            <Column dataField="display" caption="Change User" />
                            <Column dataField="createDate" caption="Change Date" dataType="datetime" format="MM/dd/yyyy HH:mm" />
                        </DataGrid>
                    </Popup>

                </div>
            </>
        );
    }
}