import React from 'react';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent.js';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Column, Editing, Pager, Paging, Form, Label, GroupPanel, Export } from 'devextreme-react/data-grid';
import PropTypes from 'prop-types';
import { SimpleItem, Item, RequiredRule } from 'devextreme-react/form';
import DataSource from 'devextreme/data/data_source';
import * as $ from 'jquery';

const currencyFormat = {
    type: 'currency',
    precision: 2
};

export class WorkOrderResourcesOtherContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.workOrderName = props.workOrder.workOrderName;
        this.state = {
            customers: [],
            locations: null,
        };
    }

    otherItemsDataSource = new CustomStore({
        load: () => {
            console.log('Loading data...');
            return FetchAPIPromise('InventoryOtherItemsManagement');
        },
        update: (key, values) => {
            console.log('Add items');
            values.customers = this.customerOtherItems;
            values.workOrderId = this.props.workOrder.id;
            console.log(values);
            return PostAPIPromise('WorkOrderResourcesOther', values);
        }
    });

    customDataSource = new CustomStore({
        load: () => {
            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderResourcesOther/' + this.props.workOrder.id);
            }
        },
        insert: (values) => {
            console.log('Values');
            this.itemDataGrid.instance().saveEditData();
            this.otherItemsData = {};
            this.customerOtherItems = [];
            return;
        },
        remove: (key) => {
            console.log('Remove from WO and Add to Inventory');
            console.log(key);
            return DeleteAPIPromise('WorkOrderResourcesOther/DeleteFromWOAndAddToInventory/' + key.id);
        }
    });

    componentDidUpdate(prevProps) {
        console.log('Props Updated');

        if (this.props.workOrder != prevProps.workOrder) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.fetchData();
        }
    }

    GetDropDownData = async () => {
        const customerData = await this.FetchAPI('WorkOrderResourcesCustomers/GetFlattenedCustomerList/' + this.props.workOrder.id);
        const locationData = await this.FetchAPI('Location/GetWithOfficeName');

        const groupedLocations = new DataSource({
            store: locationData,
            key: 'id',
            group: 'officeName'
        });

        await this.setState({
            customers: customerData,
            locations: groupedLocations
        });
    }

    locationChanged = async (args) => {
        this.otherItemsData.location = args.value;
        if (args.value == null) {
            this.itemDataGrid.instance().clearFilter();
        }
        else {
            this.itemDataGrid.instance().clearFilter();
            this.itemDataGrid.instance().filter(['locationId', '=', args.value]);
        }
        this.itemDataGrid.instance().repaint();
        this.itemDataGrid.instance().cancelEditData();
    }

    selectionChanged = (e) => {
        this.customerOtherItems = e.value;
    }

    customerOtherItems = [];

    otherItemsData = {};

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.visible = false;
    }

    onContentReady = (e) => {
        if (this.itemDataGrid) {
            var filter = this.itemDataGrid.instance().getCombinedFilter();
            if (filter == undefined) {
                this.itemDataGrid.instance().filter(['locationId', '=', 0]);
            }
        }
    }

    onRowUpdated = (e) => {
        this.customDataSource.load();
        this.dataGrid.instance().refresh();
        this.dataGrid.instance().repaint();
    }

    componentIsReadOnly = () => {
        return this.IsReadOnly() || this.props.workOrder.statusID > 1;
    }

    render() {
        return (
            <div style={{ margin: "10px" }}>
                <DataGrid dataSource={this.customDataSource} ref={ref => this.dataGrid = ref} showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"otherItems" + this.workOrderName} />
                    <GroupPanel visible={true} />
                    <Editing mode="popup" allowAdding={(this.props.workOrder.statusID < 2 ? true : false) && !this.componentIsReadOnly()} allowDeleting={(this.props.workOrder.statusID < 2 ? true : false) && !this.componentIsReadOnly()} texts={{
                        confirmDeleteMessage: 'Are you sure you want to delete this record and return items to inventory?'
                    }}>
                        <Form colCount={1}>
                            <SimpleItem ref={ref => this.tagBox = ref} editorType="dxTagBox" editorOptions={{
                                dataSource: this.state.customers, onValueChanged: this.selectionChanged, showSelectionControls: true, valueExpr: 'customerID', displayExpr: function (item) {
                                    return item ? item.firstName + ' ' + item.lastName + ' Age: ' + item.age + ' Gender: ' + item.gender : null;
                                }
                            }}>
                                <Label text="Customer(s)" />
                                <RequiredRule />
                            </SimpleItem>
                            <Item dataField="location_code" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, dataSource: this.state.locations, grouped: true, valueExpr: 'id', displayExpr: 'name', onValueChanged: this.locationChanged }} >
                                <RequiredRule />
                            </Item>
                            <Item>
                                <DataGrid dataSource={this.otherItemsDataSource} sorting="none"
                                    ref={ref => this.itemDataGrid = ref} onRowUpdating={this.onRowUpdating}
                                    onRowUpdated={this.onRowUpdated} onToolbarPreparing={this.onToolbarPreparing}
                                    onContentReady={this.onContentReady} >
                                    <Editing mode="batch" allowUpdating={true} />
                                    <FilterRow visible={true} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 20]}
                                        showInfo={true} />
                                    <Column caption="Item No." dataField="itemNumber" allowEditing={false} />
                                    <Column caption="Item Name" dataField="itemDescription" allowEditing={false} />
                                    <Column caption="Price" dataField="price" format={currencyFormat} allowEditing={false} />
                                    <Column caption="Available Quantity" dataField="quantity" allowEditing={false} />
                                    <Column caption="Quantity to Remove" dataField="quantityToRemove" editorOptions={{ min: 0 }}>
                                    </Column>
                                </DataGrid>
                            </Item>
                        </Form>
                    </Editing>
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <Column caption="Customer Name" dataField="customerName" groupIndex={0} />
                    <Column caption='Item No.' dataField='itemNumber' />
                    <Column caption='Name' dataField='itemName' />
                    <Column caption='Price' dataField='price' format={currencyFormat} />
                    <Column caption='Quantity' dataField='quantity' />
                </DataGrid>
            </div>
        );
    }
}