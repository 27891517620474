import React from 'react';
import Button from 'devextreme-react/button';
import { msalAuth } from './msal/MsalAuthProvider';
import { systemConfig } from './components/SystemConfig';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

export default class LandingPage extends React.Component {
    constructor(props) {
        super(props);
    }

    continueLogin = () => {
        var loginResult = msalAuth.loginRedirect({
        });
    }

    render() {
        document.title = systemConfig.systemName;

        return (
            <div className="mb-4">
                <Navbar id="navbar" collapseOnSelect expand="lg" bg="customBlue" variant="dark">
                    <Container>
                        <Navbar.Brand id='brand' href="/">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0px" y="0px" viewBox="0 0 144 119.4" xmlSpace="preserve" fill="currentColor">
                                <g>
                                    <path d="M116.2,56.6c-1,2.7-3.4,8.5-3.5,8.7c-0.1-0.2-2.7-5.9-3.7-8.6c-0.2-0.4-0.4-0.7-0.7-0.8H98c-0.3,0.1-0.5,0.4-0.6,0.8
			                            c-3.1,8.8-20.3,54.6-21.3,57.3h10.8c2.6-6.7,14.1-36.1,16.6-42.7c0.1-0.3,0.3-0.6,0.5-1c0.3,0.3,0.4,0.7,0.5,1
			                            c2.5,6.4,8.3,19.9,8.3,19.9c0.1-0.3,5.7-13.9,8.4-20.4c0.1-0.3,0.1-0.7,0.4-0.7c0.3,0,0.3,0.4,0.4,0.7c0.1,0.1,0.1,0.2,0.1,0.3
			                            c2.6,6.6,8,20,8,20.1h9.9c-0.3-0.3-8.7-23.3-12.8-34.5c-0.2-0.5-0.4-0.7-0.7-0.8h-9.8C116.6,56,116.4,56.2,116.2,56.6z"></path>
                                    <path d="M84,56.8c-3.5,9.3-11.1,28.9-11.1,28.9s-7.7-19.6-11.2-28.9c-0.2-0.5-0.4-0.8-0.8-0.9h-10c-0.2,0.2-0.1,0.5,0.1,1
			                            c2.7,7,21.6,57.1,21.6,57.1s17.5-45.7,21.9-57.1c0.2-0.5,0.3-0.9,0.1-1h-9.9C84.4,56,84.2,56.3,84,56.8z"></path>
                                    <path d="M36.7,56.7c-1,2.6-2.1,5.2-3.2,7.7c-0.1,0.3-0.1,0.6-0.5,0.8c-0.1-0.3-0.3-0.5-0.4-0.8c-1-2.7-2-5.3-2.9-8
			                            c-0.1-0.3-0.2-0.5-0.4-0.6H19c-0.3,0.1-0.4,0.4-0.6,0.8C17.7,58.9,5.5,91.2,5.5,91.2h9.8l8-20.4c0,0,0.2-0.8,0.4-0.8
			                            c0.4,0,0.4,0.5,0.5,0.9c2.8,6.5,8.9,20.4,8.9,20.4s5.5-13.8,8-20.3c0.1-0.3,0.1-0.7,0.5-0.9c0.1,0.3,0.2,0.6,0.4,0.9
			                            c2.6,6.8,13.7,36.3,16.3,43.1h10.8C65,102.4,52.3,68.1,48.2,56.6c-0.1-0.4-0.3-0.6-0.5-0.7H37.3C37,56,36.8,56.2,36.7,56.7z"></path>
                                    <path d="M59.7,52c2.5-6.4,5-12.7,7.6-19.1c0.3-0.7,0.2-1.4-0.2-2c-2.2-3.5-4.3-7-6.5-10.5c-0.7-1.2-1.4-2.4-2.5-3.4
			                            c-0.6-0.5-1.2-0.8-1.9-0.1c-0.2,0.2-0.3,0.4-0.6,0.3c-0.3-0.2-0.2-0.5-0.2-0.8c0.3-2.1,2.2-3.2,4.2-2.3c0.4,0.2,0.8,0.4,1.2,0.6
			                            c0.8,0.5,1.5,0.5,2.3-0.1c0.9-0.7,1.9-1,2.9-0.6c2.9,1.3,5.7,2.5,7,6c3.8,10.6,7.9,21.1,11.9,31.6c0.1,0.4,0.3,0.7,0.6,0.8h40.1
			                            c0,0-1.6-4.7-2.2-6.6c-0.3-0.8-0.6-1.1-1.5-1.1c-9.9,0-19.8,0-29.6,0c-1,0-1.4-0.3-1.8-1.2c-3.7-10.1-7.4-20.1-11.1-30.2
			                            c-0.5-1.3-1.3-2.2-2.6-2.6c-1.4-0.5-2.9-1-4.3-1.5c-3.3-1.2-6.8-2.2-10.1-3.6c-0.8-0.2-1.6-0.2-2.4,0c-1.4,0.7-2.1,2-2.6,3.4
			                            c-0.3,0.8-0.7,1-1.4,0.5c-0.7-0.5-1.5-1-2.3-1.3c-3.5-1.3-4.6-0.4-5.4,2.4c-0.8,2.7-1.7,5.5-2.5,8.2c-0.2,0.7-0.9,1.6-0.3,2
			                            c0.6,0.4,1.2-0.6,1.7-1c1.9-1.3,3.7-1.2,5.2,0.5c0.4,0.5,0.8,1.1,1.2,1.6c1.7,2.7,3.4,5.4,5.1,8.1c0.7,1,0.7,1.9,0.2,2.9
			                            c-1.5,3.4-2.9,6.8-4.2,10.3c-0.4,0.9-0.9,1.2-1.8,1.2c-9.6,0-19.3,0.1-28.9,0.1c-0.7,0-1.1,0.2-1.3,0.9c-0.7,2-2.3,6.8-2.3,6.8h39
			                            C59.5,52.4,59.6,52.2,59.7,52z"></path>
                                </g>
                            </svg>{systemConfig.systemName}
                        </Navbar.Brand>
                    </Container>
                </Navbar>
                <br />
                <br />
                <div className="d-flex flex-column min-vh-90 justify-content-center align-items-center">
                    <h1>*************** WARNING! ***************</h1>
                    <div style={{ maxWidth: '800px' }}>
                        <p>You are accessing a U.S. Government information system, which includes (1) this computer, (2) this computer network, (3) all computers connected to this network, and (4) all devices and storage media attached to this network or to a computer on this network. This information system is provided for U.S. Government-authorized use only. Unauthorized or improper use of this system may result in disciplinary action, as well as civil and criminal penalties.</p>
                        <br />
                        <p>By using this information system, you understand and consent to the following:</p>
                        <ul>
                            <li>You have no reasonable expectation of privacy regarding any communications or data transiting or stored on this information system. At any time, the government may for any lawful government purpose monitor, intercept, search and seize any communication or data transiting or stored on this information system.</li>
                            <li>Communications or data transiting or stored on this information system may be disclosed or used for any lawful government purpose. Your consent is final and irrevocable.</li>
                            <li>You may not rely on any statements or informal policies purporting to provide you with any expectation of privacy regarding communications on this system.</li>
                        </ul>
                        <br />
                        <p>For further information see the Department order on Use and Monitoring of Department Computers and Computer Systems.</p>
                    </div>
                    <br />
                    <Button text="Accept & Continue" onClick={this.continueLogin} />
                </div>
            </div>
        );
    }
};