
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Lookup, Form, FormItem, Export } from 'devextreme-react/data-grid';
import { Item, Label } from 'devextreme-react/form';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import * as $ from 'jquery';
import Button from 'devextreme-react/button';
import { alert } from 'devextreme/ui/dialog';

const facilityDataSource = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('Facility');
    },
    insert: (values) => {
        return PostAPIPromise('Facility', values);
    },
    remove: (key) => {
        return DeleteAPIPromise('Facility/' + key)
            .then((result) => {
                if (result.status == -2) {
                    alert('Facility is referenced in the system and cannot be deleted. Consider inactivating.')
                }
            });
    },
    update: (key, values) => {
        console.log('Updating', values);
        return PostAPIPromise('Facility', values);
    }
});

export class FacilityContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.facilityPOCsRender = this.facilityPOCsRender.bind(this);
        this.calculateGeolocationRender = this.calculateGeolocationRender.bind(this);

        this.state = {
            aors: [],
            states: [],
            cities: []
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return "Facility Management";
    }

    GetDropDownData = async () => {
        const stateData = await this.FetchAPI('DictionaryState');
        const aorData = await this.FetchAPI('DictionaryAOR');
        const cityData = await this.FetchAPI('DictionaryCity');

        this.setState({
            states: stateData,
            aors: aorData,
            cities: cityData
        });
        //console.log('AOR');
        //console.log(this.state.aors);
    }

    onInitNewRow(e) {
        // Set active value default to true
        e.data.isActive = true;
        e.data.frC_Facility = false;
        e.data.emergencyShelter = false;
        e.data.isAirport = false;
        e.data.isReunificationLocation = false;

        e.data.facilityPOCs = [];
    }

    onSaved = (cell, e) => {
        // TODO: Maybe make this an actual value
        //console.log('leave hours saved', cell);
        //console.log('leave hours saved', e);
        cell.setValue(cell.data.facilityPOCs);
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    onRowValidating = (e) => {
        console.log('validating', e);

        var email = ('email' in e.newData ? e.newData.email : (e.oldData ? e.oldData.email : null));
        var phone1 = ('phone1' in e.newData ? e.newData.phone1 : (e.oldData ? e.oldData.phone1 : null));
        var phone2 = ('phone2' in e.newData ? e.newData.phone2 : (e.oldData ? e.oldData.phone2 : null));

        console.log(email);
        console.log(!email);
        console.log(email == '');

        if ((!email || email == '') && (!phone1 || phone1 == '') && (!phone2 || phone2 == '')) {
            e.isValid = false;
            e.errorText = 'At least one contact method is required';
        }
    }

    calculateGeolocationRender(cell) {
        let onSavedSecond = this.onSavedSecond.bind(this, cell);

        return <Button text="Calculate Latitude/Longitude" onClick={onSavedSecond} />
    }

    setGeoLocationValues(rowData, value, currentRowData) {
        //console.log('Incoming New Value', value);

        var parts = value.split(',')

        rowData.latitude = parts[0];
        rowData.longitude = parts[1];
        rowData.timeZone = parts[2];
    }

    onSavedSecond = async (cell, e) => {
        // TODO: Maybe make this an actual value
        console.log('Calculating geolocation', cell);

        // Check for full address information
        if (!cell.row.data.address1 || !cell.row.data.city || !cell.row.data.state || !cell.row.data.zipCode) {
            alert('Please provide Address, City, State, and Zip Code');
        }
        else {
            var fullAddress = cell.row.data.address1 + ' ' + cell.row.data.city + ' ' + cell.row.data.state + ' ' + cell.row.data.zipCode;

            var response = await this.FetchAPI('Placement/GetGeolocationCoordinates?address=' + fullAddress);
            if (response.status = 1) {
                // Populate results
                cell.setValue(response.latitude + ',' + response.longitude + ',' + response.timeZone);
            }
            else {
                alert(response.message);
            }
        }
    }

    facilityPOCsRender(cell) {
        //console.log('POCs render', cell);
        let onSaved = this.onSaved.bind(this, cell);

        return <DataGrid dataSource={cell.data.facilityPOCs} onSaved={onSaved}
            keyExpr="id" showBorders={true} allowColumnResizing={true} onRowValidating={this.onRowValidating}>
            <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()} />
            <Column dataField="name">
                <RequiredRule />
            </Column>
            <Column dataField="email" />
            <Column dataField="phone1" />
            <Column dataField="phone2" />
        </DataGrid>;
    }

    render() {
        
        return (
            <div className="container-fluid">
                <h1>Facility Management</h1>

                <div className="row">
                    <DataGrid dataSource={facilityDataSource}
                        showBorders={true} allowColumnResizing={true}
                        keyExpr="id" onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating} onExporting={this.onExporting}>
                        <Export enabled={true} fileName={"facilityManagement"} />
                        <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()}>
                            <Form colCount={3}>
                                <Item itemType="group" colSpan={3} colCount={3}>
                                    <Item dataField="name" />
                                    <Item dataField="address1" />
                                    <Item dataField="address2" />

                                    <Item dataField="city" />
                                    <Item dataField="state" />
                                    <Item dataField="zipCode" />

                                    <Item dataField="departureCityCode" />
                                    <Item dataField="aorCode" />
                                    <Item dataField="shelter" />
                                    <Item dataField="isActive" />

                                    <Item dataField="detlocsCode" />
                                    <Item dataField="note" colSpan={2} />

                                    <Item dataField="frC_Facility" />
                                    <Item dataField="emergencyShelter" />
                                    <Item dataField="isAirport" />

                                    <Item dataField="isReunificationLocation" colSpan={3} />

                                    <Item dataField="latitude" editorOptions={{ readOnly: true }} />
                                    <Item dataField="longitude" editorOptions={{ readOnly: true }} />
                                    <Item dataField="timeZone" editorOptions={{ readOnly: true }} />

                                    <Item dataField="geoLocationCalculate" />
                                </Item>

                                <Item colSpan={3} dataField="facilityPOCs" />
                            </Form>
                        </Editing>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />

                        <Column caption="Name" dataField="name">
                            <RequiredRule />
                            <StringLengthRule max={200} />
                        </Column>
                        <Column caption="Address" dataField="address1">
                            <RequiredRule />
                            <StringLengthRule max={100} />
                        </Column>
                        <Column caption="Address 2" dataField="address2" visible={false} >
                            <StringLengthRule max={100} />
                        </Column>
                        <Column caption="City" dataField="city">
                            <RequiredRule />
                            <StringLengthRule max={50} />
                        </Column>
                        <Column dataField="state">
                            <RequiredRule />
                            <Lookup allowClearing={true} dataSource={this.state.states} displayExpr="fullName" valueExpr="name" /> 
                        </Column>
                        <Column dataField="zipCode">
                            <RequiredRule />
                            <StringLengthRule max={10} />
                        </Column>
                        <Column caption="Departure City" dataField="departureCityCode">
                            <RequiredRule />
                            <Lookup allowClearing={true} dataSource={this.state.cities} displayExpr="cityName" valueExpr="cityCode" />
                        </Column>
                        <Column caption="AOR" dataField="aorCode">
                            <RequiredRule />
                            <Lookup allowClearing={true} dataSource={this.state.aors} displayExpr="aorCode" valueExpr="aorCode" />
                        </Column>
                        <Column dataField="shelter" visible={false} >
                            <StringLengthRule max={200} />
                        </Column>
                        <Column caption="Active" dataField="isActive" />
                        <Column caption="DETLOCs Code" dataField="detlocsCode">
                            <StringLengthRule max={20} />
                        </Column>

                        <Column dataField="note" visible={false} >
                            <StringLengthRule max={100} />
                        </Column>

                        <Column caption="Is FRC" dataField="frC_Facility" />
                        <Column caption="Is Emergency Facility" dataField="emergencyShelter" />
                        <Column caption="Is Airport" dataField="isAirport" />

                        <Column caption="Is Reunification Location" dataField="isReunificationLocation" />

                        <Column caption="Latitude" dataField="latitude">
                            <RequiredRule />
                        </Column>
                        <Column caption="Longitude" dataField="longitude">
                            <RequiredRule />
                        </Column>

                        <Column caption="Time Zone" dataField="timeZone">
                            <RequiredRule />
                        </Column>

                        <Column dataField="geoLocationCalculate" editCellRender={this.calculateGeolocationRender} visible={false}
                        setCellValue={this.setGeoLocationValues}>
                            <FormItem>
                                <Label visible={false} />
                            </FormItem>
                        </Column>

                        <Column caption="POCs" visible={false} dataField="facilityPOCs" editCellRender={this.facilityPOCsRender} />
                    </DataGrid>
                    <br />
                </div>
            </div>
        );
    }
}