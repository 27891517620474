
import React from 'react';
import BaseComponent, { withParams } from '../BaseComponent.js';
import DataGrid, { FilterRow, Column, Pager, Paging } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import Form, { Item, Label, RequiredRule } from 'devextreme-react/form';
import FileUploader from 'devextreme-react/file-uploader';
import { msalAuth } from '../../msal/MsalAuthProvider';
import { msalConfig } from '../../msal/MsalConfig';
import { LoadPanel } from 'devextreme-react/load-panel';
import * as moment from 'moment';

export class UploadRequestContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.formElement = React.createRef();

        this.state = {
            loading: false,

            token: '',

            importResult: null,
            importMessage: null,
            requestsToImport: [],
            returnPath: null,

            isApprovalDateVisible: false,
            isAdmissionDateVisible: false,
            isSponsorInformationVisible: false,
            isFamilyUnitNumberVisible: false,
            isSubclassificationCodeVisible: false,

            importDetails: {
                orrPlacementDate: ''
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props);
        //console.log(this.props.match);

        //console.log('return path ' + this.props.match.params.returnPath);

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.returnPath != undefined) {
            await this.setState({ returnPath: this.props.match.params.returnPath });
        }

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Upload Request Container");
    }

    ImportRecords = async (e) => {

        var result = this.filterForm.instance().validate();

        if (result.isValid) {
            await this.setState({
                loading: true
            });

            try {

                var recordsToImport = this.state.requestsToImport.filter((item) => item.willImport);

                // Validate for duplicated A numbers
                for (var i = 0; i < recordsToImport.length; i++) {
                    if ((recordsToImport.filter(a => (a.aNumber == recordsToImport[i].aNumber || (!a.aNumber && !recordsToImport[i].aNumber)) && (a.subjectID == recordsToImport[i].subjectID || (!a.subjectID && !recordsToImport[i].subjectID)) && a.currentShelter == recordsToImport[i].currentShelter && a.assignedShelter == recordsToImport[i].assignedShelter)).length > 1) {
                        alert('Error encountered during import. Multiple rows cannot have the same A number, Subject ID, current shelter, and assigned shelter. The first duplicated row found contains the A number/Subject ID: ' + recordsToImport[i].aNumber + '/' + recordsToImport[i].subjectID);
                        return;
                    }
                }

                const result = await this.PostAPI('Placement/BulkImport', { requestsToImport: recordsToImport, orrPlacementDate: moment(this.state.importDetails.orrPlacementDate).format('YYYY-MM-DDTHH:mm') });

                if (result.status == 1) {
                    alert(result.message);

                    var returnPath = '/search-placements';
                    
                    if (this.state.returnPath) {
                        returnPath = '/' + this.state.returnPath;
                    }

                    this.props.navigate('/');
                    //this.props.navigate('/search-placements');
                    this.props.navigate(returnPath);
                }
                else {
                    alert(result.message);
                    alert('Error encountered during import. Please contact MVM Command Center for assistance.');
                }
            }
            finally {
                await this.setState({
                    loading: false
                });
            }
        } {}
    }

    onClick() {
        this.setState({
            importResult: null,
            importMessage: null,
            requestsToImport: []
        });

        //notify('Uncomment the line to enable sending a form to the server.');
        this.fileUpload.instance().upload();
    }

    onBeforeSend = (e) => {
        // console.log(e);

        e.request.setRequestHeader('Authorization', `Bearer ${this.state.token}`);
    }

    onUploaded = (e) => {
        //console.log('Uploaded', e);

        var response = JSON.parse(e.request.response);

        var isAdmissionDateVisible = true;
        var isApprovalDateVisible = true;
        var isSponsorInformationVisible = true;
        var isFamilyUnitNumberVisible = true;
        var isSubclassificationCodeVisible = true;

        if (response.requestsToImport && response.requestsToImport.length > 0) {
            isAdmissionDateVisible = response.requestsToImport[0].isAdmissionDateVisible;
            isApprovalDateVisible = response.requestsToImport[0].isApprovalDateVisible;
            isSponsorInformationVisible = response.requestsToImport[0].isSponsorInformationVisible;
            isFamilyUnitNumberVisible = response.requestsToImport[0].isFamilyUnitNumberVisible;
            isSubclassificationCodeVisible = response.requestsToImport[0].isSubclassificationCodeVisible;
        }

        this.setState({
            importResult: response.status,
            importMessage: response.message,
            requestsToImport: response.requestsToImport,

            isAdmissionDateVisible: isAdmissionDateVisible,
            isApprovalDateVisible: isApprovalDateVisible,
            isSponsorInformationVisible: isSponsorInformationVisible,
            isFamilyUnitNumberVisible: isFamilyUnitNumberVisible,
            isSubclassificationCodeVisible: isSubclassificationCodeVisible
        });

        this.fileUpload.instance().reset();
    }

    GetData = async () => {
        console.log('get data');

        var requestScope = msalConfig.auth.clientId + '/.default';

        const accessTokenRequest = {
            scopes: [requestScope]
        }

        var token = await msalAuth.acquireTokenSilent(accessTokenRequest)

        //console.log(token.accessToken);

        this.setState({
            token: token.accessToken,
            importDetails: {
                orrPlacementDate: moment().format('YYYY-MM-DD HH:mm')
            }
        });
    }

    render() {
        //console.log('Rendering');
        //console.log(this.state);

        var clientTimeZone = moment.tz.guess();
        var clientTZAbbreviation = moment.tz(clientTimeZone).format('z');

        return (
            <div className="container-fluid">
                <h1>Upload Request</h1>

                <br />
                <h3>Bulk Upload</h3>
                <div className="fileuploader-container">
                    <FileUploader ref={ref => this.fileUpload = ref} selectButtonText="Select Import File" labelText="" uploadUrl={`API/Placement/BulkUpload`}
                        onBeforeSend={this.onBeforeSend} onUploaded={this.onUploaded} multiple={false} uploadMode="useButtons" name="photo" />
                </div>
                <br />

                {this.state.importResult &&
                    <div>
                        <p>{this.state.importMessage}</p>
                    <br />
                    <Form formData={this.state.importDetails} ref={ref => this.filterForm = ref} colCount={3}>
                        <Item dataField="orrPlacementDate" editorType="dxDateBox" editorOptions={{ displayFormat: `MM/dd/yyyy HH:mm '${clientTZAbbreviation}'`, type: 'datetime' }}>
                            <RequiredRule />
                        </Item>
                    </Form>
                    <br />
                        <DataGrid dataSource={this.state.requestsToImport} showBorders={true} allowColumnResizing={true}>
                            <Column dataField="willImport" caption="Will Import?" cellRender={function (options) { return (<img width='20px' height='20px' src={options.row.data.willImport ? 'yesbutton.png' : 'nobutton.png'} />); }} />
                            <Column dataField="aNumber" caption="A Number" cellRender={function (options) { return (<div title={options.row.data.aNumberMessage} style={{ backgroundColor: `${options.row.data.isANumberValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.aNumber}&nbsp;</div>); }} />
                            <Column dataField="AdmissionDate" caption="Admission Date" visible={this.state.isAdmissionDateVisible} cellRender={function (options) { return (<div title={options.row.data.admissionDateMessage} style={{ backgroundColor: `${options.row.data.isAdmissionDateValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.admissionDate}&nbsp;</div>); }} />
                            <Column dataField="ApprovalDate" caption="Approval Date" visible={this.state.isApprovalDateVisible} cellRender={function (options) { return (<div title={options.row.data.approvalDateMessage} style={{ backgroundColor: `${options.row.data.isApprovalDateValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.approvalDate}&nbsp;</div>); }} />
                            <Column dataField="lastName" caption="Last Name" cellRender={function (options) { return (<div title={options.row.data.lastNameMessage} style={{ backgroundColor: `${options.row.data.isLastNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.lastName}&nbsp;</div>); }} />
                            <Column dataField="firstName" caption="First Name" cellRender={function (options) { return (<div title={options.row.data.firstNameMessage} style={{ backgroundColor: `${options.row.data.isFirstNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.firstName}&nbsp;</div>); }} />
                            <Column dataField="middleName" caption="Middle Name" />
                            <Column dataField="dob" caption="DOB" cellRender={function (options) { return (<div title={options.row.data.dobMessage} style={{ backgroundColor: `${options.row.data.isDOBValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.dob}&nbsp;</div>); }} />
                            <Column dataField="gender" caption="Gender" cellRender={function (options) { return (<div title={options.row.data.genderMessage} style={{ backgroundColor: `${options.row.data.isGenderValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.gender}&nbsp;</div>); }} />
                            <Column dataField="nationality" caption="Nationality" cellRender={function (options) { return (<div title={options.row.data.nationalityMessage} style={{ backgroundColor: `${options.row.data.isNationalityValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.nationality}&nbsp;</div>); }} />
                            <Column dataField="familyGroupID" caption="Family Group ID" cellRender={function (options) { return (<div title={options.row.data.familyGroupIDMessage} style={{ backgroundColor: `${options.row.data.isFamilyGroupIDValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.familyGroupID}&nbsp;</div>); }} />
                            <Column dataField="movementTypeCode" caption="Type" cellRender={function (options) { return (<div title={options.row.data.movementTypeCodeMessage} style={{ backgroundColor: `${options.row.data.isMovementTypeCodeValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.movementTypeCode}&nbsp;</div>); }} />
                            <Column dataField="currentShelter" caption="Current Shelter/Program" cellRender={function (options) { return (<div title={options.row.data.currentShelterMessage} style={{ backgroundColor: `${options.row.data.isCurrentShelterValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.currentShelter}&nbsp;</div>); }} />
                            <Column dataField="assignedShelter" caption="Assigned Shelter/Program" cellRender={function (options) { return (<div title={options.row.data.assignedShelterMessage} style={{ backgroundColor: `${options.row.data.isAssignedShelterValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.assignedShelter}&nbsp;</div>); }} />
                            <Column dataField="sponsorName" caption="Sponsor Name" visible={this.state.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorNameMessage} style={{ backgroundColor: `${options.row.data.isSponsorNameValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorName}&nbsp;</div>); }} />
                            <Column dataField="sponsorPhone" caption="Phone No." visible={this.state.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorPhoneMessage} style={{ backgroundColor: `${options.row.data.isSponsorPhoneValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorPhone}&nbsp;</div>); }} />
                            <Column dataField="sponsorAlternatePhone" caption="Alt. Phone No." visible={this.state.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorAlternatePhoneMessage} style={{ backgroundColor: `${options.row.data.isSponsorAlternatePhoneValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorAlternatePhone}&nbsp;</div>); }} />
                            <Column dataField="sponsorAddress" caption="Street Address" visible={this.state.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorAddressMessage} style={{ backgroundColor: `${options.row.data.isSponsorAddressValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorAddress}&nbsp;</div>); }} />
                            <Column dataField="sponsorCity" caption="City" visible={this.state.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorCityMessage} style={{ backgroundColor: `${options.row.data.isSponsorCityValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorCity}&nbsp;</div>); }} />
                            <Column dataField="sponsorState" caption="State" visible={this.state.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorStateMessage} style={{ backgroundColor: `${options.row.data.isSponsorStateValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorState}&nbsp;</div>); }} />
                            <Column dataField="sponsorZipCode" caption="Zip Code" visible={this.state.isSponsorInformationVisible} cellRender={function (options) { return (<div title={options.row.data.sponsorZipCodeMessage} style={{ backgroundColor: `${options.row.data.isSponsorZipCodeValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.sponsorZipCode}&nbsp;</div>); }} />
                            <Column dataField="transportUnit" caption="Transport Unit" cellRender={function (options) { return (<div title={options.row.data.transportUnitMessage} style={{ backgroundColor: `${options.row.data.isTransportUnitValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.transportUnit}&nbsp;</div>); }} />
                            <Column dataField="familyUnitNumber" caption="Family Unit #" visible={this.state.isFamilyUnitNumberVisible} cellRender={function (options) { return (<div title={options.row.data.familyUnitNumberMessage} style={{ backgroundColor: `${options.row.data.isFamilyUnitNumberValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.familyUnitNumber}&nbsp;</div>); }} />
                            <Column dataField="subclassificationCode" caption="Subclassification Code" visible={this.state.isSubclassificationCodeVisible} cellRender={function (options) { return (<div title={options.row.data.subclassificationCodeMessage} style={{ backgroundColor: `${options.row.data.isSubclassificationCodeValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.subclassificationCode}&nbsp;</div>); }} />
                            <Column dataField="subjectID" caption="Subject ID" cellRender={function (options) { return (<div title={options.row.data.subjectIDMessage} style={{ backgroundColor: `${options.row.data.isSubjectIDValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.subjectID}&nbsp;</div>); }} />
                            <Column dataField="authority" caption="Authority Code" cellRender={function (options) { return (<div title={options.row.data.authorityMessage} style={{ backgroundColor: `${options.row.data.isAuthorityValid ? 'transparent' : '#f1929f'}` }}>{options.row.data.authority}&nbsp;</div>); }} />
                        </DataGrid>
                    </div>
                }

                <br />
                {this.state.importResult == 1 &&
                    <Button className="button" text="Import File" type="success" onClick={this.ImportRecords} />
                }
                <br />
                <br />

                <LoadPanel
                    visible={this.state.loading} />
            </div>
        );
    }
}

export default withParams(UploadRequestContainer);