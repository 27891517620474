import React from 'react';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import InactivityHelper from './InactivitySession';
import SessionHelper from './Session';

export default class TimeoutPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showInactivityWarning: false,
            secondsLeft: 0
        };
    }

    hideInactiveWarning = async (e) => {
        console.log('Hiding');
        // reset the expiry
        SessionHelper.setExpiry();

        // and reset the timeout function
        SessionHelper.resetExpiryTimeout();

        InactivityHelper.setExpiry();

        InactivityHelper.resetExpiryTimeout();

        await this.setState({
            showInactivityWarning: false
        });
    }

    calculateTimeLeft = () => {
        //console.log('Got here initially');

        if (InactivityHelper.isTokenExpired) {
            //console.log('Token expired');
            let secondsleft = this.state.secondsLeft - 1;

            secondsleft = secondsleft < 0 ? 0 : secondsleft;

            this.setState({
                secondsLeft: secondsleft
            });

            setTimeout(() => {
                this.calculateTimeLeft()
            }, 1000);
        }
        else {
            //console.log('Token not expired any longer');
        }
    }

    async componentDidMount() {
        InactivityHelper.expiryTimeoutCallback = () => {
            //console.log('Inactivity Warning');
            this.setState({
                showInactivityWarning: true,
                secondsLeft: 60
            });

            setTimeout(() => this.calculateTimeLeft(), 1000);
        };

        InactivityHelper.startExpiryTimeout();
    }

    render() {

        return (
            <Popup visible={this.state.showInactivityWarning} dragEnabled={false} onHiding={this.hideInactiveWarning}
                closeOnOutsideClick={true} showTitle={false} width={400} height={200}>
                <div onMouseDown={this.hideInactiveWarning}>
                    <p>Your session will time out in {this.state.secondsLeft} second(s)</p>
                    <br />
                    <p>Click to continue</p>

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Continue" onClick={this.hideInactiveWarning} />
                    </div>
                </div>
            </Popup>
        );
    }
};