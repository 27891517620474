
import React from 'react';

// TODO: ABG, fix this when we convert basecomponent
// @ts-ignore
import { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise, onExportingFileName } from '../BaseComponent.js';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Editing, RequiredRule, StringLengthRule, Column, Lookup, Export, MasterDetail, CustomRule } from 'devextreme-react/data-grid';
import { DataGridTypes } from "devextreme-react/data-grid"
import * as $ from 'jquery';
import { ValidationCallbackData } from 'devextreme/common.js';

const allOrgCode : string = 'ALL';
const allOrgName: string = 'Any or N/A';

const orgCodeStore = new CustomStore({
    key: 'orgCode',
    load: () => {
        return FetchAPIPromise('SupervisoryOrg/GetForContract').then((result: any[]) => {
            result.unshift({ orgCode: allOrgCode, orgName: allOrgName });

            return result;
        });
    },
    byKey: (key) => {
        if (key == allOrgCode) {
            return { orgCode: allOrgCode, orgName: allOrgName };
        }
        else {
            return FetchAPIPromise('SupervisoryOrg/GetByCode/' + key);
        }
    }
});

const NotificationManagement: React.FunctionComponent = () => {

    const customDataSource = new CustomStore({
        key: 'id',
        load: () => {
            return FetchAPIPromise('SysNotificationType');
        }
    });

    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        onExportingFileName(e, "notificationTypes");
    }

    return (
        <div className="container">
            <h1>Notification Management</h1>

            <DataGrid dataSource={customDataSource}
                showBorders={true} allowColumnResizing={true}
                keyExpr="id" onExporting={onExporting}>
                <Export enabled={true} />

                <FilterRow visible={true} />

                <Column caption="Unique Name" dataField="shortName" />
                <Column caption="Description" dataField="description" />

                <MasterDetail enabled={true} component={NotificationAudienceList} autoExpandAll={true} />
            </DataGrid>
        </div>
    );
}

interface Sys_NotificationType {
    id: string
}

function NotificationAudienceList(props: DataGridTypes.MasterDetailTemplateData) {

    //console.log(props);

    const audienceStore = new CustomStore({
        key: 'id',
        load: () => {
            return FetchAPIPromise('NotificationAudience/GetByNotificationType?notificationTypeId=' + props.data.data.id).then((result: any[]) => {

                for (var i = 0; i < result.length; i++) {
                    if (result[i].orgCode == null) {
                        result[i].orgCode = allOrgCode;
                    }
                }

                return result;
            });
        },
        update: (key, values) => {
            values.notificationTypeId = props.data.data.id;

            if (values.orgCode == allOrgCode) {
                values.orgCode = null;
            }

            return PostAPIPromise('NotificationAudience/UpdateAudience', values);
        },
        insert: (values) => {
            values.notificationTypeId = props.data.data.id;

            if (values.orgCode == allOrgCode) {
                values.orgCode = null;
            }

            return PostAPIPromise('NotificationAudience/UpdateAudience', values);
        },
        remove: (key) => {
            return DeleteAPIPromise('NotificationAudience/' + key);
        }
    });

    const onRowUpdating = (options: DataGridTypes.RowUpdatingEvent) => {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    const customValidation = (params: ValidationCallbackData) => {
        //console.log(params);

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        const emailArray = params.value.split(';').map((email: string) => email.trim());

        for (let email of emailArray) {
            if (!emailRegex.test(email)) {
                return false;
            }
        }

        return true;
    }

    return (
        <div>
            <DataGrid dataSource={audienceStore} onRowUpdating={onRowUpdating}
                showBorders={true} allowColumnResizing={true} 
                keyExpr="id">
                <Editing allowAdding={true} mode="popup" allowUpdating={true} allowDeleting={true} />

                <Column dataField="recipientList" caption="Recipient List">
                    <RequiredRule />
                    <CustomRule
                        type="custom"
                        message={"Recipient List must contain one or more valid email addresses separated by a semicolon(';')"}
                        validationCallback={customValidation}
                    />
                    <StringLengthRule max={1000} />
                </Column>
                <Column dataField="title" caption="Title">
                    <StringLengthRule max={200} />
                </Column>
                <Column dataField="purpose" caption="Purpose">
                    <StringLengthRule max={200} />
                </Column>
                <Column dataField="orgCode" caption="For Org" visible={props.data.data.isOrganizationDependent}>
                    <RequiredRule />
                    <Lookup allowClearing={true} dataSource={orgCodeStore} displayExpr='orgName' valueExpr='orgCode' />
                </Column>
            </DataGrid>
        </div>
    );
}

export default NotificationManagement;