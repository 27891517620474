
import Button from 'devextreme-react/button';
import Form, { GroupItem, Item, Label } from 'devextreme-react/form';
import List from 'devextreme-react/list';
import MultiView, { Item as MultiViewItem } from 'devextreme-react/multi-view';
import { Popup } from 'devextreme-react/popup';
import * as moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FetchAPIPromise, PostAPIPromise } from '../BaseComponent';
import DataGrid, { FilterRow, Button as GridButton, Column } from 'devextreme-react/data-grid';
import { alert } from 'devextreme/ui/dialog';

import HoursOfServiceLog from './HoursOfServiceLog';

// QUES: How about cases where driver has more than one work order?
function WorkOrderHoursOfServiceWizardContainer() {

    const hosWizard = useRef();
    const dayList = useRef();

    const KilometerToMileConversion = 0.621371;

    const [hosCoreInfo, setHosCoreInfo] = useState({});
    const [existingHosLogs, setExistingHosLogs] = useState([]);

    const [responsibleOfficeTimeZone, setResponsibleOfficeTimeZone] = useState('');

    const MaxShorthaulShiftTime = 14.0;

    const MaxShorthaulDistance = 172.6;

    const [isViewingHosLogWizard, setIsViewingHosLogWizard] = useState(false);

    const [hosWizardSelectedIndex, setHosWizardSelectedIndex] = useState(0);

    //const [isBusy, setIsBusy] = useState(false);

    const [selectedDriver, setSelectedDriver] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [driverFilteredVehicleSegments, setDriverFilteredVehicleSegments] = useState([]);

    const [additionalUnclaimedSegmentsData, setAdditionalUnclaimedSegmentsData] = useState([]);
    const [additionalIncompleteSegmentsData, setAdditionalIncompleteSegmentsData] = useState([]);

    const params = useParams();

    const [logPopups, setLogPopups] = useState([]);

    useEffect(() => {

        async function fetchData() {

            //setIsBusy(true);

            try {

                // TODO: Pull the driving for this work order and detect the calendar days

                // TODO: ... "this work order involves a Commercial Motor Vehicle and does NOT qualify for shorthaul exemption"

                const workOrderData = await FetchAPIPromise('WorkOrder/GetHoursOfServiceInformation?workOrderId=' + params.id);

                var responsibleOfficeData = await FetchAPIPromise('Office/GetById?id=' + workOrderData.responsibleOfficeId);

                const existingHosLogsData = await FetchAPIPromise('WorkOrder/GetHoursOfServiceLogsByWorkOrderId?workOrderId=' + params.id);

                //console.log(existingHosLogsData);

                if (workOrderData.transitMetricsList && workOrderData.transitMetricsList.length > 0) {
                    // Find longest distance
                    const maxLocationDistance = workOrderData.transitMetricsList.reduce((prev, current) => (prev.straightLineDistance > current.straightLineDistance) ? prev : current, 0);

                    //console.log('Longest distance location', maxLocationDistance.straightLineDistance);

                    // Find shift hours count for employees
                    // I THINK if ANY shift on the work order extends beyond 14 hours, we're outside of the limit
                    // TODO: Make some hover dialog that shows the reasons why it qualifies or does not
                    const minClockIn = workOrderData.shiftHours.reduce((prev, current) => (prev.clockInDateTime < current.clockInDateTime) ? prev : current, 0);
                    const maxClockOut = workOrderData.shiftHours.reduce((prev, current) => (prev.clockOutDateTime > current.clockOutDateTime) ? prev : current, 0);

                    //console.log('Min Clockin', minClockIn.clockInDateTime);
                    if (maxClockOut && maxClockOut.clockOutDateTime) {
                        //console.log('Max Clockout', maxClockOut.clockOutDateTime);
                    }
                    else {
                        // No clock outs yet, look at current timestamp
                    }

                    var timeDifferenceInHours = ((maxClockOut && maxClockOut.clockOutDateTime) ? moment(maxClockOut.clockOutDateTime) : moment()).diff(moment(minClockIn.clockInDateTime), 'minute') / 60;

                    //console.log('Total time diff in hours', timeDifferenceInHours);

                    workOrderData.exceededShorthaulShiftTime = timeDifferenceInHours >= MaxShorthaulShiftTime;
                    workOrderData.exceededShorthaulDistance = maxLocationDistance.straightLineDistance > MaxShorthaulDistance;

                    // TODO: What about inbound/outbound trips?
                    if (workOrderData.exceededShorthaulShiftTime || workOrderData.exceededShorthaulDistance) {
                        workOrderData.qualifiesForShorthaul = false;
                    }
                    else {
                        workOrderData.qualifiesForShorthaul = true;
                        workOrderData.hasAllHoursOfServiceLogs = 'N/A';
                    }
                }
                //else {
                //    // No legs, no actual transport
                //    row.qualifiesForShorthaul = 'N/A';
                //}

                //console.log(workOrderData.qualifiesForShorthaul);
                //console.log(workOrderData.hasAllHoursOfServiceLogs);

                workOrderData.driverCount = workOrderData.eligibleDriverList.length;

                var actualTotalDrivingDistance = workOrderData.vehicleSegmentList.reduce((total, item) => total = total + item.distanceKilometers, 0);

                workOrderData.actualDrivingDistance = (actualTotalDrivingDistance * KilometerToMileConversion).toFixed(2)

                var uniqueDayList = [];

                workOrderData.vehicleSegmentList.map((item) => {
                    var momentDate = moment(item.startDate).format('MM/DD/YYYY');

                    if (!uniqueDayList.includes(momentDate)) {
                        uniqueDayList.push(momentDate);
                    }
                });

                workOrderData.vehicleSegmentList.map((item) => {
                    var momentDate = moment(item.endDate).format('MM/DD/YYYY');

                    if (!uniqueDayList.includes(momentDate)) {
                        uniqueDayList.push(momentDate);
                    }
                });

                //console.log(uniqueDayList);

                workOrderData.eligibleDayList = uniqueDayList;

                setHosCoreInfo(workOrderData);

                setExistingHosLogs(existingHosLogsData);

                setResponsibleOfficeTimeZone(responsibleOfficeData.timeZone);

                // TODO: 3) Day list
                // TODO: 4) Look for other segments for that vehicle? Or that driver?
                // TODO: 5) Look for segments that may need to be claimed, either empty or someone not on the trip?
            }
            finally {
                //setIsBusy(false);
            }
        }

        fetchData();
    }, []);

    const hideHosLogWizard = () => {
        setIsViewingHosLogWizard(false);
    }

    const createHosLogs = () => {
        console.log(hosWizardSelectedIndex);
        console.log(selectedDay);

        setHosWizardSelectedIndex(0);
        setIsViewingHosLogWizard(true);

        hosWizard.current.instance().option("selectedIndex", [0]);
    }

    const eligibleDriverRender = (data, index) => {
        return (
            <div>{data.firstName} {data.lastName} ({data.number})</div>
        );
    }

    const vehicleSegmentRender = (data, index) => {
        return (
            <div>{data.vehicleName} {data.lastName} ({data.number})</div>
        );
    }

    const onDriverSelectionChanged = (e) => {
         //console.log(e);

        if (e.addedItems && e.addedItems.length > 0) {
            setSelectedDriver(e.addedItems[0].number);

            setHosWizardSelectedIndex(1);
            hosWizard.current.instance().option("selectedIndex", [1]);
        }
    };

    const onDaySelectionChanged = async (e) => {
        //console.log(e);

        if (e.addedItems.length > 0) {
            var selectedDay = e.addedItems[0];

            // TODO: TIME ZONE?????
            // Need to filter the set of segments for this driver
            var selectedDriverSegmentsList = hosCoreInfo.vehicleSegmentList.filter((item) => item.driverNumber == selectedDriver);

            //console.log('First pass', selectedDriverSegmentsList);

            // Then filter by date, just in case
            selectedDriverSegmentsList = selectedDriverSegmentsList.filter((segment) => moment(segment.startDate).format('MM/DD/YYYY') == selectedDay || moment(segment.endDate).format('MM/DD/YYYY') == selectedDay);

            //console.log('Second pass', selectedDriverSegmentsList);

            selectedDriverSegmentsList = selectedDriverSegmentsList.sort((a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf());

            setSelectedDay(selectedDay);
            setHosWizardSelectedIndex(2);
            setDriverFilteredVehicleSegments(selectedDriverSegmentsList);

            // Pull extra segments
            const unclaimedSegmentsData = await FetchAPIPromise('WorkOrder/GetUnclaimedSegmentsForDayAndDriver?selectedDay=' + selectedDay + '&driverNumber=' + selectedDriver);

            const incompleteSegmentsData = await FetchAPIPromise('WorkOrder/GetIncompleteSegmentsForDayAndDriver?selectedDay=' + selectedDay + '&driverNumber=' + selectedDriver);

            console.log('Incompleted segments', incompleteSegmentsData);
            console.log(responsibleOfficeTimeZone);

            unclaimedSegmentsData.map((item) => {

                // Convert to the responsible office timezone
                item.startDate = moment.parseZone(item.startDate).tz(responsibleOfficeTimeZone).format('YYYY-MM-DDTHH:mm');
                item.endDate = moment.parseZone(item.endDate).tz(responsibleOfficeTimeZone).format('YYYY-MM-DDTHH:mm');
            });

            incompleteSegmentsData.map((item) => {

                // Convert to the responsible office timezone
                item.startDate = moment.parseZone(item.startDate).tz(responsibleOfficeTimeZone).format('YYYY-MM-DDTHH:mm');
                item.endDate = moment.parseZone(item.endDate).tz(responsibleOfficeTimeZone).format('YYYY-MM-DDTHH:mm');
            });

            setAdditionalUnclaimedSegmentsData(unclaimedSegmentsData);
            setAdditionalIncompleteSegmentsData(incompleteSegmentsData);

            hosWizard.current.instance().option("selectedIndex", [2]);
        }
    }

    const proceedToUnclaimedSegments = (e) => {
        var currentValue = hosWizardSelectedIndex;

        setHosWizardSelectedIndex(3);
        hosWizard.current.instance().option("selectedIndex", [3]);
    }

    const proceedToIncompleteSegments = (e) => {
        var currentValue = hosWizardSelectedIndex;

        setHosWizardSelectedIndex(4);
        hosWizard.current.instance().option("selectedIndex", [4]);
    }

    const goBackSegments = (e) => {
        var currentValue = hosWizardSelectedIndex;

        console.log(currentValue);

        setSelectedDay(null);
        dayList.current.instance().option("selectedItemKeys", null);

        setHosWizardSelectedIndex(2);
        hosWizard.current.instance().option("selectedIndex", [2]);
    }

    const goBackUnclaimedSegments = (e) => {
        var currentValue = hosWizardSelectedIndex;

        setHosWizardSelectedIndex(3);
        hosWizard.current.instance().option("selectedIndex", [3]);
    }

    const goBackDay = (e) => {
        var currentValue = hosWizardSelectedIndex;

        // TODO: Do this here too?
        setSelectedDay(null);
        dayList.current.instance().option("selectedItemKeys", null);

        setHosWizardSelectedIndex(currentValue - 1);
        hosWizard.current.instance().option("selectedIndex", [currentValue - 1]);
    }

    const distanceCellRender = ((cell) => {
        return (cell.value * KilometerToMileConversion).toFixed(2);
    });

    const confirmAndGenerate = async (e) => {
        const model = {
            selectedDay: selectedDay,
            selectedDriver: selectedDriver,
            additionalClaimedSegments: additionalUnclaimedSegmentsData.filter((item) => item.workOrderId == hosCoreInfo.id),
            incompleteClaimedSegments: additionalIncompleteSegmentsData.filter((item) => item.overrideDriverNumber == selectedDriver)
        };

        console.log(model);

        var returnValue = await PostAPIPromise('WorkOrder/GenerateHoursOfService', model);

        console.log(returnValue);

        if (returnValue.status == 1) {
            // Close the window
            setIsViewingHosLogWizard(false);

            setSelectedDay(null);
            setSelectedDriver(null);
            setHosWizardSelectedIndex(0);

            hosWizard.current.instance().option("selectedIndex", [0]);

            dayList.current.instance().option("selectedItemKeys", null);

            const existingHosLogsData = await FetchAPIPromise('WorkOrder/GetHoursOfServiceLogsByWorkOrderId?workOrderId=' + params.id);

            setExistingHosLogs(existingHosLogsData);
        }
        else {
            alert(returnValue.message);
        }
    }

    const closeLogPopup = async (e, id) => {
        //console.log(logPopups);

        setLogPopups(l => l.filter(item => item !== id));
    }

    const addLogPopup = async (e) => {

        var id = e.row.data.id;

        setLogPopups(oldArray => [...oldArray, id]);
    }

    const additionalUnclaimedWorkOrderCellRender = ((cell) => {
        //console.log(cell);
        return (cell.row.data.workOrderId) ? <span>Claimed <a href="#" onClick={(e) => unclaimSegment(e, cell.row.data.id)}>Unclaim</a></span> : ((!cell.row.data.workOrderId) ? <span>Unclaimed <a href="#" onClick={(e) => claimSegment(e, cell.row.data.id)}>Claim</a></span> : <span>{cell.value}</span>);
    });

    const incompleteWorkOrderCellRender = ((cell) => {
        //console.log(cell);
        return (cell.row.data.overrideDriverNumber) ? <span>Claimed <a href="#" onClick={(e) => unclaimSegmentForDriver(e, cell.row.data.id)}>Unclaim</a></span> : ((!cell.row.data.overrideDriverNumber) ? <span>Unclaimed <a href="#" onClick={(e) => claimSegmentForDriver(e, cell.row.data.id)}>Claim</a></span> : <span>{cell.value}</span>);
    });

    const claimSegment = async (e, id) => {
        e.preventDefault();

        setAdditionalUnclaimedSegmentsData(additionalUnclaimedSegmentsData.map((item) => {
            if (item.id === id) {
                return { ...item, workOrderId: hosCoreInfo.id };
            }
            return item;
        }));
    }

    const unclaimSegment = async (e, id) => {
        e.preventDefault();

        setAdditionalUnclaimedSegmentsData(additionalUnclaimedSegmentsData.map((item, i) => {
            if (item.id === id) {
                return { ...item, workOrderId: null };
            }
            return item;
        }));
    }

    const claimSegmentForDriver = async (e, id) => {
        e.preventDefault();

        setAdditionalIncompleteSegmentsData(additionalIncompleteSegmentsData.map((item) => {
            if (item.id === id) {
                return { ...item, overrideDriverNumber: selectedDriver, workOrderId: hosCoreInfo.id };
            }
            return item;
        }));
    }

    const unclaimSegmentForDriver = async (e, id) => {
        e.preventDefault();

        setAdditionalIncompleteSegmentsData(additionalIncompleteSegmentsData.map((item, i) => {
            if (item.id === id) {
                return { ...item, overrideDriverNumber: null, workOrderId: null };
            }
            return item;
        }));
    }

    const getLogPopupTitle = (id) => {
        var log = existingHosLogs.find((item) => item.id == id);

        return 'HoS Log for ' + log.display + ' for ' + moment(log.date).format('MM/DD/YYYY');
    }

    return (

        <div className="container">
            <h1>Hours of Service Wizard</h1>

            <div>
                <Form
                    id="form" className="text-center"
                    formData={hosCoreInfo} colCount={1}>

                    <GroupItem caption="Work Order Info" colCount={2}>
                        <Item dataField="name" editorOptions={{ readOnly: true }}>
                            <Label text="Name" />
                        </Item>

                        <Item dataField="number" editorOptions={{ readOnly: true }}>
                            <Label text="Number" />
                        </Item>

                        <Item dataField="planStartDate" editorType="dxDateBox" editorOptions={{
                            readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime'
                        }}>
                            <Label text="Plan Start Date" />
                        </Item>

                        <Item dataField="planEndDate" editorType="dxDateBox" editorOptions={{
                            readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime'
                        }}>
                            <Label text="Plan End Date" />
                                </Item>
                    </GroupItem>

                    <GroupItem caption="Driving Info" colCount={2}>
                        <Item dataField="transportType" editorOptions={{ readOnly: true }}>
                            <Label text="Transport Type" />
                        </Item>
                        <Item dataField="transportMethod" editorOptions={{ readOnly: true }}>
                            <Label text="Transport Method" />
                        </Item>

                        <Item dataField="driverCount" editorOptions={{ readOnly: true }}>
                            <Label text="# Drivers" />
                        </Item>
                        <Item dataField="actualDrivingDistance" editorOptions={{ readOnly: true }}>
                            <Label text="Total Distance" />
                        </Item>
                    </GroupItem>
                    </Form>
            </div>
            <br />

            <div className="text-center">
            {/*TODO: ABG other cases*/}
            <p><b>
                The DOT relevant activity for this Work Order <span style={{ color: (hosCoreInfo.qualifiesForShorthaul ? 'green' : 'red') }}>{hosCoreInfo.qualifiesForShorthaul ? 'DOES' : 'DOES NOT'}</span> qualify for Shorthaul Exemption for the following reasons:
            </b></p>

            <ul>
                <li><span style={{ color: 'green' }}><b>DID</b></span> begin and end at home office/depot</li>
                    <li><span style={{ color: (hosCoreInfo.exceededShorthaulDistance ? 'red' : 'green') }}><b>{hosCoreInfo.exceededShorthaulDistance ? 'DID NOT' : 'DID'}</b></span> operate within {MaxShorthaulDistance} miles of the home office/depot, and</li>
                    <li><span style={{ color: (hosCoreInfo.exceededShorthaulShiftTime ? 'red' : 'green') }}><b>{hosCoreInfo.exceededShorthaulShiftTime ? 'DID NOT' : 'DID'}</b></span> conclude within the Shorthaul shift time of {MaxShorthaulShiftTime} hours</li>
            </ul>

            <p><b>
                and therefore requires Hours of Service Logs for the day(s) driven
            </b></p>

                <br />
                <Button type="success" stylingMode="contained" text="Create and Process Hours of Service Log(s)" onClick={createHosLogs} />
            </div>
            <br />

            <DataGrid dataSource={existingHosLogs}>
                <Column dataField="date" caption="Date" dataType="datetime" format="MM/dd/yy" />
                <Column dataField="display" caption="Driver" />

                <Column type="buttons" dataField="buttons" caption="Actions">
                    <GridButton
                        text="View Log"
                        hint="View Log"
                        onClick={addLogPopup}
                    />
                </Column>
            </DataGrid>
            <br />

            <Popup visible={isViewingHosLogWizard} onHiding={hideHosLogWizard} dragEnabled={false}
                closeOnOutsideClick={true} showTitle={true} title="Create HoS Log(s)" width={900} height={500}>
                <MultiView ref={hosWizard} swipeEnabled={false}>
                    <MultiViewItem>
                        <div>
                            <p><b>Select Driver to generate for</b></p>

                            <List dataSource={hosCoreInfo.eligibleDriverList} itemRender={eligibleDriverRender}
                                onSelectionChanged={onDriverSelectionChanged} selectionMode='single' />
                        </div>
                    </MultiViewItem>

                    <MultiViewItem>
                        <div>
                            <p><b>Select a Day to generate for</b></p>

                            <List ref={dayList} dataSource={hosCoreInfo.eligibleDayList} onSelectionChanged={onDaySelectionChanged}
                                selectionMode='single' />
                        </div>
                        <br />
                        <Button text="Go back" onClick={goBackDay} />
                    </MultiViewItem>

                    <MultiViewItem>
                        <p><b>Review and confirm these found segments</b></p>
                        <DataGrid dataSource={driverFilteredVehicleSegments} height={250}>
                            <Column dataField="vehicleName" caption="Vehicle Name" />
                            <Column dataField="startDate" caption="Start Date" dataType="datetime" format="MM/dd/yy HH:mm" />
                            <Column dataField="endDate" caption="End Date" dataType="datetime" format="MM/dd/yy HH:mm" />
                            <Column dataField="distanceKilometers" caption="Distance (mi)" cellRender={distanceCellRender} />
                        </DataGrid>
                        <br />
                        <Button text="Go back" onClick={goBackDay} />
                        &nbsp;
                        {additionalUnclaimedSegmentsData.length > 0 &&
                            <Button text="Next" onClick={proceedToUnclaimedSegments} />
                        }
                        {additionalIncompleteSegmentsData.length > 0 &&
                            <Button text="Next" onClick={proceedToIncompleteSegments} />
                        }
                        {(!additionalUnclaimedSegmentsData || additionalUnclaimedSegmentsData.length == 0) && (!additionalIncompleteSegmentsData || additionalIncompleteSegmentsData.length == 0) && 
                            <Button text="Confirm & Generate HoS" onClick={confirmAndGenerate} />
                        }
                    </MultiViewItem>

                    <MultiViewItem>
                        <p><b>The following segments belong to you and have been detected as possibly belonging on this log.</b></p>
                        <DataGrid dataSource={additionalUnclaimedSegmentsData} height={250}>
                            <Column dataField="workOrderId" cellRender={additionalUnclaimedWorkOrderCellRender} />
                            <Column dataField="vehicleName" caption="Vehicle Name" />
                            <Column dataField="startDate" caption="Start Date" dataType="datetime" format="MM/dd/yy HH:mm" />
                            <Column dataField="endDate" caption="End Date" dataType="datetime" format="MM/dd/yy HH:mm" />
                            <Column dataField="distanceKilometers" caption="Distance (mi)" cellRender={distanceCellRender} />
                        </DataGrid>
                        <br />
                        <Button text="Go back" onClick={goBackUnclaimedSegments} />
                        &nbsp;
                        {additionalIncompleteSegmentsData.length > 0 &&
                            <Button text="Next" onClick={proceedToIncompleteSegments} />
                        }
                        {(!additionalIncompleteSegmentsData || additionalIncompleteSegmentsData.length == 0) &&
                            <Button text="Confirm & Generate HoS" onClick={confirmAndGenerate} />
                        }
                    </MultiViewItem>

                    <MultiViewItem>
                        <p><b>The following segments belong to related vehicles on this log and may need to be claimed as well.</b></p>
                        <DataGrid dataSource={additionalIncompleteSegmentsData} height={250}>
                            <Column dataField="workOrderId" cellRender={incompleteWorkOrderCellRender} />
                            <Column dataField="driverNumber" caption="Driver" />
                            <Column dataField="vehicleName" caption="Vehicle Name" />
                            <Column dataField="startDate" caption="Start Date" dataType="datetime" format="MM/dd/yy HH:mm" />
                            <Column dataField="endDate" caption="End Date" dataType="datetime" format="MM/dd/yy HH:mm" />
                            <Column dataField="distanceKilometers" caption="Distance (mi)" cellRender={distanceCellRender} />
                        </DataGrid>
                        <br />
                        {additionalUnclaimedSegmentsData.length > 0 &&
                            <Button text="Go back" onClick={goBackUnclaimedSegments} />
                        }
                        {(!additionalUnclaimedSegmentsData || additionalUnclaimedSegmentsData.length == 0) &&
                            <Button text="Go back" onClick={goBackSegments} />
                        }
                        &nbsp;
                        <Button text="Confirm & Generate HoS" onClick={confirmAndGenerate} />
                    </MultiViewItem>
                </MultiView>
            </Popup>

            {logPopups.map((id) => (
                <Popup title={() => getLogPopupTitle(id)} onHiding={(e) => closeLogPopup(e, id)} key={id} name={id} resizeEnabled={false} shading={false} width={850} height={625} visible={true}><HoursOfServiceLog logId={id} /></Popup>
            ))}
        </div>
    );
}

export default WorkOrderHoursOfServiceWizardContainer;