import React from 'react';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import { Link } from 'react-router-dom'
import Button from 'devextreme-react/button';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Column, Lookup } from 'devextreme-react/data-grid';

const checklists = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('CheckList');
    }
});

export class ComplianceChecklistManagementContainer extends BaseComponent {
    static displayName = ComplianceChecklistManagementContainer.name;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Checklist Manager");
    }

    render() {
        return (
            <div className="container">
                <h1>Checklist Management</h1>

                <div className="row">
                        <div className="d-flex justify-content-end">
                        <Link to="/create-checklist">
                            <Button icon="plus" text="Add Checklist" />
                        </Link>
                    </div>
                </div>
                <br />

                <DataGrid dataSource={checklists} keyExpr="id" allowColumnResizing={true} showBorders={true} columnAutoWidth={true}>
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <Column dataField="name" caption="Checklist Name" />

                    <Column caption="Active" dataField="isActive" />

                    <Column caption="" allowFiltering={false} allowSorting={false} allowResizing={false} dataField="id"
                        width={225}
                        cellRender={
                            function (options) {
                                return (<><Link to={`/edit-checklist/${options.row.data.id}`}><Button text="Edit" /></Link></>);
                            }
                        }
                    />
                </DataGrid>
            </div>
        );
    }
}