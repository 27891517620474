import React from 'react';
import BaseComponent, { FetchAPIPromise, DeleteAPIPromise, PostAPIPromise } from '../BaseComponent';
import PropTypes from 'prop-types';
import DataGrid, { RequiredRule, FilterRow, Column, Editing, Button as GridButton, Selection, Summary, TotalItem, Lookup, Export, Form, FormItem } from 'devextreme-react/data-grid';
import { SimpleItem, GroupItem } from 'devextreme-react/form';
import CustomStore from 'devextreme/data/custom_store';
import * as $ from 'jquery'
import Button from 'devextreme-react/button';
import Accordion, { Item as AccordionItem } from 'devextreme-react/accordion';

export class WorkOrderResourcesSupportStaffContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.rowKey = -1;
        this.dataGrid = React.createRef();
        this.state = {
            functionTypes: [],
        };
    }

    customDataSource = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);
            //console.log(this.props.workOrder);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderResourcesSupportStaffList/' + this.props.workOrder.id);
            }
        },
        insert: (values) => {
            //console.log('Insert');
            values.workOrderId = this.props.workOrder.id;
            console.log(values);
            return PostAPIPromise('WorkOrderResourcesSupportStaffList', values);
        },
        remove: (key) => {
            console.log(key);

            return DeleteAPIPromise('WorkOrderResourcesSupportStaffList/' + key);
        },
        update: (key, values) => {
            return PostAPIPromise('WorkOrderResourcesSupportStaffList', values);
        }
    });

    GetDropDownData = async () => {
        const supportFunctionData = await this.FetchAPI('DictionarySupportStaffFunction');
        //this.currentFunction = 'Other';
        this.setState({
            functionTypes: supportFunctionData
        });
    }

    GetData = async () => {

    }

    componentIsReadOnly = () => {
        return this.IsReadOnly() || this.props.workOrder.statusID > 1;
    }

    componentDidUpdate(prevProps) {
        //console.log('Props Updated');

        if (this.props.workOrder != prevProps.workOrder) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.fetchData();
            this.dataGrid.instance().refresh();
            this.inactiveRosterAccordion.instance().collapseItem(0);
            this.inactiveEmployeesDataGrid.instance().refresh();
        }
    }

    onRowRemoved = (e) => {
        this.inactiveEmployeesDataGrid.instance().refresh();
    }

    inactiveEmployeesDataSource = new CustomStore({
        key: ['id'],
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderResourcesSupportStaffList/GetInactiveEmployees/' + this.props.workOrder.id);
            }
        },
        update: (key, values) => {
            return PostAPIPromise('WorkOrderResourcesSupportStaffList/MakeActive?id=' + key);
        }
    });

    onInitNewRow = (e) => {
        this.rowKey = -1;

        e.data.functionCode = 'Other';
        e.data.isActive = true;
    }

    onEditingStart = (e) => {
        this.rowKey = e.key;
    }

    setCellValue(newData, value, currentRowData){
        this.defaultSetCellValue(newData, value, currentRowData);
    }

    customizeItem = (item) => {
        if (item && item.dataField == 'notes') {
            let index = this.dataGrid.current.instance().getRowIndexByKey(this.rowKey);
            index = (index == -1) ? 0 : index;
            try {
                var functionType = this.dataGrid.current.instance().option("editing").changes[0].data.functionCode;
            } catch (error) {
                var functionType = this.dataGrid.current.instance().cellValue(index, "functionCode");
            }
            item.isRequired = functionType == 'Other';
        }
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    setActiveStatus = async (e) => {
        //console.log(e);

        await this.inactiveEmployeesDataSource.update(e.row.data.id, {});

        this.dataGrid.current.instance().refresh();
        this.inactiveEmployeesDataGrid.instance().refresh();
    }

    render() {
        //console.log("Beginning render");
        //console.log(this.props.workOrder);

        return (
            <div style={{ margin: "10px" }}>
                <DataGrid dataSource={this.customDataSource} showBorders={true} allowColumnResizing={true}
                    ref={this.dataGrid} onRowPrepared={this.onRowPrepared} onEditingStart={this.onEditingStart} onExporting={this.onExporting}
                    onRowRemoved={this.onRowRemoved} onRowUpdating={this.onRowUpdating} onInitNewRow={this.onInitNewRow}>
                    <Export enabled={true} fileName={"supportStaff" + this.props.workOrder.workOrderName} />
                    <FilterRow visible={true} />
                    <Editing mode="popup" allowDeleting={!this.componentIsReadOnly()} allowAdding={!this.componentIsReadOnly()} allowUpdating={!this.componentIsReadOnly()} >
                        <Form customizeItem={this.customizeItem}>
                            <SimpleItem dataField="firstName"/>
                            <SimpleItem dataField="middleName" />
                            <SimpleItem dataField="lastName" />
                            <SimpleItem dataField="birthDate" />
                            <SimpleItem dataField="functionCode" editorType="dxSelectBox" editorOptions={{ dataSource: this.state.functionTypes, displayExpr: 'functionName', valueExpr: 'functionCode', searchEnabled: true }} />
                            <SimpleItem dataField="notes" />
                        </Form>
                    </Editing>
                    <Column visible={false} caption="ID" dataField="id">
                        <FormItem visible={false} />
                    </Column>
                    <Column visible={false} caption="WorkOrder ID" dataField="workOrderId">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="First Name" dataField="firstName">
                        <RequiredRule />
                    </Column>
                    <Column caption="Middle Name" dataField="middleName" />
                    <Column caption="Last Name" dataField="lastName">
                        <RequiredRule />
                    </Column>
                    <Column caption="DOB" dataField="birthDate" dataType="date" format="MM/dd/yyyy" />
                    <Column caption="Notes" dataField="notes" />
                    <Column caption="Support Staff Function" dataField="functionCode" setCellValue={this.setCellValue}>
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={this.state.functionTypes} displayExpr="functionName" valueExpr="functionCode" />
                    </Column>
                    <Column visible={false} caption="isActive" dataField="isActive" />
                    
                    <Summary>
                        <TotalItem
                            cssClass="summaryRow"
                            showInColumn="firstName"
                            summaryType="count"
                            displayFormat="Total Staff: {0}" />
                    </Summary>
                </DataGrid>

                <br />
                <Accordion collapsible={true} ref={ref => this.inactiveRosterAccordion = ref} deferRendering={false}>
                    <AccordionItem title="Historic Assignments">
                        <DataGrid dataSource={this.inactiveEmployeesDataSource} showBorders={true} allowColumnResizing={true}
                            ref={ref => this.inactiveEmployeesDataGrid = ref} onRowUpdating={this.onRowUpdating}>
                            <Export enabled={true} fileName={"inactiveSupportStaff" + this.props.workOrder.workOrderName} />
                            <FilterRow visible={true} />

                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="DOB" dataField="birthDate" dataType="date" format="MM/dd/yyyy" />
                            <Column caption="Notes" dataField="notes" />
                            <Column caption="Support Staff Function" dataField="functionCode" >
                                <Lookup allowClearing={true} dataSource={this.state.functionTypes} displayExpr="functionName" valueExpr="functionCode" />
                            </Column>

                            <Column type="buttons" dataField="buttons" caption="Actions">
                                <GridButton
                                    text="Make Active"
                                    hint="Make Active"
                                    onClick={this.setActiveStatus}
                                />
                            </Column>
                        </DataGrid>
                    </AccordionItem>
                </Accordion>

                {/*<Popup visible={this.state.isAddingStaff} onHiding={this.hideStaffDialog} dragEnabled={true}*/}
                {/*    closeOnOutsideClick={false} showTitle={true} title="Add Support Staff" width={800} height={475}>*/}
                {/*    <ScrollView height={340}>*/}
                {/*        <p>Select Support Staff to add to this trip</p>*/}
                {/*        <br />*/}
                {/*        <DataGrid ref={ref => this.addStaffDataGrid = ref} dataSource={this.state.supportStaffEmployees} showBorders={true} allowColumnResizing={true}>*/}
                {/*            <FilterRow visible={true} />*/}
                {/*            <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="always" deferred={true} />*/}

                {/*            <Column caption="First Name" dataField="firstName" />*/}
                {/*            <Column caption="Middle Name" dataField="middleName" />*/}
                {/*            <Column caption="Last Name" dataField="lastName" />*/}
                {/*            <Column caption="Gender" dataField="sex" />*/}
                {/*        </DataGrid>*/}
                {/*    </ScrollView>*/}
                {/*    <br />*/}

                {/*    <div style={{ textAlign: 'center' }}>*/}
                {/*        <Button text="Save" onClick={this.saveSupportStaff.bind(this)} />*/}
                {/*        &nbsp;&nbsp;*/}
                {/*        <Button text="Cancel" onClick={this.hideStaffDialog} />*/}
                {/*    </div>*/}
                {/*</Popup>*/}
            </div>
        );
    }
}