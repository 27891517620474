
import React from 'react';
import BaseComponent, { withParams } from '../BaseComponent.js';
import DataGrid, { Column, Editing, Paging, RequiredRule, StringLengthRule, CustomRule, Lookup } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import Button from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
//import { Link } from 'react-router-dom';
//import Form, { Item, Label, RequiredRule } from 'devextreme-react/form';
//import { msalAuth } from '../../msal/MsalAuthProvider';
import { alert, confirm } from 'devextreme/ui/dialog';
import { CustomerSearchContainer } from './CustomerSearchContainer';

export class TransportRequestFormContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.formElement = React.createRef();

        this.state = {
            loading: false,

            movementTypes: [],
            genders: [],
            nationalities: [],
            states: [],
            pickupAgencies: [],
            facilities: []
        };
    }

    placementDataSource = new ArrayStore({
        key: 'id'
        // Other ArrayStore options go here
    });

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Transport Request Form");
    }

    GetDropDownData = async () => {
        const facilityData = await this.FetchAPI('Facility/GetForSelection');

        var pickupAgencies = await this.FetchAPI('PickupLocation/GetPickupAgencies');

        const genderData = await this.FetchAPI('DictionaryGender');
        const nationalityData = await this.FetchAPI('DictionaryNationality/GetCountries');

        const stateData = await this.FetchAPI('DictionaryState');

        const movementTypeData = await this.FetchAPI('DictionaryMovementType');

        this.setState({
            facilities: facilityData,
            pickupLocations: pickupAgencies,
            movementTypes: movementTypeData,
            genders: genderData,
            nationalities: nationalityData,
            states: stateData
        });
    }

    onInitNewRow(e) {
        // Set active value default to true
        e.data.isPregnant = 'N';
        e.data.systemMovementTypeCode = 'SHELTER';
    }

    setStateValue(rowData, value, currentRowData) {
        if (value == 'REUNIFICATION') {
            // Clear assigned shelter
            rowData.systemFacilityId = null;
        }
        else {
            // Clear all sponsor fields
            rowData.sponsorName = null;
            rowData.sponsorPhone = null;
            rowData.sponsorAddress = null;
            rowData.sponsorCity = null;
            rowData.sponsorState = null;
            rowData.sponsorZipCode = null;
        }

        // Set conditional required field state
        this.defaultSetCellValue(rowData, value);
    }

    onEditorPreparing = (e) => {
        if (e.dataField === "systemFacilityId" && e.parentType === "dataRow") {
            e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode === "REUNIFICATION";
        }

        if (e.dataField === "sponsorName" && e.parentType === "dataRow") {
            e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
        }

        if (e.dataField === "sponsorPhone" && e.parentType === "dataRow") {
            e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
        }

        if (e.dataField === "sponsorAddress" && e.parentType === "dataRow") {
            e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
        }

        if (e.dataField === "sponsorCity" && e.parentType === "dataRow") {
            e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
        }

        if (e.dataField === "sponsorState" && e.parentType === "dataRow") {
            e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
        }

        if (e.dataField === "sponsorZipCode" && e.parentType === "dataRow") {
            e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
        }

        if (e.dataField === "sponsorNotified" && e.parentType === "dataRow") {
            e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
        }

        if (e.dataField === "sponsorNotifiedDate" && e.parentType === "dataRow") {
            e.editorOptions.readOnly = e.row.data && e.row.data.systemMovementTypeCode !== "REUNIFICATION";
        }
    }

    validateShelterFields(e) {
         console.log('Validate shelter', e);
        return e.value || e.data.systemMovementTypeCode === 'REUNIFICATION';
    }

    validateSponsorFields(e) {
        return e.value || e.data.systemMovementTypeCode !== 'REUNIFICATION';
    }

    validateApprovalField(e) {
        return e.value || e.data.systemMovementTypeCode === 'TRANSFER';
    }

    SubmitTransportRequest = async (e) => {

        if (this.placementDataSource._array.length == 0) {
            alert('Please enter at least one Customer to Transport');
        }
        else{

            for (var i = 0; i < this.placementDataSource._array.length; i++) {

                // Validate for single member relationship groups
                if (this.placementDataSource._array[i].familyGroupID != null && this.placementDataSource._array[i].familyGroupID != '')
                {
                    if ((this.placementDataSource._array.filter(a => a.familyGroupID == this.placementDataSource._array[i].familyGroupID)).length < 2) {
                        alert('Relationship group ' + this.placementDataSource._array[i].familyGroupID + ' contains only one member.');
                        return;
                    }
                }
                else
                {
                    // TODO: possible better solution since "" should never be an imputted value, it should be null
                    this.placementDataSource._array[i].familyGroupID = null;
                }

                // Validate for duplicated A numbers
                if ((this.placementDataSource._array.filter(a => a.aNumber == this.placementDataSource._array[i].aNumber && a.systemFacilityId == this.placementDataSource._array[i].systemFacilityId && a.systemPickupLocationId == this.placementDataSource._array[i].systemPickupLocationId)).length > 1) {
                    alert('Error encountered during import. Multiple rows cannot have the same A number, current shelter, and assigned shelter. The first duplicated row found contains the A number: ' + this.placementDataSource._array[i].aNumber);
                    return;
                }
            }

            var confirmAnswer = await confirm("Are you sure you want to submit this Transport Request?");

            if (confirmAnswer) {

                await this.setState({
                    loading: true
                });

                console.log(this.state.placement);
                const result = await this.PostAPI('Placement/BulkEntry', this.placementDataSource._array);

                console.log(result);

                if (result.status == 1) {
                    // TODO: Alert

                    this.props.navigate('/initial-transport-request');
                    this.props.navigate(0);
                }
                else {
                    alert(result.message);
                }

                await this.setState({
                    loading: false
                }); 
            }
        }
    }

    render() {
        var yesNoData = [{ code: 'Y', name: 'Yes' }, { code: 'N', name: 'No' }];

        return (
            <div className="container-fluid">
                <h1>Initial Transport Request Form</h1>

                <br />

                <DataGrid dataSource={this.placementDataSource} ref={ref => this.dataGrid = ref}
                    showBorders={true} allowColumnResizing={true} onInitNewRow={this.onInitNewRow} onEditorPreparing={this.onEditorPreparing}>
                    <Paging enabled={false} />
                    <Editing mode="popup" allowAdding={true} allowUpdating={true} allowDeleting={true} />

                    <Column dataField="aNumber" caption="A Number">
                        <RequiredRule />
                        <StringLengthRule max={10} />
                    </Column>

                    <Column dataField="AdmissionDate" caption="Admission Date" editorType="dxDateBox" format="MM/d/yyyy" dataType="date">
                        <RequiredRule />
                    </Column>

                    <Column dataField="ApprovalDate" caption="Approval Date" editorType="dxDateBox" format="MM/d/yyyy" dataType="date">
                        <CustomRule validationCallback={this.validateApprovalField} message="FFS Approval Date is required" />
                    </Column>

                    <Column dataField="lastName" caption="Last Name">
                        <RequiredRule />
                        <StringLengthRule max={200} />
                    </Column>

                    <Column dataField="firstName" caption="First Name">
                        <RequiredRule />
                        <StringLengthRule max={200} />
                    </Column>

                    <Column dataField="middleName" caption="Middle Name">
                        <StringLengthRule max={200} />
                    </Column>

                    <Column dataField="dob" caption="DOB" editorType="dxDateBox" format="MM/d/yyyy" dataType="date">
                        <RequiredRule />
                    </Column>

                    <Column dataField="gender" caption="Gender">
                        <RequiredRule />
                        <Lookup allowClearing={true} searchEnabled={true} dataSource={this.state.genders} displayExpr="name" valueExpr="code" />
                    </Column>

                    <Column dataField="systemNationalityCode" caption="Country of Origin">
                        <RequiredRule />
                        <Lookup allowClearing={true} searchEnabled={true} dataSource={this.state.nationalities} displayExpr="countryName" valueExpr="code" />
                    </Column>

                    <Column dataField="familyGroupID" caption="Relationship Group ID">
                        <StringLengthRule max={40} />
                    </Column>

                    <Column dataField="systemMovementTypeCode" caption="Type" setCellValue={this.setStateValue}>
                        <RequiredRule />
                        <Lookup allowClearing={false} dataSource={this.state.movementTypes} displayExpr="name" valueExpr="code" />
                    </Column>

                    <Column dataField="systemPickupLocationId" caption="Current Shelter/Program">
                        <RequiredRule />
                        <Lookup allowClearing={true} searchEnabled={true} dataSource={this.state.pickupLocations} displayExpr="name" valueExpr="id" />
                    </Column>

                    <Column dataField="systemFacilityId" caption="Assigned Shelter/Program (non-reun. Only)">
                        <CustomRule validationCallback={this.validateShelterFields} message="Assigned Shelter/Program is required" />
                        <Lookup allowClearing={true} searchEnabled={true} dataSource={this.state.facilities} displayExpr="name" valueExpr="id" />
                    </Column>

                    <Column dataField="sponsorNotified" caption="Sponsor Notified (Y/N) (Reun. Only)">
                        <CustomRule validationCallback={this.validateSponsorFields} message="Sponsor Notified is required" />
                        <Lookup allowClearing={false} dataSource={yesNoData} displayExpr="name" valueExpr="code" />
                    </Column>

                    <Column dataField="sponsorNotifiedDate" caption="Sponsor Notified Date (Reun. Only)" editorType="dxDateBox" format="MM/d/yyyy HH:mm" dataType="datetime">
                    </Column>

                    <Column dataField="sponsorName" caption="Sponsor Name (Reun. Only)">
                        <StringLengthRule max={100} />
                        <CustomRule validationCallback={this.validateSponsorFields} message="Sponsor Name is required" />
                    </Column>

                    <Column dataField="sponsorPhone" caption="Phone No. (Reun. Only)" editorOptions={{
                        mask: '(000) 000-0000'
                    }}>
                        <StringLengthRule max={25} />
                        <CustomRule validationCallback={this.validateSponsorFields} message="Phone No. is required" />
                    </Column>

                    <Column dataField="sponsorAddress" caption="Street Address (Reun. Only)">
                        <StringLengthRule max={200} />
                        <CustomRule validationCallback={this.validateSponsorFields} message="Street Address is required" />
                    </Column>

                    <Column dataField="sponsorCity" caption="City (Reun. Only)">
                        <StringLengthRule max={50} />
                        <CustomRule validationCallback={this.validateSponsorFields} message="City is required" />
                    </Column>

                    <Column dataField="sponsorState" caption="State (Reun. Only)">
                        <CustomRule validationCallback={this.validateSponsorFields} message="State is required" />
                        <Lookup allowClearing={true} searchEnabled={true} dataSource={this.state.states} displayExpr="name" valueExpr="name" />
                    </Column>

                    <Column dataField="sponsorZipCode" caption="Zip Code (Reun. Only)">
                        <StringLengthRule max={10} />
                        <CustomRule validationCallback={this.validateSponsorFields} message="Zip Code is required" />
                    </Column>

                </DataGrid>
                <br />

                <div className="row">
                    <div className="ms-auto me-3">
                        <Button text='Submit Request' onClick={this.SubmitTransportRequest} />
                    </div>
                </div>
                <br />

                <LoadPanel
                    visible={this.state.loading} />
            </div>
        );
    }
}

export default withParams(TransportRequestFormContainer);