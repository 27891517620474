import React from 'react';
import * as $ from 'jquery'
import BaseComponent, { FetchAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import PropTypes from 'prop-types';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Column, MasterDetail, Editing, Button as GridButton, Summary, TotalItem, Selection, Export } from 'devextreme-react/data-grid';
import { Link } from 'react-router-dom';
import DataSource from 'devextreme/data/data_source';


export class WorkOrderDetailsMSWOCoverageContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object
    };

    constructor(props) {
        super(props);
    }

    onCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField === "workOrderName") {
            if (e.data.workOrderName == "*****UNASSIGNED*****") {
                e.cellElement.style.color = "red"
            }
        }
        if (e.rowType === "data" && e.column.dataField === "workOrderNumber") {
            if (e.data.workOrderNumber == "*****UNASSIGNED*****") {
                e.cellElement.style.color = "red"
            }
        }
    }

    customDataSource = new CustomStore({
        key: ['regularWorkOrderID', 'orrPlacementID'],
        load: (loadOptions) => {
            console.log('Load', loadOptions);
            console.log(this.props.workOrder.id);
            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrder/MSWOandRWOCoverage/' + this.props.workOrder.id);
            }

        }
    });

    render() {
        console.log("Beginning render");
        console.log('Date', new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes()));
        return (
            <div>
                <DataGrid dataSource={this.customDataSource} showBorders={true} allowColumnResizing={true} repaintChangesOnly={true}
                    onCellPrepared={this.onCellPrepared}
                    ref={ref => this.dataGrid = ref}>
                    <FilterRow visible={true} />
                    <Column caption="Placement Name" dataField="placementShortName" cellRender={function (options) { return (<Link to={`/edit-placement/${options.row.data.orrPlacementID}`}>{options.value}</Link>); }} />
                    <Column caption="Regular WorkOrder Name" dataField="workOrderName" />
                    <Column caption="Regular WorkOrder Number" dataField="workOrderNumber" cellRender={function (options) { if (options.row.data.regularWorkOrderID != "*****UNASSIGNED*****") { return (<Link to={`/workorder-detail/${options.row.data.regularWorkOrderID}`} onClick={() => window.location.href = '/workorder-detail/' + options.row.data.regularWorkOrderID}>{options.value}</Link>); } else { return (options.value) } }} />
                    <MasterDetail enabled={true} autoExpandAll={true} component={PlacementDetail} />
                </DataGrid>
            </div>
        );
    }
}


class PlacementDetail extends BaseComponent {
    constructor(props) {
        console.log('Detail Props', props);
        super(props);

        this.dataSource = new DataSource({
            key: 'id',
            load: (loadOptions) => {
                return props.data.row.data.customerList;
            }
        })

        this.transportUnitCode = props.data.row.data.transportUnitCode;
        this.placementId = props.data.row.data.id;
        this.workOrderId = props.data.row.data.workOrderID;
        this.workOrderStatusId = props.data.row.data.workOrderStatusId;
        this.placementShortName = props.data.row.data.placementShortName;
        this.active = !props.data.row.data.inactiveReason;
        if (this.transportUnitCode == 'UC' && props.data.row.data.customerList.length > 1 && this.active)
            this.selectionMode = 'multiple';
        else
            this.selectionMode = 'none';

    }

    render() {
        return (
            <div>
                <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true} ref={ref => this.dataGrid = ref}>
                    <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                    <Column caption="First Name" dataField="firstName" />
                    <Column caption="Middle Name" dataField="middleName" />
                    <Column caption="Last Name" dataField="lastName" />
                    <Column caption="Age" dataField="age" />
                    <Column caption="Gender" dataField="gender" />
                    <Column caption="Nationality" dataField="nationality" />
                </DataGrid>
            </div>

        );
    }
}
