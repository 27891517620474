
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Lookup, FormItem, Export } from 'devextreme-react/data-grid';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import * as $ from 'jquery';

const customDataSource = new CustomStore({
    key: 'cityCode',
    load: () => {
        return FetchAPIPromise('DictionaryCity');
    },
    insert: (values) => {
        return PostAPIPromise('DictionaryCity', values);
    },
    remove: (key) => {
        return DeleteAPIPromise('DictionaryCity/' + key)
            .then((result) => {
                if (result.status == -2) {
                    alert('City is referenced in the system and cannot be deleted. Consider inactivating.')
                }
            });
    },
    update: (key, values) => {
        return PostAPIPromise('DictionaryCity', values);
    }
});

export class DictionaryCityContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            aors: [],
            states: []
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return "City Management";
    }

    GetDropDownData = async () => {
        const stateData = await this.FetchAPI('DictionaryState');
        const aorData = await this.FetchAPI('DictionaryAOR');

        this.setState({
            states: stateData,
            aors: aorData
        });
    }

    onInitNewRow(e) {
        // Set active value default to true
        e.data.isActived = true;
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    render() {
        console.log('Begin Render');

        return (
            <div className="container">
                <h1>City Management</h1>

                <div className="container">
                    <DataGrid dataSource={customDataSource}
                        showBorders={true} allowColumnResizing={true}
                        keyExpr="cityCode" onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating} onExporting={this.onExporting}>
                        <Export enabled={true} fileName={"cityManagment"} />
                        <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()} />
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />

                        <Column caption="City Code" dataField="cityCode">
                            <RequiredRule />
                            <StringLengthRule max={50} />
                        </Column>
                        <Column caption="City Name" dataField="cityName">
                            <RequiredRule />
                            <StringLengthRule max={200} />
                        </Column>
                        <Column
                            caption="State Code"
                            dataField="stateCode">
                            <RequiredRule />
                            <Lookup allowClearing={true} dataSource={this.state.states} displayExpr="name" valueExpr="name" />
                        </Column>
                        <Column caption="State Name"
                            dataField="stateName">
                            <FormItem visible={false} />
                        </Column>
                        <Column caption="AOR"
                            dataField="aorCode">
                            <RequiredRule />
                            <Lookup allowClearing={true} dataSource={this.state.aors} displayExpr="aorCode" valueExpr="aorCode" />
                        </Column>
                        <Column caption="Active"
                            dataField="isActived" />
                    </DataGrid>
                    <br />
                </div>
            </div>
        );
    }
}