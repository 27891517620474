import React from 'react';
import { systemConfig } from './components/SystemConfig';
import { Navbar, Container, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from 'devextreme-react/button';
import SessionHelper from './msal/Session'
import { msalAuth } from './msal/MsalAuthProvider';
import BaseComponent from './components/BaseComponent';
import Form, { Item, Label, RequiredRule } from 'devextreme-react/form';
import { alert } from 'devextreme/ui/dialog';

export default class CustomMultiAuthPage extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            fullName: '',
            passCodeRecord: null,
            entry: {
                passCodeEntry: null
            }
        };
    }

    async componentDidMount() {
        try {
            //console.log('Checking user info');
            var response = await this.FetchAPI("UserConfirmLogin");

            //console.log('Done checking', response);

            this.setState({
                fullName: response.employee.fullName,
                passCodeRecord: response.employee.passCode
            });
        }
        catch (ex) {
            //console.log('Verification error', ex);
            this.setState({
                fullName: 'ERROR',
                passCodeRecord: null
            });
        }
    }

    continueLogin = () => {
        var result = this.editFormControl.instance().validate();

        if (result.isValid) {
            if (this.state.passCodeRecord !== this.state.entry.passCodeEntry) {
                alert("Pass Code entered does not match the Pass Code for this user.");
            }
            else {
                //alert("Good");
                this.props.onContinue();
            }
        }
    }

    logOut = (e) => {
        e.preventDefault();

        //console.log('logging out');
        SessionHelper.removeExpiry()
        SessionHelper.stopExpiryTimeout()

        const accounts = msalAuth.getAllAccounts();
        if (accounts.length > 0) {
            //console.log('Accounts present');
            //console.log(accounts[0]);
            //console.log(accounts[0].idTokenClaims.login_hint);
            //console.log(accounts[0].idTokenClaims.preferred_username);
            // log out user
            //msalAuth.logoutRedirect({
            //    account: accounts[0],
            //    logoutHint: accounts[0].idTokenClaims.login_hint,
            //    postLogoutRedirectUri: msalConfig.auth.redirectUri
            //})
            // TODO: Use v2 logout redirect
            msalAuth.logoutRedirect(
                { extraQueryParameters: { logout_hint: accounts[0].idTokenClaims.login_hint } }
            );
            //window.location.href = msalConfig.auth.authority + "/oauth2/logout?post_logout_redirect_uri=" + msalConfig.auth.redirectUri + "&logout_hint=" + accounts[0].idTokenClaims.login_hint;
            //window.location.href = "https://login.microsoftonline.com/common/oauth2/logout";
            return;
        }
        else {
            // TODO: Use v2 logout redirect
            msalAuth.logoutRedirect();
            //window.location.href = msalConfig.auth.authority + "/oauth2/logout?post_logout_redirect_uri=" + msalConfig.auth.redirectUri + "&logout_hint=" + accounts[0].idTokenClaims.login_hint;
            //window.location.href = "https://login.microsoftonline.com/common/oauth2/logout";
            return;
        }
    }

    render() {
        document.title = systemConfig.systemName;

        return (
            <div className="container-fluid">
                <Navbar style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/banner.jpg')`, backgroundSize: '100% 140px', height: '140px' }} className="navbar-expand-sm navbar-toggleable-sm" light>
                    <Container>
                        <img src='mvm.png' width='75px' height='50px' style={{ backgroundColor: 'white', position: 'relative', top: '30px', marginRight: '10px' }} />

                        <NavbarBrand tag={Link} to="/" style={{ color: 'white', position: 'relative', top: '50px' }}>{systemConfig.systemName}</NavbarBrand>

                    </Container>
                </Navbar>
                <br />
                <br />
                <div className="d-flex flex-column min-vh-90 justify-content-center align-items-center">
                    {/*<h1>*************** WARNING! ***************</h1>*/}
                    <div style={{ maxWidth: '800px' }}>
                        <p>You are logged in as: <b>{this.state.fullName}</b></p>
                        <p>To log in as a different user, Click <a href="#" onClick={this.logOut}>Here</a></p>
                    </div>

                    <Form ref={ref => this.editFormControl = ref} formData={this.state.entry}>
                        <Item dataField="passCodeEntry" editorOptions={{ placeholder: 'Pass Code', maxLength: 6, mode: 'password' }}>
                            <RequiredRule />
                            <Label visible={false} />
                        </Item>
                    </Form>
                    <br />
                    <Button text="Continue" onClick={this.continueLogin} />
                </div>
            </div>
        );
    }
};