import React from 'react';
import BaseComponent from '../BaseComponent';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { InventoryItemMealsContainer } from './InventoryItemMealsContainer.js';
import { InventoryItemClothesContainer } from './InventoryItemClothesContainer.js';
import { InventoryItemOtherItemsContainer } from './InventoryItemOtherItemsContainer.js';

export class InventoryItemManagementContainer extends BaseComponent {
    static displayName = InventoryItemManagementContainer.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Inventory Item Management");
    }

    render() {

        return (
            <div className="container">
                <h1>Inventory Item Management</h1>
                <br />

                <TabPanel>
                    <Item title="Meals">
                        <InventoryItemMealsContainer />
                    </Item>

                    <Item title="Clothes">
                        <InventoryItemClothesContainer />
                    </Item>

                    <Item title="Other Items">
                        <InventoryItemOtherItemsContainer />
                    </Item>
                </TabPanel>
            </div>
        );
    }
}