
import React, { useEffect, useState } from 'react';

import DataGrid, { FilterRow, Column, GroupPanel, Selection, MasterDetail, Button as GridButton, Editing, Export } from 'devextreme-react/data-grid';
import Form, { Item, Label, RequiredRule, EmptyItem } from 'devextreme-react/form';
import { Map as ReactMap } from 'devextreme-react/map';
import { FetchAPIPromise, PostAPIPromise, withParams, formatComponentPageName } from '../BaseComponent.js';

function WorkOrderDropoffInfo(props) {
    console.log('Incoming work order', props.workOrder);
    console.log('Incoming dropoff location', props.dropoffLocationId);

    const [dropoffLocationData, setDropoffLocationData] = useState({});

    const key = {
        google: "AIzaSyDLhzLhxQLyaz3C_gigq8Rd9N8hC0yRT5c"
    };

    useEffect(() => {
        async function fetchData() {
            const dropoffLocationData = await FetchAPIPromise('Placement/GetDropoffLocationDetailForWorkOrderPickup?workOrderId=' + props.workOrder.id + '&facilityId=' + props.dropoffLocationId);

            setDropoffLocationData(dropoffLocationData);
        }

        fetchData();
    }, [props.dropoffLocationId]);

    return (
        <Form formData={dropoffLocationData} colCount={3}>
            <Item itemType="group" caption={dropoffLocationData.locationName} colCount={2} colSpan={2}>
                <Item dataField="planPickupDate" editorType="dxDateBox" editorOptions={{
                    displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime',
                    readOnly: true
                }}>
                    <Label text="Plan Dropoff/Removal Date" />
                    <RequiredRule />
                </Item>
                <EmptyItem />

                <Item dataField="departureCity" editorOptions={{ readOnly: true }}>
                </Item>
                <EmptyItem />

                <Item dataField="locationFullAddress" colSpan={2} editorOptions={{ readOnly: true }}>
                    <Label text="Address" />
                </Item>

                <Item colSpan={2}>
                    <Label text="Primary POC" />
                    {dropoffLocationData.primaryPOCName ? `${dropoffLocationData.primaryPOCName} ${dropoffLocationData.primaryPOCPhone1}` : 'None Identified'}
                </Item>
            </Item>

            <Item>
                <ReactMap
                    markers={[{
                        location: `${dropoffLocationData.locationFullAddress}`,
                        tooltip: {
                            text: `${dropoffLocationData.locationName}`,
                        },
                    }]}
                    defaultZoom={12}
                    apiKey={key.google}
                    height={250}
                    width={250}
                    provider="google">
                </ReactMap>
            </Item>

            <Item colSpan={3}>
                <div>
                    <b>Active</b>
                    <br /><br />
                    {dropoffLocationData.customerList &&
                        <DataGrid dataSource={dropoffLocationData.customerList.filter(m => !m.isCancelled)}
                            showBorders={true} allowColumnResizing={true}>
                            <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Gender" dataField="gender" />
                            <Column caption="DOB" dataField="dob" dataType="datetime" format="MM/dd/yyyy" />
                            <Column caption="Dropoff/Removal Time" dataField="actualTransferDate" dataType="datetime" format="MM/dd/yyyy HH:mm" />
                        </DataGrid>
                    }
                </div>
                <br />
                {dropoffLocationData.customerList && dropoffLocationData.customerList.filter(m => m.isCancelled).length > 0 &&
                    <div>
                        <b>Dropped/Inactive</b>
                        <br /><br />
                        <DataGrid dataSource={dropoffLocationData.customerList.filter(m => m.isCancelled)}
                            showBorders={true} allowColumnResizing={true}>
                            <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Gender" dataField="gender" />
                            <Column caption="DOB" dataField="dob" dataType="datetime" format="MM/dd/yyyy" />
                            <Column caption="Inactive Reason" dataField="inactiveReason" />
                            <Column caption="Comments" dataField="inactiveComments" />
                        </DataGrid>
                    </div>
                }
            </Item>
        </Form>
    );
}

export default WorkOrderDropoffInfo;