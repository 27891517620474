
import React from 'react';
import BaseComponent, { FetchAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import DataGrid, { Column as DGColumn, FilterRow as DGFilterRow, Pager, Paging} from 'devextreme-react/data-grid';
import Form, { Item as FormItem, Label, RequiredRule, GroupItem } from 'devextreme-react/form';
import ArrayStore from 'devextreme/data/array_store';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import Button from 'devextreme-react/button';
import Gantt, { Tasks, StripLine, Column, Editing, Toolbar, Item, Validation, FilterRow } from 'devextreme-react/gantt';
import { v4 as uuidv4 } from 'uuid';
import Popup from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import CheckBox from 'devextreme-react/check-box';
import { msalAuth } from '../../msal/MsalAuthProvider';
import { flushSync } from 'react-dom'; // Note: react-dom, not react
import { Popover } from 'devextreme-react/popover';
import { alert, confirm } from 'devextreme/ui/dialog';
import CustomStore from 'devextreme/data/custom_store';

// Below is needed by the Form control to "import" the appropriate control
import TextArea from 'devextreme-react/text-area';

var timezoneNames = [{
    windowsName: 'Central Standard Time',
    ianaName: 'America/Chicago'
}, {
    windowsName: 'Eastern Standard Time',
    ianaName: 'America/New_York'
}, {
    windowsName: 'Eastern Standard Time',
    ianaName: 'America/Detroit'
}, {
    windowsName: 'US Mountain Standard Time',
    ianaName: 'America/Phoenix'
}, {
    windowsName: 'Mountain Standard Time',
    ianaName: 'America/Denver'
}, {
    windowsName: 'Mountain Standard Time',
    ianaName: 'America/Boise'
}, {
    windowsName: 'Pacific Standard Time',
    ianaName: 'America/Los_Angeles'
}];

var isAlreadyDeleting = false;

export class ScheduleContainer extends BaseComponent {
    constructor(props) {
        super(props);

        // ABG Not used until/unless we time zone aware the timekeeping
        //this.startDateRender = this.startDateRender.bind(this);
        //this.endDateRender = this.endDateRender.bind(this);

        this.state = {
            offices: [],
            employees: [],
            isCurrentPayPeriodLocked: false,
            canEditPayrollLocking: false,
            lockedPeriods: [],
            lockedDays: [],
            shifts: [],

            isViewingTaskDetails: false,
            taskDetails: {
                id: '',
                parentId: '',
                display: '',
                actualStartDate: '',
                actualEndDate: '',
                reason: ''
            },

            filters: {
                fromDate: '',
                toDate: '',
                officeId: -1,
                employeeId: -1,
                totalScheduledHours: '0',
                totalWorkedHours: '0'
            },

            changeHistoryVisible: false,
            scheduleDetailId: ''

            // ABG Not needed until/unless we time zone aware the timekeeping
            //popover: {
            //    isVisible: false,
            //    target: 'hello',
            //    originalDateTime: null,
            //    convertedDateTime: null,
            //    employeeTimeZone: null
            //}
        };

        this.refreshPageInterval = 1800;
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();

        this.interval = setInterval(async () => {
            //console.log('Refreshing from timer');

            var queryString = 'beginDate=' + this.state.filters.fromDate;
            queryString += '&endDate=' + this.state.filters.toDate;
            queryString += '&officeId=' + (this.state.filters.officeId ? this.state.filters.officeId : -1);

            //console.log(queryString);

            var shiftData = await this.FetchAPI('Schedule/GetScheduleDetail?' + queryString)

            //console.log('Before', shiftData);

            // Set effective end date
            this.convertEmployeeTimeZone(shiftData);

            flushSync(() => {
                this.setState({
                    shifts: shiftData
                });
            });
            
            this.updateShiftData();

        }, this.refreshPageInterval * 1000);
    }

    GetComponentPageName = () => {
        return ("Schedule");
    }

    componentWillUnmount = async () => {
        clearInterval(this.interval);
    }

    GetData = async () => {
        var today = moment();
        var from_date = today.clone().startOf('week');
        var to_date = today.clone().endOf('week');

        var result = await this.FetchAPI('Schedule/GetLockedPeriods?startTime=' + from_date.format("MM/DD/YYYY") + '&endTime=' + to_date.format("MM/DD/YYYY"));

        //console.log('Locked Periods', result);

        var lockedDays = [];

        for (var i = 0; i < 7; i++) {
            var currentData = from_date.clone().add(i, 'days');
            var selected = false;

            var lockDay = null;

            lockDay = {
                display: currentData.format('dddd'),
                date: currentData,
                selected: false
            };

            if (result.findIndex((item) => currentData.isBetween(moment(item.startDate), moment(item.endDate), 'day', '[]')) > -1) {
                selected = true;
            }

            lockDay.selected = selected;

            lockedDays.push(lockDay);
        }

        //console.log(lockedDays);

        var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

        var isUserPayrollUser = userRoles.findIndex(element => element === 'Payroll') > -1;

        //console.log('Is current pay period locked?', result.length > 0);
        //console.log('Can edit payroll locking', isUserPayrollUser);

        flushSync(() => {
            this.setState({
                isCurrentPayPeriodLocked: result.length > 0,
                lockedPeriods: result,
                lockedDays: lockedDays,
                canEditPayrollLocking: isUserPayrollUser,

                filters: {
                    fromDate: from_date.format("MM/DD/YYYY HH:mm"),
                    toDate: to_date.format("MM/DD/YYYY HH:mm"),
                    officeId: -1
                }
            });
        });

        this.shiftDataSource = new ArrayStore({
            key: 'id'
            // Other ArrayStore options go here
        });

        var queryString = 'beginDate=' + this.state.filters.fromDate;
        queryString += '&endDate=' + this.state.filters.toDate;
        queryString += '&officeId=' + (this.state.filters.officeId ? this.state.filters.officeId : -1);
        queryString += '&employeeId=' + (this.state.filters.employeeId ? this.state.filters.employeeId : -1);

        console.log(queryString);

        var shiftData = await this.FetchAPI('Schedule/GetScheduleDetail?' + queryString)

        //console.log('Before', shiftData);

        // Set effective end date
        this.convertEmployeeTimeZone(shiftData);

        //console.log('After', shiftData);

        flushSync(() => {
            this.setState({
                shifts: shiftData
            });
        });

        this.updateShiftData();
    }

    convertEmployeeTimeZone = (shiftData) => {
        // ABG: The ONLY thing we will do here for now is to lop off the timezone
        // Detect and convert values for office time zone
        shiftData.map((item, index) => {
            if (item.taskType == 'SHIFT') {
                //console.log('Employee Time Zone', item.employeeTimeZone);

                // Reset fields
                //item.startDateOriginal = null;
                //item.endDateOriginal = null;
                //item.startDateModified = false;
                //item.endDateModified = false;

                //var ianaTimezone = timezoneNames.find((zone) => zone.ianaName == item.employeeTimeZone);
                //console.log(ianaTimezone);

                // TODO: Handle DST?
                //var employeeSchedulingOfficeOffset = moment('2023-1-1').tz(ianaTimezone.ianaName).format('Z');
                //console.log('TimeZone offset', employeeSchedulingOfficeOffset);

                if (item.actualStartDate) {
                    //console.log('Actual Start Date value', item.actualStartDate);

                    //var originalStartDateMoment = moment.parseZone(item.actualStartDate);
                    //console.log(originalStartDateMoment.format('MM/DD/YYYY HH:mm'));

                    //var originalOffset = originalStartDateMoment.format('Z')
                    //var convertedOffset = originalStartDateMoment.tz(ianaTimezone.ianaName).format('Z');

                    //if (originalOffset == convertedOffset) {
                    //    item.startDateModified = false;
                    //console.log('Same zone, no change');
                    //console.log('Actual Start Date value', item.actualStartDate);
                    item.effectiveStartDate = moment.parseZone(item.actualStartDate).format('YYYY-MM-DDTHH:mm');
                    //}
                    //else {
                    //    item.startDateModified = true;
                    //    item.startDateOriginal = moment.parseZone(item.actualStartDate).format('MM/DD/YYYY HH:mm');
                    //    //item.actualStartDate = originalStartDateMoment.tz(ianaTimezone.ianaName).format('MM/DD/YYYY HH:mm Z')
                    //    item.effectiveStartDate = originalStartDateMoment.tz(ianaTimezone.ianaName).format('MM/DD/YYYY HH:mm');
                    //    //console.log('Different zone, converted');
                    //    //console.log('Original time offset', originalOffset);
                    //    //console.log('Converted offset', convertedOffset);

                    //    //console.log('Actual Start Date value', item.actualStartDate);
                    //}

                    //console.log(item.effectiveStartDate);
                }

                if (item.actualEndDate) {
                    //console.log('Actual End Date value', item.actualEndDate);

                    //var originalEndDateMoment = moment.parseZone(item.actualEndDate);
                    //console.log(originalStartDateMoment.format('MM/DD/YYYY HH:mm'));

                    //var originalOffset = originalEndDateMoment.format('Z')
                    //var convertedOffset = originalEndDateMoment.tz(ianaTimezone.ianaName).format('Z');

                    //if (originalOffset == convertedOffset) {
                    //    item.endDateModified = false;
                    //console.log('Same zone, no change');
                    //console.log('Actual End Date value', item.actualEndDate);
                    item.effectiveEndDate = moment.parseZone(item.actualEndDate).format('YYYY-MM-DDTHH:mm');
                    //}
                    //else {
                    //    item.endDateModified = true;
                    //    item.endDateOriginal = moment.parseZone(item.actualEndDate).format('MM/DD/YYYY HH:mm');
                    //    //item.actualEndDate = originalEndDateMoment.tz(ianaTimezone.ianaName).format('MM/DD/YYYY HH:mm Z');
                    //    item.effectiveEndDate = originalEndDateMoment.tz(ianaTimezone.ianaName).format('MM/DD/YYYY HH:mm');
                    //    //console.log('Different zone, converted');
                    //    //console.log('Original time offset', originalOffset);
                    //    //console.log('Converted offset', convertedOffset);

                    //    //console.log('Actual End Date value', item.actualEndDate);
                    //}

                    //console.log(item.effectiveEndDate);
                }
            }
            else if (item.taskType == 'WORKORDER') {
                // ABG: We want to store and retrieve the time relative to UTC but display the time often as relative to the employee's local/home time
                item.effectiveStartDate = moment.parseZone(item.effectiveStartDate).format('MM/DD/YYYY HH:mm');
                item.effectiveEndDate = moment.parseZone(item.effectiveEndDate).format('MM/DD/YYYY HH:mm');
            }
            else if (item.taskType == 'EMPLOYEE') {
                // ABG: We want to store and retrieve the time relative to UTC but display the time often as relative to the employee's local/home time
                item.effectiveStartDate = moment.parseZone(item.effectiveStartDate).format('MM/DD/YYYY HH:mm');
                item.effectiveEndDate = moment.parseZone(item.effectiveEndDate).format('MM/DD/YYYY HH:mm');
            }
        });

        //console.log('After', shiftData);
    }

    updateShiftData = async () => {
        this.shiftDataSource.clear();

        //console.log('Calculating shift data for: ' + schedulePeriodStartDate.format('MM/DD/YYYY') + ' to ' + schedulePeriodEndDate.format('MM/DD/YYYY'));

        // *********************************
        var currentPeriodBeginMoment = moment(this.state.filters.fromDate);
        var currentPeriodEndMoment = moment(this.state.filters.toDate);

        var workedTotal = 0;
        var scheduledTotal = 0;

        var filters = this.state.filters;

        // TODO: Could we multi-thread this?
        this.state.shifts.map((item, index) => {

            // Sum up worked and scheduled hours for the period
            //console.log('Timesheet Item', item);

            if (item.taskType == 'SHIFT') {
                //        //console.log(shift);
                var clockOutMoment = moment(item.effectiveEndDate);
                var clockInMoment = moment(item.effectiveStartDate);

                //        // console.log(tripBeginMoment);

                if ((clockInMoment.isSameOrAfter(currentPeriodBeginMoment) && clockInMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockOutMoment.isSameOrAfter(currentPeriodBeginMoment) && clockOutMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockInMoment.isSameOrBefore(currentPeriodBeginMoment) && clockOutMoment.isSameOrAfter(currentPeriodEndMoment))) {

                    var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
                    var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

                    var calculatedAmount = end.diff(begin, 'minute') / 60;

                    //console.log('Worked item', item);
                    //console.log('Worked hours', calculatedAmount);

                    item.currentWorkedHours = calculatedAmount.toFixed(2);

                    workedTotal += calculatedAmount;
                }
            }

            // Sum up worked and scheduled hours for the period
            //console.log('Shift Item', item);

            if (item.taskType == 'EMPLOYEE') {
                //        //console.log(shift);
                var clockOutMoment = moment(item.effectiveEndDate);
                var clockInMoment = moment(item.effectiveStartDate);

                //        // console.log(tripBeginMoment);

                if ((clockInMoment.isSameOrAfter(currentPeriodBeginMoment) && clockInMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockOutMoment.isSameOrAfter(currentPeriodBeginMoment) && clockOutMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockInMoment.isSameOrBefore(currentPeriodBeginMoment) && clockOutMoment.isSameOrAfter(currentPeriodEndMoment))) {

                    var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
                    var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

                    var calculatedAmount = end.diff(begin, 'minute') / 60;

                    item.currentScheduledHours = calculatedAmount.toFixed(2);

                    scheduledTotal += calculatedAmount;
                }
            }

            this.shiftDataSource.insert(item);
        });

        filters.totalScheduledHours = scheduledTotal.toFixed(2);
        filters.totalWorkedHours = workedTotal.toFixed(2);

        flushSync(() => {
            this.setState({
                filters: filters
            });
        });

        this.filterForm.instance().repaint();

        //console.log(this.shiftDataSource);

        this.projectGantt.instance().refresh();
    }

    fieldDataChanged = async (e) => {
        console.log('Field Data changed', e);
        //console.log(this.state.filters);

        if (e.dataField == 'fromDate') {
            //console.log(e);

            var filters = this.state.filters;

            filters.fromDate = e.value;

            var fromDate = moment(filters.fromDate);
            var toDate = fromDate.clone().endOf('week');

            filters.toDate = toDate.format("MM/DD/YYYY HH:mm");

            var result = await this.FetchAPI('Schedule/GetLockedPeriods?startTime=' + fromDate.format("MM/DD/YYYY") + '&endTime=' + toDate.format("MM/DD/YYYY"));

            console.log('Locked Periods', result);

            var lockedDays = [];

            for (var i = 0; i < 7; i++) {
                var currentData = fromDate.clone().add(i, 'days');
                var selected = false;

                var lockDay = null;

                lockDay = {
                    display: currentData.format('dddd'),
                    date: currentData,
                    selected: false
                };

                if (result.findIndex((item) => currentData.isBetween(moment(item.startDate), moment(item.endDate), 'day', '[]')) > -1) {
                    selected = true;
                }

                lockDay.selected = selected;

                lockedDays.push(lockDay);
            }

            //console.log(lockedDays);

            await this.setState({
                isCurrentPayPeriodLocked: result.length > 0,
                lockedPeriods: result,
                lockedDays: lockedDays,

                filters: filters
            });
        }

        if (this.filterForm != null) {
            this.filterForm.instance().repaint();
        }

        var queryString = 'beginDate=' + this.state.filters.fromDate;
        queryString += '&endDate=' + this.state.filters.toDate;
        queryString += '&officeId=' + (this.state.filters.officeId ? this.state.filters.officeId : -1);
        queryString += '&employeeId=' + (this.state.filters.employeeId ? this.state.filters.employeeId : -1);

        console.log(queryString);

        var shiftData = await this.FetchAPI('Schedule/GetScheduleDetail?' + queryString)

        //console.log('Before', shiftData);

        // Set effective end date
        this.convertEmployeeTimeZone(shiftData);

        flushSync(() => {
            this.setState({
                shifts: shiftData
            });
        });

        flushSync(() => {
            this.updateShiftData();
        });
    }

    GetDropDownData = async () => {
        var officeData = await this.FetchAPI('Office');
        //console.log(officeData);

        const employeeData = await this.FetchAPI('Employee/GetScheduleEmployees');
        //console.log(employeeData);

        // Filter on active and scheduling office
        officeData = officeData.filter((item) => item.isActive && item.isSchedulingOffice);

        await this.setState({
            offices: officeData,
            employees: employeeData
        });
    }

    onTaskInserting = async (e) => {
        console.log(e);

        // TODO ABG: NOTE Currently, cannot insert a task and then immediately delete it. The Ids are being corrupted, for some reason. Working with devexpress on this
    }

    onTaskDeleting = async (e) => {
        console.log(e);
        
        if (!isAlreadyDeleting) {
            console.log('item exists');
            isAlreadyDeleting = true;

            // If item is in the store, delete it
            const isCanceled = new Promise((resolve, reject) => {
                FetchAPIPromise('Timesheet/GetTimesheetEntriesByScheduleDetailsId?scheduleDetailId=' + e.key).then((result) => {
                    if (result.employeeTimesheets && result.employeeTimesheets.length > 0) {

                        if (result.employeeTimesheets.find(element => element.approvalUser)) {
                            alert('This time entry appears on one or more APPROVED Employee timesheets and cannot be deleted.');
                            reject(true);
                            console.log('finally, reset the delete flag');
                            isAlreadyDeleting = false;
                            return true;
                        }
                        else {
                            return confirm('This time entry appears on one or more Employee timesheets but is eligible to be deleted. Are you sure you want to delete?').then((deleteConfirmed) => {

                                if (!deleteConfirmed) {
                                    console.log('finally, reset the delete flag');
                                    isAlreadyDeleting = false;
                                    resolve(true);
                                    return true;
                                } else {
                                    return DeleteAPIPromise('Timesheet/DeleteTimesheetEntriesByScheduleDetailsId?scheduleDetailId=' + e.key).then((result) => {
                                        if (result.status == 1) {
                                            // If we're good, all we do is return false (aka not canceled)
                                            resolve(false);
                                            return false;
                                        } else {
                                            // TODO: Test this?
                                            alert(result.message);
                                            resolve(true);
                                            console.log('finally, reset the delete flag');
                                            isAlreadyDeleting = false;
                                            return true;
                                        }
                                    });
                                }
                            });
                        }
                    }
                }).then((isCanceled) => {
                    // alert(isCanceled);
                    // TODO: Test this
                    if (!isCanceled) {
                        return DeleteAPIPromise('Schedule/' + e.key)
                            .then((result) => {
                                //alert('Schedule page result' + result);
                                //alert('Schedule page result' + result.status);
                                if (result.status == 1) {
                                    console.log('Successful, lets return false');
                                    var currentShifts = this.state.shifts;

                                    var rowsToRemove = currentShifts.filter((item) => item.id == e.key);

                                    // TODO: ABG: Currently, can't customize the delete confirmation and I don't believe there's any way to actually disable it if we need to call deleteTask later
                                    //       So, for now, I think we have to trust that the underlying delete will occur and notify if it doesn't but we can't actually prevent it
                                    console.log(rowsToRemove);

                                    var updatedTotalWorkedHours = parseFloat(this.state.filters.totalWorkedHours);

                                    // TODO: Update hours
                                    rowsToRemove.map((item, index) => {
                                        var deleteIndex = currentShifts.indexOf(item);

                                        var deletedItems = currentShifts.splice(deleteIndex, 1);

                                        console.log('Calling delete task');
                                        this.projectGantt.instance().deleteTask(item.id);

                                        //// TODO ABG: Can't do it this way yet, though I'd like to
                                        //this.shiftDataSource.remove(item.id).then((result) => {
                                        //    console.log('Remove result', result);

                                        //    return this.shiftDataSource.byKey(item.id).then((resultTwo) => {
                                        //        console.log('Found item?', resultTwo);


                                        //        return true;
                                        //    });
                                        //});

                                        // Then, update projected hours
                                        updatedTotalWorkedHours -= parseFloat(deletedItems[0].currentWorkedHours);
                                    });

                                    var filters = this.state.filters;
                                    filters.totalWorkedHours = updatedTotalWorkedHours;

                                    this.setState({
                                        filters: filters,

                                        shifts: currentShifts
                                    });

                                    this.filterForm.instance().repaint();
                                    // this.projectGantt.instance().refresh();

                                    console.log('finally, reset the delete flag');
                                    isAlreadyDeleting = false;

                                    resolve(false);
                                    return false;
                                }
                                else if (result.status == -1 || result.status == -2) {
                                    resolve(true);
                                    alert(result.message);
                                    console.log('finally, reset the delete flag');
                                    isAlreadyDeleting = false;
                                    return true;
                                }
                                else {
                                    resolve(true);
                                    alert('Failed. Please reload and try again later.');
                                    console.log('finally, reset the delete flag');
                                    isAlreadyDeleting = false;
                                    return true;
                                }
                            });
                    }
                });
            });

            // e.cancel = isCanceled;
            e.cancel = true;
        }
        else {
            // If item is no longer in the store, no problem with deleting it
            e.cancel = false;
        }
    }

    onTaskDeleted = async (e) => {
        console.log('We successfully deleted a task');
    }

    onTaskUpdating = async (e) => {
        //console.log('Updating', e);

        if (e.values.taskType == 'EMPLOYEE') {
            e.cancel = true;
        }
        else if (e.values.taskType == 'WORKORDER') {
            e.cancel = true;
        }
        else {
            console.log('Updating', e);
            e.cancel = false;

            var previousClockInMoment = moment(e.values.effectiveStartDate);
            var previousClockOutMoment = moment(e.values.effectiveEndDate);

            e.values.actualEndDate = e.values.effectiveEndDate;
            e.values.actualStartDate = e.values.effectiveStartDate;

            e.newValues.actualEndDate = e.newValues.effectiveEndDate;
            e.newValues.actualStartDate = e.newValues.effectiveStartDate;

            var updatedTotalWorkedHours = parseFloat(this.state.filters.totalWorkedHours);

            var currentPeriodBeginMoment = moment(this.state.filters.fromDate);
            var currentPeriodEndMoment = moment(this.state.filters.toDate);

            var clockInMoment = moment(e.newValues.effectiveStartDate);
            var clockOutMoment = moment(e.newValues.effectiveEndDate);

            var previousBegin = previousClockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : previousClockInMoment;
            var previousEnd = previousClockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : previousClockOutMoment;

            var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
            var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

            var previousCalculatedAmount = previousEnd.diff(previousBegin, 'minute') / 60;
            var newCalculatedAmount = end.diff(begin, 'minute') / 60;

            console.log('Current worked hours', updatedTotalWorkedHours);

            updatedTotalWorkedHours -= previousCalculatedAmount;
            updatedTotalWorkedHours += newCalculatedAmount;

            e.newValues.currentWorkedHours = newCalculatedAmount.toFixed(2);

            console.log('New worked hours', updatedTotalWorkedHours);

            var filters = this.state.filters;
            filters.totalWorkedHours = updatedTotalWorkedHours.toFixed(2);

            await this.setState({
                filters: filters
            });

            this.filterForm.instance().repaint();
        }
    }

    reviewHours = async (event, e) => {
        event.preventDefault();
        await this.setState({
            loading: true
        });

        var localArrayItem = this.shiftDataSource._array.find((item) => item.id == e.row.data.id);

        //console.log('Array Item', localArrayItem);

        var taskDetails = e.row.data;

        taskDetails.workOrderId = localArrayItem.workOrderId;

        var result = await this.PostAPI("Schedule/ReviewHours", taskDetails);

        if (result.status == 1) {
            var ReviewStatusCode = "REVIEWED";
            var Color = "#8ed962";

            this.projectGantt.instance().updateTask(e.row.data.id, {
                display: 'Shift',
                color: Color,
                actualStartDate: new Date(localArrayItem.actualStartDate), actualEndDate: new Date(localArrayItem.actualEndDate),
                effectiveStartDate: new Date(localArrayItem.effectiveStartDate), effectiveEndDate: new Date(localArrayItem.effectiveEndDate),
                currentWorkedHours: localArrayItem.currentWorkedHours,
                reviewStatusCode: ReviewStatusCode
            });
        }
        else if (result.status == -1) {
            alert(result.message);
        }
        else {
            //console.log(result);
            alert(' review hours Failed. Please try again later.');
        }

        await this.setState({
            loading: false
        });
    }

    addAdditionalShift = async (event, e) => {
        event.preventDefault();
        console.log('Add Additional Shift', e);
        var localArrayItem = this.shiftDataSource._array.find((item) => item.id == e.row.data.id);

        console.log('Root of new item', localArrayItem);

        await this.setState({
            isViewingTaskDetails: true,
            taskDetails: {
                id: uuidv4(),
                parentId: localArrayItem.id,
                display: 'Shift',
                actualStartDate: null,
                actualEndDate: null,
                reason: null,
                workOrderId: localArrayItem.workOrderId,
                employeeTimeZone: localArrayItem.employeeTimeZone
            }
        });
    }

    onTaskEditDialogShowing = async (e) => {
        //console.log(e);

        // TODO: I believe we can set this by adding hidden columns to the task list
        if (e.values.display.indexOf('Shift') == -1) {
            e.cancel = true;
        }
        else {
            //e.readOnlyFields.push('display');
            //e.hiddenFields.push('progress');
            //e.hiddenFields.push('resources');

            e.cancel = true;

            var localArrayItem = this.shiftDataSource._array.find((item) => item.id == e.key);

            //console.log('local array', localArrayItem);
            if (localArrayItem.workOrderStatusId < 4) {
                // TODO ABG: Having to set the effective start date in some cases because the Gantt control will not update the actual end date
                await this.setState({
                    isViewingTaskDetails: true,
                    taskDetails: {
                        id: localArrayItem.id,
                        parentId: localArrayItem.parentId,
                        display: localArrayItem.display,
                        taskType: 'SHIFT',
                        employeeTimeZone: localArrayItem.employeeTimeZone,
                        previousStartDate: localArrayItem.actualStartDate,
                        previousEndDate: localArrayItem.actualEndDate,
                        actualStartDate: localArrayItem.actualStartDate ? localArrayItem.effectiveStartDate : localArrayItem.actualStartDate,
                        actualEndDate: localArrayItem.actualEndDate ? localArrayItem.effectiveEndDate : localArrayItem.actualEndDate,
                        reason: localArrayItem.reason, reviewStatusCode: localArrayItem.reviewStatusCode
                    }
                });
            }
        }
    }

    onContextMenuPreparing = async (e) => {
        console.log(e);

        if (!e.data) {
            e.cancel = true;
        }
        // TODO: Just cancel if things are too locked down by HR
        else if (e.data.taskType == 'WORKORDER') {
            // Just cancel
            e.cancel = true;
        }
        else if (e.data.taskType == 'EMPLOYEE') {
            e.cancel = true;
        }
        else if (e.data.taskType == 'SHIFT') {
            var localArrayItem = this.shiftDataSource._array.find((item) => item.id == e.data.id);
            //console.log('Local array item', localArrayItem);

            if (localArrayItem.workOrderStatusId < 4) {
                // Hide add menu
                e.items[0].visible = false;
                e.items[1].text = 'Hours Details...';
                e.items[2].text = 'Delete Hours';
            }
            else {
                e.cancel = true;
            }
        }
    }

    onTaskMoving(e) {
        // Do not allow task moving
        e.cancel = true;
    }

    isDisabledDate = (args) => {
        /*console.log('Disabled date', args);*/

        if (args.view === "month") {
            return args.date.getDay() > 0;
        }

        return false;
    }

    hideEditDialog = () => {
        this.setState({
            isViewingTaskDetails: false,
            taskDetails: {
                id: '',
                parentId: '',
                display: '',
                actualStartDate: '',
                actualEndDate: '',
                reason: ''
            }
        });
    }

    saveTaskDetails = async () => {
        var result = this.editFormControl.instance().validate();

        if (result.isValid) {

            await this.setState({
                loading: true
            });

            var taskDetails = this.state.taskDetails;

            console.log('task details', taskDetails);

            if (moment(taskDetails.actualStartDate).isAfter(moment(taskDetails.actualEndDate))) {
                alert('End Date cannot precede Start Date');
            }
            else {
                //commentData.workOrderId = this.state.workOrder.id;

                if (!taskDetails.workOrderId) {
                    var localArrayItem = this.shiftDataSource._array.find((item) => item.id == taskDetails.id);

                    //console.log('Array Item', localArrayItem);

                    taskDetails.workOrderId = localArrayItem.workOrderId;
                }

                taskDetails.clientDateTime = moment();

                //console.log('Employee Time Zone', taskDetails.employeeTimeZone);

                var ianaTimezone = timezoneNames.find((zone) => zone.ianaName == taskDetails.employeeTimeZone);
                console.log(ianaTimezone);

                var employeeSchedulingOfficeOffset = moment('2023-1-1').tz(ianaTimezone.ianaName).format('Z');

                //console.log('Actual start date', moment.parseZone(taskDetails.actualStartDate));
                //console.log('Previous start date', moment.parseZone(taskDetails.previousStartDate));

                //console.log('Is actually same', moment(taskDetails.actualStartDate, 'MM/DD/YYYY HH:mm').isSame(moment(taskDetails.previousStartDate,'MM/DD/YYYY HH:mm')));

                //console.log('Actual start date changed');
                var convertedStartDateMoment = moment.parseZone(moment.parseZone(taskDetails.actualStartDate).format('MM/DD/YYYY HH:mm') + ' ' + employeeSchedulingOfficeOffset);

                var clientOffset = convertedStartDateMoment.format('YYYY-MM-DDTHH:mmZ');

                //console.log(clientOffset);

                taskDetails.clockInDateTime = clientOffset;

                //console.log('Actual end date', moment.parseZone(taskDetails.actualEndDate));
                //console.log('Previous end date', moment.parseZone(taskDetails.previousEndDate));

                //console.log('Is actually same', moment(taskDetails.actualEndDate, 'MM/DD/YYYY HH:mm').isSame(moment(taskDetails.previousEndDate, 'MM/DD/YYYY HH:mm')));

                //console.log('Actual end date changed');
                var convertedEndDateMoment = moment.parseZone(moment.parseZone(taskDetails.actualEndDate).format('MM/DD/YYYY HH:mm') + ' ' + employeeSchedulingOfficeOffset);

                var clientOffset = convertedEndDateMoment.format('YYYY-MM-DDTHH:mmZ');

                //console.log(clientOffset);

                taskDetails.clockOutDateTime = clientOffset;

                console.log(taskDetails);

                const result = await this.PostAPI('Schedule', taskDetails);

                console.log(result);

                if (result.status == 1) {
                    // TODO: Make sure popups and render work after update
                    var currentShifts = this.state.shifts;

                    taskDetails.effectiveStartDate = taskDetails.actualStartDate;
                    taskDetails.effectiveEndDate = taskDetails.actualEndDate;

                    taskDetails.actualStartDate = taskDetails.clockInDateTime;
                    taskDetails.actualEndDate = taskDetails.clockOutDateTime;

                    console.log('After save values', taskDetails);

                    var insertIndex = currentShifts.findIndex((item) => item.id == taskDetails.id);
                    //console.log('index', insertIndex);

                    var updatedTotalWorkedHours = parseFloat(this.state.filters.totalWorkedHours);

                    var currentPeriodBeginMoment = moment(this.state.filters.fromDate);
                    var currentPeriodEndMoment = moment(this.state.filters.toDate);

                    var clockInMoment = moment(taskDetails.actualStartDate);
                    var clockOutMoment = moment(taskDetails.actualEndDate);

                    var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
                    var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

                    var calculatedAmount = end.diff(begin, 'minute') / 60;

                    if (insertIndex == -1) {
                        // New item
                        // TODO: Find the last index of shifts for the parent id
                        taskDetails.taskType = 'SHIFT';

                        var parentIndex = currentShifts.findIndex((item) => item.id == taskDetails.parentId);
                        currentShifts.splice(parentIndex + 1, 0, taskDetails);

                        //console.log('Newly inserted id', taskDetails.id);

                        // Update worked hours
                        this.projectGantt.instance().insertTask({
                            display: taskDetails.display, id: taskDetails.id, parentId: taskDetails.parentId,
                            actualStartDate: new Date(new Date(moment.parseZone(taskDetails.actualStartDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
                            actualEndDate: new Date(new Date(moment.parseZone(taskDetails.actualEndDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
                            effectiveStartDate: new Date(new Date(moment.parseZone(taskDetails.actualStartDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
                            effectiveEndDate: new Date(new Date(moment.parseZone(taskDetails.actualEndDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
                            currentWorkedHours: calculatedAmount.toFixed(2),
                            taskType: taskDetails.taskType
                        });

                        updatedTotalWorkedHours += calculatedAmount;

                        var filters = this.state.filters;
                        filters.totalWorkedHours = updatedTotalWorkedHours.toFixed(2);

                        await this.setState({
                            filters: filters
                        });

                        this.filterForm.instance().repaint();
                    }
                    else {
                        var previousItem = currentShifts.splice(insertIndex, 1, taskDetails);

                        var previousClockInMoment = moment(previousItem[0].effectiveStartDate);
                        var previousClockOutMoment = moment(previousItem[0].effectiveEndDate);

                        var updatedTotalWorkedHours = parseFloat(this.state.filters.totalWorkedHours);

                        var currentPeriodBeginMoment = moment(this.state.filters.fromDate);
                        var currentPeriodEndMoment = moment(this.state.filters.toDate);

                        var clockInMoment = moment(taskDetails.effectiveStartDate);
                        var clockOutMoment = moment(taskDetails.effectiveEndDate);

                        var previousBegin = previousClockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : previousClockInMoment;
                        var previousEnd = previousClockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : previousClockOutMoment;

                        var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
                        var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

                        var previousCalculatedAmount = previousEnd.diff(previousBegin, 'minute') / 60;
                        var newCalculatedAmount = end.diff(begin, 'minute') / 60;

                        console.log('Current worked hours', updatedTotalWorkedHours);

                        updatedTotalWorkedHours -= previousCalculatedAmount;
                        updatedTotalWorkedHours += newCalculatedAmount;

                        taskDetails.currentWorkedHours = newCalculatedAmount.toFixed(2);

                        taskDetails.reviewStatusCode = "REVIEWED";

                        console.log('New worked hours', updatedTotalWorkedHours);

                        var filters = this.state.filters;
                        filters.totalWorkedHours = updatedTotalWorkedHours.toFixed(2);

                        await this.setState({
                            filters: filters
                        });

                        this.filterForm.instance().repaint();

                        //console.log('Before convert', taskDetails);

                        this.convertEmployeeTimeZone([taskDetails]);

                        console.log('After convert', taskDetails);

                        //console.log(moment.parseZone(taskDetails.actualEndDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
                        //console.log(new Date(moment.parseZone(taskDetails.actualEndDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName }));

                        // Updating task
                        // TODO: Update hours
                        this.projectGantt.instance().updateTask(taskDetails.id, {
                            display: 'Shift',
                            color: "#8ed962",
                            actualStartDate: new Date(new Date(moment.parseZone(taskDetails.actualStartDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
                            actualEndDate: new Date(new Date(moment.parseZone(taskDetails.actualEndDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
                            effectiveStartDate: new Date(new Date(moment.parseZone(taskDetails.actualStartDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
                            effectiveEndDate: new Date(new Date(moment.parseZone(taskDetails.actualEndDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
                            currentWorkedHours: taskDetails.currentWorkedHours,
                            parentId: taskDetails.parentId,
                            reviewStatusCode: taskDetails.reviewStatusCode
                            //endDateModified: taskDetails.endDateModified,
                            //startDateModified: taskDetails.startDateModified,
                            //endDateOriginal: taskDetails.endDateOriginal,
                            //startDateOriginal: taskDetails.startDateOriginal
                        });
                    }

                    await this.setState({
                        shifts: currentShifts,

                        isViewingTaskDetails: false,
                        taskDetails: {
                            id: '',
                            parentId: '',
                            display: '',
                            actualStartDate: '',
                            actualEndDate: '',
                            reason: ''
                        }
                    });

                    //console.log('GGGGOOOOOTTTTT HHHHHEEEERRRREEE');
                }
                else if (result.status == -1) {
                    alert(result.message);
                }
                else {
                    //console.log(result);
                    alert('save task details Failed. Please try again later.');
                }
            }

            await this.setState({
                loading: false
            });
        }
    }

    isAddShiftButtonVisible = (options) => {
        // If the entry in the chart has the same work order id, we can add additional shifts, where needed
        console.log('got here');
        return options.row.data.taskType == 'EMPLOYEE' && options.row.data.workOrderStatusId < 4;
    }

    isReviewButtonVisible = (options) => {
        return options.row.data.taskType == "SHIFT" && options.row.data.actualStartDate != null && options.row.data.actualEndDate != null && options.row.data.reviewStatusCode == "UNREVIEWED" && !this.IsReadOnly() && options.row.data.workOrderStatusId < 4;
    }

    changeLockedDay = (e) => {
        console.log(e);

        var checkboxId = e.element.id.substring(9);
        console.log(checkboxId);

        var currentLockedDays = this.state.lockedDays;

        currentLockedDays[checkboxId].selected = e.value;

        this.setState({
            lockedDays: currentLockedDays
        });
    }

    saveLockPeriods = async (e) => {
        console.log(e);
        console.log(this.state.lockedDays);

        try {
            await this.setState({
                loading: true
            });

            var daysToSave = [];

            // If ALL days are selected, then just send one record.
            if (this.state.lockedDays.findIndex((item) => !item.selected) == -1) {
                daysToSave.push({
                    startDate: this.state.lockedDays[0].date.format('MM/DD/yyyy'),
                    endDate: this.state.lockedDays[6].date.format('MM/DD/yyyy')
                });
            }
            else {
                this.state.lockedDays.filter((item) => item.selected).map((item, itemIndex) => {
                    daysToSave.push({
                        startDate: item.date.format('MM/DD/yyyy'),
                        endDate: item.date.format('MM/DD/yyyy')
                    });
                });
            }

            const result = await this.PostAPI('Schedule/LockPayrollPeriods', {
                start: this.state.filters.fromDate,
                end: this.state.filters.toDate,
                lockUpdates: daysToSave
            });
            //console.log(result);

            if (result.status != 1) {
                alert(' save lock periods Failed. Please try again later.');
            }
            else {
                this.setState({
                    isCurrentPayPeriodLocked: this.state.lockedDays.findIndex((item) => item.selected) > -1
                });
            }
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    // ABG Not needed until/unless we timezone aware the page
    /* BEGIN
    //startToggleWithTitle = (cell, e) => {
    //    //console.log('Got here start', cell.row.data);
    //    var target = this.state.popover.isVisible ? null : '#' + 'start_' + cell.row.data.id.replace('|', '_');
    //    var originalDateTime = this.state.popover.isVisible ? null : cell.row.data.startDateOriginal;
    //    var convertedDateTime = this.state.popover.isVisible ? null : moment.parseZone(cell.row.data.effectiveStartDate).format('MM/DD/yyyy HH:mm');

    //    this.setState({
    //        popover: {
    //            isVisible: !this.state.popover.isVisible,
    //            target: target,
    //            originalDateTime: originalDateTime,
    //            convertedDateTime: convertedDateTime,
    //            employeeTimeZone: cell.row.data.employeeTimeZone
    //        }
    //    });
    //}

    //endToggleWithTitle = (cell, e) => {
    //    //console.log('Got here end', cell.row.data);
    //    var target = this.state.popover.isVisible ? null : '#' + 'end_' + cell.row.data.id.replace('|', '_');
    //    var originalDateTime = this.state.popover.isVisible ? null : cell.row.data.endDateOriginal;
    //    var convertedDateTime = this.state.popover.isVisible ? null : moment.parseZone(cell.row.data.effectiveEndDate).format('MM/DD/yyyy HH:mm');

    //    this.setState({
    //        popover: {
    //            isVisible: !this.state.popover.isVisible,
    //            target: target,
    //            originalDateTime: originalDateTime,
    //            convertedDateTime: convertedDateTime,
    //            employeeTimeZone: cell.row.data.employeeTimeZone
    //        }
    //    });
    //}

    //toggleOut = (e) => {

    //    this.setState({
    //        popover: {
    //            isVisible: false,
    //            target: null,
    //            originalDateTime: null,
    //            convertedDateTime: null,
    //            employeeTimeZone: null
    //        }
    //    });
    //}

    disabledClick(e) {
        e.preventDefault();
    }

    startDateRender(cell) {
        let onToggle = this.startToggleWithTitle.bind(this, cell);

        if (cell.row.data.startDateModified) {
            //console.log('start date render', cell);
            return <a href='#' onClick={(e) => this.disabledClick(e)} id={'start_' + cell.row.data.id.replace('|', '_')} onMouseOver={onToggle} onMouseLeave={this.toggleOut}>{moment(cell.row.data.effectiveStartDate).format('MM/DD/yyyy HH:mm')}</a>;
        }
        else {
            return moment(cell.row.data.effectiveStartDate).format('MM/DD/yyyy HH:mm');
        }
    }

    endDateRender(cell) {
        let onToggle = this.endToggleWithTitle.bind(this, cell);

        if (cell.row.data.endDateModified) {
            //console.log('end date render', cell);
            return <a href='#' onClick={(e) => this.disabledClick(e)} id={'end_' + cell.row.data.id.replace('|', '_')} onMouseOver={onToggle} onMouseLeave={this.toggleOut}>{moment(cell.row.data.effectiveEndDate).format('MM/DD/yyyy HH:mm')}</a>;
        }
        else {
            return moment(cell.row.data.effectiveEndDate).format('MM/DD/yyyy HH:mm');
        }
    }
    END */

    buttonsColumnRender = (e) => {
        //console.log('REndering', e);
        //return (<><Button text="Add Hours" stylingMode="text" onClick={() => this.addAdditionalShift(e)} visible={e.row.data.taskType == 'EMPLOYEE' && e.row.data.workOrderStatusId < 4} />
        //    <Button text="Review" stylingMode="text" onClick={() => this.reviewHours(e)} visible={e.row.data.taskType == "SHIFT" && e.row.data.actualStartDate != null && e.row.data.actualEndDate != null && e.row.data.reviewStatusCode == "UNREVIEWED" && !this.IsReadOnly() && e.row.data.workOrderStatusId < 4} /></>);
        return (<>{e.row.data.taskType == 'EMPLOYEE' && e.row.data.workOrderStatusId < 4 && !this.IsReadOnly() && <a href="#" onClick={(event) => this.addAdditionalShift(event, e)} class="dx-link dx-link-delete">Add Hours</a>}&nbsp;
            {e.row.data.taskType == "SHIFT" && e.row.data.actualStartDate != null && e.row.data.actualEndDate != null && e.row.data.reviewStatusCode == "UNREVIEWED" && !this.IsReadOnly() && e.row.data.workOrderStatusId < 4 && <a href="#" onClick={(event) => this.reviewHours(event, e)} class="dx-link dx-link-delete">Review</a>}&nbsp;
            {e.row.data.taskType == "SHIFT" && <a href="#" onClick={(event) => this.showChangeHistory(event, e)} class="dx-link dx-link-delete">Change History</a>}</>);
    }

    hideChangeHistory = async () => {
        await this.setState({
            changeHistoryVisible: false
        });
    }

    showChangeHistory = async (event, e) => {
        event.preventDefault();

        await this.setState({
            changeHistoryVisible: true,
            scheduleDetailId: e.key
        });

        this.changeHistoryDataGrid.instance().refresh();
    }

    changeLogDataSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            if (this.state.scheduleDetailId) {
                return FetchAPIPromise('Schedule/GetScheduleDetailsChangeHistory?scheduleDetailsId=' + this.state.scheduleDetailId);
            }
        }
    });

    render() {
        //console.log('Begin render');

        return (
            <div className="container-fluid">
                {/* TODO: Back and forth buttons? */}
                <Form ref={ref => this.filterForm = ref}
                    formData={this.state.filters} colCount={2} onFieldDataChanged={this.fieldDataChanged}>
                    <FormItem editorType="dxDateBox" dataField="fromDate" editorOptions={{ disabledDates: this.isDisabledDate }}>
                        <Label text="Week Begin" />
                    </FormItem>

                    <FormItem editorType="dxDateBox" dataField="toDate" editorOptions={{ readOnly: true }}>
                        <Label text="End" />
                    </FormItem>

                    <FormItem dataField="employeeId" editorType="dxSelectBox" editorOptions={{ dataSource: this.state.employees, displayExpr: 'employeeName', valueExpr: 'employeeId', searchEnabled: true, showClearButton: true }}>
                        <Label text="Employee" />
                    </FormItem>

                    <FormItem dataField="officeId" editorType="dxSelectBox" editorOptions={{ dataSource: this.state.offices, displayExpr: 'name', valueExpr: 'id', searchEnabled: true, showClearButton: true }}>
                        <Label text="Office" />
                    </FormItem>

                    <FormItem dataField="totalScheduledHours" editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                        <Label text="Total Hours Scheduled" />
                    </FormItem>

                    <FormItem dataField="totalWorkedHours" editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                        <Label text="Total Hours Worked" />
                    </FormItem>
                    <br />

                    <GroupItem colSpan={2} visible={this.state.isCurrentPayPeriodLocked || this.state.canEditPayrollLocking} caption="Payroll Locking">
                        {this.state.isCurrentPayPeriodLocked &&
                                <label style={{ color: 'red' }}>This Schedule Period is Locked</label>
                        }

                        {this.state.lockedDays && this.state.lockedDays.map((day, dayIndex) => (
                            <>&nbsp;&nbsp;<CheckBox key={day.display} text={day.display} defaultValue={day.selected} readOnly={!this.state.canEditPayrollLocking} id={'lockedDay' + dayIndex} onValueChanged={this.changeLockedDay} /></>
                        ))}

                        &nbsp;&nbsp;<Button text="Save Lock Periods" visible={this.state.canEditPayrollLocking && !this.IsReadOnly()} onClick={this.saveLockPeriods} />
                    </GroupItem>
                </Form>

                <br />
                <div className="row justify-content-center">
                        <div className="col">
                        <span style={{
                            color: '#5b9d95'
                        }}>
                            &#x2B24;
                        </span>
                        &nbsp;
                        <label className="dx-field-item-label-text">Open/Active Work Order</label>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div className="col">
                        <span style={{
                            color: '#78b6d9'
                        }}>
                            &#x2B24;
                        </span>
                        &nbsp;
                        <label className="dx-field-item-label-text">Completed Work Order</label>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div className="col">
                        <span style={{
                            color: '#a37182'
                        }}>
                            &#x2B24;
                        </span>
                        &nbsp;
                        <label className="dx-field-item-label-text">Canceled Work Order</label>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                        <div className="col">
                        <span style={{
                            color: '#3cbab2'
                        }}>
                            &#x2B24;
                        </span>
                        &nbsp;
                        <label className="dx-field-item-label-text">Employee/Scheduled Shift</label>
                        </div>
                    &nbsp;&nbsp;&nbsp;
                        <div className="col">
                        <span style={{
                            color: '#f1929f'
                        }}>
                            &#x2B24;
                        </span>
                        &nbsp;
                        <label className="dx-field-item-label-text">Invalid</label>
                        </div>
                    &nbsp;&nbsp;&nbsp;
                        <div className="col">
                        <span style={{
                            color: '#efcd7c'
                        }}>
                            &#x2B24;
                        </span>
                        &nbsp;
                        <label className="dx-field-item-label-text">Unreviewed Hours</label>
                        </div>
                    &nbsp;&nbsp;&nbsp;
                        <div className="col">
                        <span style={{
                            color: '#8ed962'
                        }}>
                            &#x2B24;
                        </span>
                        &nbsp;
                        <label className="dx-field-item-label-text">Reviewed Hours</label>
                        </div>
                    </div>
                <br />

                <Gantt
                    taskListWidth={820} onTaskInserting={this.onTaskInserting} onTaskEditDialogShowing={this.onTaskEditDialogShowing}
                    onContextMenuPreparing={this.onContextMenuPreparing} onTaskMoving={this.onTaskMoving} onTaskDeleting={this.onTaskDeleting} onTaskDeleted={this.onTaskDeleted} onTaskUpdating={this.onTaskUpdating}
                    scaleType="days"
                    height={700}
                    ref={ref => this.projectGantt = ref} showResources={false}>
                    <Validation autoUpdateParentTasks={false} />
                    <Editing enabled={true} allowDependencyAdding={false} allowDependencyDeleting={false} allowResourceAdding={false} allowResourceDeleting={false}
                        allowResourceUpdating={false} />

                    <StripLine start={this.state.filters.fromDate} title="Work Week Start" cssClass="current-time" />
                    <StripLine start={this.state.filters.toDate} title="Work Week End" cssClass="current-time" />

                    {this.state.lockedDays && this.state.lockedDays.filter((item) => item.selected).map((day, dayIndex) => (
                        <StripLine key={day.date.format('MM/DD/yyyy')} start={day.date.format('MM/DD/yyyy')} end={day.date.clone().add(23, 'hour').add(59, 'minute').format('MM/DD/yyyy HH:mm')} title="Period Locked" cssClass="lockedPeriod" />
                    ))}

                    <Tasks dataSource={this.shiftDataSource}
                        keyExpr="id"
                        startExpr="effectiveStartDate" endExpr="effectiveEndDate"
                        titleExpr="display" />

                    <Toolbar>
                        <Item name="collapseAll" />
                        <Item name="expandAll" />
                        <Item name="separator" />
                        <Item name="zoomIn" />
                        <Item name="zoomOut" />
                    </Toolbar>

                    <Column dataField="display" caption="Work Order/Employee/Shift" width={250} />
                    {/* The following visible false columns are necessary to get the Gantt to allow updates and tracking */}
                    <Column dataField="taskType" visible={false} />
                    {/*<Column dataField="startDateModified" visible={false} />*/}
                    {/*<Column dataField="endDateModified" visible={false} />*/}
                    <Column dataField="employeeTimeZone" visible={false} />
                    <Column dataField="actualStartDate" visible={false} />
                    <Column dataField="actualEndDate" visible={false} />
                    <Column dataField="reviewStatusCode" visible={false} />
                    {/* END */}
                    <Column dataField="effectiveStartDate" caption="Plan Start Date" dataType="datetime" format="MM/dd/yy, HH:mm" width={135} />
                    <Column dataField="effectiveEndDate" caption="Plan End Date" dataType="datetime" format="MM/dd/yy, HH:mm" width={135} />
                    <Column dataField="currentScheduledHours" caption="Expected" width={80} dataType='number' />
                    <Column dataField="currentWorkedHours" caption="Actual" width={80} dataType='number' />
                    <Column cellRender={this.buttonsColumnRender} width={120} />

                    <FilterRow visible={true} />
                </Gantt>

                <Popup visible={this.state.isViewingTaskDetails} onHiding={this.hideEditDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={true} title="Task Details" width={400} height={340}>

                    <Form ref={ref => this.editFormControl = ref}
                        id="form" formData={this.state.taskDetails} colCount={1}>

                        <FormItem editorType="dxTextBox" dataField="display" editorOptions={{ readOnly: true }}>
                            <Label text="Title" />
                        </FormItem>

                        <FormItem editorType="dxDateBox" dataField="actualStartDate" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>
                            <Label text="Start" />
                            <RequiredRule />
                        </FormItem>

                        <FormItem editorType="dxDateBox" dataField="actualEndDate" editorOptions={{ type: 'datetime', displayFormat: 'MM/dd/yyyy HH:mm' }}>
                            <Label text="End" />
                            <RequiredRule />
                        </FormItem>

                        <FormItem editorType="dxTextArea" dataField="reason" editorOptions={{
                            maxLength: 2000
                        }}>
                            <Label text="Reason" />
                            <RequiredRule />
                        </FormItem>
                    </Form>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="OK" onClick={this.saveTaskDetails} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideEditDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.changeHistoryVisible} onHiding={this.hideChangeHistory} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Change History" width={900} height={500}>

                    <DataGrid dataSource={this.changeLogDataSource} showBorders={true} allowColumnResizing={true}
                        ref={ref => this.changeHistoryDataGrid = ref}>
                        <DGFilterRow visible={true} />
                        <Paging defaultPageSize={5} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} visible={true} />

                        <DGColumn dataField="updateType" />
                        <DGColumn dataField="updateField" caption="Field" />
                        <DGColumn dataField="oldValue" />
                        <DGColumn dataField="newValue" />
                        <DGColumn dataField="display" caption="Change User" />
                        <DGColumn dataField="createDate" caption="Change Date" dataType="datetime" format="MM/dd/yyyy HH:mm" />
                    </DataGrid>
                </Popup>

                {/* ABG: Not needed until/unless we timezone aware the page */}
                {/*<Popover*/}
                {/*    target={this.state.popover.target}*/}
                {/*    position="top"*/}
                {/*    width={325}*/}
                {/*    visible={this.state.popover.isVisible}>*/}
                {/*    Employee's Time Zone: {this.state.popover.employeeTimeZone}<br />*/}
                {/*    Original: {this.state.popover.originalDateTime} remote time<br />*/}
                {/*    Converted: {this.state.popover.convertedDateTime}*/}
                {/*</Popover>);*/}

                <LoadPanel
                    visible={this.state.loading} />

                <br />
            </div>
        );
    }
}