
import React, { useState, useEffect } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Column, Pager, Paging, Export } from 'devextreme-react/data-grid';
import { Link } from 'react-router-dom';
import Button from 'devextreme-react/button';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, onExporting } from '../BaseComponent';
import { CheckBox } from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import Form, { Item as FormItem, Label } from 'devextreme-react/form';
import * as moment from 'moment';
import List from 'devextreme-react/list';
import { msalAuth } from '../../msal/MsalAuthProvider';
import { LoadPanel } from 'devextreme-react/load-panel';

function WorkOrderGroundTransportContainer() {

    const [filters, setFilters] = useState({
        filters: {
            currentDay: '',
            selectedTransportMethods: ['MVMMINIVAN', 'MVM15PASSVAN', 'CHARTERSPRINTER']
        }
    });

    const [selectedTransportMethods, setSelectedTransportMethods] = useState(['MVMMINIVAN', 'MVM15PASSVAN', 'CHARTERSPRINTER']);

    const [transportMethods, setTransportMethods] = useState([]);

    const [allWorkOrderList, setAllWorkOrderList] = useState([]);

    const [filteredWorkOrderList, setFilteredWorkOrderList] = useState([]);

    const [userIsSysAdmin, setUserIsSysAdmin] = useState(false);

    const KilometerToMileConversion = 0.621371;

    const MaxShorthaulShiftTime = 14.0;

    const MaxShorthaulDistance = 172.6;

    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {

        var currentDay = moment();

        currentDay = moment(currentDay, "YYYY-MM-DD").format('YYYY-MM-DD');

        setFilters({ ...filters, currentDay: currentDay });

        async function fetchData() {

            setIsBusy(true);

            try {
                var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

                //console.log(userRoles);

                var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;

                //console.log(isUserSystemAdmin);

                setUserIsSysAdmin(isUserSystemAdmin);
                var transportMethodData = await FetchAPIPromise('DictionaryTransportMethod');

                // console.log(transportMethodData);

                var workOrderListData = await FetchAPIPromise('WorkOrder/GetGroundTransportWorkOrdersByDate?currentDay=' + currentDay);

                /*console.log(workOrderListData);*/

                processWorkOrderTransportComplianceData(workOrderListData);

                setAllWorkOrderList(workOrderListData);

                setTransportMethods(transportMethodData.filter((item) => item.trafficTypeCode == 'Ground'));

                var filteredList = workOrderListData;

                if (selectedTransportMethods.length > 0) {
                    filteredList = filteredList.filter((item) => selectedTransportMethods.includes(item.transportTypeCode));
                }

                setFilteredWorkOrderList(filteredList);
            }
            finally {
                setIsBusy(false);
            }
        }

        fetchData();
    }, []);

    const processWorkOrderTransportComplianceData = (data) => {
        data.map((row) => {
            row.isMissingDrivers = "No";
            row.hasIncorrectDrivers = "No";

            // TODO: do the shorthaul calc here instead
            if (row.transportMethod != 'MVM - 15 Passenger Van') {
                row.hasAllHoursOfServiceLogs = "N/A";
                row.qualifiesForShorthaul = 'N/A';
                return;
            }

            if (row.hasAllHoursOfServiceLogs) {
                row.hasAllHoursOfServiceLogs = "Yes";
            }
            else {
                row.hasAllHoursOfServiceLogs = "No";
            }

            row.vehicleSegmentList.map((segment) => {
                if (!segment.driverNumber) {
                    row.isMissingDrivers = "Yes";
                }
                else if (row.eligibleDriverList && !row.eligibleDriverList.find((item) => item.number == segment.driverNumber)) {
                    row.hasIncorrectDrivers = "Yes";
                }
            });

            //console.log('Work Order #', row.number);

            if (row.transitMetricsList && row.transitMetricsList.length > 0) {
                // Find longest distance
                const maxLocationDistance = row.transitMetricsList.reduce((prev, current) => (prev.straightLineDistance > current.straightLineDistance) ? prev : current, 0);

                //console.log('Longest distance location', maxLocationDistance.straightLineDistance);

                // Find shift hours count for employees
                // I THINK if ANY shift on the work order extends beyond 14 hours, we're outside of the limit
                // TODO: Make some hover dialog that shows the reasons why it qualifies or does not
                const minClockIn = row.shiftHours.reduce((prev, current) => (prev.clockInDateTime < current.clockInDateTime) ? prev : current, 0);
                const maxClockOut = row.shiftHours.reduce((prev, current) => (prev.clockOutDateTime > current.clockOutDateTime) ? prev : current, 0);

                //console.log('Min Clockin', minClockIn.clockInDateTime);
                if (maxClockOut && maxClockOut.clockOutDateTime) {
                    //console.log('Max Clockout', maxClockOut.clockOutDateTime);
                }
                else {
                    // No clock outs yet, look at current timestamp
                }

                var timeDifferenceInHours = ((maxClockOut && maxClockOut.clockOutDateTime) ? moment(maxClockOut.clockOutDateTime) : moment()).diff(moment(minClockIn.clockInDateTime), 'minute') / 60;

                //console.log('Total time diff in hours', timeDifferenceInHours);

                // TODO: What about inbound/outbound trips?
                if (timeDifferenceInHours >= MaxShorthaulShiftTime || maxLocationDistance.straightLineDistance > MaxShorthaulDistance) {
                    row.qualifiesForShorthaul = 'No';
                }
                else {
                    row.qualifiesForShorthaul = 'Yes';
                    row.hasAllHoursOfServiceLogs = 'N/A';
                }
            }
            else {
                // No legs, no actual transport
                row.qualifiesForShorthaul = 'N/A';
            }
        });
    }

    const onSelectionChanged = (e) => {
        // console.log(e);

        setIsBusy(true);

        try {
            var localselectedTransportMethods = selectedTransportMethods;

            if (e.addedItems.length > 0) {
                setSelectedTransportMethods(oldArray => [...oldArray, e.addedItems[0].code])
                localselectedTransportMethods.push(e.addedItems[0].code);
            }

            if (e.removedItems.length > 0) {
                setSelectedTransportMethods(l => l.filter(item => item !== e.removedItems[0].code));
                localselectedTransportMethods = localselectedTransportMethods.filter((item) => item != e.removedItems[0].code);
            }

            //console.log('local selected list');

            var filteredList = allWorkOrderList;

            if (selectedTransportMethods.length > 0) {
                filteredList = filteredList.filter((item) => localselectedTransportMethods.includes(item.transportTypeCode));
            }

            setFilteredWorkOrderList(filteredList);
        }
        finally {
            setIsBusy(false);
        }
    }


    const fieldDataChanged = async (e) => {
        //console.log('Field Data changed', e);
        //console.log(this.state.filters);

        setIsBusy(true);

        try {
            //localStorage.setItem(e.dataField, e.value);
            if (e.dataField == 'currentDay') {
                setFilters({ ...filters, currentDay: e.value });

                var workOrderListData = await FetchAPIPromise('WorkOrder/GetGroundTransportWorkOrdersByDate?currentDay=' + e.value);

                //console.log('All Data', workOrderListData);

                processWorkOrderTransportComplianceData(workOrderListData);

                setAllWorkOrderList(workOrderListData);

                // TODO: Move to a function
                var filteredList = workOrderListData;

                if (selectedTransportMethods.length > 0) {
                    filteredList = filteredList.filter((item) => selectedTransportMethods.includes(item.transportTypeCode));
                }

                setFilteredWorkOrderList(filteredList);
            }
        }
        finally {
            setIsBusy(false);
        }
    }

    const driverCountCellRender = (cell) => {
        // Pull the distinct driver count
        // TODO: any sort of color/warning indicator?
        const uniqueDrivers = [];
        cell.row.data.vehicleSegmentList.map(segment => {
            if (uniqueDrivers.indexOf(segment.driverNumber) === -1) {
                uniqueDrivers.push(segment.driverNumber)
            }
        });

        return uniqueDrivers.length;
    }

    const expectedMileageCellRender = (cell) => {
        // console.log(cell.row.data);
        var miSummation = cell.row.data.transitLegList.reduce((total, item) => total = total + item.drivingDistance, 0);

        return (miSummation).toFixed(2);
    }

    const actualMileageCellRender = (cell) => {
        // console.log(cell.row.data);
        var kmSummation = cell.row.data.vehicleSegmentList.reduce((total, item) => total = total + item.distanceKilometers, 0);

        return (kmSummation * KilometerToMileConversion).toFixed(2);
    }

    const percentageDifferenceCellRender = (cell) => {
        var miSummation = cell.row.data.transitLegList.reduce((total, item) => total = total + item.drivingDistance, 0);
        var kmSummation = cell.row.data.vehicleSegmentList.reduce((total, item) => total = total + item.distanceKilometers, 0) * KilometerToMileConversion;

        var difference = 100 * Math.abs((miSummation - kmSummation) / ((miSummation + kmSummation) / 2));

        if (((!miSummation || miSummation == 0) || (!kmSummation || kmSummation == 0))) {
            difference = '';
        }
        else {
            difference = difference.toFixed(2);
        }

        return <span style={{ color: (difference > 10) ? 'red' : 'green' }}>{difference}</span>;
    }

    const yesNoCellRender = (cell) => {
        return <span style={{ color: (cell.value == 'Yes') ? 'red' : 'green' }}>{cell.value}</span>;
    }

    const noYesCellRender = (cell) => {
        return <span style={{ color: (cell.value == 'No') ? 'red' : 'green' }}>{cell.value}</span>;
    }

    const qualifiesForShorthaulRender = (cell) => {
        if (cell.row.data.qualifiesForShorthaul == 'N/A') {
            return <span>{cell.row.data.qualifiesForShorthaul}</span>
        }
        else {
            return <span style={{ color: (cell.row.data.qualifiesForShorthaul == 'Yes' ? 'green' : 'red') }}>{cell.row.data.qualifiesForShorthaul}</span>
        }
    }

    const hasAllHoSLogsRender = (cell) => {
        //console.log('hos render', cell.row.data.existingHoursOfServiceLogs);

        //if (cell.row.data.hasAllHoursOfServiceLogs == 'N/A') {
        //    return <span>{cell.row.data.hasAllHoursOfServiceLogs}</span>
        //}
        //else {
        //    return <span style={{ color: (cell.row.data.hasAllHoursOfServiceLogs == 'Yes' ? 'green' : 'red') }}>{cell.row.data.hasAllHoursOfServiceLogs}</span>
        //}

        if (cell.row.data.hasAllHoursOfServiceLogs == 'N/A') {
            return <span>{cell.row.data.hasAllHoursOfServiceLogs}</span>
        }
        else {
            var allEmployees = cell.row.data.eligibleDriverList;

            return <span style={{ color: (cell.row.data.existingHoursOfServiceLogs.length == allEmployees.length ? 'green' : 'red') }}>{cell.row.data.existingHoursOfServiceLogs.length + '/' + allEmployees.length}</span>
        }
    }

    const markReviewed = async (options) => {
        //console.log(options);

        //console.log('Got here', id);

        // Remove workorderid and workordernumber from segment
        var returnValue = await PostAPIPromise('WorkOrder/MarkWorkOrderDOTReviewed?workOrderId=' + options.row.data.id);

        // console.log('Method returned', returnValue);
        if (returnValue.status == 1) {
            // alert('Success');

            setAllWorkOrderList(allWorkOrderList.map((item, i) => {
                if (item.id === options.row.data.id) {
                    return { ...item, isReviewed: true };
                }
                return item;
            }));

            setFilteredWorkOrderList(filteredWorkOrderList.map((item, i) => {
                if (item.id === options.row.data.id) {
                    return { ...item, isReviewed: true };
                }
                return item;
            }));
        }
        else {
            alert(returnValue.message);
        }
    }

    return (

        <div className="container-fluid">
            <h1>Ground Transport</h1>

            <div className="d-flex justify-content-between">
                <Form
                    formData={filters} colCount={2} onFieldDataChanged={fieldDataChanged}>
                    <FormItem editorType="dxDateBox" dataField="currentDay" editorOptions={{ width: 200 }}>
                        <Label text="Viewing for" />
                    </FormItem>

                    <FormItem>
                        <List dataSource={transportMethods} selectedItemKeys={selectedTransportMethods} displayExpr='name' valueExpr='code' keyExpr='code' height={200} width={400} selectionMode='multiple' showSelectionControls={true} onSelectionChanged={onSelectionChanged}
                        ></List>
                        <Label text="Methods" />
                    </FormItem>
                </Form>
            </div>

            <br />
            <div id="results">
                <DataGrid dataSource={filteredWorkOrderList}
                    showBorders={true} allowColumnResizing={true} onExporting={onExporting}>
                    <Export enabled={true} fileName={"workOrderList"} />
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={20} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column caption="Number" dataField="number" dataType="string" />
                    <Column caption="Work Order Name" dataField="name" cellRender={function (options) { return (<Link to={`/workorder-detail/${options.row.data.id}`}>{options.value}</Link>); }} />
                    <Column caption="Start Date" dataField="planStartDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="End Date" dataField="planEndDate" dataType="datetime" format="MM/dd/yy, HH:mm" />

                    <Column caption="Type" dataField="workOrderType" />
                    <Column caption="Status" dataField="status" />
                    <Column caption="Transport Type" dataField="transportMethod" />

                    <Column caption="Driver Count" cellRender={driverCountCellRender} dataType="number" />
                    <Column caption="Expected Mileage" cellRender={expectedMileageCellRender} />
                    <Column caption="Actual Mileage" cellRender={actualMileageCellRender} />
                    <Column caption="Pct Diff" cellRender={percentageDifferenceCellRender} />
                    <Column caption="Is Reviewed?" dataField="isReviewed" />

                    <Column caption="Missing Drivers?" dataField="isMissingDrivers" cellRender={yesNoCellRender} />
                    <Column caption="Has Incorrect Drivers?" dataField="hasIncorrectDrivers" cellRender={yesNoCellRender} />
                    <Column caption="Qualifies for Shorthaul?" dataField="qualifiesForShorthaul" cellRender={qualifiesForShorthaulRender} />
                    <Column caption="Has All HoS Logs?" dataField="hasAllHoursOfServiceLogs" cellRender={hasAllHoSLogsRender} />

                    <Column caption="" allowFiltering={false} allowSorting={false} allowResizing={false} dataField="id"
                        width={(userIsSysAdmin ? 325 : 135)}
                        cellRender={
                            function (options) {
                                return (<>
                                    <Link to={`/trip-status/${options.row.data.id}`}><Button text="Trip Overview" /></Link>
                                    &nbsp;
                                    {userIsSysAdmin && <Button text="Mark Reviewed" onClick={() => markReviewed(options)} />}
                                    &nbsp;
                                    {userIsSysAdmin && <Link to={`/groundtransport-hoswizard/${options.row.data.id}`}><Button text="HoS" /></Link>}
                                </>);
                            }
                        } />
                </DataGrid>
            </div>
            <LoadPanel
                visible={isBusy} position={{ at: 'top', of: '#results' }} />
            <br />
        </div>
    );
}

export default WorkOrderGroundTransportContainer;
