
import React from 'react';
import List, { ItemDragging } from 'devextreme-react/list';
import BaseComponent, { withParams } from '../BaseComponent.js';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import Form, { Item, Label, ButtonItem, RequiredRule } from 'devextreme-react/form';
import parse from 'html-react-parser';
import { alert, confirm } from 'devextreme/ui/dialog';

const htmlEditorOptions = {
    height: "50vh",
    toolbar: {
        items: ["undo", "redo", "separator", "bold", "italic", "underline", "separator", "orderedList", "bulletList", "separator", "clear"]
    }
};

class ComplianceChecklistEditContainer extends BaseComponent {
    static displayName = ComplianceChecklistEditContainer.name;

    constructor(props) {
        super(props);

        this.state = {
            checklistId: '',
            checklistData: {},
            questions: [],
            editedQuestions: [],
            currentQuestion: {},
            currentSection: {},
            layout: [],
            draggable: [],
            loading: false,
            questionPopupVisible: false,
            sectionPopupVisible: false,

            hasEmptySection: false,
            unsavedLayout: false
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);

        const { id } = this.props.params

        await this.setState({ checklistId: id });

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return (this.state.checklistData.name ? (this.state.checklistData.name + ' Checklist Edit') : 'New Checklist');
    }

    GetData = async () => {
        console.log(this.state.checklistId);

        if (this.state.checklistId) {
            const checklistData = await this.FetchAPI('CheckList/' + this.state.checklistId);

            //console.log(checklistData);

            const questionData = await this.FetchAPI('ComplianceChecklistQuestions/' + this.state.checklistId);

            //console.log(questionData);

            const layoutData = await this.FetchAPI('ComplianceChecklistLayout/' + this.state.checklistId);

            //console.log(layoutData);

            // Basically, loop through the layout to determine where each question goes.
            for (var i = 0; i < layoutData.length; i++) {
                // Find the given question via questionId
                var index = questionData.findIndex(x => x.id == layoutData[i].questionId);

                if (index > -1) {
                    //console.log("Found Question from layout");

                    layoutData[i].questionText = questionData[index].questionText;
                    layoutData[i].answerType = questionData[index].answerType;
                    layoutData[i].allowsComments = questionData[index].allowsComments;
                    layoutData[i].questionKey = questionData[index].questionKey;

                    questionData.splice(index, 1);
                }
                else {
                    // TODO: Shouldn't happen yet
                    console.log("COULD NOT FIND QUESTION FROM LAYOUT");
                }
            }

            await this.setState(
            {
                checklistData: checklistData,
                questions: questionData,
                layout: layoutData
            });
        }
    }

    SaveChanges = async () => {
        var checklist = await this.SaveChecklist();
        var questions = await this.SaveQuestions();
        var layout = await this.SaveLayout();

        var alertMessage = "Message:";

        if (checklist.result && questions.result && layout.result) {
            alert("Changes saved successfully");
        }
        else {
            if (!checklist.result) {
                alertMessage += "<br/>- " + checklist.message;
            }
            if (!questions.result) {
                alertMessage += "<br/>- " + questions.message;
            }
            if (!layout.result) {
                alertMessage += "<br/>- " + layout.message;
            }

            alert(alertMessage, "Changes not saved");
        }
    }

    SaveLayout = async () => {
        console.log("Saving Layout...");

        if (this.state.hasEmptySection) {
            return { result: false, message: "Cannot save layout containing empty section(s)" };
        }

        try {
            const result = await this.PostAPI('ComplianceChecklistLayout', { CheckListId: this.state.checklistData.id, LayoutList: this.state.layout });

            if (result.status == 1) {
                this.state.unsavedLayout = false;
                return { result: true, message: "Layout saved successfully" };
            }
            else {
                this.state.unsavedLayout = false;
                return { result: false, message: "Failed to save layout" };
            }
        }
        catch (ex) {
            return { result: false, message: "Failed to save layout" };
        }
    }

    SaveQuestions = async () => {
        console.log("Saving Questions...", this.state.editedQuestions);

        try {
            const result = await this.PostAPI('ComplianceChecklistQuestions/SaveChecklistQuestions', { ChecklistId: this.state.checklistData.id, QuestionList: this.state.editedQuestions });

            if (result.status == 1) {
                this.state.unsavedLayout = false;
                return { result: true, message: "Questions saved successfully" };
            }
            else {
                return { result: false, message: "Failed to save questions" };
            }
        }
        catch (ex) {
            return { result: false, message: "Failed to save questions" };
        }
    }

    SaveChecklist = async (e) => {
        console.log('Saving Checklist...');

        if (e) {
            e.preventDefault();
        }

        try {
            const result = await this.PostAPI('CheckList', this.state.checklistData);

            if (result.status == 1) {
                if (!this.state.checklistData.id) {
                    this.props.navigate('/edit-checklist/' + result.checkListId);
                    this.props.navigate(0);
                    return { result: true, message: "Checklist saved successfully" }
                }
                else {
                    return { result: true, message: "Checklist saved successfully" }
                }
            }
            else {
                return { result: false, message: "Failed to save checklist" }
            }
        }
        catch (ex) {
            return { result: false, message: "Failed to save checklist" };
        }

    }

    SaveCurrentQuestion = async (e) => {
        console.log('Saving Current Question...', e);

        var validationResult = e.validationGroup.validate();

        if (!validationResult.isValid) {
            return;
        }

        this.setState({
            loading: true
        });

        try {
            var currentQuestion = this.state.currentQuestion;
            currentQuestion.checklistId = this.state.checklistData.id;

            if (currentQuestion.questionId) {
                currentQuestion.id = currentQuestion.questionId;
            }


            var questionExists = this.state.questions.some((question) => {
                if (question.id && question.id === currentQuestion.id) {
                    question = currentQuestion;

                    return true;
                }
            });

            var layoutExists = this.state.layout.some((layout) => {
                if (layout.questionId && layout.questionId === currentQuestion.id) {
                    layout.questionText = currentQuestion.questionText;
                    layout.allowsComments = currentQuestion.allowsComments;
                    layout.answerType = currentQuestion.answerType;
                    layout.questionKey = currentQuestion.questionKey;

                    return true;
                }
            });

            var editedExists = this.state.editedQuestions.some((question) => {
                if (question.id && question.id === currentQuestion.id) {
                    question = currentQuestion;

                    return true;
                }
            });

            if (!questionExists && !layoutExists) {
                currentQuestion.id = crypto.randomUUID(); //Temp ID if this question gets edited before pushed to server
                this.state.questions.push(currentQuestion);
            }

            if (!editedExists) {
                this.state.editedQuestions.push(currentQuestion);
            }
        }
        finally {
            this.state.unsavedLayout = true;
            this.questionList.instance().repaint();
            this.hideQuestion();
        }

        this.setState({
            loading: false
        });
    }

    SaveCurrentSection = async (e) => {
        console.log('Saving...');
        var validationResult = e.validationGroup.validate();

        if (!validationResult.isValid) {
            return;
        }

        this.setState({
            loading: true
        });

        try {
            var currentSection = this.state.currentSection;

            //if this is a new section
            if(!currentSection.checklistId) {
                var layout = this.state.layout;
                currentSection.checklistId = this.state.checklistData.id;
                currentSection.isSection = true;
                currentSection.layoutIndex = layout.length;
                layout.push(currentSection);
            }

            this.labelEmptySections();
        }
        finally {
            this.state.unsavedLayout = true;
            this.sectionList.instance().repaint();
            this.hideSection()
        }

        this.setState({
            loading: false
        });
    }

    DeleteQuestion = async (e) => {
        e.cancel = confirm(e.itemData.isSection ? 'Are you sure you want to delete this section? All previous references to it will be deleted.'
            : 'Are you sure you want to delete this question? All previous answers and references to it will be deleted.').then(
            async (answer) => {
                try {
                    if (answer) {
                        var result = await this.DeleteAPI('ComplianceChecklistQuestions/' + e.itemData.id);

                        if (result.status == 1) {
                            this.state.unsavedLayout = true;
                            this.state.editedQuestions = this.state.editedQuestions.filter((question) => question.id !== e.itemData.id);
                            return false;
                        }
                        else {
                            alert("Failed to delete question");
                            return true;
                        }
                    }
                    else {
                        return true;
                    }
                }
                catch (ex) {
                    alert("Failed to delete question" + ex.message);
                    return true;
                }
            }
        );
    }

    labelEmptySections = () => {
        //console.log('Labeling...')
        var prevIsSection = false;

        this.state.layout.map((item, index) => {
            // RPM: Are both items of the same type?
            if (prevIsSection == item.isSection) {
                // if so, are they sections?
                if (prevIsSection) {
                    this.state.layout[index - 1].badge = 'Section cannot be empty';
                    item.badge = null;

                    this.state.hasEmptySection = true;
                }
                else {
                    this.state.layout[index].badge = null;
                }
            }
            else {
                item.badge = null;
            }

            // Is this the last item, and if so, is it a section?
            if (!this.state.layout[index + 1] && item.isSection) {
                item.badge = 'Section cannot be empty';

                this.state.hasEmptySection = true;
            }

            prevIsSection = item.isSection;
        });

        this.state.questions.map((item) => {
            item.badge = null;
        });
    }

    onDragStart = (e) => {
        e.itemData = this.state[e.fromData][e.fromIndex];
    }

    onDragChange = async (e) => {
        if (e.toData === 'questions' && e.itemData.isSection) {
            e.cancel = true;
        }
        else {
            e.cancel = false;
        }
    }

    onRemove = (e) => {
        this.setState({
            loading: true
        });
        const questions = this.state[e.fromData];

        this.state[e.fromData] = [...questions.slice(0, e.fromIndex), ...questions.slice(e.fromIndex + 1)];

        this.setState({
            loading: false
        });
    }

    onAdd = (e) => {
        this.setState({
            loading: true,
            hasEmptySection: false
        });
        const questions = this.state[e.toData];

        if (e.toData === e.fromData) {
            this.state[e.toData] = [...questions.slice(0, e.toIndex), e.itemData, ...questions.slice(e.toIndex)];
        }
        else {
            const destClone = this.state[e.toData];

            if (e.toData === 'layout' && e.itemData.isSection !== true && !e.itemData.questionId) {
                e.itemData.questionId = e.itemData.id;
                this.state.layout = [...destClone.slice(0, e.toIndex), e.itemData, ...destClone.slice(e.toIndex)];
            }
            else {
                this.state[e.toData] = [...questions.slice(0, e.toIndex), e.itemData, ...questions.slice(e.toIndex)];
            }
        }

        // Update layout indexes
        for (var i = 0; i < this.state.layout.length; i++) {
            this.state.layout[i].layoutIndex = i;
        }

        this.setState({
            loading: false,
            unsavedLayout: true
        });
    }

    onReorder = (e) => {
        this.onRemove(e);
        this.onAdd(e);
    }

    nameChanged = (e) => {
        this.updateProperty('checklistData', 'name', e.value);
    }

    showQuestion = (e, item) => {
        if (!item) {
            this.setState({
                questionPopupVisible: true
            });
        }
        else {
            this.setState({
                questionPopupVisible: true,
                currentQuestion: item
            });
        }
    }

    showSection = (e, item) => {
        if (!item) {
            this.setState({
                sectionPopupVisible: true
            });
        }
        else {
            this.setState({
                sectionPopupVisible: true,
                currentSection: item
            });
        }
    }

    hideQuestion = () => {
        this.setState({
            questionPopupVisible: false,
            currentQuestion: {}
        });
    }

    hideSection = () => {
        this.setState({
            sectionPopupVisible: false,
            currentSection: {}
        });
    }

    itemTemplate = (item) => {
        if (item.sectionText != null) {
            return <div style={{
                whiteSpace: 'normal'
            }} className="row">
                <h3 className="col-lg">{item.sectionText}</h3>
                <div style={{ marginRight: '0px', padding: '0px' }} className='col-auto me-2 align-self-center'>
                    <Button className="m-0 p-0" icon='edit' onClick={(e) => this.showSection(e, item)} />
                </div>
            </div>;
        }
        else {
            return (
                <>
                    {item.questionKey
                        ? <div style={{ position: "absolute", right: "10px" }} className="h6 pe-1 ps-1 dx-theme-accent-as-background-color text-light rounded">
                            {item.questionKey}
                        </div>
                        : <></>
                    }
                    <div style={{ position: "relative", zIndex: 1 }} className='row'>
                        <div className="col-lg" style={{ whiteSpace: 'normal' }}>
                            {parse(item.questionText)}
                        </div>
                        <div style={{ marginRight: '0px', padding: '0px' }} className='col-auto me-2 align-self-center'>
                            <Button className="m-0 p-0" icon='edit' onClick={(e) => this.showQuestion(e, item)} />
                        </div>
                    </div>
                    <style>{`
                        .dx-button-has-icon .dx-button-content {
                            padding: 4px 4px;
                        }
                    `}</style>
                </>);
        }
    }

    onItemRendered(e) {
        if (!e.itemData.isSection) {
            var deleteElement = e.itemElement.getElementsByClassName("dx-list-static-delete-button-container");
            deleteElement[0].style.display = "none";
        }
    }

    render() {
        console.log("Beginning render");

        var radioGroupItems = [{
            text: 'Yes/No',
            value: 'Y/N'
        },
        {
            text: 'Yes/No/NA',
            value: 'Y/N/NA'
        }];

        this.labelEmptySections();

        // TODO: Find some way to make the drop area larger for lists when they are empty. Height of lists needs to somehow be shared between both lists. -RPM
        return (
            <div className="container">
                <h1>Edit Checklist</h1>

                <form action="your-action" onSubmit={this.SaveChecklist}>
                    <Form id="form" formData={this.state.checklistData} colCount={4}>
                        <Item dataField="name" colSpan={4}>
                            <RequiredRule />
                            <Label text="Checklist Name" />
                        </Item>
                        <Item dataField="isActive" editorType="dxCheckBox" colSpan={3}>
                            <Label text="Active" />
                        </Item>
                        <ButtonItem buttonOptions={{ text: 'Save', useSubmitBehavior: true }} colSpan={1} />
                    </Form>
                </form>

                <br />

                {this.state.checklistId &&
                    <div className="widget-container">
                        <div className="form-group row">
                            <div className="col">
                                <Button text="Add Question" onClick={this.showQuestion} />
                                <br />
                                <br />
                                <Popup visible={this.state.questionPopupVisible} onHiding={this.hideQuestion} dragEnabled={false}
                                    closeOnOutsideClick={true} showTitle={true} title="Editing Question" height="auto">
                                    <Form id="currentQuestionForm" formData={this.state.currentQuestion} validationGroup="editQuestion">
                                        <Item dataField="questionKey" editorType="dxTextBox" editorOptions={{ maxLength: 20, width: "auto" }}>
                                            <Label text="Question Key" />
                                        </Item>
                                        <Item dataField="questionText" editorType="dxHtmlEditor" editorOptions={htmlEditorOptions} isRequired={true}>
                                            <RequiredRule message="Please input Question Text." />
                                            <Label text="Question Text"/>
                                        </Item>
                                        <Item dataField="answerType" editorType="dxRadioGroup" editorOptions={{ dataSource: radioGroupItems, displayExpr: 'text', valueExpr: 'value' }} isRequired={true}>
                                            <RequiredRule message="Please select Answer Type." />
                                            <Label text="Answer Type" />
                                        </Item>
                                        <Item dataField="allowsComments" editorType="dxCheckBox" >
                                            <Label text="Allows Comments" />
                                        </Item>
                                    </Form>

                                    <Button onClick={this.SaveCurrentQuestion} text="Save" type="success" validationGroup="editQuestion" className="float-end" />
                                </Popup>
                                <List items={this.state.questions} keyExpr="id"
                                    displayExpr="questionText"
                                    itemRender={this.itemTemplate}
                                    allowItemDeleting={true} itemDeleteMode="static"
                                    onItemDeleting={this.DeleteQuestion}
                                    ref={ref => this.questionList = ref}>
                                    <ItemDragging
                                        allowReordering={true}
                                        group="checklist"
                                        data="questions"
                                        onDragStart={this.onDragStart}
                                        onDragEnd={this.onDragEnd}
                                        onDragChange={this.onDragChange}
                                        onAdd={this.onAdd}
                                        onRemove={this.onRemove}
                                        onReorder={this.onReorder}>
                                    </ItemDragging>
                                </List>
                            </div>
                            <div className="col">
                                <div className="d-flex">
                                    <Button text="Add Section" onClick={this.showSection} />
                                    <Button className="ms-auto" type="success" text="Save Changes" onClick={this.SaveChanges} />
                                </div>
                                <br />
                                <Popup visible={this.state.sectionPopupVisible} onHiding={this.hideSection} dragEnabled={false}
                                    closeOnOutsideClick={true} showTitle={true} title="Editing Section" height="auto">
                                    <Form id="currentSectionForm" formData={this.state.currentSection} validationGroup="editSection">
                                        <Item dataField="sectionText">
                                            <RequiredRule message="Please input Section Text." />
                                            <Label text="Section Text" />
                                        </Item>
                                    </Form>
                                    <br/>
                                    <Button onClick={this.SaveCurrentSection} text="Save" type="success" validationGroup="editSection" className="float-end" />
                                </Popup>
                                <List items={this.state.layout} keyExpr="id"
                                    displayExpr="questionText" itemRender={this.itemTemplate}
                                    allowItemDeleting={true} itemDeleteMode="static" onItemDeleting={this.DeleteQuestion}
                                    onItemRendered={this.onItemRendered}
                                    ref={ref => this.sectionList = ref}>
                                    <ItemDragging
                                        allowReordering={true}
                                        group="checklist"
                                        data="layout"
                                        onDragStart={this.onDragStart}
                                        onDragEnd={this.onDragEnd}
                                        onAdd={this.onAdd}
                                        onRemove={this.onRemove}
                                        onReorder={this.onReorder}>
                                    </ItemDragging>
                                </List>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default withParams(ComplianceChecklistEditContainer);