import React from 'react';
import BaseComponent from '../BaseComponent';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { InventoryMealsManagementContainer } from './InventoryMealsManagementContainer.js';
import { InventoryClothesManagementContainer } from './InventoryClothesManagementContainer.js';
import { InventoryOtherItemsManagementContainer } from './InventoryOtherItemsManagementContainer.js';

export class InventoryManagementContainer extends BaseComponent {
    static displayName = InventoryManagementContainer.name;

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Inventory Management");
    }

    render() {

        return (
            <div className="container">
                <h1>Inventory Management</h1>
                <br />

                <TabPanel>
                    <Item title="Meals">
                        <InventoryMealsManagementContainer />
                    </Item>

                    <Item title="Clothes">
                        <InventoryClothesManagementContainer />
                    </Item>

                    <Item title="Other Items">
                        <InventoryOtherItemsManagementContainer />
                    </Item>
                </TabPanel>
            </div>
        );
    }
}