
import React from 'react';
import * as moment from 'moment';
import DataGrid, { FilterRow, Column, Pager, Paging, MasterDetail } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import Button from 'devextreme-react/button';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { Link } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
import BaseComponent, { PostAPIPromise } from '../BaseComponent';
import PieChart, { Series, Title, Legend, Font, Size, Tooltip } from 'devextreme-react/pie-chart';
import Form, { Item as FormItem, Label } from 'devextreme-react/form';

import Accordion, { Item as AccordionItem } from 'devextreme-react/accordion';

// TODO: Consider storing user preferences for office selection(s)
// TODO: Why is pickup location showing inactive entries?
export class TransportAnalystDashboardContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            daysToCompleteValue: [{
                label: 'Trips Ending Today',
                value: 5
            }, {
                label: 'Trips Beginning Today',
                value: 5
            }],
            ageOutRiskValue: [{
                label: 'Aged Out',
                value: 5
            }, {
                label: 'Low',
                value: 5
            },
            {
                label: 'Medium',
                value: 5
            },
            {
                label: 'High',
                value: 5
            }],

            workOrderMetrics: {
                tripsBegunNotActive: -1,
                planPickupNoPickups: -1,
                planDropoffNoDropoffs: -1
            },

            placementMetrics: {
                placementsNeedingRebooking: -1,
            },

            tripsBeginningTodayMetric: "Trip Breakdown",
            tripsEndingTodayMetric: 'Trips Ending Today',

            questionsFailedMetric: 'X Questions Failed',

            ageOutRiskMetric: 'Age Out Risk',

            departureLocations: [],
            destinationLocations: [],
            states: [],
            aors: [],

            initialized: false,

            accordionSelectedIndex: 0,

            // TODO: Does it make sense to add other filters, like pickup location, departure city, AOR, etc?
            filters: {
                currentDay: '',

                departureLocations: [],
                departureStates: [],
                departureAORs: [],

                destinationLocations: [],
                destinationStates: [],
                destinationAORs: [],

                showBeginningToday: false,
                showEndingToday: false,

                showBegunStillOpen: false,
                showPickupNoPickups: false,
                showDropoffNoDropoffs: false,

                showAgedOutPlacements: false,
                showHighRiskPlacements: false,
                showMediumRiskPlacements: false,
                showLowRiskPlacements: false,

                showNeedingRebooking: false
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("TA Dashboard");
    }

    customizeTooltip = (e) => {
        return { text: e.argumentText + ': ' + e.valueText };
    }

    GetDropDownData = async () => {
        var facilityData = await this.FetchAPI('Facility/GetForSelection');

        var pickupAgencies = await this.FetchAPI('PickupLocation/GetPickupAgencies');

        const stateData = await this.FetchAPI('DictionaryState');
        const aorData = await this.FetchAPI('DictionaryAOR');

        await this.setState({
            departureLocations: pickupAgencies,
            destinationLocations: facilityData,
            states: stateData,
            aors: aorData
        });
    }

    GetData = async () => {
         // console.log(this.state.filters);

        //console.log('Local Storage for dashboard', localStorage.getItem('currentDay'));

        var newFilters = this.state.filters;

        if (!this.state.initialized) {

            //console.log('Initializing Widget');

            //var currentDay = moment();

            var currentDay = localStorage.getItem('currentDay');

            //console.log('Local storage', currentDay);

            currentDay = !currentDay ? moment().format('YYYY-MM-DD') : moment(currentDay, "YYYY-MM-DD").format('YYYY-MM-DD');

            //console.log('Current Day');
            //console.log(currentDay);

            newFilters.currentDay = currentDay
            newFilters.departureAORs = localStorage.getItem('departureAORs');
            newFilters.departureLocations = localStorage.getItem('departureLocations');
            newFilters.departureStates = localStorage.getItem('departureStates');
            newFilters.destinationAORs = localStorage.getItem('destinationAORs');
            newFilters.destinationLocations = localStorage.getItem('destinationLocations');
            newFilters.destinationStates = localStorage.getItem('destinationStates');

            newFilters.departureAORs = newFilters.departureAORs ? newFilters.departureAORs.split(',') : null;
            newFilters.departureLocations = newFilters.departureLocations ? newFilters.departureLocations.split(',').map((item) => parseInt(item)) : null;
            newFilters.departureStates = newFilters.departureStates ? newFilters.departureStates.split(',') : null;

            newFilters.destinationAORs = newFilters.destinationAORs ? newFilters.destinationAORs.split(',') : null;
            newFilters.destinationLocations = newFilters.destinationLocations ? newFilters.destinationLocations.split(',').map((item) => parseInt(item)) : null;
            newFilters.destinationStates = newFilters.destinationStates ? newFilters.destinationStates.split(',') : null;

            var selectedIndex = localStorage.getItem('additionalFiltersSelectedIndex');

            selectedIndex = (selectedIndex ? parseInt(selectedIndex) : 0);

            await this.setState({
                filters: {
                    currentDay: newFilters.currentDay,
                    departureAORs: newFilters.departureAORs,
                    departureLocations: newFilters.departureLocations,
                    departureStates: newFilters.departureStates,
                    destinationAORs: newFilters.destinationAORs,
                    destinationLocations: newFilters.destinationLocations,
                    destinationStates: newFilters.destinationStates
                },

                accordionSelectedIndex: selectedIndex,

                initialized: true
            });
        }

        // console.log('Current filters', this.state);

        await this.setState({

            loading: false
        });

        if (this.workOrderDataGrid) {
            this.workOrderDataGrid.instance().refresh();
        }

        if (this.placementDataGrid) {
            this.placementDataGrid.instance().refresh();
        }
    }

    tripBreakdownClickHandler = (arg) => {
        var beginningToday = false;
        var endingToday = false;

        if (arg.target.isSelected()) {
            arg.target.clearSelection();
            beginningToday = false;
            endingToday = false;
        }
        else {
            arg.target.select();
            beginningToday = arg.target.data.label == 'Trips Beginning Today';
            endingToday = arg.target.data.label == 'Trips Ending Today';
        }
        //console.log('target', arg.target);

        this.setState(
            (state) => ({
                filters: {
                    ...state.filters,
                    showBeginningToday: beginningToday,
                    showEndingToday: endingToday
                }
            })
        );

        this.workOrderDataGrid.instance().refresh();
    }

    ageOutRiskClickHandler = (arg) => {
        var agedOutPlacements = false;
        var highRiskPlacements = false;
        var mediumRiskPlacements = false;
        var lowRiskPlacements = false;

        if (arg.target.isSelected()) {
            arg.target.clearSelection();

            agedOutPlacements = false;
            highRiskPlacements = false;
            mediumRiskPlacements = false;
            lowRiskPlacements = false;
        }
        else {
            arg.target.select();
            agedOutPlacements = arg.target.data.label == 'Aged Out';
            highRiskPlacements = arg.target.data.label == 'High';
            mediumRiskPlacements = arg.target.data.label == 'Medium';
            lowRiskPlacements = arg.target.data.label == 'Low';
        }

        //console.log('target', arg.target);

        this.setState(
            (state) => ({
                filters: {
                    ...state.filters,
                    showAgedOutPlacements: agedOutPlacements,
                    showHighRiskPlacements: highRiskPlacements,
                    showMediumRiskPlacements: mediumRiskPlacements,
                    showLowRiskPlacements: lowRiskPlacements
                }
            })
        );

        this.placementDataGrid.instance().refresh();
    }

    toggleTripsBegunNotActiveFilter = (e) => {
        e.preventDefault();

        this.setState(
            (state) => ({
                filters: {
                    ...state.filters,
                    showBegunStillOpen: !this.state.filters.showBegunStillOpen
                }
            })
        );

        this.workOrderDataGrid.instance().refresh();
    }

    togglePlanPickupNoPickupsFilter = (e) => {
        e.preventDefault();

        this.setState(
            (state) => ({
                filters: {
                    ...state.filters,
                    showPickupNoPickups: !this.state.filters.showPickupNoPickups
                }
            })
        );

        this.workOrderDataGrid.instance().refresh();
    }

    togglePlanDropoffNoDropoffsFilter = (e) => {
        e.preventDefault();

        this.setState(
            (state) => ({
                filters: {
                    ...state.filters,
                    showDropoffNoDropoffs: !this.state.filters.showDropoffNoDropoffs
                }
            })
        );

        this.workOrderDataGrid.instance().refresh();
    }

    togglePlacementsNeedingRebookingFilter = (e) => {
        e.preventDefault();

        this.setState(
            (state) => ({
                filters: {
                    ...state.filters,
                    showNeedingRebooking: !this.state.filters.showNeedingRebooking
                }
            })
        );

        this.placementDataGrid.instance().refresh();
    }

    fieldDataChanged = async (e) => {
         //console.log('Field Data changed', e);
        //console.log(this.state.filters);

        localStorage.setItem(e.dataField, e.value);

        // TODO: Probably could alter this to not fetch all data every time
        await this.fetchData();

        if (this.workOrderDataGrid) {
            this.workOrderDataGrid.instance().refresh();
        }

        if (this.placementDataGrid) {
            this.placementDataGrid.instance().refresh();
        }
    }

    selectionChanged = (e) => {
        //console.log(e);

        var selectedIndex = e.addedItems.length > 0 ? 0 : -1;

        localStorage.setItem('additionalFiltersSelectedIndex', selectedIndex);

        this.setState({
            accordionSelectedIndex: selectedIndex
        });
    }

    workOrderDataSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            if (this.state.filters.currentDay) {
                return PostAPIPromise('WorkOrder/GetWorkOrderDailyMetrics', this.state.filters);
            }
        },
        onLoaded: (loadOptions) => {
            //console.log('loaded', loadOptions);

            if (!loadOptions.status) {
                var workOrderData = loadOptions;

                var tripsThatStartToday = workOrderData.filter((item) => this.customMoment(item.planStartDate).isBetween(this.customMoment(this.state.filters.currentDay), this.customMoment(this.state.filters.currentDay).add(1, 'day'), null, "[]")).length;
                var tripsThatEndToday = workOrderData.filter((item) => this.customMoment(item.planEndDate).isBetween(this.customMoment(this.state.filters.currentDay), this.customMoment(this.state.filters.currentDay).add(1, 'day'), null, "[]")).length;

                // Calculate trips begun not active (in open status)
                var tripsBegunStillOpen = workOrderData.filter((item) => this.customMoment().isAfter(this.customMoment(item.planStartDate)) && item.status == "Open").length;

                // Calculate trips with overdue pickups/dropoffs
                var tripsWithOverduePickups = workOrderData.filter((item) => item.pickupDropoffList.filter((customerItem) => this.customMoment().isAfter(this.customMoment(customerItem.planPickupDate)) && !customerItem.pickupMilestone).length > 0).length;
                var tripsWithOverdueDropoffs = workOrderData.filter((item) => item.pickupDropoffList.filter((customerItem) => this.customMoment().isAfter(this.customMoment(customerItem.planRemovalDate)) && !customerItem.dropoffMilestone).length > 0).length;

                this.setState({
                    daysToCompleteValue: [{
                        label: 'Trips Ending Today',
                        value: tripsThatEndToday
                    }, {
                        label: 'Trips Beginning Today',
                        value: tripsThatStartToday
                    }],

                    workOrderMetrics: {
                        tripsBegunNotActive: tripsBegunStillOpen,
                        planPickupNoPickups: tripsWithOverduePickups,
                        planDropoffNoDropoffs: tripsWithOverdueDropoffs
                    },

                    loading: false
                });
            }
        }
    });

    placementDataSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            return PostAPIPromise('Placement/GetPlacementDailyMetrics', this.state.filters);
        },
        onLoaded: (loadOptions) => {
            //console.log('loaded', loadOptions);

            if (!loadOptions.status) {
                var placementData = loadOptions;

                var agedOutPlacements = placementData.filter((item) => item.customerList.filter((customerItem) => customerItem.daysUntilAgeOut < 0).length > 0).length;
                var highRiskPlacements = placementData.filter((item) => item.customerList.filter((customerItem) => customerItem.daysUntilAgeOut > 0 && customerItem.daysUntilAgeOut <= 14).length > 0).length;
                var mediumRiskPlacements = placementData.filter((item) => item.customerList.filter((customerItem) => customerItem.daysUntilAgeOut > 14 && customerItem.daysUntilAgeOut <= 30).length > 0).length;
                var lowRiskPlacements = placementData.filter((item) => item.customerList.filter((customerItem) => customerItem.daysUntilAgeOut > 30).length > 0).length;

                // Calculate placements in need of rebooking
                var placementsNeedingRebooking = placementData.filter((item) => item.workOrderID).length;

                this.setState({
                    ageOutRiskValue: [{
                        label: 'Aged Out',
                        value: agedOutPlacements
                    }, {
                        label: 'Low',
                        value: lowRiskPlacements
                    },
                    {
                        label: 'Medium',
                        value: mediumRiskPlacements
                    },
                    {
                        label: 'High',
                        value: highRiskPlacements
                    }],

                    placementMetrics: {
                        placementsNeedingRebooking: placementsNeedingRebooking
                    },

                    loading: false
                });
            }
        }
    });

    ageOutRiskRender(cell) {
        //console.log('POCs render', cell);

        // TODO: What about empty placement?
        var maxAgeOutRisk = null;

        cell.data.customerList.map((customer, customerIndex) => {

            // TODO: When N/A, when cut off?
            // TODO: N/A when canceled or dispatched?
            var localDays = customer.daysUntilAgeOut;

            if (maxAgeOutRisk == null || localDays < maxAgeOutRisk) {
                //console.log('Setting age out to ', localDays);
                maxAgeOutRisk = localDays;
            }
        });

        //console.log('Max Risk', maxAgeOutRisk);

        if (maxAgeOutRisk == null) {
            return 'N/A';
        }
        else {
            // TODO: Convert to risk level
            if (maxAgeOutRisk < 0) {
                return 'Aged Out';
            }
            else if (maxAgeOutRisk <= 14) {
                return 'High';
            }
            else if (maxAgeOutRisk <= 30) {
                return 'Medium';
            }
            else {
                return 'Low';
            }
        }
    }

    ageOutRiskCalculation(rowData) {
        //console.log('Cell Data', rowData);

        var maxAgeOutRisk = null;

        rowData.customerList.map((customer, customerIndex) => {

            // TODO: When N/A, when cut off?
            // TODO: N/A when canceled or dispatched?
            var localDays = customer.daysUntilAgeOut;

            if (maxAgeOutRisk == null || localDays < maxAgeOutRisk) {
                //console.log('Setting age out to ', localDays);
                maxAgeOutRisk = localDays;
            }
        });

        //console.log('Max Risk', maxAgeOutRisk);

        if (maxAgeOutRisk == null) {
            return 'N/A';
        }
        else {
            // TODO: Convert to risk level
            if (maxAgeOutRisk < 0) {
                return 'Aged Out';
            }
            else if (maxAgeOutRisk <= 14) {
                return 'High';
            }
            else if (maxAgeOutRisk <= 30) {
                return 'Medium';
            }
            else {
                return 'Low';
            }
        }
    }

    render() {

        return (
            <div className="container">
                <h1>TA Dashboard</h1>

                <div className="container">
                    <Form ref={ref => this.filterForm = ref}
                        formData={this.state.filters} colCount={2} onFieldDataChanged={this.fieldDataChanged}>
                        <FormItem editorType="dxDateBox" dataField="currentDay">
                            <Label text="Viewing for" />
                        </FormItem>
                    </Form>
                    <br />

                    <Accordion collapsible={true} multiple={false} selectedIndex={this.state.accordionSelectedIndex} onSelectionChanged={this.selectionChanged}>
                        <AccordionItem title="Add'l Filters" key="filters">
                            <Form 
                                formData={this.state.filters} colCount={2} onFieldDataChanged={this.fieldDataChanged}>
                                <FormItem editorType="dxTagBox" dataField="departureLocations" editorOptions={{ searchEnabled: true, applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.departureLocations, displayExpr: 'name', valueExpr: 'id' }}>
                                    <Label text="Departure Location(s)" />
                                </FormItem>
                                <FormItem editorType="dxTagBox" dataField="destinationLocations" editorOptions={{ searchEnabled: true, applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.destinationLocations, displayExpr: 'name', valueExpr: 'id' }}>
                                    <Label text="Destination Location(s)" />
                                </FormItem>

                                <FormItem editorType="dxTagBox" dataField="departureStates" editorOptions={{ searchEnabled: true, applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.states, displayExpr: 'name', valueExpr: 'name' }}>
                                    <Label text="Departure State(s)" />
                                </FormItem>
                                <FormItem editorType="dxTagBox" dataField="destinationStates" editorOptions={{ searchEnabled: true, applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.states, displayExpr: 'name', valueExpr: 'name' }}>
                                    <Label text="Destination State(s)" />
                                </FormItem>

                                <FormItem editorType="dxTagBox" dataField="departureAORs" editorOptions={{ searchEnabled: true, applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.aors, displayExpr: 'aorCode', valueExpr: 'aorCode' }}>
                                    <Label text="Departure AOR(s)" />
                                </FormItem>
                                <FormItem editorType="dxTagBox" dataField="destinationAORs" editorOptions={{ searchEnabled: true, applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.aors, displayExpr: 'aorCode', valueExpr: 'aorCode' }}>
                                    <Label text="Destination AOR(s)" />
                                </FormItem>
                            </Form>
                        </AccordionItem>
                    </Accordion>

                    <br />

                    <TabPanel>
                        <Item title="Work Orders">
                            <div style={{ margin: "10px" }}>
                                <div className="row">

                                    <PieChart className="col-sm-3"
                                        type="doughnut"
                                        palette="Soft Pastel"
                                        dataSource={this.state.daysToCompleteValue}
                                        onPointClick={this.tripBreakdownClickHandler}
                                        sizeGroup="doughnuts">
                                        <Series argumentField="label" valueField="value" />
                                        <Title text={this.state.tripsBeginningTodayMetric} horizontalAlignment="center">
                                            <Font size="15" />
                                        </Title>
                                        <Tooltip enabled={true} customizeTooltip={this.customizeTooltip} />
                                        <Legend visible={false} />
                                        <Size height={200} width={200} />
                                    </PieChart>

                                    <div className="text-center col-sm-4">
                                        <h1><a href="#" onClick={this.toggleTripsBegunNotActiveFilter} style={{ color: (this.state.filters.showBegunStillOpen ? '#f1929f' : '#3cbab2') }}>{this.state.workOrderMetrics.tripsBegunNotActive}</a></h1>
                                        <p>Trips Begun but Still Open</p>
                                    </div>

                                    <div className="text-center col-sm-4">
                                        <h1><a href="#" onClick={this.togglePlanPickupNoPickupsFilter} style={{ color: (this.state.filters.showPickupNoPickups ? '#f1929f' : '#3cbab2') }}>{this.state.workOrderMetrics.planPickupNoPickups}</a></h1>
                                        <p>Trips w/ Overdue Pickups</p>

                                        <h1><a href="#" onClick={this.togglePlanDropoffNoDropoffsFilter} style={{ color: (this.state.filters.showDropoffNoDropoffs ? '#f1929f' : '#3cbab2') }}>{this.state.workOrderMetrics.planDropoffNoDropoffs}</a></h1>
                                        <p>Trips w/ Overdue Dropoffs</p>
                                    </div>
                                </div>

                                <br />

                                <div className="text-center">
                                    <h5>Work Orders for {this.state.filters.currentDay ? moment(this.state.filters.currentDay, "YYYY-MM-DD").format('L') : 'MISSING'}</h5>
                                </div>

                                <DataGrid dataSource={this.workOrderDataSource}
                                    ref={ref => this.workOrderDataGrid = ref}
                                    showBorders={true} allowColumnResizing={true}>
                                    <FilterRow visible={true} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 20]}
                                        showInfo={true} />
                                    <Column caption="Number" dataField="number" dataType="string" />
                                    <Column caption="Work Order Name" dataField="name" cellRender={function (options) { return (<Link to={`/workorder-detail/${options.row.data.id}`}>{options.value}</Link>); }} />
                                    <Column caption="Start Date" dataField="planStartDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                                    <Column caption="End Date" dataField="planEndDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                                    <Column caption="Departure City" dataField="departureCity" />
                                    <Column caption="Destination City" dataField="destinationCity" />
                                    <Column caption="Status" dataField="status" dataType="string" />
                                    <Column caption="Transport Type" dataField="transportMethod" />
                                    <Column caption="Transport Method" dataField="transportType" />
                                    <Column caption="Unit" dataField="transportUnit" />
                                    <Column caption="Type" dataField="workOrderType" />
                                    <Column caption="LUU" dataField="lastUpdateUser" />
                                    <Column caption="" allowFiltering={false} allowSorting={false} allowResizing={false} dataField="id"
                                        width={225}
                                        cellRender={
                                            function (options) {
                                                return (<><Link to={`/workorder-milestones/${options.row.data.id}`}><Button text="Milestones" /></Link>&nbsp;<Link to={`/workorder-resources/${options.row.data.id}`}><Button text="Resources" /></Link></>);
                                            }
                                        } />

                                    <MasterDetail enabled={true} component={PickupDropoffDetail} />
                                </DataGrid>
                            </div>
                        </Item>

                        <Item title="Placements">
                            <div style={{ margin: "10px" }}>
                                <div className="row">

                                    <PieChart className="col-sm-3"
                                        type="doughnut"
                                        palette="Soft Pastel"
                                        dataSource={this.state.ageOutRiskValue}
                                        onPointClick={this.ageOutRiskClickHandler}
                                        sizeGroup="doughnuts">
                                        <Series argumentField="label" valueField="value" />
                                        <Title text={this.state.ageOutRiskMetric} horizontalAlignment="center">
                                            <Font size="15" />
                                        </Title>
                                        <Tooltip enabled={true} customizeTooltip={this.customizeTooltip} />
                                        <Legend visible={false} />
                                        <Size height={200} width={200} />
                                    </PieChart>

                                    <div className="text-center col-sm-4">
                                        <h1><a href="#" onClick={this.togglePlacementsNeedingRebookingFilter} style={{ color: (this.state.filters.showNeedingRebooking ? '#f1929f' : '#3cbab2') }}>{this.state.placementMetrics.placementsNeedingRebooking}</a></h1>
                                        <p>Placements Needing Rebooking</p>
                                    </div>
                                </div>
                                <br />

                                <div className="text-center">
                                    <h5>Placement Queue</h5>
                                </div>

                                <DataGrid dataSource={this.placementDataSource}
                                    ref={ref => this.placementDataGrid = ref}
                                    showBorders={true} allowColumnResizing={true}>
                                    <FilterRow visible={true} />
                                    <Paging defaultPageSize={10} />
                                    <Pager showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 20]}
                                        showInfo={true} />

                                    <Column caption="Name" dataField="placementShortName" />
                                    <Column caption="Placement Date" dataField="orrPlacementDate" dataType="datetime" format="MM/dd/yy, HH:mm '(Local)'" />
                                    <Column caption="Movement Type" dataField="movementType" />
                                    <Column caption="Transport Type" dataField="transportUnit" />
                                    <Column caption="Departure City" dataField="departureCity" />
                                    <Column caption="Pickup Location" dataField="pickupLocationName" />
                                    <Column caption="Destination" dataField="destinationName" />
                                    <Column caption="DHS Custody Date" dataField="dhsCustodyDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                                    <Column caption="Subclassification Code" dataField="subclassificationName" />
                                    <Column caption="Status" dataField="statusName" />
                                    <Column caption="Age Out Risk" cellRender={this.ageOutRiskRender} allowFiltering={true} allowSorting={true} calculateSortValue={this.ageOutRiskCalculation} calculateCellValue={this.ageOutRiskCalculation} />

                                    <MasterDetail enabled={true} component={PlacementDetail} />
                                </DataGrid>
                            </div>
                        </Item>
                    </TabPanel>
                    <br />
                </div>
                <LoadPanel visible={this.state.loading} />
            </div>
        );
    }
}

class PickupDropoffDetail extends React.Component {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.dataSource = props.data.row.data.groupedPickupDropoffList;
    }

    planPickupDateRender = function (options) {

        //console.log(options);

        if (options.data.pickupStatusCode == 'ACTIVE') {
            if (options.value != null) {
            options.value = moment.parseZone(options.value).format('yyyy-MM-DDTHH:mm:ss');
            var timezoneAbbr = moment.tz(options.value, options.data.pickUpTimeZoneId).format('z');
            return (moment(options.value).format('MM/DD/yyyy HH:mm ') + timezoneAbbr);
            }
            else {
                return 'N/A';
            }
        }
        else {
            return 'N/A';
        }
    }

    planDropoffDateRender = function (options) {

        //console.log(options);

        if (options.data.dropoffStatusCode == 'ACTIVE') {
            if (options.value != null) {
                options.value = moment.parseZone(options.value).format('yyyy-MM-DDTHH:mm:ss');
                var timezoneAbbr = moment.tz(options.value, options.data.dropOffTimeZoneId).format('z');
                return (moment(options.value).format('MM/DD/yyyy HH:mm ') + timezoneAbbr);
            }
            else {
                return 'N/A';
            }
        }
        else {
            return 'N/A';
        }
    }

    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true}>
                <Column caption="Pickup Location" dataField="pickupLocation" />
                <Column caption="Facility" dataField="facility" />
                <Column caption="Plan Pickup Time" dataField="planPickupDate" dataType="string" cellRender={this.planPickupDateRender} />
                <Column caption="Plan Dropoff Time" dataField="planRemovalDate" dataType="string" cellRender={this.planDropoffDateRender} />

                <MasterDetail enabled={true} autoExpandAll={true} component={PlacementDetail} />
            </DataGrid>
        );
    }
}

class PlacementDetail extends React.Component {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.dataSource = props.data.row.data.customerList;
    }

    pickupDateRender = function (options) {

        //console.log(options);

        if (options.data.pickupStatusCode == 'ACTIVE') {
            if (options.value != null) {
                options.value = moment.parseZone(options.value).format('yyyy-MM-DDTHH:mm:ss');
                var timezoneAbbr = moment.tz(options.value, options.data.pickUpTimeZoneId).format('z');
                return (moment(options.value).format('MM/DD/yyyy HH:mm ') + timezoneAbbr);
            }
            else {
                return 'N/A';
            } 
        }
        else {
            return 'N/A';
        }
    }

    dropoffDateRender = function (options) {

        //console.log(options);

        if (options.data.dropoffStatusCode == 'ACTIVE') {
            if (options.value != null) {
                options.value = moment.parseZone(options.value).format('yyyy-MM-DDTHH:mm:ss');
                var timezoneAbbr = moment.tz(options.value, options.data.dropOffTimeZoneId).format('z');
                return (moment(options.value).format('MM/DD/yyyy HH:mm ') + timezoneAbbr);
            }
            else {
                return 'N/A';
            } 
        }
        else {
            return 'N/A';
        }
    }

    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true}>
                <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                <Column caption="First Name" dataField="firstName" />
                <Column caption="Middle Name" dataField="middleName" />
                <Column caption="Last Name" dataField="lastName" />
                <Column caption="Gender" dataField="gender" />
                <Column caption="DOB" dataField="dob" dataType="datetime" format="MM/dd/yyyy" />
                <Column caption="Actual Pickup Time" dataField="pickupDate" dataType="string" cellRender={this.pickupDateRender} />
                <Column caption="Actual Dropoff Time" dataField="dropOffDate" dataType="string" cellRender={this.dropoffDateRender} />
                <Column caption="Days Until Age Out" dataField="daysUntilAgeOut" />
            </DataGrid>
        );
    }
}