import React from 'react';
import { Link } from 'react-router-dom';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent.js'
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { Column, FilterRow, Pager, Paging, MasterDetail, Export } from 'devextreme-react/data-grid';
import { CheckBox } from 'devextreme-react/check-box';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { systemConfig } from '../SystemConfig';
import Badge from 'react-bootstrap/Badge'

export class PlacementSearchContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.state = {
            pendingChangeRequestCount: 0,

            filter: {
                notDispatched: true,
                dispatched: false,
                addedOnSupport: false,
                cancelled: false,
                text: ""
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Placement List");
    }

    placementList = new CustomStore({
        key: 'id',
        onLoading: (loadOptions) => {
            console.log('Loading: ' + loadOptions);

            loadOptions.userData["notDispatched"] = this.state.filter.notDispatched;
            loadOptions.userData["dispatched"] = this.state.filter.dispatched;
            loadOptions.userData["addedOnSupport"] = this.state.filter.addedOnSupport;
            loadOptions.userData["cancelled"] = this.state.filter.cancelled;
            loadOptions.userData["text"] = this.state.filter.text;
        },
        load: (loadOptions) => {
            console.log('Load: ' + loadOptions);

            var queryString = ' ';
            var queryOptions = Object.keys(loadOptions.userData);

            console.log(queryOptions);

            for (var i = 0; i < queryOptions.length; i++) {

                if (queryString) {
                    queryString += '&';
                }

                queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
            }
            console.log(queryString);

            return FetchAPIPromise('Placement', queryString);
        }
    });

    handleChange = (event) => {
        this.setState(
            (state) => ({
                filter: {
                    ...state.filter,
                    [event.element.id]: !state.filter[event.element.id]
                }
            })
        );
    };

    GetData = async () => {
        const changeRequestData = await this.FetchAPI('PlacementChangeRequest?submitted=true&approved=false&notApproved=false');
        const addRequestData = await this.FetchAPI('PlacementAddRequest?submitted=true&approved=false&notApproved=false');

        //console.log(changeRequestData);
        //console.log(addRequestData);

        var changeRequestCount = 0;

        changeRequestData.map((item) => changeRequestCount += item.requestList.length);
        addRequestData.map((item) => changeRequestCount += item.requestList.length);

        this.setState({
            pendingChangeRequestCount: changeRequestCount
        });
    }

    GetDropDownData = async () => {
        const genderData = await this.FetchAPI('DictionaryGender');

        this.setState({
            genders: genderData
        });
    }

    search = () => {
        this.dataGrid.instance().refresh();
    }

    handleTextChange = async (event) => {
        await this.setState(
            (prevState) => ({
                filter: {
                    ...prevState.filter,
                    text: event.value
                }
            })
        );
    }

    ageOutRiskRender(cell) {
        //console.log('POCs render', cell);

        // TODO: What about empty placement?
        var maxAgeOutRisk = null;

        cell.data.customerList.map((customer, customerIndex) => {

            // TODO: When N/A, when cut off?
            // TODO: N/A when canceled or dispatched?
            var localDays = customer.daysUntilAgeOut;

            if (maxAgeOutRisk == null || localDays < maxAgeOutRisk) {
                //console.log('Setting age out to ', localDays);
                maxAgeOutRisk = localDays;
            }
        });

        //console.log('Max Risk', maxAgeOutRisk);

        if (maxAgeOutRisk == null) {
            return 'N/A';
        }
        else {
            // TODO: Convert to risk level
            if (maxAgeOutRisk < 0) {
                return 'Aged Out';
            }
            else if (maxAgeOutRisk <= 14) {
                return 'High';
            }
            else if (maxAgeOutRisk <= 30) {
                return 'Medium';
            }
            else {
                return 'Low';
            }
        }
    }

    ageOutRiskCalculation(rowData) {
        //console.log('Cell Data', rowData);

        var maxAgeOutRisk = null;

        rowData.customerList.map((customer, customerIndex) => {

            // TODO: When N/A, when cut off?
            // TODO: N/A when canceled or dispatched?
            var localDays = customer.daysUntilAgeOut;

            if (maxAgeOutRisk == null || localDays < maxAgeOutRisk) {
                //console.log('Setting age out to ', localDays);
                maxAgeOutRisk = localDays;
            }
        });

        //console.log('Max Risk', maxAgeOutRisk);

        if (maxAgeOutRisk == null) {
            return 'N/A';
        }
        else {
            // TODO: Convert to risk level
            if (maxAgeOutRisk < 0) {
                return 'Aged Out';
            }
            else if (maxAgeOutRisk <= 14) {
                return 'High';
            }
            else if (maxAgeOutRisk <= 30) {
                return 'Medium';
            }
            else {
                return 'Low';
            }
        }
    }

    daysInCareCalculation(rowData) {
        //console.log('Cell Data', rowData);

        var maxDaysInCare = null;

        rowData.customerList.map((customer, customerIndex) => {

            // TODO: When cut off?
            // TODO: N/A when canceled or dispatched?
            var localDays = customer.daysInCare;

            if (maxDaysInCare == null || localDays < maxDaysInCare) {
                //console.log('Setting age out to ', localDays);
                maxDaysInCare = localDays;
            }
        });

        //console.log('Max Risk', maxAgeOutRisk);

        if (maxDaysInCare == null || maxDaysInCare == -1) {
            return null;
        }
        else {
            return maxDaysInCare;
        }
    }

    daysInCareRender(cell) {
        //console.log('Cell Data', rowData);

        var maxDaysInCare = null;

        cell.data.customerList.map((customer, customerIndex) => {

            // TODO: When cut off?
            // TODO: N/A when canceled or dispatched?
            var localDays = customer.daysInCare;

            if (maxDaysInCare == null || localDays < maxDaysInCare) {
                //console.log('Setting age out to ', localDays);
                maxDaysInCare = localDays;
            }
        });

        //console.log('Max Risk', maxAgeOutRisk);

        if (maxDaysInCare == null || maxDaysInCare == -1) {
            return 'N/A';
        }
        else {
            return maxDaysInCare + ' Days';
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h1>Placement List</h1>

                <div className="d-flex justify-content-end">
                    <div className="ms-auto">
                        {(this.IsReadOnly())
                            ? <Button disabled={true} text="Bulk Upload" />
                            : <Link to="/upload-request"><Button text="Bulk Upload" /></Link>
                        }
                        &nbsp;
                        <Link to="/matchmake-placements">
                            <Button text="Matchmake Placements" />
                        </Link>
                        &nbsp;
                        <Link to="/placement-change-requests">
                            <Button text="Change Requests" hint={this.state.pendingChangeRequestCount > 0 ? this.state.pendingChangeRequestCount + ' pending requests' : ''} style={{ height: '36px' }}>
                                <span>Change Requests {this.state.pendingChangeRequestCount > 0 && <Badge bg="danger">{this.state.pendingChangeRequestCount}</Badge>}</span>
                            </Button>
                        </Link>
                        &nbsp;
                        {(this.IsReadOnly())
                            ? <Button disabled={true} text="Stage Placements" />
                            : <Link to="/stage-placements"><Button text="Stage Placements" /></Link>
                        }
                        &nbsp;
                        <Link to={"/Artifacts/Work Instruction - Importing Unaccompanied Child Travel Request into " + systemConfig.docCreationSystemName + ".docx"} target="_blank">
                            <Button  icon="help" hint="Work Instruction" text="Work Instruction" />
                        </Link>
                        &nbsp;
                        {(this.IsReadOnly())
                            ? <Button disabled={true} icon="xlsfile" hint="Bulk Upload Template" text="Bulk Upload Template" />
                            : <Link to="/Artifacts/MVM Travel Request.xlsx" target="_blank"><Button icon="xlsfile" hint="Bulk Upload Template" text="Bulk Upload Template" /></Link>
                        }
                    </div>
                </div>

                <br />

                <div className="d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        Status:{' '}&nbsp;
                        <CheckBox className="p-2" value={this.state.filter.notDispatched} id="notDispatched" text="Not Dispatched" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filter.dispatched} id="dispatched" text="Dispatched" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filter.addedOnSupport} id="addedOnSupport" text="Added on Support" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filter.cancelled} id="cancelled" text="Permanently Cancelled" onValueChanged={this.handleChange} />&nbsp;&nbsp;&nbsp;
                        <TextBox value={this.state.filter.text} id="text" defaultValue="" width={200} placeholder="Search by A#/Subject ID" onValueChanged={this.handleTextChange} />&nbsp;&nbsp;&nbsp;
                        <Button text="Search" icon="search" onClick={this.search} />
                    </div>

                    <div className="ms-auto p-2 align-items-center">
                        {(this.IsReadOnly())
                            ? <Button disabled={true} icon="plus" text="Add Placement" />
                            : <Link to="/add-placement"><Button icon="plus" text="Add Placement" /></Link>
                        }
                        
                    </div>
                </div>

                <br />

                <DataGrid dataSource={this.placementList}
                    keyExpr="id" ref={ref => this.dataGrid = ref} allowColumnResizing={true}
                    showBorders={true}
                    onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"placementList"} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <FilterRow visible={true} />

                    <Column caption="Name" dataField="placementShortName" />
                    <Column caption="Placement Date" dataField="orrPlacementDate" dataType="datetime" format="MM/dd/yy, HH:mm '(Local)'" />
                    <Column caption="Movement Type" dataField="movementType" />
                    <Column caption="Transport Type" dataField="transportUnit" />
                    <Column caption="Departure City" dataField="departureCity" />
                    <Column caption="Pickup Location" dataField="pickupLocationName" />
                    <Column caption="Destination" dataField="destinationName" />
                    <Column caption="DHS Custody Date" dataField="dhsCustodyDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Subclassification Code" dataField="subclassificationName" />
                    <Column caption="Status" dataField="statusName" />

                    <Column caption="Max Length of Care" cellRender={this.daysInCareRender} allowFiltering={true} allowSorting={true} calculateSortValue={this.daysInCareCalculation} calculateCellValue={this.daysInCareCalculation} />
                    <Column caption="Age Out Risk" cellRender={this.ageOutRiskRender} allowFiltering={true} allowSorting={true} calculateSortValue={this.ageOutRiskCalculation} calculateCellValue={this.ageOutRiskCalculation} />

                    <Column caption="Operations" allowFiltering={false} allowSorting={false} allowResizing={false} dataField="id"

                        cellRender={
                            function (options) {
                                return (<><Link to={`/edit-placement/${options.row.data.id}`}><Button text="Edit" /></Link></>);
                            }
                        } />

                    <MasterDetail enabled={true} autoExpandAll={true} component={PlacementDetail} />
                </DataGrid>
                <br />
            </div>
        );
    }
}

class PlacementDetail extends React.Component {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.dataSource = props.data.row.data.customerList;
        this.placementShortName = props.data.row.data.placementShortName;
    }

    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                <Export enabled={true} fileName={"placementDetail" + this.placementShortName} />
                <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                <Column caption="First Name" dataField="firstName" />
                <Column caption="Middle Name" dataField="middleName" />
                <Column caption="Last Name" dataField="lastName" />
                <Column caption="Gender" dataField="gender" />
                <Column caption="DOB" dataField="dob" dataType="datetime" format="MM/dd/yyyy" />
                <Column caption="Admission Date" dataField="admissionDate" dataType="datetime" format="MM/dd/yyyy" />
                <Column caption="Days Until Age Out" dataField="daysUntilAgeOut" />
            </DataGrid>
        );
    }
}
