
import React from 'react';
import { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise, onExporting, isAccessReadOnly, formatComponentPageName } from '../BaseComponent';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Lookup, Form, Export, FormItem } from 'devextreme-react/data-grid';
import * as $ from 'jquery';

function HotelStayReasonComponent() {

    const customDataSource = new CustomStore({
        key: 'code',
        load: () => {
            return FetchAPIPromise('DictionaryHotelStayReason');
        },
        insert: (values) => {
            return PostAPIPromise('DictionaryHotelStayReason', values);
        },
        update: (key, values) => {
            return PostAPIPromise('DictionaryHotelStayReason', values);
        },
        remove: (key) => {
            return DeleteAPIPromise('DictionaryHotelStayReason/' + key)
                .then((result) => {
                    if (result.status == -2) {
                        alert('Hotel Stay Reason is referenced in the system and cannot be deleted. Consider inactivating.')
                    }
                });
        }
    });

    formatComponentPageName('Hotel Stay Reasons');

    const isReadOnly = isAccessReadOnly();

    const onInitNewRow = (e) => {
        console.log(e);
        // Set active value default to true
        e.data.active = true;
    }

    const onRowUpdating = (options) => {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    return (
        <div className="container">
            <h1>Hotel Stay Reasons</h1>

            <DataGrid dataSource={customDataSource}
                showBorders={true} allowColumnResizing={true}
                onInitNewRow={onInitNewRow} onRowUpdating={onRowUpdating}
                keyExpr="code" onExporting={onExporting}>
                <Export enabled={true} fileName={"hotelStayReasons"} />
                <Editing mode="popup" allowAdding={!isReadOnly} allowUpdating={!isReadOnly} allowDeleting={!isReadOnly} />
               
                <FilterRow visible={true} />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 20]}
                    showInfo={true} />

                <Column caption="Code" dataField="code">
                    <RequiredRule />
                    <StringLengthRule max={20} />
                </Column>
                <Column caption="Name" dataField="name">
                    <RequiredRule />
                    <StringLengthRule max={100} />
                </Column>
                <Column caption="Active" dataType="boolean"
                    dataField="active" />
            </DataGrid>
            <br />
        </div>
    );
}

export default HotelStayReasonComponent;