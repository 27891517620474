
import * as moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from "../BaseComponent";

export class CommandCenterWorkorder extends BaseComponent {
    static propTypes = {
        workorder: PropTypes.object.isRequired,
        customers: PropTypes.array.isRequired
    };

    render() {
        //console.log('Begin Render');

        const colors = {};
        colors["HLG"] = "#8DB4E2";
        colors["ELP"] = "#66FF33";
        colors["PHO"] = "#E36378";
        colors["SNA"] = "#B1A0C7";
        colors["SND"] = "#00B050";

        var workOrderCustomers = this.props.customers.filter(item => item.workOrderID == this.props.workorder.id);

        //const uniquePickupDropoffs = [...new Map(workOrderCustomers.map(item =>
        //    [item['pickupLocationId'], item['facilityId'], item])).values()];

        //console.log(uniquePickupDropoffs);

        //uniquePickupDropoffs.map((pickup) => {
        //    console.log(pickup);

        //    console.log(workOrderCustomers.filter((customer) => customer.pickupLocationId == pickup.pickupLocationId).length);
        //});

        var uniquePickupDropoffs = workOrderCustomers.reduce(function (memo, e1) {
            var matches = memo.filter(function (e2) {
                return e1.pickupLocationId == e2.pickupLocationId && e1.facilityId == e2.facilityId
            })
            if (matches.length == 0)
                memo.push(e1)
            return memo;
        }, []);

        //console.log(uniquePickupDropoffs);

        return (
            <div>
                <table>
                    <tr>
                        <th><h4>Work Order #</h4></th>
                        <th><h4><span style={{ color: "red" }}>{this.props.workorder.workOrderNumber}</span></h4></th>
                        <th style={{ textAlign: "right" }}>&nbsp;&nbsp;TA:</th>
                        <th colSpan="3" style={{ textAlign: "left" }}><span style={{ color: "red" }}>{this.props.workorder.createByFullName}</span></th>
                        <th colSpan="2" style={{ textAlign: "right" }}>&nbsp;&nbsp;Transport Specialists:</th>
                        <th colSpan="8" style={{ textAlign: "left" }}><span style={{ color: "red" }}>{this.props.workorder.employeesFullName}</span></th>
                    </tr>
                </table>
                {uniquePickupDropoffs.sort((a, b) => moment(a.planPickupDate).valueOf() - moment(b.planPickupDate).valueOf()).sort((a, b) => moment(a.planRemovalDate).valueOf() - moment(b.planRemovalDate).valueOf()).map((match) =>
                    <>
                        <table>
                            <thead>
                                <tr className="table-bordered" style={{ backgroundColor: colors[this.props.workorder.departureAOR] }}>
                                    <th style={{ width: "180px" }}>{match.pickupLocationName}</th>
                                    <th style={{ width: "250px" }} rowSpan="2">{match.facilityName}</th>
                                    <th rowSpan="2">{this.props.workorder.trafficTypeCode == 'Ground' ? 'Ground' : 'Flight: ****MISSING****'}</th>
                                    <th rowSpan="2">DOB</th>
                                    <th rowSpan="2">Age</th>
                                    <th rowSpan="2">Gender</th>
                                    <th rowSpan="2">Nationality</th>
                                    <th colSpan="2">Placement</th>
                                    <th colSpan="2">Pregnant</th>
                                    <th rowSpan="2">Medication</th>
                                    <th colSpan="2">Date/Time</th>
                                    <th rowSpan="2">Comments</th>
                                </tr>
                                <tr className="table-bordered" style={{ backgroundColor: colors[this.props.workorder.departureAOR] }}>
                                    <th>{match.pickupStatusCode == 'ACTIVE' ? `${moment(match.planPickupDate).format('HHmm')} hrs.` : `N/A`}</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Yes</th>
                                    <th>No</th>
                                    <th>Pick Up</th>
                                    <th>Drop Off</th>
                                </tr>
                            </thead>
                            <tbody className="table-bordered">
                                {this.props.customers.filter(item => item.workOrderID == this.props.workorder.id && item.pickupLocationId == match.pickupLocationId && item.facilityId == match.facilityId).map((customer) => (
                                    <tr>
                                        <td><a href={"/edit-placement/" + customer.customerID}>{customer.comboIDDisplay}</a></td>
                                        <td>{customer.firstName} {customer.middleName}</td>
                                        <td>{customer.lastName}</td>
                                        <td>{moment(customer.dob).format('MM/DD/YYYY')}</td>
                                        <td>{customer.age}</td>
                                        <td>{customer.gender}</td>
                                        <td>{customer.nationality}</td>
                                        <td>{moment(customer.orrPlacementDate).format('MM/DD/YYYY')}</td>
                                        <td>{moment(customer.orrPlacementDate).format('HHmm [(Local)]')}</td>
                                        <td style={{ backgroundColor: customer.gender == 'M' ? 'Grey' : 'White' }}>{customer.gender == 'F' && customer.isPregnant ? "X" : ""}</td>
                                        <td style={{ backgroundColor: customer.gender == 'M' ? 'Grey' : 'White' }}>{customer.gender == 'F' && !customer.isPregnant ? "X" : ""}</td>
                                        <td>{customer.medicationsLogged}</td>
                                        <td>{match.pickupStatusCode == 'ACTIVE' ? (customer.pickupDate ? moment(customer.pickupDate).format('MM/DD/YYYY HH:mm') : "") : 'N/A'}</td>
                                        <td>{match.dropoffStatusCode == 'ACTIVE' ? (customer.dropOffDate ? moment(customer.dropOffDate).format('MM/DD/YYYY HH:mm') : "") : 'N/A'}</td>
                                        <td></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <br />
                    </>
                    )}
            </div>
        );
    }
}

export default CommandCenterWorkorder;
