
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Lookup, Form, FormItem, Export } from 'devextreme-react/data-grid';
import { Item, Label } from 'devextreme-react/form';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import * as $ from 'jquery'
import Button from 'devextreme-react/button';
import { alert } from 'devextreme/ui/dialog';

const facilityDataSource = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('HotelFacility');
    },
    insert: (values) => {
        return PostAPIPromise('HotelFacility', values);
    },
    remove: (key) => {
        return DeleteAPIPromise('HotelFacility/' + key)
            .then((result) => {
                if (result.status == -2) {
                    alert('Hotel is referenced in the system and cannot be deleted. Consider inactivating.')
                }
            });
    },
    update: (key, values) => {
        console.log('Updating', values);
        return PostAPIPromise('HotelFacility', values);
    }
});

export class HotelFacilityContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            cities: []
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return "Hotel Management";
    }

    GetDropDownData = async () => {
        const cityData = await this.FetchAPI('DictionaryCity');

        this.setState({
            cities: cityData
        });
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    render() {

        return (
            <div className="container-fluid">
                <h1>Hotel Management</h1>

                <div className="row">
                    <DataGrid dataSource={facilityDataSource}
                        showBorders={true} allowColumnResizing={true}
                        keyExpr="id" onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating} onExporting={this.onExporting}>
                        <Export enabled={true} fileName={"hotelManagement"} />
                        <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()}>
                            <Form>
                                <Item itemType="group" colCount={1} colSpan={2}>
                                    <Item dataField="name" />
                                    <Item dataField="address1" />
                                    <Item dataField="address2" />
                                    <Item dataField="cityCode" />
                                    <Item dataField="zipCode" />
                                </Item>
                            </Form>
                        </Editing>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />

                        <Column caption="Name" dataField="name">
                            <RequiredRule />
                            <StringLengthRule max={200} />
                        </Column>
                        <Column caption="Address" dataField="address1">
                            <RequiredRule />
                            <StringLengthRule max={100} />
                        </Column>
                        <Column caption="Address 2" dataField="address2">
                            <StringLengthRule max={100} />
                        </Column>
                        <Column caption="City" dataField="cityCode">
                            <RequiredRule />
                            <Lookup allowClearing={true} dataSource={this.state.cities} displayExpr="cityName" valueExpr="cityCode" />
                        </Column>
                        <Column dataField="zipCode">
                            <RequiredRule />
                            <StringLengthRule max={10} />
                        </Column>
                    </DataGrid>
                    <br />
                </div>
            </div>
        );
    }
}