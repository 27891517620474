
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Lookup, Form, Export, FormItem } from 'devextreme-react/data-grid';
import { Item, Label } from 'devextreme-react/form';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import * as $ from 'jquery';
import Button from 'devextreme-react/button';
import { alert } from 'devextreme/ui/dialog';

const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('PickupLocation');
    },
    insert: (values) => {
        return PostAPIPromise('PickupLocation', values);
    },
    update: (key, values) => {
        return PostAPIPromise('PickupLocation', values);
    },
    remove: (key) => {
        return DeleteAPIPromise('PickupLocation/' + key)
            .then((result) => {
                if (result.status == -2) {
                    alert('Location is referenced in the system and cannot be deleted. Consider inactivating.')
                }
            });
    }
});

export class PickupLocationContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.pickupLocationPOCsRender = this.pickupLocationPOCsRender.bind(this);
        this.calculateGeolocationRender = this.calculateGeolocationRender.bind(this);

        this.state = {
            agencies: [],
            cities: [],
            states: []
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return "Pickup Location Management";
    }

    GetDropDownData = async () => {
        const agencyData = await this.FetchAPI('DictionaryAgency');
        const aorData = await this.FetchAPI('DictionaryAOR');
        const cityData = await this.FetchAPI('DictionaryCity');
        const stateData = await this.FetchAPI('DictionaryState');
        const bpSectorData = await this.FetchAPI('DictionaryBPSector');

        this.setState({
            agencies: agencyData,
            aors: aorData,
            cities: cityData,
            states: stateData,
            bpSectors: bpSectorData
        });
    }

    onInitNewRow(e) {
        // Set active value default to true
        e.data.isActive = true;

        e.data.pickupLocationPOCs = [];
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    onSaved = (cell, e) => {
        // TODO: Maybe make this an actual value
        //console.log('leave hours saved', cell);
        //console.log('leave hours saved', e);
        cell.setValue(cell.data.pickupLocationPOCs);
    }

    onRowValidating = (e) => {
        console.log('validating', e);

        var email = ('email' in e.newData ? e.newData.email : (e.oldData ? e.oldData.email : null));
        var phone1 = ('phone1' in e.newData ? e.newData.phone1 : (e.oldData ? e.oldData.phone1 : null));
        var phone2 = ('phone2' in e.newData ? e.newData.phone2 : (e.oldData ? e.oldData.phone2 : null));

        console.log(email);
        console.log(!email);
        console.log(email == '');

        if ((!email || email == '') && (!phone1 || phone1 == '') && (!phone2 || phone2 == '')) {
            e.isValid = false;
            e.errorText = 'At least one contact method is required';
        }
    }

    calculateGeolocationRender(cell) {
        let onSavedSecond = this.onSavedSecond.bind(this, cell);

        return <Button text="Calculate Latitude/Longitude" onClick={onSavedSecond} />
    }

    setGeoLocationValues(rowData, value, currentRowData) {
        //console.log('Incoming New Value', value);

        var parts = value.split(',')

        rowData.latitude = parts[0];
        rowData.longitude = parts[1];
        rowData.timeZone = parts[2];
    }

    onSavedSecond = async (cell, e) => {
        // TODO: Maybe make this an actual value
        console.log('Calculating geolocation', cell);

        // Check for full address information
        if (!cell.row.data.address1 || !cell.row.data.city || !cell.row.data.state || !cell.row.data.zipCode) {
            alert('Please provide Address, City, State, and Zip Code');
        }
        else {
            var fullAddress = cell.row.data.address1 + ' ' + cell.row.data.city + ' ' + cell.row.data.state + ' ' + cell.row.data.zipCode;

            var response = await this.FetchAPI('Placement/GetGeolocationCoordinates?address=' + fullAddress);
            if (response.status = 1) {
                // Populate results
                cell.setValue(response.latitude + ',' + response.longitude + ',' + response.timeZone);
            }
            else {
                alert(response.message);
            }
        }
    }

    pickupLocationPOCsRender(cell) {
        //console.log('POCs render', cell);
        let onSaved = this.onSaved.bind(this, cell);

        return <DataGrid dataSource={cell.data.pickupLocationPOCs} onSaved={onSaved}
            keyExpr="id" showBorders={true} allowColumnResizing={true} onRowValidating={this.onRowValidating} onExporting={this.onExporting}>
            <Export enabled={true} fileName={"pickupLocationPOCs"} />
            <Editing mode="popup" allowAdding={true} allowUpdating={true} allowDeleting={true} />
            <Column dataField="name">
                <RequiredRule />
            </Column>
            <Column dataField="email" />
            <Column dataField="phone1" />
            <Column dataField="phone2" />
        </DataGrid>;
    }

    render() {

        return (
            <div className="container">
                <h1>Pickup Location Management</h1>

                <div className="row">
                    <DataGrid dataSource={customDataSource}
                        showBorders={true} allowColumnResizing={true}
                        keyExpr="id" onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating} onExporting={this.onExporting}>
                        <Export enabled={true} fileName={"pickupLocationManagement"} />
                        <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()}>
                            <Form colCount={2}>
                                <Item itemType="group" colSpan={2} colCount={2}>
                                    <Item dataField="name" />
                                    <Item dataField="agencyCode" />

                                    <Item dataField="departureCityCode" />
                                    <Item dataField="address1" />

                                    <Item dataField="address2" />
                                    <Item dataField="city" />

                                    <Item dataField="state" />
                                    <Item dataField="zipCode" />

                                    <Item dataField="isActive" />
                                    <Item dataField="aorCode" />

                                    <Item dataField="code" />
                                    <Item dataField="bpSectorId" />

                                    <Item dataField="latitude" editorOptions={{ readOnly: true }} />
                                    <Item dataField="longitude" editorOptions={{ readOnly: true }} />
                                    <Item dataField="geoLocationCalculate" />

                                    <Item dataField="timeZone" editorOptions={{ readOnly: true }} />
                                </Item>

                                <Item colSpan={2} dataField="pickupLocationPOCs" />
                            </Form>
                        </Editing>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />

                        <Column caption="Name" dataField="name">
                            <RequiredRule />
                            <StringLengthRule max={200} />
                        </Column>
                        <Column
                            caption="Agency"
                            dataField="agencyCode">
                            <RequiredRule />
                            <Lookup allowClearing={true} dataSource={this.state.agencies} displayExpr="name" valueExpr="code" />
                        </Column>
                        <Column
                            caption="Departure City"
                            dataField="departureCityCode">
                            <RequiredRule />
                            <Lookup allowClearing={true} dataSource={this.state.cities} displayExpr="cityName" valueExpr="cityCode" />
                        </Column>
                        <Column caption="Address 1" dataField="address1">
                            <RequiredRule />
                            <StringLengthRule max={100} />
                        </Column>
                        <Column caption="Address 2" dataField="address2">
                            <StringLengthRule max={100} />
                        </Column>
                        <Column caption="City" dataField="city">
                            <RequiredRule />
                            <StringLengthRule max={50} />
                        </Column>
                        <Column
                            caption="State"
                            dataField="state">
                            <RequiredRule />
                            <Lookup allowClearing={true} dataSource={this.state.states} displayExpr="name" valueExpr="name" />
                        </Column>
                        <Column caption="Zip Code" dataField="zipCode">
                            <RequiredRule />
                            <StringLengthRule max={10} />
                        </Column>
                        <Column caption="Active"
                            dataField="isActive" />
                        <Column caption="AOR" dataField="aorCode">
                            <RequiredRule />
                            <Lookup allowClearing={true} dataSource={this.state.aors} displayExpr="aorCode" valueExpr="aorCode" />
                        </Column>
                        <Column caption="Code" dataField="code">
                            <StringLengthRule max={10} />
                        </Column>
                        <Column caption="BP Sector" dataField="bpSectorId">
                            <Lookup allowClearing={true} dataSource={this.state.bpSectors} displayExpr="sectorName" valueExpr="sectorId" />
                        </Column>

                        <Column caption="Latitude" dataField="latitude">
                            <RequiredRule />
                        </Column>
                        <Column caption="Longitude" dataField="longitude">
                            <RequiredRule />
                        </Column>

                        <Column caption="Time Zone" dataField="timeZone">
                            <RequiredRule />
                        </Column>

                        <Column dataField="geoLocationCalculate" editCellRender={this.calculateGeolocationRender} visible={false}
                            setCellValue={this.setGeoLocationValues}>
                            <FormItem>
                                <Label visible={false} />
                            </FormItem>
                        </Column>

                        <Column caption="POCs" visible={false} dataField="pickupLocationPOCs" editCellRender={this.pickupLocationPOCsRender} />
                    </DataGrid>
                    <br />
                </div>
            </div>
        );
    }
}