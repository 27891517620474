
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from 'devextreme-react/button';
import * as moment from 'moment';
import BaseComponent from '../BaseComponent';
import Form, { Item as FormItem, Label, RequiredRule, StringLengthRule, EmptyItem } from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import Gantt, { Tasks, Column, Editing, Toolbar, Item, Validation, FilterRow } from 'devextreme-react/gantt';
import ArrayStore from 'devextreme/data/array_store';
import { LoadPanel } from 'devextreme-react/load-panel';
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel';
import Scheduler, { Resource } from 'devextreme-react/scheduler';
import ScrollView from 'devextreme-react/scroll-view';
import Sortable from 'devextreme-react/sortable';
import { alert, confirm } from 'devextreme/ui/dialog';

export class ManagerDashboardContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            offices: [],
            loading: false,

            reserveDays: [],

            isAddingStandbyShift: false,
            standbyShiftDetails: {},
            eligibleEmployees: [],

            isViewingRoster: false,
            rosterData: {},

            filters: {
                fromDate: null,
                toDate: '',
                fromDateRange: '',
                toDateRange: '',
                officeId: -1,
                minDate: '',
                maxDate: ''
            },

            staffingLevels: []
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Manager Dashboard");
    }

    fieldDataChanged = async (e) => {
        //console.log('Field Data changed', e);
        //console.log(this.state.filters);

        if (e.dataField == 'fromDate') {
            //console.log(e);

            var filters = this.state.filters;

            var fromDate = moment(e.value, "YYYY/MM/DD HH:mm");
            var toDate = fromDate.clone().endOf('week');

            filters.fromDate = fromDate.format("MM/DD/YYYY HH:mm");
            filters.toDate = toDate.format("MM/DD/YYYY HH:mm");

            filters.fromDateRange = fromDate.clone().add(-1, 'day').toDate();
            filters.toDateRange = toDate.clone().add(1, 'day').toDate();

            var min = fromDate.clone().startOf('month');
            var max = min.clone().endOf('month');

            filters.min = min.format("MM/DD/YYYY");
            filters.max = max.format("MM/DD/YYYY");

            await this.setState({
                filters: filters
            });
        }

        if (e.dataField == 'officeId') {
            var employeeData = await this.FetchAPI('Employee/GetTSEmployeesByOffice?officeId=' + (this.state.filters.officeId ? this.state.filters.officeId : -1));

            await this.setState({
                eligibleEmployees: employeeData
            });
        }

        if (this.filterForm != null) {
            this.filterForm.instance().repaint();
        }

        //console.log(this.state.filters.fromDate);

        var queryString = 'periodStartDate=' + this.state.filters.fromDate;
        queryString += '&periodEndDate=' + this.state.filters.toDate;
        queryString += '&officeId=' + (this.state.filters.officeId ? this.state.filters.officeId : -1);

        //console.log(queryString);

        var staffingLevelData = await this.FetchAPI('Schedule/GetStaffingLevels?' + queryString)

        //console.log(staffingLevelData);

        var staffingLevels = this.calculateStaffingLevels(staffingLevelData);

        var standbyScheduleData = await this.FetchAPI('Schedule/GetStandbySchedules?' + queryString);

        //console.log(standbyScheduleData);

        var reserveScheduleDays = [];

        var from_date = this.customMoment(this.state.filters.fromDate);

        for (var i = 0; i < 7; i++) {
            var currentData = from_date.clone().add(i, 'days');

            var reserveDay = {
                display: currentData.format('MM/DD/YYYY'),
                date: currentData,
                schedules: standbyScheduleData.filter((task) => moment(task.planStartDate).get('date') === currentData.get('date'))
            };

            reserveScheduleDays.push(reserveDay);
        }

        //console.log('Reserve Days', reserveScheduleDays);

        this.setState({
            staffingLevels: staffingLevels,
            reserveDays: reserveScheduleDays
        });
    }

    GetData = async () => {

        const confirmLoginData = await this.FetchAPI('UserConfirmLogin');
        const employeeData = await this.FetchAPI('Employee/' + confirmLoginData.employee.employeeId);

        //console.log('Current Employee', employeeData);

        var defaultOfficeId = employeeData.schedulingOfficeId ? employeeData.schedulingOfficeId : employeeData.officeId;

        //console.log('Original default office id', defaultOfficeId);

        // TODO: Test this
        defaultOfficeId = this.state.offices.find(element => element.id == defaultOfficeId) ? defaultOfficeId : this.state.offices[0].id;

        //console.log('Altered default office id', defaultOfficeId);

        var today = moment();
        var from_date = today.clone().startOf('week');
        var to_date = today.clone().endOf('week');

        var fromDateRange = from_date.clone().add(-1, 'day');
        var toDateRange = to_date.clone().add(1, 'day');

        //min = "2/1/2022" max = "2/28/2022"

        var min = from_date.clone().startOf('month');
        var max = min.clone().endOf('month');

        await this.setState({
            //isCurrentPayPeriodLocked: result.length > 0,
            //lockedPeriods: result,
            //lockedDays: lockedDays,
            //canEditPayrollLocking: isUserPayrollUser,

            filters: {
                fromDate: from_date.format("MM/DD/YYYY HH:mm"),
                toDate: to_date.format("MM/DD/YYYY HH:mm"),
                fromDateRange: fromDateRange.toDate(),
                toDateRange: toDateRange.toDate(),
                officeId: defaultOfficeId,
                min: min.format("MM/DD/YYYY"),
                max: max.format("MM/DD/YYYY")
            }
        });

        var eligibleEmployeeData = await this.FetchAPI('Employee/GetTSEmployeesByOffice?officeId=' + (this.state.filters.officeId ? this.state.filters.officeId : -1));

        var queryString = 'periodStartDate=' + this.state.filters.fromDate;
        queryString += '&periodEndDate=' + this.state.filters.toDate;
        queryString += '&officeId=' + (this.state.filters.officeId ? this.state.filters.officeId : -1);

        var staffingLevelData = await this.FetchAPI('Schedule/GetStaffingLevels?' + queryString)

        // console.log(staffingLevelData);
        
        var staffingLevels = this.calculateStaffingLevels(staffingLevelData);

        var standbyScheduleData = await this.FetchAPI('Schedule/GetStandbySchedules?' + queryString);

        //console.log(standbyScheduleData);

        var reserveScheduleDays = [];

        for (var i = 0; i < 7; i++) {
            var currentData = from_date.clone().add(i, 'days');

            var reserveDay = {
                display: currentData.format('MM/DD/YYYY'),
                date: currentData,
                schedules: standbyScheduleData.filter((task) => moment(task.planStartDate).get('date') === currentData.get('date'))
            };

            reserveScheduleDays.push(reserveDay);
        }

        //console.log(reserveScheduleDays);

        this.setState({
            staffingLevels: staffingLevels,
            reserveDays: reserveScheduleDays,
            eligibleEmployees: eligibleEmployeeData
        });
    }

    calculateStaffingLevels(staffingLevelData) {
        // Basically, loop through every day in the month, and find who is available, unavailable, and on leave
        // Find the beginning and end of the month
        var monthBegin = moment(this.state.filters.beginDate).startOf('month');
        var monthEnd = monthBegin.clone().endOf('month');

        //console.log('Beginning', monthBegin.format("MM/DD/YYYY"));
        //console.log('End', monthEnd.format("MM/DD/YYYY"));

        var staffingLevelDatapoints = [];

        while (monthBegin.isSameOrBefore(monthEnd)) {
            //console.log('Current Day', monthBegin);

            var availabilityCount = 0;
            var unavailabilityCount = 0;
            var leaveCount = 0;

            // Cycle through every employee in the list
            for (var i = 0; i < staffingLevelData.length; i++)
            {
                var employee = staffingLevelData[i];

                //if (employee.employeeNo == '61174') {
                //    console.log(employee);
                //}

                var hasAvailability = false;
                var hasLeave = false;

                // Do they have availability?
                // TODO: Take into account whether the availability begins or ends in the date range
                var availabilityEntry = employee.availability.find(item => (item.weekDayId - 1) == monthBegin.day());

                //console.log('Availability for this day', availabilityEntry);

                if (!availabilityEntry) {
                    hasAvailability = false;
                }
                else {
                    if (availabilityEntry.m1 || availabilityEntry.m2 || availabilityEntry.e1 || availabilityEntry.e2) {
                        hasAvailability = true;
                    }
                    else {
                        hasAvailability = false;
                    }
                }

                var leaveEntry = employee.leaveRequests.find(item => moment(item.planStartDate).isSameOrBefore(monthBegin) && moment(item.planEndDate).isSameOrAfter(monthBegin));

                if (leaveEntry) {
                    hasLeave = true;
                }
                else {
                    hasLeave = false;
                }

                if (hasLeave) {
                    leaveCount++;
                }
                else {
                    if (hasAvailability) {
                        availabilityCount++;
                    }
                    else {
                        unavailabilityCount++;
                    }
                }
            }

            staffingLevelDatapoints.push({
                text: 'CFPCS Availability - ' + availabilityCount,
                startDate: monthBegin.format("MM/DD/YYYY"),
                endDate: monthBegin.format("MM/DD/YYYY")
            });

            staffingLevelDatapoints.push({
                text: 'CFPCS Unavailability - ' + unavailabilityCount,
                startDate: monthBegin.format("MM/DD/YYYY"),
                endDate: monthBegin.format("MM/DD/YYYY")
            });

            staffingLevelDatapoints.push({
                text: 'CFPCS On Leave - ' + leaveCount,
                startDate: monthBegin.format("MM/DD/YYYY"),
                endDate: monthBegin.format("MM/DD/YYYY")
            });

            monthBegin.add(1, 'day');
        }

        return staffingLevelDatapoints;
    }

    GetDropDownData = async () => {
        var officeData = await this.FetchAPI('Office');

        // Filter on active and scheduling office
        officeData = officeData.filter((item) => item.isActive && item.isSchedulingOffice);

        //console.log(officeData);
        
        await this.setState({
            offices: officeData
        });
    }

    isDisabledDate = (args) => {
        //console.log(args);

        if (args.view === "month") {
            return args.date.getDay() > 0;
        }

        return false;
    }

    isStandbyDisabledDate = (args) => {
        var momentDate = moment(args.date);

        return momentDate.isBefore(moment(this.state.filters.fromDate)) || momentDate.isAfter(moment(this.state.filters.toDate));
    }

    hideRoster = (e) => {
        this.setState({
            isViewingRoster: false,
            rosterData: {},
        });
    }

    viewRoster = async (e) => {

        await this.setState({
            isViewingRoster: true,
            loading: true
        });

        var officeId = this.state.filters.officeId == null ? -1 : this.state.filters.officeId;

        var shiftData = await this.FetchAPI('Schedule/GetEmployeeRoster?officeId=' + officeId + '&periodStartDate=' + this.state.filters.fromDate + '&periodEndDate=' + this.state.filters.toDate);

        var processedShiftData = this.processInitialShiftData(shiftData);

        await this.setState({
            loading: false,
            rosterData: processedShiftData
        });

        //console.log(this.projectGantt.instance);
        if (this.projectGantt.instance()) {
            this.projectGantt.instance().repaint();
            this.projectGantt.instance().refresh();
            this.projectGantt.instance().updateDimensions();
        }

        //this.contactLogDataGrid.instance().refresh();
        //this.projectGantt.instance().refresh();
    }

    shiftDataSource = new ArrayStore({
        key: 'id'
        // Other ArrayStore options go here
    });

    consolidateShiftAvailability(availabilityList) {
        var availableShifts = [];

        //this.state.weeks.map((weekItem, weekIndex) => {
            //var weekBegin = moment(weekItem.fromDate);
        //var weekEnd = moment(weekItem.toDate);
        var weekBegin = this.customMoment(this.state.filters.fromDate);
        var weekEnd = this.customMoment(this.state.filters.toDate);

            // What we need to do here is to Include/Consolidate availability that either
            // 1) BeginDate and EndDate are within the given week, OR
            // 2) BeginDate is before or on the beginning of the week and end date is on or after the end date or is null
            var localAvailabilityList = availabilityList.filter((item) => /* ONEOFF item */(moment(item.beginDate).isSameOrAfter(weekBegin) && moment(item.endDate).isSameOrBefore(weekEnd)) || (moment(item.beginDate).isSameOrBefore(weekBegin) && (moment(item.endDate).isSameOrAfter(weekEnd) || !item.endDate)));

            //console.log(localAvailabilityList);

            for (var i = 0; i < localAvailabilityList.length; i++) {
                var dayAvailability = localAvailabilityList[i];
                var adjustedWeekDayIndex = dayAvailability.weekDayId - 1;

                if (dayAvailability.isAvailable) {
                    var shiftBegin = weekBegin.clone();
                    shiftBegin.add(adjustedWeekDayIndex, 'days');

                    var shiftEnd = weekBegin.clone();
                    shiftEnd.add(adjustedWeekDayIndex + 1, 'days');

                    availableShifts.push({ beginDate: shiftBegin, endDate: shiftEnd });
                }
                else {
                    // Partial Availability
                    // TODO: Try to consolidate some here?
                    if (dayAvailability.m1) {
                        var shiftBegin = weekBegin.clone();
                        shiftBegin.add(adjustedWeekDayIndex, 'days');

                        var shiftEnd = weekBegin.clone();
                        shiftEnd.add(adjustedWeekDayIndex, 'days');
                        shiftEnd.add(6, 'hours');

                        availableShifts.push({ beginDate: shiftBegin, endDate: shiftEnd });
                    }

                    if (dayAvailability.m2) {
                        var shiftBegin = weekBegin.clone();
                        shiftBegin.add(adjustedWeekDayIndex, 'days');
                        shiftBegin.add(6, 'hours');

                        var shiftEnd = weekBegin.clone();
                        shiftEnd.add(adjustedWeekDayIndex, 'days');
                        shiftEnd.add(12, 'hours');

                        availableShifts.push({ beginDate: shiftBegin, endDate: shiftEnd });
                    }

                    if (dayAvailability.e1) {
                        var shiftBegin = weekBegin.clone();
                        shiftBegin.add(adjustedWeekDayIndex, 'days');
                        shiftBegin.add(12, 'hours');

                        var shiftEnd = weekBegin.clone();
                        shiftEnd.add(adjustedWeekDayIndex, 'days');
                        shiftEnd.add(18, 'hours');

                        availableShifts.push({ beginDate: shiftBegin, endDate: shiftEnd });
                    }

                    if (dayAvailability.e2) {
                        var shiftBegin = weekBegin.clone();
                        shiftBegin.add(adjustedWeekDayIndex, 'days');
                        shiftBegin.add(18, 'hours');

                        var shiftEnd = weekBegin.clone();
                        shiftEnd.add(adjustedWeekDayIndex, 'days');
                        shiftEnd.add(24, 'hours');

                        availableShifts.push({ beginDate: shiftBegin, endDate: shiftEnd });
                    }
                }
            }
        //});

        // Consolidate
        var consolidatedShifts = [];

        availableShifts.sort((a, b) => a.valueOf() - b.valueOf()).map((availableShift, availableIndex) => {
            if (availableIndex == 0) {
                consolidatedShifts.push(availableShift);
            }
            else {
                if (availableShift.beginDate.isSame(consolidatedShifts[consolidatedShifts.length - 1].endDate)) {
                    consolidatedShifts[consolidatedShifts.length - 1].endDate = availableShift.endDate;
                }
                else {
                    consolidatedShifts.push(availableShift);
                }
            }
        });

        return consolidatedShifts;
    }

    processInitialShiftData(shiftData) {
        this.shiftDataSource.clear();

        var allPeriodData = []

        //console.log(shiftData);

        var currentPeriodBeginMoment = this.customMoment(this.state.filters.fromDate);
        var currentPeriodEndMoment = this.customMoment(this.state.filters.toDate);

        shiftData.map((data, dataIndex) => {
            var localConsolidatedData = this.consolidateShiftAvailability(data.availability);

            //console.log(localConsolidatedData);

            localConsolidatedData.sort((a, b) => a.beginDate.valueOf() - b.beginDate.valueOf()).map((localItem, localIndex) => {
                allPeriodData.push({
                    id: uuidv4(),
                    employeeId: data.employeeId,
                    planStartDate: localItem.beginDate.toString(),
                    planEndDate: localItem.endDate.toString(),
                    parentId: data.employeeId,
                    display: 'Availability',
                    workOrderId: null,
                    color: "pink"
                })
            });

            data.leaveRequests.map((request, index) => {
                allPeriodData.push({
                    id: request.id,
                    employeeId: request.employeeId,
                    workOrderId: request.workOrderID,
                    planStartDate: request.planStartDate,
                    planEndDate: request.planEndDate,
                    parentId: request.employeeId,
                    display: request.display,
                    color: "orange"
                });
            });

            if (data.scheduledShifts.length > 0) {
                var scheduledTotal = 0;

                data.scheduledShifts.map((shift, shiftIndex) => {
                    var shiftStartMoment = moment(shift.planStartDate);
                    var shiftEndMoment = moment(shift.planEndDate);

                    if ((shiftStartMoment.isSameOrAfter(currentPeriodBeginMoment) && shiftStartMoment.isSameOrBefore(currentPeriodEndMoment))
                        ||
                        (shiftEndMoment.isSameOrAfter(currentPeriodBeginMoment) && shiftEndMoment.isSameOrBefore(currentPeriodEndMoment))
                        ||
                        (shiftStartMoment.isSameOrBefore(currentPeriodBeginMoment) && shiftEndMoment.isSameOrAfter(currentPeriodEndMoment))) {

                        var begin = shiftStartMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : shiftStartMoment;
                        var end = shiftEndMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : shiftEndMoment;

                        //console.log(shift);
                        scheduledTotal += moment(end).diff(moment(begin), 'minute') / 60;
                    }
                });

                data.currentScheduledHours = scheduledTotal.toFixed(2);

                //console.log(data);
            }
            else {
                data.currentScheduledHours = null;
            }

            data.scheduledShifts.map((shift, index) => {

                var shiftStartDate = moment(shift.planStartDate);
                var shiftEndDate = moment(shift.planEndDate);

                var begin = shiftStartDate.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : shiftStartDate;
                var end = shiftEndDate.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : shiftEndDate;

                var shiftHours = (moment(end).diff(moment(begin), 'minute') / 60);

                if (shift.scheduleType == 'DEDICATED' && shift.workOrderList.length > 0) {
                    //console.log('Dedicated shift', shift);

                    shift.display = shift.workOrderList[0].display;
                    shift.workOrderID = shift.workOrderList[0].workOrderID;

                    shift.color = shift.workOrderList[0].workOrderID == this.state.workOrderId ? '#3cbab2' : 'red';

                    shift.currentScheduledHours = shiftHours.toFixed(2);

                    allPeriodData.push(shift);
                }
                else {
                    //console.log('Standy shifts');

                    shift.display = 'Standby Shift';
                    shift.color = '#6c76bf';

                    shift.currentScheduledHours = shiftHours.toFixed(2);

                    allPeriodData.push(shift);

                    // Add all assigned work orders
                    shift.workOrderList.map((workOrder) => {
                        //console.log('Shift', workOrder);

                        workOrder.color = 'pink';

                        allPeriodData.push(workOrder);
                    });
                }
            });

            allPeriodData.push({
                id: data.employeeId,
                currentScheduledHours: data.currentScheduledHours,
                employeeId: data.employeeId,
                planStartDate: this.state.filters.fromDate,
                planEndDate: this.state.filters.toDate,
                parentId: "0",
                display: data.lastName + ', ' + data.firstName + ' (' + data.employeeNo + ')',
                //workOrderId: this.state.workOrder.id,
                color: "#78b6d9"
            });
        });

        return allPeriodData;
    }

    onShown = (e) => {
        //console.log(this.projectGantt.instance);
        if (this.projectGantt.instance()) {
            this.projectGantt.instance().repaint();
            this.projectGantt.instance().refresh();
            this.projectGantt.instance().updateDimensions();
        }
    }

    onTaskEditDialogShowing = (e) => {
        //console.log('Canceling');
        e.cancel = true;
    }

    addStandbyShift = async (e) => {
        //console.log(this.state.standbyShiftDetails);

        this.setState({
            isAddingStandbyShift: true,
            //HistoryHeader: HistoryHeader,
            //customerHistory: changeHistory
            //loading: false
        });
    }

    hideAddShiftPopup = async () => {
        await this.setState({
            isAddingStandbyShift: false,
            standbyShiftDetails: {}
        })
    }

    saveStandbyShift = async () => {
        var result = this.editFormControl.instance().validate();

        if (result.isValid) {

            await this.setState({
                loading: true
            });

            var standbyShiftDetails = this.state.standbyShiftDetails;

            //console.log('shift details', standbyShiftDetails);

            if (moment(standbyShiftDetails.planStartDate).isAfter(moment(standbyShiftDetails.planEndDate))) {
                alert('End Date cannot precede Start Date');
            }
            else {
                const result = await this.PostAPI('Schedule/SaveStandbyShift', standbyShiftDetails);

                //console.log(result);

                if (result.status == 1) {
                    await this.setState({
                        isAddingStandbyShift: false,
                        standbyShiftDetails: {}
                    });

                    var queryString = 'periodStartDate=' + this.state.filters.fromDate;
                    queryString += '&periodEndDate=' + this.state.filters.toDate;
                    queryString += '&officeId=' + (this.state.filters.officeId ? this.state.filters.officeId : -1);

                    //console.log(queryString);

                    var standbyScheduleData = await this.FetchAPI('Schedule/GetStandbySchedules?' + queryString);

                    //console.log(standbyScheduleData);

                    var reserveScheduleDays = [];

                    var from_date = moment(this.state.filters.fromDate);

                    for (var i = 0; i < 7; i++) {
                        var currentData = from_date.clone().add(i, 'days');

                        var reserveDay = {
                            display: currentData.format('MM/DD/YYYY'),
                            date: currentData,
                            schedules: standbyScheduleData.filter((task) => moment(task.planStartDate).get('date') === currentData.get('date'))
                        };

                        reserveScheduleDays.push(reserveDay);
                    }

                    //console.log('Reserve Days', reserveScheduleDays);

                    this.setState({
                        reserveDays: reserveScheduleDays
                    });
                }
                else if (result.status == -1) {
                    alert(result.message);
                }
                else {
                    //console.log(result);
                    alert('Failed. Please try again later.');
                }
            }

            await this.setState({
                loading: false
            });
        }
    }

    onListReorder(e) {
        this.setState({
            lists: this.reorder(this.state.lists, this.state.lists[e.fromIndex], e.fromIndex, e.toIndex),
            statuses: this.reorder(
                this.state.statuses,
                this.state.statuses[e.fromIndex],
                e.fromIndex,
                e.toIndex,
            ),
        });
    }

    onTaskDragStart(e) {
        e.itemData = this.state.lists[e.fromData][e.fromIndex];
    }

    onTaskDrop(e) {
        this.updateTask(e.fromData, e.itemData, e.fromIndex, -1);
        this.updateTask(e.toData, e.itemData, -1, e.toIndex);
    }

    reorder(items, item, fromIndex, toIndex) {
        let result = items;
        if (fromIndex >= 0) {
            result = [...items.slice(0, fromIndex), ...items.slice(fromIndex + 1)];
        }

        if (toIndex >= 0) {
            result = [...items.slice(0, toIndex), item, ...items.slice(toIndex)];
        }

        return result;
    }

    updateTask(listIndex, itemData, fromIndex, toIndex) {
        const lists = this.state.lists.slice();

        lists[listIndex] = this.reorder(lists[listIndex], itemData, fromIndex, toIndex);

        this.setState({
            lists,
        });
    }

    standbyShiftClick = (e, id) => {
        e.preventDefault();

        // console.log(id);
        // console.log(this.state.reserveDays);
        var standbyShiftEntry = this.state.reserveDays.find(item => item.schedules.findIndex((schedule) => schedule.id == id) > -1);

        standbyShiftEntry = standbyShiftEntry.schedules.find((item) => item.id == id);

        // console.log('found entry', standbyShiftEntry);
        this.setState({
            isAddingStandbyShift: true,
            standbyShiftDetails: standbyShiftEntry
        });
    }

    deleteShift = async (e, id) => {
        // console.log(id);
        // console.log(this.state.reserveDays);
        e.preventDefault();

        var dialogResult = await confirm('Are you sure you want to delete this Standby shift?');

        if (dialogResult) {
            const result = await this.DeleteAPI('Schedule/DeleteStandbyShift/' + id);

            if (result.status == 1) {
                var currentShifts = this.state.reserveDays;

                var standbyShiftEntry = currentShifts.findIndex(item => item.schedules.findIndex((schedule) => schedule.id == id) > -1);

                var deleteIndex = currentShifts[standbyShiftEntry].schedules.findIndex((item) => item.id == id);

                currentShifts[standbyShiftEntry].schedules.splice(deleteIndex, 1);

                // console.log('found entry', standbyShiftEntry);
                this.setState({
                    reserveDays: currentShifts
                });
            }
            else if (result.status == -1) {
                alert(result.message);
            }
            else {
                //console.log(result);
                alert('Failed. Please reload and try again later.');
            }
        }
    }

    render() {
        const views = ['month'];

        return (
            <div className="container">
                <h1>Manager Dashboard</h1>
                <br />

                <Form ref={ref => this.filterForm = ref}
                    formData={this.state.filters} colCount={2} onFieldDataChanged={this.fieldDataChanged}>
                    <FormItem editorType="dxDateBox" dataField="fromDate" editorOptions={{ disabledDates: this.isDisabledDate }}>
                        <Label text="Week Begin" />
                    </FormItem>

                    <FormItem editorType="dxDateBox" dataField="toDate" editorOptions={{ readOnly: true }}>
                        <Label text="End" />
                    </FormItem>

                    <EmptyItem />

                    <FormItem dataField="officeId" editorType="dxSelectBox" editorOptions={{ dataSource: this.state.offices, displayExpr: 'name', valueExpr: 'id', searchEnabled: true, showClearButton: true }}>
                        <Label text="Office" />
                    </FormItem>
                </Form>
                <br />

                <div className="d-flex justify-content-end">
                    <div className="ms-auto">
                        <Button text="View Roster" onClick={this.viewRoster} />
                    </div>
                </div>

                <br />
                <TabPanel>
                    <TabItem title="Staffing Levels">
                        <div style={{ margin: "10px" }}>

                            <Scheduler
                                views={views}
                                dataSource={this.state.staffingLevels}
                                height={700}
                                defaultCurrentView="month"
                                currentDate={this.state.filters.fromDate}
                                min={this.state.filters.min}
                                max={this.state.filters.max}
                                />
                        </div>
                    </TabItem>
                    <TabItem title="Standby Roster">
                        <div style={{ margin: "10px" }}>
                            <Button disabled={this.IsReadOnly()} icon="plus" text="Add Shift" onClick={this.addStandbyShift} />
                            <br />
                            <div id="kanban">
                                <ScrollView
                                    className="scrollable-board"
                                    direction="horizontal"
                                    showScrollbar="always">
                                    <Sortable
                                        allowReordering={false}
                                        allowDropInsideItem={false}
                                        className="sortable-lists"
                                        itemOrientation="horizontal"
                                        handle=".list-title"
                                        onReorder={this.onListReorder}>
                                        {this.state.reserveDays.map((tasks, listIndex) => {
                                            const status = this.state.reserveDays[listIndex];
                                            //console.log('Current', status);
                                            var key = tasks.display + listIndex;
                                            var displayKey = key + 'display';

                                            return (<div key={key} className="list">
                                                <div key={displayKey} className="list-title dx-theme-text-color">{tasks.display}</div>
                                                <ScrollView
                                                    className="scrollable-list"
                                                    direction="vertical"
                                                    showScrollbar="always">
                                                    <Sortable
                                                        className="sortable-cards"
                                                        group="cardsGroup"
                                                        data={listIndex}
                                                        allowReordering={false}
                                                        allowDropInsideItem={false}
                                                        onDragStart={this.onTaskDragStart}
                                                        onReorder={this.onTaskDrop}
                                                        onAdd={this.onTaskDrop}>
                                                        {tasks.schedules.map((task) =>
                                                            <div key={task.id} className="card dx-card dx-theme-text-color dx-theme-background-color">
                                                                <div key={task.id + 'priority'} className={`card-priority priority-${task.Task_Priority}`}></div>
                                                                <div key={task.id + 'subject'} className="card-subject"><b>{moment(task.planStartDate).format('HH:mm')}</b> - {moment(task.planEndDate).format('MM/DD HH:mm')}</div>
                                                                <div key={task.id + 'description'} className="card-description">{task.description}</div>
                                                                <div key={task.id + 'assignee'} className="card-assignee">{task.display}</div>
                                                                <div key={task.id + 'standby'} className="card-actions">{(this.IsReadOnly()) ? <span>Update</span> : <a href="#" onClick={(e) => this.standbyShiftClick(e, task.id)}>Update</a>}{(this.IsReadOnly()) ? <span>Delete</span> : <a href="#" onClick={(e) => this.deleteShift(e, task.id)}>Delete</a>}</div>
                                                            </div>)}
                                                    </Sortable>
                                                </ScrollView>
                                            </div>);
                                        })}
                                    </Sortable>
                                </ScrollView>
                            </div>
                        </div>
                    </TabItem>
                </TabPanel>
                <br />

                <Popup visible={this.state.isAddingStandbyShift} onHiding={this.hideAddShiftPopup} dragEnabled={false}
                    closeOnOutsideClick={false} showTitle={true} title="New Standby Shift" width={400} height={340}>
                        <Form ref={ref => this.editFormControl = ref}
                            id="form" formData={this.state.standbyShiftDetails} colCount={1}>
                        <FormItem editorType="dxTextBox" dataField="description">
                            <Label text="Name/Description" />
                            <StringLengthRule max={200} />
                            <RequiredRule />
                        </FormItem>

                        <FormItem editorType="dxDateBox" dataField="planStartDate" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm", disabledDates: this.isStandbyDisabledDate, acceptCustomValue: false}}>
                            <Label text="Start" />
                            <RequiredRule />
                        </FormItem>

                        <FormItem editorType="dxDateBox" dataField="planEndDate" editorOptions={{ type: 'datetime', displayFormat: 'MM/dd/yyyy HH:mm', acceptCustomValue: false}}>
                                <Label text="End" />
                                <RequiredRule />
                            </FormItem>

                        <FormItem editorType="dxSelectBox" dataField="employeeId" editorOptions={{ readOnly: this.state.standbyShiftDetails.id != null, dataSource: this.state.eligibleEmployees, displayExpr: 'employeeName', valueExpr: 'employeeId', searchEnabled: true, showClearButton: true }}>
                                <Label text="Employee" />
                                <RequiredRule />
                            </FormItem>
                        </Form>
                        <br />

                        <div className="d-flex justify-content-end">
                        <Button disabled={this.IsReadOnly()} text="Save" onClick={this.saveStandbyShift} />
                        &nbsp;
                        <Button text="Cancel" onClick={this.hideAddShiftPopup} />
                        </div>
                </Popup>

                <Popup visible={this.state.isViewingRoster} onHiding={this.hideRoster} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Employee Roster" width={1300} height={700}
                    onShown={this.onShown}>

                    <Gantt
                        taskListWidth={330}
                        scaleType="days"
                        height={620}
                        onTaskEditDialogShowing={this.onTaskEditDialogShowing}
                        ref={ref => this.projectGantt = ref} showResources={false} startDateRange={this.state.filters.fromDateRange} endDateRange={this.state.filters.toDateRange}>
                        <Validation autoUpdateParentTasks={false} />
                        <Editing enabled={false} />

                        <Toolbar>
                            <Item name="collapseAll" />
                            <Item name="expandAll" />
                        </Toolbar>

                        <Tasks dataSource={this.state.rosterData}
                            keyExpr="id"
                            startExpr="planStartDate" endExpr="planEndDate"
                            titleExpr="display" />

                        <Column dataField="display" caption="Employee" width={250} />
                        <Column dataField="currentScheduledHours" caption="Scheduled" width={80} dataType='number' sortOrder="desc" />

                        <FilterRow visible={true} />
                    </Gantt>
                </Popup>

                <LoadPanel
                    visible={this.state.loading} />
            </div>
        );
    }
}