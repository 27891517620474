
import React from 'react';
import * as moment from 'moment';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import BaseComponent from '../BaseComponent.js';
import CommandCenterWorkorder from './CommandCenterWorkorder';
import DateBox from 'devextreme-react/date-box';
import { CheckBox } from 'devextreme-react/check-box';
import { LoadPanel } from 'devextreme-react/load-panel';

import 'bootstrap';

export class CommandCenterDashboardContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            ucWorkOrders: [],
            ucDRMWorkOrders: [],
            ucDRToPOEWorkOrders: [],
            famuWorkOrders: [],
            famuDRMWorkOrders: [],
            famuATDWorkOrders: [],
            famuDRToPOEWorkOrders: [],
            ucCustomers: [],
            ucDRMCustomers: [],
            ucDRToPOECustomers: [],
            famuCustomers: [],
            famuDRMCustomers: [],
            famuATDCustomers: [],
            famuDRToPOECustomers: [],
            currentDay: '',
            IncludeScheduledNoDate: false,
            loading: false
        };

        this.refreshPageInterval = 40;
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();

        this.interval = setInterval(() => {
            this.fetchData();
        }, this.refreshPageInterval * 1000);
    }

    GetComponentPageName = () => {
        return ("Command Center Dashboard");
    }

    componentWillUnmount = async () => {
        clearInterval(this.interval);
    }

    fetchData = async () => {
        try {
            await this.setState({ loading: true });

            console.log(this.state.currentDay);

            var currentDay = this.state.currentDay ? moment(this.state.currentDay, "YYYY/MM/DD").format("YYYY/MM/DD") : moment().format("YYYY/MM/DD");
            var includeScheduledNoDate = this.state.IncludeScheduledNoDate;

            console.log("Current Day", currentDay);

            var data = await this.FetchAPI('CommandCenterDashboard?day=' + currentDay + '&includeScheduledNoDate=' + includeScheduledNoDate);

            console.log(data);

            await this.setState({
                ucWorkOrders: data.ucWorkOrders,
                ucDRMWorkOrders: data.ucDRMWorkOrders,
                ucDRToPOEWorkOrders: data.ucDRToPOEWorkOrders,
                famuWorkOrders: data.famuWorkOrders,
                famuDRMWorkOrders: data.famuDRMWorkOrders,
                famuATDWorkOrders: data.famuATDWorkOrders,
                famuDRToPOEWorkOrders: data.famuDRToPOEWorkOrders,
                ucCustomers: data.ucCustomers,
                ucDRMCustomers: data.ucDRMCustomers,
                ucDRToPOECustomers: data.ucDRToPOECustomers,
                famuCustomers: data.famuCustomers,
                famuDRMCustomers: data.famuDRMCustomers,
                famuATDCustomers: data.famuATDCustomers,
                famuDRToPOECustomers: data.famuDRToPOECustomers,
                currentDay: currentDay,
                loading: false
            });

            document.title = (this.systemConfig.systemName + ' - ' + this.GetComponentPageName());
        }
        catch (error) {
            console.log(error);
        }

        await this.setState({ loading: false });
    };

    toggleScheduledNoDate = async (event) => {
        //console.log('Changing scheduledNoDate');

        await this.setState(state => { return { IncludeScheduledNoDate: !state.IncludeScheduledNoDate, } });

        await this.fetchData();
    }

    SumUnits(array) {
        var sum = 0;
        array.forEach(function (item) {
            sum += item.familyUnitCount;
        })

        return sum;
    }

    onDateChanged = async (e) => {
        var newData = moment(e.value, "YYYY/MM/DD");

        await this.setState({
            currentDay: newData.format('YYYY/MM/DD')
        });

        await this.fetchData();
    }

    render() {
        //console.log('Begin Render');

        const tabs = [["McAllen", "HLG"], ["El Paso", "ELP"], ["Phoenix", "PHO"], ["San Antonio", "SNA"], ["San Diego", "SND"]];
        const colors = {};
        colors["HLG"] = "#8DB4E2";
        colors["ELP"] = "#66FF33";
        colors["PHO"] = "#E36378";
        colors["SNA"] = "#B1A0C7";
        colors["SND"] = "#00B050";

        //console.log(this.state.ucWorkOrders);

        return (
            <div className="container">
                <div className="row d-flex justify-content-between">
                    <div className="dx-field">
                        <div className="dx-field-label">Schedule For:</div>
                        <div className="dx-field-value">
                            <DateBox type="date" onValueChanged={this.onDateChanged} value={this.state.currentDay} />
                        </div>
                    </div>

                    <div className="ms-auto me-3">
                        <CheckBox text="Include Scheduled With No Pickup/Removal Date?" value={this.state.IncludeScheduledNoDate} onValueChanged={this.toggleScheduledNoDate} />
                    </div>
                </div>

                <br />

                <div className="row">
                    <TabPanel>
                        {tabs.map(([text, value], i) => (
                            <Item title={text}>
                                <div style={{ margin: "10px" }}>
                                    <h3>{text} Transports</h3>

                                    {this.state.ucWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'fl').length > 0 &&
                                        <div>
                                            <h4 style={{ backgroundColor: "#FFFF00", textAlign: "center" }}>Air</h4>

                                            {this.state.ucWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'fl').map((workorder) => (
                                                <CommandCenterWorkorder workorder={workorder} customers={this.state.ucCustomers} />
                                            ))}
                                        </div>
                                    }

                                    {this.state.ucWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'Ground' && item.departureAOR != item.destinationAOR).length > 0 &&
                                        <div>
                                            <h4 style={{ backgroundColor: "#FFFF00", textAlign: "center" }}>Grounds</h4>

                                            {this.state.ucWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'Ground' && item.departureAOR != item.destinationAOR).map((workorder) => (
                                                <CommandCenterWorkorder workorder={workorder} customers={this.state.ucCustomers} />
                                            ))}
                                        </div>
                                    }

                                    {this.state.ucWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'Ground' && item.departureAOR == item.destinationAOR).length > 0 &&
                                        <div>
                                            <h4 style={{ backgroundColor: "#FFFF00", textAlign: "center" }}>Locals</h4>

                                            {this.state.ucWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'Ground' && item.departureAOR == item.destinationAOR).map((workorder) => (
                                                <CommandCenterWorkorder workorder={workorder} customers={this.state.ucCustomers} />
                                            ))}
                                        </div>
                                    }
                                    <br />

                                    <table>
                                        <thead>
                                            <tr>
                                                <th><b>Total UNCs:</b></th>
                                                <th style={{ width: "100px" }}>{this.state.ucCustomers.filter(item => this.state.ucWorkOrders.filter(workorder => workorder.departureAOR == value).find((o) => { return o.id == item.workOrderID })).length}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-bordered">
                                            <tr>
                                                <td>Air</td>
                                                <td>{this.state.ucCustomers.filter(item => this.state.ucWorkOrders.filter(workorder => workorder.departureAOR == value).find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'fl' })).length}</td>
                                            </tr>
                                            <tr>
                                                <td>Grounds</td>
                                                <td>{this.state.ucCustomers.filter(item => this.state.ucWorkOrders.filter(workorder => workorder.departureAOR == value).find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'Ground' && o.departureAOR != o.destinationAOR })).length}</td>
                                            </tr>
                                            <tr>
                                                <td>Locals</td>
                                                <td>{this.state.ucCustomers.filter(item => this.state.ucWorkOrders.filter(workorder => workorder.departureAOR == value).find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'Ground' && o.departureAOR == o.destinationAOR })).length}</td>
                                            </tr>
                                            <tr>
                                                <td>Work Orders</td>
                                                <td>{this.state.ucWorkOrders.filter(workorder => workorder.departureAOR == value).length}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Item>
                        ))}
                        <Item title="Others">
                            <div style={{ margin: "10px" }}>
                                <h3>Other UC Transports</h3>
                                {this.state.ucWorkOrders.filter(item => item.departureAOR != 'HLG' && item.departureAOR != 'ELP' && item.departureAOR != 'PHO' && item.departureAOR != 'SNA' && item.departureAOR != 'SND').length > 0 &&
                                    <div>
                                        {this.state.ucWorkOrders.filter(item => item.departureAOR != 'HLG' && item.departureAOR != 'ELP' && item.departureAOR != 'PHO' && item.departureAOR != 'SNA' && item.departureAOR != 'SND').map((workorder) => (
                                            <CommandCenterWorkorder workorder={workorder} customers={this.state.ucCustomers} />
                                        ))}
                                    </div>
                                }

                                <br />
                                <table>
                                    <thead>
                                        <tr>
                                            <th><b>Total UNCs:</b></th>
                                            <th style={{ width: "100px" }}>{this.state.ucCustomers.filter(item => this.state.ucWorkOrders.filter(item => item.departureAOR != 'HLG' && item.departureAOR != 'ELP' && item.departureAOR != 'PHO' && item.departureAOR != 'SNA' && item.departureAOR != 'SND').find((o) => { return o.id == item.workOrderID })).length}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-bordered">
                                        <tr>
                                            <td>Air</td>
                                            <td>{this.state.ucCustomers.filter(item => this.state.ucWorkOrders.filter(item => item.departureAOR != 'HLG' && item.departureAOR != 'ELP' && item.departureAOR != 'PHO' && item.departureAOR != 'SNA' && item.departureAOR != 'SND').find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'fl' })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Grounds</td>
                                            <td>{this.state.ucCustomers.filter(item => this.state.ucWorkOrders.filter(item => item.departureAOR != 'HLG' && item.departureAOR != 'ELP' && item.departureAOR != 'PHO' && item.departureAOR != 'SNA' && item.departureAOR != 'SND').find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'Ground' && o.aor != o.destinationAOR })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Locals</td>
                                            <td>{this.state.ucCustomers.filter(item => this.state.ucWorkOrders.filter(item => item.departureAOR != 'HLG' && item.departureAOR != 'ELP' && item.departureAOR != 'PHO' && item.departureAOR != 'SNA' && item.departureAOR != 'SND').find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'Ground' && o.aor == o.destinationAOR })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Work Orders</td>
                                            <td>{this.state.ucWorkOrders.filter(item => item.departureAOR != 'HLG' && item.departureAOR != 'ELP' && item.departureAOR != 'PHO' && item.departureAOR != 'SNA' && item.departureAOR != 'SND').length}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Item>
                        <Item title="FAMU">
                            <div style={{ margin: "10px" }}>
                                <h3>FAMU Transports</h3>

                                {tabs.map(([text, value], i) => (
                                    <div key={i}>
                                        {this.state.famuWorkOrders && this.state.famuWorkOrders.filter(item => item.departureAOR == value).length > 0 &&
                                            <div>
                                                <h4 style={{ backgroundColor: colors[value], textAlign: "center" }}>{text.toUpperCase()} SECTOR</h4>

                                                {this.state.famuWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'fl').length > 0 &&
                                                    <div>
                                                        <h4 style={{ backgroundColor: "#FFFF00", textAlign: "center" }}>Air</h4>

                                                        {this.state.famuWorkOrders && this.state.famuWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'fl').map((workorder) => (
                                                            <CommandCenterWorkorder workorder={workorder} customers={this.state.famuCustomers} />
                                                        ))}
                                                    </div>
                                                }

                                                {this.state.famuWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'Ground' && item.departureAOR != item.destinationAOR).length > 0 &&
                                                    <div>
                                                        <h4 style={{ backgroundColor: "#FFFF00", textAlign: "center" }}>Grounds</h4>

                                                        {this.state.famuWorkOrders && this.state.famuWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'Ground' && item.departureAOR != item.destinationAOR).map((workorder) => (
                                                            <CommandCenterWorkorder workorder={workorder} customers={this.state.famuCustomers} />
                                                        ))}
                                                    </div>
                                                }

                                                {this.state.famuWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'Ground' && item.departureAOR == item.destinationAOR).length > 0 &&
                                                    <div>
                                                        <h4 style={{ backgroundColor: "#FFFF00", textAlign: "center" }}>Locals</h4>

                                                        {this.state.famuWorkOrders && this.state.famuWorkOrders.filter(item => item.departureAOR == value && item.trafficTypeCode == 'Ground' && item.departureAOR == item.destinationAOR).map((workorder) => (
                                                            <CommandCenterWorkorder workorder={workorder} customers={this.state.famuCustomers} />
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>))
                                }

                                {this.state.famuWorkOrders && this.state.famuWorkOrders.filter(item => item.departureAOR != 'HLG' && item.departureAOR != 'ELP' && item.departureAOR != 'PHO' && item.departureAOR != 'SNA' && item.departureAOR != 'SND').length > 0 &&
                                    <div>
                                        <h4 style={{ textAlign: "center" }}>MISCELLANEOUS</h4>

                                        {this.state.famuWorkOrders.filter(item => item.departureAOR != 'HLG' && item.departureAOR != 'ELP' && item.departureAOR != 'PHO' && item.departureAOR != 'SNA' && item.departureAOR != 'SND').map((workorder) => (
                                            <CommandCenterWorkorder workorder={workorder} customers={this.state.famuCustomers} />
                                        ))}
                                    </div>
                                }

                                <br />
                                <table>
                                    <tbody className="table-bordered">
                                        <tr>
                                            <td>Bodies</td>
                                            <td style={{ width: "100px" }}>{this.state.famuCustomers.length}</td>
                                        </tr>
                                        <tr>
                                            <td>Family Units</td>
                                            <td>{this.SumUnits(this.state.famuWorkOrders)}</td>
                                        </tr>
                                        <tr>
                                            <td>Work Orders</td>
                                            <td>{this.state.famuWorkOrders.length}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Item>
                        <Item title="DRM UC">
                            <div style={{ margin: "10px" }}>
                                <h3>DRM UC</h3>
                                {this.state.ucDRMWorkOrders.length > 0 &&
                                    <div>
                                        {this.state.ucDRMWorkOrders.map((workorder) => (
                                            <CommandCenterWorkorder workorder={workorder} customers={this.state.ucDRMCustomers} />
                                        ))}
                                    </div>
                                }

                                <br />
                                <table>
                                    <thead>
                                        <tr>
                                            <th><b>Total UNCs:</b></th>
                                            <th style={{ width: "100px" }}>{this.state.ucDRMCustomers.filter(item => this.state.ucDRMWorkOrders.find((o) => { return o.id == item.workOrderID })).length}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-bordered">
                                        <tr>
                                            <td>Air</td>
                                            <td>{this.state.ucDRMCustomers.filter(item => this.state.ucDRMWorkOrders.find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'fl' })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Grounds</td>
                                            <td>{this.state.ucDRMCustomers.filter(item => this.state.ucDRMWorkOrders.find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'Ground' })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Work Orders</td>
                                            <td>{this.state.ucDRMWorkOrders.length}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Item>

                        <Item title="DRM FAMU">
                            <div style={{ margin: "10px" }}>
                                <h3>DRM FAMU</h3>
                                {this.state.famuDRMWorkOrders.length > 0 &&
                                    <div>
                                        {this.state.famuDRMWorkOrders.map((workorder) => (
                                            <CommandCenterWorkorder workorder={workorder} customers={this.state.famuDRMCustomers} />
                                        ))}
                                    </div>
                                }

                                <br />
                                <table>
                                    <thead>
                                        <tr>
                                            <th><b>Total UNCs:</b></th>
                                            <th style={{ width: "100px" }}>{this.state.famuDRMCustomers.filter(item => this.state.famuDRMWorkOrders.find((o) => { return o.id == item.workOrderID })).length}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-bordered">
                                        <tr>
                                            <td>Air</td>
                                            <td>{this.state.famuDRMCustomers.filter(item => this.state.famuDRMWorkOrders.find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'fl' })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Grounds</td>
                                            <td>{this.state.famuDRMCustomers.filter(item => this.state.famuDRMWorkOrders.find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'Ground' })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Work Orders</td>
                                            <td>{this.state.famuDRMWorkOrders.length}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Item>
                        <Item title="ATD FAMU">
                            <div style={{ margin: "10px" }}>
                                <h3>ATD FAMU</h3>
                                {this.state.famuATDWorkOrders.length > 0 &&
                                    <div>
                                        {this.state.famuATDWorkOrders.map((workorder) => (
                                            <CommandCenterWorkorder workorder={workorder} customers={this.state.famuATDCustomers} />
                                        ))}
                                    </div>
                                }

                                <br />
                                <table>
                                    <thead>
                                        <tr>
                                            <th><b>Total UNCs:</b></th>
                                            <th style={{ width: "100px" }}>{this.state.famuATDCustomers.filter(item => this.state.famuATDWorkOrders.find((o) => { return o.id == item.workOrderID })).length}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-bordered">
                                        <tr>
                                            <td>Air</td>
                                            <td>{this.state.famuATDCustomers.filter(item => this.state.famuATDWorkOrders.find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'fl' })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Grounds</td>
                                            <td>{this.state.famuATDCustomers.filter(item => this.state.famuATDWorkOrders.find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'Ground' })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Work Orders</td>
                                            <td>{this.state.famuATDWorkOrders.length}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Item>
                        <Item title="DR To POE UC">
                            <div style={{ margin: "10px" }}>
                                <h3>DR To POE UC</h3>
                                {this.state.ucDRToPOEWorkOrders.length > 0 &&
                                    <div>
                                        {this.state.ucDRToPOEWorkOrders.map((workorder) => (
                                            <CommandCenterWorkorder workorder={workorder} customers={this.state.ucDRToPOECustomers} />
                                        ))}
                                    </div>
                                }

                                <br />
                                <table>
                                    <thead>
                                        <tr>
                                            <th><b>Total UNCs:</b></th>
                                            <th style={{ width: "100px" }}>{this.state.ucDRToPOECustomers.filter(item => this.state.ucDRToPOEWorkOrders.find((o) => { return o.id == item.workOrderID })).length}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-bordered">
                                        <tr>
                                            <td>Air</td>
                                            <td>{this.state.ucDRToPOECustomers.filter(item => this.state.ucDRToPOEWorkOrders.find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'fl' })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Grounds</td>
                                            <td>{this.state.ucDRToPOECustomers.filter(item => this.state.ucDRToPOEWorkOrders.find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'Ground' })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Work Orders</td>
                                            <td>{this.state.ucDRToPOEWorkOrders.length}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Item>
                        <Item title="DR To POE FAMU">
                            <div style={{ margin: "10px" }}>
                                <h3>DR To POE FAMU</h3>
                                {this.state.famuDRToPOEWorkOrders.length > 0 &&
                                    <div>
                                        {this.state.famuDRToPOEWorkOrders.map((workorder) => (
                                            <CommandCenterWorkorder workorder={workorder} customers={this.state.famuDRToPOECustomers} />
                                        ))}
                                    </div>
                                }

                                <br />
                                <table>
                                    <thead>
                                        <tr>
                                            <th><b>Total UNCs:</b></th>
                                            <th style={{ width: "100px" }}>{this.state.famuDRToPOECustomers.filter(item => this.state.famuDRToPOEWorkOrders.find((o) => { return o.id == item.workOrderID })).length}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-bordered">
                                        <tr>
                                            <td>Air</td>
                                            <td>{this.state.famuDRToPOECustomers.filter(item => this.state.famuDRToPOEWorkOrders.find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'fl' })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Grounds</td>
                                            <td>{this.state.famuDRToPOECustomers.filter(item => this.state.famuDRToPOEWorkOrders.find((o) => { return o.id == item.workOrderID && o.trafficTypeCode == 'Ground' })).length}</td>
                                        </tr>
                                        <tr>
                                            <td>Work Orders</td>
                                            <td>{this.state.famuDRToPOEWorkOrders.length}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Item>
                    </TabPanel>

                    <LoadPanel
                        visible={this.state.loading} />
                </div>

                <br />
                <br />
            </div>
        );
    }
}
