
import React from 'react';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import DataGrid, { Item, FilterRow, Pager, Paging, Editing, Column, Lookup, FormItem, RequiredRule } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import * as $ from 'jquery'

const clothesList = new CustomStore({
    key: ['itemId', 'locationId'],
    load: () => {
        return FetchAPIPromise('InventoryClothesManagement');
    },
    insert: (values) => {
        console.log('Values ' + values);
        return PostAPIPromise('InventoryClothesManagement', values);
    },
    remove: (key) => {
        console.log(key);

        var queryString = 'locationId=' + key.locationId + '&itemId=' + key.itemId
        return DeleteAPIPromise('InventoryClothesManagement', queryString)
            .then((result) => {
                if (result.status == -2) {
                    alert('Clothes are referenced in the system and cannot be deleted. Consider inactivating.')
                }
            });
    },
    update: (key, values) => {
        return PostAPIPromise('InventoryClothesManagement', values);
    }
});

export class InventoryClothesManagementContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            items: []
        };
    }

    GetDropDownData = async () => {
        const locationData = await this.FetchAPI('Location');
        const itemData = await this.FetchAPI('InventoryItemClothes');

        this.setState({
            locations: locationData,
            items: itemData
        });
    }

    onInitNewRow(e) {
        // Set active value default to true
        e.data.isActive = true;
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    render() {
        return (
            <div style={{ margin: "10px" }}>
                <DataGrid dataSource={clothesList} onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating} >
                    <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()} />
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[10, 20, 30, 40, 50]}
                        showInfo={true} />
                    <Column caption="Location" dataField="locationId" >
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={this.state.locations} displayExpr="name" valueExpr="id" />
                    </Column>
                    <Column caption="Item" dataField="itemId" visible={false} >
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={this.state.items} displayExpr="itemNumber" valueExpr="id" />
                    </Column>
                    <Column caption="Item Number" dataField="itemNumber" >
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Item Description" dataField="itemDescription" >
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Quantity" dataField="quantity" >
                        <RequiredRule />
                    </Column>
                </DataGrid>
            </div>
        );
    }
}