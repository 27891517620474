import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Lookup, Form, FormItem, Export } from 'devextreme-react/data-grid';
import { EmptyItem, Item, Label } from 'devextreme-react/form';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import * as $ from 'jquery';
import Button from 'devextreme-react/button';
import { alert } from 'devextreme/ui/dialog';

const mileageDataSource = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('Mileage');
    },
    //insert: (values) => {
    //    console.log(values);
    //    return PostAPIPromise('Mileage', values);
    //},
    remove: (key) => {
        return DeleteAPIPromise('Mileage/' + key);
    },
    update: (key, values) => {
        console.log('Updating', values);
        return PostAPIPromise('Mileage', values);
    }
});

export class MileageContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            aors: [],
            states: [],
            cities: [],
            facilityID: null
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return "Mileage Management";
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    render() {

        return (
            <div className="container-fluid">
                <h1>Mileage Management</h1>

                <div className="row">
                    <DataGrid dataSource={mileageDataSource} onEditorPreparing={this.onEditorPreparing}
                        showBorders={true} allowColumnResizing={true}
                        keyExpr="id" onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating} onExporting={this.onExporting}>
                        <Export enabled={true} fileName={"mileageManagement"} />
                        <Editing mode="popup" allowAdding={false} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()}>
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2}>
                                    <Item dataField="locationOneType" editorOptions={{ readOnly: true }} />
                                    <Item dataField="locationTwoType" editorOptions={{ readOnly: true }} />
                                    <Item dataField="locationOneName" editorOptions={{ readOnly: true }} />
                                    <Item dataField="locationTwoName" editorOptions={{ readOnly: true }} />
                                    <Item dataField="locationOneId" editorOptions={{ readOnly: true }} />
                                    <Item dataField="locationTwoId" editorOptions={{ readOnly: true }} />

                                    <EmptyItem colSpan={2} />
                                    <EmptyItem colSpan={2} />

                                    <Item dataField="distanceInMiles">
                                        <RequiredRule />
                                    </Item>
                                </Item>
                            </Form>
                        </Editing>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />

                        <Column caption="Location One Type" dataField="locationOneType" alignment="left">
                        </Column>

                        <Column caption="Location One Name" dataField="locationOneName" alignment="left">
                        </Column>

                        <Column caption="Location One Id" dataField="locationOneId" alignment="left">
                        </Column>

                        <Column caption="Location Two Type" dataField="locationTwoType" alignment="left">
                        </Column>

                        <Column caption="Location Two Name" dataField="locationTwoName" alignment="left">
                        </Column>

                        <Column caption="Location Two Id" dataField="locationTwoId" alignment="left">
                        </Column>

                        <Column caption="Distance In Miles" dataField="distanceInMiles" alignment="left">
                        </Column>
                    </DataGrid>
                    <br />
                </div>
            </div>
        );
    }
}