import React from 'react';
import BaseComponent, { withParams } from '../BaseComponent.js'
import DataGrid, { Column, FilterRow, Pager, Paging, Lookup, MasterDetail } from 'devextreme-react/data-grid';
import TextBox from 'devextreme-react/text-box';
import Form, { EmptyItem, Item, Label, RequiredRule } from 'devextreme-react/form';
import DateBox from 'devextreme-react/date-box';
import MilestoneDetail from '../WorkOrder/MilestoneDetail';
import QRCode from 'qrcode.react';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import Button from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';

class CustomerPlacementContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.state = {
            loading: false,

            placementId: null,
            customerId: null,

            placementDetails: {},
            isViewingMilestones: false,
            allMilestones: {},
            workOrderSelected: null,
            milestonesHeader: null
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);
        //console.log(this.props.params.placementId);
        //console.log(this.props.params.customerId);

        if (this.props.params.placementId != undefined && this.props.params.customerId != undefined) {
            await this.setState({ placementId: this.props.params.placementId, customerId: this.props.params.customerId });
        }

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return (this.state.placementDetails.customerDetails.aNum ? ("#" + this.state.placementDetails.customerDetails.aNum + " Customer Placement Details") : 'Customer Placement Details');
    }

    GetData = async () => {

        //console.log('Placement Id', this.state.placementId);
        //console.log('Customer Id', this.state.customerId);

        if (this.state.placementId != '00000000-0000-0000-0000-000000000000') {

            const placementDetails = await this.FetchAPI('Placement/GetCustomerPlacementHistoryDetails?customerId=' + this.state.customerId + '&placementId=' + this.state.placementId);

            this.setState({
                placementDetails: placementDetails
            });
        }
    }

    hideMilestonesPopUp = (e) => {
        this.setState({
            isViewingMilestones: false,
            allMilestones: {},
            workOrderSelected: null,
            milestonesHeader: null
        });
    }

    showMilestonesPopUp = async (e) => {

        await this.setState({
            loading: true
        });

        const allMilestones = await this.FetchAPI('WorkOrderMilestone/GetAllMilestonesByCustomer?workOrderId=' + this.state.workOrderSelected.workOrderId + '&customerId=' + this.state.placementDetails.customerDetails.customerID);
        const milestonesHeader = ("All milestones for customer #" + (this.state.placementDetails.customerDetails.aNum ? this.state.placementDetails.customerDetails.aNum : this.state.placementDetails.customerDetails.subjectID) + " on work order #" + this.state.workOrderSelected.workOrderNumber + " ");

        this.setState({
            isViewingMilestones: true,
            allMilestones: allMilestones,
            milestonesHeader: milestonesHeader,
            loading: false
        });
    }


    render() {

        //const rootUrl = process.env.PUBLIC_URL;
        const rootUrl = window.location.origin;
        //console.log(rootUrl);
        //console.log(window.location.host);
        //console.log(window.location.origin);
        //console.log(process.env.PUBLIC_URL);

        return (
            <div className="container">
                <h1>Customer Placement Information</h1>

                <br />
                <br />

                <Form formData={this.state.placementDetails}>
                    <Item itemType="group" caption="Customer Info" colCount={3}>
                        <Item dataField="customerDetails.aNum" editorOptions={{ readOnly: true }}>
                            <Label text="A#" />
                        </Item>
                        <Item dataField="customerDetails.subjectID" editorOptions={{ readOnly: true }}>
                            <Label text="Subject ID" />
                        </Item>
                        <EmptyItem />

                        <Item dataField="customerDetails.firstName" editorOptions={{ readOnly: true }}>
                            <Label text="First Name" />
                        </Item>
                        <Item dataField="customerDetails.middleName" editorOptions={{ readOnly: true }}>
                            <Label text="Middle Name" />
                        </Item>
                        <Item dataField="customerDetails.lastName" editorOptions={{ readOnly: true }}>
                            <Label text="Last Name" />
                        </Item>

                        <Item dataField="customerDetails.gender" editorOptions={{ readOnly: true }}>
                            <Label text="Gender" />
                        </Item>
                        <Item dataField="customerDetails.dob" editorType="dxDateBox" editorType="dxDateBox" editorOptions={{ displayFormat: 'MM/dd/yyyy', type: 'date', readOnly: true }}>
                            <Label text="DOB" />
                        </Item>
                        <Item dataField="customerDetails.nationality" editorOptions={{ readOnly: true }}>
                            <Label text="Nationality" />
                        </Item>
                    </Item>

                    <Item itemType="group" caption="Placement Details" colCount={3}>
                        <Item dataField="placementDetails.orrPlacementDate" editorType="dxDateBox" editorOptions={{ displayFormat: "MM/dd/yyyy HH:mm '(Local)'", type: 'datetime', readOnly: true }}>
                            <Label text="Placement Date" />
                        </Item>
                        <Item dataField="placementDetails.dhsCustodyDate" editorType="dxDateBox" editorOptions={{ displayFormat: 'MM/dd/yyyy HH:mm', type: 'datetime', readOnly: true }}>
                            <Label text="DHS Custody Date" />
                        </Item>
                        <Item dataField="placementDetails.statusName" editorOptions={{ readOnly: true }}>
                            <Label text="Status" />
                        </Item>

                        <Item dataField="placementDetails.departureCity" editorOptions={{ readOnly: true }}>
                            <Label text="Departure City" />
                        </Item>
                        <Item dataField="placementDetails.pickupLocationName" editorOptions={{ readOnly: true }}>
                            <Label text="Pickup Location" />
                        </Item>
                        <Item dataField="placementDetails.destinationName" editorOptions={{ readOnly: true }}>
                            <Label text="Destination" />
                        </Item>

                        <Item dataField="placementDetails.authorityCode" editorOptions={{ readOnly: true }}>
                            <Label text="Authority" />
                        </Item>
                        <EmptyItem />
                        <Item>
                            {this.state.placementDetails.placementDetails &&
                                <QRCode value={`${rootUrl}/customer-placement/${this.state.placementDetails.placementDetails.id}/${this.state.placementDetails.customerDetails.customerID}`} />
                            }
                        </Item>
                    </Item>

                    {this.state.placementDetails.placementDetails && this.state.placementDetails.placementDetails.alternatePOCName &&
                        <Item itemType="group" caption="Sponsor Info" colCount={3}>
                            <Item dataField="placementDetails.alternatePOCName" editorOptions={{ readOnly: true }}>
                                <Label text="POC Name" />
                            </Item>
                            <Item dataField="placementDetails.alternatePOCPhoneNumber" editorOptions={{ readOnly: true }}>
                                <Label text="POC Phone" />
                            </Item>
                            <Item dataField="placementDetails.alternatePOCAddress" editorOptions={{ readOnly: true }}>
                                <Label text="POC Address" />
                            </Item>

                            <Item dataField="placementDetails.alternatePOCCity" editorOptions={{ readOnly: true }}>
                                <Label text="POC City" />
                            </Item>
                            <Item dataField="placementDetails.alternatePOCState" editorOptions={{ readOnly: true }}>
                                <Label text="POC State" />
                            </Item>
                            <Item dataField="placementDetails.alternatePOCZIPCode" editorOptions={{ readOnly: true }}>
                                <Label text="POC ZIP" />
                            </Item>
                        </Item>
                    }

                    {(!this.state.placementDetails.placementDetails || !this.state.placementDetails.placementDetails.alternatePOCName) &&
                        <Item colSpan={3}>
                        <p>No Alternate POC/Sponsor Information provided</p>
                        </Item>
                    }

                    {this.state.placementDetails.workOrderHistory && this.state.placementDetails.workOrderHistory.length > 0 &&
                        <Item itemType="group" caption="Trip History" colCount={3}>
                            {this.state.placementDetails.workOrderHistory.map((workOrder) => {
                                var items = [];

                                items.push(<Item>
                                    <Label text="Work Order Name" />
                                    <TextBox value={workOrder.workOrderName} readOnly={true} />
                                </Item>);
                                items.push(<Item>
                                    <Label text="Work Order Number" />
                                    <TextBox value={workOrder.workOrderNumber} readOnly={true} />
                                </Item>);
                                items.push(<Item>
                                    <Label text="Status" />
                                    <TextBox value={workOrder.statusName} readOnly={true} />
                                </Item>);

                                items.push(<Item>
                                    <Label text="Plan Start Date" />
                                    <DateBox displayFormat="MM/d/yyyy HH:mm" value={workOrder.planStartDate} readOnly={true} />
                                </Item>);
                                if (workOrder.pickupStatusCode == 'ACTIVE') {
                                    items.push(<Item>
                                        <Label text="Plan Pickup Date" />
                                        <DateBox displayFormat="MM/d/yyyy HH:mm" value={workOrder.planPickupDate} readOnly={true} />
                                    </Item>);
                                }
                                else {
                                    items.push(<Item>
                                        <Label text="Plan Pickup Date" />
                                        <TextBox value='N/A' readOnly={true} />
                                    </Item>);
                                }
                                items.push(<Item>
                                    <Label text="Departure City" />
                                    <TextBox value={workOrder.departureCityFullName} readOnly={true} />
                                </Item>);

                                if (workOrder.dropoffStatusCode == 'ACTIVE') {
                                    items.push(<Item>
                                        <Label text="Plan Dropoff/Removal Date" />
                                        <DateBox displayFormat="MM/d/yyyy HH:mm" value={workOrder.planRemovalDate} readOnly={true} />
                                    </Item>);
                                }
                                else {
                                    items.push(<Item>
                                        <Label text="Plan Dropoff/Removal Date" />
                                        <TextBox value='N/A' readOnly={true} />
                                    </Item>);
                                }
                                items.push(<Item>
                                    <Label text="Plan End Date" />
                                    <DateBox displayFormat="MM/d/yyyy HH:mm" value={workOrder.planEndDate} readOnly={true} />
                                </Item>);
                                items.push(<Item>
                                    <Label text="Destination City" />
                                    <TextBox value={workOrder.destinationCityFullName} readOnly={true} />
                                </Item>);

                                items.push(<Item>
                                    <Label text="Traffic Type" />
                                    <TextBox value={workOrder.trafficTypeCode} readOnly={true} />
                                </Item>);
                                items.push(<Item>
                                    <Label text="Transport Method" />
                                    <TextBox value={workOrder.transportMethodCode} readOnly={true} />
                                </Item>);

                                items.push(<EmptyItem />);
                                items.push(<Item>
                                    <Label text="Transport Unit" />
                                    <TextBox value={workOrder.transportUnit} readOnly={true} />
                                </Item>);
                                items.push(<Item>
                                    <Label text="Work Order Type" />
                                    <TextBox value={workOrder.workOrderType} readOnly={true} />
                                </Item>);
                                items.push(<EmptyItem />);

                                items.push(<Item colSpan={3}>
                                    <h7>{"Employees assigned to work order #" + workOrder.workOrderNumber + " :"}</h7>
                                </Item>);

                                items.push(<Item colSpan={3}><DataGrid dataSource={workOrder.employeeList}
                                    showBorders={true} allowColumnResizing={true}                                        >
                                    <Column dataField="firstName" />
                                    <Column dataField="middleName" />
                                    <Column dataField="lastName" />
                                    <Column dataField="employeeNo" />
                                    <Column dataField="homePhone" caption="Personal Phone" />
                                    <Column dataField="workOrderRole" />
                                </DataGrid></Item>);

                                items.push(<Item colSpan={2}>
                                    <h7>{"Most recent milestones for customer #" + (this.state.placementDetails.customerDetails.aNum ? this.state.placementDetails.customerDetails.aNum : this.state.placementDetails.customerDetails.subjectID) + " on work order #" + workOrder.workOrderNumber + " :"}</h7>
                                </Item>);

                                items.push(<Item>
                                    <Button text="Show all milestones" type="normal" onClick={this.state.workOrderSelected = workOrder, this.showMilestonesPopUp} />
                                </Item>);

                                items.push(<Item colSpan={3}><DataGrid dataSource={workOrder.milestoneList}
                                    showBorders={true} allowColumnResizing={true}>

                                    <Column dataField="eventDateTime" caption="Event Date/Time" dataType="datetime" format="MM/dd/yy, HH:mm" />
                                    <Column caption="Stage" dataField="stageName" />
                                    <Column dataField="milestoneName" caption="Milestone" />
                                    <Column dataField="employeeFullNameAnum" caption="TS Name" />
                                    <Column dataField="comment" caption="Comment" />
                                    <Column dataField="updatedByFullNameAnum" caption="Last Updated By" />
                                    <Column dataField="updatedAt" caption="Last Updated At" dataType="datetime" format="MM/dd/yy, HH:mm" />

                                    <MasterDetail enabled={true} component={MilestoneDetail} />

                                </DataGrid></Item>);

                                return (items);
                            })}
                        </Item>
                    }
                </Form>

                <Popup visible={this.state.isViewingMilestones} onHiding={this.hideMilestonesPopUp} dragEnabled={false}
                    closeOnOutsideClick={false} showTitle={true} title={this.state.milestonesHeader}>
                    <ScrollView>
                        <DataGrid dataSource={this.state.allMilestones}
                            showBorders={true} allowColumnResizing={true}>

                            <FilterRow visible={true} />

                            <Column dataField="eventDateTime" caption="Event Date/Time" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Stage" dataField="stageName" />
                            <Column dataField="milestoneName" caption="Milestone" />
                            <Column dataField="employeeFullNameAnum" caption="TS Name" />
                            <Column dataField="comment" caption="Comment" />
                            <Column dataField="updatedByFullNameAnum" caption="Last Updated By" />
                            <Column dataField="updatedAt" caption="Last Updated At" dataType="datetime" format="MM/dd/yy, HH:mm" />

                            <MasterDetail enabled={true} component={MilestoneDetail} />
                        </DataGrid>
                    </ScrollView>
                </Popup>

                <LoadPanel visible={this.state.loading} />

            </div>
        );
    }
}

export default withParams(CustomerPlacementContainer);