
import React from 'react';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import Form, { EmptyItem, Item, Label } from 'devextreme-react/form';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Column, Pager, Paging, MasterDetail } from 'devextreme-react/data-grid';
import { alert } from 'devextreme/ui/dialog';
import * as moment from 'moment';
import { Link } from 'react-router-dom';

export class TravelBookingDashboardContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            displayMode: 'Recently Received Itineraries',

            filters: {
                searchText: ''
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();

        this.fetchData();
    }

    placementList = new CustomStore({
        load: (loadOptions) => {
            console.log('Load: ' + this.state.filters.searchText);

            if (this.state.filters.searchText) {
                return FetchAPIPromise('FroschItinerary/CheckFroschRecordStatus?recordLocators=' + this.state.filters.searchText).then((result) => {
                    if (result.status == -1) {
                        alert(result.message);
                    }
                    else {
                        return result.results;
                    }
                });
            } else {
                return FetchAPIPromise('FroschItinerary/GetRecentItineraries');
            }
        }
    });

    SearchItineraries = async (e) => {

        if (this.state.filters.searchText) {
            this.dataGrid.instance().refresh();

            await this.setState({
                displayMode: 'Record Locator Search Results'
            });
        }
        else {
            alert('Record Locators values cannot be empty.');
        }
    }

    ValueChanged = async (e) => {

        if (!e.value || e.value == '') {
            this.dataGrid.instance().refresh();

            await this.setState({
                displayMode: 'Recently Received Itineraries'
            });
        }
    }

    itineraryDateItemRender = function (options) {
        if (!options.value) {
            return '****MISSING****';
        }
        else {
            return moment(options.value).format("MM/DD/YYYY HH:mm");
        }
    }

    itineraryItemRender = function (options) {
        if (!options.value) {
            return '****MISSING****';
        }
        else {
            return (<Link to={`/workorder-resources/${options.row.data.workOrderId}`}>{options.value}</Link>);
        }
    }

    render() {

        return (
            <div className="container">
                <h1>Travel Bookings</h1>

                <div className="container">
                    <br />
                    <div className="text-center">
                        <h6>To check the status of a Frosch Itinerary, enter one or more record locators, comma-delimited (eg ABCDEF,GHIJKL,MNOPQR)</h6>
                        <br />
                    </div>

                    <Form
                        ref={ref => this.recordLocatorSearch = ref}
                        formData={this.state.filters} colCount={3}>
                        <EmptyItem />
                        <Item dataField="searchText" editorType="dxTextBox" editorOptions={{ placeholder: 'Search for Record Locators', onEnterKey: this.SearchItineraries, showClearButton: true, onValueChanged: this.ValueChanged }}>
                            <Label visible={false} />
                        </Item>
                        <EmptyItem />
                    </Form>
                    <br />

                    <h6>{this.state.displayMode}</h6>
                    <DataGrid dataSource={this.placementList} ref={ref => this.dataGrid = ref}
                        showBorders={true} allowColumnResizing={true}>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />

                        <Column dataField="pnr" caption="Record Locator" />
                        <Column dataField="receivedDate" caption="Received Date" cellRender={this.itineraryDateItemRender} />
                        <Column dataField="workOrderNumber" caption="Work Order #" cellRender={this.itineraryItemRender} />
                        <Column dataField="status_message" caption="Frosch Status" />

                        <MasterDetail enabled={true} component={ItineraryDetail} />
                    </DataGrid>
                </div>
                <br />
            </div>
        );
    }
}

class ItineraryDetail extends React.Component {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.flightData = props.data.row.data.flights;
        this.rentalData = props.data.row.data.rentals;
        this.hotelData = props.data.row.data.hotels;
    }

    render() {
        return (
            <div>
                {this.flightData.length > 0 &&
                    <div>
                        <br />
                        <b><h7>Flights</h7></b>
                        <br />
                        <br />
                        <DataGrid dataSource={this.flightData}
                            showBorders={true} allowColumnResizing={true}
                            keyExpr="id">

                            <Column dataField="travelerName" caption="Traveler Name" />
                            <Column dataField="flightNumber" caption="Flight Number" />
                            <Column dataField="operatingCarrier" caption="Carrier" />
                            <Column dataField="confirmationNumber" caption="Confirmation Number" />
                            <Column dataField="departureAirportCode" caption="Departure Airport" />
                            <Column dataField="departureLocalTime" caption="Departure Time" dataType="datetime" format="MM/dd/yy HH:mm" />
                            <Column dataField="arrivalAirportCode" caption="Arrival Airport" />
                            <Column dataField="arrivalLocalTime" caption="Arrival Time" dataType="datetime" format="MM/dd/yy HH:mm" />
                        </DataGrid>
                    </div>
                }

                {this.hotelData.length > 0 &&
                    <div>
                        <br />
                        <b><h7>Hotels</h7></b>
                        <br />
                        <br />
                        <DataGrid dataSource={this.hotelData}
                            showBorders={true} allowColumnResizing={true}
                            keyExpr="id">

                            <Column dataField="travelerName" caption="Traveler Name" />
                            <Column dataField="confirmationNumber" caption="Confirmation Number" />
                            <Column dataField="hotelName" caption="Hotel Name" />
                            <Column dataField="rooms" caption="Rooms" />
                            <Column dataField="checkIn" caption="Check In" dataType="datetime" format="MM/dd/yy HH:mm" />
                            <Column dataField="checkOut" caption="Check Out" dataType="datetime" format="MM/dd/yy HH:mm" />
                        </DataGrid>
                    </div>
                }

                {this.rentalData.length > 0 &&
                    <div>
                        <br />
                        <b><h7>Rentals</h7></b>
                        <br />
                        <br />
                        <DataGrid dataSource={this.rentalData}
                            showBorders={true} allowColumnResizing={true}
                            keyExpr="id">

                            <Column dataField="travelerName" caption="Traveler Name" />
                            <Column dataField="confirmationNumber" caption="Confirmation Number" />
                            <Column dataField="vendorName" caption="Vendor Name" />
                            <Column dataField="equipmentType" caption="Equipment Type" />
                            <Column dataField="pickUpLocationName" caption="Pickup Location Name" />
                            <Column dataField="pickUpLocalTime" caption="Pickup Time" dataType="datetime" format="MM/dd/yy HH:mm" />
                            <Column dataField="dropOffLocationName" caption="Dropoff Location Name" />
                            <Column dataField="dropOffLocalTime" caption="Dropoff Time" dataType="datetime" format="MM/dd/yy HH:mm" />
                        </DataGrid>
                    </div>
                }
            </div>
        );
    }
}