import React from 'react';
import BaseComponent, { FetchAPIPromise, withParams } from '../BaseComponent.js';
import DataGrid, { FilterRow, Button as GridButton, Pager, Paging, Column, MasterDetail } from 'devextreme-react/data-grid';
import Form, { EmptyItem, Item, Label, RequiredRule, StringLengthRule, CustomRule } from 'devextreme-react/form';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Link } from 'react-router-dom';
import Button from 'devextreme-react/button';
import { alert, confirm } from 'devextreme/ui/dialog';
import { Popup } from 'devextreme-react/popup';
import CustomStore from 'devextreme/data/custom_store';
import { flushSync } from 'react-dom'; // Note: react-dom, not react
import * as moment from 'moment';
import ScrollView from 'devextreme-react/scroll-view';
import { WorkOrderDetailsMSWOCoverageContainer } from './WorkOrderDetailsMSWOCoverageContainer.js';

import "devextreme/ui/autocomplete";

class WorkOrderDetailContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.transferLocationChanged.bind(this);

        this.state = {
            workOrder: { workOrderName: null, planStartDate: null, planEndDate: null, statusID: null, departureCityName: null, trafficTypeCode: null, workOrderNumber: null, woNotes: null, transportUnit: null, workOrderType: null, destinationCityName: null, planPickupDate: null, planRemovalDate: null, transportMethodCode: null, statusID: null, supportWorkOrders: null, responsibleOfficeId: null },
            transportMethods: [],
            filteredTransportMethods: [],
            isActiveStatus: false,
            statuses: [],

            mvmTransferAutocompleteItems: [],

            offices: [],
            filteredOffices: [],

            trafficTypes: [],
            transportUnits: [],
            supportWorkOrderUACs: [],
            linkedRegularWorkOrders: [],
            departureCities: [],
            destinationCities: [],
            reviewStatuses: [],
            loading: false,
            isOutOfRatio: false,

            changeHistoryVisible: false,

            isViewingGroundTransitPlan: false,
            transitMetricsList: [],
            transitLegsList: [],

            QAQCIssuesVisible: false,
            QAQCIssuesList: [],
            QAQCAllowContinue: false
        };
        this.supportWorkOrderAOR = '';
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');

        const { type } = this.props.params;

        if (this.props.params.supportWorkOrderId) {
            var supportWorkOrderData = await this.FetchAPI('WorkOrder/GetSupportWorkOrderInfo/' + this.props.params.supportWorkOrderId);

            this.supportWorkOrderAOR = supportWorkOrderData.departureAOR;
        }

        await this.updateProperty('workOrder', 'workOrderType', type);

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return (this.state.workOrder.workOrderNumber ? ("#" + this.state.workOrder.workOrderNumber + " WO Details") : 'Create New Work Order');
    }

    GetData = async () => {
        if (this.props.params.id == undefined) {
            var workOrderData = await this.FetchAPI('WorkOrder/GetNewWorkOrder/' + this.state.workOrder.workOrderType);
            workOrderData.planStartDate = null;
            workOrderData.planEndDate = null;
            workOrderData.planRemovalDate = null;

            const filterMethods = this.state.transportMethods.filter(t => t.trafficTypeCode === workOrderData.trafficTypeCode && (t.isActive || t.code === workOrderData.transportMethodCode));

            // Filter on active and scheduling office
            const filterOffices = this.state.offices.filter((item) => (item.isActive && item.isSchedulingOffice) || item.id == workOrderData.responsibleOfficeId);

            flushSync(() => {
                this.setState({
                    workOrder: workOrderData,
                    filteredTransportMethods: filterMethods,
                    filteredOffices: filterOffices
                });
            });
        }
        else {
            const workOrderData = await this.FetchAPI('WorkOrder/' + this.props.params.id);

            var rules = await this.FetchAPI('WorkOrder/GetRules/' + this.props.params.id);
            //console.log(rules);

            var isOutOfRatio = !rules.statusMinTsFemaleCount || !rules.statusMinTsMaleCount || !rules.statusMinTsTotalCount;

            const supportUACs = workOrderData.supportUAC;

            const linkedRegularWorkOrders = workOrderData.linkedRegularWorkOrders;

            //console.log('Transport methods from the server', this.state.transportMethods);

            const filterMethods = this.state.transportMethods.filter(t => t.trafficTypeCode === workOrderData.trafficTypeCode && (t.isActive || t.code === workOrderData.transportMethodCode));

            // Filter on active and scheduling office
            // TODO: Test this
            const filterOffices = this.state.offices.filter((item) => (item.isActive && item.isSchedulingOffice) || item.id == workOrderData.responsibleOfficeId);

            //console.log('Work Order Data', workOrderData);
            var isActiveStatus = workOrderData.statusID == 1;

            // ABG: Not sure why I have to do this, but devextreme will not handle offset dates properly
            workOrderData.planStartDate = moment.parseZone(workOrderData.planStartDate).format('yyyy-MM-DDTHH:mm:ss');
            workOrderData.planEndDate = moment.parseZone(workOrderData.planEndDate).format('yyyy-MM-DDTHH:mm:ss');

            if (workOrderData.planPickupDates) {
                workOrderData.planPickupDates.map((date, index) => {
                    date.planDate = moment.parseZone(date.planDate).format('yyyy-MM-DDTHH:mm:ss');
                });
            }

            // ABG: Not sure why I have to do this, but devextreme will not handle offset dates properly
            if (workOrderData.planDropoffDates) {
                workOrderData.planDropoffDates.map((date, index) => {
                    date.planDate = moment.parseZone(date.planDate).format('yyyy-MM-DDTHH:mm:ss');
                });
            }

            flushSync(() => {
                this.setState({
                    workOrder: workOrderData,
                    supportWorkOrderUACs: supportUACs,
                    filteredTransportMethods: filterMethods,
                    filteredOffices: filterOffices,
                    linkedRegularWorkOrders: linkedRegularWorkOrders,
                    isActiveStatus: isActiveStatus,
                    isOutOfRatio: isOutOfRatio
                });
            });
        }
    }

    GetDropDownData = async () => {
        const trafficTypeData = await this.FetchAPI('DictionaryTrafficType');

        const transportMethodData = await this.FetchAPI('DictionaryTransportMethod');

        const cityData = await this.FetchAPI('DictionaryCity');

        const transportUnitData = await this.FetchAPI('DictionaryTransportUnit');

        const statusData = await this.FetchAPI('DictionaryWorkOrderStatus');

        const reviewStatusData = [{ code: 'UNREVIEWED', name: 'Unreviewed' }, { code: 'REVIEWED', name: 'Reviewed' }];

        const officeData = await this.FetchAPI('Office');

        const physicalTransferAddresses = await this.FetchAPI('Office/GetPhysicalAddressesForContract');

        physicalTransferAddresses.map((item) => {
            item.name = 'MVM Office: ' + item.name;
        });

        var destinationCityData = [...cityData];

        // Filter out inactive auth codes here
        var filterCity = [];
        if (this.state.workOrder.workOrderType == "Regular" && (this.state.workOrder.supportWorkOrders != null || this.props.params.supportWorkOrderId != undefined)) {
            filterCity = cityData.filter(t => t.aorCode == this.supportWorkOrderAOR);
        }
        else {
            filterCity = cityData;
        }

        flushSync(() => {
            this.setState({
                trafficTypes: trafficTypeData,
                transportMethods: transportMethodData,
                transportUnits: transportUnitData,
                statuses: statusData,
                departureCities: filterCity,
                destinationCities: destinationCityData,
                reviewStatuses: reviewStatusData,
                offices: officeData,

                mvmTransferAutocompleteItems: physicalTransferAddresses
            });
        });
    }

    deleteWorkOrder = async () => {
        if (window.confirm("Are you sure you want to delete this Work Order? Its Placement(s) will be moved into 'Not Dispatched' status. ")) {
            //console.log('Deleting work order...');
            await this.setState({
                loading: true
            });
            try {
                //console.log(this.props.params.id);
                //console.log(this.state.workOrder.id);
                const result = await this.DeleteAPI('WorkOrder/' + this.props.params.id);
                if (result.status == 1) {
                    this.props.navigate('/workorders');
                }
                else {
                    alert(result.message);
                }
            }
            finally {
                await this.setState({
                    loading: false
                });
            }
        }
    }

    saveWorkOrder = async (e) => {
        //console.log('Saving...');
        e.preventDefault();

        await this.setState({
            loading: true
        });

        try {

            var workOrder = this.state.workOrder;

            //console.log(workOrder);

            if (this.props.params.supportWorkOrderId) {
                workOrder.supportWorkOrderId = this.props.params.supportWorkOrderId;
            }

            // Find responsibleoffice selected value and set the offset
            var responsibleOffice = this.state.offices.find((item) => item.id == workOrder.responsibleOfficeId);

            if (responsibleOffice) {
                var officeOffset = moment(workOrder.planStartDate).tz(responsibleOffice.timeZone).format('Z');

                workOrder.planStartDate = moment.parseZone(moment.parseZone(workOrder.planStartDate).format('MM/DD/YYYY HH:mm') + ' ' + officeOffset).format('YYYY-MM-DDTHH:mmZ');
                workOrder.planEndDate = moment.parseZone(moment.parseZone(workOrder.planEndDate).format('MM/DD/YYYY HH:mm') + ' ' + officeOffset).format('YYYY-MM-DDTHH:mmZ');

                if (workOrder.mvmToMVMTransferDate) {
                    workOrder.mvmToMVMTransferDate = moment.parseZone(moment.parseZone(workOrder.mvmToMVMTransferDate).format('MM/DD/YYYY HH:mm') + ' ' + officeOffset).format('YYYY-MM-DDTHH:mmZ');
                }
            }

            //console.log(workOrder);

            // When we save the new date data, need to convert back to the expected offset
            if (workOrder.planPickupDates) {
                workOrder.planPickupDates.map((date, index) => {
                    //console.log(moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ'));
                    date.planDate = moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ');
                });
            }

            if (workOrder.planDropoffDates) {
                workOrder.planDropoffDates.map((date, index) => {
                    console.log(date);
                    //console.log(moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ'));
                    date.planDate = moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ');
                });
            }

            // console.log('Data to save', workOrder);
            const result = await this.PostAPI('WorkOrder', { workOrder: workOrder, bypassQAQCWarnings: false });

            if (result.status == 1) {
                this.props.navigate('/workorder-detail/' + result.workOrderId);
                this.props.navigate(0);
            }
            else if (result.status == -1) {
                alert(result.message);
            }
            else {
                //alert('Display error log');

                // TODO: Display Error log
                // 1) Check results
                // 2) IF severity is critical, do not allow continue
                // 3) Else, display results and give user option to continue
                this.showQAQCIssues(result.workOrderRulesRunResults);
            }
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    saveFAMUs = async () => {
        var placements = await this.addFAMUdataGrid.instance().getSelectedRowKeys();

        if (placements.length == 0) {
            alert("Please select FAMU's.");
        }
        else {
            var array = {};
            array.placementIds = placements;
            array.workOrderID = this.props.params.id;
            array.remove = false;
            console.log(array);

            var validationResult = await this.PostAPI('Placement/CheckPlacementIsUsed', array);

            console.log(validationResult);

            if (validationResult.status == -1) {
                alert(validationResult.message);
                return;
            }
            else if (validationResult.status == 0) {
                alert("One or more Customers from these Placements are already on Work Order and cannot be added.");
                return;
            }
            else {
                for (var i = 0; i < validationResult.errorList.length; i++) {
                    var item = validationResult.errorList[i];

                    console.log(item);

                    if (item.startsWith('Age Error')) {
                        alert(item + " is not the same Transport Unit. Assigned Placement Transport Units must be the same as the Work Order.");
                        return;
                    }
                    else if (item.startsWith('Inactive Roster Error ')) {
                        alert(item + " is already on this Work Order in the Inactive Roster and cannot be added. ");
                        return;
                    }
                    else {
                        alert(item + " has already been dispatched on another Work Order.");
                        return;

                    }
                }
            }

            var uacs = {
                placementIds: placements,
                supportWorkOrderNumbers: this.state.workOrder.supportWorkOrders,
                workOrderId: this.props.params.id
            }

            var response = await this.PostAPI('WorkOrder/SaveUACFromSupport', uacs);
            if (response.status == 1) {
                window.location.href = '/workorder-detail/' + this.props.params.id;
            }
            else {
                alert('Failed. Please try again later.');
            }
        }
    }

    unlinkComponentIsReadOnly = () => {
        return this.IsReadOnly() || this.state.workOrder.statusID != 0;
    }


    unlinkRWO = async (e) => {
        var workOrderNumber = e.row.values[0];

        var array = {};
        array.supportWorkOrderID = this.props.params.id;
        array.remove = false;
        console.log(array);

        var uacs = {
            supportWorkOrderId: this.props.params.id,
            RegularWorkOrderNumber: workOrderNumber,
        }

        var response = await this.PostAPI('WorkOrder/CheckIfUnlinkAllowed', uacs);

        if (response.status == 1) {
            var response = await this.PostAPI('WorkOrder/UnlinkSWOFromRWO', uacs);
            if (response.status == 1) {
                window.location.href = '/workorder-detail/' + this.props.params.id;
            }
            else {
                alert('Failed to unlink WorkOrders. Please try again later.');
            }
        }
        else {
            alert('<center>Cannot unlink Regular Work Order from Support Work Order. <br /> Placements may only be Active on the Support OR Regular Work Order. <br/> Please inactivate all linked placements in the Support OR Regular Work Order and try again. <br/> To see the list of linked placements, use the MSWO -> RWO Coverage Table below and filter using Work Order Number: <u>' + workOrderNumber + '</u>. </center>');
        }
       
    }

    saveUACs = async () => {
        var placements = await this.addUACdataGrid.instance().getSelectedRowKeys();

        if (placements.length == 0) {
            alert("Please select UC's.");
        }
        else {
            var array = {};
            array.placementIds = placements;
            array.workOrderID = this.props.params.id;
            array.remove = false;
            console.log(array);

            var validationResult = await this.PostAPI('Placement/CheckPlacementIsUsed', array);

            console.log(validationResult);

            if (validationResult.status == -1) {
                alert(validationResult.message);
                return;
            }
            else if (validationResult.status == 0) {
                alert("One or more Customers from these Placements are already on Work Order and cannot be added.");
                return;
            }
            else {
                for (var i = 0; i < validationResult.errorList.length; i++) {
                    var item = validationResult.errorList[i];

                    console.log(item);

                    if (item.startsWith('Age Error')) {
                        alert(item + " is not the same Transport Unit. Assigned Placement Transport Units must be the same as the Work Order.");
                        return;
                    }
                    else if (item.startsWith('Inactive Roster Error ')) {
                        alert(item + " is already on this Work Order in the Inactive Roster and cannot be added. ");
                        return;
                    }
                    else {
                        alert(item + " has already been dispatched on another Work Order.");
                        return;
                    }

                }
            }

            var uacs = {
                placementIds: placements,
                supportWorkOrderNumbers: this.state.workOrder.supportWorkOrders,
                workOrderId: this.props.params.id
            }

            var response = await this.PostAPI('WorkOrder/SaveUACFromSupport', uacs);
            if (response.status == 1) {
                window.location.href = '/workorder-detail/' + this.props.params.id;
            }
            else {
                alert(response.message);
            }
        }
    }

    cancelClick = () => {
        //console.log('cancel click')
        this.props.navigate('/workorders');
    }

    linkSupportClick = () => {
        //console.log('link sup click');
        this.props.navigate('/link-to-support/' + this.props.params.id);
    }

    createNewRegularClick = () => {
        //console.log('create reg click');
        this.props.navigate('/create-workorder/Regular/' + this.props.params.id);
        this.props.navigate(0);
    }

    planPickupDateUpdate = async (e, planDateId) => {
        e.preventDefault();

        //console.log(planDateId);

        // Pull the original
        var previous = this.state.workOrder.planPickupDates;

        // find the item
        var updateItem = previous.find((item) => item.id == planDateId);

        updateItem.statusCode = (updateItem.statusCode == 'ACTIVE' ? 'CANCELLED' : 'ACTIVE');

        console.log(previous);

        await this.setState(prevState => ({
            workOrder: {
                ...prevState.workOrder,
                planPickupDates: previous
            }
        }));
    }

    planDropoffDateUpdate = async (e, planDateId) => {
        e.preventDefault();

        //console.log(planDateId);

        // Pull the original
        var previous = this.state.workOrder.planDropoffDates;

        // find the item
        var updateItem = previous.find((item) => item.id == planDateId);

        updateItem.statusCode = (updateItem.statusCode == 'ACTIVE' ? 'CANCELLED' : 'ACTIVE');

        console.log(previous);

        await this.setState(prevState => ({
            workOrder: {
                ...prevState.workOrder,
                planDropoffDates: previous
            }
        }));
    }

    fieldDataChanged = async (e) => {
        //console.log('Field data changed...', e);
        //console.log(this.state);

        switch (e.dataField) {
            case "departureCityName":
                var city = this.state.departureCities.find(c => c.cityCode === e.value);
                if (city) {

                    await this.setState(prevState => ({
                        workOrder: {
                            ...prevState.workOrder,
                            departureStateName: city.stateCode,
                            departureStateFullName: city.stateName,
                            departureAOR: city.aorCode
                        }
                    }));
                }
                break;
            case "destinationCityName":
                var city = this.state.destinationCities.find(c => c.cityCode === e.value);
                if (city) {
                    await this.setState(prevState => ({
                        workOrder: {
                            ...prevState.workOrder,
                            destinationStateName: city.stateCode,
                            destinationStateFullName: city.stateName,
                            destinationAOR: city.aorCode
                        }
                    }));
                }
                break;
            case "planEndDate":
                this.updateProperty('workOrder', 'planEndDate', e.value);
                break;
            case "planPickupDate":
                this.updateProperty('workOrder', 'planPickupDate', e.value);
                break;
            case "planRemovalDate":
                this.updateProperty('workOrder', 'planRemovalDate', e.value);
                break;
            case "planStartDate":
                this.updateProperty('workOrder', 'planStartDate', e.value);
                break;
            case "includesMVMToMVMTransfer":
                this.updateProperty('workOrder', 'includesMVMToMVMTransfer', e.value);
                break;
            case "statusID":
                this.updateProperty('workOrder', 'statusID', e.value);
                break;
            case "trafficTypeCode":
                const filterMethods = this.state.transportMethods.filter(t => t.trafficTypeCode === e.value && t.isActive);
                await this.setState({
                    filteredTransportMethods: filterMethods
                });
                break;
        }
    }

    showChangeHistory = async (e) => {
        await this.setState({
            changeHistoryVisible: true
        });

        this.changeHistoryDataGrid.instance().refresh();
    }

    viewGroundTransitPlan = async (e) => {
        var workOrderGroundSegments = await this.FetchAPI('WorkOrder/GetWorkOrderTripSegments?workOrderId=' + this.state.workOrder.id);

        await this.setState({
            isViewingGroundTransitPlan: true,

            transitMetricsList: workOrderGroundSegments.transitMetricsList,
            transitLegsList: workOrderGroundSegments.transitLegsList
        });
    }

    hideChangeHistory = async () => {
        await this.setState({
            changeHistoryVisible: false
        });
    }

    hideGroundTransitPlan = async () => {
        await this.setState({
            isViewingGroundTransitPlan: false,

            transitMetricsList: [],
            transitLegsList: []
        });
    }

    changeLogDataSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            if (this.state.workOrder) {
                return FetchAPIPromise('WorkOrder/GetChangeHistory?workOrderId=' + this.state.workOrder.id);
            }
        }
    });

    showQAQCIssues = async (rulesResults) => {

        var allowContinue = true;

        if (rulesResults && rulesResults.find(element => element.ruleSeverity == 1)) {
            allowContinue = false;
        }

        await this.setState({
            QAQCIssuesVisible: true,
            QAQCIssuesList: rulesResults,
            QAQCAllowContinue: allowContinue
        });
    }

    hideQAQCIssues = async () => {
        await this.setState({
            QAQCIssuesVisible: false
        });
    }

    // TODO: Test that window does not even display if there are no issues at all
    continueAndBypassQAQCIssues = async () => {
        //console.log('Saving...');

        this.hideQAQCIssues();
        
        await this.setState({
            loading: true
        });

        try {

            var workOrder = this.state.workOrder;
            if (this.props.params.supportWorkOrderId) {
                workOrder.supportWorkOrderId = this.props.params.supportWorkOrderId;
            }

            // Find responsibleoffice selected value and set the offset
            var responsibleOffice = this.state.offices.find((item) => item.id == workOrder.responsibleOfficeId);

            if (responsibleOffice) {
                var officeOffset = moment(workOrder.planStartDate).tz(responsibleOffice.timeZone).format('Z');

                workOrder.planStartDate = moment.parseZone(moment.parseZone(workOrder.planStartDate).format('MM/DD/YYYY HH:mm') + ' ' + officeOffset).format('YYYY-MM-DDTHH:mmZ');
                workOrder.planEndDate = moment.parseZone(moment.parseZone(workOrder.planEndDate).format('MM/DD/YYYY HH:mm') + ' ' + officeOffset).format('YYYY-MM-DDTHH:mmZ');
            }

            console.log(workOrder);

            // When we save the new date data, need to convert back to the expected offset
            if (workOrder.planPickupDates) {
                workOrder.planPickupDates.map((date, index) => {
                    //console.log(moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ'));
                    date.planDate = moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ');
                });
            }

            if (workOrder.planDropoffDates) {
                workOrder.planDropoffDates.map((date, index) => {
                    console.log(date);
                    //console.log(moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ'));
                    date.planDate = moment.tz(date.planDate, date.timeZoneId).format('YYYY-MM-DDTHH:mmZ');
                });
            }

            // console.log('Data to save', workOrder);
            const result = await this.PostAPI('WorkOrder', { workOrder: workOrder, bypassQAQCWarnings: true });

            if (result.status == 1) {
                this.props.navigate('/workorder-detail/' + result.workOrderId);
                this.props.navigate(0);
            }
            else {
                alert(result.message);
            }
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    severityRender(cell) {
        //console.log('Severity Render', cell);

        var finalIcon;
        var finalAlt;

        if (cell.data.ruleSeverity == 1) {
            finalIcon = 'error_color.png';
            finalAlt = 'Critical';
        }
        else if (cell.data.ruleSeverity == 2) {
            finalIcon = 'warning_color.png';
            finalAlt = 'Warning';
        }
        else if (cell.data.ruleSeverity == 3) {
            finalIcon = 'information_color.png';
            finalAlt = 'Information';
        }
        else {
            finalIcon = 'warning_color.png';
            finalAlt = 'Warning';
        }

        return <img height="40" width="40" src={finalIcon} alt={finalAlt} />;
    }

    transferLocationChanged = (e) => {
        // console.log('Got here', e);

        if (e.selectedItem != null) {
            this.updateProperty('workOrder', 'mvmToMVMTransferLocation', e.selectedItem.name + ' ' + e.selectedItem.fullAddress);
        }
    }

    validateNumber = (e) => {

        console.log(e);
        console.log(this.state.workOrder);
        var pattern = '^[a-zA-Z0-9\\s\\:]{1,}?(\\d{1,}) [a-zA-Z0-9\\s]+(\\,)? [a-zA-Z]+(\\,)? [A-Z]{2} [0-9]{5,6}$';

        return this.state.workOrder.statusID == 0 || (e.value && e.value.match(pattern));
    }

    render() {
        var pickupItems = [];
        var dropoffItems = [];

        var autocompleteitems = ['Hello', 'goodbye'];

        if (this.state.workOrder.planPickupDates) {
            this.state.workOrder.planPickupDates.map((date, index) => {
                var timezoneAbbr = moment.tz(date.planDate, date.timeZoneId).format('z');
                //console.log('Pickup timezone', timezoneAbbr);

                pickupItems.push(
                    <Item cssClass={date.statusCode == 'ACTIVE' ? `` : `lineThroughText`} colSpan={3} dataField={`planPickupDates[${index}].planDate`} editorType="dxDateBox" editorOptions={{ width: '190px', minWidth: '190px', displayFormat: `MM/dd/yyyy HH:mm '${timezoneAbbr}'`, type: 'datetime', readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), min: this.state.workOrder.planStartDate, max: this.state.workOrder.planEndDate }}>
                        <Label text={`${date.locationName} Pickup Time`} />
                        <RequiredRule />
                    </Item>
                );

                if (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()) {
                    pickupItems.push(<EmptyItem />);
                }
                else {
                    pickupItems.push(<Item><a href="#" style={{ verticalAlign: 'center' }} onClick={(e) => this.planPickupDateUpdate(e, date.id)}>{date.statusCode == 'ACTIVE' ? `Mark Canceled` : `Mark Active`}</a></Item >);
                }
            });
        }

        if (this.state.workOrder.planDropoffDates) {
            this.state.workOrder.planDropoffDates.map((date, index) => {
                var timezoneAbbr = moment.tz(date.planDate, date.timeZoneId).format('z');
                //console.log('Dropoff timezone', timezoneAbbr);

                dropoffItems.push(
                    <Item cssClass={date.statusCode == 'ACTIVE' ? `` : `lineThroughText`} colSpan={3} dataField={`planDropoffDates[${index}].planDate`} editorType="dxDateBox" editorOptions={{ width: '190px', minWidth: '190px', displayFormat: `MM/dd/yyyy HH:mm '${timezoneAbbr}'`, type: 'datetime', readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), min: this.state.workOrder.planStartDate, max: this.state.workOrder.planEndDate }}>
                        <Label text={`${date.locationName} Dropoff Time`} />
                        <RequiredRule />
                    </Item>
                );

                if (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()) {
                    dropoffItems.push(<EmptyItem />);
                }
                else {
                    dropoffItems.push(<Item><a href="#" onClick={(e) => this.planDropoffDateUpdate(e, date.id)}>{date.statusCode == 'ACTIVE' ? `Mark Canceled` : `Mark Active`}</a></Item>);
                }
            });
        }

        return (
            <div className="container specialForm">
                <h1>Work Order Details</h1>

                <form action="your-action" onSubmit={this.saveWorkOrder}>
                    <Form formData={this.state.workOrder} onFieldDataChanged={this.fieldDataChanged} colCount={2}>
                        <Item itemType="group" colCount={3} alignItemLabels={false} colSpan={2}>
                            <Item dataField="workOrderName" editorOptions={{ readOnly: true }} >
                                <Label text="Work Order Name" />
                            </Item>
                            <Item dataField="workOrderNumber" editorOptions={{ readOnly: true }} >
                                <Label text="Work Order Number" />
                            </Item>
                            <Item dataField="workOrderType" editorOptions={{ readOnly: true }}>
                                <Label text="Work Order Type" />
                            </Item>
                            <Item itemType="empty" colSpan={3} />
                        </Item>

                        <Item dataField="planStartDate" editorType="dxDateBox" editorOptions={{ displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime', readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), max: this.state.workOrder.planEndDate }}>
                            <Label text="Plan Start Date" />
                            <RequiredRule />
                        </Item>
                        <Item dataField="planEndDate" editorType="dxDateBox" editorOptions={{ displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime', readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), min: this.state.workOrder.planStartDate }}>
                            <Label text="Plan End Date" />
                            <RequiredRule />
                        </Item>

                        {this.props.params.id != undefined && this.state.workOrder.planPickupDates && this.state.workOrder.planPickupDates.length > 0 &&
                            <Item id="pickupItems" itemType="group" caption="Pickup Times" alignItemLabels={false} colCount={4}>
                                {pickupItems}
                            </Item>
                        }

                        {this.props.params.id != undefined && this.state.workOrder.planDropoffDates && this.state.workOrder.planDropoffDates.length > 0 &&
                            <Item itemType="group" caption="Dropoff/Removal Times" alignItemLabel={false} colCount={4}>
                                {dropoffItems}
                            </Item>
                        }

                        <Item dataField="includesMVMToMVMTransfer" editorType="dxCheckBox" editorOptions={{ readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()) }}>
                            <Label text="Includes MVM to MVM Transfer" />
                        </Item>

                        <EmptyItem />

                        {this.state.workOrder.includesMVMToMVMTransfer &&
                            <Item dataField="mvmToMVMTransferDate" editorType="dxDateBox" editorOptions={{ displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime', readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), min: this.state.workOrder.planStartDate, max: this.state.workOrder.planEndDate }}>
                                <Label text="Transfer Date" />
                                <RequiredRule />
                            </Item>
                        }

                        {this.state.workOrder.includesMVMToMVMTransfer &&
                            <Item dataField="mvmToMVMTransferLocation" editorType="dxAutocomplete" editorOptions={{ onSelectionChanged: this.transferLocationChanged, dataSource: this.state.mvmTransferAutocompleteItems, displayExpr: 'name', valueExpr: 'name', readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), showClearButton: true, openOnFieldClick: true, minSearchLength: 0 }}>
                                <Label text="Transfer Location" />
                                <RequiredRule />
                                <StringLengthRule max={255} />
                                <CustomRule
                                    validationCallback={this.validateNumber}
                                    message="Transfer Location requires a full street address"
                                />
                            </Item>
                        }

                        <Item itemType="group" caption="Trip Logistics" colCount={2} colSpan={2}>
                            <Item dataField="transportUnit" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, dataSource: this.state.transportUnits, displayExpr: 'name', valueExpr: 'code', readOnly: this.state.workOrder.areSupportFieldsDisabled || this.IsReadOnly() }}>
                                <Label text="Transport Unit" />
                                <RequiredRule />
                            </Item>
                            {(this.state.workOrder.transportUnit == "Family") ?
                                (<Item dataField="familyUnitCount" editorType="dxNumberBox" editorOptions={{ readOnly: true }}>
                                    <Label text="No. of Family Units" />
                                </Item>) : (<Item itemType="empty" />)
                            }

                            <Item dataField="trafficTypeCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), dataSource: this.state.trafficTypes, defaultValue: this.state.workOrder.trafficTypeCode, displayExpr: 'name', valueExpr: 'code', id: "stuff" }}>
                                <Label text="Transport Type" />
                                <RequiredRule />
                            </Item>
                            <Item dataField="transportMethodCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, dataSource: this.state.filteredTransportMethods, displayExpr: 'name', valueExpr: 'code', readOnly:(this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()) }}>
                                <Label text="Transport Method" />
                                <RequiredRule />
                            </Item>

                            {(this.state.workOrder.trafficTypeCode == 'Ground') &&
                                <EmptyItem />
                            }

                            {(this.state.workOrder.trafficTypeCode == 'Ground') &&
                                <Item itemType="button" colSpan={1} buttonOptions={{ text: 'View Ground Transit Plan', stylingMode: 'contained', onClick: this.viewGroundTransitPlan }} />
                            }

                            <Item dataField="departureCityName" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), dataSource: this.state.departureCities, valueExpr: 'cityCode', displayExpr: 'cityName' }} >
                                <Label text="Departure City (Airport)" />
                                <RequiredRule />
                            </Item>

                            <Item dataField="destinationCityName" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), dataSource: this.state.destinationCities, valueExpr: 'cityCode', displayExpr: 'cityName' }} >
                                <Label text="Destination City (Airport)" />
                                <RequiredRule />
                            </Item>

                            {(this.state.workOrder.workOrderType == "Regular") ?
                                (<Item dataField="departureStateFullName" editorOptions={{ readOnly: true, value: this.state.workOrder.departureStateFullName }}>
                                    <Label text="Departure State" />
                                </Item>) : (<Item itemType="empty" visible={false} />)
                            }

                            {(this.state.workOrder.workOrderType == "Regular") ?
                                (<Item dataField="destinationStateFullName" editorOptions={{ readOnly: true, value: this.state.workOrder.destinationStateFullName }}>
                                    <Label text="Destination State" />
                                </Item>) : (<Item itemType="empty" visible={false} />)
                            }

                            <Item dataField="departureAOR" editorOptions={{ readOnly: true, value: this.state.workOrder.departureAOR }}>
                                <Label text="Departure AOR" />
                            </Item>
                            <Item dataField="destinationAOR" editorOptions={{ readOnly: true, value: this.state.workOrder.destinationAOR }}>
                                <Label text="Destination AOR" />
                            </Item>
                        </Item>

                        <Item colSpan={2} dataField="woNotes" editorType="dxTextArea" editorOptions={{ readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), autoResizeEnabled: true }} >
                            <Label text="Notes" />
                        </Item>

                        <EmptyItem />
                        <Item dataField="statusID" editorType="dxSelectBox" editorOptions={{ readOnly: this.IsReadOnly(), searchEnabled: true, dataSource: this.state.statuses, defaultValue: this.state.workOrder.statusID, displayExpr: 'name', valueExpr: 'id' }}>
                            <Label text="Status" />
                        </Item>

                        <Item dataField="responsibleOfficeId" editorType="dxSelectBox" editorOptions={{ showClearButton: true, searchEnabled: true, dataSource: this.state.filteredOffices, displayExpr: 'name', valueExpr: 'id', readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly())}}>
                            <Label text="Responsible Office" />
                            <RequiredRule />
                        </Item>
                        <Item itemType="empty" />

                        <Item dataField="reviewStatusCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, dataSource: this.state.reviewStatuses, displayExpr: 'name', valueExpr: 'code', readOnly: !this.state.isActiveStatus || this.IsReadOnly() }}
                            visible={this.props.params.id != undefined && this.state.workOrder.statusID > 0}>
                            <Label text="Review Status" />
                        </Item>
                        <Item visible={this.props.params.id != undefined && this.state.workOrder.statusID > 0}>
                            {this.state.workOrder.reviewStatusCode == 'REVIEWED' ? `Reviewed by ${this.state.workOrder.lastReviewedByDisplay} at ${moment(new Date(this.state.workOrder.reviewDate)).format('MM/DD/yyyy HH:mm')}` : ''}
                        </Item>

                        {(this.state.workOrder.workOrderType == "Regular" && this.state.workOrder.hasSupportWorkOrders) ?
                            (<Item dataField="supportWorkOrders" editorOptions={{ readOnly: true }} >
                                <Label text="Support Work Order" />
                            </Item>) : (<Item itemType="empty" visible={false} />)
                        }
                        {((this.state.isOutOfRatio || this.state.workOrder.outOfComplianceApprovalDate || this.state.workOrder.outOfComplianceApprovalNotes)) ?
                            (<Item itemType="group" caption="Unit Chief or Section Chief Approval">
                                <Item>
                                    <p>This Work Order’s Employee ratio requires UC or SC approval. Please provide the UC or SC approval information below.</p>
                                </Item>

                                <Item dataField="outOfComplianceApprovalDate" editorType="dxDateBox" isRequired={this.state.workOrder.statusID == 1 && this.state.isOutOfRatio} editorOptions={{
                                    displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime', readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly())
                                }}>
                                    <Label text="Approval Date/Time" />
                                </Item>

                                <Item dataField="outOfComplianceApprovalNotes" editorType="dxTextArea" isRequired={this.state.workOrder.statusID == 1 && this.state.isOutOfRatio} editorOptions={{ readOnly: (this.state.workOrder.isWorkOrderDisabled || this.IsReadOnly()), autoResizeEnabled: true }}>
                                    <Label text="UC or SC Approval Notes" />
                                </Item>
                            </Item>)
                            : (<Item colSpan={2} />)
                        }

                        <Item itemType="group" colCount={5} colSpan={2}>
                        </Item>

                        <Item itemType="group" colCount={5} colSpan={2}>
                            <Item itemType="group" colSpan={1}>
                                {(this.state.workOrder.workOrderType == "Support" && this.props.params.id != undefined && !this.state.workOrder.isWorkOrderDisabled) ?
                                    (<Item itemType="button" buttonOptions={{ text: 'Create New Regular Work Order', width: 'auto', type: 'danger', onClick: this.createNewRegularClick }} />)
                                    : (<Item itemType="empty" visible={false} />)
                                }
                                {(this.state.workOrder.workOrderType == "Regular" && !this.state.supportWorkOrderId && this.props.params.id != undefined && !this.state.workOrder.isWorkOrderDisabled) ?
                                    (<Item itemType="button" disabled={this.IsReadOnly()} buttonOptions={{ text: 'Link to Support Work Order', width: 'auto', type: 'danger', onClick: this.linkSupportClick }} />)
                                    : (<Item itemType="empty" visible={false} />)
                                }
                            </Item>
                            <Item itemType="group" colSpan={4} colCount={6}>
                                <Item colSpan={1}>
                                    {this.props.params.id != undefined &&
                                        <Link to={`/workorder-resources/${this.props.params.id}`}>
                                            <Button text="Resources" />
                                        </Link>
                                    }
                                </Item>

                                <Item colSpan={1}>
                                    {this.props.params.id != undefined &&
                                        <Link to={`/workorder-milestones/${this.props.params.id}`}>
                                            <Button text="Milestones" />
                                        </Link>
                                    }
                                </Item>

                                <Item visible={this.props.params.id != undefined} itemType="button" buttonOptions={{ text: 'Change History', onClick: this.showChangeHistory }} />

                                {(this.props.params.id != undefined && this.state.workOrder.statusID == 0) ?
                                    (<Item itemType="button" colSpan={1} disabled={this.IsReadOnly()} buttonOptions={{ text: 'Delete', onClick: this.deleteWorkOrder }} />)
                                    : (<Item colSpan={1} />)
                                }

                                <Item itemType="button" colSpan={1} buttonOptions={{ text: 'Cancel', stylingMode: 'contained', onClick: this.cancelClick }} />

                                <Item disabled={this.IsReadOnly()} itemType="button" colSpan={1} buttonOptions={{ text: 'Save', type: 'success', width: 'auto', useSubmitBehavior: true }} />
                            </Item>
                        </Item>
                    </Form>
                    <hr />
                    <LoadPanel
                        visible={this.state.loading} />
                </form>

                {this.state.workOrder.workOrderType == "Support" && this.props.params.id != undefined &&
                    <React.Fragment>
                        <h1>Linked Regular Work Orders</h1>
                        <DataGrid dataSource={this.state.linkedRegularWorkOrders} showBorders={true}
                            allowColumnResizing={true}
                            ref={ref => this.linkedRegularWorkOrders = ref}>
                            <FilterRow visible={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                                showInfo={true} visible={true} />
                            <Column dataField="workOrderNumber" />
                            <Column dataField="workOrderName" cellRender={function (options) { return (<Link to={`/workorder-detail/${options.row.data.id}`} onClick={() => window.location.href = '/workorder-detail/' + options.row.data.id}>{options.value}</Link>); }} />
                            <Column caption="Unlink MSWO And RWO" type="buttons">
                                <GridButton
                                    text="Unlink"
                                    onClick={this.unlinkRWO}
                                    visible={!this.unlinkComponentIsReadOnly()}
                                />
                            </Column>
                        </DataGrid>
                    </React.Fragment>
                }
                <hr />
                {this.state.workOrder.workOrderType == "Support" && this.props.params.id != undefined &&
                    <React.Fragment>
                        <h1>MSWO -> RWO Coverage</h1>
                        <WorkOrderDetailsMSWOCoverageContainer workOrder={this.state.workOrder} />
                    </React.Fragment>
                }
                {this.state.workOrder.supportWorkOrders != "" && this.state.workOrder.transportUnit == "UC" && this.props.params.id != undefined &&
                    <React.Fragment>
                        <legend style={{ color: 'darkgreen' }}>Add Placements from Support WorkOrder</legend>
                        <DataGrid dataSource={this.state.supportWorkOrderUACs} allowColumnResizing={true}
                            showBorders={true} onInitialized={this.onUCInitialized} keyExpr="id"
                        selection={{ mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always', deferred: true }}
                        ref={ref => this.addUACdataGrid = ref}>
                            <FilterRow visible={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                            showInfo={true} visible={true} />

                        <Column caption="Name" dataField="placementShortName" />
                        <Column caption="Pickup Location" dataField="pickupLocationName" />
                        <Column caption="Destination" dataField="destinationName" />

                        <MasterDetail enabled={true} autoExpandAll={true} component={PlacementDetail} />
                    </DataGrid>
                    {!this.state.workOrder.isWorkOrderDisabled &&
                            <div style={{ float: 'right' }}>
                                <Button text="Save UC" onClick={this.saveUACs} />
                                <br />
                            </div>
                        }
                    </React.Fragment>
                }
                {this.state.workOrder.supportWorkOrders != "" && this.state.workOrder.transportUnit == 'Family' && this.props.params.id != undefined &&
                    <React.Fragment>
                        <legend style={{ color: 'darkgreen' }}>Add Placements from Support WorkOrder</legend>
                        <DataGrid dataSource={this.state.supportWorkOrderUACs} allowColumnResizing={true}
                            showBorders={true} onInitialized={this.onFAMUInitialized} keyExpr="id"
                            selection={{ mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always', deferred: true }}
                            ref={ref => this.addFAMUdataGrid = ref}>
                            <FilterRow visible={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                                showInfo={true} visible={true} />

                            <Column caption="Name" dataField="placementShortName" />
                            <Column caption="Pickup Location" dataField="pickupLocationName" />
                            <Column caption="Destination" dataField="destinationName" />

                            <MasterDetail enabled={true} autoExpandAll={true} component={PlacementDetail} />
                        </DataGrid>
                        {!this.state.workOrder.isWorkOrderDisabled &&
                            <div style={{ float: 'right' }}>
                                <Button text="Save FAMU" onClick={this.saveFAMUs} />
                                <br />
                            </div>
                        }
                        <br />

                        <br />
                    </React.Fragment>
                }
                <div style={{ marginBottom: 100 }} />

                <Popup visible={this.state.isViewingGroundTransitPlan} onHiding={this.hideGroundTransitPlan} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Ground Transit Metrics" width={900} height={500}>
                    <ScrollView>
                        <p>Distance Metrics</p>
                        <DataGrid dataSource={this.state.transitMetricsList} showBorders={true} allowColumnResizing={true}>
                        
                            <Column dataField="origin" caption="Origin" />
                            <Column dataField="destination" caption="Destination" />
                            <Column dataField="straightLineDistance" caption="Distance" />
                        </DataGrid>
                        <br />
                        <p>Ground Transit Plan</p>
                        <DataGrid dataSource={this.state.transitLegsList} showBorders={true} allowColumnResizing={true}>
                            <Column dataField="origin" caption="Origin" />
                            <Column dataField="estimatedDepartureTime" caption="Estimated DepartureTime" dataType="datetime" format="MM/dd/yyyy HH:mm"  />
                            <Column dataField="destination" caption="Destination" />
                            <Column dataField="estimatedArrivalTime" caption="Estimated Arrival Time" dataType="datetime" format="MM/dd/yyyy HH:mm" />
                            <Column dataField="drivingDistance" caption="Driving Distance" />
                            <Column dataField="estimatedTransitTime" caption="Estimate Transit Time" />
                        </DataGrid>
                    </ScrollView>
                </Popup>

                <Popup visible={this.state.changeHistoryVisible} onHiding={this.hideChangeHistory} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Change History" width={900} height={500}>

                    <DataGrid dataSource={this.changeLogDataSource} showBorders={true} allowColumnResizing={true}
                        ref={ref => this.changeHistoryDataGrid = ref}>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={5} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} visible={true} />

                        <Column dataField="updateType" />
                        <Column dataField="updateField" caption="Field" />
                        <Column dataField="oldValue" />
                        <Column dataField="newValue" />
                        <Column dataField="display" caption="Change User" />
                        <Column dataField="createDate" caption="Change Date" dataType="datetime" format="MM/dd/yyyy HH:mm" />
                    </DataGrid>
                </Popup>

                <Popup visible={this.state.QAQCIssuesVisible} onHiding={this.hideQAQCIssues} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Work Order QA/QC Items" width={900} height={500}>

                    <DataGrid dataSource={this.state.QAQCIssuesList} showBorders={true} allowColumnResizing={true}
                        ref={ref => this.qaQCIssuesDataGrid = ref}>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={5} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} visible={true} />

                        <Column width={65} caption="Severity" cellRender={this.severityRender} />
                        <Column dataField="ruleDescription" caption="Item Description" />
                    </DataGrid>

                    <div className="d-flex justify-content-end">
                        <Button text="Continue" onClick={this.continueAndBypassQAQCIssues} visible={this.state.QAQCAllowContinue} />
                        &nbsp;
                        <Button text="Cancel" onClick={this.hideQAQCIssues} />
                    </div>
                </Popup>
            </div>
        );
    }
}

class PlacementDetail extends React.Component {
    constructor(props) {
        console.log('Detail Props', props);
        super(props);
        this.dataSource = props.data.row.data.customerList;
    }
    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true}>
                <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                <Column caption="First Name" dataField="firstName" />
                <Column caption="Middle Name" dataField="middleName" />
                <Column caption="Last Name" dataField="lastName" />
                <Column caption="Gender" dataField="gender" />
            </DataGrid>
        );
    }
}

export default withParams(WorkOrderDetailContainer);