import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import BaseComponent, { FetchAPIPromise, withParams } from '../BaseComponent';
import CustomStore from 'devextreme/data/custom_store';
import ArrayStore from 'devextreme/data/array_store';
import DataGrid, { Sorting, FilterRow, Column, Pager, Paging, Button as GridButton, Selection, MasterDetail } from 'devextreme-react/data-grid';
import RadioGroup from 'devextreme-react/radio-group';
import * as moment from 'moment';
import Gantt, { Tasks, StripLine, Column as GanttColumn, Editing, Toolbar, Item, Validation, FilterRow as GanttFilterRow } from 'devextreme-react/gantt';
import Form, { Item as FormItem, Label, RequiredRule, StringLengthRule, ButtonItem } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { alert, confirm } from 'devextreme/ui/dialog';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
// Below is needed by the Form control to "import" the appropriate control
import TextArea from 'devextreme-react/text-area';
import CheckBox from 'devextreme-react/check-box';
import Accordion, { Item as AccordionItem } from 'devextreme-react/accordion';
import Sortable from 'devextreme-react/sortable';
import { flushSync } from 'react-dom'; // Note: react-dom, not react
import parse from 'html-react-parser';
import { msalAuth } from '../../msal/MsalAuthProvider';

// TODO: Canceled work orders


// ACTUAL REVIEWED HOURS: #70c92f
// THIS WORK ORDER: #e97f02

// TODO
// 2) Test add additional shift
// 3) Test deletes
// 4) Test updates

// TODO:
// Show Overtime Approved Indicator, maybe?

// TODO:
//   1) DONE Check whether authority code requires approval
//   2) If requires approval, for any new row that would put TS into overtime, display that they need approval, option to request

//   4) What if we start out needing approval then resize and/or move to avoid it?
//   5) Different color for tasks needing approval?

// TODO Validation:
//   1) Shifts for the same work order cannot overlap


const renderGridCell = (data) => {
    //console.log(data)
    if (data.data.employeeId == data.data.id) {
        return <b>{data.text}</b>;
    }
    else {
        return data.text;
    }
}

const OTApprovalDetailItemRender = (data) => {
    //console.log(data);

    var dataArray = [];
    dataArray.push(data.data);

    return (
        // TODO: Should we show the estimated total scheduled hours here/as well?
        <DataGrid dataSource={dataArray}>
            <Column caption="Status" dataField="statusName" />
            <Column caption="Shift Begin" dataField="shiftBegin" dataType="datetime" format="MM/dd/yy, HH:mm" />
            <Column caption="Shift End" dataField="shiftEnd" dataType="datetime" format="MM/dd/yy, HH:mm" />
            <Column caption="Update Manager" dataField="approvalUser" />
            <Column caption="Update Date" dataField="approvalDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
            <Column caption="Comments" dataField="overtimeNote" />
        </DataGrid>
    );
}

const ResponseDetailItemRender = (data) => {
    // console.log('Detail Render', data);

    return (
        <DataGrid dataSource={data.data.responses}>
            <Column caption="Delivery Status" dataField="status" />
            <Column caption="Requested Date" dataField="requestedDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
            <Column caption="Date Sent" dataField="statusDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
            <Column caption="Response" dataField="response" />
            <Column caption="Response Date" dataField="responseDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
        </DataGrid>
    );
}

class WorkOrderScheduleContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.previewEmployee = this.previewEmployee.bind(this);

        this.state = {
            workOrderId: '',
            workOrder: {},
            weeks: [],
            selectedSchedulePeriod: {},
            commentReasons: [],
            availabilityMode: 0,
            includeContractBreak: false,
            excludeOverlappingShifts: true,
            excludeOverlappingLeave: true,

            isViewingResponses: false,
            responseFilter: true,

            isViewingOTApprovals: false,

            isViewingConfirmationErrors: false,
            currentConfirmationErrors: [],

            isShowingManagerOverridePopup: false,
            managerOverrideConfirmation: {
                reason: '',
                approverEmployeeNo: '',
                passPin: ''
            },

            userIsSysAdmin: false,

            enforceContractTripBreakCompliance: false,
            tripBreakHoursLimit: 0,
            overtimeSchedulingThreshold: 0,
            enforceOvertimeSchedulingHardLimit: false,
            overtimeSchedulingHardLimit: 0,

            isConfirmingAvailability: false,
            availabilityConfirmationKeys: [],
            availabilityConfirmationMessage: 'Are you available to work beginning ',
            availabilityBeginDates: [],
            availabilityConfirmation: {
                availabilityDate: ''
            },

            availability: [],
            scheduleBeginDate: '',
            scheduleEndDate: '',
            assignedShifts: [],
            standbySchedules: [],

            isShowingScheduleValidationPopup: false,
            scheduleValidationMessage: '',
            scheduleOverridesList: [],

            isViewingCommentsLog: false,
            commentsForm: {
                employeeId: '',
                reasonId: '',
                comment: ''
            }
        };
    }

    shiftDataSource = new ArrayStore({
        key: 'id'
        // Other ArrayStore options go here
    });

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);

        const { id } = this.props.params

        await this.setState({ workOrderId: id });

        this.fetchData();

        this.standbyScheduleAccordion.instance().collapseItem(0);
    }

    GetComponentPageName = () => {
        return (this.state.workOrder.workOrderNumber ? ("#" + this.state.workOrder.workOrderNumber + " WO Schedule") : 'Work Order Schedule');
    }

    GetDropDownData = async () => {
        var commentReasonData = await this.FetchAPI('CommentReason');

        //console.log(commentReasonData);

        this.setState({
            commentReasons: commentReasonData
        });
    }

    GetData = async () => {

        var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

        // TODO: Change this to supervisor admin privileges/manager override eventually
        var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;
        //console.log('User ' + (isUserSystemAdmin ? 'IS' : 'IS NOT') + ' a Sys Admin');

        var workOrderData = await this.FetchAPI('WorkOrder/' + this.state.workOrderId);

        // ABG: Not sure why I have to do this, but devextreme will not handle offset dates properly
        workOrderData.planStartDate = moment.parseZone(workOrderData.planStartDate).format('yyyy-MM-DDTHH:mm:ss');
        workOrderData.planEndDate = moment.parseZone(workOrderData.planEndDate).format('yyyy-MM-DDTHH:mm:ss');

        //console.log('Work Order', workOrderData);

        // Order here is
        // 1) Split the work order duration into 1 or more scheduling periods
        //      a) TODO: What about work orders that go across more than one scheduling period?
        // 2) IF the current date falls within one of those periods, THEN default to that period
        //    ELSE, default to the last period
        // 3) Then, based on the period selected, pull the available employees list based on the radio button selection, either full duration, or partial duration
        // 4) ...
        var weekData = [];
        var initialWeekSelection = null;

        // TODO: What we need to do is check the current date.
        // If that date is 
        var planStartDate = moment(workOrderData.planStartDate);
        var planEndDate = moment(workOrderData.planEndDate);

        var startDatePeriodBeginDate = planStartDate.clone().startOf('week');
        var endDatePeriodBeginDate = planEndDate.clone().startOf('week');

        var fullScheduleBeginDate = startDatePeriodBeginDate;
        var fullScheduleEndDate = null;

        //console.log(startDatePeriodBeginDate);
        //console.log(endDatePeriodBeginDate);

        if (startDatePeriodBeginDate.isSame(endDatePeriodBeginDate)) {
            //console.log('Work Order begins and ends within same scheduling period');

            // Add the one scheduling period to the list
            var periodEndDate = planEndDate.clone().endOf('week');

            initialWeekSelection = {
                display: startDatePeriodBeginDate.format("MM/DD/YYYY") + " - " + periodEndDate.format("MM/DD/YYYY"),
                fromDate: startDatePeriodBeginDate.format("MM/DD/YYYY HH:mm"),
                toDate: periodEndDate.format("MM/DD/YYYY HH:mm")
            };

            weekData.push(initialWeekSelection);

            fullScheduleEndDate = periodEndDate;

            console.log('Full end date', fullScheduleEndDate);
        }
        else {
            //console.log('Work Order begins and ends within DIFFERENT scheduling periods');

            // Add each schedule period to the dropdown
            // Pull the actual week end for the start week
            // ABG This could probably be more elegant, but I also like that it's a simple if/else to follow for the code
            // Set initial selection
            while (!startDatePeriodBeginDate.isSame(endDatePeriodBeginDate)) {
                var actualPeriodEndDate = startDatePeriodBeginDate.clone().endOf('week');

                var currentWeek = {
                    display: startDatePeriodBeginDate.format("MM/DD/YYYY") + " - " + actualPeriodEndDate.format("MM/DD/YYYY"),
                    fromDate: startDatePeriodBeginDate.format("MM/DD/YYYY HH:mm"),
                    toDate: actualPeriodEndDate.format("MM/DD/YYYY HH:mm")
                };

                weekData.push(currentWeek);

                if (moment().isBetween(startDatePeriodBeginDate, actualPeriodEndDate)) {
                    initialWeekSelection = currentWeek;
                }

                startDatePeriodBeginDate = actualPeriodEndDate;
                startDatePeriodBeginDate.add(1, 'days').startOf('week');
            }

            var periodEndDate = planEndDate.clone().endOf('week')

            // Add the last week
            var lastWeek = {
                display: startDatePeriodBeginDate.format("MM/DD/YYYY") + " - " + periodEndDate.format("MM/DD/YYYY"),
                fromDate: startDatePeriodBeginDate.format("MM/DD/YYYY HH:mm"),
                toDate: periodEndDate.format("MM/DD/YYYY HH:mm")
            };

            weekData.push(lastWeek);

            if (!initialWeekSelection) {
                //console.log('No weeks in the duration are the current week, so just select the last one');

                initialWeekSelection = lastWeek;
            }

            fullScheduleEndDate = periodEndDate;
        }

        // Calculate Availability Begin Date(s)
        var beginDates = [];

        while (planStartDate.isSameOrBefore(planEndDate)) {
            beginDates.push(planStartDate.format("MM/DD/YYYY"));

            planStartDate.add(1, 'days');
        }

        //console.log('Formatted', fullScheduleBeginDate.format("MM/DD/YYYY HH:mm"));
        //console.log('Formatted', fullScheduleEndDate.format("MM/DD/YYYY HH:mm"));

        flushSync(() => {
            this.setState({
                workOrder: workOrderData,
                weeks: weekData,
                selectedSchedulePeriod: initialWeekSelection,

                userIsSysAdmin: isUserSystemAdmin,

                enforceContractTripBreakCompliance: workOrderData.enforceContractTripBreakCompliance,
                tripBreakHoursLimit: workOrderData.tripBreakHoursLimit,
                enforceOvertimeSchedulingHardLimit: workOrderData.enforceOvertimeSchedulingHardLimit,
                overtimeSchedulingHardLimit: workOrderData.overtimeSchedulingHardLimit,

                includeContractBreak: workOrderData.enforceContractTripBreakCompliance,
                overtimeSchedulingThreshold: workOrderData.overtimeSchedulingThreshold,

                availabilityBeginDates: beginDates,
                availabilityConfirmation: {
                    availabilityDate: beginDates[0]
                },

                scheduleBeginDate: fullScheduleBeginDate.format("MM/DD/YYYY HH:mm"),
                scheduleEndDate: fullScheduleEndDate.format("MM/DD/YYYY HH:mm")
            });
        });

        //console.log(this.state.scheduleBeginDate);
        //console.log(this.state.scheduleEndDate);

        //console.log(this.customDataSource);
        this.dataGrid.instance().refresh();

        //console.log(initialWeekSelection);

        var queryString = 'workOrderId=' + this.state.workOrderId;
        queryString += '&beginDate=' + this.state.scheduleBeginDate;
        queryString += '&endDate=' + this.state.scheduleEndDate;

        var shiftData = await this.FetchAPI('Schedule/GetAssignedShifts?' + queryString);

        //console.log('Pre process', shiftData);

        var processedShiftData = this.processInitialShiftData(shiftData);

        var standbyScheduleData = await this.FetchAPI('Schedule/GetStandbySchedules?periodStartDate=' + this.state.scheduleBeginDate + '&periodEndDate=' + this.state.scheduleEndDate + '&officeId=-1');

        //console.log('Standby Schedules', standbyScheduleData);

        for (var i = 0; i < standbyScheduleData.length; i++) {
            // Consolidate
            var consolidatedShifts = this.consolidateShiftAvailability(standbyScheduleData[i].availability);

            //console.log('Consolidated', consolidatedShifts);

            //consolidatedShifts.map((display, displayIndex) => {
            //    console.log(display.beginDate.format('MM/DD/YYYY HH:mm') + ' - ' + display.endDate.format('MM/DD/YYYY HH:mm'));
            //});

            standbyScheduleData[i].availableShifts = consolidatedShifts;
        }

        var reserveScheduleDays = [];

        //var from_date = moment(this.state.filters.fromDate);

        var currentData = moment(workOrderData.planStartDate);

        //console.log(currentData.format('MM/DD/YYYY'));
        //console.log(planEndDate.format('MM/DD/YYYY'));
        //console.log(currentData.isSameOrBefore(planEndDate));

        while (currentData.isSameOrBefore(planEndDate)) {
            //console.log('Loop');

            var reserveDay = {
                display: currentData.format('MM/DD/YYYY'),
                date: currentData,
                schedules: standbyScheduleData.filter((task) => moment(task.planStartDate).get('date') === currentData.get('date'))
            };

            reserveScheduleDays.push(reserveDay);

            var currentData = currentData.clone().add(1, 'days');
        }

        //for (var i = 0; i < 7; i++) {
        //    var currentData = from_date.clone().add(i, 'days');

        //    var reserveDay = {
        //        display: currentData.format('MM/DD/YYYY'),
        //        date: currentData,
        //        schedules: standbyScheduleData.filter((task) => moment(task.planStartDate).get('date') === currentData.get('date'))
        //    };

        //    reserveScheduleDays.push(reserveDay);
        //}

        //console.log('Reserve Days', reserveScheduleDays);

        //console.log('Post initial process', processedShiftData);

        // TODO: Technically, we should never need this, but just in case...
        await this.checkForOTShiftApprovals(processedShiftData);

        flushSync(() => {
            this.setState({
                assignedShifts: processedShiftData,
                standbySchedules: reserveScheduleDays
            });
        });

        //console.log(this.shiftDataSource);

        this.updateShiftData();
    }

    checkForOTShiftApprovals = async (processedShiftData) => {
        // Look for overtime issues
        var employeeShiftGroups = _.groupBy(processedShiftData.filter((item) => item.parentId == item.employeeId && item.workOrderID), 'employeeId');
        //console.log(employeeShiftGroups);

        for (const property in employeeShiftGroups) {
            var scheduledTotal = 0;

            var shifts = employeeShiftGroups[property];

            //console.log(shifts);

            // Sort the shifts/hours
            var sortedShifts = shifts.sort((a, b) => moment(a.planStartDate).valueOf() - moment(b.planStartDate).valueOf());

            var schedulePeriodStartDate = moment(this.state.selectedSchedulePeriod.fromDate);
            var schedulePeriodEndDate = moment(this.state.selectedSchedulePeriod.toDate);

            for (var i = 0; i < sortedShifts.length; i++) {
                var shift = sortedShifts[i];
                //console.log(property, shift);

                var shiftStartDate = moment(shift.planStartDate);
                var shiftEndDate = moment(shift.planEndDate);

                var begin = shiftStartDate.isBefore(schedulePeriodStartDate) ? schedulePeriodStartDate : shiftStartDate;
                var end = shiftEndDate.isAfter(schedulePeriodEndDate) ? schedulePeriodEndDate : shiftEndDate;

                var shiftHours = (moment(end).diff(moment(begin), 'minute') / 60);

                scheduledTotal += shiftHours;

                //console.log(scheduledTotal);
                if (this.state.workOrder.requiresOTApproval) {
                    //console.log('Work Order requires approval');

                    var employeeNode = processedShiftData.find((x) => x.id == shift.parentId);

                    //console.log(employeeNode);

                    //console.log('Scheduling hard limit enforced: ', this.state.enforceOvertimeSchedulingHardLimit);
                    //console.log('Scheduling hard limit reached: ', scheduledTotal > this.state.overtimeSchedulingHardLimit);
                    //console.log('Is this Work Order shift: ', shift.workOrderID == this.state.workOrder.id);
                    //console.log('Is a new shift: ', employeeNode.display.indexOf('PREVIEW') > -1);

                    if (this.state.enforceOvertimeSchedulingHardLimit && scheduledTotal > this.state.overtimeSchedulingHardLimit && shift.workOrderID == this.state.workOrder.id && employeeNode.display.indexOf('PREVIEW') > -1) {
                        console.log('Shift blocked for scheduling hard limit');

                        var shiftNode = processedShiftData.find((x) => x.id == shift.id);

                        // console.log(shiftNode);
                        shiftNode.otApprovalBlocked = true;
                    }
                }

                // Add each
                // If/when hours go > overtimeSchedulingThreshold
                if (scheduledTotal > this.state.overtimeSchedulingThreshold) {
                    //console.log('Scheduled total greater than ' + this.state.overtimeSchedulingThreshold + ', APPROVAL NEEDED.')

                    // ABG For now, only actually check here for the current work order
                    //if (shift.workOrderID == this.state.workOrder.id) {
                        // Needs approval
                        // Check for approval
                        var approvalStatus = await this.FetchAPI('OvertimeApproval/GetOTApprovalStatus?employeeId=' + shift.employeeId + '&workOrderId=' + shift.workOrderID + '&weekBegin=' + schedulePeriodStartDate.format('MM/DD/YYYY'));

                        if (approvalStatus.workOrderRequiresOTApproval && (!approvalStatus.approval || approvalStatus.approval.statusCode !== 'APPROVED')) {
                            //console.log(item);
                            //console.log(this.state.assignedShifts);
                            // If not approved, warn
                            //console.log('NOT APPROVED', shift);

                            var shiftNode = processedShiftData.find((x) => x.id == shift.id);
                            //console.log(shiftNode);
                            shiftNode.requiresOTApproval = true;
                        }
                        else {
                            // TODO: Test approved
                            // TODO: Test not required
                            // TODO: Test shift alteration putting another later shift into overtime
                            // If approved, continue
                            //console.log('Approved or does not require approval');
                        }
                    //}
                }
                else {
                    //console.log('Scheduled total less than 40, no approval needed.');
                }
            }
        }
    }

    processInitialShiftData(shiftData) {
        var allPeriodData = []

        //console.log(shiftData);

        shiftData.map((data) => {
            var localConsolidatedData = this.consolidateShiftAvailability(data.availability);

            console.log('Shift data', data);

            localConsolidatedData.sort((a, b) => b.beginDate.valueOf() - a.beginDate.valueOf()).map((localItem, localIndex) => {
                allPeriodData.push({
                    id: uuidv4(),
                    employeeId: data.employeeId,
                    planStartDate: localItem.beginDate.toString(),
                    planEndDate: localItem.endDate.toString(),
                    parentId: data.employeeId,
                    display: 'Availability',
                    workOrderId: null,
                    color: "pink"
                })
            });

            data.leaveRequests.map((request, index) => {
                allPeriodData.push({
                    id: request.id,
                    employeeId: request.employeeId,
                    workOrderId: request.workOrderID,
                    planStartDate: request.planStartDate,
                    planEndDate: request.planEndDate,
                    parentId: request.employeeId,
                    display: request.display,
                    color: "orange"
                });
            });

            // TODO: Why were we ever pushing worked shifts??
            //data.workedShifts.map((shift, index) => {
            //    allPeriodData.push(shift);
            //});

            //console.log('Server Scheduled Shifts', data.scheduledShifts);

            data.scheduledShifts.map((shift, index) => {
                if (shift.scheduleType == 'DEDICATED') {
                    //console.log('Dedicated shift', shift);

                    // For this single work order, look at the work order status
                    // If the status is terminal (4 or 5), enter a row per worked shift, otherwise only one row for the work order
                    if (shift.workOrderList[0].workOrderStatusId > 1) {
                        //console.log('Work Order Terminal');
                        //console.log(shift);

                        // Pull the shifts
                        var filteredShiftList = data.workedShifts.filter((item) => item.workOrderID == shift.workOrderList[0].workOrderID);

                        console.log('Filteredshift list', filteredShiftList);

                        // TODO: What about completed/canceled trips with no time billed? Could that happen?
                        filteredShiftList.map((hoursShift) => {
                            hoursShift.display = shift.workOrderList[0].display;
                            hoursShift.workOrderID = shift.workOrderList[0].workOrderID;

                            hoursShift.color = shift.workOrderList[0].workOrderID == this.state.workOrderId ? '#e97f02' : (shift.workOrderList[0].workOrderStatusId < 4 ? '#5b9d95' : (shift.workOrderList[0].workOrderStatusId == 4) ? '#78b6d9' : '#a37182');

                            allPeriodData.push(hoursShift);
                        });
                    }
                    else {
                        shift.display = shift.workOrderList[0].display;
                        shift.workOrderID = shift.workOrderList[0].workOrderID;

                        shift.color = shift.workOrderList[0].workOrderID == this.state.workOrderId ? '#e97f02' : (shift.workOrderList[0].workOrderStatusId < 4 ? '#5b9d95' : (shift.workOrderList[0].workOrderStatusId == 4) ? '#78b6d9' : '#a37182');

                        allPeriodData.push(shift);
                    }
                }
                else {
                    //console.log('Standy shift', shift);

                    shift.display = shift.description;
                    shift.color = '#6c76bf';

                    allPeriodData.push(shift);

                    // TODOHERE
                    // Add all assigned work orders
                    shift.workOrderList.map((workOrder) => {
                        //console.log('Shift', workOrder);

                        workOrder.color = workOrder.workOrderID == this.state.workOrderId ? '#e97f02' : (workOrder.workOrderStatusId < 4 ? '#5b9d95' : (workOrder.workOrderStatusId == 4) ? '#78b6d9' : '#a37182');

                        allPeriodData.push(workOrder);
                    });
                }
            });

            // Employee node
            allPeriodData.push({
                id: data.employeeId,
                employeeId: data.employeeId,
                planStartDate: this.state.workOrder.planStartDate,
                planEndDate: this.state.workOrder.planEndDate,
                parentId: 0,
                display: data.lastName + ', ' + data.firstName + ' (' + data.employeeNo + ')',
                workOrderId: this.state.workOrder.id,
                color: "#3cbab2"
            });
        });

        return allPeriodData;
    }

    // This will populate the shiftDataSource with the current schedule period's data
    updateShiftData() {
        //console.log(this);
        //console.log(this.shiftDataSource);

        //console.log('Starting shifts', this.state.assignedShifts);

        this.shiftDataSource.clear();

        var schedulePeriodStartDate = moment(this.state.selectedSchedulePeriod.fromDate);
        var schedulePeriodEndDate = moment(this.state.selectedSchedulePeriod.toDate);

        //console.log('Calculating shift data for: ' + schedulePeriodStartDate.format('MM/DD/YYYY') + ' to ' + schedulePeriodEndDate.format('MM/DD/YYYY'));

        var employeeShiftGroups = _.groupBy(this.state.assignedShifts, 'employeeId');
        //console.log('Shift groups', employeeShiftGroups);

        for (const property in employeeShiftGroups) {
            var totalScheduledHours = 0;

            var totalShifts = [];
            var availability = [];

            var shifts = employeeShiftGroups[property];

            //console.log(property.employeeId);
            //console.log(property.key);

            var employeeNode = this.state.assignedShifts.find((x) => x.employeeId == x.id && x.employeeId == property);

            //console.log('Distinct employee', employeeNode);

            // Look across all shift data that are not employee nodes
            shifts.filter((shiftItem) => shiftItem.employeeId != shiftItem.id && shiftItem.employeeId == employeeNode.id).map((shift, shiftIndex) => {
                // Find all shifts for other work orders
                //this.state.assignedShifts.filter((shiftItem) => shiftItem.employeeId != shiftItem.id && shiftItem.employeeId == item.employeeId && shiftItem.workOrderID != this.state.workOrderId).map((shift, index) => {
                var shiftStartDate = moment(shift.planStartDate);
                var shiftEndDate = moment(shift.planEndDate);

                //console.log(shift.planStartDate);
                //console.log(shift.planEndDate);

                // IF the shift is within the current period, add it to the list
                if ((shiftStartDate.isSameOrAfter(schedulePeriodStartDate) && shiftStartDate.isSameOrBefore(schedulePeriodEndDate))
                    ||
                    (shiftEndDate.isSameOrAfter(schedulePeriodStartDate) && shiftEndDate.isSameOrBefore(schedulePeriodEndDate))
                    ||
                    (shiftStartDate.isSameOrBefore(schedulePeriodStartDate) && shiftEndDate.isSameOrAfter(schedulePeriodEndDate))) {

                    //console.log('Shift node', shift);

                    if (shift.workOrderID) {
                        //console.log('Within the period and adding hours');

                        var begin = shiftStartDate.isBefore(schedulePeriodStartDate) ? schedulePeriodStartDate : shiftStartDate;
                        var end = shiftEndDate.isAfter(schedulePeriodEndDate) ? schedulePeriodEndDate : shiftEndDate;

                        var shiftHours = (moment(end).diff(moment(begin), 'minute') / 60);

                        //console.log(shift.currentScheduledHours);

                        var shiftNode = this.state.assignedShifts.find((x) => x.id == shift.parentId);

                        // For standby shifts, don't count the work order times, only the schedule times
                        if (shiftNode.scheduleType == 'STANDBY') {
                            //console.log('Parent shift node is standby');
                            //shift.currentScheduledHours = null;
                            shift.currentScheduledHours = null;
                            shiftHours = 0;
                        }
                        else {
                            shift.currentScheduledHours = shiftHours.toFixed(2);

                            //console.log(shiftHours);

                            totalScheduledHours += shiftHours;

                            //console.log(totalScheduledHours);
                        }
                    }
                    else {
                        if (shift.scheduleType == 'STANDBY') {
                            //console.log('Regular standby shift', shift);

                            var begin = shiftStartDate.isBefore(schedulePeriodStartDate) ? schedulePeriodStartDate : shiftStartDate;
                            var end = shiftEndDate.isAfter(schedulePeriodEndDate) ? schedulePeriodEndDate : shiftEndDate;

                            var shiftHours = (moment(end).diff(moment(begin), 'minute') / 60);

                            //console.log(shift.currentScheduledHours);

                            shift.currentScheduledHours = shiftHours.toFixed(2);

                            totalScheduledHours += shiftHours;

                            //totalShifts.push(shift);
                        }
                        else {
                            //console.log('Availability', shift);
                            availability.unshift(shift);
                        }
                    }
                }

                this.shiftDataSource.insert(shift);
            });

            employeeNode.currentScheduledHours = totalScheduledHours.toFixed(2);

            this.shiftDataSource.insert(employeeNode);
        };

        this.schedulingGantt.instance().refresh();

        //console.log('Ending shifts', this.shiftDataSource._array);
    }

    customDataSource = new CustomStore({
        key: 'employeeId',
        loadMode: 'raw',
        load: () => {
            // console.log('Load', 'No Options');
        },
        load: (loadOptions) => {
            // console.log('Load', 'With Options');
            // console.log('Load', loadOptions);
            //console.log('Loading Custom Data Source');

            if (this.state.workOrder && this.state.scheduleEndDate) {
                //console.log('Now we are ready to get available employees');
                //console.log(this.state.selectedSchedulePeriod);

                var queryString = 'workOrderId=' + this.state.workOrderId;
                queryString += '&showPartialAvailability=' + (this.state.availabilityMode == 0 ? 'false' : 'true');

                queryString += '&startDate=' + this.state.workOrder.planStartDate;
                queryString += '&endDate=' + this.state.workOrder.planEndDate;

                queryString += '&periodStartDate=' + this.state.scheduleBeginDate;
                queryString += '&periodEndDate=' + this.state.scheduleEndDate;

                return FetchAPIPromise('Schedule/GetAvailableEmployees', queryString)
                    .then((json) => {
                        // console.log('Postretrieve of data', json);

                        var filteredList = [];

                        // *********************************
                        // TODO:
                        // 1) Check unavailability/leave requests
                        // 2) Check full period availability across schedule periods
                        var currentPeriodBeginMoment = moment(this.state.selectedSchedulePeriod.fromDate);
                        var currentPeriodEndMoment = moment(this.state.selectedSchedulePeriod.toDate);

                        // TODO: Could we multi-thread this?
                        json.map((item, index) => {
                            // 1) Check availability
                            // If not available, do not add to list
                            //console.log(item.availability);
                            /*console.log(item);*/

                            // ABG Shouldn't really happen, but we'll check
                            if (!item.availability) {
                                //console.log('Incomplete availability for employee no: ' + item.employeeNo);
                                return;
                            }

                            //console.log('Total', availableShifts);
                            //availableShifts.map((display, displayIndex) => {
                            //    console.log(display.beginDate.format('MM/DD/YYYY HH:mm') + ' - ' + display.endDate.format('MM/DD/YYYY HH:mm'));
                            //});

                            // Consolidate
                            var consolidatedShifts = this.consolidateShiftAvailability(item.availability);

                            //console.log('Consolidated', consolidatedShifts);

                            //consolidatedShifts.map((display, displayIndex) => {
                            //    console.log(display.beginDate.format('MM/DD/YYYY HH:mm') + ' - ' + display.endDate.format('MM/DD/YYYY HH:mm'));
                            //});

                            item.availableShifts = consolidatedShifts;

                            //console.log(item.availableShifts);

                            // Finally: Check whether the availability matches what is requested
                            if (item.availableShifts.length > 0) {
                                var hasAvailability = false;

                                var tripBeginMoment = moment(this.state.workOrder.planStartDate);
                                var tripEndMoment = moment(this.state.workOrder.planEndDate);

                                var stillEligible = true;

                                // IF we are filtering out employees who are already assigned...
                                if (item.scheduledShifts && item.scheduledShifts.length > 0) {
                                    // Check whether their existing shifts overlap
                                    //console.log(item);

                                    if (this.state.excludeOverlappingShifts) {
                                        item.scheduledShifts.map((existingShift, existingIndex) => {
                                            var existingBeginDate = moment(existingShift.planStartDate);
                                            var existingEndDate = moment(existingShift.planEndDate);

                                            //if (existingShift.employeeId == 595128) {
                                            //    console.log(existingShift);
                                            //    console.log('Shift Begin:', existingBeginDate);
                                            //    console.log('Shift End:', existingEndDate);
                                            //    console.log('WO Begin:', tripBeginMoment);
                                            //    console.log('WO End:', tripEndMoment);

                                            //    console.log(tripBeginMoment.isSameOrAfter(existingBeginDate));
                                            //    console.log(tripBeginMoment.isSameOrBefore(existingEndDate));
                                            //}

                                            if ((tripBeginMoment.isSameOrAfter(existingBeginDate) && tripBeginMoment.isSameOrBefore(existingEndDate))
                                                ||
                                                (tripEndMoment.isSameOrAfter(existingBeginDate) && tripEndMoment.isSameOrBefore(existingEndDate))) {
                                                stillEligible = false;
                                                return;
                                            }
                                        });
                                    }

                                    if (stillEligible && this.state.includeContractBreak) {
                                        //console.log('Checking contract break threshold');

                                        // Check that any existing scheduled shift is at least the contract break period difference away
                                        item.scheduledShifts.map((existingShift, existingIndex) => {
                                            var existingBeginDate = moment(existingShift.planStartDate);
                                            var existingEndDate = moment(existingShift.planEndDate);

                                            //console.log('Original shift begin date', existingBeginDate.format('MM/DD/YYYY HH:mm'));
                                            //console.log('Original shift end date', existingEndDate.format('MM/DD/YYYY HH:mm'));

                                            // Extend the dates by the contract period for the purpose of comparison
                                            existingBeginDate.add(-this.state.tripBreakHoursLimit, 'hour');
                                            existingEndDate.add(this.state.tripBreakHoursLimit, 'hour');

                                            //console.log('Padded shift begin date', existingBeginDate.format('MM/DD/YYYY HH:mm'));
                                            //console.log('Padded shift end date', existingEndDate.format('MM/DD/YYYY HH:mm'));

                                            //if (existingShift.employeeId == 595128) {
                                            //    console.log(existingShift);
                                            //    console.log('Shift Begin:', existingBeginDate);
                                            //    console.log('Shift End:', existingEndDate);
                                            //    console.log('WO Begin:', tripBeginMoment);
                                            //    console.log('WO End:', tripEndMoment);

                                            //    console.log(tripBeginMoment.isSameOrAfter(existingBeginDate));
                                            //    console.log(tripBeginMoment.isSameOrBefore(existingEndDate));
                                            //}

                                            // TODO: UNLESS WORK ORDER TYPE OF EACH IS DIFFERENT
                                            // TODO: Test threshold with two regular work orders
                                            // TODO: Test overlapping threshold?
                                            // DONE: Test threshold with one MSWO and one RWO
                                            // DONE: Test difference between break threshold and overlapping numbers
                                            // DONE: Test MSWO first then RWO
                                            // DONE: Test RWO first then MSWO
                                            if (((tripBeginMoment.isSameOrAfter(existingBeginDate) && tripBeginMoment.isBefore(existingEndDate))
                                                ||
                                                (tripEndMoment.isAfter(existingBeginDate) && tripEndMoment.isSameOrBefore(existingEndDate)))) {

                                                // Ensure that work order shifts being compared are not linked
                                                if ((this.state.workOrder.missionSupportWorkOrderId && this.state.workOrder.missionSupportWorkOrderId == existingShift.workOrderID)
                                                    ||
                                                    (existingShift.missionSupportWorkOrderId && existingShift.missionSupportWorkOrderId == this.state.workOrder.id)) {
                                                    // Should be okay
                                                    //if (item.employeeNo == '65061') {
                                                    //    console.log(this.state.workOrder);
                                                    //    console.log(existingShift);
                                                    //}
                                                }
                                                else {
                                                    stillEligible = false;

                                                    //if (item.employeeNo == '65061') {
                                                    //    console.log(this.state.workOrder);
                                                    //    console.log(existingShift);
                                                    //}

                                                    //console.log(item.employeeNo + ' HITTING BREAK THRESHOLD');
                                                    return;
                                                }
                                            }
                                        });
                                    }
                                }

                                if (stillEligible) {
                                    if (this.state.availabilityMode == 0) {

                                        // Basically, look at the week end for the plan start date, check for the availability to cover multiple weeks
                                        item.availableShifts.map((section, sectionIndex) => {
                                            // Look for full coverage
                                            if (section.beginDate.isSameOrBefore(tripBeginMoment) && section.endDate.isSameOrAfter(tripEndMoment)) {
                                                hasAvailability = true;
                                                return false;
                                            }
                                            // If the week beginnings are NOT the same, we need to check cross week availability for the full trip mode
                                            // ABG I THINK this is actually working correctly. Now that we populate availability across the trip, there must be an unbroken availability for the work order, which should be the case for employees who are eligible
                                            //else if (!startDatePeriodBeginDate.isSame(endDatePeriodBeginDate)) {
                                            //    console.log('Availability has to be across periods');

                                            //    // TODO: ABG continue calculation
                                            //    // Look for unbroken availability from begin to end
                                            //    // If lastSectionEnd is null, then we are calculating at the beginning of the trip
                                            //    //      Find the end of the week, and this first section has to fully overlap
                                            //    if (lastSectionEnd == null) {
                                            //        var firstWeekEndMoment = tripBeginMoment.clone().endOf('week');

                                            //        console.log('First Week Ends', firstWeekEndMoment);
                                            //    }
                                            //}
                                        });
                                    }
                                    else {
                                        item.availableShifts.map((section, sectionIndex) => {
                                            // Look for any partial coverage
                                            // TODO: Maybe a certain amount of overlap, 4 hours, 8 hours, 12 hours?
                                            if ((section.beginDate.isSameOrAfter(tripBeginMoment) && section.beginDate.isSameOrBefore(tripEndMoment))
                                                ||
                                                (section.endDate.isSameOrAfter(tripBeginMoment) && section.endDate.isSameOrBefore(tripEndMoment))
                                                ||
                                                (section.beginDate.isSameOrBefore(tripBeginMoment) && section.endDate.isSameOrAfter(tripEndMoment))) {
                                                hasAvailability = true;
                                                return false;
                                            }
                                        });
                                    }
                                }

                                // At this point, employee is available in general
                                if (hasAvailability && item.leaveRequests.length > 0 && this.state.excludeOverlappingLeave) {
                                    //console.log('Has leave requests', item);

                                    // Basically, look across the leave requests and see if any of them overlap with the trip date
                                    // TODO: Base on the entire or partial filters
                                    item.leaveRequests.map((request, requestIndex) => {
                                        var requestBeginDateMoment = moment(request.planStartDate);
                                        var requestEndDateMoment = moment(request.planEndDate);

                                        //console.log(requestBeginDateMoment.format('MM/DD/YYYY HH:mm'));
                                        //console.log(requestEndDateMoment.format('MM/DD/YYYY HH:mm'));

                                        //console.log('Availability Mode', this.state.availabilityMode);

                                        // TODO: Test trip spanning
                                        // TODO: Test full mode when leave does not touch the trip dates
                                        if (this.state.availabilityMode == 0) {
                                            // If availability mode is FULL, it means the trip cannot overlap with the leave at all
                                            if ((requestBeginDateMoment.isSameOrAfter(tripBeginMoment) && requestBeginDateMoment.isSameOrBefore(tripEndMoment))
                                                ||
                                                (requestEndDateMoment.isSameOrAfter(tripBeginMoment) && requestEndDateMoment.isSameOrBefore(tripEndMoment))
                                                ||
                                                (requestBeginDateMoment.isSameOrBefore(tripBeginMoment) && requestEndDateMoment.isSameOrAfter(tripEndMoment))) {
                                                hasAvailability = false;
                                                //console.log(item.employeeNo + 'LEAVE CONTRADICTS');
                                                return false;
                                            }
                                        }
                                        else {
                                            // For partial coverage, make sure the leave does not extend beyond the borders and make sure that the leave begins and ends only partial to the trip dates
                                            if (!(requestBeginDateMoment.isSameOrBefore(tripBeginMoment) && requestEndDateMoment.isSameOrAfter(tripEndMoment))
                                                &&
                                                (requestBeginDateMoment.isAfter(tripBeginMoment)
                                                ||
                                                requestEndDateMoment.isBefore(tripEndMoment))
                                                ||
                                                (requestBeginDateMoment.isAfter(tripBeginMoment) && requestEndDateMoment.isBefore(tripEndMoment))
                                                ) {
                                                // TODO: Find way to consistently invert this condition to avoid empty true block?
                                                //console.log(item.employeeNo + 'LEAVE IS GOOD');
                                            }
                                            else {
                                                hasAvailability = false;
                                                //console.log(item.employeeNo + 'LEAVE CONTRADICTS');
                                                return false;
                                            }
                                        }
                                    });
                                }

                                if (!hasAvailability) {
                                    //console.log('Employee No ' + item.employeeNo + ' not available for trip');
                                    return;
                                }
                            }
                            else {
                                //console.log('Employee No ' + item.employeeNo + ' has no availability');
                                //console.log(item.availability);
                                return;
                            }
                            //}

                            // 2) Sum up worked and scheduled hours for the period
                            if (item.workedShifts.length > 0) {
                                var workedTotal = 0;

                                item.workedShifts.map((shift) => {
                                    //console.log(shift);
                                    var clockOutMoment = moment(shift.planEndDate);
                                    var clockInMoment = moment(shift.planStartDate);

                                    // console.log(tripBeginMoment);

                                    if ((clockInMoment.isSameOrAfter(currentPeriodBeginMoment) && clockInMoment.isSameOrBefore(currentPeriodEndMoment))
                                        ||
                                        (clockOutMoment.isSameOrAfter(currentPeriodBeginMoment) && clockOutMoment.isSameOrBefore(currentPeriodEndMoment))
                                        ||
                                        (clockInMoment.isSameOrBefore(currentPeriodBeginMoment) && clockOutMoment.isSameOrAfter(currentPeriodEndMoment))) {

                                        var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
                                        var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

                                        workedTotal += end.diff(begin, 'minute') / 60;
                                    }
                                });

                                item.currentWorkedHours = workedTotal.toFixed(2);
                            }
                            else {
                                item.currentWorkedHours = 0;
                            }

                            if (item.scheduledShifts.length > 0) {
                                var scheduledTotal = 0;

                                item.scheduledShifts.map((shift, shiftIndex) => {
                                    var shiftStartMoment = moment(shift.planStartDate);
                                    var shiftEndMoment = moment(shift.planEndDate);

                                    if ((shiftStartMoment.isSameOrAfter(currentPeriodBeginMoment) && shiftStartMoment.isSameOrBefore(currentPeriodEndMoment))
                                        ||
                                        (shiftEndMoment.isSameOrAfter(currentPeriodBeginMoment) && shiftEndMoment.isSameOrBefore(currentPeriodEndMoment))
                                        ||
                                        (shiftStartMoment.isSameOrBefore(currentPeriodBeginMoment) && shiftEndMoment.isSameOrAfter(currentPeriodEndMoment))) {

                                        var begin = shiftStartMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : shiftStartMoment;
                                        var end = shiftEndMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : shiftEndMoment;

                                        //console.log(shift);
                                        scheduledTotal += moment(end).diff(moment(begin), 'minute') / 60;
                                    }
                                });

                                item.currentScheduledHours = scheduledTotal.toFixed(2);
                            }
                            else {
                                item.currentScheduledHours = 0;
                            }

                            filteredList.push(item);
                        });

                        //console.log('finallist', filteredList);

                        return filteredList;
                    });
            }
        }
    });

    consolidateShiftAvailability(availabilityList) {
        var availableShifts = [];

        this.state.weeks.map((weekItem, weekIndex) => {
            var weekBegin = moment(weekItem.fromDate);
            var weekEnd = moment(weekItem.toDate);

            // What we need to do here is to Include/Consolidate availability that either
            // 1) BeginDate and EndDate are within the given week, OR
            // 2) BeginDate is before or on the beginning of the week and end date is on or after the end date or is null
            var localAvailabilityList = availabilityList.filter((item) => /* ONEOFF item */(moment(item.beginDate).isSameOrAfter(weekBegin) && moment(item.endDate).isSameOrBefore(weekEnd)) || (moment(item.beginDate).isSameOrBefore(weekBegin) && (moment(item.endDate).isSameOrAfter(weekEnd) || !item.endDate)));

            //console.log(localAvailabilityList);

            for (var i = 0; i < localAvailabilityList.length; i++) {
                var dayAvailability = localAvailabilityList[i];
                var adjustedWeekDayIndex = dayAvailability.weekDayId - 1;

                if (dayAvailability.isAvailable) {
                    var shiftBegin = weekBegin.clone();
                    shiftBegin.add(adjustedWeekDayIndex, 'days');

                    var shiftEnd = weekBegin.clone();
                    shiftEnd.add(adjustedWeekDayIndex + 1, 'days');

                    availableShifts.push({ beginDate: shiftBegin, endDate: shiftEnd });
                }
                else {
                    // Partial Availability
                    // TODO: Try to consolidate some here?
                    if (dayAvailability.m1) {
                        var shiftBegin = weekBegin.clone();
                        shiftBegin.add(adjustedWeekDayIndex, 'days');

                        var shiftEnd = weekBegin.clone();
                        shiftEnd.add(adjustedWeekDayIndex, 'days');
                        shiftEnd.add(6, 'hours');

                        availableShifts.push({ beginDate: shiftBegin, endDate: shiftEnd });
                    }

                    if (dayAvailability.m2) {
                        var shiftBegin = weekBegin.clone();
                        shiftBegin.add(adjustedWeekDayIndex, 'days');
                        shiftBegin.add(6, 'hours');

                        var shiftEnd = weekBegin.clone();
                        shiftEnd.add(adjustedWeekDayIndex, 'days');
                        shiftEnd.add(12, 'hours');

                        availableShifts.push({ beginDate: shiftBegin, endDate: shiftEnd });
                    }

                    if (dayAvailability.e1) {
                        var shiftBegin = weekBegin.clone();
                        shiftBegin.add(adjustedWeekDayIndex, 'days');
                        shiftBegin.add(12, 'hours');

                        var shiftEnd = weekBegin.clone();
                        shiftEnd.add(adjustedWeekDayIndex, 'days');
                        shiftEnd.add(18, 'hours');

                        availableShifts.push({ beginDate: shiftBegin, endDate: shiftEnd });
                    }

                    if (dayAvailability.e2) {
                        var shiftBegin = weekBegin.clone();
                        shiftBegin.add(adjustedWeekDayIndex, 'days');
                        shiftBegin.add(18, 'hours');

                        var shiftEnd = weekBegin.clone();
                        shiftEnd.add(adjustedWeekDayIndex, 'days');
                        shiftEnd.add(24, 'hours');

                        availableShifts.push({ beginDate: shiftBegin, endDate: shiftEnd });
                    }
                }
            }
        });

        // Consolidate
        var consolidatedShifts = [];

        availableShifts.sort((a, b) => a.valueOf() - b.valueOf()).map((availableShift, availableIndex) => {
            if (availableIndex == 0) {
                consolidatedShifts.push(availableShift);
            }
            else {
                if (availableShift.beginDate.isSame(consolidatedShifts[consolidatedShifts.length - 1].endDate)) {
                    consolidatedShifts[consolidatedShifts.length - 1].endDate = availableShift.endDate;
                }
                else {
                    consolidatedShifts.push(availableShift);
                }
            }
        });

        return consolidatedShifts;
    }

    previewStandbyShift = async (e, shift) => {
        //console.log('Preview standby request', e);current
        //console.log('Preview standby', shift);
        e.preventDefault();

        // TODO: Add the current work order to the list
        // TODO: Prevent adding again for already previewed
        var standbyShift = shift.scheduledShifts.find((item) => item.id == shift.id);

        //console.log('Shift', shift);
        //console.log('Standby shift', standbyShift);

        standbyShift.workOrderList.push({
            id: this.state.workOrder.id + shift.id,
            employeeId: shift.employeeId,
            planStartDate: this.state.workOrder.planStartDate,
            planEndDate: this.state.workOrder.planEndDate,
            parentId: shift.id,
            display: this.state.workOrder.workOrderName + ' (' + this.state.workOrder.workOrderNumber + ')',
            workOrderID: this.state.workOrder.id,
            color: "#e97f02"
        });

        await this.previewEmployeeCommon({
            employeeId: shift.employeeId,
            lastName: shift.lastName,
            firstName: shift.firstName,
            employeeNo: shift.employeeNo,
            availableShifts: shift.availableShifts,
            leaveRequests: shift.leaveRequests,
            scheduledShifts: shift.scheduledShifts,
            workedShifts: shift.workedShifts,
            scheduleType: 'STANDBY',
            color: "#6c76bf",
            display: shift.description,
            newlyAdded: true
        },
            null
        );

        // Either maybe do this approach OR save in uac_schedule with a certain status or something
        this.updateShiftData();
    }

    previewEmployee = async (e, row) => {
        e.preventDefault();
        //console.log('Preview request', row.data);

        await this.previewEmployeeDedicated(row.data);
    }

    previewEmployeeDedicated = async (data) => {
        await this.previewEmployeeCommon(data,
            {
                id: uuidv4(),
                employeeId: data.employeeId,
                planStartDate: this.state.workOrder.planStartDate,
                planEndDate: this.state.workOrder.planEndDate,
                missionSupportWorkOrderId: this.state.workOrder.missionSupportWorkOrderId,
                parentId: data.employeeId,
                display: this.state.workOrder.workOrderName + ' (' + this.state.workOrder.workOrderNumber + ')',
                workOrderID: this.state.workOrder.id,
                scheduleType: 'DEDICATED',
                color: "#e97f02",

                newlyAdded: true
            });

        // Either maybe do this approach OR save in uac_schedule with a certain status or something
        this.updateShiftData();
    }

    previewEmployeeCommon = async (data, newScheduleEntry) => {

        var currentAssignedShifts = this.state.assignedShifts;

        // console.log(data);

        data.availableShifts.map((shift, index) => {
            //console.log('Availability Shift', shift);
            currentAssignedShifts.unshift({
                id: uuidv4(),
                employeeId: data.employeeId,
                planStartDate: shift.beginDate.toString(),
                planEndDate: shift.endDate.toString(),
                parentId: data.employeeId,
                display: 'Availability',
                workOrderId: null,
                color: "pink"
            });
        });

        data.leaveRequests.map((request, index) => {
            currentAssignedShifts.push({
                id: request.id,
                employeeId: request.employeeId,
                workOrderId: request.workOrderID,
                planStartDate: request.planStartDate,
                planEndDate: request.planEndDate,
                parentId: request.employeeId,
                display: request.display,
                color: "orange"
            });
        });

        if (newScheduleEntry != null) {
            currentAssignedShifts.push(newScheduleEntry);
        }

        data.scheduledShifts.map((shift) => {
            //console.log(shift);
            if (shift.scheduleType == 'DEDICATED') {
                //console.log('Dedicated shift', shift);

                // For this single work order, look at the work order status
                // If the status is terminal (4 or 5), enter a row per worked shift, otherwise only one row for the work order
                if (shift.workOrderList[0].workOrderStatusId > 1) {
                    //console.log('Work Order Terminal');
                    //console.log(shift);

                    // Pull the shifts
                    var filteredShiftList = data.workedShifts.filter((item) => item.workOrderID == shift.workOrderList[0].workOrderID);

                    //console.log('Filteredshift list', filteredShiftList);

                    // TODO: What about completed/canceled trips with no time billed? Could that happen?
                    filteredShiftList.map((hoursShift) => {
                        hoursShift.display = shift.workOrderList[0].display;
                        hoursShift.workOrderID = shift.workOrderList[0].workOrderID;
                        hoursShift.missionSupportWorkOrderId = shift.workOrderList[0].missionSupportWorkOrderId;

                        hoursShift.color = shift.workOrderList[0].workOrderID == this.state.workOrderId ? '#e97f02' : (shift.workOrderList[0].workOrderStatusId < 4 ? '#5b9d95' : (shift.workOrderList[0].workOrderStatusId == 4) ? '#78b6d9' : '#a37182');

                        currentAssignedShifts.push(hoursShift);
                    });
                }
                else {
                    shift.display = shift.workOrderList[0].display;
                    shift.workOrderID = shift.workOrderList[0].workOrderID;
                    shift.missionSupportWorkOrderId = shift.workOrderList[0].missionSupportWorkOrderId;

                    shift.color = shift.workOrderList[0].workOrderID == this.state.workOrderId ? '#e97f02' : (shift.workOrderList[0].workOrderStatusId < 4 ? '#5b9d95' : (shift.workOrderList[0].workOrderStatusId == 4) ? '#78b6d9' : '#a37182');

                    currentAssignedShifts.push(shift);
                }
            }
            else {
                //console.log('****RESERVE ADDITION****');
                //console.log(shift);

                //console.log('Reserve data', data);

                shift.display = data.display;
                //shift.color = data.color;
                shift.color = "#6c76bf";

                for (var j = 0; j < shift.workOrderList.length; j++) {
                    var workOrder = shift.workOrderList[j];

                    //console.log(workOrder);

                    workOrder.parentId = shift.id;
                    workOrder.employeeId = data.employeeId;
                    workOrder.scheduleType = 'STANDBY';

                    //console.log('Work order is equal?', workOrder.workOrderID.toUpperCase() == this.state.workOrderId.toUpperCase());
                    workOrder.color = workOrder.workOrderID.toUpperCase() == this.state.workOrderId.toUpperCase() ? '#e97f02' : (workOrder.workOrderStatusId < 4 ? '#5b9d95' : (workOrder.workOrderStatusId == 4) ? '#78b6d9' : '#a37182');

                    currentAssignedShifts.push(workOrder);
                }

                currentAssignedShifts.push(shift);
            }
        });

        currentAssignedShifts.push({
            id: data.employeeId,
            employeeId: data.employeeId,
            planStartDate: this.state.workOrder.planStartDate,
            planEndDate: this.state.workOrder.planEndDate,
            parentId: 0,
            display: data.lastName + ', ' + data.firstName + ' (' + data.employeeNo + ') PREVIEW',
            workOrderId: this.state.workOrder.id,
            color: "#3cbab2"
        });

        await this.checkForOTShiftApprovals(currentAssignedShifts);

        this.setState({
            assignedShifts: currentAssignedShifts
        });
    }

    onOptionChanged = async (e) => {
        //console.log('Availability Option Changed', e);

        await this.setState({
            availabilityMode: e.value
        });

        this.dataGrid.instance().refresh();
    }

    onShiftFilterChanged = async (e) => {
        //console.log('Availability Option Changed', e);

        await this.setState({
            excludeOverlappingShifts: e.value
        });

        this.dataGrid.instance().refresh();
    }

    onContractBreakFilterChanged = async (e) => {
        //console.log('Availability Option Changed', e);

        await this.setState({
            includeContractBreak: e.value
        });

        this.dataGrid.instance().refresh();
    }

    onLeaveFilterChanged = async (e) => {
        //console.log('Availability Option Changed', e);

        await this.setState({
            excludeOverlappingLeave: e.value
        });

        this.dataGrid.instance().refresh();
    }

    // TODO: On Initial add
    // TODO: On update that changes time(s)
    finalizeSchedule = async (e) => {
        // TODO: What about when/if we switch periods? Do we actually need to have them save?

         console.log(this.state.assignedShifts);

        // Confirm schedule changes
        var dialogResult = await confirm('Are you sure you want to finalize this schedule? All shifts left in preview will be added to the Work Order.');

        if (dialogResult) {
            // Validate overtime status
            // 1) TODO: For each week...?
            var schedulePeriodStartDate = moment(this.state.selectedSchedulePeriod.fromDate);
            var schedulePeriodEndDate = moment(this.state.selectedSchedulePeriod.toDate);

            console.log(this.state.assignedShifts);

            // 2) For each employee
            var employeeShiftGroups = _.groupBy(this.state.assignedShifts.filter((item) => item.parentId == item.employeeId && item.workOrderID), 'employeeId');
            //console.log('Shift groups', employeeShiftGroups);

            var employeesRequiringApproval = [];
            var employeesThatCannotBeAdded = [];
            var employeesBlockedByCompliance = [];

            // TODO: MSWO and RWO exception
            // TODO: Check last shift end
            for (const property in employeeShiftGroups) {
                var scheduledTotal = 0;

                var shifts = employeeShiftGroups[property];

                //console.log('Shifts to validate', shifts);

                // Only used if contract break limit compliance is enforced
                var lastShift = null;

                // Sort the shifts/hours
                var sortedShifts = shifts.sort((a, b) => moment(a.planStartDate).valueOf() - moment(b.planStartDate).valueOf());

                for (var i = 0; i < sortedShifts.length; i++) {
                    var shift = sortedShifts[i];
                    //console.log(property, shift);

                    var shiftStartDate = moment(shift.planStartDate);
                    var shiftEndDate = moment(shift.planEndDate);

                    var begin = shiftStartDate.isBefore(schedulePeriodStartDate) ? schedulePeriodStartDate : shiftStartDate;
                    var end = shiftEndDate.isAfter(schedulePeriodEndDate) ? schedulePeriodEndDate : shiftEndDate;

                    var shiftHours = (moment(end).diff(moment(begin), 'minute') / 60);

                    scheduledTotal += shiftHours;

                    //console.log(scheduledTotal);

                    // Add each
                    // If/when hours go > 40
                    // TODO: Add legitimate assignment while someone else is over threshold
                    // TODO: Reserve schedule?
                    if (this.state.workOrder.requiresOTApproval) {
                        //console.log('Work Order requires approval');

                        var employeeNode = this.state.assignedShifts.find((x) => x.id == shift.parentId);

                        //console.log('Scheduling hard limit enforced: ', this.state.enforceOvertimeSchedulingHardLimit);
                        //console.log('Scheduling hard limit reached: ', scheduledTotal > this.state.overtimeSchedulingHardLimit);
                        //console.log('Is this Work Order shift: ', shift.workOrderID == this.state.workOrder.id);
                        //console.log('Is a new shift: ', employeeNode.display.indexOf('PREVIEW') > -1);

                        if (this.state.enforceOvertimeSchedulingHardLimit && scheduledTotal > this.state.overtimeSchedulingHardLimit && shift.workOrderID == this.state.workOrder.id && employeeNode.display.indexOf('PREVIEW') > -1) {
                            //console.log('Shift blocked for scheduling hard limit');
                            employeesThatCannotBeAdded.push({
                                display: employeeNode.display,
                                workScheduleId: shift.id
                            });

                            break;
                        }
                    }

                    if (scheduledTotal > this.state.overtimeSchedulingThreshold) {
                        //console.log('Scheduled total greater than 40, APPROVAL NEEDED.')

                        // Needs approval
                        // Check for approval
                        var approvalStatus = await this.FetchAPI('OvertimeApproval/GetOTApprovalStatus?employeeId=' + shift.employeeId + '&workOrderId=' + shift.workOrderID + '&weekBegin=' + schedulePeriodStartDate.format('MM/DD/YYYY'));

                        if (approvalStatus.workOrderRequiresOTApproval && (!approvalStatus.approval || approvalStatus.approval.statusCode !== 'APPROVED')) {
                            //console.log(this.state.assignedShifts);
                            // If not approved, warn
                            //console.log('NOT APPROVED');
                            //console.log('Approval status', approvalStatus);
                            //console.log('Shift', shift);

                            // TODO: Include work order number/name possibly?
                            var employeeNode = this.state.assignedShifts.find((x) => x.id == shift.parentId);

                            if (!employeesRequiringApproval.find((item) => item.employeeId == employeeNode.employeeId)) {
                                employeesRequiringApproval.push({
                                    display: employeeNode.display,
                                    workOrder: shift.display,
                                    workScheduleId: shift.id
                                });
                            }
                        }
                        else {
                            // TODO: Test approved
                            // TODO: Test not required
                            // TODO: Test shift alteration putting another later shift into overtime
                            // If approved, continue
                            //console.log('Approved or does not require approval');
                        }
                    }
                    else {
                        //console.log('Scheduled total less than 40, no approval needed.');
                    }

                    // TODO: Test to make sure it's not enforced if turned off
                    if (this.state.enforceContractTripBreakCompliance) {
                        // Check begin/end times
                        if (lastShift != null) {
                            //console.log('Last shift end', moment(lastShift.planEndDate).format('MM/DD/YYYY HH:mm'));
                            //console.log('Next shift begin', shiftStartDate.format('MM/DD/YYYY HH:mm'));

                            // Check distance between begin and end
                            var shiftDifference = (shiftStartDate.diff(moment(lastShift.planEndDate), 'minute') / 60);

                            //console.log('Shift difference', shiftDifference);
                            //console.log('Last Shift work order', lastShift.workOrderID);
                            //console.log('This Shift Work order', shift.workOrderID);

                            if (shiftDifference < this.state.tripBreakHoursLimit) {
                                //console.log(lastShift);
                                //console.log(shift);

                                // IF the last shift and the current shift are the same work order
                                // OR IF the last shift is a regular work order linked to this MSWO shift
                                // OR IF the last shift is a MSWO linked to this shift
                                if (lastShift.workOrderID == shift.workOrderID || (shift.missionSupportWorkOrderId && lastShift.workOrderID == shift.missionSupportWorkOrderId) || (lastShift.missionSupportWorkOrderId && shift.workOrderID == lastShift.missionSupportWorkOrderId)) {
                                    // Shift is okay
                                }
                                else {
                                    var employeeNode = this.state.assignedShifts.find((x) => x.id == shift.parentId);

                                    if (employeeNode) {
                                        if (!employeesBlockedByCompliance.find((item) => item.employeeId == employeeNode.employeeId)) {
                                            employeesBlockedByCompliance.push({
                                                display: employeeNode.display,
                                                workOrder: shift.display,
                                                workScheduleId: shift.id
                                            });
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            lastShift = shift;
                        }
                    }
                }
            }

            //console.log('Finished validating', employeesBlockedByCompliance);

            if (employeesRequiringApproval.length > 0 || employeesThatCannotBeAdded.length > 0 || employeesBlockedByCompliance.length > 0) {
                console.log('Some validation issues');
                var message = '';

                var validationErrors = [];

                if (employeesBlockedByCompliance.length > 0) {

                    employeesBlockedByCompliance.map((employee) => {
                        message += employee.display + ' has a schedule that violates contract compliance with shifts closer than ' + this.state.tripBreakHoursLimit + ' hours apart.';

                        validationErrors.push({
                            workScheduleId: employee.workScheduleId,
                            overrideTypeCode: 'BREAKCOMPLIANCE',
                            //approverEmployeeId: ,
                            reason: 'TEMPORARY Sys Admin Override'
                        });
                    });
                }

                // console.log(employeesRequiringApproval);
                if (employeesRequiringApproval.length > 0) {
                    if (employeesBlockedByCompliance.length > 0) {
                        message += '<br />';
                    }

                    message += 'The following Employee(s) require Overtime Approval and cannot be finalized until Approved or removed:';

                    employeesRequiringApproval.map((employee) => {
                        var employeeName = employee.display.substring(0, employee.display.lastIndexOf(')') + 1) + ' for ' + employee.workOrder;
                        message += ('<br />' + employeeName);

                        validationErrors.push({
                            workScheduleId: employee.workScheduleId,
                            overrideTypeCode: 'OTAPPROVALREQUIRED',
                            //approverEmployeeId: ,
                            reason: 'TEMPORARY Sys Admin Override'
                        });
                    });
                }

                if (employeesThatCannotBeAdded.length > 0) {
                    console.log(employeesThatCannotBeAdded);
                    if (employeesRequiringApproval.length > 0) {
                        message += '<br />';
                    }

                    message += 'The following Employee(s) are over the Overtime Approval limit of ' + this.state.overtimeSchedulingHardLimit + ' hours and cannot be finalized:';

                    employeesThatCannotBeAdded.map((employee) => {
                        var employeeName = employee.display.substring(0, employee.display.lastIndexOf(')') + 1);
                        message += ('<br />' + employeeName);

                        validationErrors.push({
                            workScheduleId: employee.workScheduleId,
                            overrideTypeCode: 'OTHARDLIMITREACHED',
                            //approverEmployeeId: ,
                            reason: 'TEMPORARY Sys Admin Override'
                        });
                    });
                }

                // Issue, can't save this record
                //alert(message);
                this.showScheduleValidationPopup(message, validationErrors);
            }
            else {
                console.log('No validation issues');
                await this.SaveWorkOrderScheduleItems([]);
            }
        }
    }

    executeManagerOverrideForScheduleSave = async () => {

        var scheduleOverrides = this.state.scheduleOverridesList;

        await this.hideScheduleValidationPopup();

        await this.setState({
            isShowingManagerOverridePopup: true,
            scheduleOverridesList: scheduleOverrides
        });
    }

    SaveWorkOrderScheduleItems = async (scheduleOverrides) => {
        //console.log('Possible assigned shifts', this.state.assignedShifts);

        // TODO: Validate schedule data
        // 1) TODO: Shifts for active work order cannot begin or end after the plan start/end dates
        // 2) TODO: 12 hour shift start from last shift end?
        // 3) TODO: Overlaps with other work order shifts?
        var updatedShiftList = this.state.assignedShifts.filter((item) => item.parentId == item.employeeId && ((item.scheduleType == 'DEDICATED' && item.workOrderID == this.state.workOrder.id) || (item.scheduleType == 'STANDBY')));

        //console.log('Updated shift list', updatedShiftList);

        if (updatedShiftList.length > 0) {
            this.setState({
                loading: true
            });

            try {
                //console.log(updatedShiftList);

                var response = await this.PostAPI('Schedule/SaveWorkOrderSchedule', { workOrderId: this.state.workOrder.id, employeeShifts: updatedShiftList, scheduleOverrides: scheduleOverrides });

                //console.log(response);

                if (response.status == 1) {
                    this.props.navigate('/workorder-resources/' + this.state.workOrder.id)
                }
                else {
                    alert('Failed. Please try again later.');
                }
            }
            finally {
                this.setState({
                    loading: false
                });
            }
        }
    }

    isAddShiftButtonVisible = (options) => {
        //console.log('Add shift button', options.row.data);

        // If the entry in the chart has the same work order id, we can add additional shifts, where needed
        // ONLY DEDICATED SHIFTS CAN HAVE SHIFTS ADDED
        return options.row.data && options.row.data.workOrderID == this.state.workOrderId && options.row.data.id != options.row.data.employeeId && options.row.data.scheduleType == 'DEDICATED';
    }

    isRequestOTApprovalButtonVisible = (options) => {
        //console.log('OTApproval button visibility', options.row.data);
        //console.log('Visible?', options.row.data && options.row.data.requiresOTApproval && this.state.workOrder.requiresOTApproval && !options.row.data.otApprovalBlocked);

        return options.row.data && options.row.data.requiresOTApproval && this.state.workOrder.requiresOTApproval && !options.row.data.otApprovalBlocked/* && options.row.data.workOrderID == this.state.workOrder.id*/;
    }

    isOTBlockedButtonVisible = (options) => {
        //console.log('OTBlocked button visibility', options.row.data);
        //console.log('Visible?', options.row.data && options.row.data.otApprovalBlocked);

        return options.row.data && options.row.data.otApprovalBlocked/* && options.row.data.workOrderID == this.state.workOrder.id*/;
    }

    isRemoveButtonVisible = (options) => {
        //console.log(options);
        //console.log('Can Delete', options.row.data && options.row.data.createUserId == this.state.employeeId && options.row.data.statusCode == 'SUBMITTED');

        return options.row.data && options.row.data.employeeId == options.row.data.id && options.row.data.display.indexOf('PREVIEW') > -1;
    }

    removeScheduleEntry = async (event, e) => {
        var currentAssignedShifts = this.state.assignedShifts;

        var rowsToRemove = currentAssignedShifts.filter((item) => item.employeeId == e.row.data.employeeId);

        //console.log(rowsToRemove);

        rowsToRemove.map((item, index) => {
            var deleteIndex = currentAssignedShifts.indexOf(item);

            currentAssignedShifts.splice(deleteIndex, 1);
        });

        await this.setState({
            assignedShifts: currentAssignedShifts
        });

        this.updateShiftData();
        this.dataGrid.instance().repaint();
    }

    requestOTApproval = async (event, e) => {
        //console.log(e);

        // TODO: Check for existing approval, maybe?
        // TODO: What if concurrent approvals (like one employee being requested for more than one shift?
        var dialogResult = await confirm('Are you sure you want to request OT Approval for this shift?');

        if (dialogResult) {
            var approvalToSubmit = {
                workOrderId: e.row.data.workOrderID,
                employeeId: e.row.data.employeeId,
                weekBegin: moment(this.state.selectedSchedulePeriod.fromDate).format('yyyy-MM-DDTHH:mm:ss'),
                shiftBegin: e.row.data.planStartDate,
                shiftEnd: e.row.data.planEndDate
            };

            var response = await this.PostAPI('OvertimeApproval/SaveOvertimeApproval', approvalToSubmit);

            //console.log(response);

            if (response.status != 1) {
                alert('Failed. Please try again later.');
            }
        }
    }

    // TODORESERVEADDITION
    addAdditionalShift = async (event, e) => {
        //console.log(e);

        var currentShifts = this.state.assignedShifts;

        var insertIndex = currentShifts.findIndex((item) => item.id == e.row.data.id);

        //console.log(insertIndex);

        var newShiftStartDate = moment(e.row.data.planEndDate);
        var newShiftEndDate = moment(e.row.data.planEndDate).add('h', 12);

        var begin = newShiftStartDate.isBefore(moment(this.state.selectedSchedulePeriod.fromDate)) ? this.state.selectedSchedulePeriod.fromDate : newShiftStartDate;
        var end = newShiftEndDate.isAfter(moment(this.state.selectedSchedulePeriod.toDate)) ? this.state.selectedSchedulePeriod.toDate : newShiftEndDate;

        var currentScheduledHours = (moment(end).diff(moment(begin), 'minute') / 60);

        var newItem = {
            id: uuidv4(),
            employeeId: e.row.data.employeeId,
            planStartDate: newShiftStartDate.format('MM/DD/YYYY HH:mm'),
            planEndDate: newShiftEndDate.format('MM/DD/YYYY HH:mm'),
            parentId: e.row.data.parentId,
            display: this.state.workOrder.workOrderName + ' (' + this.state.workOrder.workOrderNumber + ')',
            workOrderID: this.state.workOrder.id,
            currentScheduledHours: currentScheduledHours.toFixed(2),
            scheduleType: 'DEDICATED',
            color: "#e97f02"
        };

        // TODO: Maybe try to get it to insert after the last shift, not necessarily under the first shift
        currentShifts.splice(insertIndex + 1, 0, newItem);

        // Then, update projected hours
        var primaryItem = currentShifts.find((item) => item.id == item.employeeId && item.employeeId == e.row.data.employeeId);
        primaryItem.currentScheduledHours = (parseFloat(primaryItem.currentScheduledHours) + currentScheduledHours).toFixed(2);

        await this.setState({
            assignedShifts: currentShifts
        });

        this.updateShiftData();
    }

    onTaskEditDialogShowing = (e) => {
        console.log(e);

        var localArrayItem = this.shiftDataSource._array.find((item) => item.id == e.key);

        if (localArrayItem.workOrderID == this.state.workOrderId && localArrayItem.scheduleType == 'DEDICATED') {
            e.readOnlyFields.push('display');
            e.hiddenFields.push('progress');
        }
        else {
            //console.log('Canceling');
            e.cancel = true;
        }
    }

    onContextMenuPreparing = (e) => {
        console.log(e);

        if (!e.data) {
            e.cancel = true;
        }
        else {
            // Find the original row in the underlying collection
            var currentAssignedShifts = this.state.assignedShifts;

            var originalItem = currentAssignedShifts.find((item) => item.id == e.targetKey);

            if (originalItem.workOrderID == this.state.workOrderId && e.data.id != originalItem.employeeId && originalItem.parentId && originalItem.scheduleType == 'DEDICATED') {
                // Hide details and delete
                // TODO
                //e.items[0].items[1].visible = false;
                //e.items[0].items[0].text = 'New Shift';
                e.items[0].visible = false;

                e.items[1].text = 'Shift Details...';
                e.items[2].text = 'Delete Shift';
            }
            else {
                e.cancel = true;
            }
        }
    }

    onTaskUpdating = async (e) => {
        //console.log('onTaskUpdating', e);
        if (e.values.display.indexOf(this.state.workOrder.workOrderName + ' (' + this.state.workOrder.workOrderNumber + ')') == 0) {
            // Update hours
            var oldShiftStartDate = moment(e.values.planStartDate);
            var oldShiftEndDate = moment(e.values.planEndDate);

            var newShiftStartDate = e.newValues.planStartDate ? moment(e.newValues.planStartDate) : oldShiftStartDate;
            var newShiftEndDate = e.newValues.planEndDate ? moment(e.newValues.planEndDate) : oldShiftEndDate;

            var oldBegin = oldShiftStartDate.isBefore(moment(this.state.selectedSchedulePeriod.fromDate)) ? moment(this.state.selectedSchedulePeriod.fromDate) : oldShiftStartDate;
            var oldEnd = oldShiftEndDate.isAfter(moment(this.state.selectedSchedulePeriod.toDate)) ? moment(this.state.selectedSchedulePeriod.toDate) : oldShiftEndDate;

            // Update new calculated/projected hours
            var newBegin = newShiftStartDate.isBefore(moment(this.state.selectedSchedulePeriod.fromDate)) ? moment(this.state.selectedSchedulePeriod.fromDate) : newShiftStartDate;
            var newEnd = newShiftEndDate.isAfter(moment(this.state.selectedSchedulePeriod.toDate)) ? moment(this.state.selectedSchedulePeriod.toDate) : newShiftEndDate;

            var previousScheduledHours = (oldEnd.diff(oldBegin, 'minute') / 60);
            var newScheduledHours = (newEnd.diff(newBegin, 'minute') / 60);

            //console.log(previousScheduledHours);
            //console.log(newScheduledHours);

            // Find the original row in the underlying collection
            var currentAssignedShifts = this.state.assignedShifts;

            var originalItem = currentAssignedShifts.find((item) => item.id == e.key);

            if (originalItem && originalItem.scheduleType == 'DEDICATED') {
                //console.log('Original item', originalItem);

                if (e.newValues.planEndDate) {
                    originalItem.planEndDate = e.newValues.planEndDate;
                }

                if (e.newValues.planStartDate) {
                    originalItem.planStartDate = e.newValues.planStartDate;
                }

                if (previousScheduledHours != newScheduledHours) {
                    // Update calculations
                    originalItem.currentScheduledHours = newScheduledHours.toFixed(2);

                    // Update in the local array for now, actually
                    var localArrayItem = this.shiftDataSource._array.find((item) => item.id == e.key);
                    localArrayItem.currentScheduledHours = newScheduledHours.toFixed(2);

                    // Update root item
                    var rootItem = this.shiftDataSource._array.find((item) => item.id == localArrayItem.parentId);

                    //console.log(rootItem);

                    rootItem.currentScheduledHours = rootItem.currentScheduledHours - previousScheduledHours;
                    rootItem.currentScheduledHours += newScheduledHours;

                    //console.log(rootItem);
                }

                await this.setState({
                    assignedShifts: currentAssignedShifts
                });
            }
            else {
                e.cancel = true;
            }
        }
        else {
            e.cancel = true;
        }
    }

    onTaskMoving = (e) => {
        //console.log(e);

        // TODO: Update hours
        if (e.values.display.indexOf(this.state.workOrder.workOrderName + ' (' + this.state.workOrder.workOrderNumber + ')') == 0) {
            // Find the original row in the underlying collection
            var currentAssignedShifts = this.state.assignedShifts;

            var originalItem = currentAssignedShifts.find((item) => item.id == e.key);

            //console.log('Moving item', originalItem);

            if (originalItem && originalItem.scheduleType == 'DEDICATED') {
                //console.log('Original item', originalItem);
                if (e.newValues.planEndDate) {
                    originalItem.planEndDate = e.newValues.planEndDate;
                }

                if (e.newValues.planStartDate) {
                    originalItem.planStartDate = e.newValues.planStartDate;
                }

                this.setState({
                    assignedShifts: currentAssignedShifts
                });
            }
            else {
                e.cancel = true;
            }
        }
        else {
            e.cancel = true;
        }
    }

    onTaskDeleting = (e) => {
        //console.log(e);

        // TODO: Prevent last shift delete?
        // Update hours
        var oldShiftStartDate = moment(e.values.planStartDate);
        var oldShiftEndDate = moment(e.values.planEndDate);

        var oldBegin = oldShiftStartDate.isBefore(moment(this.state.selectedSchedulePeriod.fromDate)) ? moment(this.state.selectedSchedulePeriod.fromDate) : oldShiftStartDate;
        var oldEnd = oldShiftEndDate.isAfter(moment(this.state.selectedSchedulePeriod.toDate)) ? moment(this.state.selectedSchedulePeriod.toDate) : oldShiftEndDate;

        var previousScheduledHours = (oldEnd.diff(oldBegin, 'minute') / 60);

        //console.log(previousScheduledHours);
        //console.log(newScheduledHours);

        // Find the original row in the underlying collection
        var currentAssignedShifts = this.state.assignedShifts;

        //console.log(currentAssignedShifts);
        var originalItemIndex = currentAssignedShifts.findIndex((item) => item.id == e.key);

        if (originalItemIndex) {
            //console.log('Original item index', originalItemIndex);
            var deletedItems = currentAssignedShifts.splice(originalItemIndex, 1);

            //console.log(deletedItems);
            //console.log(this.shiftDataSource._array);

            // Update root item
            var rootItem = this.shiftDataSource._array.find((item) => item.id == deletedItems[0].employeeId);

            //console.log(rootItem);

            rootItem.currentScheduledHours = rootItem.currentScheduledHours - previousScheduledHours;

            this.setState({
                assignedShifts: currentAssignedShifts
            });
        }
    }

    showAvailabilityResponses = () => {
        this.workOrderResponsesGrid.instance().refresh();

        this.setState({
            isViewingResponses: true,
            responseFilter: true
        });
    }

    onResponsesFilterOptionChanged = async (e) => {
        //console.log('Responses Option Changed', e);

        await this.setState({
            responseFilter: e.value
        });

        this.workOrderResponsesGrid.instance().refresh();
    }

    // TODO: Question: Do we need to allow for sending availability message(s) from the OT Approvals window?
    // TODO: Question: Do we need to show overtime approval status in the Check Responses window?
    showOTApprovals = () => {
        this.workOrderOTApprovalsGrid.instance().refresh();

        this.setState({
            isViewingOTApprovals: true
        });
    }

    showScheduleValidationPopup = (message, scheduleOverridesList) => {
        //this.workOrderOTApprovalsGrid.instance().refresh();

        this.setState({
            isShowingScheduleValidationPopup: true,
            scheduleValidationMessage: message,
            scheduleOverridesList: scheduleOverridesList
        });
    }

    hideOTApprovals = () => {
        this.setState({
            isViewingOTApprovals: false
        });
    }

    hidePopup = () => {
        this.setState({
            isViewingResponses: false
        });
    }

    hideScheduleValidationPopup = async () => {
        await this.setState({
            isShowingScheduleValidationPopup: false,
            scheduleValidationMessage: '',
            scheduleOverridesList: []
        });
    }

    hideConfirmationErrors = async () => {
        await this.setState({
            isViewingConfirmationErrors: false,
            currentConfirmationErrors: []
        });
    }

    hideManagerOverridePopup = () => {
        this.setState({
            isShowingManagerOverridePopup: false,
            scheduleValidationMessage: '',
            scheduleOverridesList: [],
            managerOverrideConfirmation: {
                reason: '',
                approverEmployeeNo: '',
                passPin: ''
            }
        });
    }

    acceptManagerOverride = async (e) => {

        var result = this.managerOverrideFormControl.instance().validate();
        if (result.isValid) {
            console.log(this.state.managerOverrideConfirmation);

            var couldNotBeConfirmedMessage = 'Manager information could not be confirmed.';

            // Lookup employee
            // TODO: Weird thing here. API has difficult time handling null return
            try {
                var managerLookupData = await this.FetchAPI('Employee/GetEmployeeByNumber?employeeNo=' + this.state.managerOverrideConfirmation.approverEmployeeNo);

                if (managerLookupData) {
                    // Check pass pin
                    console.log(managerLookupData);

                    if (!managerLookupData.passCode || managerLookupData.passCode == '') {
                        alert(couldNotBeConfirmedMessage);
                    }
                    else if (managerLookupData.passCode != this.state.managerOverrideConfirmation.passPin) {
                        alert(couldNotBeConfirmedMessage);
                    }
                    else {
                        // Correct, continue
                        var scheduleOverrides = this.state.scheduleOverridesList;

                        console.log(scheduleOverrides);

                        scheduleOverrides.map((override) => {
                            override.reason = this.state.managerOverrideConfirmation.reason;
                            override.approverEmployeeId = managerLookupData.employeeId;
                        });

                        this.hideManagerOverridePopup();

                        await this.SaveWorkOrderScheduleItems(scheduleOverrides);
                    }
                }
                else {
                    alert(couldNotBeConfirmedMessage);
                }

                //var commentData = this.state.commentsForm;
                //commentData.workOrderId = this.state.workOrder.id;

                //const result = await this.PostAPI('ScheduleComment', commentData);

                ////console.log(result);

                //if (result.status == 1) {
                //    this.hideCommentsLog();
                //}
                //else {
                //    //console.log(result);
                //    alert('Failed. Please try again later.');
                //}
            }
            catch (e) {
                alert(couldNotBeConfirmedMessage);
            }
        }
    }

    previewEmployeesFromApprovals = async (e) => {
        var selectedKeys = this.workOrderOTApprovalsGrid.instance().getSelectedRowKeys();

        //console.log(selectedKeys);

        if (selectedKeys.length == 0) {
            alert('Please select one or more Employees to preview.');
        }
        else {
            // Check for any selected rows that are not approved
            var s = selectedKeys.filter((keyItem) => keyItem.statusCode != 'APPROVED');

            if (s.length > 0) {
                alert('One or more selected Employees have not been approved for OT. Please check selection.');
            }
            else {
                for (var i = 0; i < selectedKeys.length; i++) {
                    ////console.log(this.customDataSource.__rawData);
                    var item = selectedKeys[i];
                    //console.log(item);

                    var rowsToRemove = this.state.assignedShifts.filter((previewItem) => previewItem.employeeId == item.employeeId);

                    //                //// console.log(rowsToRemove);

                    if (rowsToRemove.length == 0) {
                        var selectedAvailableEmployeeRecord = this.customDataSource.__rawData.find((previewItem) => previewItem.employeeId == item.employeeId);

                        // console.log(selectedAvailableEmployeeRecord);

                        if (selectedAvailableEmployeeRecord) {
                            await this.previewEmployeeDedicated(selectedAvailableEmployeeRecord);
                        }
                    }
                }

                this.updateShiftData();

                // Close modal, if successful
                this.hideOTApprovals();
            }
        }
    }

    previewEmployeesFromResponse = async (e) => {
        var selectedKeys = this.workOrderResponsesGrid.instance().getSelectedRowKeys();

        //console.log(selectedKeys);

        if (selectedKeys.length == 0) {
            alert('Please select one or more Employees to preview.');
        }
        else {

            for (var i = 0; i < selectedKeys.length; i++) {
                //console.log(this.customDataSource.__rawData);
                var item = selectedKeys[i];

                var rowsToRemove = this.state.assignedShifts.filter((previewItem) => previewItem.employeeId == item.employeeId);

                // console.log(rowsToRemove);

                if (rowsToRemove.length == 0) {
                    //var selectedAvailableEmployeeRecord = this.customDataSource.byKey(item.employeeId);
                    var selectedAvailableEmployeeRecord = this.customDataSource.__rawData.find((previewItem) => previewItem.employeeId == item.employeeId);

                    // console.log(selectedAvailableEmployeeRecord);

                    if (selectedAvailableEmployeeRecord) {
                        await this.previewEmployeeDedicated(selectedAvailableEmployeeRecord);
                    }
                }
            }

            this.updateShiftData();

            // Close modal, if successful
            this.hidePopup();
        }
    }

    confirmAvailabilityAvailable = (e) => {
        var selectedKeys = this.dataGrid.instance().getSelectedRowKeys();

        if (selectedKeys.length == 0) {
            alert('Please select one or more Employees to confirm.');
        }
        else {
            this.setState({
                isConfirmingAvailability: true,
                availabilityConfirmationKeys: selectedKeys
            });
        }
    }

    hideAvailabilityConfirmation = () => {
        this.setState({
            isConfirmingAvailability: false,
            availabilityConfirmationKeys: []
        });
    }

    showCommentLogs = async (e) => {
        //console.log(e);

        await this.setState({
            isViewingCommentsLog: true,
            commentsForm: {
                employeeId: e.row.data.employeeId,
                comment: '',
                reasonId: ''
            }
        });

        this.employeeCommentsGrid.instance().refresh();
    }

    hideCommentsLog = () => {
        this.setState({
            isViewingCommentsLog: false
        });
    }

    sendAvailabilityConfirmations = async (e) => {
        // console.log(this.state.availabilityConfirmationKeys);

        // TODO: 2) Update available employees display with notification pending?
        var finalMessage = `${this.state.availabilityConfirmationMessage}${this.state.availabilityConfirmation.availabilityDate}?`

        var result = await this.PostAPI('AvailabilityConfirmation', { workOrderId: this.state.workOrder.id, employeeIds: this.state.availabilityConfirmationKeys, availabilityMessage: finalMessage, requestedDate: moment(this.state.availabilityConfirmation.availabilityDate).format("YYYY-MM-DDTHH:mm") });

         console.log(result);

        if (result.status == -1) {
            if (result.errorList.length > 0) {
                // Launch a window to show the failed messages
                await this.setState({
                    isViewingConfirmationErrors: true,
                    currentConfirmationErrors: result.errorList.map(item => { return { errorText: item }; })
                });
            }
            else {
                alert('One or more notifications failed to send. Please refresh and try again');
            }
        }
        else {
            alert('All notifications sent successfully.');

            this.dataGrid.instance().getSelectedRowsData().map((employee, index) => {
                // console.log(employee);

                // TODO: ABG, log an issue with Devexpress that, for some reason, repaint does update for this value with cellRender set but NOT with no cellRender value set
                employee.currentNotification = {
                    body: null,
                    respondWithin: moment().add(1, 'hours')
                };

                employee.availabilityStatus = 'Pending Response';
            });

            this.dataGrid.instance().refresh();
        }

        // TODO: Test failures
        this.hideAvailabilityConfirmation();
    }

    SaveComment = async (e) => {

        var result = this.commentsFormControl.instance().validate();
        if (result.isValid) {

            var commentData = this.state.commentsForm;
            commentData.workOrderId = this.state.workOrder.id;

            const result = await this.PostAPI('ScheduleComment', commentData);

            //console.log(result);

            if (result.status == 1) {
                this.hideCommentsLog();
            }
            else {
                //console.log(result);
                alert('Failed. Please try again later.');
            }
        }
    }
    
    overtimeApprovalsDataSource = new CustomStore({
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            return FetchAPIPromise('OvertimeApproval/GetByWorkOrderAndPeriod?workOrderId=' + this.state.workOrderId + '&periodBegin=' + this.state.selectedSchedulePeriod.fromDate);
        }
    })

    workOrderResponsesDataSource = new CustomStore({
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            return FetchAPIPromise('AvailabilityConfirmation?workOrderId=' + this.state.workOrderId + '&limitToCurrentWorkOrder=' + this.state.responseFilter);
        }
    })

    employeeCommentsDataSource = new CustomStore({
        load: (loadOptions) => {

            if (this.state.commentsForm.employeeId) {
                return FetchAPIPromise('ScheduleComment/GetByEmployeeId?employeeId=' + this.state.commentsForm.employeeId);
            }
        }
    });

    onValueChanged = (e) => {
        // console.log(e);

        $("#availabilityMessagePreviewLabel").html(`Message: ${this.state.availabilityConfirmationMessage}${this.state.availabilityConfirmation.availabilityDate}?`);
    }

    totallyATest = (options) => {
        //console.log('hit the render');
        return <label>{options.row.data.availabilityStatus}</label>
    }

    experienceRender = (options) => {
        //console.log('Experience Render', options);

        var competencies = options.row.data.competencyList;

        var controls = [];

        var experienceKey = options.row.data.employeeId;

        controls.push(<span key={experienceKey}>
            <span style={{
                color: options.value >= 700 ? '#57d500'
                    : (options.value >= 300 && options.value < 700) ? '#ffa500'
                        : options.value < 300 ? '#ff2e00'
                            : '#ffffff'
            }}>
                &#x2B24;
                </span>
        </span>);

        competencies.map((competency) => {
            var codeKey = options.row.data.employeeId + '|' + competency.tagCode;
            var iconKey = options.row.data.employeeId + '|' + competency.tagIcon;

            controls.push(<span key={codeKey}>&nbsp;</span>);
            controls.push(<i key={iconKey} style={{ height: '15px !important', width: '15px !important' }} className={`dx-icon-${competency.tagIcon}`} title={competency.tagName} />);
        });

        return (<div style={{ textAlign: 'left' }}>{controls}</div>);
    }

    availableEmployeeActionRender = (options) => {
        //console.log(options);

        // Check whether this row is already added
        var existingItem = this.state.assignedShifts.find((item) => item.employeeId == options.row.data.employeeId && item.employeeId == item.id);

        if (!existingItem) {
            return <a href="#" onClick={(e) => this.previewEmployee(e, options.row)}>Preview</a>;
        }
        else if (existingItem.display.indexOf('PREVIEW') > -1) {
            return <label>Previewing</label>;
        }
        else {
            return <label>Already Added</label>;
        }
    }

    availableEmployeeStandbyActionRender = (options) => {
        //console.log('Logged options', options);

        var existingItem = this.state.assignedShifts.find((item) => item.employeeId == options.employeeId && item.employeeId == item.id);

        if (!existingItem) {
            return (<a href="#" onClick={(e) => this.previewStandbyShift(e, options)}>Preview</a>);
        }
        else if (existingItem.display.indexOf('PREVIEW') > -1) {
            return <label>Previewing</label>;
        }
        else {
            return <label>Already Added</label>;
        }
    }

    selectedSchedulePeriodChanged = async (e) => {
        //console.log('Schedule Period Changed', e);

        await this.setState({
            selectedSchedulePeriod: e.selectedItem
        });

        this.dataGrid.instance().refresh();

        this.updateShiftData();
    }

    phoneCellRender = (e) => {
        //console.log(e);

        return this.formatPhoneNumber(e.value);
    }

    buttonsColumnRender = (e) => {

        return (<>
            {e.row.data && e.row.data.workOrderID == this.state.workOrderId && e.row.data.id != e.row.data.employeeId && e.row.data.scheduleType == 'DEDICATED' && <Button className="gantt-icon-buttons" hint="Add Shift" stylingMode="text" icon="plus" onClick={(event) => this.addAdditionalShift(event, e)} />}&nbsp;
            {e.row.data && e.row.data.employeeId == e.row.data.id && e.row.data.display.indexOf('PREVIEW') > -1 && <Button hint="Remove" stylingMode="text" className="gantt-icon-buttons" icon="remove" onClick={(event) => this.removeScheduleEntry(event, e)} />}&nbsp;
            {e.row.data && e.row.data.requiresOTApproval && this.state.workOrder.requiresOTApproval && !e.row.data.otApprovalBlocked/* && options.row.data.workOrderID == this.state.workOrder.id*/ && <Button className="gantt-icon-buttons" stylingMode="text" hint="Request OT Approval" icon="clock" onClick={(event) => this.requestOTApproval(event, e)} />}&nbsp;
            {e.row.data && e.row.data.otApprovalBlocked/* && options.row.data.workOrderID == this.state.workOrder.id*/ && <Button stylingMode="text" className="gantt-icon-buttons" disabled={true} hint="OT Approval Blocked" icon="clock" />}
        </>);
    }

    render() {
        //console.log("Beginning render");

        var filterOptions = [
            {
                value: 1,
                text: 'Show Available Employees for SHIFT/PARTIAL trip duration'
            },
            {
                value: 0,
                text: 'Show Available Employees for FULL trip duration'
            }];

        var availabilitySelectionOptions = [
            {
                value: true,
                text: 'Notified for THIS Work Order '
            },
            {
                value: false,
                text: 'Notified for ALL Work Orders '
            }
        ];

        return (
            <div className="container-fluid">
                <fieldset>
                    <header><b>Work Order</b></header>
                    <br />

                    <Form
                        id="form"
                        colCount={4}
                        formData={this.state.workOrder}>

                        <FormItem dataField="workOrderName" editorOptions={{ readOnly: true }}>
                            <Label text="Name" />
                        </FormItem>

                        <FormItem dataField="workOrderNumber" editorOptions={{ readOnly: true }}>
                            <Label text="Number" />
                        </FormItem>

                        <FormItem dataField="planStartDate" editorType="dxDateBox" editorOptions={{
                            readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime'
                        }}>
                            <Label text="Plan Start Date" />
                        </FormItem>

                        <FormItem dataField="planEndDate" editorType="dxDateBox" editorOptions={{
                            readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime'
                        }}>
                            <Label text="Plan End Date" />
                        </FormItem>
                    </Form>
                </fieldset>
                <br />

                <fieldset>
                    <header><b>Available Employees</b></header>
                    <br />

                    <div className="d-flex justify-content-between">
                        <div className="d-flex me-auto align-items-center">
                            <Button disabled={this.IsReadOnly()} icon="message" text="Confirm Availability" onClick={this.confirmAvailabilityAvailable} />
                        </div>

                        <div className="d-flex ms-auto align-items-center">
                            <CheckBox text='Include Contract Break Threshold' value={this.state.includeContractBreak} onValueChanged={this.onContractBreakFilterChanged}
                                visible={this.state.enforceContractTripBreakCompliance} />&nbsp;&nbsp;&nbsp;
                            <CheckBox text='Exclude Employees w/ Leave' value={this.state.excludeOverlappingLeave} onValueChanged={this.onLeaveFilterChanged} />&nbsp;&nbsp;&nbsp;
                            <CheckBox text='Exclude Employees w/ Overlapping Shifts' value={this.state.excludeOverlappingShifts} onValueChanged={this.onShiftFilterChanged} />&nbsp;&nbsp;&nbsp;
                            <RadioGroup dataSource={filterOptions} layout="horizontal" displayExpr="text" valueExpr="value" value={this.state.availabilityMode} onValueChanged={this.onOptionChanged} />
                        </div>
                    </div>
                    <br />
                    <div>
                        <p className="fs-6 fw-bold">*This table allows for multiple columns to be selected. Hold Shift key and Click the column headers.</p>
                    </div>
                    <DataGrid dataSource={this.customDataSource} showBorders={true} allowColumnResizing={true}
                        ref={ref => this.dataGrid = ref}>
                        <FilterRow visible={true} />
                        <Sorting mode='multiple'/>
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />
                        <Selection mode={(this.IsReadOnly() ? 'none' : 'multiple')} selectAllMode="page" />

                        <Column caption="Comp." width={85} allowFiltering={false} alignment="center" dataField="experienceMetric" cellRender={this.experienceRender} />
                        <Column caption="Availability Status" dataField="availabilityStatus" cellRender={this.totallyATest} />
                        <Column caption="Employee No" dataField="employeeNo" />
                        <Column caption="First Name" dataField="firstName" />
                        <Column caption="Middle Name" dataField="middleName" />
                        <Column caption="Last Name" dataField="lastName" />
                        <Column caption="Gender" dataField="sex" />
                        <Column caption="Phone" dataField="homePhone" cellRender={this.phoneCellRender} />
                        <Column caption="Office" dataField="office" />
                        <Column caption="Worked Hours" dataField="currentWorkedHours" dataType="number" defaultSortIndex={0} defaultSortOrder="asc" />
                        <Column caption="Scheduled Hours" dataField="currentScheduledHours" dataType="number" />
                        <Column caption="Last Completed Air Trip" dataField="lastCompletedAirTrip" dataType="datetime" format="MM/dd/yy, HH:mm" />
                        <Column caption="Last Trip" dataField="lastCompletedTripDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                        <Column caption="Last Trip Type" dataField="lastCompletedTripTrafficType" />
                        <Column caption="Last Trip Vehicle" dataField="lastCompletedTripTransportType" />
                        <Column type="buttons">
                            <GridButton text="Comment Log" icon="comment" onClick={this.showCommentLogs} />
                        </Column>
                        <Column visible={!this.IsReadOnly()} type="buttons">
                            <GridButton render={this.availableEmployeeActionRender} />
                        </Column>
                    </DataGrid>
                </fieldset>
                <br />
                <Accordion collapsible={true} ref={ref => this.standbyScheduleAccordion = ref} deferRendering={false}>
                    <AccordionItem title="Standby Schedules">
                        <div id="kanban">
                            <ScrollView
                                className="scrollable-board"
                                direction="horizontal"
                                showScrollbar="always">
                                <Sortable
                                    allowReordering={false}
                                    allowDropInsideItem={false}
                                    className="sortable-lists"
                                    itemOrientation="horizontal"
                                    handle=".list-title">
                                    {this.state.standbySchedules && this.state.standbySchedules.map((tasks, listIndex) => {
                                        //console.log('id', tasks.display);
                                        //console.log('Current', listIndex);
                                        var key = tasks.display + listIndex;
                                        var displayKey = key + 'display';

                                        return (<div key={key} className="list">
                                            <div key={displayKey} className="list-title dx-theme-text-color">{tasks.display}</div>
                                            <ScrollView
                                                style={{ height: '300px' }}
                                                className="scrollable-list"
                                                direction="vertical"
                                                showScrollbar="always">
                                                <Sortable
                                                    style={{ minHeight: '280px'}}
                                                    className="sortable-cards"
                                                    group="cardsGroup"
                                                    data={listIndex}
                                                    allowReordering={false}
                                                    allowDropInsideItem={false}>
                                                    {tasks.schedules && tasks.schedules.map((task) =>
                                                        <div key={task.id} className="card dx-card dx-theme-text-color dx-theme-background-color">
                                                            <div key={task.id + 'priority'} className={`card-priority priority-${task.Task_Priority}`}></div>
                                                            <div key={task.id + 'subject'} className="card-subject"><b>{moment(task.planStartDate).format('HH:mm')}</b> - {moment(task.planEndDate).format('MM/DD HH:mm')}</div>
                                                            <div key={task.id + 'description'} className="card-description">{task.description}</div>
                                                            <div key={task.id + 'assignee'} className="card-assignee">{task.display}</div>
                                                            <div key={task.id + 'standby'} style={{ paddingTop: '10px', textAlign: 'right' }}>{this.availableEmployeeStandbyActionRender(task)}</div>
                                                        </div>)}
                                                </Sortable>
                                            </ScrollView>
                                        </div>);
                                    })}
                                </Sortable>
                            </ScrollView>
                        </div>
                    </AccordionItem>
                </Accordion>
                <br />

                <div className="d-flex">
                    <div className="ms-auto me-3">
                        <label>Schedule Period</label>
                        <SelectBox width={250} value={this.state.selectedSchedulePeriod} dataSource={this.state.weeks} displayExpr="display"
                            onSelectionChanged={this.selectedSchedulePeriodChanged} />
                    </div>
                </div>

                <fieldset>
                    <header><b>Schedule Preview</b></header>
                    <br />

                    <div className="d-flex">
                        <div className="ms-auto me-3">
                            <Button disabled={this.IsReadOnly()} icon="clock" text="Check OT Approvals" onClick={this.showOTApprovals} visible={this.state.workOrder.requiresOTApproval} />
                            &nbsp;
                            <Button icon="comment" text="Check Responses" onClick={this.showAvailabilityResponses} />
                            &nbsp;
                            <Button disabled={this.IsReadOnly()} icon="save" text="Finalize Schedule" onClick={this.finalizeSchedule} />
                        </div>
                    </div>
                    <br />

                    <div className="row justify-content-center">
                        <div className="col">
                            <span style={{
                                color: '#5b9d95'
                            }}>
                                &#x2B24;
                            </span>
                            &nbsp;
                            <label className="dx-field-item-label-text">Open/Active Work Order</label>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div className="col">
                            <span style={{
                                color: '#78b6d9'
                            }}>
                                &#x2B24;
                            </span>
                            &nbsp;
                            <label className="dx-field-item-label-text">Completed Work Order</label>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div className="col">
                            <span style={{
                                color: '#a37182'
                            }}>
                                &#x2B24;
                            </span>
                            &nbsp;
                            <label className="dx-field-item-label-text">Canceled Work Order</label>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div className="col">
                            <span style={{
                                color: '#3cbab2'
                            }}>
                                &#x2B24;
                            </span>
                            &nbsp;
                            <label className="dx-field-item-label-text">Employee</label>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div className="col">
                            <span style={{
                                color: '#e97f02'
                            }}>
                                &#x2B24;
                            </span>
                            &nbsp;
                            <label className="dx-field-item-label-text">Current Work Order</label>
                        </div>
                        
                        {/*&nbsp;&nbsp;&nbsp;*/}
                        {/*<div className="col">*/}
                        {/*    <span style={{*/}
                        {/*        color: '#f1929f'*/}
                        {/*    }}>*/}
                        {/*        &#x2B24;*/}
                        {/*    </span>*/}
                        {/*    &nbsp;*/}
                        {/*    <label className="dx-field-item-label-text">Invalid</label>*/}
                        {/*</div>*/}
                        {/*&nbsp;&nbsp;&nbsp;*/}
                        {/*<div className="col">*/}
                        {/*    <span style={{*/}
                        {/*        color: '#efcd7c'*/}
                        {/*    }}>*/}
                        {/*        &#x2B24;*/}
                        {/*    </span>*/}
                        {/*    &nbsp;*/}
                        {/*    <label className="dx-field-item-label-text">Unreviewed Hours</label>*/}
                        {/*</div>*/}
                        {/*&nbsp;&nbsp;&nbsp;*/}
                        {/*<div className="col">*/}
                        {/*    <span style={{*/}
                        {/*        color: '#8ed962'*/}
                        {/*    }}>*/}
                        {/*        &#x2B24;*/}
                        {/*    </span>*/}
                        {/*    &nbsp;*/}
                        {/*    <label className="dx-field-item-label-text">Reviewed Hours</label>*/}
                        {/*</div>*/}
                        {/* TODO: Reserve shift, legend, etc */}
                    </div>
                    <br />

                    <Gantt
                        taskListWidth={665}
                        scaleType="days"
                        height={700}
                        ref={ref => this.schedulingGantt = ref} showResources={false} onContextMenuPreparing={this.onContextMenuPreparing} onTaskEditDialogShowing={this.onTaskEditDialogShowing}
                        onTaskMoving={this.onTaskMoving} onTaskUpdating={this.onTaskUpdating} onTaskDeleting={this.onTaskDeleting}>
                        <Editing enabled={!this.IsReadOnly()} allowTaskAdding={false} allowTaskDeleting={false} allowResourceAdding={false} allowResourceUpdating={false}
                            allowResourceDeleting={false} allowTaskResourceUpdating={false} />
                        <Validation autoUpdateParentTasks={false} />

                        <StripLine start={this.state.selectedSchedulePeriod.fromDate} title="Schedule Period Begin" cssClass="current-time" />
                        <StripLine start={this.state.selectedSchedulePeriod.toDate} title="Schedule Period End" cssClass="current-time" />
                        <StripLine start={this.state.workOrder.planStartDate} end={this.state.workOrder.planEndDate} title="Current Work Order" />

                        <Tasks dataSource={this.shiftDataSource}
                            keyExpr="id"
                            startExpr="planStartDate" endExpr="planEndDate"
                            titleExpr="display"
                            colorExpr="color" />

                        <Toolbar>
                            <Item name="collapseAll" />
                            <Item name="expandAll" />
                            <Item name="separator" />
                            <Item name="zoomIn" />
                            <Item name="zoomOut" />
                        </Toolbar>

                        <GanttColumn dataField="display" caption="Work Order/Employee/Shift" width={225} />
                        <GanttColumn dataField="planStartDate" caption="Plan Start Date" dataType="datetime" format="MM/dd/yy, HH:mm" width={115} />
                        <GanttColumn dataField="planEndDate" caption="Plan End Date" dataType="datetime" format="MM/dd/yy, HH:mm" width={115} />
                        {/*TODO: Do we need to see current worked hours here?*/}
                        {/*<GanttColumn dataField="currentWorkedHours" caption="Current Hours" />*/}
                        <GanttColumn dataField="currentScheduledHours" caption="Effective Hours" dataType="number" width={120} cellRender={renderGridCell} />

                        <GanttColumn dataField="id" visible={false} />
                        <GanttColumn dataField="workOrderID" visible={false} />
                        <GanttColumn dataField="employeeId" visible={false} />

                        <GanttColumn visible={!this.IsReadOnly()} cellRender={this.buttonsColumnRender} width={100} />

                        <GanttFilterRow visible={true} />
                    </Gantt>
                </fieldset>

                <Popup visible={this.state.isViewingResponses} onHiding={this.hidePopup} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Availability Responses">

                    <header><b>Filters</b></header>
                    <br />
                    <RadioGroup value={this.state.responseFilter} dataSource={availabilitySelectionOptions} displayExpr='text' valueExpr='value' layout='horizontal' onValueChanged={this.onResponsesFilterOptionChanged} />
                    <br />

                    <ScrollView width='100%' height='95%'>
                        <DataGrid dataSource={this.workOrderResponsesDataSource} showBorders={true} allowColumnResizing={true}
                            ref={ref => this.workOrderResponsesGrid = ref}
                            keyExpr="employeeId">
                            <FilterRow visible={true} />
                            <Selection mode={(this.IsReadOnly() ? 'none' : 'multiple')} selectAllMode="page" />
                            <MasterDetail autoExpandAll={true} enabled={true} render={ResponseDetailItemRender} />

                            <Column caption="Employee No" dataField="employeeNo" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Gender" dataField="sex" />
                            <Column caption="Email" dataField="email" />
                            <Column caption="Phone" dataField="homePhone" />
                            <Column caption="Office" dataField="office" />
                        </DataGrid>
                    </ScrollView>

                    <Button text="Preview" onClick={this.previewEmployeesFromResponse} />
                    &nbsp;
                    <Button text="Cancel" onClick={this.hidePopup} />
                </Popup>

                <Popup visible={this.state.isShowingScheduleValidationPopup} onHiding={this.hideScheduleValidationPopup} dragEnabled={false}
                    closeOnOutsideClick={false} showTitle={true} title="Schedule Validation" height={250} width={600}>
                    <ScrollView width='100%' height='75%'>
                        <p>{parse(this.state.scheduleValidationMessage)}</p>
                    </ScrollView>

                    <div className="d-flex mt-auto p-2 justify-content-center">
                        <Button text="OK" onClick={this.hideScheduleValidationPopup} />
                        &nbsp;
                        <Button text="Manager Override" onClick={this.executeManagerOverrideForScheduleSave} />
                    </div>
                </Popup>

                <Popup visible={this.state.isShowingManagerOverridePopup} onHiding={this.hideManagerOverridePopup} dragEnabled={false}
                    closeOnOutsideClick={false} showTitle={true} title="Manager Override" height={300} width={600}>
                    <Form width={500}
                        id="form" ref={ref => this.managerOverrideFormControl = ref}
                        formData={this.state.managerOverrideConfirmation}
                        colCount={1}>

                        <FormItem editorType="dxTextBox" dataField="reason" editorOptions={{ maxLength:1000 }}>
                            <Label text="Reason" />
                            <RequiredRule />
                            <StringLengthRule max={1000} />
                        </FormItem>

                        <FormItem editorType="dxTextBox" dataField="approverEmployeeNo" editorOptions={{ maxLength: 6 }}>
                            <Label text="Manager Employee #" />
                            <RequiredRule />
                            <StringLengthRule max={6} />
                        </FormItem>

                        <FormItem editorType="dxTextBox" dataField="passPin" editorOptions={{ mode: 'password', maxLength: 4 }}>
                            <Label text="Pass Code" />
                            <RequiredRule />
                            <StringLengthRule max={4} />
                        </FormItem>
                    </Form>
                    <br />

                    <div className="d-flex mt-auto p-2 justify-content-center">
                        <Button text="OK" onClick={this.acceptManagerOverride} />
                        &nbsp;
                        <Button text="Cancel" onClick={this.hideManagerOverridePopup} />
                    </div>
                </Popup>

                <Popup visible={this.state.isViewingOTApprovals} onHiding={this.hideOTApprovals} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="OT Approvals">
                    <ScrollView width='100%' height='95%'>
                        <DataGrid dataSource={this.overtimeApprovalsDataSource} showBorders={true} allowColumnResizing={true}
                            ref={ref => this.workOrderOTApprovalsGrid = ref}
                            keyExpr="employeeId">
                            <FilterRow visible={true} />
                            <Selection mode={(this.IsReadOnly() ? 'none' : 'multiple')} selectAllMode="page" />
                            {<MasterDetail autoExpandAll={true} enabled={true} render={OTApprovalDetailItemRender} />}

                            <Column caption="Employee No" dataField="employeeNo" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Gender" dataField="sex" />
                            <Column caption="Email" dataField="email" />
                            <Column caption="Phone" dataField="homePhone" />
                            <Column caption="Office" dataField="office" />
                        </DataGrid>
                    </ScrollView>

                    <Button text="Preview" onClick={this.previewEmployeesFromApprovals} />
                    <Button text="Cancel" onClick={this.hideOTApprovals} />
                </Popup>

                <Popup visible={this.state.isConfirmingAvailability} onHiding={this.hideAvailabilityConfirmation} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Confirm Availability" width={500} height={275}>

                    <Form width={300}
                        id="form"
                        formData={this.state.availabilityConfirmation} onFieldDataChanged={this.onValueChanged}
                        colCount={1}>

                        <FormItem editorType="dxSelectBox" dataField="availabilityDate" editorOptions={{
                            dataSource: this.state.availabilityBeginDates
                        }}>
                            <Label text="Begin Date" />
                        </FormItem>
                    </Form>
                    <br />

                    <label id="availabilityMessagePreviewLabel">{`Message: ${this.state.availabilityConfirmationMessage} ${this.state.availabilityConfirmation.availabilityDate}?`}</label>
                    <br />
                    <br />

                    <label>{`Sending confirmations to ${this.state.availabilityConfirmationKeys.length} employees`}</label>
                    <br />
                    <br />

                    <Button text="Send Confirmation" onClick={this.sendAvailabilityConfirmations} />
                    &nbsp;
                    <Button text="Cancel" onClick={this.hideAvailabilityConfirmation} />
                </Popup>

                <Popup visible={this.state.isViewingCommentsLog} onHiding={this.hideCommentsLog} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Add New Comment">
                    <ScrollView>
                    <Form readOnly={this.IsReadOnly()} ref={ref => this.commentsFormControl = ref}
                        id="form" formData={this.state.commentsForm} colCount={2}>

                        <FormItem editorType="dxSelectBox" dataField="reason" editorOptions={{
                            dataSource: this.state.commentReasons,
                            displayExpr: 'description',
                            valueExpr: 'id',
                            searchEnabled: true
                        }} colSpan={2}>
                            <Label text="Reason" />
                            <RequiredRule />
                        </FormItem>

                        <FormItem editorType="dxTextArea" dataField="comment" editorOptions={{
                            maxLength: 2000
                        }} colSpan={2}>
                            <Label text="Comment" />
                        </FormItem>

                        <br />

                        <Item itemType="group" caption="Comments Log List" colCount={1} colSpan={2}>
                            <DataGrid dataSource={this.employeeCommentsDataSource} showBorders={true} allowColumnResizing={true}
                                ref={ref => this.employeeCommentsGrid = ref} height={350}>
                                <FilterRow visible={true} />
                                <Paging defaultPageSize={10} />
                                <Pager showPageSizeSelector={true}
                                    allowedPageSizes={[5, 10, 20]}
                                    showInfo={true} />

                                <Column caption="Submitted Date" dataField="createDateTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                                <Column caption="Work Order No" dataField="workOrderNumber" />
                                <Column caption="Reason" dataField="reason" />
                                <Column caption="Comments" dataField="comment" />
                                <Column caption="Submitted By" dataField="submitter" />
                            </DataGrid>
                        </Item>
                        <br />

                        <ButtonItem disabled={this.IsReadOnly()} buttonOptions={{ text: 'Save', onClick: this.SaveComment }} />
                        <ButtonItem buttonOptions={{ text: 'Close', onClick: this.hideCommentsLog }} />
                        </Form>
                    </ScrollView>
                </Popup>

                <Popup visible={this.state.isViewingConfirmationErrors} dragEnabled={false} onHiding={this.hideConfirmationErrors}
                    closeOnOutsideClick={true} showTitle={true} title="Confirmation Errors" height={350} width={600}>
                    <ScrollView>
                        <DataGrid dataSource={this.state.currentConfirmationErrors} showBorders={true} allowColumnResizing={true}>

                            <Column caption="Error Text" dataField="errorText" />
                        </DataGrid>
                        <br />
                        <Button text="Close" onClick={this.hideConfirmationErrors} />
                    </ScrollView>
                </Popup>
                <br />

                <LoadPanel
                    visible={this.state.loading} />
            </div>
        );
    }
}

export default withParams(WorkOrderScheduleContainer);

// Possible Enhancements
// 1) TODO: Do we need to have a "Show Availability filter?"
// 2) Maybe, at some point/status, we don't automatically pull available employees?

// TODOREVIEW ABG We aren't doing this currently
//if (projectedHours > 40) {
//    var onlineUser = OnlineUser.Current.LastName + "," + OnlineUser.Current.FirstName;
//    UAC_WorkOrderBLL woBll = new UAC_WorkOrderBLL();
//    var result = woBll.SaveCommentsByEmpID(empId, workOrderID, "Approving overtime overriding notification", "20", onlineUser);
//}

// TODOREVIEW ABG For enhancements
//public string GetWorkOrderRules(int empId, int response, double projectedHours)
//{
//    var result = "<div></div>";

//    if (response == 0)//New - no SMS sent on this workorder - Notify
//    {
//        result = "<button class='btn btn-info' onclick='btnNotifyEmployee_Click(event, " + empId + ");' data-dismiss='modal' aria-hidden='true'>Notify Employee</button>";
//    }
//    if (response == 1)//New - exceeding 40 hours - NotifyMgr during normal working time
//    {
//        var managerList = _woBll.GetManagerListBasedOnOffice(empId);
//        bool managerAvailable = false;
//        foreach(var m in managerList)
//        {
//            managerAvailable = CheckIfAvailable(m.ManagerId.Value);
//            if (managerAvailable) {
//                int managerId = m.ManagerId.Value;
//                result = "<button class='btn btn-warning' onclick='btnNotifyMgr_Click(event, " + empId + ", " + projectedHours + ", " + managerId + ");' data-dismiss='modal' aria-hidden='true'>Notify Manager</button>"; //if manager available send SMS
//                break;
//            }
//        }
//        if (!managerAvailable) {
//            result = "<button class='btn btn-danger' onclick='btnApproveMgrBehalf_Click(" + empId + "," + OnlineUser.Current.EmployeeID + ");' data-dismiss='modal' aria-hidden='true'>Approve Manager behalf</button>";
//        }
//    }
//    if (response == 2)// Pending Employee Response
//    {
//        result = "<a href = 'javascript:void(0);' data - toggle = 'tooltip' data - placement = 'top' title = '' class='tooltipskills' data-original-title='Notification Sent'>Pending Emp&nbsp;<i class='icon-time'></i> </a>";
//    }
//    if (response == 3)// Pending Mgr Response
//    {
//        result = "<a href = 'javascript:void(0);' data - toggle = 'tooltip' data - placement = 'top' title = '' class='tooltipskills' data-original-title='Prending Mgr Approval'>Pending Mgr&nbsp;<i class='icon-time'></i></a>";
//    }
//    if (response == 4 || response == 10)//Emp - Accepted
//    {
//        result = "<input id='btnAddEmp' style='color:white;width:50%;' class='btn btn-success' value='Add' onclick='saveEmployee_Click(event," + empId + "," + projectedHours + ")' />";
//    }
//    if (response == 5)// Emp - Declined or No Response
//    {
//        result = "<img src='../img/redcorss.png'><a href='javascript: void(0);' data-toggle='tooltip' data-placement='top' title='' class='tooltipskills' data-original-title='Employee declined'>Declined&nbsp;</a>";
//    }
//    // For response 6, if Mgr approves OT, sms is sent to employees and state changes to 'Pending Emp Response' (2)
//    if (response == 7)// Mgr declined OT
//    {
//        result = "<img src='../img/redcorss.png'><a href='javascript: void(0);' data-toggle='tooltip' data-placement='top' title='' class='tooltipskills' data-original-title='Manager declined OT'>OT Declined&nbsp;</a>";
//    }
//    if (response == 8)// SMS undelivered - deskphone/network connectivity
//    {
//        result = "<img src='../img/redcorss.png'><a href='javascript: void(0);' data-toggle='tooltip' data-placement='top' title='' class='tooltipskills' data-original-title='SMS undelivered'>SMS Failed&nbsp;</a>";
//    }
//    if (response == 9)// Unkown error
//    {
//        result = "<img src='../img/redcorss.png'><a href='javascript: void(0);' data-toggle='tooltip' data-placement='top' title='' class='tooltipskills' data-original-title='unknown error'>Error&nbsp;</a>";
//    }
//    return result;
//}