import React from 'react';
import BaseComponent, { withParams } from '../BaseComponent';
import ArrayStore from 'devextreme/data/array_store';
import Form, { Item as FormItem, Label, RequiredRule, GroupItem } from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import * as moment from 'moment';
import Gantt, { Tasks, StripLine, Column, Editing, Toolbar, Item, Validation, FilterRow } from 'devextreme-react/gantt';
import { flushSync } from 'react-dom'; // Note: react-dom, not react
import Popup from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import { v4 as uuidv4 } from 'uuid';

var timezoneNames = [{
    windowsName: 'Central Standard Time',
    ianaName: 'America/Chicago'
}, {
    windowsName: 'Eastern Standard Time',
    ianaName: 'America/New_York'
}, {
    windowsName: 'Eastern Standard Time',
    ianaName: 'America/Detroit'
}, {
    windowsName: 'US Mountain Standard Time',
    ianaName: 'America/Phoenix'
}, {
    windowsName: 'Mountain Standard Time',
    ianaName: 'America/Denver'
}, {
    windowsName: 'Mountain Standard Time',
    ianaName: 'America/Boise'
}, {
    windowsName: 'Pacific Standard Time',
    ianaName: 'America/Los_Angeles'
}];

// TODO
// Questions
// 1) TODO Do we include any sort of color legend?
// 2) TODO Is it a problem to display like this?
// 3) TODO Do we make time entries show as reviewed if/when entered/updated by the employee?
export class TransportSpecialistHoursContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            shifts: [],

            isViewingTaskDetails: false,
            taskDetails: {
                id: '',
                parentId: '',
                display: '',
                actualStartDate: '',
                actualEndDate: ''
            },

            filters: {
                fromDate: null,
                toDate: null,
                totalScheduledHours: '0',
                totalWorkedHours: '0'
            }
        };
    }

    GetComponentPageName = () => {
        return ("My Hours");
    }

    GetData = async () => {
        var today = moment();
        var from_date = today.clone().startOf('week');
        var to_date = today.clone().endOf('week');

        flushSync(() => {
            this.setState({
                filters: {
                    fromDate: from_date.format("MM/DD/YYYY HH:mm"),
                    toDate: to_date.format("MM/DD/YYYY HH:mm")
                }
            });
        });

        this.shiftDataSource = new ArrayStore({
            key: 'id'
            // Other ArrayStore options go here
        });

        var queryString = 'beginDate=' + this.state.filters.fromDate;
        queryString += '&endDate=' + this.state.filters.toDate;

        var shiftData = await this.FetchAPI('Schedule/GetHoursDetailForEmployee?' + queryString);

        console.log('Before', shiftData);

        // Set effective end date
        this.convertEmployeeTimeZone(shiftData);

        //console.log('After', shiftData);

        flushSync(() => {
            this.setState({
                shifts: shiftData
            });
        });

        this.updateShiftData();
    }

    updateShiftData = async () => {
        this.shiftDataSource.clear();

        var schedulePeriodStartDate = moment(this.state.filters.fromDate);
        var schedulePeriodEndDate = moment(this.state.filters.toDate);

        console.log('Calculating shift data for: ' + schedulePeriodStartDate.format('MM/DD/YYYY') + ' to ' + schedulePeriodEndDate.format('MM/DD/YYYY'));

        console.log(this.state.shifts);

        // *********************************
        var currentPeriodBeginMoment = moment(this.state.filters.fromDate);
        var currentPeriodEndMoment = moment(this.state.filters.toDate);

        var workedTotal = 0;
        var scheduledTotal = 0;

        var filters = this.state.filters;

        // TODO: Could we multi-thread this?
        this.state.shifts.map((item, index) => {

            // Sum up worked and scheduled hours for the period
            //console.log('Timesheet Item', item);

            if (item.taskType == 'SHIFT') {
                //        //console.log(shift);
                var clockOutMoment = moment(item.effectiveEndDate);
                var clockInMoment = moment(item.effectiveStartDate);

                //        // console.log(tripBeginMoment);

                if ((clockInMoment.isSameOrAfter(currentPeriodBeginMoment) && clockInMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockOutMoment.isSameOrAfter(currentPeriodBeginMoment) && clockOutMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockInMoment.isSameOrBefore(currentPeriodBeginMoment) && clockOutMoment.isSameOrAfter(currentPeriodEndMoment))) {

                    var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
                    var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

                    var calculatedAmount = end.diff(begin, 'minute') / 60;

                    //console.log('Worked item', item);
                    //console.log('Worked hours', calculatedAmount);

                    item.currentWorkedHours = calculatedAmount.toFixed(2);

                    workedTotal += calculatedAmount;
                }
            }

            // Sum up worked and scheduled hours for the period
            //console.log('Shift Item', item);

            if (item.taskType == 'EMPLOYEE') {
                //        //console.log(shift);
                var clockOutMoment = moment(item.effectiveEndDate);
                var clockInMoment = moment(item.effectiveStartDate);

                //        // console.log(tripBeginMoment);

                if ((clockInMoment.isSameOrAfter(currentPeriodBeginMoment) && clockInMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockOutMoment.isSameOrAfter(currentPeriodBeginMoment) && clockOutMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockInMoment.isSameOrBefore(currentPeriodBeginMoment) && clockOutMoment.isSameOrAfter(currentPeriodEndMoment))) {

                    var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
                    var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

                    var calculatedAmount = end.diff(begin, 'minute') / 60;

                    item.currentScheduledHours = calculatedAmount.toFixed(2);

                    scheduledTotal += calculatedAmount;
                }
            }

            if (item.taskType == 'LEAVE') {

                console.log(item);

                var clockOutMoment = moment(item.effectiveEndDate);
                var clockInMoment = moment(item.effectiveStartDate);

                if ((clockInMoment.isSameOrAfter(currentPeriodBeginMoment) && clockInMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockOutMoment.isSameOrAfter(currentPeriodBeginMoment) && clockOutMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockInMoment.isSameOrBefore(currentPeriodBeginMoment) && clockOutMoment.isSameOrAfter(currentPeriodEndMoment))) {

                    var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
                    var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

                    var calculatedAmount = end.diff(begin, 'minute') / 60;

                    item.currentScheduledHours = calculatedAmount.toFixed(2);
                }
            }

            if (item.taskType == 'LEAVEHOURS') {

                console.log(item);

                var clockOutMoment = moment(item.effectiveEndDate);
                var clockInMoment = moment(item.effectiveStartDate);

                if ((clockInMoment.isSameOrAfter(currentPeriodBeginMoment) && clockInMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockOutMoment.isSameOrAfter(currentPeriodBeginMoment) && clockOutMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockInMoment.isSameOrBefore(currentPeriodBeginMoment) && clockOutMoment.isSameOrAfter(currentPeriodEndMoment))) {

                    var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
                    var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

                    var calculatedAmount = end.diff(begin, 'minute') / 60;

                    item.currentScheduledHours = calculatedAmount.toFixed(2);

                    scheduledTotal += calculatedAmount;

                    item.currentWorkedHours = calculatedAmount.toFixed(2);

                    workedTotal += calculatedAmount;
                }
            }

            if (item.taskType == 'NONBILLHOURS') {

                console.log(item);

                var clockOutMoment = moment(item.effectiveEndDate);
                var clockInMoment = moment(item.effectiveStartDate);

                if ((clockInMoment.isSameOrAfter(currentPeriodBeginMoment) && clockInMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockOutMoment.isSameOrAfter(currentPeriodBeginMoment) && clockOutMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockInMoment.isSameOrBefore(currentPeriodBeginMoment) && clockOutMoment.isSameOrAfter(currentPeriodEndMoment))) {

                    var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
                    var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

                    var calculatedAmount = end.diff(begin, 'minute') / 60;

                    item.currentScheduledHours = calculatedAmount.toFixed(2);

                    scheduledTotal += calculatedAmount;

                    item.currentWorkedHours = calculatedAmount.toFixed(2);

                    workedTotal += calculatedAmount;
                }
            }

            this.shiftDataSource.insert(item);
        });

        filters.totalScheduledHours = scheduledTotal.toFixed(2);
        filters.totalWorkedHours = workedTotal.toFixed(2);

        console.log(filters);

        flushSync(() => {
            this.setState({
                filters: filters
            });
        });

        this.filterForm.instance().repaint();

        console.log('Final shift data source', this.shiftDataSource);

        this.projectGantt.instance().refresh();
    }

    fieldDataChanged = async (e) => {
        //console.log('Field Data changed', e);
        ////console.log(this.state.filters);

        if (e.dataField == 'fromDate') {
            //console.log(e);

            var filters = this.state.filters;

            filters.fromDate = e.value;

            var fromDate = moment(filters.fromDate);
            var toDate = fromDate.clone().endOf('week');

            filters.toDate = toDate.format("MM/DD/YYYY HH:mm");

            await this.setState({
                filters: filters
            });
        }

        if (this.filterForm != null) {
            this.filterForm.instance().repaint();
        }

        var queryString = 'beginDate=' + this.state.filters.fromDate;
        queryString += '&endDate=' + this.state.filters.toDate;

        //console.log(queryString);

        var shiftData = await this.FetchAPI('Schedule/GetHoursDetailForEmployee?' + queryString)

        //console.log('Before', shiftData);

        // Set effective end date
        this.convertEmployeeTimeZone(shiftData);

        flushSync(() => {
            this.setState({
                shifts: shiftData
            });
        });

        flushSync(() => {
            this.updateShiftData();
        });
    }

    //onTaskInserting = async (e) => {
    //    console.log(e);

    //    // TODO ABG: NOTE Currently, cannot insert a task and then immediately delete it. The Ids are being corrupted, for some reason. Working with devexpress on this
    //}

    //onTaskDeleting = async (e) => {
    //    console.log(e);

    //    const result = await this.DeleteAPI('Schedule/' + e.key);

    //    if (result.status == 1) {
    //        var currentShifts = this.state.shifts;

    //        var rowsToRemove = currentShifts.filter((item) => item.id == e.key);

    //        // TODO: ABG: Currently, can't customize the delete confirmation and I don't believe there's any way to actually disable it if we need to call deleteTask later
    //        //       So, for now, I think we have to trust that the underlying delete will occur and notify if it doesn't but we can't actually prevent it
    //        //console.log(rowsToRemove);

    //        var updatedTotalWorkedHours = parseFloat(this.state.filters.totalWorkedHours);

    //        // TODO: Update hours
    //        rowsToRemove.map((item, index) => {
    //            var deleteIndex = currentShifts.indexOf(item);

    //            var deletedItems = currentShifts.splice(deleteIndex, 1);

    //            // TODO ABG: Can't do it this way yet, though I'd like to
    //            //this.projectGantt.instance().deleteTask(item.id);

    //            // Then, update projected hours
    //            updatedTotalWorkedHours -= parseFloat(deletedItems[0].currentWorkedHours);
    //        });

    //        var filters = this.state.filters;
    //        filters.totalWorkedHours = updatedTotalWorkedHours.toFixed(2);

    //        await this.setState({
    //            filters: filters,

    //            shifts: currentShifts
    //        });

    //        this.filterForm.instance().repaint();
    //    }
    //    else if (result.status == -1) {
    //        e.cancel = true;
    //        alert(result.message);
    //    }
    //    else {
    //        e.cancel = true;
    //        //console.log(result);
    //        alert('Failed. Please reload and try again later.');
    //    }
    //}

    //onTaskUpdating = async (e) => {
    //    //console.log('Updating', e);

    //    if (e.values.taskType == 'EMPLOYEE') {
    //        e.cancel = true;
    //    }
    //    else if (e.values.taskType == 'WORKORDER') {
    //        e.cancel = true;
    //    }
    //    else {
    //        console.log('Updating', e);
    //        e.cancel = false;

    //        var previousClockInMoment = moment(e.values.effectiveStartDate);
    //        var previousClockOutMoment = moment(e.values.effectiveEndDate);

    //        e.values.actualEndDate = e.values.effectiveEndDate;
    //        e.values.actualStartDate = e.values.effectiveStartDate;

    //        e.newValues.actualEndDate = e.newValues.effectiveEndDate;
    //        e.newValues.actualStartDate = e.newValues.effectiveStartDate;

    //        var updatedTotalWorkedHours = parseFloat(this.state.filters.totalWorkedHours);

    //        var currentPeriodBeginMoment = moment(this.state.filters.fromDate);
    //        var currentPeriodEndMoment = moment(this.state.filters.toDate);

    //        var clockInMoment = moment(e.newValues.effectiveStartDate);
    //        var clockOutMoment = moment(e.newValues.effectiveEndDate);

    //        var previousBegin = previousClockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : previousClockInMoment;
    //        var previousEnd = previousClockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : previousClockOutMoment;

    //        var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
    //        var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

    //        var previousCalculatedAmount = previousEnd.diff(previousBegin, 'minute') / 60;
    //        var newCalculatedAmount = end.diff(begin, 'minute') / 60;

    //        console.log('Current worked hours', updatedTotalWorkedHours);

    //        updatedTotalWorkedHours -= previousCalculatedAmount;
    //        updatedTotalWorkedHours += newCalculatedAmount;

    //        e.newValues.currentWorkedHours = newCalculatedAmount.toFixed(2);

    //        console.log('New worked hours', updatedTotalWorkedHours);

    //        var filters = this.state.filters;
    //        filters.totalWorkedHours = updatedTotalWorkedHours.toFixed(2);

    //        await this.setState({
    //            filters: filters
    //        });

    //        this.filterForm.instance().repaint();
    //    }
    //}

    //addAdditionalShift = async (e) => {
    //    var localArrayItem = this.shiftDataSource._array.find((item) => item.id == e.row.data.id);

    //    //console.log('Root of new item', localArrayItem);

    //    await this.setState({
    //        isViewingTaskDetails: true,
    //        taskDetails: {
    //            id: uuidv4(),
    //            parentId: localArrayItem.id,
    //            display: 'Shift',
    //            actualStartDate: null,
    //            actualEndDate: null,
    //            workOrderId: localArrayItem.workOrderId,
    //            employeeTimeZone: localArrayItem.employeeTimeZone
    //        }
    //    });
    //}

    onTaskEditDialogShowing = async (e) => {
        //console.log(e);

        // TODO: I believe we can set this by adding hidden columns to the task list
        if (e.values.display.indexOf('Shift') == -1) {
            e.cancel = true;
        }
        else {
            //e.readOnlyFields.push('display');
            //e.hiddenFields.push('progress');
            //e.hiddenFields.push('resources');

            e.cancel = true;

            var localArrayItem = this.shiftDataSource._array.find((item) => item.id == e.key);

            //console.log('local array', localArrayItem);
            if (localArrayItem.workOrderStatusId < 4) {
                // TODO ABG: Having to set the effective start date in some cases because the Gantt control will not update the actual end date
                await this.setState({
                    isViewingTaskDetails: true,
                    taskDetails: {
                        id: localArrayItem.id,
                        parentId: localArrayItem.parentId,
                        display: localArrayItem.display,
                        taskType: 'SHIFT',
                        employeeTimeZone: localArrayItem.employeeTimeZone,
                        previousStartDate: localArrayItem.actualStartDate,
                        previousEndDate: localArrayItem.actualEndDate,
                        actualStartDate: localArrayItem.actualStartDate ? localArrayItem.effectiveStartDate : localArrayItem.actualStartDate,
                        actualEndDate: localArrayItem.actualEndDate ? localArrayItem.effectiveEndDate : localArrayItem.actualEndDate
                    }
                });
            }
        }
    }

    onContextMenuPreparing = async (e) => {
        //console.log(e);

        //if (!e.data) {
        //    e.cancel = true;
        //}
        //// TODO: Just cancel if things are too locked down by HR
        //else if (e.data.taskType == 'WORKORDER') {
        //    // Just cancel
        //    e.cancel = true;
        //}
        //else if (e.data.taskType == 'EMPLOYEE') {
        //    e.cancel = true;
        //}
        //else if (e.data.taskType == 'SHIFT') {
            //var localArrayItem = this.shiftDataSource._array.find((item) => item.id == e.data.id);
            ////console.log('Local array item', localArrayItem);

            //if (localArrayItem.workOrderStatusId < 4) {
            //    // Hide add menu
            //    e.items[0].visible = false;
            //    e.items[1].text = 'Hours Details...';
            //    e.items[2].text = 'Delete Hours';
            //}
            //else {
                e.cancel = true;
            //}
        //}
    }

    onTaskMoving(e) {
        // Do not allow task moving
        e.cancel = true;
    }

    isDisabledDate = (args) => {
        /*console.log('Disabled date', args);*/

        if (args.view === "month") {
            var fromDate = moment().startOf('week');
            var checkDate = moment(args.date);
            return args.date.getDay() > 0 || fromDate.diff(checkDate, 'day') > 13 || fromDate.diff(checkDate, 'day') < 0;
        }

        return false;
    }

    //hideEditDialog = () => {
    //    this.setState({
    //        isViewingTaskDetails: false,
    //        taskDetails: {
    //            id: '',
    //            parentId: '',
    //            display: '',
    //            actualStartDate: '',
    //            actualEndDate: ''
    //        }
    //    });
    //}

    //saveTaskDetails = async () => {
    //    var result = this.editFormControl.instance().validate();

    //    if (result.isValid) {

    //        await this.setState({
    //            loading: true
    //        });

    //        var taskDetails = this.state.taskDetails;

    //        console.log('task details', taskDetails);

    //        if (moment(taskDetails.actualStartDate).isAfter(moment(taskDetails.actualEndDate))) {
    //            alert('End Date cannot precede Start Date');
    //        }
    //        else {
    //            //commentData.workOrderId = this.state.workOrder.id;

    //            if (!taskDetails.workOrderId) {
    //                var localArrayItem = this.shiftDataSource._array.find((item) => item.id == taskDetails.id);

    //                //console.log('Array Item', localArrayItem);

    //                taskDetails.workOrderId = localArrayItem.workOrderId;
    //            }

    //            taskDetails.clientDateTime = moment();

    //            //console.log('Employee Time Zone', taskDetails.employeeTimeZone);

    //            var ianaTimezone = timezoneNames.find((zone) => zone.ianaName == taskDetails.employeeTimeZone);
    //            //console.log(ianaTimezone);

    //            var employeeSchedulingOfficeOffset = moment('2023-1-1').tz(ianaTimezone.ianaName).format('Z');

    //            //console.log('Actual start date', moment.parseZone(taskDetails.actualStartDate));
    //            //console.log('Previous start date', moment.parseZone(taskDetails.previousStartDate));

    //            //console.log('Is actually same', moment(taskDetails.actualStartDate, 'MM/DD/YYYY HH:mm').isSame(moment(taskDetails.previousStartDate,'MM/DD/YYYY HH:mm')));

    //            //console.log('Actual start date changed');
    //            var convertedStartDateMoment = moment.parseZone(moment.parseZone(taskDetails.actualStartDate).format('MM/DD/YYYY HH:mm') + ' ' + employeeSchedulingOfficeOffset);

    //            var clientOffset = convertedStartDateMoment.format('MM/DD/YYYY HH:mm Z');

    //            //console.log(clientOffset);

    //            taskDetails.clockInDateTime = clientOffset;

    //            //console.log('Actual end date', moment.parseZone(taskDetails.actualEndDate));
    //            //console.log('Previous end date', moment.parseZone(taskDetails.previousEndDate));

    //            //console.log('Is actually same', moment(taskDetails.actualEndDate, 'MM/DD/YYYY HH:mm').isSame(moment(taskDetails.previousEndDate, 'MM/DD/YYYY HH:mm')));

    //            //console.log('Actual end date changed');
    //            var convertedEndDateMoment = moment.parseZone(moment.parseZone(taskDetails.actualEndDate).format('MM/DD/YYYY HH:mm') + ' ' + employeeSchedulingOfficeOffset);

    //            var clientOffset = convertedEndDateMoment.format('MM/DD/YYYY HH:mm Z');

    //            //console.log(clientOffset);

    //            taskDetails.clockOutDateTime = clientOffset;

    //            console.log(taskDetails);

    //            const result = await this.PostAPI('Schedule', taskDetails);

    //            console.log(result);

    //            if (result.status == 1) {
    //                // TODO: Make sure popups and render work after update
    //                var currentShifts = this.state.shifts;

    //                taskDetails.effectiveStartDate = taskDetails.actualStartDate;
    //                taskDetails.effectiveEndDate = taskDetails.actualEndDate;

    //                taskDetails.actualStartDate = taskDetails.clockInDateTime;
    //                taskDetails.actualEndDate = taskDetails.clockOutDateTime;

    //                //console.log('After save values', taskDetails);

    //                var insertIndex = currentShifts.findIndex((item) => item.id == taskDetails.id);
    //                //console.log('index', insertIndex);

    //                var updatedTotalWorkedHours = parseFloat(this.state.filters.totalWorkedHours);

    //                var currentPeriodBeginMoment = moment(this.state.filters.fromDate);
    //                var currentPeriodEndMoment = moment(this.state.filters.toDate);

    //                var clockInMoment = moment(taskDetails.actualStartDate);
    //                var clockOutMoment = moment(taskDetails.actualEndDate);

    //                var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
    //                var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

    //                var calculatedAmount = end.diff(begin, 'minute') / 60;

    //                if (insertIndex == -1) {
    //                    // New item
    //                    // TODO: Find the last index of shifts for the parent id
    //                    taskDetails.taskType = 'SHIFT';

    //                    var parentIndex = currentShifts.findIndex((item) => item.id == taskDetails.parentId);
    //                    currentShifts.splice(parentIndex + 1, 0, taskDetails);

    //                    //console.log('Newly inserted id', taskDetails.id);

    //                    // Update worked hours
    //                    this.projectGantt.instance().insertTask({
    //                        display: taskDetails.display, id: taskDetails.id, parentId: taskDetails.parentId,
    //                        actualStartDate: new Date(new Date(moment.parseZone(taskDetails.actualStartDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
    //                        actualEndDate: new Date(new Date(moment.parseZone(taskDetails.actualEndDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
    //                        effectiveStartDate: new Date(new Date(moment.parseZone(taskDetails.actualStartDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
    //                        effectiveEndDate: new Date(new Date(moment.parseZone(taskDetails.actualEndDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
    //                        currentWorkedHours: calculatedAmount.toFixed(2),
    //                        taskType: taskDetails.taskType
    //                    });

    //                    updatedTotalWorkedHours += calculatedAmount;

    //                    var filters = this.state.filters;
    //                    filters.totalWorkedHours = updatedTotalWorkedHours.toFixed(2);

    //                    await this.setState({
    //                        filters: filters
    //                    });

    //                    this.filterForm.instance().repaint();
    //                }
    //                else {
    //                    var previousItem = currentShifts.splice(insertIndex, 1, taskDetails);

    //                    var previousClockInMoment = moment(previousItem[0].effectiveStartDate);
    //                    var previousClockOutMoment = moment(previousItem[0].effectiveEndDate);

    //                    var updatedTotalWorkedHours = parseFloat(this.state.filters.totalWorkedHours);

    //                    var currentPeriodBeginMoment = moment(this.state.filters.fromDate);
    //                    var currentPeriodEndMoment = moment(this.state.filters.toDate);

    //                    var clockInMoment = moment(taskDetails.effectiveStartDate);
    //                    var clockOutMoment = moment(taskDetails.effectiveEndDate);

    //                    var previousBegin = previousClockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : previousClockInMoment;
    //                    var previousEnd = previousClockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : previousClockOutMoment;

    //                    var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
    //                    var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

    //                    var previousCalculatedAmount = previousEnd.diff(previousBegin, 'minute') / 60;
    //                    var newCalculatedAmount = end.diff(begin, 'minute') / 60;

    //                    //console.log('Current worked hours', updatedTotalWorkedHours);

    //                    updatedTotalWorkedHours -= previousCalculatedAmount;
    //                    updatedTotalWorkedHours += newCalculatedAmount;

    //                    taskDetails.currentWorkedHours = newCalculatedAmount.toFixed(2);

    //                    taskDetails.reviewStatusCode = "REVIEWED";

    //                    //console.log('New worked hours', updatedTotalWorkedHours);

    //                    var filters = this.state.filters;
    //                    filters.totalWorkedHours = updatedTotalWorkedHours.toFixed(2);

    //                    await this.setState({
    //                        filters: filters
    //                    });

    //                    this.filterForm.instance().repaint();

    //                    //console.log('Before convert', taskDetails);

    //                    this.convertEmployeeTimeZone([taskDetails]);

    //                    //console.log('After convert', taskDetails);

    //                    //console.log(moment.parseZone(taskDetails.actualEndDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    //                    //console.log(new Date(moment.parseZone(taskDetails.actualEndDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName }));

    //                    // Updating task
    //                    // TODO: Update hours
    //                    this.projectGantt.instance().updateTask(taskDetails.id, {
    //                        display: 'Shift',
    //                        color: "#8ed962",
    //                        actualStartDate: new Date(new Date(moment.parseZone(taskDetails.actualStartDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
    //                        actualEndDate: new Date(new Date(moment.parseZone(taskDetails.actualEndDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
    //                        effectiveStartDate: new Date(new Date(moment.parseZone(taskDetails.actualStartDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
    //                        effectiveEndDate: new Date(new Date(moment.parseZone(taskDetails.actualEndDate).valueOf()).toLocaleString("en-US", { timeZone: ianaTimezone.ianaName })),
    //                        currentWorkedHours: taskDetails.currentWorkedHours,
    //                        parentId: taskDetails.parentId,
    //                        reviewStatusCode: taskDetails.reviewStatusCode
    //                        //endDateModified: taskDetails.endDateModified,
    //                        //startDateModified: taskDetails.startDateModified,
    //                        //endDateOriginal: taskDetails.endDateOriginal,
    //                        //startDateOriginal: taskDetails.startDateOriginal
    //                    });
    //                }

    //                await this.setState({
    //                    shifts: currentShifts,

    //                    isViewingTaskDetails: false,
    //                    taskDetails: {
    //                        id: '',
    //                        parentId: '',
    //                        display: '',
    //                        actualStartDate: '',
    //                        actualEndDate: ''
    //                    }
    //                });

    //                //console.log('GGGGOOOOOTTTTT HHHHHEEEERRRREEE');
    //            }
    //            else if (result.status == -1) {
    //                alert(result.message);
    //            }
    //            else {
    //                //console.log(result);
    //                alert('Failed. Please try again later.');
    //            }
    //        }

    //        await this.setState({
    //            loading: false
    //        });
    //    }
    //}

    convertEmployeeTimeZone = (shiftData) => {
        // ABG: The ONLY thing we will do here for now is to lop off the timezone
        // Detect and convert values for office time zone
        shiftData.map((item, index) => {
            if (item.taskType == 'SHIFT') {
                //console.log('Employee Time Zone', item.employeeTimeZone);

                // Reset fields
                //item.startDateOriginal = null;
                //item.endDateOriginal = null;
                //item.startDateModified = false;
                //item.endDateModified = false;

                //var ianaTimezone = timezoneNames.find((zone) => zone.ianaName == item.employeeTimeZone);
                //console.log(ianaTimezone);

                // TODO: Handle DST?
                //var employeeSchedulingOfficeOffset = moment('2023-1-1').tz(ianaTimezone.ianaName).format('Z');
                //console.log('TimeZone offset', employeeSchedulingOfficeOffset);

                if (item.actualStartDate) {
                    //console.log('Actual Start Date value', item.actualStartDate);

                    //var originalStartDateMoment = moment.parseZone(item.actualStartDate);
                    //console.log(originalStartDateMoment.format('MM/DD/YYYY HH:mm'));

                    //var originalOffset = originalStartDateMoment.format('Z')
                    //var convertedOffset = originalStartDateMoment.tz(ianaTimezone.ianaName).format('Z');

                    //if (originalOffset == convertedOffset) {
                    //    item.startDateModified = false;
                    //console.log('Same zone, no change');
                    //console.log('Actual Start Date value', item.actualStartDate);
                    item.effectiveStartDate = moment.parseZone(item.actualStartDate).format('MM/DD/YYYY HH:mm');
                    //}
                    //else {
                    //    item.startDateModified = true;
                    //    item.startDateOriginal = moment.parseZone(item.actualStartDate).format('MM/DD/YYYY HH:mm');
                    //    //item.actualStartDate = originalStartDateMoment.tz(ianaTimezone.ianaName).format('MM/DD/YYYY HH:mm Z')
                    //    item.effectiveStartDate = originalStartDateMoment.tz(ianaTimezone.ianaName).format('MM/DD/YYYY HH:mm');
                    //    //console.log('Different zone, converted');
                    //    //console.log('Original time offset', originalOffset);
                    //    //console.log('Converted offset', convertedOffset);

                    //    //console.log('Actual Start Date value', item.actualStartDate);
                    //}

                    //console.log(item.effectiveStartDate);
                }

                if (item.actualEndDate) {
                    //console.log('Actual End Date value', item.actualEndDate);

                    //var originalEndDateMoment = moment.parseZone(item.actualEndDate);
                    //console.log(originalStartDateMoment.format('MM/DD/YYYY HH:mm'));

                    //var originalOffset = originalEndDateMoment.format('Z')
                    //var convertedOffset = originalEndDateMoment.tz(ianaTimezone.ianaName).format('Z');

                    //if (originalOffset == convertedOffset) {
                    //    item.endDateModified = false;
                    //console.log('Same zone, no change');
                    //console.log('Actual End Date value', item.actualEndDate);
                    item.effectiveEndDate = moment.parseZone(item.actualEndDate).format('MM/DD/YYYY HH:mm');
                    //}
                    //else {
                    //    item.endDateModified = true;
                    //    item.endDateOriginal = moment.parseZone(item.actualEndDate).format('MM/DD/YYYY HH:mm');
                    //    //item.actualEndDate = originalEndDateMoment.tz(ianaTimezone.ianaName).format('MM/DD/YYYY HH:mm Z');
                    //    item.effectiveEndDate = originalEndDateMoment.tz(ianaTimezone.ianaName).format('MM/DD/YYYY HH:mm');
                    //    //console.log('Different zone, converted');
                    //    //console.log('Original time offset', originalOffset);
                    //    //console.log('Converted offset', convertedOffset);

                    //    //console.log('Actual End Date value', item.actualEndDate);
                    //}

                    //console.log(item.effectiveEndDate);
                }
            }

            if (item.taskType == 'LEAVE') {
                // Effectively, this currently strips the time zone information off for now
                if (item.actualStartDate) {
                    item.effectiveStartDate = moment.parseZone(item.actualStartDate).format('MM/DD/YYYY HH:mm');
                }

                if (item.actualEndDate) {
                    item.effectiveEndDate = moment.parseZone(item.actualEndDate).format('MM/DD/YYYY HH:mm');
                }
            }

            if (item.taskType == 'LEAVEHOURS') {
                // Effectively, this currently strips the time zone information off for now
                if (item.actualStartDate) {
                    item.effectiveStartDate = moment.parseZone(item.actualStartDate).format('MM/DD/YYYY HH:mm');
                }

                if (item.actualEndDate) {
                    item.effectiveEndDate = moment.parseZone(item.actualEndDate).format('MM/DD/YYYY HH:mm');
                }
            }

            if (item.taskType == 'NONBILLHOURS') {
                // Effectively, this currently strips the time zone information off for now
                if (item.actualStartDate) {
                    item.effectiveStartDate = moment.parseZone(item.actualStartDate).format('MM/DD/YYYY HH:mm');
                }

                if (item.actualEndDate) {
                    item.effectiveEndDate = moment.parseZone(item.actualEndDate).format('MM/DD/YYYY HH:mm');
                }
            }
        });

        //console.log('After', shiftData);
    }

    //isAddShiftButtonVisible = (options) => {
    //    // If the entry in the chart has the same work order id, we can add additional shifts, where needed
    //    //return options.row.data.taskType == 'EMPLOYEE' && options.row.data.workOrderStatusId < 4;
    //    // ABG for now, read-only, always false
    //    return false;
    //}

    render() {
        //console.log('Begin render');

        return (
            <div className="container-fluid">
                {/* TODO: Back and forth buttons? */}
                <Form ref={ref => this.filterForm = ref}
                    formData={this.state.filters} colCount={2} onFieldDataChanged={this.fieldDataChanged}>
                    <FormItem editorType="dxDateBox" dataField="fromDate" editorOptions={{ disabledDates: this.isDisabledDate }}>
                        <Label text="Week Begin" />
                    </FormItem>

                    <FormItem editorType="dxDateBox" dataField="toDate" editorOptions={{ readOnly: true }}>
                        <Label text="End" />
                    </FormItem>

                    <FormItem dataField="totalScheduledHours" editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                        <Label text="Total Hours Scheduled" />
                    </FormItem>

                    <FormItem dataField="totalWorkedHours" editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                        <Label text="Total Hours" />
                    </FormItem>
                </Form>

                <br />

                <Gantt
                    taskListWidth={650} onTaskInserting={this.onTaskInserting} onTaskEditDialogShowing={this.onTaskEditDialogShowing}
                    onContextMenuPreparing={this.onContextMenuPreparing} onTaskMoving={this.onTaskMoving} onTaskDeleting={this.onTaskDeleting} onTaskUpdating={this.onTaskUpdating}
                    scaleType="days"
                    height={700}
                    ref={ref => this.projectGantt = ref} showResources={false}>
                    <Validation autoUpdateParentTasks={false} />
                    <Editing enabled={false} allowDependencyAdding={false} allowDependencyDeleting={false} allowResourceAdding={false} allowResourceDeleting={false}
                        allowResourceUpdating={false} allowTaskAdding={false} allowTaskUpdating={false} allowTaskDeleting={false} />

                    <StripLine start={this.state.filters.fromDate} title="Work Week Start" cssClass="current-time" />
                    <StripLine start={this.state.filters.toDate} title="Work Week End" cssClass="current-time" />

                    <Tasks dataSource={this.shiftDataSource}
                        keyExpr="id"
                        startExpr="effectiveStartDate" endExpr="effectiveEndDate"
                        titleExpr="display" />

                    <Toolbar>
                        <Item name="collapseAll" />
                        <Item name="expandAll" />
                        <Item name="separator" />
                        <Item name="zoomIn" />
                        <Item name="zoomOut" />
                    </Toolbar>

                    <Column dataField="display" caption="Work Order/Employee/Shift/Schedule" width={250} />
                    <Column dataField="taskType" visible={false} />
                    <Column dataField="effectiveStartDate" caption="Plan Start Date" dataType="datetime" format="MM/dd/yy, HH:mm" width={115} />
                    <Column dataField="effectiveEndDate" caption="Plan End Date" dataType="datetime" format="MM/dd/yy, HH:mm" width={115} />
                    <Column dataField="currentScheduledHours" caption="Scheduled" width={80} dataType='number' />
                    <Column dataField="reviewStatusCode" visible={false} />
                    <Column dataField="currentWorkedHours" caption="Actual" width={80} dataType='number' />

                    <FilterRow visible={true} />
                </Gantt>

                {/*<Popup visible={this.state.isViewingTaskDetails} onHiding={this.hideEditDialog} dragEnabled={true}*/}
                {/*    closeOnOutsideClick={false} showTitle={true} title="Task Details" width={400} height={280}>*/}

                {/*    <Form ref={ref => this.editFormControl = ref}*/}
                {/*        id="form" formData={this.state.taskDetails} colCount={1}>*/}

                {/*        <FormItem editorType="dxTextBox" dataField="display" editorOptions={{ readOnly: true }}>*/}
                {/*            <Label text="Title" />*/}
                {/*        </FormItem>*/}

                {/*        <FormItem editorType="dxDateBox" dataField="actualStartDate" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>*/}
                {/*            <Label text="Start" />*/}
                {/*            <RequiredRule />*/}
                {/*        </FormItem>*/}

                {/*        <FormItem editorType="dxDateBox" dataField="actualEndDate" editorOptions={{ type: 'datetime', displayFormat: 'MM/dd/yyyy HH:mm' }}>*/}
                {/*            <Label text="End" />*/}
                {/*            <RequiredRule />*/}
                {/*        </FormItem>*/}
                {/*    </Form>*/}
                {/*    <br />*/}

                {/*    <div style={{ textAlign: 'center' }}>*/}
                {/*        <Button text="OK" onClick={this.saveTaskDetails} />*/}
                {/*        &nbsp;&nbsp;*/}
                {/*        <Button text="Cancel" onClick={this.hideEditDialog} />*/}
                {/*    </div>*/}
                {/*</Popup>*/}

                <LoadPanel
                    visible={this.state.loading} />

                <br />
            </div>
        );
    }
}

export default withParams(TransportSpecialistHoursContainer);