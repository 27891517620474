import React from 'react';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise, withParams } from '../BaseComponent';
import CustomStore from 'devextreme/data/custom_store';
import Form, { Item, ButtonItem, RequiredRule, EmptyItem } from 'devextreme-react/form';
import DataGrid, { FilterRow, Pager, Paging, Column, Editing, Lookup, Form as GridForm, Export } from 'devextreme-react/data-grid';
import * as $ from 'jquery';

class ContractContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.supervisorsRender = this.supervisorsRender.bind(this);

        this.state = {
            employees: [],
            offices: [],

            contractId: '',
            contract: {},
            timezones: []
        };
    }

    supervisoryOrgDataSource = new CustomStore({
        key: 'orgCode',
        load: () => {
            return FetchAPIPromise('SupervisoryOrg/GetByContract/' + this.state.contractId);
        },
        insert: (values) => {
            values.contractId = this.state.contractId;
            return PostAPIPromise('SupervisoryOrg', values);
        },
        remove: (key) => {
            console.log(key);
            return DeleteAPIPromise('SupervisoryOrg/' + key)
                .then((result) => {
                    if (result.status == -2) {
                        alert('Supervisory Org is referenced in the system and cannot be deleted. Consider inactivating.')
                    }
                });
        },
        update: (key, values) => {
            values.contractId = this.state.contractId;
            return PostAPIPromise('SupervisoryOrg', values);
        }
    });

    officeDataSource = new CustomStore({
        key: 'id',
        load: () => {
            return FetchAPIPromise('Office/GetByContract/' + this.state.contractId);
        },
        insert: (values) => {
            values.contractId = this.state.contractId;
            return PostAPIPromise('Office', values);
        },
        remove: (key) => {
            return DeleteAPIPromise('Office/' + key)
                .then((result) => {
                    if (result.status == -2) {
                        alert('Office is referenced in the system and cannot be deleted. Consider inactivating.')
                    }
                });
        },
        update: (key, values) => {
            values.contractId = this.state.contractId;
            return PostAPIPromise('Office', values);
        }
    });

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);

        const { id } = this.props.params;

        await this.setState({
            contractId: id
        });

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return (this.state.contract.contractName ? this.state.contract.contractName : 'New Contract');
    }

    GetDropDownData = async () => {
        const aorData = await this.FetchAPI('DictionaryAOR');
        const employeeData = await this.FetchAPI('Employee/GetActiveEmployees');
        const officeData = await this.FetchAPI('Office/GetByContract/' + this.state.contractId);

        this.setState({
            aors: aorData,
            employees: employeeData,
            offices: officeData
        });
    };

    GetData = async () => {
        const contractData = await this.FetchAPI('Contract/GetContract/' + this.state.contractId);
        const timezoneData = await this.FetchAPI('DictionaryTimeZoneInfo');

        this.setState({
            timezones: timezoneData,
            contract: contractData
        });
    };

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    saveEditedContract = async (e) => {
        const { contract } = this.state
        e.preventDefault();
        contract.contractId = this.state.contractId;
        var response = await this.PostAPI('Contract', contract);
        if (response.status = 1) {
            if (!this.state.contract.contractId) {
                window.location.href = '/contract-details/' + response.contractId;
            }
            else {
                this.fetchData();
            }
        }
        else {
            alert(response.message);
        }
    }

    onEditorPreparing = (e) => {
        //console.log(e);

        if (e.dataField == 'orgCode') {
            e.editorOptions.readOnly = e.row && !e.row.isNewRow;
        }
    }

    onInitNewRow(e) {
        e.data.supervisors = [];
    }

    onSaved = (cell, e) => {
        // TODO: Maybe make this an actual value
        //console.log('leave hours saved', cell);
        //console.log('leave hours saved', e);
        cell.setValue(cell.data.supervisors);
    }

    supervisorsRender(cell) {
        console.log('Supervisors render', cell);
        let onSaved = this.onSaved.bind(this, cell);

        return <DataGrid dataSource={cell.data.supervisors} onSaved={onSaved}
            keyExpr="employeeId" showBorders={true} allowColumnResizing={true}>
            <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()} />

            <Column dataField="employeeId" caption="Supervisor" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, dataSource: this.state.employees, displayExpr: 'employeeName', valueExpr: 'employeeId' }}>
                <RequiredRule />
                <Lookup allowClearing={true} dataSource={this.state.employees} displayExpr="employeeName" valueExpr="employeeId" />
            </Column>
            <Column dataField="rank" caption="Rank" dataType="number" editorType="dxNumberBox">
                <RequiredRule />
            </Column>
        </DataGrid>;
    }

    render() {
        return (
            <div className="container">
                <React.Fragment>
                    <form action="your-action" onSubmit={this.saveEditedContract}>
                        <Form formData={this.state.contract} >
                            <Item itemType="group" colCount={2} >
                                <Item>
                                    <div className="mt-auto">
                                        <h1>{this.state.contract.contractName ? this.state.contract.contractName : 'New Contract'}</h1>
                                    </div>
                                </Item>
                                <ButtonItem disabled={this.IsReadOnly()} buttonOptions={{ text: 'Save Contract', useSubmitBehavior: true }} />
                                <Item dataField="contractName" >
                                    <RequiredRule />
                                </Item>
                                <Item dataField="contractCode" >
                                    <RequiredRule />
                                </Item>
                            </Item>
                        </Form>
                    </form>
                </React.Fragment>
                <hr />
                {this.state.contractId &&
                    <div>
                        <h2>Supervisory Orgs</h2>
                    <DataGrid dataSource={this.supervisoryOrgDataSource} showBorders={true} allowColumnResizing={true}
                        onRowUpdating={this.onRowUpdating} onInitNewRow={this.onInitNewRow} onEditorPreparing={this.onEditorPreparing} onExporting={this.onExporting}>
                        <Export enabled={true} fileName={"supervisoryOrgs"} />
                        <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()}>
                            <GridForm>
                                <Item dataField="orgCode" />
                                <Item dataField="orgName" />
                                <Item dataField="alternateOrgCode" />
                                <Item dataField="representativeOfficeId" />

                                    <Item dataField="supervisors" colSpan={2} />
                                </GridForm>
                            </Editing>
                            <FilterRow visible={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                                showInfo={true} visible={true} />

                            <Column caption="Org Code" dataField="orgCode">
                                <RequiredRule />
                            </Column>
                            <Column caption="Org Name" dataField="orgName">
                                <RequiredRule />
                            </Column>
                            <Column caption="Alternate Org Code" dataField="alternateOrgCode" />

                            <Column caption="Representative Office" dataField="representativeOfficeId">
                                <Lookup allowClearing={true} dataSource={this.state.offices} valueExpr="id" displayExpr="name" />
                            </Column>

                            <Column caption="Supervisors" visible={false} dataField="supervisors" editCellRender={this.supervisorsRender} />
                        </DataGrid>
                    </div>
                }
                <br />

                {this.state.contractId &&
                    <div>
                        <h2>Office Management</h2>
                        <DataGrid dataSource={this.officeDataSource} showBorders={true} allowColumnResizing={true}
                        onRowUpdating={this.onRowUpdating} onExporting={this.onExporting}>
                            <Export enabled={true} fileName={"officeManagement"} />
                        <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()} />
                            <FilterRow visible={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                                showInfo={true} visible={true} />
                            <Column caption="Office Name" dataField="name"  >
                                <RequiredRule />
                            </Column>
                            <Column caption="Time Zone" dataField="timeZone" >
                                <RequiredRule />
                                <Lookup allowClearing={true} dataSource={this.state.timezones} valueExpr="timeZoneId" displayExpr="display" />
                            </Column>
                            <Column caption="Office Code" dataField="code" >
                                <RequiredRule />
                            </Column>
                        <Column caption="Status" dataField="active" dataType="boolean" />
                        <Column caption="AOR" dataField="aorCode">
                            <RequiredRule />
                            <Lookup allowClearing={true} dataSource={this.state.aors} displayExpr="aorCode" valueExpr="aorCode" />
                        </Column>
                        </DataGrid>
                    </div>
                }
                <br />
            </div>
        );
    }
}

export default withParams(ContractContainer);