
import React from 'react';
import * as moment from 'moment';

class MilestoneDetail extends React.Component {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.dataSource = props.data.row.data;
    }
    render() {
        // console.log(this.dataSource);

        return (
            <div>
                {(this.dataSource.flight && this.dataSource.flight.departureAirportCode) &&
                    <div>Flight - ({this.dataSource.flight.departureAirportCode} - {this.dataSource.flight.arrivalAirportCode})</div>
                }

                {(this.dataSource.hotelStay && this.dataSource.hotelStay.facilityName) &&
                    <div>Hotel Stay - {this.dataSource.hotelStay.facilityName} ({moment(this.dataSource.hotelStay.planCheckInDate).format("MM/DD/YY HH:mm")} - {moment(this.dataSource.hotelStay.planCheckOutDate).format("MM/DD/YY HH:mm")})</div>
                }

                {this.dataSource.values.length > 0 && this.dataSource.values.map((item, index) => {
                    // console.log(item);
                    var key = item.workOrderMilestoneId + '|' + item.controlId;

                    if (item.controlType == 'signature' || item.controlType == 'photo') {
                        // TODO: What if no file?
                        var imgSource = "data:image/png;base64," + item.file;
                        var imgHeight = item.controlType == 'signature' ? '200px' : '450px';

                        return (<div key={key}>{item.label}: <img style={{ height: imgHeight }} id="ItemPreview" src={imgSource} /></div>);
                    }
                    else {
                        return (<div key={key}>{item.label}: {item.value}</div>);
                    }
                })}

                {this.dataSource.values.length == 0 &&
                    <div>No value(s) found</div>
                }
            </div>
        );
    }
}

export default MilestoneDetail;