
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Lookup, FormItem } from 'devextreme-react/data-grid';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import * as $ from 'jquery'

const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('InventoryVehicles');
    },
    insert: (values) => {
        return PostAPIPromise('InventoryVehicles', values);
    },
    remove: (key) => {
        return DeleteAPIPromise('InventoryVehicles/' + key)
            .then((result) => {
                if (result.status == -2) {
                    alert('Vehicle is referenced in the system and cannot be deleted. Consider inactivating.')
                }
            });
    },
    update: (key, values) => {
        return PostAPIPromise('InventoryVehicles', values);
    }
});

export class InventoryVehiclesContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Inventory Vehicle Management");
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    onInitNewRow(e) {
        // Set active value default to true
        e.data.isActive = true;
    }

    render() {

        return (
            <div className="container">
                <h1>Vehicle Management</h1>

                <div className="container">
                    <DataGrid dataSource={customDataSource}
                        showBorders={true} allowColumnResizing={true}
                        keyExpr="id" onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating}>
                        <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()} />
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />

                        <Column caption="Vehicle Number" dataField="vehicleNumber">
                            <RequiredRule />
                            <StringLengthRule max={50} />
                        </Column>
                        <Column caption="VIN Number" dataField="vinNumber">
                            <RequiredRule />
                            <StringLengthRule max={50} />
                        </Column>
                        <Column caption="Description" dataField="description">
                            <RequiredRule />
                            <StringLengthRule max={255} />
                        </Column>
                        <Column caption="Active" dataField="isActive" dataType="boolean" />
                    </DataGrid>
                    <br />
                </div>
            </div>
        );
    }
}