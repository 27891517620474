
import React from 'react';
import Button from 'devextreme-react/button';
import * as moment from 'moment';
import BaseComponent from '../BaseComponent';
import Form, { Item as FormItem, Label } from 'devextreme-react/form';
import { Link } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
import DataGrid, { FilterRow, Column, Button as GridButton } from 'devextreme-react/data-grid';
import DataGridNormal from "devextreme/ui/data_grid";

export class QADashboardContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            qaRulesResults: [],

            filters: {
                beginDate: null,
                toDate: null
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("QA Dashboard");
    }

    GetData = async () => {

        var today = moment();
        var from_date = today.clone().startOf('week');
        var to_date = today.clone().endOf('week');

        await this.setState({

            filters: {
                beginDate: from_date.format("MM/DD/YYYY"),
                endDate: to_date.format("MM/DD/YYYY")
            }
        });
    }

    GetDropDownData = async () => {

    }

    executeQARules = async (e) => {

        await this.setState({
            loading: true,
            qaRulesResults: null
        });

        var qaRulesData = await this.FetchAPI('QARules?beginDate=' + this.state.filters.beginDate + '&endDate=' + this.state.filters.endDate);

        console.log(qaRulesData);

        await this.setState({
            loading: false,
            qaRulesResults: qaRulesData
        });
    }

    ignoreRule = async (e) => {

        var ruleIgnore = {
            ruleId: e.row.data.ruleId,
            workOrderId: e.row.data.id
        };

        var ruleIgnoreResult = await this.PostAPI('QARules/SaveRuleIgnore', ruleIgnore);

        console.log('Result', ruleIgnoreResult);

        if (ruleIgnoreResult.status == 1) {
            var origState = this.state.qaRulesResults;

            // Delete the row from the grid
            //console.log(e.row.data.ruleId);
            //console.log(this.state.qaRulesResults);
            var setIndex = origState.workOrderRulesRunResults.findIndex(x => x.ruleId == e.row.data.ruleId);

            //console.log(setIndex);

            // Update the ignore set
            //originalRow.isIgnored = true;
            var rowIndex = origState.workOrderRulesRunResults[setIndex].results.findIndex(x => x.id == e.row.data.id);
            //console.log(rowIndex);

            origState.workOrderRulesRunResults[setIndex].results[rowIndex].isIgnored = true;

            this.setState({
                qaRulesResults: origState
            });

            //console.log(e);
            //e.component.clearFilter();
            e.component.repaint();
            e.component.refresh();
        }
    }

    getVisibleCount(records) {
        return records.filter(x => !x.isIgnored).length;
    }

    getIgnoreCount(records) {
        return records.filter(x => x.isIgnored).length;
    }

    disabledClick(e) {
        e.preventDefault();
    }

    showIgnoredResults(e, ruleName) {
        //console.log(e);
        e.preventDefault();

        // Find DataGrid instance
        // Find the grid reference
        let element = document.getElementById(ruleName);

        //console.log(element);

        let instance = DataGridNormal.getInstance(element);

        if (e.target.text == "Show") {
            e.target.text = "Hide";

            instance.clearFilter();
        }
        else {
            e.target.text = "Show";

            instance.filter(['isIgnored', '=', false]);
        }
    }

    render() {
        const filterValue = ['isIgnored', '=', false];

        return (
            <div className="container">
                <h1>QA Dashboard</h1>
                <br />

                <Form ref={ref => this.filterForm = ref}
                    formData={this.state.filters} colCount={2} onFieldDataChanged={this.fieldDataChanged}>
                    <FormItem editorType="dxDateBox" dataField="beginDate">
                        <Label text="Begin Date" />
                    </FormItem>

                    <FormItem editorType="dxDateBox" dataField="endDate">
                        <Label text="End Date" />
                    </FormItem>
                </Form>
                <br />

                <div className="d-flex justify-content-end">
                    <div className="ms-auto">
                        <Button text="Execute Rules" onClick={this.executeQARules} />
                    </div>
                </div>

                <br />
                {this.state.qaRulesResults && this.state.qaRulesResults.workOrderRulesRunResults && this.state.qaRulesResults.workOrderRulesRunResults.map((item, index) =>
                    <>
                        <div className="row">
                            <div className="col-sm-3 my-auto">
                                <div className="text-center">
                                    <h1><a href="#" style={{ color: 'red' }} onClick={(e) => this.disabledClick(e)}>{this.getVisibleCount(item.results)}</a></h1>
                                    <p>{item.ruleName}</p>
                                    {this.getIgnoreCount(item.results) > 0 ? <p><span style={{ color: 'red' }}>({this.getIgnoreCount(item.results)} Ignored Records <a href="#" onClick={(e) => this.showIgnoredResults(e, item.ruleName)}>Show</a>)</span></p> : <p />}
                                </div>
                            </div>

                            <div className="col-sm-8">
                                <DataGrid dataSource={item.results}
                                    id={item.ruleName}
                                    defaultFilterValue={filterValue}
                                    showBorders={true} allowColumnResizing={true}>
                                    <FilterRow visible={true} />

                                    <Column type="buttons">
                                        <GridButton name="ignore" text="Ignore Issue" onClick={this.ignoreRule} />
                                    </Column>
                                    <Column caption="Work Order #" dataField="workOrderNumber" />
                                    <Column caption="Work Order Name" dataField="workOrderName" cellRender={function (options) { return (<Link to={`/workorder-resources/${options.row.data.id}`}>{options.value}</Link>); }} />
                                    <Column caption="Traffic Type" dataField="trafficType" />
                                    <Column caption="Transport Method" dataField="transportMethod" />
                                    <Column caption="Status" dataField="statusName" />
                                    <Column dataField="isIgnored" visible={false} />
                                </DataGrid>
                            </div>
                        </div>
                        <br />
                    </>
                )}
                <br />

                <LoadPanel
                    visible={this.state.loading} />
            </div>
        );
    }
}