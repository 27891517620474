import React from 'react';
import moment from 'moment';
import BaseComponent from '../BaseComponent';
import Map from 'devextreme-react/map';
import { LoadPanel } from 'devextreme-react/load-panel';
import { flushSync } from 'react-dom'; // Note: react-dom, not react

export class ShowAllLocationContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            locations: [],
            mapMarkers: [],
            loading: true
        };

        this.key = {
            google: "AIzaSyDLhzLhxQLyaz3C_gigq8Rd9N8hC0yRT5c"
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Locations Dashboard");
    }

    GetData = async () => {
        //console.log('Fetch');
        var data = await this.FetchAPI('WorkOrderMilestone/GetLastLocation');

        //console.log(data);
        //console.log('done');

        flushSync(() => {
            this.setState({
                locations: data
            });
        });

        var markerLocations = [];

        for (var i = 0; i < this.state.locations.length; i++) {
            var latitude = this.state.locations[i].latitude;
            var longitude = this.state.locations[i].longitude;
            var workOrderName = this.state.locations[i].workOrderName;
            var eventDateTime = new Date(this.state.locations[i].eventDateTime);
            var comment = this.state.locations[i].comment;
            let location = {
                location: {
                    "lat": latitude,
                    "lng": longitude
                },
                tooltip: {
                    text: '<b><center>' + workOrderName + '</center></b><br /> ' + moment(eventDateTime).format("MM/DD/YYYY HH:mm") + ' ' + comment,
                    isShown: true
                }
            }
            markerLocations.unshift(location);
        }

        flushSync(() => {
            this.setState({
                mapMarkers: markerLocations,
                loading: false
            })
        });
    }

    render() {
        return (
            <div>
                <Map
                    width="100%"
                    height={500}
                    type="roadmap"
                    defaultZoom={5}
                    defaultCenter="New Mexico"
                    provider="google"
                    markers={this.state.mapMarkers}
                    controls={true}
                    apiKey={this.key.google}
                    />
                <LoadPanel visible={this.state.loading} />
            </div>
        );
    }
}