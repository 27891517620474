
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Lookup, Form, FormItem } from 'devextreme-react/data-grid';
import { Item, Label } from 'devextreme-react/form';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import * as $ from 'jquery'

const customDataSource = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('StagingConfiguration');
    },
    insert: (values) => {
        return PostAPIPromise('StagingConfiguration', values);
    },
    remove: (key) => {
        return DeleteAPIPromise('StagingConfiguration/' + key)
            .then((result) => {
                if (result.status == -2) {
                    alert('City is referenced in the system and cannot be deleted. Consider inactivating.')
                }
            });
    },
    update: (key, values) => {
        return PostAPIPromise('StagingConfiguration', values);
    }
});

export class StagingConfigurationContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.criteriaRender = this.criteriaRender.bind(this);

        this.state = {
            facilities: [],

            cities: [],
            states: [],
            pickupAgencies: []
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return "Staging Configuration";
    }

    GetDropDownData = async () => {
        var facilityData = await this.FetchAPI('Facility/GetForSelection');
        var pickupAgencies = await this.FetchAPI('PickupLocation/GetPickupAgencies');

        const cityData = await this.FetchAPI('DictionaryCity');
        const stateData = await this.FetchAPI('DictionaryState');

        //const groupedFacilities = new DataSource({
        //    store: filterFacilities,
        //    key: 'id',
        //    group: 'state'
        //});

        this.setState({
            facilities: facilityData,

            cities: cityData,
            states: stateData,
            pickupLocations: pickupAgencies
        });
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    onInitNewRow(e) {
        e.data.configurationCriteria = [];
    }

    onSaved = (cell, e) => {
        // TODO: Maybe make this an actual value
        //console.log('leave hours saved', cell);
        //console.log('leave hours saved', e);
        cell.setValue(cell.data.configurationCriteria);
    }

    onRowValidating = (e) => {
        console.log('validating', e);

        var departureCityCode = ('departureCityCode' in e.newData ? e.newData.departureCityCode : (e.oldData ? e.oldData.departureCityCode : null));
        var departureStateCode = ('departureStateCode' in e.newData ? e.newData.departureStateCode : (e.oldData ? e.oldData.departureStateCode : null));
        var departureLocationId = ('departureLocationId' in e.newData ? e.newData.departureLocationId : (e.oldData ? e.oldData.departureLocationId : null));

        var destinationCityCode = ('destinationCityCode' in e.newData ? e.newData.destinationCityCode : (e.oldData ? e.oldData.destinationCityCode : null));
        var destinationStateCode = ('destinationStateCode' in e.newData ? e.newData.destinationStateCode : (e.oldData ? e.oldData.destinationStateCode : null));
        var destinationLocationId = ('destinationLocationId' in e.newData ? e.newData.destinationLocationId : (e.oldData ? e.oldData.destinationLocationId : null));

        if ((!departureCityCode || departureCityCode == '') && (!departureStateCode || departureStateCode == '') && (!departureLocationId || departureLocationId == '')
            && (!destinationCityCode || destinationCityCode == '') && (!destinationStateCode || destinationStateCode == '') && (!destinationLocationId || destinationLocationId == '')) {
            e.isValid = false;
            e.errorText = 'At least one criterion is required';
        }
    }

    criteriaRender(cell) {
        //console.log('POCs render', cell);
        let onSaved = this.onSaved.bind(this, cell);

        return <DataGrid dataSource={cell.data.configurationCriteria} onSaved={onSaved}
            keyExpr="id" showBorders={true} allowColumnResizing={true} onRowValidating={this.onRowValidating}>
            <Editing mode="popup" allowAdding={true} allowUpdating={true} allowDeleting={true}>
                <Form>
                    <Item dataField="departureCityCode" />
                    <Item dataField="destinationCityCode" />

                    <Item dataField="departureStateCode" />
                    <Item dataField="destinationStateCode" />

                    <Item dataField="departureLocationId" />
                    <Item dataField="destinationLocationId" />
                </Form>
            </Editing>

            <Column dataField="departureCityCode" caption="Departure City">
                <Lookup dataSource={this.state.cities} displayExpr="cityName" valueExpr="cityCode" />
            </Column>
            <Column dataField="departureStateCode" caption="Departure State">
                <Lookup dataSource={this.state.states} displayExpr="fullName" valueExpr="name" />
            </Column>
            <Column dataField="departureLocationId" caption="Departure Location">
                <Lookup dataSource={this.state.pickupLocations} displayExpr="name" valueExpr="id" />
            </Column>

            <Column dataField="destinationCityCode" caption="Destination City">
                <Lookup dataSource={this.state.cities} displayExpr="cityName" valueExpr="cityCode" />
            </Column>
            <Column dataField="destinationStateCode" caption="Destination State">
                <Lookup dataSource={this.state.states} displayExpr="fullName" valueExpr="name" />
            </Column>
            <Column dataField="destinationLocationId" caption="Destination Location">
                <Lookup dataSource={this.state.facilities} displayExpr="name" valueExpr="id" />
            </Column>
        </DataGrid>;
    }

    render() {
        console.log('Begin Render');

        return (
            <div className="container">
                <h1>Staging Configuration</h1>

                {/*<div className="container">*/}
                <DataGrid dataSource={customDataSource}
                    showBorders={true} allowColumnResizing={true}
                    keyExpr="id" onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating}>
                    <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()}>
                        <Form>
                            <Item itemType="group" colCount={1} colSpan={2}>
                                <Item dataField="destinationLocationId" />

                                <Item dataField="configurationCriteria" />
                            </Item>
                        </Form>
                    </Editing>
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column caption="Staging Location" dataField="destinationLocationId">
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={this.state.facilities} displayExpr="name" valueExpr="id" />
                    </Column>

                    <Column caption="Criteria" visible={false} dataField="configurationCriteria" editCellRender={this.criteriaRender} />
                </DataGrid>
                <br />
                {/*</div>*/}
            </div>
        );
    }
}