
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import * as moment from 'moment';

import CustomStore from 'devextreme/data/custom_store';
import * as $ from 'jquery'
import { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise, PutAPIPromise, onExporting } from '../BaseComponent.js';
import DataGrid, { Column, FilterRow, Editing, Form as GridForm, Selection, FormItem, GroupPanel, MasterDetail, Button as GridButton, Export, Lookup } from 'devextreme-react/data-grid';
import Form, { Item, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';
import { msalAuth } from '../../msal/MsalAuthProvider';
import Popup from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import Button from 'devextreme-react/button';

function CarRentalDetail(props) {
    const dataSource = [props.data.row.data];

    return (
        <DataGrid dataSource={dataSource} showBorders={true} allowColumnResizing={true}>
            <Column caption="Record Locator" dataField="recordLocator" />
            <Column caption="Confirmation Number" dataField="confirmationNumber" />
            <Column caption="Equipment Type" dataField="equipmentType" />
            <Column caption="Additional Notes" dataField="additionalNotes" />
            <Column caption="Daily Rate" dataField="dailyRate" />
            <Column caption="Approximate Total Price" dataField="approximateTotalPrice" />
            <Column caption="Extra Hour" dataField="extraHour" />
            <Column caption="Pick Up Address" dataField="pickUpLocationAddress" />
            <Column caption="Drop Off Address" dataField="dropOffLocationAddress" />
        </DataGrid>
    );
}

function WorkOrderResourcesGroundTransportationContainer(props) {

    // console.log(props);
    const params = useParams();

    const dataGrid = useRef();
    const correctRentalsDataGrid = useRef();
    const supportStaffRentalsDataGrid = useRef();
    const moveSupportRentalsDataGrid = useRef();
    const moveWorkOrderForm = useRef();
    const mergeRentalsDataGrid = useRef();
    const editReservationFormControl = useRef();

    const [userIsSysAdmin, setUserIsSysAdmin] = useState(false);
    const [userIsCCSupervisor, setUserIsCCSupervisor] = useState(false);

    const [isAddingCarReservation, setIsAddingCarReservation] = useState(false);
    const [carReservationDetails, setCarReservationDetails] = useState({});

    const [vehicles, setVehicles] = useState([]);
    const [rentalReasons, setRentalReasons] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);

    const [isMovingSupportRental, setIsMovingSupportRental] = useState(false);
    const [isMovingWorkOrder, setIsMovingWorkOrder] = useState(false);

    const [isCorrectingRental, setIsCorrectingRental] = useState(false);
    const [eligibleRentalEmployees, setEligibleRentalEmployees] = useState([]);
    const [itineraryItemId, setItineraryItemId] = useState(null);

    const [eligibleRentalSupportStaff, setEligibleRentalSupportStaff] = useState([]);

    const [isMergingRental, setIsMergingRental] = useState(false);

    const [destinationWorkOrder, setDestinationWorkOrder] = useState({ workOrderNumber: '' });

    const [overwritingMergeItem, setOverwritingMergeItem] = useState([]);
    const [eligibleMergeRentals, setEligibleMergeRentals] = useState([]);

    const IsReadOnly = () => {
        return msalAuth.getActiveAccount().idTokenClaims.roles.some(role => role.includes('ReadOnly'));
    }

    useEffect(() => {
        async function fetchData() {
            const vehicleData = await FetchAPIPromise('InventoryVehicles/GetActive');
            var employeeListData = await FetchAPIPromise('WorkOrderResourcesEmployeeList/' + props.workOrder.id);

            const rentalReasonData = await FetchAPIPromise('CarRentalReason');

            var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

            var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;
            //console.log('User ' + (isUserSystemAdmin ? 'IS' : 'IS NOT') + ' a Sys Admin');

            var isCCSupervisor = userRoles.findIndex(element => element === 'ProgramManager') > -1;

            setUserIsSysAdmin(isUserSystemAdmin);
            setUserIsCCSupervisor(isCCSupervisor);

            setVehicles(vehicleData);
            setEmployeeList(employeeListData);

            setRentalReasons(rentalReasonData);
        };

        fetchData();
    }, [params]);

    const componentIsReadOnly = () => {
        return IsReadOnly() || props.workOrder.statusID > 1;
    }

    const mvmTransportationStore = new CustomStore({
        //key: ['workOrderID', 'customerID'],
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(props.workOrder.id);

            if (props.workOrder.id) {
                return FetchAPIPromise('WorkOrderVehicle/' + props.workOrder.id);
            }
        },
        insert: (values) => {
            //console.log('Insert');
            values.workOrderId = props.workOrder.id;
            console.log(values);
            return PostAPIPromise('WorkOrderVehicle', values);
        },
        remove: (key) => {
            console.log(key);

            var queryString = 'workOrderId=' + key.workOrderID + '&vehicleId=' + key.vehicleId

            return DeleteAPIPromise('WorkOrderVehicle', queryString);
        }
    });

    const carRentalsStore = new CustomStore({
        key: 'employeeItineraryId',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(props.workOrder.id);

            if (props.workOrder.id) {
                return FetchAPIPromise('WorkOrderCarRentals/' + props.workOrder.id);
            }
        },
        update: (key, values) => {
             console.log('Update', values);

            if (values.isSettingReasonCode) {
                return PutAPIPromise('WorkOrderCarRentals', values);
            }
            else {
                return PostAPIPromise('WorkOrderCarRentals', values);
            }
        }
    });

    const supportRentalsStore = new CustomStore({
        key: 'supportStaffItineraryId',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(props.workOrder.id);

            if (props.workOrder.id) {
                return FetchAPIPromise('WorkOrderSupportStaffCarRentals/' + props.workOrder.id);
            }
        },
        update: (key, values) => {
            return PostAPIPromise('WorkOrderSupportStaffCarRentals?itineraryId=' + key);
        }
    });

    const setCarStatus = async (e) => {
        //console.log(e);

        await carRentalsStore.update(e.row.data.employeeItineraryId, { employeeItineraryId: e.row.data.employeeItineraryId, isSettingReasonCode: false });

        dataGrid.current.instance().refresh();
    }

    const setSupportRentalStatus = async (e) => {
        //console.log(e);

        await supportRentalsStore.update(e.row.data.supportStaffItineraryId, {});

        supportStaffRentalsDataGrid.current.instance().refresh();
    }

    const isActive = (options) => {
        return options.row.data && options.row.data.itineraryStatus != 'CANCELLED';
    }

    const isCancelled = (options) => {
        return options.row.data && options.row.data.itineraryStatus != 'ACTIVE';
    }

    const onRowPrepared = (e) => {
        if (e.rowType == 'data' && e.data.itineraryStatus == 'CANCELLED') {
            e.rowElement.className = e.rowElement.className + ' cancelledRow';
        }
    }

    const correctRental = async (e) => {
        // console.log(e);

        const employeesData = await FetchAPIPromise('WorkOrderResourcesEmployeeList/' + props.workOrder.id);
        const inactiveEmployeesData = await FetchAPIPromise('WorkOrderResourcesEmployeeList/GetInactiveEmployees/' + props.workOrder.id);

        setIsCorrectingRental(true);
        setItineraryItemId(e.row.data.employeeItineraryId);
        setEligibleRentalEmployees(employeesData.concat(inactiveEmployeesData));
    }

    const hideCorrectDialog = () => {

        setIsCorrectingRental(false);
        setItineraryItemId(null);
        setEligibleRentalEmployees([]);
    }

    const hideMergeDialog = () => {
        setIsMergingRental(false);
        setItineraryItemId(null);
        setEligibleRentalEmployees([]);
    }

    const hideMoveSupportDialog = () => {
        setIsMovingSupportRental(false);
        setItineraryItemId(null);
        setEligibleRentalSupportStaff([]);
    }

    const hideMoveWorkOrderDialog = () => {
        setIsMovingWorkOrder(false);
        setDestinationWorkOrder({ workOrderNumber: '' });
    }

    const saveWorkOrderMove = async () => {

        var result = moveWorkOrderForm.current.instance().validate();

        if (result.isValid) {

            var param = {
                itineraryItemId: itineraryItemId,
                destinationWorkOrderNumber: destinationWorkOrder.workOrderNumber
            };

            var moveResult = await PostAPIPromise('WorkOrderCarRentals/MoveWorkOrder', param);

            //console.log(moveResult);

            if (moveResult.status == -1) {
                alert(moveResult.message);
            }
            else {
                hideMoveWorkOrderDialog();
                dataGrid.current.instance().refresh();
            }
        }
    }

    const saveRentalCorrection = async () => {
        correctRentalsDataGrid.current.instance().getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select an Employee to correct Car Rental to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    employeeId: rowToMove.employeeId,
                    itineraryItemId: itineraryItemId
                }

                var moveResult = await PostAPIPromise('WorkOrderCarRentals/CorrectEmployeeRental', param);

                //console.log(moveResult);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    hideCorrectDialog();
                    dataGrid.current.instance().refresh();
                    alert('Car Rental correction Successful.');
                }
            }
        });
    }

    const saveSupportRentalMove = async () => {
        moveSupportRentalsDataGrid.current.instance().getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Support Staff to move Car Rental to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    travelerID: rowToMove.id,
                    itineraryItemId: itineraryItemId
                }

                var moveResult = await PostAPIPromise('WorkOrderSupportStaffCarRentals/MoveSupportStaffRental', param);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    hideMoveSupportDialog();
                    dataGrid.current.instance().refresh();
                    supportStaffRentalsDataGrid.current.instance().refresh();
                }
            }
        })
    }

    const saveEmployeeRentalMerge = async () => {
        mergeRentalsDataGrid.current.instance().getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Car Rental to Merge Car Rental to.");
            }
            else {
                var rowToMerge = rowData[0];

                //console.log(rowToMove);

                var param = {
                    sourceItineraryItemId: overwritingMergeItem[0].employeeItineraryId,
                    destinationItineraryItemId: rowToMerge.employeeItineraryId
                }

                var moveResult = await PostAPIPromise('WorkOrderCarRentals/MergeEmployeeCarRental', param);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    hideMergeDialog();
                    dataGrid.current.instance().refresh();
                    supportStaffRentalsDataGrid.current.instance().refresh();
                }
            }
        })
    }

    const moveSupportRental = async (e) => {
        const employeesData = await FetchAPIPromise('WorkOrderResourcesSupportStaffList/' + props.workOrder.id);

        //console.log(e.row.data);

        setIsMovingSupportRental(true);
        setItineraryItemId(e.row.data.employeeItineraryId);
        setEligibleRentalSupportStaff(employeesData);
    }

    const mergeRental = async (e) => {
        const rentalData = await FetchAPIPromise('WorkOrderCarRentals/' + props.workOrder.id);

        //console.log(e);

        setIsMergingRental(true);
        setOverwritingMergeItem([e.row.data]);
        setEligibleMergeRentals(rentalData);
    }

    const moveWorkOrder = async (e) => {
        //console.log(e);

        setIsMovingWorkOrder(true);
        setItineraryItemId(e.row.data.employeeItineraryId);
    }

    const isMoveRentalVisible = (options) => {
        return options.row.data && !options.row.data.employeeID && userIsSysAdmin;
    }

    const isAssignRentalVisible = (options) => {
        return options.row.data && !options.row.data.employeeID && userIsSysAdmin;
    }

    const isSetReasonVisible = (options) => {
        return options.row.data && options.row.data.vendorName == 'ICS Charters';
    }

    const addCarReservation = async (e) => {
        setIsAddingCarReservation(true);
        setCarReservationDetails({});
    }

    const hideCarReservation = () => {
        setIsAddingCarReservation(false);
        setCarReservationDetails({});
    }

    const saveCarReservation = async () => {
        var result = editReservationFormControl.current.instance().validate();

        if (result.isValid) {

            var carDetails = carReservationDetails;

            //console.log(carDetails);

            if (!carDetails.employeeList || carDetails.employeeList.length == 0) {
                alert('Car Reservation must contain at least one Employee');
            } else {

                carDetails.workOrderId = props.workOrder.id;

                const result = await PostAPIPromise('FroschItinerary/AddCarReservation', carDetails);

                //console.log(result);

                if (result.status == 1) {
                    setIsAddingCarReservation(false);
                    setCarReservationDetails({});

                    dataGrid.current.instance().refresh();
                }
                else if (result.status == -1) {
                    alert(result.message);
                }
                else {
                    //console.log(result);
                    alert('Failed. Please try again later.');
                }
            }
        }
    }

    const onRowUpdating = (options) => {
        options.newData = $.extend({}, options.oldData, options.newData);

        options.newData.isSettingReasonCode = true;

        console.log(options.newData);
    }

    const dropOffTimeCellRender = (e) => {
        // console.log(e);

        var parsedValue = moment(e.value);

        console.log(parsedValue);
        console.log(moment('1900-01-01T00:00'));

        if (parsedValue.isSame(moment('1900-01-01T00:00'))) {
            return 'Not Available';
        }
        else {
            return parsedValue.format(e.column.format);
        }
    }

    return (
        <div style={{ margin: "10px" }}>
            {props.workOrder != null && (userIsSysAdmin || userIsCCSupervisor) &&
                <Button disabled={componentIsReadOnly()} icon="car" text="Add Commercial Rental" onClick={addCarReservation} />
            }
            <br />
            <br />
            <fieldset>
                <header><b>MVM Transportation</b></header>
                <br />
                <DataGrid dataSource={mvmTransportationStore} showBorders={true} allowColumnResizing={true} onExporting={onExporting}>
                    <Export enabled={true} fileName={"groundTransportation" + props.workOrder.workOrderName} />
                    <Editing mode="popup" allowAdding={!componentIsReadOnly()} allowDeleting={!componentIsReadOnly()}>
                        <GridForm colCount={1}>
                            <Item dataField="vehicleId" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, dataSource: vehicles, displayExpr: 'vehicleNumber', valueExpr: 'id' }} />
                        </GridForm>
                    </Editing>

                    <Column dataField="vehicleId" caption="Vehicle Number" visible={false}>
                        <RequiredRule />
                    </Column>
                    <Column caption="Vehicle Number" dataField="name">
                    </Column>
                    <Column caption="Vehicle Description" dataField="description" />
                </DataGrid>
            </fieldset>
            <br />
            <fieldset>
                <header><b>Car Rentals</b></header>
                <br />

                <DataGrid ref={dataGrid} dataSource={carRentalsStore} showBorders={true} allowColumnResizing={true} onRowPrepared={onRowPrepared} onRowUpdating={onRowUpdating}>
                    <Editing mode="popup" allowUpdating={!componentIsReadOnly()} />
                    <GroupPanel visible={true} />

                    <Column caption="Employee Name" dataField="travelerName" groupIndex={0}>
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Status" dataField="status">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Vendor" dataField="vendorName">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Rental Type" dataField="rentalType">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Pick Up City" dataField="pickUpCity">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Pick Up Location" dataField="pickUpLocationName">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Pick Up Time" dataField="pickUpLocalTime" dataType="datetime" format="MM/dd/yy HH:mm">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Drop Off City" dataField="dropOffCity">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Drop Off Location" dataField="dropOffLocationName">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Drop Off Time" dataField="dropOffLocalTime" dataType="datetime" format="MM/DD/yy HH:mm" cellRender={dropOffTimeCellRender}>
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Booking Status" dataField="itineraryStatus">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Reason" dataField="reasonCode">
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={rentalReasons} displayExpr="name" valueExpr="code" />
                    </Column>
                    <Column visible={!componentIsReadOnly()} type="buttons">
                        <GridButton
                            text="Move as Support"
                            hint="Move as Support"
                            onClick={moveSupportRental}
                            visible={isMoveRentalVisible}
                        />
                        <GridButton
                            text="Move Work Order"
                            hint="Move Work Order"
                            onClick={moveWorkOrder}
                            visible={isMoveRentalVisible}
                        />
                        <GridButton
                            text="Merge"
                            hint="Merge"
                            onClick={mergeRental}
                        />
                        <GridButton
                            text="Correct"
                            hint="Correct"
                            onClick={correctRental}
                            visible={isAssignRentalVisible}
                        />
                        <GridButton
                            text="Cancel"
                            hint="Cancel"
                            onClick={setCarStatus}
                            visible={isActive}
                        />
                        <GridButton
                            text="Make Active"
                            hint="Make Active"
                            onClick={setCarStatus}
                            visible={isCancelled}
                        />
                        <GridButton
                            text="Set Reason"
                            hint="Set Reason"
                            name="edit"
                            visible={isSetReasonVisible}
                        />
                    </Column>

                    <MasterDetail enabled={true} component={CarRentalDetail} />
                </DataGrid>

                <Popup visible={isCorrectingRental} onHiding={hideCorrectDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Employee to correct this Car Rental to</p>
                        <br />
                        <DataGrid ref={correctRentalsDataGrid} dataSource={eligibleRentalEmployees} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="Employee No" dataField="employeeNo" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Gender" dataField="sex" />
                            <Column caption="Office" dataField="office" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={saveRentalCorrection.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={hideCorrectDialog} />
                    </div>
                </Popup>

                <br />

                <header><b>Support Staff Car Rentals</b></header>
                <br />

                <DataGrid ref={supportStaffRentalsDataGrid} dataSource={supportRentalsStore} showBorders={true} allowColumnResizing={true} onRowPrepared={onRowPrepared} onExporting={onExporting}>
                    <Export enabled={true} fileName={"supportStaffRentals" + props.workOrder.workOrderName} />
                    <Editing mode="row" allowUpdating={true} />
                    <GroupPanel visible={true} />

                    <Column caption="Employee Name" dataField="travelerName" groupIndex={0} />
                    <Column caption="Status" dataField="status" />
                    <Column caption="Vendor" dataField="vendorName" />
                    <Column caption="Rental Type" dataField="rentalType" />
                    <Column caption="Pick Up City" dataField="pickUpCity" />
                    <Column caption="Pick Up Location" dataField="pickUpLocationName" />
                    <Column caption="Pick Up Time" dataField="pickUpLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Drop Off City" dataField="dropOffCity" />
                    <Column caption="Drop Off Location" dataField="dropOffLocationName" />
                    <Column caption="Drop Off Time" dataField="dropOffLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Booking Status" dataField="itineraryStatus" />
                    <Column type="buttons">
                        <GridButton
                            text="Cancel"
                            hint="Cancel"
                            onClick={setSupportRentalStatus}
                            visible={isActive}
                        />
                        <GridButton
                            text="Make Active"
                            hint="Make Active"
                            onClick={setSupportRentalStatus}
                            visible={isCancelled}
                        />
                    </Column>
                    <MasterDetail enabled={true} component={CarRentalDetail} />
                </DataGrid>

                <Popup visible={isMovingSupportRental} onHiding={hideMoveSupportDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Support Staff to move this Car Rental to</p>
                        <br />
                        <DataGrid ref={moveSupportRentalsDataGrid} dataSource={eligibleRentalSupportStaff} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={saveSupportRentalMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={hideMoveSupportDialog} />
                    </div>
                </Popup>

                <Popup visible={isMergingRental} onHiding={hideMergeDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={1000} height={675}>
                    <ScrollView height={575}>
                        <p>Select the Car Rental to Merge this Car Rental on top of</p>
                        <br />
                        <DataGrid dataSource={overwritingMergeItem} showBorders={true} allowColumnResizing={true}>

                            <Column caption="Employee Name" dataField="travelerName" />
                            <Column caption="Confirmation #" dataField="confirmationNumber" />
                            <Column caption="Status" dataField="status" />
                            <Column caption="Vendor" dataField="vendorName" />
                            <Column caption="Pick Up City" dataField="pickUpCity" />
                            <Column caption="Pick Up Location" dataField="pickUpLocationName" />
                            <Column caption="Pick Up Time" dataField="pickUpLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Drop Off City" dataField="dropOffCity" />
                            <Column caption="Drop Off Location" dataField="dropOffLocationName" />
                            <Column caption="Drop Off Time" dataField="dropOffLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Booking Status" dataField="itineraryStatus" />
                        </DataGrid>
                        <br />
                        <DataGrid ref={mergeRentalsDataGrid} dataSource={eligibleMergeRentals} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="Employee Name" dataField="travelerName" />
                            <Column caption="Confirmation #" dataField="confirmationNumber" />
                            <Column caption="Status" dataField="status" />
                            <Column caption="Vendor" dataField="vendorName" />
                            <Column caption="Pick Up City" dataField="pickUpCity" />
                            <Column caption="Pick Up Location" dataField="pickUpLocationName" />
                            <Column caption="Pick Up Time" dataField="pickUpLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Drop Off City" dataField="dropOffCity" />
                            <Column caption="Drop Off Location" dataField="dropOffLocationName" />
                            <Column caption="Drop Off Time" dataField="dropOffLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Booking Status" dataField="itineraryStatus" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={saveEmployeeRentalMerge.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={hideMergeDialog} />
                    </div>
                </Popup>

                <Popup visible={isMovingWorkOrder} onHiding={hideMoveWorkOrderDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Enter the Work Order to move this Car Rental to</p>
                        <br />
                        <Form ref={moveWorkOrderForm} formData={destinationWorkOrder}>
                            <Item editorType="dxTextBox" dataField="workOrderNumber">
                                <RequiredRule />
                            </Item>
                        </Form>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={saveWorkOrderMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={hideMoveWorkOrderDialog} />
                    </div>
                </Popup>

                <Popup visible={isAddingCarReservation} onHiding={hideCarReservation} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={true} title="Add Car Reservation" width={800} height={495}>
                    <ScrollView height={360}>
                        <Form ref={editReservationFormControl}
                            id="form" formData={carReservationDetails} colCount={2}>

                            <Item editorType="dxTextBox" dataField="recordLocator" editorOptions={{ maxLength: 10 }}>
                                <Label text="Record Locator" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxDateBox" dataField="reservationDate" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>
                                <Label text="Reservation Date" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="confirmationNumber" editorOptions={{ maxLength: 25 }}>
                                <Label text="Confirmation #" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="status" editorOptions={{ maxLength: 25 }}>
                                <Label text="Status" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="vendorName" editorOptions={{ maxLength: 50 }}>
                                <Label text="Vendor Name" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="equipmentType" editorOptions={{ maxLength: 100 }}>
                                <Label text="Equipment Type" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="dailyRate" editorOptions={{ maxLength: 100 }}>
                                <Label text="Daily Rate" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="extraHour" editorOptions={{ maxLength: 100 }}>
                                <Label text="Extra Hour" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="approximateTotalPrice" editorOptions={{ maxLength: 100 }}>
                                <Label text="Approximate Total Price" />
                                <RequiredRule />
                            </Item>

                            <EmptyItem />

                            <Item editorType="dxTextBox" dataField="pickUpCity" editorOptions={{ maxLength: 100 }}>
                                <Label text="Pick Up City" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="pickUpLocationName" editorOptions={{ maxLength: 100 }}>
                                <Label text="Pick Up Location Name" />
                                <RequiredRule />
                            </Item>

                            <Item colSpan={2} editorType="dxTextBox" dataField="pickUpLocationAddress" editorOptions={{ maxLength: 100 }}>
                                <Label text="Pick Up Location Address" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="pickUpLocationPhone" editorOptions={{ maxLength: 100 }}>
                                <Label text="Pick Up Location Phone" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxDateBox" dataField="pickUpLocalTime" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>
                                <Label text="Pick Up Local Time" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="dropOffCity" editorOptions={{ maxLength: 100 }}>
                                <Label text="Drop Off City" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="dropOffLocationName" editorOptions={{ maxLength: 100 }}>
                                <Label text="Drop Off Location Name" />
                                <RequiredRule />
                            </Item>

                            <Item colSpan={2} editorType="dxTextBox" dataField="dropOffLocationAddress" editorOptions={{ maxLength: 100 }}>
                                <Label text="Drop Off Location Address" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="dropOffLocationPhone" editorOptions={{ maxLength: 100 }}>
                                <Label text="Drop Off Location Phone" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxDateBox" dataField="dropOffLocalTime" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>
                                <Label text="Drop Off Local Time" />
                                <RequiredRule />
                            </Item>

                            <Item colSpan={2} editorType="dxTagBox" dataField="employeeList" editorOptions={{ applyValueMode: 'useButtons', showSelectionControls: true, dataSource: employeeList, displayExpr: 'displayValue', valueExpr: 'employeeId' }}>
                                <Label text="Employees" />
                            </Item>
                        </Form>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="OK" onClick={saveCarReservation} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={hideCarReservation} />
                    </div>
                </Popup>
            </fieldset>
        </div>
    );
}

export default WorkOrderResourcesGroundTransportationContainer;