import React from 'react';
import BaseComponent, { FetchAPIPromise, DeleteAPIPromise, PostAPIPromise } from '../BaseComponent';
import PropTypes from 'prop-types';
import Form, { Item as FormItem, Label, RequiredRule } from 'devextreme-react/form';
import DataGrid, { FilterRow, Column, Editing, Button as GridButton, Summary, TotalItem, Export } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Link } from 'react-router-dom';
import Button from 'devextreme-react/button';
import Popup from 'devextreme-react/popup';
import Accordion, { Item as AccordionItem } from 'devextreme-react/accordion';
import { ScrollView } from 'devextreme-react/scroll-view';
import { msalAuth } from '../../msal/MsalAuthProvider';
import { alert, confirm } from 'devextreme/ui/dialog';

function RulesTemplate(props) {
    return (
        <div>
            {props.rules.statusMinTsTotalCount ? <i class="dx-icon-todo" /> : <i class="dx-icon-remove" />} <label> A minimum of <b>{props.rules.minTsTotalCount}</b> Employee(s).</label><br />
            {props.rules.minTsMaleCount > 0 &&
                <div>
                    {props.rules.statusMinTsMaleCount ? <i class="dx-icon-todo" /> : <i class="dx-icon-remove" />} < label > A minimum of <b>{props.rules.minTsMaleCount} Male</b> Employee(s).</label><br />
                </div>
            }
            {props.rules.minTsFemaleCount > 0 &&
                <div>
                {props.rules.statusMinTsMaleCount ? <i class="dx-icon-todo" /> : <i class="dx-icon-remove" />} < label > A minimum of <b>{props.rules.minTsFemaleCount} Female</b> Employee(s).</label><br />
                </div>
            }
            {props.rules.parentWorkOrderName != null &&
                <div>
                    <label>Select TS! Please include employee(s) from parent work order: {props.rules.parentWorkOrderName}</label>
                </div>
            }
        </div>
    );
}

export class WorkOrderResourcesEmployeesContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object
    };

    constructor(props) {
        super(props);

        //console.log('Detail Prop: ', props)

        this.state = {
            rules: {},

            airportCodes: [],
            customers: [],
            employeesCount: 0,

            isAddingCommercialFlight: false,

            commercialFlightDetails: {},

            userIsSysAdmin: false,
            userIsCCSupervisor: false
        };
    }

    customDataSource = new CustomStore({
        key: ['workOrderId', 'employeeId'],
        loadMode: 'raw',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);GetAllFlightsForBooking
            //console.log(this.props.workOrder);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderResourcesEmployeeList/' + this.props.workOrder.id);
            }
        },
        update: (key, values) => {
            return PostAPIPromise('WorkOrderResourcesEmployeeList?workOrderID=' + key.workOrderId + '&empID=' + key.employeeId + '&values=' + values);
        },
        remove: (key) => {
            //console.log(key);

            var queryString = 'workOrderId=' + key.workOrderId + '&employeeId=' + key.employeeId

            var currentcount = this.state.employeesCount;

            if (this.state.rules.minTsTotalCount > (currentcount - 1) && this.props.workOrder.statusID == 1) {
                alert("A minumum of " + this.state.rules.minTsTotalCount + " employees is required at all times when Work Order is Active. Add another employee to the Work Order, then try removing again.")
                return
            }
            else {
                this.setState({
                    employeesCount: currentcount - 1
                });
                return DeleteAPIPromise('WorkOrderResourcesEmployeeList', queryString)
                    .then((result) => {
                    if (result.status != 1) {
                        alert(result.message);
                    }
                });
            }
        }
    });

    componentDidUpdate(prevProps) {
        //console.log('Props Updated');

        if (this.props.workOrder != prevProps.workOrder) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.fetchData();
            this.dataGrid.instance().refresh();
            this.inactiveRosterAccordion.instance().collapseItem(0);
            this.inactiveEmployeesDataGrid.instance().refresh();
            this.getRules(this.props.workOrder);
        }
    }

    onRowRemoved = (e) => {
        this.inactiveEmployeesDataGrid.instance().refresh();
    }

    GetDropDownData = async () => {
        var airportListData = await this.FetchAPI('Facility/GetAirports');

        var customerListData = await this.FetchAPI('BookTicket/GetCustomersByWorkOrder/' + this.props.workOrder.id);

        //console.log(customerListData);

        this.setState({
            airportCodes: airportListData,
            customers: customerListData,
        });
    }

    GetData = async () => {
        var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

        var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;
        //console.log('User ' + (isUserSystemAdmin ? 'IS' : 'IS NOT') + ' a Sys Admin');

        var isCCSupervisor = userRoles.findIndex(element => element === 'ProgramManager') > -1;

        await this.setState({
            userIsSysAdmin: isUserSystemAdmin,
            userIsCCSupervisor: isCCSupervisor
        });
    }

    getRules = async (workOrder) => {
        var rules = await this.FetchAPI('WorkOrder/GetRules/' + workOrder.id);
        var employeeListData = await this.FetchAPI('WorkOrderResourcesEmployeeList/' + this.props.workOrder.id);

        await this.setState({
            rules: rules,
            employeesCount: employeeListData.length
        });
    }

    onRowPrepared = (e) => {
        //console.log(e);

        if (e.rowType == 'data' && e.data.workOrderRole == 'TL') {
            e.rowElement.className = e.rowElement.className + ' transportLeaderRow';
        }
    }

    setTransportLeader = async (e) => {
        //console.log(e);

        await this.customDataSource.update(e.row.key, {});

        this.dataGrid.instance().refresh();
    }

    setReportTime = async (e) => {
        //console.log(e);

        await this.customDataSource.update(e.row.key, "Confirmed");

        this.dataGrid.instance().refresh();
    }

    allowUpdating = (options) => {
        //console.log(options);
        //console.log('Can Delete', options.row.data && options.row.data.createUserId == this.state.employeeId && options.row.data.statusCode == 'SUBMITTED');

        return options.row.data && options.row.data.workOrderRole != 'TL';
    }

    addCommercialFlight = async (e) => {

        await this.setState({
            isAddingCommercialFlight: true
        });
    }

    hideCommercialFlight = () => {
        this.setState({
            isAddingCommercialFlight: false,
            commercialFlightDetails: {}
        });
    }

    saveCommercialFlight = async () => {
        var result = this.editCommercialFormControl.instance().validate();

        if (result.isValid) {

            var flightDetails = this.state.commercialFlightDetails;

            //console.log(flightDetails);

            if ((!flightDetails.customerList || flightDetails.customerList.length == 0) && (!flightDetails.employeeList || flightDetails.employeeList.length == 0)) {
                alert('Commercial Flight Itinerary must contain at least one Customer and/or Employee');
            } else {

                flightDetails.workOrderId = this.props.workOrder.id;

                const result = await this.PostAPI('FroschItinerary/AddCommercialFlightItinerary', flightDetails);

                console.log(result);

                if (result.status == 1) {
                    await this.setState({
                        isAddingCommercialFlight: false,
                        commercialFlightDetails: {}
                    });
                }
                else if (result.status == -1) {
                    alert(result.message);
                }
                else {
                    //console.log(result);
                    alert('Failed. Please try again later.');
                }
                //    }
            }
        }
    }

    experienceRender = (options) => {
        //console.log('Experience Render', options);

        var competencies = options.row.data.competencyList;

        var controls = [];

        controls.push(<span>
            <span style={{
                color: options.value >= 700 ? '#57d500'
                    : (options.value >= 300 && options.value < 700) ? '#ffa500'
                        : options.value < 300 ? '#ff2e00'
                            : '#ffffff'
            }}>
                &#x2B24;
                </span>
        </span>);

        competencies.map((competency) => {
            controls.push(<span>&nbsp;</span>);
            controls.push(<i style={{ height: '15px !important', width: '15px !important' }} className={`dx-icon-${competency.tagIcon}`} title={competency.tagName} />);
        });

        return (<div style={{ textAlign: 'left' }}>{controls}</div>);
    }

    inactiveEmployeesDataSource = new CustomStore({
        key: ['workOrderId', 'employeeId'],
        load: (loadOptions) => {
            console.log('Load', loadOptions);
            console.log(this.props.workOrder.id);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderResourcesEmployeeList/GetInactiveEmployees/' + this.props.workOrder.id);
            }
        }
    });

    componentIsReadOnly = () => {
        return this.IsReadOnly() || this.props.workOrder.statusID > 1;
    }

    timeComponentIsReadOnly = () => {
        return this.IsReadOnly() || this.props.workOrder.statusID != 1;
    }

    phoneCellRender = (e) => {
        //console.log(e);

        return this.formatPhoneNumber(e.value);
    }

    TimeConfirmationRender = (e) => {
        //console.log(e);
        if (e.value == 'Confirmed') {
            return (<div> {<i class="dx-icon-todo" />}<label> &nbsp;{e.value}</label> </div>);
        }
        else {
            return (<div> {<i class="dx-icon-remove" />}<label> &nbsp;{e.value}</label> </div>);
        }
    }

    TimeConfirmationRender = (e) => {
        //console.log(e);
        if (e.value == 'Confirmed') {
            return (<div> {<i class="dx-icon-todo" />}<label> &nbsp;{e.value}</label> </div>);
        }
        else {
            return (<div> {<i class="dx-icon-remove" />}<label> &nbsp;{e.value}</label> </div>);
        }
    }

    render() {
        //console.log("Beginning render");
        //console.log(this.props.workOrder);

        return (
            <div style={{ margin: "10px" }}>
                {this.props.workOrder != null && this.props.workOrder.statusID < 2 &&
                    <Link to={`/workorder-schedule/${this.props.workOrder.id}`}>
                        <Button icon={(this.componentIsReadOnly() ? 'search' : 'plus')} text={(this.componentIsReadOnly() ? 'View Schedule' : 'Manage Schedule')} />
                    </Link>
                }
                &nbsp;
                {(this.componentIsReadOnly())
                    ? <Button disabled={true} icon="airplane" text="Book Travel" />
                    : <Link to={`/book-ticket/${this.props.workOrder.id}`}><Button icon="airplane" text="Book Travel" /></Link>
                }
                &nbsp;
                {this.props.workOrder != null && (this.state.userIsSysAdmin || this.state.userIsCCSupervisor) &&
                    <Button disabled={this.componentIsReadOnly()} icon="airplane" text="Add Commercial Flight" onClick={this.addCommercialFlight} />
                }
                <br />
                <br />
                <RulesTemplate rules={this.state.rules} />
                <br />
                <br />

                <DataGrid dataSource={this.customDataSource} showBorders={true} allowColumnResizing={true}
                    ref={ref => this.dataGrid = ref} onRowPrepared={this.onRowPrepared} onExporting={this.onExporting}
                    onRowRemoved={this.onRowRemoved}>
                    <Export enabled={true} fileName={"employees" + this.props.workOrder.workOrderName} />
                    <Editing allowDeleting={!this.componentIsReadOnly()} allowUpdating={this.allowUpdating && !this.componentIsReadOnly()} />
                    <FilterRow visible={true} />

                    <Column caption="Comp." width={85} allowFiltering={false} alignment="center" dataField="experienceMetric" cellRender={this.experienceRender} />
                    <Column caption="Employee No" dataField="employeeNo" />
                    <Column caption="First Name" dataField="firstName" />
                    <Column caption="Middle Name" dataField="middleName" />
                    <Column caption="Last Name" dataField="lastName" />
                    <Column caption="DOB" dataField="birthDate" dataType="date" format="MM/dd/yyyy" />
                    <Column caption="Phone Number" dataField="homePhone" cellRender={this.phoneCellRender} />
                    <Column caption="Gender" dataField="sex" />
                    <Column caption="Office" dataField="office" />
                    <Column caption="Employee Role" dataField="employeeRole" />
                    <Column caption="Work Order Role" dataField="workOrderRole" />
                    <Column caption="Report Time Confirmed" dataField="reportTimeConfirmation" cellRender={this.TimeConfirmationRender} />
                    <Column type="buttons" dataField="buttons">
                        <GridButton
                            text="Set As TL"
                            onClick={this.setTransportLeader}
                            visible={this.allowUpdating && !this.componentIsReadOnly()}
                        />
                        <GridButton
                            text="Mark Time Confirmed"
                            onClick={this.setReportTime}
                            visible={!this.timeComponentIsReadOnly()}
                        />
                        <GridButton name="delete" />
                    </Column>
                    <Summary>
                        <TotalItem
                            cssClass="summaryRow"
                            showInColumn="experienceMetric"
                            summaryType="count"
                            displayFormat="Total Employees: {0}" />
                    </Summary>
                </DataGrid>

                <br />
                <Accordion collapsible={true} ref={ref => this.inactiveRosterAccordion = ref} deferRendering={false}>
                    <AccordionItem title="Historic Assignments">
                        <DataGrid dataSource={this.inactiveEmployeesDataSource} showBorders={true} allowColumnResizing={true}
                            ref={ref => this.inactiveEmployeesDataGrid = ref}>
                            <Export enabled={true} fileName={"inactiveEmployees" + this.props.workOrder.workOrderName} />
                            <FilterRow visible={true} />

                            <Column caption="Employee No" dataField="employeeNo" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="DOB" dataField="birthDate" dataType="date" format="MM/dd/yyyy" />
                            <Column caption="Gender" dataField="sex" />
                            <Column caption="Office" dataField="office" />
                        </DataGrid>
                    </AccordionItem>
                </Accordion>

                <Popup visible={this.state.isAddingCommercialFlight} onHiding={this.hideCommercialFlight} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={true} title="Add Commercial Flight" width={800} height={495}>
                    <ScrollView height={360}>
                        <Form ref={ref => this.editCommercialFormControl = ref}
                            id="form" formData={this.state.commercialFlightDetails} colCount={2}>

                            <FormItem editorType="dxTextBox" dataField="recordLocator" editorOptions={{ maxLength: 10 }}>
                                <Label text="Record Locator" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem editorType="dxDateBox" dataField="reservationDate" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>
                                <Label text="Reservation Date" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem editorType="dxTextBox" dataField="operatingCarrier" editorOptions={{ maxLength: 100 }}>
                                <Label text="Operating Carrier" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem editorType="dxTextBox" dataField="marketingCarrier" editorOptions={{ maxLength: 100 }}>
                                <Label text="Marketing Carrier" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem editorType="dxTextBox" dataField="flightNumber" editorOptions={{ maxLength: 25 }}>
                                <Label text="Flight Number" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem editorType="dxTextBox" dataField="confirmationNumber" editorOptions={{ maxLength: 10 }}>
                                <Label text="Confirmation #" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem editorType="dxTextBox" dataField="bookingStatus" editorOptions={{ maxLength: 20 }}>
                                <Label text="Booking Status" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem editorType="dxTextBox" dataField="classOfService" editorOptions={{ maxLength: 20 }}>
                                <Label text="Class of Service" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem editorType="dxSelectBox" dataField="departureAirportCode" editorOptions={{ dataSource: this.state.airportCodes, displayExpr: 'display', valueExpr: 'airportCode', searchEnabled: true }}>
                                <Label text="Departure Airport Code" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem dataField="departureAirportName" editorOptions={{ maxLength: 100 }}>
                                <Label text="Departure Airport Name" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem editorType="dxSelectBox" dataField="arrivalAirportCode" editorOptions={{ dataSource: this.state.airportCodes, displayExpr: 'display', valueExpr: 'airportCode', searchEnabled: true }}>
                                <Label text="Arrival Airport Code" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem dataField="arrivalAirportName" editorOptions={{ maxLength: 100 }}>
                                <Label text="Arrival Airport Name" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem dataField="departureAirportTerminal" editorOptions={{ maxLength: 25 }}>
                                <Label text="Departure Airport Terminal" />
                            </FormItem>

                            <FormItem dataField="arrivalAirportTerminal" editorOptions={{ maxLength: 25 }}>
                                <Label text="Arrival Airport Terminal" />
                            </FormItem>

                            <FormItem editorType="dxDateBox" dataField="departureLocalTime" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>
                                <Label text="Departure Local Time" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem editorType="dxDateBox" dataField="arrivalLocalTime" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>
                                <Label text="Arrival Local Time" />
                                <RequiredRule />
                            </FormItem>

                            <FormItem colSpan={2} editorType="dxTagBox" dataField="customerList" editorOptions={{ applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.customers, displayExpr: 'display', valueExpr: 'customerID' }}>
                                <Label text="Customers" />
                            </FormItem>

                            <FormItem colSpan={2} editorType="dxTagBox" dataField="employeeList" editorOptions={{ applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.customDataSource.__rawData, displayExpr: 'displayValue', valueExpr: 'employeeId' }}>
                                <Label text="Employees" />
                            </FormItem>
                        </Form>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="OK" onClick={this.saveCommercialFlight} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideCommercialFlight} />
                    </div>
                </Popup>
            </div>
        );
    }
}

// TODO: Do we still need the tab coloration?
// TODO: Should Add Employees still be removed on Active+ status?

//var employeeList = _woBll.GetEmployeesByWorkorderID(workOrderID);
//rptEmployee.DataSource = employeeList;
//rptEmployee.DataBind();
//tabEmployee.Style.Add("color", employeeList.Count > 0 ? "green" : "red");
