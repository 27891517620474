import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { systemConfig } from './SystemConfig';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenu />
                <Container style={{ paddingBottom: '25px' }} fluid={true}>
                    {this.props.children}
                </Container>
                <footer className="navbar fixed-bottom" style={{ backgroundColor: 'lightgray', width: "100%", justifyContent: 'center' }}>
                    <div>{systemConfig.systemName} Version { systemConfig.systemVersion } &#169; 2024 MVM. All Rights Reserved.</div>
                </footer>
            </div>
        );
    }
}
