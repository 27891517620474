import React from 'react';
import BaseComponent, { FetchAPIPromise, PostAPIPromise } from "../BaseComponent";
import DataGrid, { Column, FilterRow, Pager, Paging, Lookup, RequiredRule, Editing, FormItem, Export } from 'devextreme-react/data-grid';
import Form, { Item, Label } from 'devextreme-react/form';
import * as moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import CheckBox from 'devextreme-react/check-box';
import * as $ from 'jquery';

export class OvertimeRequestsContainer extends BaseComponent {
    static displayName = OvertimeRequestsContainer.name;

    constructor(props) {
        super(props);

        this.WorkedHoursCellRender = this.WorkedHoursCellRender.bind(this);

        this.state = {
            statuses: [],

            filter: {
                submitted: true,
                approved: false,
                notapproved: false,
                weekBegin: ''
            }
        };
    }

    WorkedHoursCellRender = function (options) {
        console.log(this.state);

        var workedTotal = 100;

        if (options.row.data.workedShifts.length > 0 && this.state.filter.weekBegin) {
            workedTotal = 0;

            var currentPeriodBeginMoment = moment(this.state.filter.weekBegin);
            var currentPeriodEndMoment = currentPeriodBeginMoment.clone().add(7, 'day');

            options.row.data.workedShifts.map((shift, shiftIndex) => {
                //console.log(shift);
                var clockOutMoment = moment(shift.clockOut);
                var clockInMoment = moment(shift.clockIn);

                // console.log(tripBeginMoment);

                if ((clockInMoment.isSameOrAfter(currentPeriodBeginMoment) && clockInMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockOutMoment.isSameOrAfter(currentPeriodBeginMoment) && clockOutMoment.isSameOrBefore(currentPeriodEndMoment))
                    ||
                    (clockInMoment.isSameOrBefore(currentPeriodBeginMoment) && clockOutMoment.isSameOrAfter(currentPeriodEndMoment))) {

                    var begin = clockInMoment.isBefore(currentPeriodBeginMoment) ? currentPeriodBeginMoment : clockInMoment;
                    var end = clockOutMoment.isAfter(currentPeriodEndMoment) ? currentPeriodEndMoment : clockOutMoment;

                    workedTotal += end.diff(begin, 'minute') / 60;
                }
            });

            workedTotal = workedTotal.toFixed(2);
        }
        else {
            workedTotal = 0;
        }

        return (<span>
            {workedTotal}
        </span>);
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Overtime Requests");
    }

    customDataSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            if (this.state.filter.weekBegin) {
                var queryString = '';
                //var queryOptions = Object.keys(loadOptions.userData);

                queryString += 'submitted=' + this.state.filter.submitted;
                queryString += '&approved=' + this.state.filter.approved;
                queryString += '&notapproved=' + this.state.filter.notapproved;
                queryString += '&weekBegin=' + this.state.filter.weekBegin;

                //console.log(queryString);

                return FetchAPIPromise('OvertimeApproval/GetApprovalsForSupervisor', queryString);
            }
        },
        update: (key, values) => {
            console.log(values);
            return PostAPIPromise('OvertimeApproval/SaveOvertimeApproval', values).then((result) => {
                if (result.status != 1) {
                    alert(result.message);
                }
            });
        }
    });

    handleChange = (event) => {
        this.setState(
            (state) => ({
                filter: {
                    ...state.filter,
                    [event.element.id]: !state.filter[event.element.id]
                }
            }),
            () => {
                //console.log(this.dataGrid);
                this.dataGrid.instance().refresh();
            }
        );
    }

    fieldDataChanged = async (e) => {
        console.log('Field Data changed', e);
        //console.log(this.state.filters);

        if (e.dataField == 'weekBegin') {
            this.dataGrid.instance().refresh();
        }
    }

    GetDropDownData = async () => {
        const statusesData = await this.FetchAPI('DictionaryApprovalStatus');
        
        console.log(statusesData);

        this.setState({
            statuses: statusesData
        });
    }

    GetData = async () => {
        var today = moment();
        var from_date = today.clone().startOf('week');

        this.setState({
            filter: {
                submitted: true,
                approved: false,
                notapproved: false,
                weekBegin: from_date.format("MM/DD/YYYY")
            }
        });

        this.dataGrid.instance().refresh();
    }

    onRowUpdating(options) {
        //console.log('Row Updating');
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    isDisabledDate = (args) => {
        //console.log(args);

        if (args.view === "month") {
            return args.date.getDay() > 0;
        }

        return false;
    }

    render() {
        return (
            <div className="container">
                <h1>Overtime Requests</h1>

                <Form colCount={2} formData={this.state.filter} onFieldDataChanged={this.fieldDataChanged}>
                    <Item>
                        <Label text="Request Status" />
                        <CheckBox value={this.state.filter.submitted} id="submitted" text="Submitted" onValueChanged={this.handleChange} /> &nbsp;
                        <CheckBox value={this.state.filter.approved} id="approved" text="Approved" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filter.notapproved} id="notapproved" text="Not Approved" onValueChanged={this.handleChange} />
                    </Item>
                    <Item editorType="dxDateBox" dataField="weekBegin" editorOptions={{
                        disabledDates: this.isDisabledDate
                    }}>
                        <Label text="Week Begin" />
                    </Item>
                </Form>

                <br />
                <DataGrid dataSource={this.customDataSource}
                    showBorders={true} allowColumnResizing={true} onRowUpdating={this.onRowUpdating}
                    ref={ref => this.dataGrid = ref} keyExpr="id" onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"overtimeRequests"} />
                    <Editing mode="popup" allowAdding={false} allowUpdating={!this.IsReadOnly()} allowDeleting={false} />
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column dataField="id" visible={false}>
                        <FormItem visible={false} />
                    </Column>

                    <Column caption="Employee Name" dataField="employeeName">
                        <FormItem editorOptions={{ readOnly: true }} />
                    </Column>
                    <Column caption="Work Order" dataField="workOrderDisplay">
                        <FormItem editorOptions={{ readOnly: true }} />
                    </Column>
                    <Column caption="Shift Begin" dataField="shiftBegin" dataType="datetime" format="MM/dd/yy, HH:mm">
                        <FormItem editorOptions={{ readOnly: true }} />
                    </Column>
                    <Column caption="Shift End" dataField="shiftEnd" dataType="datetime" format="MM/dd/yy, HH:mm">
                        <FormItem editorOptions={{ readOnly: true }} />
                    </Column>
                    <Column caption="Current Hours" dataField="workedShifts" cellRender={this.WorkedHoursCellRender}>
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Status" dataField="statusCode">
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={this.state.statuses} displayExpr="statusName" valueExpr="statusCode" />
                    </Column>
                    <Column caption="Submitted By" dataField="submitterUser">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Submitted At" dataField="submittedDate" dataType="datetime" format="MM/dd/yy, HH:mm">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Updated By" dataField="approvalUser">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Updated At" dataField="approvalDate" dataType="datetime" format="MM/dd/yy, HH:mm">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Comments" dataField="overtimeNote">
                    </Column>
                </DataGrid>
                <br />
            </div>
        );
    }
}