import React, { useEffect, useState } from 'react';

import BaseComponent, { FetchAPIPromise, PostAPIPromise, formatComponentPageName } from '../BaseComponent.js';

import DataGrid, { FilterRow, Button as GridButton, Pager, Paging, Column, MasterDetail } from 'devextreme-react/data-grid';

import { IPopoverOptions, Popover } from 'devextreme-react/popover';

import { useParams } from 'react-router-dom';

import { GoogleMap, Polyline, Circle, useJsApiLoader } from '@react-google-maps/api';

import CustomMarker from './CustomMarker.js';

import Form, { EmptyItem, Item, Label, RequiredRule } from 'devextreme-react/form';

import Button from 'devextreme-react/button';

import { CheckBox } from 'devextreme-react/check-box';

import List from 'devextreme-react/list';

import { msalAuth } from '../../msal/MsalAuthProvider';

import * as moment from 'moment';

const containerStyle = {
    width: '800px',
    height: '800px'
};

function WorkOrderTripStatusContainer() {
    const keys = {
        google: "AIzaSyDLhzLhxQLyaz3C_gigq8Rd9N8hC0yRT5c"
    };
    //const GOOGLE_MAP_ID = process.env.GoogleMapsMapId; // eslint-disable-line

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDLhzLhxQLyaz3C_gigq8Rd9N8hC0yRT5c",
    });

    const params = useParams();

    const [center, setCenter] = useState({
        lat: 33.4900000000,
        lng: -112.0400000000
    });

    const KilometerToMileConversion = 0.621371;
    const ShorthaulRadiusInMeters = 277772.77;

    const [userIsSysAdmin, setUserIsSysAdmin] = useState(false);

    const [showShorthaulRadius, setShowShorthaulRadius] = useState(false);

    const [workOrder, setWorkOrder] = useState({});
    const [tripMetrics, setTripMetrics] = useState({
        expectedDrivingDistance: 0,
        actualDrivingDistance: 0
    });

    useEffect(() => {
        async function fetchData() {
            var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

            //console.log(userRoles);

            var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;

            //console.log(isUserSystemAdmin);

            setUserIsSysAdmin(isUserSystemAdmin);

            var workOrderData = await FetchAPIPromise('WorkOrder/' + params.id);

            var responsibleOfficeData = await FetchAPIPromise('Office/GetById?id=' + workOrderData.responsibleOfficeId);

            //console.log('Resp office data', responsibleOfficeData);

            formatComponentPageName('#' + workOrderData.workOrderNumber + ' Trip Overview');

            var workOrderEmployeesData = await FetchAPIPromise('WorkOrderResourcesEmployeeList/GetByWorkOrderIdSimple/' + params.id);

            var workOrderGroundSegments = await FetchAPIPromise('WorkOrder/GetWorkOrderTripSegments?workOrderId=' + params.id);

            var workorderMilestones = await FetchAPIPromise('WorkOrderMilestone/GetAllMilestones/' + params.id);

            var employeeHoursData = await FetchAPIPromise('Timesheet/GetTimesheetEntriesByWorkOrderId?workOrderId=' + params.id);

            //console.log('EmployeeHours', employeeHoursData);

            // Strip the zones out
            employeeHoursData.hoursList.map((item) => {

                // Convert to the responsible office timezone
                if (item.actualEndDate) {
                    item.actualEndDate = moment.parseZone(item.actualEndDate).format('YYYY-MM-DDTHH:mm');
                }

                item.actualStartDate = moment.parseZone(item.actualStartDate).format('YYYY-MM-DDTHH:mm');
            });

            var actualMatchedGroundSegments = await FetchAPIPromise('WorkOrder/GetWorkOrderVehicleSegments?workOrderId=' + params.id);

            //console.log(actualMatchedGroundSegments);

            actualMatchedGroundSegments.map((item) => {

                // Convert to the responsible office timezone
                if (item.endDate) {
                    item.endDate = moment.parseZone(item.endDate).tz(responsibleOfficeData.timeZone).format('YYYY-MM-DDTHH:mm');
                }

                item.startDate = moment.parseZone(item.startDate).tz(responsibleOfficeData.timeZone).format('YYYY-MM-DDTHH:mm');
            });

            var allSurroundingGroundSegmentsData = await FetchAPIPromise('WorkOrder/GetAllVehicleSegmentsByDate?workOrderId=' + params.id);

            allSurroundingGroundSegmentsData.map((item) => {

                // Convert to the responsible office timezone
                if (item.endDate) {
                    item.endDate = moment.parseZone(item.endDate).tz(responsibleOfficeData.timeZone).format('YYYY-MM-DDTHH:mm');
                }

                item.startDate = moment.parseZone(item.startDate).tz(responsibleOfficeData.timeZone).format('YYYY-MM-DDTHH:mm');
            });

            //console.log(allSurroundingGroundSegmentsData);

            var actualVehicleStatusHistory = await FetchAPIPromise('WorkOrder/GetVehicleStatusHistory?workOrderId=' + params.id);

            //console.log(actualVehicleStatusHistory);

            var planGroundSegmentRoutesArray = [];
            var actualGroundSegmentRoutesArray = [];

            if (workOrderGroundSegments.transitLegsList) {

                if (workOrderGroundSegments.transitLegsList.length > 0) {
                    setCenter({
                        lat: workOrderGroundSegments.transitLegsList[0].originLatitude,
                        lng: workOrderGroundSegments.transitLegsList[0].originLongitude
                    });
                }

                workOrderGroundSegments.transitLegsList.map((leg) => {
                    var routeCoordinates = [{
                        lat: leg.originLatitude,
                        lng: leg.originLongitude
                    },
                    {
                        lat: leg.destinationLatitude,
                        lng: leg.destinationLongitude
                    }];

                    planGroundSegmentRoutesArray.push(routeCoordinates);
                });
            }

            if (actualMatchedGroundSegments) {
                actualMatchedGroundSegments.map((leg) => {
                    if (leg.isComplete) {
                        var routeCoordinates = [{
                            lat: leg.startLatitude,
                            lng: leg.startLongitude
                        },
                        {
                            lat: leg.endLatitude,
                            lng: leg.endLongitude
                        }];

                        actualGroundSegmentRoutesArray.push(routeCoordinates);
                    }
                })
            }

            if (actualVehicleStatusHistory) {
                actualVehicleStatusHistory.map((leg) => {
                    var routeCoordinates = [{
                        lat: leg.startLatitude,
                        lng: leg.startLongitude
                    },
                    {
                        lat: leg.endLatitude,
                        lng: leg.endLongitude
                    }];

                    actualVehicleStatusHistory.push(routeCoordinates);
                })
            }

            setWorkOrder(workOrderData);
            setWorkOrderEmployeesList(workOrderEmployeesData);

            setEmployeeHours(employeeHoursData.hoursList);

            setPlanGroundSegments(workOrderGroundSegments.transitLegsList);
            setPlanGroundSegmentRoutes(planGroundSegmentRoutesArray);

            setActualGroundSegments(actualMatchedGroundSegments);
            setActualGroundSegmentRoutes(actualGroundSegmentRoutesArray);

            setActualVehicleStatus(actualVehicleStatusHistory);

            setAllSurroundingVehicleSegments(allSurroundingGroundSegmentsData);

            var filteredSet = allSurroundingGroundSegmentsData;

            if (sameVehicleFilter) {
                var vehicles = [];

                actualMatchedGroundSegments.map((item) => vehicles.push(item.vehicleName));

                // Filter by matching vehicle of other identified segments
                filteredSet = filteredSet.filter((item) => vehicles.includes(item.vehicleName));

                setFilteredSurroundingVehicleSegments(filteredSet);
            }

            if (sameWorkOrderFilter) {

                filteredSet = filteredSet.filter((item) => item.workOrderId == workOrderData.id);

                setFilteredSurroundingVehicleSegments(filteredSet);
            }

            // Calculate expected driving distance
            var expectedTotalDrivingDistance = workOrderGroundSegments.transitLegsList.reduce((total, item) => total = total + item.drivingDistance, 0);

            //console.log(expectedTotalDrivingDistance);

            // Calculate actual driving distance
            var actualTotalDrivingDistance = actualMatchedGroundSegments.reduce((total, item) => total = total + item.distanceKilometers, 0);

            //console.log(actualTotalDrivingDistance);

            setTripMetrics({
                expectedDrivingDistance: expectedTotalDrivingDistance.toFixed(2),
                actualDrivingDistance: (actualTotalDrivingDistance * KilometerToMileConversion).toFixed(2)
            });

            var drivingMilestones = workorderMilestones.filter((item) => item.milestoneId == 11 || item.milestoneId == 12 || item.milestoneId == 13);

            //console.log(drivingMilestones);

            drivingMilestones.map((item) => {

                // Convert to the responsible office timezone
                item.eventDateTime = moment.parseZone(item.eventDateTime).tz(responsibleOfficeData.timeZone).format('YYYY-MM-DDTHH:mm');
            });

            setKeyMilestonesList(drivingMilestones);
        }

        fetchData();
    }, [params]);

    const [workOrderEmployeesList, setWorkOrderEmployeesList] = useState([]);

    const [planGroundSegments, setPlanGroundSegments] = useState([]);
    const [planGroundSegmentRoutes, setPlanGroundSegmentRoutes] = useState([]);

    const [actualGroundSegments, setActualGroundSegments] = useState([]);
    const [actualGroundSegmentRoutes, setActualGroundSegmentRoutes] = useState([]);

    const [actualVehicleStatus, setActualVehicleStatus] = useState([]);

    const [keyMilestonesList, setKeyMilestonesList] = useState([]);

    const [allSurroundingVehicleSegments, setAllSurroundingVehicleSegments] = useState([]);

    const [employeeHours, setEmployeeHours] = useState([]);

    const [filteredSurroundingVehicleSegments, setFilteredSurroundingVehicleSegments] = useState([]);

    const [map, setMap] = React.useState(null)

    const [sameVehicleFilter, setSameVehicleFilter] = useState(false);
    const [sameWorkOrderFilter, setSameWorkOrderFilter] = useState(true);
    const [sameEmployeesFilter, setSameEmployeesFilter] = useState(false);

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);

        map.fitBounds(bounds);

        setMap(map)
    }, [])

    useEffect(() => {
        if (map && planGroundSegmentRoutes) {

            const bounds = new window.google.maps.LatLngBounds();

            planGroundSegmentRoutes.map((location) => {
                bounds.extend(location[0]);

                bounds.extend(location[1]);
            });

            map.fitBounds(bounds);
        }
    }, [map, planGroundSegmentRoutes]);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    const claimSegment = async (e, id) => {
        e.preventDefault();

        const model = {
            segmentId: id,
            workOrderId: workOrder.id
        };

        // Remove workorderid and workordernumber from segment
        var returnValue = await PostAPIPromise('WorkOrder/ClaimWorkOrderVehicleSegment', model);

        // console.log('Method returned', returnValue);
        if (returnValue.status == 1) {
            // alert('Success');

            // Update the state to remove the workorderid and workordernumber from it
            setAllSurroundingVehicleSegments(allSurroundingVehicleSegments.map((item) => {
                if (item.id === id) {
                    return { ...item, workOrderId: workOrder.id, workOrderNumber: workOrder.workOrderNumber };
                }
                return item;
            }));

            // Update filtered set as well
            setFilteredSurroundingVehicleSegments(filteredSurroundingVehicleSegments.map((item) => {
                if (item.id === id) {
                    return { ...item, workOrderId: workOrder.id, workOrderNumber: workOrder.workOrderNumber };
                }
                return item;
            }));

            // TODO: Add routes to the map
            // Adjust mileage
            var additionalMileage = allSurroundingVehicleSegments.find((item) => item.id == id);

            setActualGroundSegments(oldArray => [...oldArray, additionalMileage]);

            //console.log('Current mileage', tripMetrics.actualDrivingDistance);
            //console.log(additionalMileage);

            setTripMetrics(prevState => ({
                ...prevState,
                actualDrivingDistance: (parseFloat(prevState.actualDrivingDistance) + (additionalMileage.distanceKilometers * KilometerToMileConversion)).toFixed(2)
            }));
        }
        else {
            alert(returnValue.message);
        }
    }

    const unclaimSegment = async (e, id) => {
        e.preventDefault();

        //console.log('Got here', id);

        // Remove workorderid and workordernumber from segment
        var returnValue = await PostAPIPromise('WorkOrder/UnclaimWorkOrderVehicleSegment?segmentId=' + id);

        // console.log('Method returned', returnValue);
        if (returnValue.status == 1) {
            // alert('Success');

            var removedMileage = allSurroundingVehicleSegments.find((item) => item.id == id);

            // Update the state to remove the workorderid and workordernumber from it
            setAllSurroundingVehicleSegments(allSurroundingVehicleSegments.map((item, i) => {
                if (item.id === id) {
                    return { ...item, workOrderId: null, workOrderNumber: null };
                }
                return item;
            }));

            // Update filtered set as well
            setFilteredSurroundingVehicleSegments(filteredSurroundingVehicleSegments.map((item, i) => {
                if (item.id === id) {
                    return { ...item, workOrderId: null, workOrderNumber: null };
                }
                return item;
            }));

            //setAllSurroundingVehicleSegments(l => l.filter(item => item.id !== id));
            setActualGroundSegments(l => l.filter(item => item.id !== id));

            // Adjust mileage, only if the unclaimed/removed segment was removed from this work order
            if (removedMileage.workOrderId == workOrder.id) {

                console.log('Current mileage', tripMetrics.actualDrivingDistance);
                console.log(removedMileage);

                setTripMetrics(prevState => ({
                    ...prevState,
                    actualDrivingDistance: (prevState.actualDrivingDistance - (removedMileage.distanceKilometers * KilometerToMileConversion)).toFixed(2)
                }));
            }
        }
        else {
            alert(returnValue.message);
        }
    }

    const toggleSameEmployeesFilter = async () => {
        var newValue = !sameEmployeesFilter;

        setSameEmployeesFilter(newValue);

        var filteredSet = allSurroundingVehicleSegments;

        if (sameVehicleFilter) {
            var vehicles = [];

            actualGroundSegments.map((item) => vehicles.push(item.vehicleName));

            // TODO: For now, filter by matching vehicle of other identified segments
            filteredSet = filteredSet.filter((item) => vehicles.includes(item.vehicleName));

            //console.log(filteredSet);
        }

        if (sameWorkOrderFilter) {

            filteredSet = filteredSet.filter((item) => item.workOrderId == workOrder.id);
        }

        if (newValue) {
            var employees = [];

            workOrderEmployeesList.map((item) => employees.push(item.employeeId));

            filteredSet = filteredSet.filter((item) => employees.includes(item.employeeId));
        }

        setFilteredSurroundingVehicleSegments(filteredSet);
    }

    const toggleSameWorkOrderFilter = async () => {
        //console.log(allSurroundingVehicleSegments);

        var newValue = !sameWorkOrderFilter;

        setSameWorkOrderFilter(newValue);

        var filteredSet = allSurroundingVehicleSegments;

        if (sameVehicleFilter) {
            var vehicles = [];

            actualGroundSegments.map((item) => vehicles.push(item.vehicleName));

            // TODO: For now, filter by matching vehicle of other identified segments
            filteredSet = filteredSet.filter((item) => vehicles.includes(item.vehicleName));

            //console.log(filteredSet);
        }

        if (sameEmployeesFilter) {
            var employees = [];

            workOrderEmployeesList.map((item) => employees.push(item.employeeId));

            filteredSet = filteredSet.filter((item) => employees.includes(item.employeeId));
        }

        if (newValue) {

            filteredSet = filteredSet.filter((item) => item.workOrderId == workOrder.id);
        }

        setFilteredSurroundingVehicleSegments(filteredSet);
    }

    const toggleSameVehicleFilter = async () => {
        console.log(allSurroundingVehicleSegments);

        var newValue = !sameVehicleFilter;

        setSameVehicleFilter(newValue);

        var filteredSet = allSurroundingVehicleSegments;

        if (newValue) {
            var vehicles = [];

            actualGroundSegments.map((item) => vehicles.push(item.vehicleName));

            // TODO: For now, filter by matching vehicle of other identified segments
            filteredSet = filteredSet.filter((item) => vehicles.includes(item.vehicleName));

            //console.log(filteredSet);
        }

        if (sameEmployeesFilter) {
            var employees = [];

            workOrderEmployeesList.map((item) => employees.push(item.employeeId));

            filteredSet = filteredSet.filter((item) => employees.includes(item.employeeId));
        }

        if (sameWorkOrderFilter) {

            filteredSet = filteredSet.filter((item) => item.workOrderId == workOrder.id);
        }

        setFilteredSurroundingVehicleSegments(filteredSet);
    }

    const toggleShowShorthaulRadius = async () => {
        //console.log(allSurroundingVehicleSegments);

        var newValue = !showShorthaulRadius;

        setShowShorthaulRadius(newValue);
    }

    const workOrderCellRender = ((cell) => {
        //console.log('WO render', cell);

        var highlightColor = (cell.row.data.workOrderId && cell.row.data.workOrderId == workOrder.id) ? 'green' : 'red';

        return (cell.row.data.workOrderId && userIsSysAdmin) ? <span style={{ color: highlightColor }}>{cell.value} <a href="#" onClick={(e) => unclaimSegment(e, cell.row.data.id)}>Unclaim</a></span> : ((!cell.row.data.workOrderId && userIsSysAdmin) ? <span style={{ color: highlightColor }}>{cell.value} <a href="#" onClick={(e) => claimSegment(e, cell.row.data.id)}>Claim</a></span> : <span style={{ color: highlightColor }}>{cell.value}</span>);
    });

    const transitLegRender = (data, index) => {
        return (
            <div>{data.origin} - {data.destination}</div>
        );
    }

    const driverNumberCellRender = ((cell) => {
        //console.log(cell);

        //console.log(workOrderEmployeesList);

        var isDriverNumberAnAssignedEmployee = workOrderEmployeesList.find((item) => item.employeeId == cell.row.data.employeeId);

        return <span style={{ color: (isDriverNumberAnAssignedEmployee ? 'green' : 'red') }}>{cell.value}</span>;
    });

    const distanceCellRender = ((cell) => {
        return (cell.value * KilometerToMileConversion).toFixed(2);
    });

    var polylineRoutes = [];

    planGroundSegmentRoutes.map((item) => polylineRoutes.push(<Polyline options={{ strokeColor: 'blue', strokeWeight: 4 }} path={item} />));
    actualGroundSegmentRoutes.map((item) => polylineRoutes.push(<Polyline options={{ strokeColor: 'red', strokeWeight: 4 }} path={item} />));
    actualVehicleStatus.map((item) => polylineRoutes.push(<Polyline options={{ strokeColor: 'yellow', strokeWeight: 4 }} path={item} />));

    var markers = [];

    planGroundSegments.map((item) => markers.push(<CustomMarker info={item.origin} lat={item.originLatitude} lng={item.originLongitude} />));

    var circles = [];

    if (showShorthaulRadius) {
        circles.push(<Circle center={center} radius={ShorthaulRadiusInMeters} options={{ fillColor: "#FF00FF", fillOpacity: 0.25 }} />);
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <fieldset>
                    <header><b>Work Order</b></header>
                    <br />

                    <Form
                        id="form"
                        colCount={4}
                        formData={workOrder}>

                        <Item dataField="workOrderName" editorOptions={{ readOnly: true }}>
                            <Label text="Name" />
                        </Item>

                        <Item dataField="workOrderNumber" editorOptions={{ readOnly: true }}>
                            <Label text="Number" />
                        </Item>

                        <Item dataField="planStartDate" editorType="dxDateBox" editorOptions={{
                            readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime'
                        }}>
                            <Label text="Plan Start Date" />
                        </Item>

                        <Item dataField="planEndDate" editorType="dxDateBox" editorOptions={{
                            readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime'
                        }}>
                            <Label text="Plan End Date" />
                        </Item>
                    </Form>
                </fieldset>
            </div>
            <br />

            <div className="row">
                <div className="me-auto">
                    <CheckBox value={showShorthaulRadius} id="showDOTRadius" text="Show Shorthaul Radius" onValueChanged={toggleShowShorthaulRadius} />
                </div>
            </div>
            <br />

            <div className="d-flex justify-content-between">
                {(isLoaded) ? (
                    <div className="me-auto">
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        >
                            {polylineRoutes}
                            {markers}
                            {circles}
                        </GoogleMap>
                    </div>
                ) : <div>Loading...</div>}

                <div className="ms-auto">
                    <Form colCount={3} style={{ margin: '10px' }} formData={tripMetrics}>
                        <Item editorType="dxTextBox" dataField="expectedDrivingDistance" editorOptions={{ readOnly: true }}>
                            <Label text="Expected Total Distance (mi)" location="top" />
                        </Item>

                        <Item rowSpan={2} colSpan={2}>
                            <DataGrid dataSource={employeeHours}>
                                <Column dataField="display" caption="Employee" width={200} />
                                <Column dataField="actualStartDate" caption="Clock In" width={130} dataType="datetime" format="MM/dd/yy HH:mm" />
                                <Column dataField="actualEndDate" caption="Clock Out" width={130} dataType="datetime" format="MM/dd/yy HH:mm" />
                            </DataGrid>
                            <Label text="Employee Hours" location="top" />
                        </Item>

                        <Item editorType="dxTextBox" dataField="actualDrivingDistance" editorOptions={{ readOnly: true }}>
                            <Label text="Actual Driving Distance (mi)" location="top" />
                        </Item>

                        <EmptyItem colSpan={2} />

                        <Item>
                            <Label text="Planned Transit Legs" location="top" />
                            <List dataSource={planGroundSegments} itemRender={transitLegRender} />
                        </Item>

                        <Item colSpan={2}>
                            <DataGrid dataSource={keyMilestonesList}>
                                <Column dataField="stageName" caption="Stage Name" width={150} />
                                <Column dataField="milestoneName" caption="Milestone" width={175} />
                                <Column dataField="eventDateTime" caption="Time" width={130} dataType="datetime" format="MM/dd/yy HH:mm" />
                                <Column dataField="comment" caption="Comment" />
                            </DataGrid>
                            <Label text="Vehicle Related Milestones" location="top" />
                        </Item>

                        <Item editorType="dxList" editorOptions={{ dataSource: actualGroundSegments, displayExpr: 'vehicleName' }}>
                            <Label text="Actual Vehicle Segments" location="top" />
                        </Item>

                        <Item colSpan={2}>
                            <CheckBox value={sameVehicleFilter} id="samevehicle" text="Show Same Vehicle" onValueChanged={toggleSameVehicleFilter} />&nbsp;&nbsp;
                            <CheckBox value={sameWorkOrderFilter} id="sameworkorder" text="This Work Order" onValueChanged={toggleSameWorkOrderFilter} />&nbsp;&nbsp;
                            <CheckBox value={sameEmployeesFilter} id="sameemployees" text="Show Work Order Employees" onValueChanged={toggleSameEmployeesFilter} />
                            <br />
                            <br />
                            <DataGrid dataSource={filteredSurroundingVehicleSegments} height={400}>
                                <FilterRow visible={true} />
                                <Column dataField="workOrderNumber" caption="Work Order #" cellRender={workOrderCellRender} width={150} />
                                <Column dataField="driverNumber" caption="Driver #" width={75} cellRender={driverNumberCellRender} />
                                <Column dataField="vehicleName" caption="Vehicle Name" />
                                <Column dataField="startDate" caption="Start Date" dataType="datetime" format="MM/dd/yy HH:mm" />
                                <Column dataField="endDate" caption="End Date" dataType="datetime" format="MM/dd/yy HH:mm" />
                                <Column dataField="distanceKilometers" caption="Distance (mi)" cellRender={distanceCellRender} />
                            </DataGrid>
                            <Label text="Surrounding Vehicle Segments" location="top" />
                        </Item>
                    </Form>
                </div>
            </div>
            <div style={{ marginBottom: 100 }} />
        </div>
    );
}

export default WorkOrderTripStatusContainer;