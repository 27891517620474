import React from 'react';
import BaseComponent, { FetchAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import { Link } from 'react-router-dom';
import DataGrid, { Pager, Paging, Column, FilterRow, Editing } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import Button from 'devextreme-react/button';
import PieChart, { Series, Title, Legend, Font, Size, Tooltip } from 'devextreme-react/pie-chart';


const complianceData = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('ComplianceUserChecklist');
    },
    remove: (key) => {
        return DeleteAPIPromise('ComplianceUserChecklist/' + key);
    }
});

export class ComplianceDashboardContainer extends BaseComponent {
    static displayName = ComplianceDashboardContainer.name;

    constructor(props) {
        super(props);

        this.state = {
            audits: [],
            loading: true,
            completedQuestionsValue: [{
                label: 'Completed Questions',
                value: 5
            }, {
                label: 'Questions To Be Completed',
                value: 5
            }],
            daysToCompleteValue: [{
                label: 'Days Since Start',
                value: 5
            }, {
                label: 'Days Left',
                value: 5
            }],
            questionsFailedValue: [{
                label: 'Passed Questions',
                value: 5
            }, {
                label: 'Failed Questions',
                value: 5
            }],
            completedQuestionsMetric: "XX/XX Items Reviewed",
            daysToCompleteMetric: 'Due in XX/XX Days',
            questionsFailedMetric: 'X Questions Failed'
        };
    }

    onFocusedRowChanged = async (e) => {
        const dataRow = e.row && e.row.data;
        const metricData = await this.FetchAPI('ComplianceUserChecklistAnswers/metrics/' + dataRow.id);
        //console.log(metricData);
        await this.setState({
            completedQuestionsValue: [{
                label: 'Completed Questions',
                value: metricData.completedQuestions
            }, {
                label: 'Questions to be completed',
                value: metricData.totalQuestions - metricData.completedQuestions
            }],
            completedQuestionsMetric: metricData.completedQuestions + "/" + metricData.totalQuestions + " Items Reviewed",

            questionsFailedValue: [{
                label: 'Passed Questions',
                value: metricData.totalQuestions - metricData.failedQuestions
            }, {
                label: 'Failed Questions',
                value: metricData.failedQuestions
            }],
            questionsFailedMetric: metricData.failedQuestions + " Questions Failed",

            daysToCompleteValue: [{
                label: 'Days Since Start',
                value: metricData.timePeriod - metricData.daysRemaining
            }, {
                label: 'Days Left',
                value: metricData.daysRemaining
            }],
            daysToCompleteMetric: "Due in " +  metricData.daysRemaining + " Days",
        })
        
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Compliance Dashboard");
    }

    customizeTooltip = (e) => {
        return { text:  e.argumentText + ': ' + e.valueText };
    }

    allowDeleting = (options) => {
        //console.log(options);

        return options.row.data && options.row.data.statusCode == 'UNINITIATED';
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-2 my-auto">
                            {(this.IsReadOnly())
                                ? <Button disabled={true} text="Manage Checklists" />
                                : <Link to="/manage-checklists"><Button text="Manage Checklists" /></Link>
                            }
                            <br />
                            <br />
                            {(this.IsReadOnly())
                                ? <Button disabled={true} text="Create New Audit" />
                                : <Link to="/create-audit"><Button disabled={this.IsReadOnly()} text="Create New Audit" /></Link>
                            }
                        </div>
                        
                        <PieChart className="col-sm-3"
                            type="doughnut"
                            palette="Soft Pastel"
                            dataSource={this.state.daysToCompleteValue}
                            sizeGroup="doughnuts">
                            <Series argumentField="label" valueField="value" />
                            <Title text={this.state.daysToCompleteMetric} horizontalAlignment="center">
                                <Font size="15" />
                            </Title>
                            <Tooltip enabled={true} customizeTooltip={this.customizeTooltip} />
                            <Legend visible={false} />
                            <Size height={200} width={200} />
                        </PieChart>

                        <PieChart className="col-sm-3"
                            type="doughnut"
                            palette="Soft Pastel"
                            dataSource={this.state.completedQuestionsValue}
                            sizeGroup="doughnuts">
                            <Series argumentField="label" valueField="value" />
                            <Title text={this.state.completedQuestionsMetric} horizontalAlignment="center">
                                <Font size="15" />
                            </Title>
                            <Tooltip enabled={true} customizeTooltip={this.customizeTooltip} />
                            <Legend visible={false} />
                            <Size height={200} width={200} />
                        </PieChart>

                        <PieChart className="col-sm-3"
                            type="doughnut"
                            palette="Soft Pastel"
                            dataSource={this.state.questionsFailedValue}
                            sizeGroup="doughnuts">
                            <Series argumentField="label" valueField="value" />
                            <Title text={this.state.questionsFailedMetric} horizontalAlignment="center">
                                <Font size="15" />
                            </Title>
                            <Tooltip enabled={true} customizeTooltip={this.customizeTooltip} />
                            <Legend visible={false} />
                            <Size height={200} width={200} />
                        </PieChart>
                    </div>
                </div>

                <br />
                <div className="container">
                    <DataGrid dataSource={complianceData}
                        showBorders={true} keyExpr="id" allowColumnResizing={true}
                        focusedRowEnabled={true} onFocusedRowChanged={this.onFocusedRowChanged}>
                        <FilterRow visible={true} />
                        <Editing allowAdding={false} allowUpdating={false} allowDeleting={this.allowDeleting} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} visible={true} />

                        <Column caption="Audit No" dataField="auditNumber" dataType="string" />
                        <Column caption="Audit Name" dataField="name" />
                        <Column caption="Audit Category" dataField="auditCategoryName" />
                        <Column caption="Audit Types" dataField="auditTypesDisplay" />
                        <Column caption="Due Date" dataField="planCompleteDate" dataType="date" format="MM/dd/yy" />
                        <Column caption="Auditor" dataField="employeeName" />
                        <Column caption="Office" dataField="officeName" />
                        <Column dataField="statusCode" visible={false} />
                        <Column caption="Status" dataField="statusName" />
                        <Column caption="Completion Date" dataField="actualCompleteDate" dataType="date" format="MM/dd/yy, HH:mm" />
                        <Column caption="Operations"
                            cellRender={
                                function (options) {
                                    return (<><Link to={`/view-audit/${options.row.data.id}`}><Button text="View" /></Link></>);
                                }}>
                        </Column>
                    </DataGrid>
                </div>
                <br />
            </div>
        );
    }
}