import React from 'react';
import BaseComponent, { withParams } from '../BaseComponent';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import Form, { Item, Label, RequiredRule, EmptyItem } from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import * as moment from 'moment';

class WorkOrderResourcesBookTicketContainer extends BaseComponent {
    static displayName = WorkOrderResourcesBookTicketContainer.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            workOrderId: '',
            workOrder: {},
            employees: [],
            customers: [],
            airports: [],
            airTicket: { origin: null, destination: null, maxStops: 0, date: null, seniorCount: null, refundable: false, adultCount: null, childCount: null, infantLapCount: null, infantSeatCount: null, rentalCar: false, hotel: false }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);

        const { id } = this.props.params

        await this.setState({ workOrderId: id });

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return (this.state.workOrder.workOrderNumber ? ("#" + this.state.workOrder.workOrderNumber + " WO Book Ticket") : 'WO Book Ticket');
    }

    GetData = async () => {
        var workOrderData = await this.FetchAPI('WorkOrder/' + this.state.workOrderId);
        var employeeTicketData = await this.FetchAPI('BookTicket/GetEmployeesByWorkOrder/' + this.state.workOrderId);
        var customerTicketData = await this.FetchAPI('BookTicket/GetCustomersByWorkOrder/' + this.state.workOrderId);

        var airportData = await this.FetchAPI('Facility/GetAirports');

        //console.log('Employee Tickets', employeeTicketData);
        //console.log('Customer Tickets', customerTicketData);
        //console.log(airportData);

        this.getInitFlightOptions(employeeTicketData, customerTicketData);

        // ABG: Not sure why I have to do this, but devextreme will not handle offset dates properly
        workOrderData.planStartDate = moment.parseZone(workOrderData.planStartDate).format('yyyy-MM-DDTHH:mm:ss');
        workOrderData.planEndDate = moment.parseZone(workOrderData.planEndDate).format('yyyy-MM-DDTHH:mm:ss');

        await this.setState({
            employees: employeeTicketData,
            customers: customerTicketData,
            workOrder: workOrderData,
            airports: airportData
        });
    }

    getInitFlightOptions = (employees, customers) => {
        var infantSeatCount = 0;
        var infantLapCount = 0;
        var childCount = 0;
        var adultCount = 0;
        var seniorCount = 0;

        for (var i = 0; i < customers.length; i++) {
            switch (customers[i].ticketType) {
                case "InfantsInLap":
                    infantLapCount++;
                    break;
                case "InfantsInSeat":
                    infantSeatCount++;
                    break;
                case "Children":
                    childCount++;
                    break;
                case "Adults":
                    adultCount++;
                    break;
                case "Seniors":
                    seniorCount++;
                    break;
            }
        }

        for (var i = 0; i < employees.length; i++) {
            switch (employees[i].ticketType) {
                case "InfantsInLap":
                    infantLapCount++;
                    break;
                case "InfantsInSeat":
                    infantSeatCount++;
                    break;
                case "Children":
                    childCount++;
                    break;
                case "Adults":
                    adultCount++;
                    break;
                case "Seniors":
                    seniorCount++;
                    break;
            }
        }

        this.updateProperty('airTicket', 'adultCount', adultCount);
        this.updateProperty('airTicket', 'childCount', childCount);
        this.updateProperty('airTicket', 'seniorCount', seniorCount);
        this.updateProperty('airTicket', 'infantLapCount', infantLapCount);
        this.updateProperty('airTicket', 'infantSeatCount', infantSeatCount);
        this.updateProperty('airTicket', 'date', this.state.workOrder.planStartDate);
        this.updateProperty('airTicket', 'employees', employees);
        this.updateProperty('airTicket', 'customers', customers);
    }

    saveAirTicket = async (e) => {

        var initialValidate = this.editFormControl.instance().validate();

        if (initialValidate.isValid) {
            var response = await this.FetchAPI('WorkOrder/CheckIsHaveOneUACandTwoEmployee?workOrderId=' + this.state.workOrderId);

            if (response.status != 1) {
                alert(response.message);
                this.props.navigate('/workorder-resources/' + this.state.workOrderId);
            }
            else {
                this.state.airTicket.workOrderName = this.state.workOrder.workOrderName;
                this.state.airTicket.workOrderNumber = this.state.workOrder.workOrderNumber;

                await this.setState({
                    loading: true
                });

                const response = await this.PostAPI('BookTicket', this.state.airTicket);

                await this.setState({
                    loading: false
                });

                if (response.status == 1) {
                    this.props.navigate('/workorder-resources/' + this.state.workOrderId)
                }
                else {
                    alert('Failed. Please try again later.');
                }
            }
        }
    }

    render() {
        return (
            <div className="container">
                <h1>Book Ticket</h1>

                <fieldset>
                    <header><b>Work Order</b></header>
                    <br />

                    <Form
                        id="form"
                        colCount={4}
                        formData={this.state.workOrder}>

                        <Item dataField="workOrderName" editorOptions={{ readOnly: true }}>
                            <Label text="Name" />
                        </Item>

                        <Item dataField="workOrderNumber" editorOptions={{ readOnly: true }}>
                            <Label text="Number" />
                        </Item>

                        <Item dataField="planStartDate" editorType="dxDateBox" editorOptions={{
                            readOnly: true, displayFormat: 'MM/dd/yy, HH:mm', type: 'datetime'
                        }}>
                            <Label text="Plan Start Date" />
                        </Item>

                        <Item dataField="planEndDate" editorType="dxDateBox" editorOptions={{
                            readOnly: true, displayFormat: 'MM/dd/yy, HH:mm', type: 'datetime'
                        }}>
                            <Label text="Plan End Date" />
                        </Item>
                    </Form>
                </fieldset>
                <br />

                <fieldset>
                    <header><b>Travelers</b></header>
                    <br />

                    <DataGrid dataSource={this.state.customers} showBorders={true} allowColumnResizing={true}>
                        <Column caption='A#/Subject ID' dataField='comboIDDisplay' />
                        <Column caption='First Name' dataField='firstName' />
                        <Column caption='Middle Name' dataField='middleName' />
                        <Column caption='Last Name' dataField='lastName' />
                        <Column caption='Gender' dataField='gender' />
                        <Column caption='Age' dataField='age' />
                        <Column caption='Ticket Type' dataField='ticketType' />
                        <Column caption='Trip Type' dataField='tripType' />
                        <Column caption='Transport Unit' dataField='transportUnit' />
                    </DataGrid>
                    <br />

                    <DataGrid dataSource={this.state.employees} showBorders={true} allowColumnResizing={true}>
                        <Column caption='Employee NO' dataField='id' />
                        <Column caption='First Name' dataField='firstName' />
                        <Column caption='Middle Name' dataField='middleName' />
                        <Column caption='Last Name' dataField='lastName' />
                        <Column caption='Gender' dataField='sex' />
                        <Column caption='Age' dataField='age' />
                        <Column caption='Ticket Type' dataField='ticketType' />
                        <Column caption='Trip Type' dataField='tripType' />
                    </DataGrid>
                </fieldset>
                <br />

                <fieldset>
                    <header><b>Generate Itinerary</b></header>
                    <br />

                    <Form id="form" colCount={3} formData={this.state.airTicket} ref={ref => this.editFormControl = ref}>

                        <Item dataField="date" editorType="dxDateBox" editorOptions={{
                            displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime'
                        }}>
                            <Label text="Outbound Date (Anytime after)" />
                            <RequiredRule />
                        </Item>
                        <Item dataField="origin" editorType="dxSelectBox" editorOptions={{ dataSource: this.state.airports, displayExpr: 'display', valueExpr: 'airportCode', searchEnabled: true }}>
                            <Label text="Origin" />
                            <RequiredRule />
                        </Item>
                        <Item dataField="destination" editorType="dxSelectBox" editorOptions={{ dataSource: this.state.airports, displayExpr: 'display', valueExpr: 'airportCode', searchEnabled: true }} >
                            <Label text="Destination" />
                            <RequiredRule />
                        </Item>

                        <Item dataField="maxStops" editorType="dxNumberBox" editorOptions={{ value: this.state.airTicket.maxStops }}>
                            <Label text="Max Stops" />
                        </Item>
                        <Item dataField="seniorCount" editorType="dxNumberBox" editorOptions={{ value: this.state.airTicket.seniorCount }}>
                            <Label text="Senior Count" />
                        </Item>
                        <Item dataField="refundable" editorType="dxCheckBox" >
                            <Label text="Refundable" />
                        </Item>

                        <Item dataField="adultCount" editorType="dxNumberBox" editorOptions={{ value: this.state.airTicket.adultCount }}>
                            <Label text="Adult Count" />
                        </Item>
                        <Item dataField="childCount" editorType="dxNumberBox" editorOptions={{ value: this.state.airTicket.childCount }}>
                            <Label text="Child Count" />
                        </Item>
                        <Item dataField="infantLapCount" editorType="dxNumberBox" editorOptions={{ value: this.state.airTicket.infantLapCount }}>
                            <Label text="Infant in Lap Count" />
                        </Item>

                        <Item dataField="infantSeatCount" editorType="dxNumberBox" editorOptions={{ value: this.state.airTicket.infantSeatCount }}>
                            <Label text="Infant in Seat Count" />
                        </Item>
                        <Item dataField="rentalCar" editorType="dxCheckBox" >
                            <Label text="Rental Car" />
                        </Item>
                        <Item dataField="hotel" itemType="dxCheckBox">
                            <Label text="Hotel" />
                        </Item>

                        <Item dataField="additionalMsg" editorType="dxTextArea" colSpan={2} editorOptions={{ autoResizeEnabled: true, minHeight: 100 }}>
                        </Item>
                        <EmptyItem />

                        <Item colSpan={2}>
                            <Label text="Example" />
                            <p>The following are some example comments for clarity:</p>
                            <ul>
                                <li>Dallas to St. Louis-Adults- early morning</li>
                                <li>St. Louis – no car needed</li>
                                <li>St. Louis to Minneapolis-Adults and Minors-late afternoon</li>
                                <li>Minneapolis rental car needed</li>
                                <li>Hotel in Minneapolis</li>
                                <li>Minneapolis to Dallas-Adults-first flight out</li>
                            </ul>
                        </Item>
                    </Form>

                    <br />
                    <div className="container">
                        <div className="row">
                            <div className="ms-auto me-3 form-inline">
                                <Button text='Generate Reservation' onClick={this.saveAirTicket} />
                            </div>
                        </div>
                    </div>
                    <br />
                </fieldset>
                <br />

                <LoadPanel visible={this.state.loading} />
            </div>
        );
    }
}

export default withParams(WorkOrderResourcesBookTicketContainer);