import React from 'react';
import DataSource from 'devextreme/data/data_source';
import BaseComponent, { withParams } from "../BaseComponent";
import Accordion, { Item } from 'devextreme-react/accordion';
import { Popup } from 'devextreme-react/popup';
import { LoadPanel } from 'devextreme-react/load-panel';
import parse from 'html-react-parser';
import Form, { EmptyItem, Item as FormItem, Label, RequiredRule } from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import PieChart, { Series, Title, Legend, Font, Size, Tooltip } from 'devextreme-react/pie-chart';

const ClosedItems = new DataSource({
    store: {
        type: 'array',
        data: [
            {
                value: false,
                name: 'No'
            },
            {
                value: true,
                name: 'Yes'
            }
        ],
        key: 'name'
    },
});

class ComplianceAuditViewContainer extends BaseComponent {
    static displayName = ComplianceAuditViewContainer.name;

    constructor(props) {
        super(props);

        this.ChecklistAccordion = React.createRef();

        this.state = {
            auditId: '',
            auditData: {},

            loading: false,

            closedQuestionsValue: [{
                label: 'Closed Questions',
                value: 0
            }, {
                label: 'Open Questions',
                value: 0
            }],
            statusCodeValue: [{
                label: 'None',
                value: 0
            }, {
                label: 'Advisied Item',
                value: 0
            }, {
                label: 'Non-Compliance',
                value: 0
            }],
            questionsFailedValue: [{
                label: 'Passed Questions',
                value: 0
            }, {
                label: 'Failed Questions',
                value: 0
            }],

            closedQuestionsMetric: "XX/XX Items Closed",
            statusCodeMetric: 'X Items With Status Code',
            questionsFailedMetric: 'X Questions Failed',

            checklistData: [],
            employees: [],
            statuses: [],

            returnedChecklistIndexes: [],

            answerData: [],
            layoutData: [],
            priorityData: [],
            statusData: [],
            tags: [],

            displayPhoto: false,
            photoData: '',

            checklists: [],

            filter: {
                isClosed: null,
                status: ""
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);

        const { id } = this.props.params

        await this.setState({ auditId: id });

        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("View Audit");
    }

    GetData = async () => {
        this.setState({
            loading: true
        });

        const auditData = await this.FetchAPI('ComplianceUserChecklist/checklist/' + this.state.auditId);

        const checklistData = await this.FetchAPI('ComplianceUserChecklist/GetChecklistsForAudit?auditId=' + this.state.auditId);

        const answerData = await this.FetchAPI('ComplianceUserChecklistAnswers/' + this.state.auditId);

        //console.log('Audit Answers', answerData);

        const layoutData = await this.FetchAPI('ComplianceUserChecklistLayout/' + this.state.auditId);

        //console.log('Audit Layout', layoutData);

        const priorityData = await this.FetchAPI('ComplianceAuditAnswerPriority');

        const statusData = await this.FetchAPI('ComplianceAuditAnswerStatus');

        const tagData = await this.FetchAPI('ComplianceAuditAnswerTags');

        const auditorsData = await this.FetchAPI('Employee/GetActiveEmployees');

        const statusesData = await this.FetchAPI('ComplianceAuditStatus');

        this.setState({
            auditData: auditData,
            checklistData: checklistData,
            answerData: answerData,
            layoutData: layoutData,
            priorityData: priorityData,
            statusData: statusData,
            employees: auditorsData,
            statuses: statusesData,
            tags: tagData,
            loading: false
        });

        this.handleData();
    };

    handleData = async () => {
        this.state.checklists = [];

        var totalQuestions = 0;
        var failedQuestions = 0;
        var closedQuestions = 0;
        var statusNone = 0;
        var statusAdvisedItem = 0;
        var statusNonCompliance = 0;

        this.state.returnedChecklistIndexes = [];

        for (var k = 0; k < this.state.checklistData.length; k++) {
            var uniqueSections = this.state.layoutData.filter(item => item.checklistId == this.state.checklistData[k].checklistId && item.isSection);

            var hasSections = uniqueSections.length > 0;

            var groupedQuestions = [];

            var questionDetail = [];

            if (hasSections) {
                var questionGroup = this.state.layoutData.filter(item => item.checklistId == this.state.checklistData[k].checklistId);

                for (var i = 0; i < uniqueSections.length; i++) {

                    var questionGroup = this.state.layoutData.filter(item => item.checklistId == this.state.checklistData[k].checklistId);

                    // Find the beginning and end
                    var startIndex = questionGroup.findIndex((element) => element.id == uniqueSections[i].id) + 1;

                    if (questionGroup[startIndex].isSection) {
                        i--;
                        questionGroup.splice(startIndex, 1);
                        questionGroup.splice(startIndex - 1, 1);
                        continue;
                    }

                    // If we're at the end, take to the end
                    if (i + 1 == uniqueSections.length) {
                        var endIndex = questionGroup.length;
                    }
                    else {
                        var endIndex = questionGroup.findIndex((element) => element.id == uniqueSections[i + 1].id);
                    }

                    groupedQuestions[i] = [];

                    if (startIndex == endIndex) {
                        var questionGroup = [questionGroup[startIndex]];
                    }
                    else {
                        var questionGroup = questionGroup.slice(startIndex, endIndex);
                    }

                    // Add for each section
                    questionGroup.map((question) => {
                        if (this.state.filter.isClosed == null) {
                            if (!this.state.filter.status) {
                                questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId);
                            }
                            else {
                                questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId && element.statusCode == this.state.filter.status);
                            }
                        }
                        else {                          
                            if (!this.state.filter.status) {
                                questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId && element.isClosed == this.state.filter.isClosed);
                            }
                            else {
                                questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId && element.statusCode == this.state.filter.status && element.isClosed == this.state.filter.isClosed);
                            }
                        }

                        if (questionDetail) {
                            groupedQuestions[i].push(questionDetail);
                            totalQuestions += 1;
                            if (!questionDetail.isClosed) {
                                closedQuestions += 1;
                            }
                            if (questionDetail.answer == 'No') {
                                failedQuestions += 1;
                            }
                            if (!questionDetail.statusCode) {
                                statusNone += 1;
                            }
                            else if (questionDetail.statusCode == 'ADVISEDITEM') {
                                statusAdvisedItem += 1;
                            }
                            else {
                                statusNonCompliance += 1;
                            }

                            if (!this.state.returnedChecklistIndexes.includes(k) && (this.state.filter.isClosed != null || this.state.filter.status)) {
                                this.state.returnedChecklistIndexes.push(k);
                            }
                        }
                    });
                }

                var questionGroup = this.state.layoutData.filter(item => item.checklistId == this.state.checklistData[k].checklistId);

                // Need to check to see if we have some questions without a section at the beginning.
                if (questionGroup.length > 0 && !questionGroup[0].isSection) {
                    // We have some out of section
                    var firstSectionIndex = questionGroup.findIndex((element) => element.id == uniqueSections[0].id);
                    var questionGroup = firstSectionIndex == -1 ? this.state.layoutData.filter(item => item.checklistId == this.state.checklistData[k].checklistId) : this.state.layoutData.slice(0, firstSectionIndex);

                    groupedQuestions.unshift([]);

                    // Add for each section
                    questionGroup.map((question) => {
                        if (this.state.filter.isClosed == null) {
                            if (!this.state.filter.status) {
                                questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId);
                            }
                            else {
                                questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId && element.statusCode == this.state.filter.status);
                            }
                        }
                        else {
                            if (!this.state.filter.status) {
                                questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId && element.isClosed == this.state.filter.isClosed);
                            }
                            else {
                                questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId && element.statusCode == this.state.filter.status && element.isClosed == this.state.filter.isClosed);
                            }
                        }

                        if (questionDetail) {
                            groupedQuestions[0].push(questionDetail);
                            totalQuestions += 1;
                            if (!questionDetail.isClosed) {
                                closedQuestions += 1;
                            }
                            if (questionDetail.answer == 'no') {
                                failedQuestions += 1;
                            }
                            if (!questionDetail.statusCode) {
                                statusNone += 1;
                            }
                            else if (questionDetail.statusCode == 'ADVISEDITEM') {
                                statusAdvisedItem += 1;
                            }
                            else {
                                statusNonCompliance += 1;
                            }

                            if (!this.state.returnedChecklistIndexes.includes(k) && (this.state.filter.isClosed != null || this.state.filter.status)) {
                                this.state.returnedChecklistIndexes.push(k);
                            }
                        }
                    });

                    uniqueSections.unshift({ sectionText: '' });
                }
            }
            else {
                // No sections in this checklist, just load the whole checklist
                uniqueSections[0] = [];
                var questionGroup = this.state.layoutData.filter(item => item.checklistId == this.state.checklistData[k].checklistId);

                groupedQuestions[0] = [];

                // Add for each section
                questionGroup.map((question) => {
                    if (this.state.filter.isClosed == null) {
                        if (!this.state.filter.status) {
                            questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId);
                        }
                        else {
                            questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId && element.statusCode == this.state.filter.status);
                        }
                    }
                    else {
                        if (!this.state.filter.status) {
                            questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId && element.isClosed == this.state.filter.isClosed);
                        }
                        else {
                            questionDetail = this.state.answerData.find((element) => element.questionId == question.questionId && element.statusCode == this.state.filter.status && element.isClosed == this.state.filter.isClosed);
                        }
                    }

                    if (questionDetail) {
                        groupedQuestions[0].push(questionDetail);
                        totalQuestions += 1;
                        if (!questionDetail.isClosed) {
                            closedQuestions += 1;
                        }
                        if (questionDetail.answer == 'No') {
                            failedQuestions += 1;
                        }
                        if (!questionDetail.statusCode) {
                            statusNone += 1;
                        }
                        else if (questionDetail.statusCode == 'ADVISEDITEM') {
                            statusAdvisedItem += 1;
                        }
                        else {
                            statusNonCompliance += 1;
                        }

                        if (!this.state.returnedChecklistIndexes.includes(k) && (this.state.filter.isClosed != null || this.state.filter.status)) {
                            this.state.returnedChecklistIndexes.push(k);
                        }
                    }
                });
            }

            this.state.checklists.push({ checklistName: this.state.checklistData[k].checklistName, uniqueSections: uniqueSections, groupedQuestions: groupedQuestions });

            this.setState({
                statusCodeValue: [{
                    label: 'None',
                    value: statusNone
                }, {
                    label: 'Advised Item',
                    value: statusAdvisedItem
                }, {
                    label: 'Non-Compliance',
                    value: statusNonCompliance
                }],
                statusCodeMetric: statusAdvisedItem + statusNonCompliance + ' Items With Status Code',

                closedQuestionsValue: [{
                    label: 'Closed Questions',
                    value: totalQuestions - closedQuestions
                }, {
                    label: 'Open Questions',
                    value: closedQuestions
                }],
                closedQuestionsMetric: totalQuestions - closedQuestions + "/" + totalQuestions + " Items Closed",

                questionsFailedValue: [{
                    label: 'Passed Questions',
                    value: totalQuestions - failedQuestions
                }, {
                    label: 'Failed Questions',
                    value: failedQuestions
                }],
                questionsFailedMetric: failedQuestions + " Questions Failed",
            });
        }
    }

    showPhoto = async (id, answerId) => {
        await this.setState({
            loading: true
        });
        //console.log('Display Photo');
        //console.log(id);
        //console.log(answerId);
        var photo = await this.FetchAPI('ComplianceUserChecklistAnswers/GetPhoto?id=' + id + '&answerId=' + answerId);
        //console.log(photo);
        await this.setState({
            displayPhoto: true,
            loading: false,
            photoData: "data:image/png;base64," + photo.file
        });
    }

    handleCloseModal = async (e) => {
        await this.setState({ displayPhoto: false, photoData: '' });
    }

    SaveAnswer = async (e, question) => {
        try {
            this.setState({
                loading: true
            });

            var questionToSave = { ...question };
            questionToSave.attachments = [];

            const result = await this.PostAPI('ComplianceUserChecklistAnswers', questionToSave);

            //console.log(result);

            if (result.status != 1) {
                alert('Failed. Please try again later.');
            }
        }
        finally {
            this.setState({
                loading: false
            });
        }
    }

    fieldDataChanged = async (e, answerId) => {
        //console.log('Field Data changed', e);
        //console.log(this.state.filters);

        if (e.dataField == 'isClosed') {
            if (e.value == false) {
                // Update
                e.component.updateData('closedDate', null);
            }
            else {
                var formData = this.state.answerData.find((item) => item.id == answerId);

                //console.log(formData);

                if (!formData.closedDate) {
                    // Update
                    e.component.updateData('closedDate', new Date());
                }
            }
        }
    }

    SaveAudit = async (e) => {
        var result = this.editFormControl.instance().validate();

        if (result.isValid) {
            // Save status
            try {
                this.setState({
                    loading: true
                });

                const result = await this.PostAPI('ComplianceUserChecklist/SaveAudit', this.state.auditData);

                //console.log(result);

                if (result.status != 1) {
                    alert('Failed. Please try again later.');
                }
                else {
                    this.props.navigate('/view-audit/' + this.state.auditData.id);
                    this.props.navigate(0);
                }
            }
            finally {
                this.setState({
                    loading: false
                });
            }
        }
    }

    handleTextChange = async (event) => {
        await this.setState(
            (state) => ({
                filter: {
                    ...state.filter,
                    [event.element.id]: event.value
                }
            })
        );
    }

    isAuditReadOnly() {
        return (this.IsReadOnly() || this.state.auditData.statusCode == 'COMPLETED');
    }

    search = async () => {
        this.setState({
            loading: true
        });

        this.handleData();

        this.ChecklistAccordion.current.instance().option('items', this.state.checklists);

        if (this.state.returnedChecklistIndexes.length > 0) {
            this.state.returnedChecklistIndexes.forEach(index => this.ChecklistAccordion.current.instance().expandItem(index))
        }
        else {
            this.ChecklistAccordion.current.instance().option('selectedIndex', 0);
        }

        this.setState({
            loading: false
        });
    }

    customizeTooltip = (e) => {
        return { text: e.argumentText + ': ' + e.valueText };
    }

    ChecklistTitleRender = (data) => {
        return (data.checklistName);
    }

    ChecklistItemRender = (data, index) => {
        var radioGroupItems = [
            {
                text: 'No',
                value: false
            },
            {
                text: 'Yes',
                value: true
            }
        ];

        return (
            <div>
                {data.uniqueSections.map((item, index) => (
                    <Accordion key={'uniqueSection' + index} collapsible={true} multiple={true}>
                        <Item title={item.sectionText}>
                            {data.groupedQuestions[index].map((question, questionIndex) => (
                                <div key={'question' + questionIndex}>
                                    <div className="row">
                                        <div className="col-sm-9">{parse(question.questionText)}</div>

                                        <div className="col-sm-2">{question.answer ? question.answer : <b>No Answer</b>}</div>

                                        <div className="col-sm-1">
                                            {question.attachments.map((attachment, index) => (
                                                <img key={'image' + index} style={{ height: '30px', width: '30px', marginLeft: '5px', marginRight: '5px' }} src="./camera.png" onClick={this.showPhoto.bind(this, attachment.id, attachment.answerId)} />
                                            ))}
                                        </div>
                                    </div>
                                    <br />

                                    <Form formData={question} colCount={4} onFieldDataChanged={(e) => this.fieldDataChanged(e, question.id)}>
                                        <FormItem dataField="comments" visible={question.allowsComments} colSpan={2} editorOptions={{ readOnly: this.isAuditReadOnly() }}>
                                            <Label text="Comments" />
                                        </FormItem>

                                        <EmptyItem colSpan={2} />

                                        <FormItem dataField="priorityCode" editorType="dxSelectBox" editorOptions={{ readOnly: this.isAuditReadOnly(), searchEnabled: true, showClearButton: true, dataSource: this.state.priorityData, displayExpr: 'name', valueExpr: 'code' }}>
                                            <Label text="Priority" />
                                        </FormItem>

                                        <FormItem dataField="statusCode" editorType="dxSelectBox" editorOptions={{ readOnly: this.isAuditReadOnly(), searchEnabled: true, showClearButton: true, dataSource: this.state.statusData, displayExpr: 'name', valueExpr: 'code' }}>
                                            <Label text="Status" />
                                        </FormItem>

                                        <EmptyItem colSpan={2} />

                                        <FormItem dataField="correctiveActionPlan" visible={question.correctiveActionPlan} colSpan={2} editorOptions={{ readOnly: this.isAuditReadOnly() }}>
                                            <Label text="Corrective Action Plan" />
                                        </FormItem>

                                        <EmptyItem colSpan={2} />

                                        <FormItem dataField="correctiveActionPlanDueDate" editorType="dxDateBox" editorOptions={{ readOnly: this.isAuditReadOnly() }}>
                                            <Label text="Corrective Action Plan Due Date" />
                                        </FormItem>

                                        <EmptyItem colSpan={3} />

                                        <FormItem dataField="isClosed" editorType="dxRadioGroup" editorOptions={{ readOnly: this.isAuditReadOnly(), dataSource: radioGroupItems, displayExpr: 'text', valueExpr: 'value' }}>
                                            <Label text="Closed?" />
                                        </FormItem>

                                        <FormItem dataField="closedDate" editorType="dxDateBox" editorOptions={{ readOnly: this.isAuditReadOnly(), displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime' }}>
                                            <Label text="Closed Date" />
                                        </FormItem>
                                        <EmptyItem colSpan={2} />

                                        <FormItem dataField="employeeAssignments" colSpan={2} editorType="dxTagBox" editorOptions={{ readOnly: this.isAuditReadOnly(), applyValueMode: 'useButtons', searchEnabled: true, showSelectionControls: true, dataSource: this.state.employees, displayExpr: 'employeeName', valueExpr: 'employeeId' }}>
                                            <Label text="Assigned To" />
                                        </FormItem>
                                        <EmptyItem colSpan={2} />

                                        <FormItem dataField="tags" colSpan={2} editorType="dxTagBox" editorOptions={{ readOnly: this.isAuditReadOnly(), applyValueMode: 'useButtons', searchEnabled: true, showSelectionControls: true, dataSource: this.state.tags, displayExpr: 'tagName', valueExpr: 'tagCode' }}>
                                            <Label text="Tags" />
                                        </FormItem>
                                    </Form>
                                    <div className="d-flex">
                                        <div className="ms-auto me-3">
                                            <Button text='Save' type='success' disabled={this.isAuditReadOnly()} onClick={(e) => this.SaveAnswer(e, question)} />
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            ))}
                        </Item>
                    </Accordion>
                ))}
            </div>
        );
    }

    render() {

        return (
            <div>
                <h1>View Audit</h1>

                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <PieChart className="col-sm-3 d-flex justify-content-center"
                            type="doughnut"
                            palette="Soft Pastel"
                            dataSource={this.state.closedQuestionsValue}
                            sizeGroup="doughnuts">
                            <Series argumentField="label" valueField="value" />
                            <Title text={this.state.closedQuestionsMetric} horizontalAlignment="center">
                                <Font size="15" />
                            </Title>
                            <Tooltip enabled={true} customizeTooltip={this.customizeTooltip} />
                            <Legend visible={false} />
                            <Size height={200} width={200} />
                        </PieChart>

                        <PieChart className="col-sm-3 d-flex justify-content-center"
                            type="doughnut"
                            palette="Soft Pastel"
                            dataSource={this.state.statusCodeValue}
                            sizeGroup="doughnuts">
                            <Series argumentField="label" valueField="value" />
                            <Title text={this.state.statusCodeMetric} horizontalAlignment="center">
                                <Font size="15" />
                            </Title>
                            <Tooltip enabled={true} customizeTooltip={this.customizeTooltip} />
                            <Legend visible={false} />
                            <Size height={200} width={200} />
                        </PieChart>

                        <PieChart className="col-sm-3 d-flex justify-content-center"
                            type="doughnut"
                            palette="Soft Pastel"
                            dataSource={this.state.questionsFailedValue}
                            sizeGroup="doughnuts">
                            <Series argumentField="label" valueField="value" />
                            <Title text={this.state.questionsFailedMetric} horizontalAlignment="center">
                                <Font size="15" />
                            </Title>
                            <Tooltip enabled={true} customizeTooltip={this.customizeTooltip} />
                            <Legend visible={false} />
                            <Size height={200} width={200} />
                        </PieChart>
                    </div>
                </div>
                <br />

                <div className="row">
                    <div style={{ marginBottom: 10 }} className="col-sm-3 d-flex me-auto justify-content-start align-items-center">
                        <SelectBox value={this.state.filter.isClosed} id="isClosed"
                            label="Closed?" labelMode="floating"
                            dataSource={ClosedItems}
                            displayExpr="name" valueExpr="value"
                            showClearButton={true}
                            onValueChanged={this.handleTextChange} />
                        &nbsp;
                        <SelectBox value={this.state.filter.status} id="status"
                            label="Status" labelMode="floating"
                            dataSource={this.state.statusData}
                            displayExpr="name" valueExpr="code"
                            showClearButton={true}
                            onValueChanged={this.handleTextChange} />
                        &nbsp;
                        <Button style={{ marginTop: 8 }} text="Search" icon="search" onClick={this.search} />
                    </div>

                    <div style={{ marginBottom: 2}} className="col-sm-3 d-flex ms-auto justify-content-end align-items-center">
                        <Form id="form" ref={ref => this.editFormControl = ref} formData={this.state.auditData}>
                            <Item dataField="statusCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, showClearButton: true, dataSource: this.state.statuses, displayExpr: 'statusName', valueExpr: 'statusCode' }}>
                                <RequiredRule />
                                <Label text="Status" />
                            </Item>
                        </Form>
                        &nbsp;
                        <Button text='Save' onClick={this.SaveAudit} />
                    </div>
                </div>

                <Accordion items={this.state.checklists}
                    itemRender={this.ChecklistItemRender}
                    itemTitleRender={this.ChecklistTitleRender}
                    collapsible={true} multiple={true}
                    ref={this.ChecklistAccordion}/>          

                <br />
                <br />

                <Popup visible={this.state.displayPhoto} onHiding={this.handleCloseModal} dragEnabled={false}
                    closeOnOutsideClick={true} height='auto' width='auto'>
                    <img style={{ height: '450px' }} id="ItemPreview" src={this.state.photoData} />
                </Popup>

                <LoadPanel visible={this.state.loading} shading={false} />
            </div>
        );
    }
}

export default withParams(ComplianceAuditViewContainer);