
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Column } from 'devextreme-react/data-grid';
import { Link } from 'react-router-dom';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';

const iceDashboardData = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('ICEDashboard');
    }
});

const keyMetricsFAMU = new CustomStore({
    load: () => {
        return FetchAPIPromise('ICEDashboard/GetKeyMetricsFAMU');
    }
});

const famuAorCount = new CustomStore({
    load: () => {
        return FetchAPIPromise('ICEDashboard/GetCountFAMUAOR');
    }
})

const keyMetricsUAC = new CustomStore({
    load: () => {
        return FetchAPIPromise('ICEDashboard/GetKeyMetricsUAC');
    }
});

const uacAorCount = new CustomStore({
    load: () => {
        return FetchAPIPromise('ICEDashboard/GetCountUACAOR')
    }
});

export class ICEDashboardContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {

        };

        this.refreshPageInterval = 3000;
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();

        this.interval = setInterval(() => {
            this.iceDashboardDataGrid.instance().refresh();
            this.famuAorCountDataGrid.instance().refresh();
            this.uacAorCountDataGrid.instance().refresh();
            this.keyMetricsFAMUDataGrid.instance().refresh();
            this.keyMetricsUACDataGrid.instance().refresh();
        }, this.refreshPageInterval * 1000);
    }

    GetComponentPageName = () => {
        return ("ICE Dashboard");
    }

    componentWillUnmount = async () => {
        clearInterval(this.interval);
    }

    RiskStatusRender = function (options) {
        return (<span>
            <span style={{
                color: options.value === 'High' ? '#ff2e00'
                    : options.value === 'Moderate' ? '#ffa500'
                        : options.value === 'Normal' ? '#57d500'
                            : '#ffffff', //#ffbf00
                transition: 'all .3s ease'
            }}>
                &#x25cf;
                            </span> {
                options.value === 'High' ? 'High'
                    : options.value === 'Moderate' ? 'Moderate'
                        : options.value === 'Normal' ? 'Normal'
                            : ''
            }
        </span>);
    }

    render() {
        return (
            <div className="container">
                <h1>ICE Dashboard</h1>
                <div className="d-flex justify-content-between">
                    <DataGrid dataSource={keyMetricsUAC} className="ms-3 me-auto" style={{ width: '550px' }}
                        showBorders={true} ref={ref => this.keyMetricsUACDataGrid = ref}>
                        <Column caption="Key Metrics: UC" dataField="metricName" />
                        <Column caption="Total" dataField="total" width={50} />
                        <Column caption="Male" dataField="maleCount" width={50} />
                        <Column caption="Female" dataField="femaleCount" width={60} />
                        <Column caption="Tender" dataField="tenderCount" width={60} />
                        <Column caption="Standard Age" dataField="standardAgeCount" width={100} />
                    </DataGrid>

                    <DataGrid dataSource={keyMetricsFAMU} className="ms-auto me-3" style={{ width: '500px' }}
                        showBorders={true} ref={ref => this.keyMetricsFAMUDataGrid = ref}>
                        <Column caption="Key Metrics: FAMU" dataField="metricName" />
                        <Column caption="Total" dataField="total" width={50} />
                        <Column caption="Male" dataField="maleCount" width={50} />
                        <Column caption="Female" dataField="femaleCount" width={60} />
                    </DataGrid>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                    <DataGrid dataSource={uacAorCount} className="ms-3 me-auto" style={{ width: '550px' }}
                        showBorders={true} ref={ref => this.uacAorCountDataGrid = ref}>
                        <Column caption="Count of UC/AOR" dataField="metricName" />
                    </DataGrid>

                    <DataGrid dataSource={famuAorCount} className="ms-auto me-3" style={{ width: '500px' }}
                        showBorders={true} ref={ref => this.famuAorCountDataGrid = ref}>
                        <Column caption="Count of FAMU/AOR" dataField="metricName" />
                    </DataGrid>
                </div>
                <br />

                <div className="d-flex">
                    <DataGrid dataSource={iceDashboardData}
                        showBorders={true} allowColumnResizing={true}
                        keyExpr="id" ref={ref => this.iceDashboardDataGrid = ref} repaintChangesOnly={true}>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={50} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[25, 50, 100]}
                            showInfo={true} />
                        <Column dataField="customerId" visible={false} />
                        <Column caption="Risk Status" dataField="severity"
                            cellRender={this.RiskStatusRender} />
                        <Column caption="Origin AOR" dataField="aor" />
                        <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                        <Column caption="First Name" dataField="firstName" />
                        <Column caption="Last Name" dataField="lastName" />
                        <Column caption="ORR/FRC Placement Date-Time" dataField="orrPlacementDate" dataType="datetime" format="MM/dd/yy, HH:mm '(Local)'" />
                        <Column caption="Pickup Date-Time" dataField="pickupDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                        <Column caption="Pickup Lead Time" dataField="hangingHours" />
                        <Column caption="Transport Method" dataField="trafficType" />
                        <Column caption="Transport Type" dataField="transType" />
                        <Column caption="FAMU/UC" dataField="transUnit" />
                        <Column caption="Pickup/Delay Reason" dataField="pickupDelay" />
                        <Column caption="Work Order Number" dataField="workOrderNumber" cellRender={function (options) { return (<Link to={`/workorder-detail/${options.row.data.id}`}>{options.value}</Link>); }} />
                        <Column caption="Responsible Office" dataField="responsibleOffice" />
                    </DataGrid>
                    <br />
                </div>
            </div>
        );
    }
}

