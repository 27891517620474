import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Editing, RequiredRule, StringLengthRule, Column, Lookup, Form, Export } from 'devextreme-react/data-grid';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import { Item } from 'devextreme-react/form';
import * as $ from 'jquery';

const projectCodes = new CustomStore({
    key: 'id',
    load: () => {
        return FetchAPIPromise('ProjectCodes');
    },
    insert: (values) => {
        console.log(values);
        return PostAPIPromise('ProjectCodes', values);
    },
    remove: (key) => {
        return DeleteAPIPromise('ProjectCodes/' + key)
            .then((result) => {
                if (result.status == -2) {
                    alert('Project code is referenced in the system and cannot be deleted. Consider inactivating.')
                }
            });
    },
    update: (key, values) => {
        return PostAPIPromise('ProjectCodes', values);
    }
});

export class ProjectCodesContainer extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            offices: [],
            cities: [],
            transTypes: [],
            transUnits: [],
            subcontractCodes: []
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return "Project Codes";
    }

    GetDropDownData = async () => {
        const officeData = await this.FetchAPI('Office');

        const cityData = await this.FetchAPI('DictionaryCity');

        const transTypeData = await this.FetchAPI('DictionaryTrafficType');

        const transUnitData = await this.FetchAPI('DictionaryTransportUnit');

        const subcontractCodeData = await this.FetchAPI('DictionaryAuthority');

        this.setState({
            offices: officeData,
            cities: cityData,
            transTypes: transTypeData,
            transUnits: transUnitData,
            subcontractCodes: subcontractCodeData
        });
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    getAuthorityCodes = (options) => {
        console.log(options);

        if (options.data) {
            return this.state.subcontractCodes.filter(m => m.transportUnitCode === options.data.transUnit && (m.isActive || m.code === options.data.subcontractCode));
        }
        else {
            return this.state.subcontractCodes;
        }
    }

    setStateValue(rowData, value) {
        rowData.subcontractCode = null;
        this.defaultSetCellValue(rowData, value);
    }

    render() {
        return (
            <div className="container">
                <h1>Maintain Project Codes</h1>
                <div className="row">
                    <DataGrid showBorders={true} allowColumnResizing={true} dataSource={projectCodes} keyExpr="id" onRowUpdating={this.onRowUpdating} onExporting={this.onExporting}>
                        <Export enabled={true} fileName={"projectCodes"} />
                        <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()} >
                            <Form>
                                <Item itemType="group" colCount={2} colSpan={2} >
                                    <Item dataField="cityCode" />
                                    <Item dataField="officeId" />
                                    <Item dataField="transUnit" />
                                    <Item dataField="transType" />
                                    <Item dataField="subcontractCode" />
                                </Item>
                                <Item itemType="group" colCount={2} colSpan={2} >
                                    <Item dataField="projectCode" />
                                    <Item dataField="plc" />
                                    <Item dataField="effectiveDate" />
                                    <Item dataField="thanHour" />
                                    <Item dataField="projectCode2" />
                                    <Item dataField="plC2" />
                                </Item>
                            </Form>
                        </Editing>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} />
                        <Column caption="Effective Date" dataField="effectiveDate" dataType="date" format="MM/dd/yyyy" >
                            <RequiredRule />
                        </Column>
                        <Column caption="Office" dataField="officeId">
                            <Lookup dataSource={this.state.offices} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column caption="City Code" dataField="cityCode" >
                            <Lookup dataSource={this.state.cities} displayExpr="cityName" valueExpr="cityCode" />
                        </Column>
                    
                        <Column caption="Transport Type" dataField="transType" >
                            <Lookup dataSource={this.state.transTypes} displayExpr="name" valueExpr="code" />
                            <RequiredRule />
                        </Column>
                        <Column caption="Transport Unit" dataField="transUnit" setCellValue={this.setStateValue}>
                            <Lookup dataSource={this.state.transUnits} displayExpr="name" valueExpr="code" />
                        </Column>
                        <Column caption="Subcontract Code" dataField="subcontractCode" >
                            <Lookup dataSource={this.getAuthorityCodes} displayExpr="name" valueExpr="code" />
                        </Column>
                        <Column caption="Project Code" dataField="projectCode">
                            <RequiredRule />
                            <StringLengthRule max={100} />
                        </Column>
                        <Column caption="PLC" dataField="plc" >
                            <StringLengthRule max={50} />
                        </Column>
                        <Column caption="Than Hours" dataField="thanHour" />
                        <Column caption="Project Code 2" dataField="projectCode2" >
                            <StringLengthRule max={100} />
                        </Column>
                        <Column caption="PLC 2" dataField="plC2" >
                            <StringLengthRule max={50} />
                        </Column>
                    </DataGrid>
                    <br />
                </div>
            </div>
        );
    }


}