import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Paging, Column } from 'devextreme-react/data-grid';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import { Link } from 'react-router-dom';
import Button from 'devextreme-react/button';


const contractListData = new CustomStore({
    key: 'contractId',
    load: () => {
        return FetchAPIPromise('Contract');
    }
})

export class ContractListContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return "Contract List";
    }

    render() {
        return (
            <div className="container">
                <h1>Contract List</h1>
                <div className="d-flex">
                    <div className="ms-auto me-3">
                        {(this.IsReadOnly())
                            ? <Button disabled={true} icon="plus" text="Add Contract" />
                            : <Link to="/contract-details"><Button icon="plus" text="Add Contract" /></Link>
                        }

                    </div>
                </div>
                <br />
                <DataGrid dataSource={contractListData} showBorders={true} allowColumnResizing={true} >
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={5} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} visible={true} />
                    <Column dataField='contractName' cellRender={function (options) { return (<Link to={`/contract-details/${options.row.data.contractId}`}>{options.value}</Link>); }} />
                    <Column dataField="contractCode" />
                </DataGrid>
            </div>
        );
    }
}