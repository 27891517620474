import React from 'react';
import BaseComponent from '../BaseComponent';
import * as $ from 'jquery';
import { Client } from "@microsoft/microsoft-graph-client";
import { msalAuth } from '../../msal/MsalAuthProvider';
import { InteractionType } from "@azure/msal-browser"
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import Box, { Item } from 'devextreme-react/box';
import { ScrollView } from 'devextreme-react/scroll-view';
import { Popup } from 'devextreme-react/popup';
import { Collapse, ButtonGroup, Button, CardBody, CardHeader, Card } from 'reactstrap';

const account = msalAuth.getActiveAccount();
const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalAuth, {
    account,
    scopes: ["user.read"],
    interactionType: InteractionType.Popup,
});
const client = Client.initWithMiddleware({ authProvider });

const MAX_COLUMNS = 3; // Controls the amount of cards per row
const MAX_PREVIEW_ITEMS = 3; // Controls the how many items should be previewed while the card is collapsed.

export class HelpContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.govUsage = React.createRef();

        this.state = {
            loading: false,

            showGovUsage: false,

            sectionRowList: [],

            staticSections: [
                {
                    id: "contacts",
                    name: "Contacts",
                    webUrl: "",
                    items: [{
                        id: "applicationSupport",
                        name:
                            <div>
                                MVM IT Helpdesk<br />
                                <a href="mailto: helpdesk@mvminc.com">helpdesk@mvminc.com</a><br />
                                571-223-4633
                            </div>
                    }, {
                        id: "commandCenter",
                        name:
                            <div>
                                Command Center<br />
                                <a href="mailto: mvmcommandcenter@mvminc.com">mvmcommandcenter@mvminc.com</a><br />
                                956-621-7020
                            </div>
                    }, {
                        id: "commandSupervisors",
                        name:
                            <div>
                                Command Supervisors<br />
                                <a href="mailto: commandcentersupervisors@mvminc.com">commandcentersupervisors@mvminc.com</a><br />
                                956-621-7020
                            </div>
                    },/* TODO: Get operation team contact information {
                    id: "operationsTeam",
                    name:
                        <div>
                            Operations Team<br />
                            <a href="mailto: xxx@mvminc.com">xxx@mvminc.com</a><br />
                            xxx-xxx-xxxx
                        </div>
                    },*/ {
                        id: "hrSupport",
                        name:
                            <div>
                                HR Support<br />
                                <a href="mailto: hrsupport@mvminc.com">hrsupport@mvminc.com</a><br />
                            </div>
                    }, {
                        id: "timesheets",
                        name:
                            <div>
                                Timesheets<br />
                                <a href="mailto: timesheets@mvminc.com">timesheets@mvminc.com</a><br />
                            </div>
                    }, {
                        id: "complaints",
                        name:
                            <div>
                                Complaints<br />
                                <a href="mailto: complaints@mvminc.com">complaints@mvminc.com</a><br />
                            </div>
                    }
                    ],
                    ratio: 1,
                    baseSize: 0
                }, {
                    id: "about",
                    name: "About",
                    webUrl: "",
                    items: [{
                        id: "governmentUsage",
                        name: <div className="d-flex align-items-center justify-content-end col p-0">
                            Government Usage
                                <ButtonGroup className="d-flex ms-auto square-icon" size="sm">
                                    <Button className="d-flex p-2" onClick={this.toggleGovUsage} color="secondary" outline><i style={{ fontSize: "1.3em" }} className="bi-eye-fill" /></Button>
                                </ButtonGroup>
                        </div>
                    }, {
                            id: "systemInformation",
                            name: this.systemConfig.systemName + " Version " + this.systemConfig.systemVersion,
                    }/*, { // TODO: Get content to fill out this item
                        id: "termsAndConditions",
                        name: <div className="d-flex align-items-center justify-content-end col p-0">
                            Terms & Conditions <div className="ms-auto">
                                <ButtonGroup size="sm">
                                    <Button onClick={this.toggleTerms} target="_blank" color="secondary" outline><i className="dx-icon-search" /></Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    }*/
                    ],
                    ratio: 1,
                    baseSize: 0
                }
            ]
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();

        this.fetchData();
    }

    GetData = async () => {
        this.setState({
            loading: true
        });

        this.getDriveItems();

        this.setState({
            loading: false
        });
    }

    GetComponentPageName = () => {
        return "Help";
    }

    getDriveItems = async () => {
        await this.setState({
            loading: true
        });

        var siteName = this.systemConfig.helpSiteName;
        var systemName = this.systemConfig.systemName;

        var sectionList;

        try {
            //Get "drive" information (defaults to the 'Shared Documents' library)
            var driveResponse = await client.api(`/sites/mvminccloud.sharepoint.com:/sites/${siteName}:/drive`).get();
            var driveId = driveResponse.id;

            var foldersResponse = await client.api(`/drives/${driveId}/root:/Applications/${systemName}:/children`).filter('folder ne null').get();
            var folders = foldersResponse.value;

            var documentsResponse;
            var documents;

            var dynamicSections = await Promise.all(
                folders.map(async (folder) => {
                    documentsResponse = await client.api(`/drives/${driveId}/items/${folder.id}/children`)
                        .select('id,name,content.downloadUrl,webUrl')
                        .get();

                    //Version History should be sorted in descending order
                    if (folder.name === 'Version History') {
                        documents = documentsResponse.value.sort((a, b) => b.name.localeCompare(a.name));
                    }
                    else {
                        documents = documentsResponse.value;
                    }

                    return {
                        id: folder.id,
                        name: folder.name,
                        webUrl: folder.webUrl,
                        items: documents,
                        ratio: 1,
                        baseSize: 0
                    }
                })
            );

            sectionList = dynamicSections.concat(this.state.staticSections);
        }
        catch (e) {
            sectionList = this.state.staticSections;
            //alert("Failed to retrieve SharePoint help content")
        }

        var tempSectionRowList = [];
        var maxIndex = sectionList.length - MAX_COLUMNS; // Marks the last index we can safely start from and still slice forward by MAX_COLUMNS
        var index = 0;

        do {
            if (index <= maxIndex) {
                tempSectionRowList.push({
                    sections: sectionList.slice(index, index + MAX_COLUMNS),
                    ratio: 1,
                    baseSize: "auto"
                });
            }
            else {
                // Less than MAX_COLUMNS sections remaining, so just slice to end
                tempSectionRowList.push({
                    sections: sectionList.slice(index),
                    ratio: 1,
                    baseSize: "auto"
                });
            }

            index += MAX_COLUMNS;
        } while (index <= sectionList.length - 1)

        await this.setState({
            loading: false,
            sectionRowList: tempSectionRowList
        });
    }

    colRender = (row) => {
        var width = (row.sections.length * (100 / MAX_COLUMNS)); // Keeps the width of sections uniform even if one row has less than MAX_COLUMNS
        return (
            <div>
                <Box direction="row"
                    width={`${width}%`}
                    style={{ "margin": "auto" }}
                    items={row.sections}
                    itemRender={this.rowRender}
                />
            </div>
        );
    }

    rowRender = (section) => {
        return (
            <div>
                <Card className="dx-accordion dx-accordion-item-closed border-0 mb-4 p-0 " style={{ "width": "85%", "margin": "auto" }}>
                    {(section.items.length > MAX_PREVIEW_ITEMS)
                        ? (<CardHeader id={"heading" + section.id}
                            onClick={this.showMore}
                            className="shadow-sm dx-theme-background-color dx-accordion-item-title"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse" + section.id}
                            aria-controls={"collapse" + section.id}
                            aria-expanded="false">
                            {section.name}
                        </CardHeader>)
                        :  (<CardHeader id={"heading" + section.id}
                            className="shadow-sm dx-theme-background-color dx-theme-accent-as-text-color"
                            style={{ padding: "9px 12px", fontSize: "18px", cursor: "auto" }}
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseOne" + section.id}
                            aria-controls={"collapseOne" + section.id}
                            aria-expanded="false">
                            {section.name}
                        </CardHeader>)
                    }
                    <CardBody style={{ cursor: "auto" }} className="border-bottom shadow-sm rounded-bottom pe-0 pt-0">
                        <ScrollView>
                            {section.items.slice(0, MAX_PREVIEW_ITEMS).map((item) => (
                                <div key={item.id} className="d-flex align-items-end align-items-center border-bottom pb-3 pt-3 me-3 m-0">
                                    {(item['@microsoft.graph.downloadUrl'] ? item.name.slice(0, item.name.lastIndexOf('.')) : item.name)}
                                    <ButtonGroup className="d-flex ms-auto square-icon" size="sm">
                                        {item.webUrl
                                            ? <Button className="d-flex p-2" href={item.webUrl} target="_blank" color="secondary" outline><i style={{ fontSize: "1.3em" }} className="bi-eye-fill" /></Button>
                                            : <></>
                                        }
                                        {item['@microsoft.graph.downloadUrl']
                                            ? <Button className="d-flex p-2" href={item['@microsoft.graph.downloadUrl']} color="secondary" outline><i style={{ fontSize: "1.3em" }} className="dx-icon-download" /></Button>
                                            : <></>
                                        }
                                    </ButtonGroup>
                                </div>
                            ))}
                            <Collapse id={"collapse" + section.id} style={{ maxHeight: 150 }}>
                                {section.items.slice(MAX_PREVIEW_ITEMS).map((item) => (
                                    <div key={item.id} className="d-flex align-items-end align-items-center border-bottom pb-3 pt-3 me-3 m-0">
                                        {(item['@microsoft.graph.downloadUrl'] ? item.name.slice(0, item.name.lastIndexOf('.')) : item.name)}
                                        <ButtonGroup className="d-flex ms-auto square-icon" size="sm">
                                            {item.webUrl
                                                ? <Button className="d-flex p-2" href={item.webUrl} target="_blank" color="secondary" outline><i style={{ fontSize: "1.3em" }} className="bi-eye-fill" /></Button>
                                                : <></>
                                            }
                                            {item['@microsoft.graph.downloadUrl']
                                                ? <Button className="d-flex p-2" href={item['@microsoft.graph.downloadUrl']} color="secondary" outline><i style={{ fontSize: "1.3em" }} className="dx-icon-download" /></Button>
                                                : <></>
                                            }
                                        </ButtonGroup>
                                    </div>
                                ))}
                            </Collapse>
                        </ScrollView>
                    </CardBody>
                </Card>
            </div>
        );
    }

    showMore = (e) => {
        if (e.target.ariaExpanded === 'true') {
            e.target.parentNode.classList.remove("dx-accordion-item-closed");
            e.target.parentNode.classList.add("dx-accordion-item-opened")
        }
        else {
            e.target.parentNode.classList.remove("dx-accordion-item-opened")
            e.target.parentNode.classList.add("dx-accordion-item-closed");
        }
    }

    toggleGovUsage = async () => {
        await this.setState({
            showGovUsage: !this.state.showGovUsage,
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <h1 className="col">Help</h1>

                <br />

                <Box direction="col"
                    width="auto"
                    height="auto"
                    noDataText="Loading..."
                    style={{ "margin": "auto" }}
                    items={this.state.sectionRowList}
                    itemRender={this.colRender} />

                <Popup visible={this.state.showGovUsage} title="Government Usage"
                    height="auto"
                    width="auto"
                    hideOnOutsideClick={false}
                    onHiding={this.toggleGovUsage}
                    ref={this.govUsage}>
                    <div className="d-flex flex-column min-vh-90 justify-content-center align-items-center">
                        <h1>*************** WARNING! ***************</h1>
                        <div style={{ maxWidth: '800px' }}>
                            <p>You are accessing a U.S. Government information system, which includes (1) this computer, (2) this computer network, (3) all computers connected to this network, and (4) all devices and storage media attached to this network or to a computer on this network. This information system is provided for U.S. Government-authorized use only. Unauthorized or improper use of this system may result in disciplinary action, as well as civil and criminal penalties.</p>
                            <br />
                            <p>By using this information system, you understand and consent to the following:</p>
                            <ul>
                                <li>You have no reasonable expectation of privacy regarding any communications or data transiting or stored on this information system. At any time, the government may for any lawful government purpose monitor, intercept, search and seize any communication or data transiting or stored on this information system.</li>
                                <li>Communications or data transiting or stored on this information system may be disclosed or used for any lawful government purpose. Your consent is final and irrevocable.</li>
                                <li>You may not rely on any statements or informal policies purporting to provide you with any expectation of privacy regarding communications on this system.</li>
                            </ul>
                            <br />
                            <p>For further information see the Department order on Use and Monitoring of Department Computers and Computer Systems.</p>
                        </div>
                    </div>
                </Popup>

            </div>
        );
    }
}